<div class="exception-panel">
    <div class="exception-code">
        
    </div>

    <div class="exception-detail">

        <div class="exception-icon">
            <i class="material-icons">verified_user</i>
        </div>
        <h1>ACCESS DENIED</h1>
        <p>You are not authorized to access the requested resource. Please contact the system administrator.</p>
        <button type="button" class="ui-button ui-widget ui-corner-all ui-button-text-only">
            <span class="ui-button-text" (click)="gotoHome()">GO HOME</span>
        </button>
    </div>
</div>