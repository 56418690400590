<ng-container>
  <p-fieldset [legend]="savedSearch?.saved_search_name" [toggleable]="true" [collapsed]="true">
    <!-- {OPEN:4a0cdde1-1ba1-4309-b7e9-1a57fc9bb48a} {} -->
    <!-- {OPEN:4a0cdde1-1ba1-4309-b7e9-1a57fc9bb48a} {} -->
    <!-- {OPEN:bbd27e28-6395-41d1-b87b-0404f962384a} {} -->
    <!-- {PREOPEN:c007e1d0-b83c-4c9c-8030-166d495b6d56} {"sha1":"66120DE8739BDA5285D0BBAAE097CD1502DA20C8"} -->
    <div class="ui-g form-group">
      <!-- {OPEN:c007e1d0-b83c-4c9c-8030-166d495b6d56} {} -->
      <!-- {PREOPEN:60bedca7-6204-4bf2-a763-c6c1119b7b69} {"sha1":"2B732D9EFD5E930E87F2F52918896EBD4796AD3F"} -->
      <div class="ui-g-12 ui-md-3">
        <!-- {OPEN:60bedca7-6204-4bf2-a763-c6c1119b7b69} {} -->
        <!-- {OPEN:0f075491-2d46-49b3-8b81-e763db1c46ac} {"sha1":"7C06F89CB6E83F4475FA8FDD0EE4D5A210BDEB58"} -->

        {{savedSearch?.saved_search_name}}<br/>
        <div class="ui-g-12 ui-md-3">
          <button pButton type="button" label="Search" icon="ui-icon-search" (click)="onSearchClick()"></button>
        </div>
        <!-- <a class="cursor-pointer" (click)="onSearchClick(savedSearch)"></a> -->
        <!-- {CLOSE:0f075491-2d46-49b3-8b81-e763db1c46ac} {"sha1":"7C06F89CB6E83F4475FA8FDD0EE4D5A210BDEB58"} -->
        <!-- {CLOSE:60bedca7-6204-4bf2-a763-c6c1119b7b69} {} -->
      </div>
      <!-- {POSTCLOSE:60bedca7-6204-4bf2-a763-c6c1119b7b69} {"sha1":"797F18B33CA2DAC7B5A389B76ABFD8D91F547465"} -->
      <!-- {PREOPEN:62f5be7c-eaf4-4d7f-b599-843609a21462} {"sha1":"2B732D9EFD5E930E87F2F52918896EBD4796AD3F"} -->
      <!-- {POSTCLOSE:62f5be7c-eaf4-4d7f-b599-843609a21462} {"sha1":"797F18B33CA2DAC7B5A389B76ABFD8D91F547465"} -->
      <!-- {PREOPEN:464fe33f-6555-474d-ba4a-53ceb91a07d8} {"sha1":"2B732D9EFD5E930E87F2F52918896EBD4796AD3F"} -->
      <div class="ui-g-12 ui-md-9">
        <div class="ui-g-12 ui-md-6" *ngFor="let query of savedSearch?.setQueries">
          <label [ngStyle]='{"word-break":"break-word"}'><b>{{getDisplayQuery(query.name) + ' : ' + setOperatorValue(query)}} </b>
            {{(query.value? convertQueryCodeToValue(query?.name, query?.value) : '') +  (query.value1? query.value1 + " " : "") + (query.value2? query.value2 : "")}}
          </label>
        </div>
      </div>
    </div>

    <!-- {POSTCLOSE:c007e1d0-b83c-4c9c-8030-166d495b6d56} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
    <!-- {CLOSE:bbd27e28-6395-41d1-b87b-0404f962384a} {} -->

    <!-- {CLOSE:4a0cdde1-1ba1-4309-b7e9-1a57fc9bb48a} {} -->
  </p-fieldset>
  </ng-container>
