<form #form="ngForm" [class.pointer-events-none]="this.showLoader">
	<div class="ui-g form-group login-form-field">
		<div class="ui-g-12 ui-md-12">
            <span class="ui-float-label">
                <input id="username-input" [(ngModel)]="searchLoginPage.Username" required name="Username" fieldLabel="Username" type="text" pInputText class="ui-inputtext">
                <label for="username-input">Username</label>
            </span>
		</div>
	</div>
	<div class="ui-g form-group login-form-field">
		<div class="ui-g-12 ui-md-12">
            <span class="ui-float-label">
                <input id="password-input" [(ngModel)]="searchLoginPage.Password"  required name="Password" fieldLabel="Password" type="password" pInputText class="ui-inputtext">
                <label for="password-input">Password</label>
            </span>
		</div>
	</div>
	<!-- <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-12  ui-fluid">
            <oneit-checkbox [(ngModel)]="login.keepLoggedin" name="KeepmeloggedinEmail" fieldLabel="Keep me logged in" binary="true"> </oneit-checkbox>Keep me logged in
        </div>
    </div> -->
	<div class="ui-g form-group">
		<div class="ui-g-12 ui-md-12  ui-fluid">
			<p-button [disabled]="disableLoginBtn" id="login" label="Login" type="submit" class="box-full-width-btn"  icon="ui-icon-lock" (onClick)="onSignMeInClick($event, searchLoginPage)" ></p-button>
		</div>
	</div>
	<div class="ui-g form-group">
		<div class="ui-g-12 ui-md-12  ui-fluid" style="text-align: center">
			<a [routerLink]="['/forgot-password']"> Forgot Password?</a>
		</div>
	</div>
</form>
<div class="ui-g form-group">
  <div class="ui-g-12 ui-md-12  ui-fluid" style="text-align: center;text-decoration: underline;">
    <a href="https://constructionbos.com/contact.php" target="_blank" > Get in touch with us.</a>
  </div>
</div>
<p-progressSpinner *ngIf="showLoader"></p-progressSpinner>
