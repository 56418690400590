
export class SearchRequest {
    serviceName: string;
    queryType: string;
    queryParams: any;
    assocs: String[];
    attribs: string;
    sort: string;
    resultsFrom: Number;
    resultsTo: Number;

    constructor(serviceName: string, queryType: string, queryParams, assocs: String[], attribProfile: string, sort: string, resultsFrom: Number, resultsTo: Number) {

        this.serviceName = serviceName;
        this.queryType = queryType;
        this.queryParams = queryParams;
        this.assocs = assocs;

        if (attribProfile) {
            this.attribs = attribProfile;
        }

        if (sort) {
            this.sort = sort;
        }

        if (resultsFrom) {
            this.resultsFrom = resultsFrom;
        }

        if (resultsTo) {
            this.resultsTo = resultsTo;
        }
    }
}