import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cbos-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss'],

})

export class AdminDashboardComponent implements OnInit {

  ngOnInit(): void {
  }

}
