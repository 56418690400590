import { CISDate } from './../cis/cis-date-filter/cis-date.model';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Constants } from 'src/app/cbos/cis/consts';
import _ from 'lodash';
import { MAT_DATE_FORMATS } from '@angular/material/core';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};


@Component({
  selector: 'app-cbos-date-filter',
  templateUrl: './cbos-date-filter.component.html',
  styleUrls: ['./cbos-date-filter.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class CbosDateFilterComponent implements OnInit {
  constructor(public datePipe: DatePipe) {}

  @Output() datesEmitter: EventEmitter<{}> = new EventEmitter();

  @Input()
  dateRange: CISDate = new CISDate();

  fromDate: FormControl;
  toDate: FormControl;

  ngOnInit(): void {
    this.fromDate = new FormControl(new Date(Date.parse(this.dateRange.from)));
    this.toDate = new FormControl(new Date(Date.parse(this.dateRange.to)));
  }
  ngOnDestroy() {
    this.dateRange.from = null;
    this.dateRange.to = null;
    this.dateRange.selected = '';
    this.emitDates();
  }

  fromValidator = (date: Date): boolean => {
    if (_.isEmpty(this.dateRange.to)) return true;
    let toDate = new Date(Date.parse(this.dateRange.to));
    if (date <= toDate) return true;
    return false;
  };

  toValidator = (date: Date): boolean => {
    if (_.isEmpty(this.dateRange.from)) return true;
    let fromDate = new Date(Date.parse(this.dateRange.from));
    if (date >= fromDate) return true;
    return false;
  };

  emitDates() {
    this.datesEmitter.emit(this.dateRange);
  }

  fromDateUpdate(event) {
    let date = null;
    if (event.value)
      date = this.datePipe.transform(event.value, Constants.DATE_FORMAT);
    this.dateRange.from = date;
    this.emitDates();
  }

  toDateUpdate(event) {
    let date = null;
    if (event.value)
      date = this.datePipe.transform(event.value, Constants.DATE_FORMAT);
    this.dateRange.to = date;
    this.emitDates();
  }
}
