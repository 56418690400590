import { Observable, of } from 'rxjs';

export class OptionsWrapper {
    isVolatile : boolean = false;   //true means this can change rapidly, so we will execute same function again to load new data.

    constructor(_isVolatile : boolean) {
        this.isVolatile = _isVolatile;
    }

    getObservable() : Observable<any[]> {
        throw new Error("Not Implemented");
    }
}

export class OptionsValue extends OptionsWrapper {
    options : any[] = [];

    constructor(_isVolatile : boolean, _options: any[]) {
        super(_isVolatile);
        this.options = _options;
    }

    getObservable() : Observable<any[]> {
        return of(this.options);
    }
}

export class OptionsObservable extends OptionsWrapper {
    observable : Observable<any[]>;

    constructor(_isVolatile : boolean, _observable: Observable<any[]>) {
        super(_isVolatile);
        this.observable = _observable;
    }

    getObservable() : Observable<any[]> {
        return this.observable;
    }
}
