import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-cards',
  templateUrl: './dashboard-cards.component.html',
  styleUrls: ['./dashboard-cards.component.scss']
})
export class DashboardCardsComponent implements OnInit, OnDestroy {

  @Input() set setProjectsOrCompanies(data) {
    if (data) {
        this.projectsOrCompanies = data;
        this.viewCardField = {};
        this.cardDetails = [];

        this.projectsOrCompanies.forEach(item => {
            this.module = item.module;

            if (item.module === 'companies') {
                this.module = item.module;
                item.comma = ' ';
                if (this.cardDetails.length === 0) {
                    let companyKeys = Object.keys(item);
                    this.defaultCardField.Company.forEach(ele => {
                        if (companyKeys.includes(ele)) {
                            this.cardDetails.push(ele);
                        }
                    })
                }
            }

            if (item.module === 'projects') {
                this.module = item.module;
                item.detailPlanning = 'Amount - ';
                item.comma = ' ';
                if (this.cardDetails.length === 0) {
                    let projectKeys = Object.keys(item);
                    this.defaultCardField.Project.forEach(ele => {
                        if (projectKeys.includes(ele)) {
                            this.cardDetails.push(ele);
                        }
                    })
                }
            }
        });
    }
  }


  hoveredCompanyID;
  projectsOrCompanies = [];
  module;

  cardDetails: any = [];


  defaultCardField : any = {Project: [`title`,'detailPlanning','value','comma', 'primarySector', 'comma', 'address4', 'comma', 'start'],
                            Company: ['name','address1', 'address2','comma','address3', 'comma', 'website']};
  viewCardField : any = {};


  constructor(
  ) {}

  ngOnInit(): void {
  }


  ngOnDestroy() {
  }

}
