<mat-form-field appearance="fill">
  <mat-label>Select an option</mat-label>
  <mat-select [(ngModel)]="dateFilter" (ngModelChange)="onChange($event.target)"  placeholder="_key">
    <mat-option
      *ngFor="let option of dropdownOptions | keyvalue: asIsOrder"
      [value]="option.key"
      >{{ option.value }}
    </mat-option>
  </mat-select>
</mat-form-field>
