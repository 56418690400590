<p-messages [(value)]="errorMsgs" *ngIf="errorMsgs && errorMsgs.length > 0"></p-messages>
<!-- {PREOPEN:2e841203-4c80-4f6a-aa12-40b7f76e88eb} {"sha1":"8C4A0E2E0EFD768CAB5850B359AF669A56A45AB4"} -->
<form #form="ngForm" [class.pointer-events-none]="this.showLoader">
	<!-- {OPEN:2e841203-4c80-4f6a-aa12-40b7f76e88eb} {} -->
	<!-- {OPEN:Toolbar} {} -->
	<p-toolbar>
		<div class="ui-toolbar-group-left">
			{{ pageTitle }}
		</div>
		<div class="ui-toolbar-group-right">
			<!-- {OPEN:0e5115cc-7850-4630-9f5e-93e45f1ac1e7} {"sha1":"F6E327E8A33213E329A2489C96D9EFC909A26498"} -->
			<button class="buttonStyle" pButton type="button" label="Close" icon="ui-icon-clear" [class]='["btn-tertiary"]' (click)="onCloseClick($event, searchRefine)"></button>
			<!-- {CLOSE:0e5115cc-7850-4630-9f5e-93e45f1ac1e7} {"sha1":"F6E327E8A33213E329A2489C96D9EFC909A26498"} -->
		</div>
		<div class="ui-toolbar-group-right actionBtn">
			<!-- {OPEN:1ebe672a-24e3-46a6-bcfe-3631e4cfeee4} {"sha1":"A6977FD74194BA490D28601B75556173E8953E80"} -->
				<button class="buttonStyle" pButton type="button" label="Clear all" icon="ui-icon-clear" [class]='["btn-tertiary"]' (click)="onClearAllClick($event, searchRefine)"></button>
			<!-- {CLOSE:1ebe672a-24e3-46a6-bcfe-3631e4cfeee4} {"sha1":"A6977FD74194BA490D28601B75556173E8953E80"} -->
		</div>
	</p-toolbar>
	<!-- {CLOSE:Toolbar} {} -->
	<!-- {OPEN:8fd7969e-22ca-4666-a006-8eb461105536} {} -->
	<!-- {PREOPEN:032428c8-f08d-4251-89ae-c43a19321b88} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
	<div class="ui-g form-group">
		<!-- {OPEN:032428c8-f08d-4251-89ae-c43a19321b88} {} -->
		<!-- {PREOPEN:74dbe138-f376-4607-b35b-00a60caf8bd7} {"sha1":"4674279FC445E84D87806A639FB40CFE07D3EB0A"} -->
		<div class="ui-g-12 ui-md-8">
			<!-- {OPEN:74dbe138-f376-4607-b35b-00a60caf8bd7} {} -->
			<!-- {PREOPEN:d159b00d-ffa0-4e1e-9709-46eef24c9e96} {"sha1":"65D9EB7EFFEFD5629FC3DB5289C16F0929CBA7D0"} -->
			<p-tabView orientation="left" [(activeIndex)]="activeTabIndex" (onChange)="onTabChange($event, searchRefine)">
				<!-- {OPEN:d159b00d-ffa0-4e1e-9709-46eef24c9e96} {} -->
				<!-- {PREOPEN:0adaa938-5213-442b-8e9c-59b1593d7208} {"sha1":"6656D5A5BB952A1D05A8520E2D6661394A1BEB13"} -->
				<p-tabPanel >
					<!-- {OPEN:0adaa938-5213-442b-8e9c-59b1593d7208} {} -->
					<!-- {PREOPEN:0adaa938-5213-442b-8e9c-59b1593d7208-HEADER} {"sha1":"1A0C4C94A622BFA2C25C4C4FBAC1BBB393D69DF2"} -->
					<ng-template pTemplate="header">
						<!-- {OPEN:0adaa938-5213-442b-8e9c-59b1593d7208-HEADER} {"sha1":"628071131615333C0B21C710D41F0F4E4C82AEFE"} -->
						<span>Project Text Filter</span>
						<!-- {CLOSE:0adaa938-5213-442b-8e9c-59b1593d7208-HEADER} {"sha1":"628071131615333C0B21C710D41F0F4E4C82AEFE"} -->
					</ng-template>
					<!-- {POSTCLOSE:0adaa938-5213-442b-8e9c-59b1593d7208-HEADER} {"sha1":"F9FB8641C9605E4F4F0182D5EBA4345041EA9630"} -->
					<!-- {OPEN:8af81a66-378e-45e4-8472-09dde74d4457} {} -->
					<!-- {PREOPEN:4a64738c-ce7d-4654-a082-b369275a5ad5} {"sha1":"8E47AF93A0EDFE97B55BAA3CF5DF54E9C0946D9E"} -->
					<div class="ui-g form-group">
						<!-- {OPEN:4a64738c-ce7d-4654-a082-b369275a5ad5} {} -->
						<!-- {PREOPEN:6156f683-b359-4323-9baf-96a62e7e5477} {"sha1":"FF61B4EE7559C64F5213E994CA3D7044AC4BC38D"} -->
						<div class="ui-g-12 ui-md-12">
							<!-- {OPEN:6156f683-b359-4323-9baf-96a62e7e5477} {} -->
							<!-- {OPEN:dd8b0e31-c4a0-426e-b07c-4cd8172526cb} {"sha1":"C4761824619DD32EA6B69435DCB47E54D9FB3826"} -->
							<label>Enter a word or phrase to search all fields</label>
							<!-- {CLOSE:dd8b0e31-c4a0-426e-b07c-4cd8172526cb} {"sha1":"C4761824619DD32EA6B69435DCB47E54D9FB3826"} -->
							<!-- {OPEN:8bf9e30b-a32a-405e-9cd0-cc80aa13ebb2} {"sha1":"8966F34251BA16046459078F9170ED682CD7ED8D"} -->
							<div class="ui-fluid">
								<div class="search-module">
									<div class="chips">
										<p-chips [(ngModel)]="searchRefine.project" name="Search{{ searchRefine.ObjectID }}" [addOnTab]="true" [addOnBlur]="true" [allowDuplicate]="false">
											<ng-template let-item pTemplate="item" let-index="index">
												<div class="chips-item">
													{{item}}
												</div>
											</ng-template>
										</p-chips>
									</div>
									<div class="search-icon">
										<i class="pi pi-search"></i>
									</div>
								</div>
							</div>
							<!-- {CLOSE:8bf9e30b-a32a-405e-9cd0-cc80aa13ebb2} {"sha1":"8966F34251BA16046459078F9170ED682CD7ED8D"} -->
							<!-- {CLOSE:6156f683-b359-4323-9baf-96a62e7e5477} {} -->
						</div>
						<!-- {POSTCLOSE:6156f683-b359-4323-9baf-96a62e7e5477} {"sha1":"079B33F711D32A1BDB5DD1A942BDD82885981728"} -->
						<!-- {CLOSE:4a64738c-ce7d-4654-a082-b369275a5ad5} {} -->
					</div>
					<!-- {POSTCLOSE:4a64738c-ce7d-4654-a082-b369275a5ad5} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
					<!-- {CLOSE:8af81a66-378e-45e4-8472-09dde74d4457} {} -->
					<!-- {CLOSE:0adaa938-5213-442b-8e9c-59b1593d7208} {} -->
				</p-tabPanel>
				<!-- {POSTCLOSE:0adaa938-5213-442b-8e9c-59b1593d7208} {"sha1":"FE51611D04120879F4F15959FB133DEB7EA85E40"} -->
				<!-- {PREOPEN:e794309f-c5f8-439f-b1d8-933afc22d866} {"sha1":"6656D5A5BB952A1D05A8520E2D6661394A1BEB13"} -->
				<p-tabPanel >
					<!-- {PREOPEN:e794309f-c5f8-439f-b1d8-933afc22d866-HEADER} {"sha1":"1A0C4C94A622BFA2C25C4C4FBAC1BBB393D69DF2"} -->
					<ng-template pTemplate="header">
						<!-- {OPEN:e794309f-c5f8-439f-b1d8-933afc22d866-HEADER} {"sha1":"1C15667E2CAF447CC4D867C6001EF24E8B71C62D"} -->
						<span>Stages</span>
						<!-- {CLOSE:e794309f-c5f8-439f-b1d8-933afc22d866-HEADER} {"sha1":"1C15667E2CAF447CC4D867C6001EF24E8B71C62D"} -->
					</ng-template>
					<!-- {POSTCLOSE:e794309f-c5f8-439f-b1d8-933afc22d866-HEADER} {"sha1":"F9FB8641C9605E4F4F0182D5EBA4345041EA9630"} -->
					<!-- {PREOPEN:33d0c5ca-bab2-45f0-9523-5843d2f9f369} {"sha1":"800979E4FD72AE919ACC4122702E99AB253CDA6E"} -->
					<ng-container *ngIf="stages && stages.length > 0">
						<p-treeTable #stagesTable [value]="stages" [columns]="stagesTableCols">
							<ng-template pTemplate="caption">
								<div class="ui-table-caption-container ui-helper-clearfix">
									<span class="table-caption">{{ stagesTableTitle }}</span>
									<div class="ui-table-buttons-container">
									</div>
								</div>
							</ng-template>
							<ng-template pTemplate="colgroup" let-columns>
								<colgroup>
									<col *ngFor="let col of columns" [style.width]="col.width">
								</colgroup>
							</ng-template>
							<ng-template pTemplate="header" let-columns>
								<tr>
									<th *ngFor="let col of stagesTableCols">
										{{col.header}}
									</th>
								</tr>
								<tr>
									<th *ngFor="let col of stagesTableCols">
										<input *ngIf="!col.noFilter" pInputText type="text" (input)="stagesTable.filter($event.target.value, col.field, 'contains')">
									</th>
								</tr>
								<tr>
									<th></th>
									<th>
										<oneit-checkbox name="includeAllStages" [disabled]="isExcludeAllStagesOptions" [(ngModel)]="isIncludeAllStagesOptions" (onChange)="selectAllOptions(stages , isIncludeAllStagesOptions , true)" binary="true"></oneit-checkbox>
									</th>
									<th>
										<oneit-checkbox name="excludeAllStages" [disabled]="isIncludeAllStagesOptions" [(ngModel)]="isExcludeAllStagesOptions" (onChange)="selectAllOptions(stages , isExcludeAllStagesOptions , false)" binary="true"></oneit-checkbox>
									</th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-rowNode let-rowData="rowData">
								<tr [ttRow]="rowNode">
									<td>
										<p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
										{{rowData.name}}
									</td>
									<td>
										<oneit-checkbox [disabled]="isExcludeAllStagesOptions || rowData.isExcluded" (onChange)="onTableDataCheckBoxValueChange(rowNode.node , true , 'isStage')" [(ngModel)]="rowData.isIncluded" name="IncludeStage{{ rowData.id }}" fieldLabel="Include" binary="true"></oneit-checkbox>
									</td>
									<td>
										<oneit-checkbox [disabled]="isIncludeAllStagesOptions || rowData.isIncluded" (onChange)="onTableDataCheckBoxValueChange(rowNode.node , false , 'isStage')" [(ngModel)]="rowData.isExcluded" name="ExcludeStage{{ rowData.id }}" fieldLabel="Exclude" binary="true"></oneit-checkbox>
									</td>
								</tr>
							</ng-template>
							<ng-template pTemplate="emptymessage">
								<tr>
									<td [attr.colspan]="stagesTableCols.length">No stages found.</td>
								</tr>
							</ng-template>
						</p-treeTable>
					</ng-container>
					<div class="ui-g form-group">
						<!-- {OPEN:60b82f23-fc03-4331-bac2-9409065c40a3} {} -->
						<!-- {PREOPEN:bb3a46ad-1c0c-4235-9f6f-4ee9bad06d26} {"sha1":"FF61B4EE7559C64F5213E994CA3D7044AC4BC38D"} -->
						<div class="ui-g-12 ui-md-12">
							<!-- {OPEN:bb3a46ad-1c0c-4235-9f6f-4ee9bad06d26} {} -->
							<!-- {OPEN:57400c05-3af1-45af-b4e3-f1a002308ffd} {"sha1":"9994FFCEAC971F0F45A09DC007E3C7783431ADC1"} -->
							<div [style.paddingTop.px]="'0'" [style.paddingLeft.px]="'10'" [style.paddingBottom.px]="'10'" [style.paddingRight.px]="'10'">
								<oneit-checkbox [(ngModel)]="searchRefine.Includerefusedwithdrawnabandonedandonhold" name="Includerefusedwithdrawnabandonedandonhold{{ searchRefine.ObjectID }}" fieldLabel="" binary="true" label="Include refused , withdrawn , abandoned and on hold"></oneit-checkbox>
							</div>
							<!-- {CLOSE:57400c05-3af1-45af-b4e3-f1a002308ffd} {"sha1":"9994FFCEAC971F0F45A09DC007E3C7783431ADC1"} -->
							<!-- {CLOSE:bb3a46ad-1c0c-4235-9f6f-4ee9bad06d26} {} -->
						</div>
						<!-- {POSTCLOSE:bb3a46ad-1c0c-4235-9f6f-4ee9bad06d26} {"sha1":"079B33F711D32A1BDB5DD1A942BDD82885981728"} -->
						<!-- {CLOSE:60b82f23-fc03-4331-bac2-9409065c40a3} {} -->
					</div>
					<!-- {POSTCLOSE:60b82f23-fc03-4331-bac2-9409065c40a3} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
					<!-- {CLOSE:e062579a-d0a0-4d05-a0b6-a537768589aa} {} -->
					<!-- {CLOSE:e794309f-c5f8-439f-b1d8-933afc22d866} {} -->
				</p-tabPanel>
				<!-- {POSTCLOSE:e794309f-c5f8-439f-b1d8-933afc22d866} {"sha1":"FE51611D04120879F4F15959FB133DEB7EA85E40"} -->
				<!-- {PREOPEN:a82f0672-c220-4985-b7f3-628f79869e34} {"sha1":"6656D5A5BB952A1D05A8520E2D6661394A1BEB13"} -->
				<p-tabPanel >
					<!-- {OPEN:a82f0672-c220-4985-b7f3-628f79869e34} {} -->
					<!-- {PREOPEN:a82f0672-c220-4985-b7f3-628f79869e34-HEADER} {"sha1":"1A0C4C94A622BFA2C25C4C4FBAC1BBB393D69DF2"} -->
					<ng-template pTemplate="header">
						<!-- {OPEN:a82f0672-c220-4985-b7f3-628f79869e34-HEADER} {"sha1":"63DCC2B3E42125DFB2BBA005BFFB5A31609A5358"} -->
						<span>Categories</span>
						<!-- {CLOSE:a82f0672-c220-4985-b7f3-628f79869e34-HEADER} {"sha1":"63DCC2B3E42125DFB2BBA005BFFB5A31609A5358"} -->
					</ng-template>
					<!-- {POSTCLOSE:a82f0672-c220-4985-b7f3-628f79869e34-HEADER} {"sha1":"F9FB8641C9605E4F4F0182D5EBA4345041EA9630"} -->
					<!-- {OPEN:0eedfbe8-fac3-4b8f-b98c-b8796f0daa0e} {} -->
					<!-- {PREOPEN:c2bfdace-e6c7-489d-bf2d-ae471bf9e28d} {"sha1":"8E47AF93A0EDFE97B55BAA3CF5DF54E9C0946D9E"} -->
					<div class="ui-g form-group">
						<!-- {OPEN:c2bfdace-e6c7-489d-bf2d-ae471bf9e28d} {} -->
						<!-- {PREOPEN:c88fff72-0837-47bb-93e2-ce890cd43eb0} {"sha1":"FF61B4EE7559C64F5213E994CA3D7044AC4BC38D"} -->
						<div class="ui-g-12 ui-md-12">
							<!-- {OPEN:c88fff72-0837-47bb-93e2-ce890cd43eb0} {} -->
							<!-- {OPEN:55c45895-0c82-4ca1-8de9-638a39ed2e30} {"sha1":"FD30F6F1723BA14337BA41156FA4284B80B4DFF9"} -->
							<label>Select categories required. Exclude any where you don’t want mixed use. Primary categories can be used where the category is a major part of the scheme (this will contain other minor categories)</label>
							<!-- {CLOSE:55c45895-0c82-4ca1-8de9-638a39ed2e30} {"sha1":"FD30F6F1723BA14337BA41156FA4284B80B4DFF9"} -->
							<div [style.paddingTop.px]="'10'" [style.paddingLeft.px]="'10'" [style.paddingBottom.px]="'0'" [style.paddingRight.px]="'10'">
								<!-- {PREOPEN:75109107-60a2-4936-b7cb-a4cdb02e5303} {"sha1":"A86FF51E92850BB2853BD8F3AC1BE9803B087BC0"} -->
								<ng-container *ngIf="categories && categories.length > 0">
									<p-treeTable #categoriesTable [value]="categories" [columns]="categoriesTableCols">
										<ng-template pTemplate="caption">
											<div class="ui-table-caption-container ui-helper-clearfix">
												<span class="table-caption">{{ categoriesTableTitle }}</span>
												<div class="ui-table-buttons-container">
												</div>
											</div>
										</ng-template>
										<ng-template pTemplate="colgroup" let-columns>
											<colgroup>
												<col *ngFor="let col of columns" [style.width]="col.width">
											</colgroup>
										</ng-template>
										<ng-template pTemplate="header" let-columns>
											<tr>
												<th *ngFor="let col of categoriesTableCols">
													{{col.header}}
												</th>
											</tr>
											<tr>
												<th *ngFor="let col of stagesTableCols">
													<input *ngIf="!col.noFilter" pInputText type="text" (input)="categoriesTable.filter($event.target.value, col.field, 'contains')">
												</th>
											</tr>
											<tr>
												<th></th>
												<th>
													<oneit-checkbox name="includeAllCategories" [disabled]="isExcludeAllCategoriesOptions" [(ngModel)]="isIncludeAllCategoriesOptions" (onChange)="selectAllOptions(categories , isIncludeAllCategoriesOptions , true)" binary="true"></oneit-checkbox>
												</th>
												<th>
													<oneit-checkbox name="excludeAllCategories" [disabled]="isIncludeAllCategoriesOptions" [(ngModel)]="isExcludeAllCategoriesOptions" (onChange)="selectAllOptions(categories , isExcludeAllCategoriesOptions , false)" binary="true"></oneit-checkbox>
												</th>
											</tr>
										</ng-template>
										<ng-template pTemplate="body" let-rowNode let-rowData="rowData">
											<tr [ttRow]="rowNode">
												<td>
													<p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
													{{rowData.name}}
												</td>
												<td>
													<oneit-checkbox [disabled]="isExcludeAllCategoriesOptions || rowData.isExcluded" (onChange)="onTableDataCheckBoxValueChange(rowNode.node , true , 'isCategory')" [(ngModel)]="rowData.isIncluded" name="IncludeCategory{{ rowData.id }}" fieldLabel="Include" binary="true"></oneit-checkbox>
												</td>
												<td>
													<oneit-checkbox [disabled]="isIncludeAllCategoriesOptions || rowData.isIncluded" (onChange)="onTableDataCheckBoxValueChange(rowNode.node , false , 'isCategory')" [(ngModel)]="rowData.isExcluded" name="ExcludeCategory{{ rowData.id }}" fieldLabel="Exclude" binary="true"></oneit-checkbox>
												</td>
											</tr>
										</ng-template>
										<ng-template pTemplate="emptymessage">
											<tr>
												<td [attr.colspan]="categoriesTableCols.length">No categories found.</td>
											</tr>
										</ng-template>
									</p-treeTable>
								</ng-container>
								<!-- {POSTCLOSE:75109107-60a2-4936-b7cb-a4cdb02e5303} {"sha1":"B8FD983A231171E4FFC1F1D7C7E6ED3836EEE039"} -->
							</div>
							<!-- {CLOSE:c88fff72-0837-47bb-93e2-ce890cd43eb0} {} -->
						</div>
						<!-- {POSTCLOSE:c88fff72-0837-47bb-93e2-ce890cd43eb0} {"sha1":"079B33F711D32A1BDB5DD1A942BDD82885981728"} -->
						<!-- {CLOSE:c2bfdace-e6c7-489d-bf2d-ae471bf9e28d} {} -->
					</div>
					<!-- {POSTCLOSE:c2bfdace-e6c7-489d-bf2d-ae471bf9e28d} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
					<!-- {CLOSE:0eedfbe8-fac3-4b8f-b98c-b8796f0daa0e} {} -->
					<!-- {CLOSE:a82f0672-c220-4985-b7f3-628f79869e34} {} -->
				</p-tabPanel>
				<!-- {POSTCLOSE:a82f0672-c220-4985-b7f3-628f79869e34} {"sha1":"FE51611D04120879F4F15959FB133DEB7EA85E40"} -->
				<!-- {PREOPEN:513079ca-55d0-46a2-941f-059581226eac} {"sha1":"6656D5A5BB952A1D05A8520E2D6661394A1BEB13"} -->
				<p-tabPanel >
					<!-- {OPEN:513079ca-55d0-46a2-941f-059581226eac} {} -->
					<!-- {PREOPEN:513079ca-55d0-46a2-941f-059581226eac-HEADER} {"sha1":"1A0C4C94A622BFA2C25C4C4FBAC1BBB393D69DF2"} -->
					<ng-template pTemplate="header">
						<!-- {OPEN:513079ca-55d0-46a2-941f-059581226eac-HEADER} {"sha1":"D1AD7F24B89088041D6DB361667EA67BCC49F388"} -->
						<span>Location</span>
						<!-- {CLOSE:513079ca-55d0-46a2-941f-059581226eac-HEADER} {"sha1":"D1AD7F24B89088041D6DB361667EA67BCC49F388"} -->
					</ng-template>
					<!-- {POSTCLOSE:513079ca-55d0-46a2-941f-059581226eac-HEADER} {"sha1":"F9FB8641C9605E4F4F0182D5EBA4345041EA9630"} -->
					<!-- {OPEN:63b5edc2-63cd-4f25-92cb-5c4db454f88f} {} -->
					<!-- {PREOPEN:6f1b060a-b45f-449c-9f0a-1d6eb2144473} {"sha1":"8E47AF93A0EDFE97B55BAA3CF5DF54E9C0946D9E"} -->
					<div class="ui-g form-group">
						<!-- {OPEN:6f1b060a-b45f-449c-9f0a-1d6eb2144473} {} -->
						<!-- {PREOPEN:6e6fa999-bcc7-4bbc-8e49-841e58c57aaa} {"sha1":"FF61B4EE7559C64F5213E994CA3D7044AC4BC38D"} -->
						<div class="ui-g-12 ui-md-12">
							<!-- {OPEN:6e6fa999-bcc7-4bbc-8e49-841e58c57aaa} {} -->
							<!-- {OPEN:3adc70fb-a5d3-498e-851e-ffed25ee15a4} {"sha1":"D4DADBFA050C31D69C7D92BE842D7407E626A1FE"} -->
							<label>Filter by selecting company regions and counties</label>
							<!-- {CLOSE:3adc70fb-a5d3-498e-851e-ffed25ee15a4} {"sha1":"D4DADBFA050C31D69C7D92BE842D7407E626A1FE"} -->
							<div [style.paddingTop.px]="'10'" [style.paddingLeft.px]="'10'" [style.paddingBottom.px]="'0'" [style.paddingRight.px]="'10'">
								<ng-container *ngIf="locations && locations.length > 0">
									<p-treeTable #locationsTable [columns]="locationsTableCols" [value]="locations">
										<ng-template pTemplate="caption">
											<div class="ui-table-caption-container ui-helper-clearfix">
												<span class="table-caption">{{ locationsTableTitle }}</span>
												<div class="ui-table-buttons-container">
												</div>
											</div>
										</ng-template>
										<ng-template pTemplate="colgroup" let-columns>
											<colgroup>
												<col *ngFor="let col of columns" [style.width]="col.width">
											</colgroup>
										</ng-template>
										<ng-template pTemplate="header" let-columns>
											<tr>
												<th *ngFor="let col of locationsTableCols">
													{{col.header}}
												</th>
											</tr>
											<tr>
												<th *ngFor="let col of locationsTableCols">
													<input *ngIf="!col.noFilter" pInputText type="text" (input)="locationsTable.filter($event.target.value, col.field, 'contains')">
												</th>
											</tr>
											<tr>
												<th></th>
												<th>
													<oneit-checkbox name="includeAllLocations" [disabled]="isExcludeAllLocationsOptions" [(ngModel)]="isIncludeAllLocationsOptions" (onChange)="selectAllOptions(locations , isIncludeAllLocationsOptions , true)" binary="true"></oneit-checkbox>
												</th>
												<th>
													<oneit-checkbox name="excludeAllLocations" [disabled]="isIncludeAllLocationsOptions" [(ngModel)]="isExcludeAllLocationsOptions" (onChange)="selectAllOptions(locations , isExcludeAllLocationsOptions , false)" binary="true"></oneit-checkbox>
												</th>
											</tr>
										</ng-template>
										<ng-template pTemplate="body" let-rowNode let-rowData="rowData">
											<tr [ttRow]="rowNode">
												<td>
													<p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
													{{rowData.name}}
												</td>
												<td>
													<oneit-checkbox [disabled]="isExcludeAllLocationsOptions || rowData.isExcluded" (onChange)="onTableDataCheckBoxValueChange(rowNode.node , true , 'isLocation')" [(ngModel)]="rowData.isIncluded" name="IncludeLocation{{ rowData.id }}" fieldLabel="Include" binary="true"></oneit-checkbox>
												</td>
												<td>
													<oneit-checkbox [disabled]="isIncludeAllLocationsOptions || rowData.isIncluded" (onChange)="onTableDataCheckBoxValueChange(rowNode.node , false , 'isLocation')" [(ngModel)]="rowData.isExcluded" name="ExcludeLocation{{ rowData.id }}" fieldLabel="Exclude" binary="true"></oneit-checkbox>
												</td>
											</tr>
										</ng-template>
										<ng-template pTemplate="emptymessage">
											<tr>
												<td [attr.colspan]="locationsTableCols.length">No locations found.</td>
											</tr>
										</ng-template>
									</p-treeTable>
								</ng-container>
							</div>
							<!-- {CLOSE:6e6fa999-bcc7-4bbc-8e49-841e58c57aaa} {} -->
						</div>
						<!-- {POSTCLOSE:6e6fa999-bcc7-4bbc-8e49-841e58c57aaa} {"sha1":"079B33F711D32A1BDB5DD1A942BDD82885981728"} -->
						<!-- {CLOSE:6f1b060a-b45f-449c-9f0a-1d6eb2144473} {} -->
					</div>
					<!-- {POSTCLOSE:6f1b060a-b45f-449c-9f0a-1d6eb2144473} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
					<!-- {CLOSE:63b5edc2-63cd-4f25-92cb-5c4db454f88f} {} -->
					<!-- {CLOSE:513079ca-55d0-46a2-941f-059581226eac} {} -->
				</p-tabPanel>
				<!-- {POSTCLOSE:513079ca-55d0-46a2-941f-059581226eac} {"sha1":"FE51611D04120879F4F15959FB133DEB7EA85E40"} -->
				<!-- {PREOPEN:b09c2521-a900-42f6-b722-2ace54c62593} {"sha1":"6656D5A5BB952A1D05A8520E2D6661394A1BEB13"} -->
				<p-tabPanel >
					<!-- {OPEN:b09c2521-a900-42f6-b722-2ace54c62593} {} -->
					<!-- {PREOPEN:b09c2521-a900-42f6-b722-2ace54c62593-HEADER} {"sha1":"1A0C4C94A622BFA2C25C4C4FBAC1BBB393D69DF2"} -->
					<ng-template pTemplate="header">
						<!-- {OPEN:b09c2521-a900-42f6-b722-2ace54c62593-HEADER} {"sha1":"AFB19507820520756B38D5083C266FB083394A45"} -->
						<span>Development Type</span>
						<!-- {CLOSE:b09c2521-a900-42f6-b722-2ace54c62593-HEADER} {"sha1":"AFB19507820520756B38D5083C266FB083394A45"} -->
					</ng-template>
					<!-- {POSTCLOSE:b09c2521-a900-42f6-b722-2ace54c62593-HEADER} {"sha1":"F9FB8641C9605E4F4F0182D5EBA4345041EA9630"} -->
					<!-- {OPEN:96a626d7-6666-4d8b-aecc-02dda4a11bba} {} -->
					<!-- {PREOPEN:91645e95-55fd-4dea-ae78-450c73231d61} {"sha1":"8E47AF93A0EDFE97B55BAA3CF5DF54E9C0946D9E"} -->
					<div class="ui-g form-group">
						<!-- {OPEN:91645e95-55fd-4dea-ae78-450c73231d61} {} -->
						<!-- {PREOPEN:ea5177b6-a96b-4025-a040-28536135cd69} {"sha1":"FF61B4EE7559C64F5213E994CA3D7044AC4BC38D"} -->
						<div class="ui-g-12 ui-md-12">
							<!-- {OPEN:ea5177b6-a96b-4025-a040-28536135cd69} {} -->
							<!-- {OPEN:59af2f89-2bdb-4227-a6e8-6cc1a509cb5e} {"sha1":"27F2F0383A42C8FD09561137F8414A009B9CDACD"} -->
							<label>Include or exclude by project development type</label>
							<!-- {CLOSE:59af2f89-2bdb-4227-a6e8-6cc1a509cb5e} {"sha1":"27F2F0383A42C8FD09561137F8414A009B9CDACD"} -->
							<div [style.paddingTop.px]="'10'" [style.paddingLeft.px]="'10'" [style.paddingBottom.px]="'0'" [style.paddingRight.px]="'10'">
								<ng-container *ngIf="developmentTypes && developmentTypes.length > 0">
									<p-treeTable #developmentTypesTable [columns]="developmentTypesTableCols" [value]="developmentTypes">
										<ng-template pTemplate="caption">
											<div class="ui-table-caption-container ui-helper-clearfix">
												<span class="table-caption">{{ developmentTypesTableTitle }}</span>
												<div class="ui-table-buttons-container">
												</div>
											</div>
										</ng-template>
										<ng-template pTemplate="colgroup" let-columns>
											<colgroup>
												<col *ngFor="let col of columns" [style.width]="col.width">
											</colgroup>
										</ng-template>
										<ng-template pTemplate="header" let-columns>
											<tr>
												<th *ngFor="let col of developmentTypesTableCols">
													{{col.header}}
												</th>
											</tr>
											<tr>
												<th *ngFor="let col of developmentTypesTableCols">
													<input *ngIf="!col.noFilter" pInputText type="text" (input)="developmentTypesTable.filter($event.target.value, col.field, 'contains')">
												</th>
											</tr>
											<tr>
												<th></th>
												<th>
													<oneit-checkbox name="includeAllDevelopmentTypes" [disabled]="isExcludeAllDevelopmentTypesOptions" [(ngModel)]="isIncludeAllDevelopmentTypesOptions" (onChange)="selectAllOptions(developmentTypes , isIncludeAllDevelopmentTypesOptions , true)" binary="true"></oneit-checkbox>
												</th>
												<th>
													<oneit-checkbox name="excludeAllDevelopmentTypes" [disabled]="isIncludeAllDevelopmentTypesOptions" [(ngModel)]="isExcludeAllDevelopmentTypesOptions" (onChange)="selectAllOptions(developmentTypes , isExcludeAllDevelopmentTypesOptions , false)" binary="true"></oneit-checkbox>
												</th>
											</tr>
										</ng-template>
										<ng-template pTemplate="body" let-rowNode let-rowData="rowData">
											<tr [ttRow]="rowNode">
												<td>
													<p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
													{{rowData.name}}
												</td>
												<td>
													<oneit-checkbox [disabled]="isExcludeAllDevelopmentTypesOptions || rowData.isExcluded" (onChange)="onTableDataCheckBoxValueChange(rowNode.node , true , 'isDevelopmentType')" [(ngModel)]="rowData.isIncluded" name="IncludeDevelopmentType{{ rowData.id }}" fieldLabel="Include" binary="true"></oneit-checkbox>
												</td>
												<td>
													<oneit-checkbox [disabled]="isIncludeAllDevelopmentTypesOptions || rowData.isIncluded" (onChange)="onTableDataCheckBoxValueChange(rowNode.node , false , 'isDevelopmentType')" [(ngModel)]="rowData.isExcluded" name="ExcludeDevelopmentType{{ rowData.id }}" fieldLabel="Exclude" binary="true"></oneit-checkbox>
												</td>
											</tr>
										</ng-template>
										<ng-template pTemplate="emptymessage">
											<tr>
												<td [attr.colspan]="developmentTypesTableCols.length">No development types found.</td>
											</tr>
										</ng-template>
									</p-treeTable>
								</ng-container>
							</div>
							<!-- {CLOSE:ea5177b6-a96b-4025-a040-28536135cd69} {} -->
						</div>
						<!-- {POSTCLOSE:ea5177b6-a96b-4025-a040-28536135cd69} {"sha1":"079B33F711D32A1BDB5DD1A942BDD82885981728"} -->
						<!-- {CLOSE:91645e95-55fd-4dea-ae78-450c73231d61} {} -->
					</div>
					<!-- {POSTCLOSE:91645e95-55fd-4dea-ae78-450c73231d61} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
					<!-- {CLOSE:96a626d7-6666-4d8b-aecc-02dda4a11bba} {} -->
					<!-- {CLOSE:b09c2521-a900-42f6-b722-2ace54c62593} {} -->
				</p-tabPanel>
				<!-- {POSTCLOSE:b09c2521-a900-42f6-b722-2ace54c62593} {"sha1":"FE51611D04120879F4F15959FB133DEB7EA85E40"} -->
				<!-- {PREOPEN:602c9953-ca6f-4056-a51b-4bf28929c241} {"sha1":"6656D5A5BB952A1D05A8520E2D6661394A1BEB13"} -->
				<p-tabPanel >
					<!-- {OPEN:602c9953-ca6f-4056-a51b-4bf28929c241} {} -->
					<!-- {PREOPEN:602c9953-ca6f-4056-a51b-4bf28929c241-HEADER} {"sha1":"1A0C4C94A622BFA2C25C4C4FBAC1BBB393D69DF2"} -->
					<ng-template pTemplate="header">
						<!-- {OPEN:602c9953-ca6f-4056-a51b-4bf28929c241-HEADER} {"sha1":"D0E3E1A9356D606F9147E63E91634A1FE3446EBA"} -->
						<span>Contract Type</span>
						<!-- {CLOSE:602c9953-ca6f-4056-a51b-4bf28929c241-HEADER} {"sha1":"D0E3E1A9356D606F9147E63E91634A1FE3446EBA"} -->
					</ng-template>
					<!-- {POSTCLOSE:602c9953-ca6f-4056-a51b-4bf28929c241-HEADER} {"sha1":"F9FB8641C9605E4F4F0182D5EBA4345041EA9630"} -->
					<!-- {OPEN:6b7cf11d-4042-44ee-80b5-bb5a85db4f85} {} -->
					<!-- {PREOPEN:9769940d-6040-4f61-82fd-7c02f41da655} {"sha1":"8E47AF93A0EDFE97B55BAA3CF5DF54E9C0946D9E"} -->
					<div class="ui-g form-group">
						<!-- {OPEN:9769940d-6040-4f61-82fd-7c02f41da655} {} -->
						<!-- {PREOPEN:9de7030a-45a2-49f1-b9f1-61463d8fa26c} {"sha1":"FF61B4EE7559C64F5213E994CA3D7044AC4BC38D"} -->
						<div class="ui-g-12 ui-md-12">
							<!-- {OPEN:9de7030a-45a2-49f1-b9f1-61463d8fa26c} {} -->
							<!-- {OPEN:ba4939e2-2aa4-4bd8-b008-b4e1e47d4f50} {"sha1":"416C90F4A2638D75EE923A9EC581579D575A993F"} -->
							<label>Include or exclude by project contract type</label>
							<!-- {CLOSE:ba4939e2-2aa4-4bd8-b008-b4e1e47d4f50} {"sha1":"416C90F4A2638D75EE923A9EC581579D575A993F"} -->
							<div [style.paddingTop.px]="'10'" [style.paddingLeft.px]="'10'" [style.paddingBottom.px]="'0'" [style.paddingRight.px]="'10'">
								<!-- {PREOPEN:75109107-60a2-4936-b7cb-a4cdb02e5303} {"sha1":"A86FF51E92850BB2853BD8F3AC1BE9803B087BC0"} -->
								<ng-container *ngIf="contractTypes && contractTypes.length > 0">
									<p-treeTable #contractTypesTable [value]="contractTypes" [columns]="contractTypesTableCols">
										<ng-template pTemplate="caption">
											<div class="ui-table-caption-container ui-helper-clearfix">
												<span class="table-caption">{{ contractTypesTableTitle }}</span>
												<div class="ui-table-buttons-container">
												</div>
											</div>
										</ng-template>
										<ng-template pTemplate="colgroup" let-columns>
											<colgroup>
												<col *ngFor="let col of columns" [style.width]="col.width">
											</colgroup>
										</ng-template>
										<ng-template pTemplate="header" let-columns>
											<tr>
												<th *ngFor="let col of contractTypesTableCols">
													{{col.header}}
												</th>
											</tr>
											<tr>
												<th *ngFor="let col of contractTypesTableCols">
													<input *ngIf="!col.noFilter" pInputText type="text" (input)="contractTypesTable.filter($event.target.value, col.field, 'contains')">
												</th>
											</tr>
											<tr>
												<th></th>
												<th>
													<oneit-checkbox name="includeAllContractTypes" [disabled]="isExcludeAllContractTypesOptions" [(ngModel)]="isIncludeAllContractTypesOptions" (onChange)="selectAllOptions(contractTypes , isIncludeAllContractTypesOptions , true)" binary="true"></oneit-checkbox>
												</th>
												<th>
													<oneit-checkbox name="excludeAllContractTypes" [disabled]="isIncludeAllContractTypesOptions" [(ngModel)]="isExcludeAllContractTypesOptions" (onChange)="selectAllOptions(contractTypes , isExcludeAllContractTypesOptions , false)" binary="true"></oneit-checkbox>
												</th>
											</tr>
										</ng-template>
										<ng-template pTemplate="body" let-rowNode let-rowData="rowData">
											<tr [ttRow]="rowNode">
												<td>
													<p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
													{{rowData.name}}
												</td>
												<td>
													<oneit-checkbox [disabled]="isExcludeAllContractTypesOptions || rowData.isExcluded" (onChange)="onTableDataCheckBoxValueChange(rowNode.node , true , 'isContractType')" [(ngModel)]="rowData.isIncluded" name="IncludeContractType{{ rowData.id }}" fieldLabel="Include" binary="true"></oneit-checkbox>
												</td>
												<td>
													<oneit-checkbox [disabled]="isIncludeAllContractTypesOptions || rowData.isIncluded" (onChange)="onTableDataCheckBoxValueChange(rowNode.node , false , 'isContracttType')" [(ngModel)]="rowData.isExcluded" name="ExcludeContractType{{ rowData.id }}" fieldLabel="Exclude" binary="true"></oneit-checkbox>
												</td>
											</tr>
										</ng-template>
										<ng-template pTemplate="emptymessage">
											<tr>
												<td [attr.colspan]="contractTypesTableCols.length">No contract types found.</td>
											</tr>
										</ng-template>
									</p-treeTable>
								</ng-container>
								<!-- {POSTCLOSE:75109107-60a2-4936-b7cb-a4cdb02e5303} {"sha1":"B8FD983A231171E4FFC1F1D7C7E6ED3836EEE039"} -->
							</div>
							<!-- {CLOSE:9de7030a-45a2-49f1-b9f1-61463d8fa26c} {} -->
						</div>
						<!-- {POSTCLOSE:9de7030a-45a2-49f1-b9f1-61463d8fa26c} {"sha1":"079B33F711D32A1BDB5DD1A942BDD82885981728"} -->
						<!-- {CLOSE:9769940d-6040-4f61-82fd-7c02f41da655} {} -->
					</div>
					<!-- {POSTCLOSE:9769940d-6040-4f61-82fd-7c02f41da655} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
					<!-- {CLOSE:6b7cf11d-4042-44ee-80b5-bb5a85db4f85} {} -->
					<!-- {CLOSE:602c9953-ca6f-4056-a51b-4bf28929c241} {} -->
				</p-tabPanel>
				<!-- {POSTCLOSE:602c9953-ca6f-4056-a51b-4bf28929c241} {"sha1":"FE51611D04120879F4F15959FB133DEB7EA85E40"} -->
				<!-- {PREOPEN:3cc963a9-9c53-4f3d-8eb9-5222c06cff08} {"sha1":"6656D5A5BB952A1D05A8520E2D6661394A1BEB13"} -->
				<p-tabPanel >
					<!-- {OPEN:3cc963a9-9c53-4f3d-8eb9-5222c06cff08} {} -->
					<!-- {PREOPEN:3cc963a9-9c53-4f3d-8eb9-5222c06cff08-HEADER} {"sha1":"1A0C4C94A622BFA2C25C4C4FBAC1BBB393D69DF2"} -->
					<ng-template pTemplate="header">
						<!-- {OPEN:3cc963a9-9c53-4f3d-8eb9-5222c06cff08-HEADER} {"sha1":"CEAB832EBB9082FCBB5AB55455A0224B15816F0D"} -->
						<span>Roles</span>
						<!-- {CLOSE:3cc963a9-9c53-4f3d-8eb9-5222c06cff08-HEADER} {"sha1":"CEAB832EBB9082FCBB5AB55455A0224B15816F0D"} -->
					</ng-template>
					<!-- {POSTCLOSE:3cc963a9-9c53-4f3d-8eb9-5222c06cff08-HEADER} {"sha1":"F9FB8641C9605E4F4F0182D5EBA4345041EA9630"} -->
					<!-- {OPEN:683dcc94-2efc-41dd-807e-3fe7d0b44425} {} -->
					<!-- {PREOPEN:cc72a187-7400-43bd-a9b0-59694a1cf856} {"sha1":"8E47AF93A0EDFE97B55BAA3CF5DF54E9C0946D9E"} -->
					<div class="ui-g form-group">
						<!-- {OPEN:cc72a187-7400-43bd-a9b0-59694a1cf856} {} -->
						<!-- {PREOPEN:447a2281-bbb5-41b3-977e-44099e24a923} {"sha1":"FF61B4EE7559C64F5213E994CA3D7044AC4BC38D"} -->
						<div class="ui-g-12 ui-md-12">
							<!-- {OPEN:447a2281-bbb5-41b3-977e-44099e24a923} {} -->
							<!-- {OPEN:092bd6f3-54f0-4fdd-8f45-bb75f1b36115} {"sha1":"14A5C2AE1AC02D3462C75BFE8E640BCBC9AB3107"} -->
							<label>Filter by selected roles occupied by people and companies on projects</label>
							<!-- {CLOSE:092bd6f3-54f0-4fdd-8f45-bb75f1b36115} {"sha1":"14A5C2AE1AC02D3462C75BFE8E640BCBC9AB3107"} -->
							<div [style.paddingTop.px]="'10'" [style.paddingLeft.px]="'10'" [style.paddingBottom.px]="'0'" [style.paddingRight.px]="'10'">
								<!-- {PREOPEN:75109107-60a2-4936-b7cb-a4cdb02e5303} {"sha1":"A86FF51E92850BB2853BD8F3AC1BE9803B087BC0"} -->
								<ng-container *ngIf="roles && roles.length > 0">
									<p-treeTable #rolesTable [value]="roles" [columns]="rolesTableCols">
										<ng-template pTemplate="caption">
											<div class="ui-table-caption-container ui-helper-clearfix">
												<span class="table-caption">{{ rolesTableTitle }}</span>
												<div class="ui-table-buttons-container">
												</div>
											</div>
										</ng-template>
										<ng-template pTemplate="colgroup" let-columns>
											<colgroup>
												<col *ngFor="let col of columns" [style.width]="col.width">
											</colgroup>
										</ng-template>
										<ng-template pTemplate="header" let-columns>
											<tr>
												<th *ngFor="let col of rolesTableCols">
													{{col.header}}
												</th>
											</tr>
											<tr>
												<th *ngFor="let col of rolesTableCols">
													<input *ngIf="!col.noFilter" pInputText type="text" (input)="rolesTable.filter($event.target.value, col.field, 'contains')">
												</th>
											</tr>
											<tr>
												<th></th>
												<th>
													<oneit-checkbox name="includeAllRoles" [disabled]="isExcludeAllRolesOptions" [(ngModel)]="isIncludeAllRolesOptions" (onChange)="selectAllOptions(roles , isIncludeAllRolesOptions , true)" binary="true"></oneit-checkbox>
												</th>
												<th>
													<oneit-checkbox name="excludeAllRoles" [disabled]="isIncludeAllRolesOptions" [(ngModel)]="isExcludeAllRolesOptions" (onChange)="selectAllOptions(roles , isExcludeAllRolesOptions , false)" binary="true"></oneit-checkbox>
												</th>
											</tr>
										</ng-template>
										<ng-template pTemplate="body" let-rowNode let-rowData="rowData">
											<tr [ttRow]="rowNode">
												<td>
													<p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
													{{rowData.name}}
												</td>
												<td>
													<oneit-checkbox [disabled]="isExcludeAllRolesOptions || rowData.isExcluded" (onChange)="onTableDataCheckBoxValueChange(rowNode.node , true , 'isRole')" [(ngModel)]="rowData.isIncluded" name="IncludeRole{{ rowData.id }}" fieldLabel="Include" binary="true"></oneit-checkbox>
												</td>
												<td>
													<oneit-checkbox [disabled]="isIncludeAllRolesOptions || rowData.isIncluded" (onChange)="onTableDataCheckBoxValueChange(rowNode.node , false , 'isRole')" [(ngModel)]="rowData.isExcluded" name="ExcludeRole{{ rowData.id }}" fieldLabel="Exclude" binary="true"></oneit-checkbox>
												</td>
											</tr>
										</ng-template>
										<ng-template pTemplate="emptymessage">
											<tr>
												<td [attr.colspan]="rolesTableCols.length">No roles found.</td>
											</tr>
										</ng-template>
									</p-treeTable>
								</ng-container>
								<!-- {POSTCLOSE:75109107-60a2-4936-b7cb-a4cdb02e5303} {"sha1":"B8FD983A231171E4FFC1F1D7C7E6ED3836EEE039"} -->
							</div>
							<!-- {CLOSE:447a2281-bbb5-41b3-977e-44099e24a923} {} -->
						</div>
						<!-- {POSTCLOSE:447a2281-bbb5-41b3-977e-44099e24a923} {"sha1":"079B33F711D32A1BDB5DD1A942BDD82885981728"} -->
						<!-- {CLOSE:cc72a187-7400-43bd-a9b0-59694a1cf856} {} -->
					</div>
					<!-- {POSTCLOSE:cc72a187-7400-43bd-a9b0-59694a1cf856} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
					<!-- {CLOSE:683dcc94-2efc-41dd-807e-3fe7d0b44425} {} -->
					<!-- {CLOSE:3cc963a9-9c53-4f3d-8eb9-5222c06cff08} {} -->
				</p-tabPanel>
				<!-- {POSTCLOSE:3cc963a9-9c53-4f3d-8eb9-5222c06cff08} {"sha1":"FE51611D04120879F4F15959FB133DEB7EA85E40"} -->
				<!-- {PREOPEN:4898606a-f7da-4e5b-adc4-6fa2c4f1f025} {"sha1":"6656D5A5BB952A1D05A8520E2D6661394A1BEB13"} -->
				<p-tabPanel >
					<!-- {OPEN:4898606a-f7da-4e5b-adc4-6fa2c4f1f025} {} -->
					<!-- {PREOPEN:4898606a-f7da-4e5b-adc4-6fa2c4f1f025-HEADER} {"sha1":"1A0C4C94A622BFA2C25C4C4FBAC1BBB393D69DF2"} -->
					<ng-template pTemplate="header">
						<!-- {OPEN:4898606a-f7da-4e5b-adc4-6fa2c4f1f025-HEADER} {"sha1":"0069A0989FD8A3ED0133D5E5754CB1BB7A689D87"} -->
						<span>Materials</span>
						<!-- {CLOSE:4898606a-f7da-4e5b-adc4-6fa2c4f1f025-HEADER} {"sha1":"0069A0989FD8A3ED0133D5E5754CB1BB7A689D87"} -->
					</ng-template>
					<!-- {POSTCLOSE:4898606a-f7da-4e5b-adc4-6fa2c4f1f025-HEADER} {"sha1":"F9FB8641C9605E4F4F0182D5EBA4345041EA9630"} -->
					<!-- {OPEN:755d68e8-11d1-4d06-8f5e-ee2d53ae3e34} {} -->
					<!-- {PREOPEN:ea7a2fd5-000a-459b-b5bc-b4ed66d1f035} {"sha1":"8E47AF93A0EDFE97B55BAA3CF5DF54E9C0946D9E"} -->
					<div class="ui-g form-group">
						<!-- {OPEN:ea7a2fd5-000a-459b-b5bc-b4ed66d1f035} {} -->
						<!-- {PREOPEN:65b52219-d58f-4557-8ada-e4b98102efc4} {"sha1":"FF61B4EE7559C64F5213E994CA3D7044AC4BC38D"} -->
						<div class="ui-g-12 ui-md-12">
							<!-- {OPEN:65b52219-d58f-4557-8ada-e4b98102efc4} {} -->
							<!-- {OPEN:1c50609e-1328-4200-925f-f732c1ed5a70} {"sha1":"FBFFB5B3FA0B3F087368715BDA81F0B2EC1A6BC2"} -->
							<label>Filter by materials specified on projects</label>
							<!-- {CLOSE:1c50609e-1328-4200-925f-f732c1ed5a70} {"sha1":"FBFFB5B3FA0B3F087368715BDA81F0B2EC1A6BC2"} -->
							<div [style.paddingTop.px]="'10'" [style.paddingLeft.px]="'10'" [style.paddingBottom.px]="'0'" [style.paddingRight.px]="'10'">
								<!-- {PREOPEN:75109107-60a2-4936-b7cb-a4cdb02e5303} {"sha1":"A86FF51E92850BB2853BD8F3AC1BE9803B087BC0"} -->
								<ng-container *ngIf="materials && materials.length > 0">
									<p-treeTable #materialsTable [value]="materials" [columns]="materialsTableCols">
										<ng-template pTemplate="caption">
											<div class="ui-table-caption-container ui-helper-clearfix">
												<span class="table-caption">{{ materialsTableTitle }}</span>
												<div class="ui-table-buttons-container">
												</div>
											</div>
										</ng-template>
										<ng-template pTemplate="colgroup" let-columns>
											<colgroup>
												<col *ngFor="let col of columns" [style.width]="col.width">
											</colgroup>
										</ng-template>
										<ng-template pTemplate="header" let-columns>
											<tr>
												<th *ngFor="let col of materialsTableCols">
													{{col.header}}
												</th>
											</tr>
											<tr>
												<th *ngFor="let col of materialsTableCols">
													<input *ngIf="!col.noFilter" pInputText type="text" (input)="materialsTable.filter($event.target.value, col.field, 'contains')">
												</th>
											</tr>
											<tr>
												<th></th>
												<th>
													<oneit-checkbox name="includeAllMaterial" [(ngModel)]="isIncludeAllMaterialsOptions" (onChange)="selectAllOptions(materials , isIncludeAllMaterialsOptions , true)" binary="true"></oneit-checkbox>
												</th>
											</tr>
										</ng-template>
										<ng-template pTemplate="body" let-rowNode let-rowData="rowData">
											<tr [ttRow]="rowNode">
												<td>
													<p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
													{{rowData.name}}
												</td>
												<td>
													<oneit-checkbox [disabled]="rowData.isExcluded" (onChange)="onTableDataCheckBoxValueChange(rowNode.node , true , 'isMaterial')" [(ngModel)]="rowData.isIncluded" name="IncludeMaterial{{ rowData.id }}" fieldLabel="Include" binary="true"></oneit-checkbox>
												</td>
											</tr>
										</ng-template>
										<ng-template pTemplate="emptymessage">
											<tr>
												<td [attr.colspan]="materialsTableCols.length">No materials found.</td>
											</tr>
										</ng-template>
									</p-treeTable>
								</ng-container>
								<!-- {POSTCLOSE:75109107-60a2-4936-b7cb-a4cdb02e5303} {"sha1":"B8FD983A231171E4FFC1F1D7C7E6ED3836EEE039"} -->
							</div>
							<!-- {CLOSE:65b52219-d58f-4557-8ada-e4b98102efc4} {} -->
						</div>
						<!-- {POSTCLOSE:65b52219-d58f-4557-8ada-e4b98102efc4} {"sha1":"079B33F711D32A1BDB5DD1A942BDD82885981728"} -->
						<!-- {CLOSE:ea7a2fd5-000a-459b-b5bc-b4ed66d1f035} {} -->
					</div>
					<!-- {POSTCLOSE:ea7a2fd5-000a-459b-b5bc-b4ed66d1f035} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
					<!-- {CLOSE:755d68e8-11d1-4d06-8f5e-ee2d53ae3e34} {} -->
					<!-- {CLOSE:4898606a-f7da-4e5b-adc4-6fa2c4f1f025} {} -->
				</p-tabPanel>
				<!-- {POSTCLOSE:4898606a-f7da-4e5b-adc4-6fa2c4f1f025} {"sha1":"FE51611D04120879F4F15959FB133DEB7EA85E40"} -->
				<!-- {PREOPEN:3a90e39c-f5a8-4bf2-aeda-d25b22387954} {"sha1":"6656D5A5BB952A1D05A8520E2D6661394A1BEB13"} -->
				<p-tabPanel >
					<!-- {OPEN:3a90e39c-f5a8-4bf2-aeda-d25b22387954} {} -->
					<!-- {PREOPEN:3a90e39c-f5a8-4bf2-aeda-d25b22387954-HEADER} {"sha1":"1A0C4C94A622BFA2C25C4C4FBAC1BBB393D69DF2"} -->
					<ng-template pTemplate="header">
						<!-- {OPEN:3a90e39c-f5a8-4bf2-aeda-d25b22387954-HEADER} {"sha1":"0EE5764C10F31D9FDC1F1B0718271B3F774F3413"} -->
						<span>Funding</span>
						<!-- {CLOSE:3a90e39c-f5a8-4bf2-aeda-d25b22387954-HEADER} {"sha1":"0EE5764C10F31D9FDC1F1B0718271B3F774F3413"} -->
					</ng-template>
					<!-- {POSTCLOSE:3a90e39c-f5a8-4bf2-aeda-d25b22387954-HEADER} {"sha1":"F9FB8641C9605E4F4F0182D5EBA4345041EA9630"} -->
					<!-- {OPEN:654902be-aedc-4a21-87c3-a94e1bb29591} {} -->
					<!-- {PREOPEN:1bf4bb20-9f1c-4258-af11-71ec3f4f3264} {"sha1":"8E47AF93A0EDFE97B55BAA3CF5DF54E9C0946D9E"} -->
					<div class="ui-g form-group">
						<!-- {OPEN:1bf4bb20-9f1c-4258-af11-71ec3f4f3264} {} -->
						<!-- {PREOPEN:f3f6cb8b-17e2-4e78-8bca-ec36ff4f0c34} {"sha1":"FF61B4EE7559C64F5213E994CA3D7044AC4BC38D"} -->
						<div class="ui-g-12 ui-md-12">
							<!-- {OPEN:f3f6cb8b-17e2-4e78-8bca-ec36ff4f0c34} {} -->
							<!-- {OPEN:cf857e50-403b-4f57-831b-509d9d4910e5} {"sha1":"932DD11214B8D6331B0BC4641314322A6EC7F27E"} -->
							<label>Include or exclude by Funding type</label>
							<!-- {CLOSE:cf857e50-403b-4f57-831b-509d9d4910e5} {"sha1":"932DD11214B8D6331B0BC4641314322A6EC7F27E"} -->
							<div [style.paddingTop.px]="'10'" [style.paddingLeft.px]="'10'" [style.paddingBottom.px]="'0'" [style.paddingRight.px]="'10'">
								<!-- {PREOPEN:75109107-60a2-4936-b7cb-a4cdb02e5303} {"sha1":"A86FF51E92850BB2853BD8F3AC1BE9803B087BC0"} -->
								<ng-container *ngIf="funding && funding.length > 0">
									<p-treeTable #fundingTable [value]="funding" [columns]="fundingsTableCols">
										<ng-template pTemplate="caption">
											<div class="ui-table-caption-container ui-helper-clearfix">
												<span class="table-caption">{{ fundingsTableTitle }}</span>
												<div class="ui-table-buttons-container">
												</div>
											</div>
										</ng-template>
										<ng-template pTemplate="colgroup" let-columns>
											<colgroup>
												<col *ngFor="let col of columns" [style.width]="col.width">
											</colgroup>
										</ng-template>
										<ng-template pTemplate="header" let-columns>
											<tr>
												<th *ngFor="let col of fundingsTableCols">
													{{col.header}}
												</th>
											</tr>
											<tr>
												<th *ngFor="let col of fundingsTableCols">
													<input *ngIf="!col.noFilter" pInputText type="text" (input)="fundingTable.filter($event.target.value, col.field, 'contains')">
												</th>
											</tr>
											<tr>
												<th></th>
												<th>
													<oneit-checkbox name="includeAllFunding" [disabled]="isExcludeAllFundingOptions" [(ngModel)]="isIncludeAllFundingOptions" (onChange)="selectAllOptions(funding , isIncludeAllFundingOptions , true)" binary="true"></oneit-checkbox>
												</th>
												<th>
													<oneit-checkbox name="excludeAllFunding" [disabled]="isIncludeAllFundingOptions" [(ngModel)]="isExcludeAllFundingOptions" (onChange)="selectAllOptions(funding , isExcludeAllFundingOptions , false)" binary="true"></oneit-checkbox>
												</th>
											</tr>
										</ng-template>
										<ng-template pTemplate="body" let-rowNode let-rowData="rowData">
											<tr [ttRow]="rowNode">
												<td>
													<p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
													{{rowData.name}}
												</td>
												<td>
													<oneit-checkbox [disabled]="isExcludeAllFundingOptions || rowData.isExcluded" (onChange)="onTableDataCheckBoxValueChange(rowNode.node , true , 'isFunding')" [(ngModel)]="rowData.isIncluded" name="IncludeFunding{{ rowData.id }}" fieldLabel="Include" binary="true"></oneit-checkbox>
												</td>
												<td>
													<oneit-checkbox [disabled]="isIncludeAllFundingOptions || rowData.isIncluded" (onChange)="onTableDataCheckBoxValueChange(rowNode.node , false , 'isFunding')" [(ngModel)]="rowData.isExcluded" name="ExcludeFunding{{ rowData.id }}" fieldLabel="Exclude" binary="true"></oneit-checkbox>
												</td>
											</tr>
										</ng-template>
										<ng-template pTemplate="emptymessage">
											<tr>
												<td [attr.colspan]="fundingsTableCols.length">No fundings found.</td>
											</tr>
										</ng-template>
									</p-treeTable>
								</ng-container>
								<!-- {POSTCLOSE:75109107-60a2-4936-b7cb-a4cdb02e5303} {"sha1":"B8FD983A231171E4FFC1F1D7C7E6ED3836EEE039"} -->
							</div>
							<!-- {CLOSE:f3f6cb8b-17e2-4e78-8bca-ec36ff4f0c34} {} -->
						</div>
						<!-- {POSTCLOSE:f3f6cb8b-17e2-4e78-8bca-ec36ff4f0c34} {"sha1":"079B33F711D32A1BDB5DD1A942BDD82885981728"} -->
						<!-- {CLOSE:1bf4bb20-9f1c-4258-af11-71ec3f4f3264} {} -->
					</div>
					<!-- {POSTCLOSE:1bf4bb20-9f1c-4258-af11-71ec3f4f3264} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
					<!-- {CLOSE:654902be-aedc-4a21-87c3-a94e1bb29591} {} -->
					<!-- {CLOSE:3a90e39c-f5a8-4bf2-aeda-d25b22387954} {} -->
				</p-tabPanel>
				<!-- {POSTCLOSE:3a90e39c-f5a8-4bf2-aeda-d25b22387954} {"sha1":"FE51611D04120879F4F15959FB133DEB7EA85E40"} -->
				<!-- {PREOPEN:042cd5b2-c792-4d57-895e-d5686f9c297f} {"sha1":"6656D5A5BB952A1D05A8520E2D6661394A1BEB13"} -->
				<p-tabPanel >
					<!-- {OPEN:042cd5b2-c792-4d57-895e-d5686f9c297f} {} -->
					<!-- {PREOPEN:042cd5b2-c792-4d57-895e-d5686f9c297f-HEADER} {"sha1":"1A0C4C94A622BFA2C25C4C4FBAC1BBB393D69DF2"} -->
					<ng-template pTemplate="header">
						<!-- {OPEN:042cd5b2-c792-4d57-895e-d5686f9c297f-HEADER} {"sha1":"0B85D4EFA27B7079AD83BA9A036634C4724C37D4"} -->
						<span>Date Filters</span>
						<!-- {CLOSE:042cd5b2-c792-4d57-895e-d5686f9c297f-HEADER} {"sha1":"0B85D4EFA27B7079AD83BA9A036634C4724C37D4"} -->
					</ng-template>
					<!-- {POSTCLOSE:042cd5b2-c792-4d57-895e-d5686f9c297f-HEADER} {"sha1":"F9FB8641C9605E4F4F0182D5EBA4345041EA9630"} -->
					<!-- {OPEN:df82df3d-4a0e-4202-9a71-5760d9533da7} {} -->
					<!-- {PREOPEN:7a284d3d-d4f5-4748-b193-730506fff73e} {"sha1":"8E47AF93A0EDFE97B55BAA3CF5DF54E9C0946D9E"} -->
					<div class="ui-g form-group">
						<!-- {OPEN:7a284d3d-d4f5-4748-b193-730506fff73e} {} -->
						<!-- {PREOPEN:4a46eb45-9064-4432-8ef9-5bc1633ad24f} {"sha1":"3B317DCCC34E0EC4DC09E176381330E9940AAD2E"} -->
						<div class="ui-g-12 ui-md-6">
							<!-- {OPEN:4a46eb45-9064-4432-8ef9-5bc1633ad24f} {} -->
							<!-- {OPEN:b04d5528-3584-49a4-9111-cca5e1c5f129} {"sha1":"3BFA9BF1052C88B822AF40722CCA697C76E1F096"} -->
							<div class="ui-fluid">
							<span class="ui-float-label sticky">
								<p-calendar [(ngModel)]="searchRefine.StartDate" name="StartDate{{ searchRefine.ObjectID }}" fieldLabel="Start Date" [showIcon]="true" dataType="string" [dateFormat]="'yy-mm-dd'" [view]="'date'" [showTime]="false" [timeOnly]="false"></p-calendar>
								<label>Start Date</label>
							</span>
							</div>
							<!-- {CLOSE:b04d5528-3584-49a4-9111-cca5e1c5f129} {"sha1":"3BFA9BF1052C88B822AF40722CCA697C76E1F096"} -->
							<!-- {CLOSE:4a46eb45-9064-4432-8ef9-5bc1633ad24f} {} -->
						</div>
						<!-- {POSTCLOSE:4a46eb45-9064-4432-8ef9-5bc1633ad24f} {"sha1":"079B33F711D32A1BDB5DD1A942BDD82885981728"} -->
						<!-- {PREOPEN:242ff972-4106-4fb8-8dc3-ebb4a46a7cbf} {"sha1":"3B317DCCC34E0EC4DC09E176381330E9940AAD2E"} -->
						<div class="ui-g-12 ui-md-6">
							<!-- {OPEN:242ff972-4106-4fb8-8dc3-ebb4a46a7cbf} {"sha1":"3EAEE93D661790A598B3ABB8F6AA28ABB45A097D"} -->
							<!-- {CLOSE:242ff972-4106-4fb8-8dc3-ebb4a46a7cbf} {"sha1":"3EAEE93D661790A598B3ABB8F6AA28ABB45A097D"} -->
						</div>
						<!-- {POSTCLOSE:242ff972-4106-4fb8-8dc3-ebb4a46a7cbf} {"sha1":"079B33F711D32A1BDB5DD1A942BDD82885981728"} -->
						<!-- {CLOSE:7a284d3d-d4f5-4748-b193-730506fff73e} {} -->
					</div>
					<!-- {POSTCLOSE:7a284d3d-d4f5-4748-b193-730506fff73e} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
					<!-- {PREOPEN:71764d5e-f31a-4d52-8e27-1c7a4a339c09} {"sha1":"8E47AF93A0EDFE97B55BAA3CF5DF54E9C0946D9E"} -->
					<div class="ui-g form-group">
						<!-- {OPEN:71764d5e-f31a-4d52-8e27-1c7a4a339c09} {} -->
						<!-- {PREOPEN:cfe92c7c-ad82-421f-b675-b1d2c7ec2596} {"sha1":"3B317DCCC34E0EC4DC09E176381330E9940AAD2E"} -->
						<div class="ui-g-12 ui-md-6">
							<!-- {OPEN:cfe92c7c-ad82-421f-b675-b1d2c7ec2596} {} -->
							<!-- {OPEN:151b231c-74d4-4416-a459-717f9fc7470e} {"sha1":"D88640E56287F303792F83D665735B0DB4C2CC68"} -->
							<div class="ui-fluid">
							<span class="ui-float-label sticky">
								<p-calendar [(ngModel)]="searchRefine.EndDate" name="EndDate{{ searchRefine.ObjectID }}" fieldLabel="End Date" [showIcon]="true" dataType="string" [dateFormat]="'yy-mm-dd'" [view]="'date'" [showTime]="false" [timeOnly]="false"></p-calendar>
								<label>End Date</label>
							</span>
							</div>
							<!-- {CLOSE:151b231c-74d4-4416-a459-717f9fc7470e} {"sha1":"D88640E56287F303792F83D665735B0DB4C2CC68"} -->
							<!-- {CLOSE:cfe92c7c-ad82-421f-b675-b1d2c7ec2596} {} -->
						</div>
						<!-- {POSTCLOSE:cfe92c7c-ad82-421f-b675-b1d2c7ec2596} {"sha1":"079B33F711D32A1BDB5DD1A942BDD82885981728"} -->
						<!-- {PREOPEN:77a032cf-9e90-4c8f-9859-cfe3bdd7f790} {"sha1":"3B317DCCC34E0EC4DC09E176381330E9940AAD2E"} -->
						<div class="ui-g-12 ui-md-6">
							<!-- {OPEN:77a032cf-9e90-4c8f-9859-cfe3bdd7f790} {"sha1":"3EAEE93D661790A598B3ABB8F6AA28ABB45A097D"} -->
							<!-- {CLOSE:77a032cf-9e90-4c8f-9859-cfe3bdd7f790} {"sha1":"3EAEE93D661790A598B3ABB8F6AA28ABB45A097D"} -->
						</div>
						<!-- {POSTCLOSE:77a032cf-9e90-4c8f-9859-cfe3bdd7f790} {"sha1":"079B33F711D32A1BDB5DD1A942BDD82885981728"} -->
						<!-- {CLOSE:71764d5e-f31a-4d52-8e27-1c7a4a339c09} {} -->
					</div>
					<!-- {POSTCLOSE:71764d5e-f31a-4d52-8e27-1c7a4a339c09} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
					<!-- {PREOPEN:110dd9fa-e871-4ce3-ab8b-fc92eded0e64} {"sha1":"8E47AF93A0EDFE97B55BAA3CF5DF54E9C0946D9E"} -->
					<div class="ui-g form-group">
						<!-- {OPEN:110dd9fa-e871-4ce3-ab8b-fc92eded0e64} {} -->
						<!-- {PREOPEN:959438ac-8541-425d-ae48-6d6fc580a211} {"sha1":"3B317DCCC34E0EC4DC09E176381330E9940AAD2E"} -->
						<div class="ui-g-12 ui-md-6">
							<!-- {OPEN:959438ac-8541-425d-ae48-6d6fc580a211} {} -->
							<!-- {OPEN:cc554419-1283-4b5f-a549-e693c6abc7ac} {"sha1":"A2CA80B829006173FC3BDD9E387B9F5F3D8E2362"} -->
							<div class="ui-fluid">
							<span class="ui-float-label sticky">
								<p-calendar [(ngModel)]="searchRefine.TenderCloseDate" name="TenderCloseDate{{ searchRefine.ObjectID }}" fieldLabel="Tender Close Date" [showIcon]="true" dataType="string" [dateFormat]="'yy-mm-dd'" [view]="'date'" [showTime]="false" [timeOnly]="false"></p-calendar>
								<label>Tender Close Date</label>
							</span>
							</div>
							<!-- {CLOSE:cc554419-1283-4b5f-a549-e693c6abc7ac} {"sha1":"A2CA80B829006173FC3BDD9E387B9F5F3D8E2362"} -->
							<!-- {CLOSE:959438ac-8541-425d-ae48-6d6fc580a211} {} -->
						</div>
						<!-- {POSTCLOSE:959438ac-8541-425d-ae48-6d6fc580a211} {"sha1":"079B33F711D32A1BDB5DD1A942BDD82885981728"} -->
						<!-- {PREOPEN:12220a40-378e-44be-ab6a-6151551a91a0} {"sha1":"3B317DCCC34E0EC4DC09E176381330E9940AAD2E"} -->
						<div class="ui-g-12 ui-md-6">
							<!-- {OPEN:12220a40-378e-44be-ab6a-6151551a91a0} {"sha1":"3EAEE93D661790A598B3ABB8F6AA28ABB45A097D"} -->
							<!-- {CLOSE:12220a40-378e-44be-ab6a-6151551a91a0} {"sha1":"3EAEE93D661790A598B3ABB8F6AA28ABB45A097D"} -->
						</div>
						<!-- {POSTCLOSE:12220a40-378e-44be-ab6a-6151551a91a0} {"sha1":"079B33F711D32A1BDB5DD1A942BDD82885981728"} -->
						<!-- {CLOSE:110dd9fa-e871-4ce3-ab8b-fc92eded0e64} {} -->
					</div>
					<!-- {POSTCLOSE:110dd9fa-e871-4ce3-ab8b-fc92eded0e64} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
					<!-- {CLOSE:df82df3d-4a0e-4202-9a71-5760d9533da7} {} -->
					<!-- {CLOSE:042cd5b2-c792-4d57-895e-d5686f9c297f} {} -->
				</p-tabPanel>
				<!-- {POSTCLOSE:042cd5b2-c792-4d57-895e-d5686f9c297f} {"sha1":"FE51611D04120879F4F15959FB133DEB7EA85E40"} -->
				<!-- {PREOPEN:47dcfda8-00bb-460b-8b00-5d6c7ac4ccec} {"sha1":"6656D5A5BB952A1D05A8520E2D6661394A1BEB13"} -->
				<p-tabPanel >
					<!-- {OPEN:47dcfda8-00bb-460b-8b00-5d6c7ac4ccec} {} -->
					<!-- {PREOPEN:47dcfda8-00bb-460b-8b00-5d6c7ac4ccec-HEADER} {"sha1":"1A0C4C94A622BFA2C25C4C4FBAC1BBB393D69DF2"} -->
					<ng-template pTemplate="header">
						<!-- {OPEN:47dcfda8-00bb-460b-8b00-5d6c7ac4ccec-HEADER} {"sha1":"BB961BC8F2CB415AC3D98AD415CABBE5B63A3485"} -->
						<span>Measurement Filters</span>
						<!-- {CLOSE:47dcfda8-00bb-460b-8b00-5d6c7ac4ccec-HEADER} {"sha1":"BB961BC8F2CB415AC3D98AD415CABBE5B63A3485"} -->
					</ng-template>
					<!-- {POSTCLOSE:47dcfda8-00bb-460b-8b00-5d6c7ac4ccec-HEADER} {"sha1":"F9FB8641C9605E4F4F0182D5EBA4345041EA9630"} -->
					<!-- {OPEN:0865ad36-35ab-4367-869a-e25cfef78b0c} {} -->
					<!-- {PREOPEN:868e0d6f-c703-4e04-ba69-2c2b4b00e1e6} {"sha1":"8E47AF93A0EDFE97B55BAA3CF5DF54E9C0946D9E"} -->
					<div class="ui-g form-group">
						<!-- {OPEN:868e0d6f-c703-4e04-ba69-2c2b4b00e1e6} {} -->
						<!-- {PREOPEN:26a1872e-2b2c-488c-b229-4adaf8f34c5f} {"sha1":"FF61B4EE7559C64F5213E994CA3D7044AC4BC38D"} -->
						<div class="ui-g-12 ui-md-12">
							<!-- {OPEN:26a1872e-2b2c-488c-b229-4adaf8f34c5f} {} -->
							<!-- {OPEN:d50e82e9-9bfe-4f14-8ea0-28c1d76e0780} {"sha1":"2CE8CC117A8E4896C6399F04B24AEADC1AC6C2E0"} -->
							<div [style.paddingTop.px]="'10'" [style.paddingLeft.px]="'19'" [style.paddingBottom.px]="'0'" [style.paddingRight.px]="'10'">
								<label>Value(£ )</label>
							</div>
							<!-- {CLOSE:d50e82e9-9bfe-4f14-8ea0-28c1d76e0780} {"sha1":"2CE8CC117A8E4896C6399F04B24AEADC1AC6C2E0"} -->
							<!-- {OPEN:0fc0216b-9059-4077-980a-8f21e2e3d1b9} {} -->
							<!-- {PREOPEN:fd39b2d2-01c3-4a0b-9fc8-700485e61e20} {"sha1":"7A6F26598C30D744924FDB7D249A2044024FC793"} -->
							<div class="ui-g form-group">
								<!-- {OPEN:fd39b2d2-01c3-4a0b-9fc8-700485e61e20} {} -->
								<!-- {PREOPEN:1531f44e-1804-46b4-bb1c-7ea13f01765c} {"sha1":"01CE1E502A7DFF35BA95CABA39EEB72245DA74D0"} -->
								<div class="ui-g-12 ui-md-6">
									<!-- {OPEN:1531f44e-1804-46b4-bb1c-7ea13f01765c} {} -->
									<!-- {OPEN:7ccf8c98-02a6-4c17-94f6-24184a22b455} {"sha1":"15990B180FFA54AA36998F936ADAB913364229D1"} -->
									<div class="ui-fluid">
										<input pKeyFilter="pint" [(ngModel)]="searchRefine.minValue" name="minValue{{ searchRefine.ObjectID }}" fieldLabel="Value(£ )" type="text" pInputText class="ui-inputtext" placeholder="min">
									</div>
									<!-- {CLOSE:7ccf8c98-02a6-4c17-94f6-24184a22b455} {"sha1":"15990B180FFA54AA36998F936ADAB913364229D1"} -->
									<!-- {CLOSE:1531f44e-1804-46b4-bb1c-7ea13f01765c} {} -->
								</div>
								<!-- {POSTCLOSE:1531f44e-1804-46b4-bb1c-7ea13f01765c} {"sha1":"A381A66D660AB311749EF880057D27479B1166BB"} -->
								<!-- {PREOPEN:6612a8dd-f39f-4f15-a5c9-6c7e1b21b431} {"sha1":"01CE1E502A7DFF35BA95CABA39EEB72245DA74D0"} -->
								<div class="ui-g-12 ui-md-6">
									<!-- {OPEN:6612a8dd-f39f-4f15-a5c9-6c7e1b21b431} {} -->
									<!-- {OPEN:05242640-df56-41fe-810e-43f1feb8faa9} {"sha1":"EE585A153566F5F2EC220C2698FCE3AFAD5AB541"} -->
									<div class="ui-fluid">
										<input pKeyFilter="pint" [class.ng-invalid]="searchRefine?.maxValue < searchRefine?.minValue" [(ngModel)]="searchRefine.maxValue" name="maxValue{{ searchRefine.ObjectID }}" fieldLabel="Value(£ )" type="text" pInputText class="ui-inputtext" placeholder="max">
									</div>
									<!-- {CLOSE:05242640-df56-41fe-810e-43f1feb8faa9} {"sha1":"EE585A153566F5F2EC220C2698FCE3AFAD5AB541"} -->
									<!-- {CLOSE:6612a8dd-f39f-4f15-a5c9-6c7e1b21b431} {} -->
								</div>
								<!-- {POSTCLOSE:6612a8dd-f39f-4f15-a5c9-6c7e1b21b431} {"sha1":"A381A66D660AB311749EF880057D27479B1166BB"} -->
								<!-- {CLOSE:fd39b2d2-01c3-4a0b-9fc8-700485e61e20} {} -->
							</div>
							<!-- {POSTCLOSE:fd39b2d2-01c3-4a0b-9fc8-700485e61e20} {"sha1":"549A6971B2AF0C164CB00BC5DEDF452C05B5D75E"} -->
							<!-- {CLOSE:0fc0216b-9059-4077-980a-8f21e2e3d1b9} {} -->
							<!-- {OPEN:2074033a-aa65-4ec7-a577-be46ed914215} {"sha1":"67E6F53D8D61EA5E4DA75B82E73FDF5F6D4DF1C3"} -->
							<div [style.paddingTop.px]="'10'" [style.paddingLeft.px]="'19'" [style.paddingBottom.px]="'0'" [style.paddingRight.px]="'10'">
								<label>Units</label>
							</div>
							<!-- {CLOSE:2074033a-aa65-4ec7-a577-be46ed914215} {"sha1":"67E6F53D8D61EA5E4DA75B82E73FDF5F6D4DF1C3"} -->
							<!-- {OPEN:cae2bc22-11a0-4ef9-aae1-646c42d7af56} {} -->
							<!-- {PREOPEN:580f38d0-a2dc-4c57-8929-caf550916c07} {"sha1":"7A6F26598C30D744924FDB7D249A2044024FC793"} -->
							<div class="ui-g form-group">
								<!-- {OPEN:580f38d0-a2dc-4c57-8929-caf550916c07} {} -->
								<!-- {PREOPEN:5e8c7cde-ed2c-4d00-98f6-0573934542cb} {"sha1":"01CE1E502A7DFF35BA95CABA39EEB72245DA74D0"} -->
								<div class="ui-g-12 ui-md-6">
									<!-- {OPEN:5e8c7cde-ed2c-4d00-98f6-0573934542cb} {} -->
									<!-- {OPEN:45a618bb-c7c2-4424-9100-11044eecdba9} {"sha1":"9D7DC3D4C206ADF97003908048339D0AD542EEDC"} -->
									<div class="ui-fluid">
										<input pKeyFilter="pint" [(ngModel)]="searchRefine.minUnits" name="minUnits{{ searchRefine.ObjectID }}" fieldLabel="Units" type="text" pInputText class="ui-inputtext" placeholder="min">
									</div>
									<!-- {CLOSE:45a618bb-c7c2-4424-9100-11044eecdba9} {"sha1":"9D7DC3D4C206ADF97003908048339D0AD542EEDC"} -->
									<!-- {CLOSE:5e8c7cde-ed2c-4d00-98f6-0573934542cb} {} -->
								</div>
								<!-- {POSTCLOSE:5e8c7cde-ed2c-4d00-98f6-0573934542cb} {"sha1":"A381A66D660AB311749EF880057D27479B1166BB"} -->
								<!-- {PREOPEN:19808d3f-009e-4a05-97dc-b0532594fa0b} {"sha1":"01CE1E502A7DFF35BA95CABA39EEB72245DA74D0"} -->
								<div class="ui-g-12 ui-md-6">
									<!-- {OPEN:19808d3f-009e-4a05-97dc-b0532594fa0b} {} -->
									<!-- {OPEN:1ccc8825-ff0a-4dce-8cf1-11dd9ef76d58} {"sha1":"46064C5C1E8C400DC3480F7E32DA6FEDDB99116C"} -->
									<div class="ui-fluid">
										<input pKeyFilter="pint" [(ngModel)]="searchRefine.maxUnits" name="maxUnits{{ searchRefine.ObjectID }}" fieldLabel="Units" type="text" pInputText class="ui-inputtext" placeholder="max">
									</div>
									<!-- {CLOSE:1ccc8825-ff0a-4dce-8cf1-11dd9ef76d58} {"sha1":"46064C5C1E8C400DC3480F7E32DA6FEDDB99116C"} -->
									<!-- {CLOSE:19808d3f-009e-4a05-97dc-b0532594fa0b} {} -->
								</div>
								<!-- {POSTCLOSE:19808d3f-009e-4a05-97dc-b0532594fa0b} {"sha1":"A381A66D660AB311749EF880057D27479B1166BB"} -->
								<!-- {CLOSE:580f38d0-a2dc-4c57-8929-caf550916c07} {} -->
							</div>
							<!-- {POSTCLOSE:580f38d0-a2dc-4c57-8929-caf550916c07} {"sha1":"549A6971B2AF0C164CB00BC5DEDF452C05B5D75E"} -->
							<!-- {CLOSE:cae2bc22-11a0-4ef9-aae1-646c42d7af56} {} -->
							<!-- {OPEN:4e974cbf-465c-4d14-97b8-2edff52fb68c} {"sha1":"70519A0AC820BD236F9CD6C563C05E043B303F4C"} -->
							<div [style.paddingTop.px]="'10'" [style.paddingLeft.px]="'19'" [style.paddingBottom.px]="'0'" [style.paddingRight.px]="'10'">
								<label>Total Floor Area(m2)</label>
							</div>
							<!-- {CLOSE:4e974cbf-465c-4d14-97b8-2edff52fb68c} {"sha1":"70519A0AC820BD236F9CD6C563C05E043B303F4C"} -->
							<!-- {OPEN:fb9aa50e-1d8a-4629-9999-aff4194d7372} {} -->
							<!-- {PREOPEN:05bc977b-a88e-4711-b558-00b6320d67a6} {"sha1":"7A6F26598C30D744924FDB7D249A2044024FC793"} -->
							<div class="ui-g form-group">
								<!-- {OPEN:05bc977b-a88e-4711-b558-00b6320d67a6} {} -->
								<!-- {PREOPEN:3e3033f3-c539-4db3-8d3f-cf081ce39b04} {"sha1":"01CE1E502A7DFF35BA95CABA39EEB72245DA74D0"} -->
								<div class="ui-g-12 ui-md-6">
									<!-- {OPEN:3e3033f3-c539-4db3-8d3f-cf081ce39b04} {} -->
									<!-- {OPEN:fb87aa51-c703-4ec3-9434-33e22b28272d} {"sha1":"6ED8603DF3D15E90197960302235C377A7CC184F"} -->
									<div class="ui-fluid">
										<input pKeyFilter="pint" [(ngModel)]="searchRefine.minTotalFloorArea" name="minTotalFloorAream2{{ searchRefine.ObjectID }}" fieldLabel="Total Floor Area(m2)" type="text" pInputText class="ui-inputtext" placeholder="min">
									</div>
									<!-- {CLOSE:fb87aa51-c703-4ec3-9434-33e22b28272d} {"sha1":"6ED8603DF3D15E90197960302235C377A7CC184F"} -->
									<!-- {CLOSE:3e3033f3-c539-4db3-8d3f-cf081ce39b04} {} -->
								</div>
								<!-- {POSTCLOSE:3e3033f3-c539-4db3-8d3f-cf081ce39b04} {"sha1":"A381A66D660AB311749EF880057D27479B1166BB"} -->
								<!-- {PREOPEN:1e52aa6f-4523-4498-8e77-cfac314e60d6} {"sha1":"01CE1E502A7DFF35BA95CABA39EEB72245DA74D0"} -->
								<div class="ui-g-12 ui-md-6">
									<!-- {OPEN:1e52aa6f-4523-4498-8e77-cfac314e60d6} {} -->
									<!-- {OPEN:c4b5ce7b-1511-436c-9243-b33425b66a41} {"sha1":"2F69E9D1401121F07FA02236DB4D07F2CB3C8647"} -->
									<div class="ui-fluid">
										<input pKeyFilter="pint" [(ngModel)]="searchRefine.maxTotalFloorArea" name="maxTotalFloorAream2{{ searchRefine.ObjectID }}" fieldLabel="Total Floor Area(m2)" type="text" pInputText class="ui-inputtext" placeholder="max">
									</div>
									<!-- {CLOSE:c4b5ce7b-1511-436c-9243-b33425b66a41} {"sha1":"2F69E9D1401121F07FA02236DB4D07F2CB3C8647"} -->
									<!-- {CLOSE:1e52aa6f-4523-4498-8e77-cfac314e60d6} {} -->
								</div>
								<!-- {POSTCLOSE:1e52aa6f-4523-4498-8e77-cfac314e60d6} {"sha1":"A381A66D660AB311749EF880057D27479B1166BB"} -->
								<!-- {CLOSE:05bc977b-a88e-4711-b558-00b6320d67a6} {} -->
							</div>
							<!-- {POSTCLOSE:05bc977b-a88e-4711-b558-00b6320d67a6} {"sha1":"549A6971B2AF0C164CB00BC5DEDF452C05B5D75E"} -->
							<!-- {CLOSE:fb9aa50e-1d8a-4629-9999-aff4194d7372} {} -->
							<!-- {OPEN:2bfcab30-154e-4b5e-a507-ff23d8e183db} {"sha1":"BA84C4EBC51CEDE22EC9E9283D67C53B40EDC980"} -->
							<div [style.paddingTop.px]="'10'" [style.paddingLeft.px]="'19'" [style.paddingBottom.px]="'0'" [style.paddingRight.px]="'10'">
								<label>Ground Floor Area(m2)</label>
							</div>
							<!-- {CLOSE:2bfcab30-154e-4b5e-a507-ff23d8e183db} {"sha1":"BA84C4EBC51CEDE22EC9E9283D67C53B40EDC980"} -->
							<!-- {OPEN:808e14c2-83c0-4d0d-9a9d-fb12823128c7} {} -->
							<!-- {PREOPEN:0e55cc43-1095-4a59-8dde-b1a837808d7a} {"sha1":"7A6F26598C30D744924FDB7D249A2044024FC793"} -->
							<div class="ui-g form-group">
								<!-- {OPEN:0e55cc43-1095-4a59-8dde-b1a837808d7a} {} -->
								<!-- {PREOPEN:b857ec61-04c4-44ff-b7ff-b4654de81b86} {"sha1":"01CE1E502A7DFF35BA95CABA39EEB72245DA74D0"} -->
								<div class="ui-g-12 ui-md-6">
									<!-- {OPEN:b857ec61-04c4-44ff-b7ff-b4654de81b86} {} -->
									<!-- {OPEN:b8d628b9-6e6b-4251-98a4-58892dfdb1b9} {"sha1":"6799D021B17CDA81A419004C64CECE34B58F3F2E"} -->
									<div class="ui-fluid">
										<input pKeyFilter="pint" [(ngModel)]="searchRefine.minGroundFloorArea" name="minGroundFloorAream2{{ searchRefine.ObjectID }}" fieldLabel="Ground Floor Area(m2)" type="text" pInputText class="ui-inputtext" placeholder="min">
									</div>
									<!-- {CLOSE:b8d628b9-6e6b-4251-98a4-58892dfdb1b9} {"sha1":"6799D021B17CDA81A419004C64CECE34B58F3F2E"} -->
									<!-- {CLOSE:b857ec61-04c4-44ff-b7ff-b4654de81b86} {} -->
								</div>
								<!-- {POSTCLOSE:b857ec61-04c4-44ff-b7ff-b4654de81b86} {"sha1":"A381A66D660AB311749EF880057D27479B1166BB"} -->
								<!-- {PREOPEN:aa0c4830-69d7-42c0-a4aa-e4d1523479c0} {"sha1":"01CE1E502A7DFF35BA95CABA39EEB72245DA74D0"} -->
								<div class="ui-g-12 ui-md-6">
									<!-- {OPEN:aa0c4830-69d7-42c0-a4aa-e4d1523479c0} {} -->
									<!-- {OPEN:93a2e6ab-17c0-47ab-93b1-788e3c2395e5} {"sha1":"400D88F90A6485623FC953720F68B52559C18305"} -->
									<div class="ui-fluid">
										<input pKeyFilter="pint" [(ngModel)]="searchRefine.maxGroundFloorArea" name="maxGroundFloorAream2{{ searchRefine.ObjectID }}" fieldLabel="Ground Floor Area(m2)" type="text" pInputText class="ui-inputtext" placeholder="max">
									</div>
									<!-- {CLOSE:93a2e6ab-17c0-47ab-93b1-788e3c2395e5} {"sha1":"400D88F90A6485623FC953720F68B52559C18305"} -->
									<!-- {CLOSE:aa0c4830-69d7-42c0-a4aa-e4d1523479c0} {} -->
								</div>
								<!-- {POSTCLOSE:aa0c4830-69d7-42c0-a4aa-e4d1523479c0} {"sha1":"A381A66D660AB311749EF880057D27479B1166BB"} -->
								<!-- {CLOSE:0e55cc43-1095-4a59-8dde-b1a837808d7a} {} -->
							</div>
							<!-- {POSTCLOSE:0e55cc43-1095-4a59-8dde-b1a837808d7a} {"sha1":"549A6971B2AF0C164CB00BC5DEDF452C05B5D75E"} -->
							<!-- {CLOSE:808e14c2-83c0-4d0d-9a9d-fb12823128c7} {} -->
							<!-- {OPEN:db7192b7-36d8-463e-9d45-c5a98a3ae597} {"sha1":"E9504550C43C866EC24315C1825B8EF676C3213F"} -->
							<div [style.paddingTop.px]="'10'" [style.paddingLeft.px]="'19'" [style.paddingBottom.px]="'0'" [style.paddingRight.px]="'10'">
								<label>Site Area(m2)</label>
							</div>
							<!-- {CLOSE:db7192b7-36d8-463e-9d45-c5a98a3ae597} {"sha1":"E9504550C43C866EC24315C1825B8EF676C3213F"} -->
							<!-- {OPEN:98ba534b-7f6f-4f4e-8e11-2f1e4883f5ca} {} -->
							<!-- {PREOPEN:1d4c78f2-2ad3-478b-8db6-73b3b2491c25} {"sha1":"7A6F26598C30D744924FDB7D249A2044024FC793"} -->
							<div class="ui-g form-group">
								<!-- {OPEN:1d4c78f2-2ad3-478b-8db6-73b3b2491c25} {} -->
								<!-- {PREOPEN:e2fb9698-c6b6-4a8a-8240-36d77d050704} {"sha1":"01CE1E502A7DFF35BA95CABA39EEB72245DA74D0"} -->
								<div class="ui-g-12 ui-md-6">
									<!-- {OPEN:e2fb9698-c6b6-4a8a-8240-36d77d050704} {} -->
									<!-- {OPEN:38405b0b-b427-4696-899c-ade399c3071a} {"sha1":"52F951D304F4C52E2BD071C85B4CE64DBAB0956D"} -->
									<div class="ui-fluid">
										<input pKeyFilter="pint" [(ngModel)]="searchRefine.minSiteArea" name="minSiteAream2{{ searchRefine.ObjectID }}" fieldLabel="Site Area(m2)" type="text" pInputText class="ui-inputtext" placeholder="min">
									</div>
									<!-- {CLOSE:38405b0b-b427-4696-899c-ade399c3071a} {"sha1":"52F951D304F4C52E2BD071C85B4CE64DBAB0956D"} -->
									<!-- {CLOSE:e2fb9698-c6b6-4a8a-8240-36d77d050704} {} -->
								</div>
								<!-- {POSTCLOSE:e2fb9698-c6b6-4a8a-8240-36d77d050704} {"sha1":"A381A66D660AB311749EF880057D27479B1166BB"} -->
								<!-- {PREOPEN:595ba6f4-a389-4d9a-97ac-b04ebc0f4c56} {"sha1":"01CE1E502A7DFF35BA95CABA39EEB72245DA74D0"} -->
								<div class="ui-g-12 ui-md-6">
									<!-- {OPEN:595ba6f4-a389-4d9a-97ac-b04ebc0f4c56} {} -->
									<!-- {OPEN:f73f0124-b99c-47a9-b4fb-58ea45fb54c2} {"sha1":"24C23734E7706AABBF1AF3D62269E2CBE0D6E5BD"} -->
									<div class="ui-fluid">
										<input pKeyFilter="pint" [(ngModel)]="searchRefine.maxSiteArea" name="maxSiteAream2{{ searchRefine.ObjectID }}" fieldLabel="Site Area(m2)" type="text" pInputText class="ui-inputtext" placeholder="max">
									</div>
									<!-- {CLOSE:f73f0124-b99c-47a9-b4fb-58ea45fb54c2} {"sha1":"24C23734E7706AABBF1AF3D62269E2CBE0D6E5BD"} -->
									<!-- {CLOSE:595ba6f4-a389-4d9a-97ac-b04ebc0f4c56} {} -->
								</div>
								<!-- {POSTCLOSE:595ba6f4-a389-4d9a-97ac-b04ebc0f4c56} {"sha1":"A381A66D660AB311749EF880057D27479B1166BB"} -->
								<!-- {CLOSE:1d4c78f2-2ad3-478b-8db6-73b3b2491c25} {} -->
							</div>
							<!-- {POSTCLOSE:1d4c78f2-2ad3-478b-8db6-73b3b2491c25} {"sha1":"549A6971B2AF0C164CB00BC5DEDF452C05B5D75E"} -->
							<!-- {CLOSE:98ba534b-7f6f-4f4e-8e11-2f1e4883f5ca} {} -->
							<!-- {OPEN:66ac0de3-2c56-48d4-a808-7cb99148cab4} {"sha1":"969F02C7D389BA3857201301C5FE17F31D37427D"} -->
							<div [style.paddingTop.px]="'10'" [style.paddingLeft.px]="'19'" [style.paddingBottom.px]="'0'" [style.paddingRight.px]="'10'">
								<label>Storeys Above Ground</label>
							</div>
							<!-- {CLOSE:66ac0de3-2c56-48d4-a808-7cb99148cab4} {"sha1":"969F02C7D389BA3857201301C5FE17F31D37427D"} -->
							<!-- {OPEN:62c1b671-ae83-4c2b-ba75-642614428e91} {} -->
							<!-- {PREOPEN:e9889d14-ceed-425f-8551-330c63b9f5a9} {"sha1":"7A6F26598C30D744924FDB7D249A2044024FC793"} -->
							<div class="ui-g form-group">
								<!-- {OPEN:e9889d14-ceed-425f-8551-330c63b9f5a9} {} -->
								<!-- {PREOPEN:7d2d5671-9629-4271-8f4d-5b199a01d93e} {"sha1":"01CE1E502A7DFF35BA95CABA39EEB72245DA74D0"} -->
								<div class="ui-g-12 ui-md-6">
									<!-- {OPEN:7d2d5671-9629-4271-8f4d-5b199a01d93e} {} -->
									<!-- {OPEN:f94e4fb9-9cba-4b27-9810-e889ad1443b7} {"sha1":"2AE21C265ABF9E897851008D5BE8224774FDEAE0"} -->
									<div class="ui-fluid">
										<input pKeyFilter="pint" [(ngModel)]="searchRefine.minStoreysAboveGround" name="minStoreysAboveGround{{ searchRefine.ObjectID }}" fieldLabel="Storeys Above Ground" type="text" pInputText class="ui-inputtext" placeholder="min">
									</div>
									<!-- {CLOSE:f94e4fb9-9cba-4b27-9810-e889ad1443b7} {"sha1":"2AE21C265ABF9E897851008D5BE8224774FDEAE0"} -->
									<!-- {CLOSE:7d2d5671-9629-4271-8f4d-5b199a01d93e} {} -->
								</div>
								<!-- {POSTCLOSE:7d2d5671-9629-4271-8f4d-5b199a01d93e} {"sha1":"A381A66D660AB311749EF880057D27479B1166BB"} -->
								<!-- {PREOPEN:195817d4-99c0-4e61-a37b-9d5204e35a54} {"sha1":"01CE1E502A7DFF35BA95CABA39EEB72245DA74D0"} -->
								<div class="ui-g-12 ui-md-6">
									<!-- {OPEN:195817d4-99c0-4e61-a37b-9d5204e35a54} {} -->
									<!-- {OPEN:77152d64-67ed-417a-a24c-9a1edf5f94a8} {"sha1":"58DFF26B7360122753C2ABDA8F61868A154C5F79"} -->
									<div class="ui-fluid">
										<input pKeyFilter="pint" [(ngModel)]="searchRefine.maxStoreysAboveGround" name="maxStoreysAboveGround{{ searchRefine.ObjectID }}" fieldLabel="Storeys Above Ground" type="text" pInputText class="ui-inputtext" placeholder="max">
									</div>
									<!-- {CLOSE:77152d64-67ed-417a-a24c-9a1edf5f94a8} {"sha1":"58DFF26B7360122753C2ABDA8F61868A154C5F79"} -->
									<!-- {CLOSE:195817d4-99c0-4e61-a37b-9d5204e35a54} {} -->
								</div>
								<!-- {POSTCLOSE:195817d4-99c0-4e61-a37b-9d5204e35a54} {"sha1":"A381A66D660AB311749EF880057D27479B1166BB"} -->
								<!-- {CLOSE:e9889d14-ceed-425f-8551-330c63b9f5a9} {} -->
							</div>
							<!-- {POSTCLOSE:e9889d14-ceed-425f-8551-330c63b9f5a9} {"sha1":"549A6971B2AF0C164CB00BC5DEDF452C05B5D75E"} -->
							<!-- {CLOSE:62c1b671-ae83-4c2b-ba75-642614428e91} {} -->
							<!-- {OPEN:b6a2e41f-c929-4cd3-8a34-2491ca2754ba} {"sha1":"098F285C988AB32F44331AC3D074EF78AFE77596"} -->
							<div [style.paddingTop.px]="'10'" [style.paddingLeft.px]="'19'" [style.paddingBottom.px]="'0'" [style.paddingRight.px]="'10'">
								<label>Storeys Below Ground</label>
							</div>
							<!-- {CLOSE:b6a2e41f-c929-4cd3-8a34-2491ca2754ba} {"sha1":"098F285C988AB32F44331AC3D074EF78AFE77596"} -->
							<!-- {OPEN:1fca153f-42e3-46a7-a6b3-3da14c2ae6f6} {} -->
							<!-- {PREOPEN:7d0a4e1b-5a44-4d0f-9814-b1a984c59d63} {"sha1":"7A6F26598C30D744924FDB7D249A2044024FC793"} -->
							<div class="ui-g form-group">
								<!-- {OPEN:7d0a4e1b-5a44-4d0f-9814-b1a984c59d63} {} -->
								<!-- {PREOPEN:bdc4e614-e953-450c-900b-1946025dd97d} {"sha1":"01CE1E502A7DFF35BA95CABA39EEB72245DA74D0"} -->
								<div class="ui-g-12 ui-md-6">
									<!-- {OPEN:bdc4e614-e953-450c-900b-1946025dd97d} {} -->
									<!-- {OPEN:7d9e5b1b-507f-4d0b-b305-cbebbd5b2309} {"sha1":"F04A412EFC0A9FE7DCF839BBC833DF208D15EAEB"} -->
									<div class="ui-fluid">
										<input pKeyFilter="pint" [(ngModel)]="searchRefine.minStoreysBelowGround" name="minStoreysBelowGround{{ searchRefine.ObjectID }}" fieldLabel="Storeys Below Ground" type="text" pInputText class="ui-inputtext" placeholder="min">
									</div>
									<!-- {CLOSE:7d9e5b1b-507f-4d0b-b305-cbebbd5b2309} {"sha1":"F04A412EFC0A9FE7DCF839BBC833DF208D15EAEB"} -->
									<!-- {CLOSE:bdc4e614-e953-450c-900b-1946025dd97d} {} -->
								</div>
								<!-- {POSTCLOSE:bdc4e614-e953-450c-900b-1946025dd97d} {"sha1":"A381A66D660AB311749EF880057D27479B1166BB"} -->
								<!-- {PREOPEN:59c20f6d-5d76-4085-ae93-232a648f4101} {"sha1":"01CE1E502A7DFF35BA95CABA39EEB72245DA74D0"} -->
								<div class="ui-g-12 ui-md-6">
									<!-- {OPEN:59c20f6d-5d76-4085-ae93-232a648f4101} {} -->
									<!-- {OPEN:67e8bff6-2040-478a-b01f-7fdf8b13b371} {"sha1":"B92BFB921F7FCE9E884D93248E5DCB59A464C271"} -->
									<div class="ui-fluid">
										<input pKeyFilter="pint" [(ngModel)]="searchRefine.maxStoreysBelowGround" name="maxStoreysBelowGround{{ searchRefine.ObjectID }}" fieldLabel="Storeys Below Ground" type="text" pInputText class="ui-inputtext" placeholder="max">
									</div>
									<!-- {CLOSE:67e8bff6-2040-478a-b01f-7fdf8b13b371} {"sha1":"B92BFB921F7FCE9E884D93248E5DCB59A464C271"} -->
									<!-- {CLOSE:59c20f6d-5d76-4085-ae93-232a648f4101} {} -->
								</div>
								<!-- {POSTCLOSE:59c20f6d-5d76-4085-ae93-232a648f4101} {"sha1":"A381A66D660AB311749EF880057D27479B1166BB"} -->
								<!-- {CLOSE:7d0a4e1b-5a44-4d0f-9814-b1a984c59d63} {} -->
							</div>
							<!-- {POSTCLOSE:7d0a4e1b-5a44-4d0f-9814-b1a984c59d63} {"sha1":"549A6971B2AF0C164CB00BC5DEDF452C05B5D75E"} -->
							<!-- {CLOSE:1fca153f-42e3-46a7-a6b3-3da14c2ae6f6} {} -->
							<!-- {OPEN:df9276fe-7f62-446c-a510-7f0a0781edb3} {"sha1":"825CE69C486D42CCD26694EF6AF8B978E83C04A8"} -->
							<div [style.paddingTop.px]="'10'" [style.paddingLeft.px]="'19'" [style.paddingBottom.px]="'0'" [style.paddingRight.px]="'10'">
								<label>Parking Spaces</label>
							</div>
							<!-- {CLOSE:df9276fe-7f62-446c-a510-7f0a0781edb3} {"sha1":"825CE69C486D42CCD26694EF6AF8B978E83C04A8"} -->
							<!-- {OPEN:37e5c6a1-767c-4fb6-b0d3-39bf809369ad} {} -->
							<!-- {PREOPEN:1f7fdba5-bdb1-49c0-a49a-6cc3045c070f} {"sha1":"7A6F26598C30D744924FDB7D249A2044024FC793"} -->
							<div class="ui-g form-group">
								<!-- {OPEN:1f7fdba5-bdb1-49c0-a49a-6cc3045c070f} {} -->
								<!-- {PREOPEN:7936ee8b-e347-449e-967c-17181da91135} {"sha1":"01CE1E502A7DFF35BA95CABA39EEB72245DA74D0"} -->
								<div class="ui-g-12 ui-md-6">
									<!-- {OPEN:7936ee8b-e347-449e-967c-17181da91135} {} -->
									<!-- {OPEN:7732aa5b-4833-47be-b3a9-cba279194c23} {"sha1":"C07B58B3C364E3A2890ACE2E8E5F9DB8FBED9E9A"} -->
									<div class="ui-fluid">
										<input pKeyFilter="pint" [(ngModel)]="searchRefine.minParkingSpaces" name="minParkingSpaces{{ searchRefine.ObjectID }}" fieldLabel="Parking Spaces" type="text" pInputText class="ui-inputtext" placeholder="min">
									</div>
									<!-- {CLOSE:7732aa5b-4833-47be-b3a9-cba279194c23} {"sha1":"C07B58B3C364E3A2890ACE2E8E5F9DB8FBED9E9A"} -->
									<!-- {CLOSE:7936ee8b-e347-449e-967c-17181da91135} {} -->
								</div>
								<!-- {POSTCLOSE:7936ee8b-e347-449e-967c-17181da91135} {"sha1":"A381A66D660AB311749EF880057D27479B1166BB"} -->
								<!-- {PREOPEN:52d8647a-0916-468f-9269-387bf80cc242} {"sha1":"01CE1E502A7DFF35BA95CABA39EEB72245DA74D0"} -->
								<div class="ui-g-12 ui-md-6">
									<!-- {OPEN:52d8647a-0916-468f-9269-387bf80cc242} {} -->
									<!-- {OPEN:7e30f7b1-859f-47d8-b6ef-6ddb27a03f14} {"sha1":"4E4F1C9C50BC0FBF708A7C719F97E111D2AD5315"} -->
									<div class="ui-fluid">
										<input pKeyFilter="pint" [(ngModel)]="searchRefine.maxParkingSpaces" name="maxParkingSpaces{{ searchRefine.ObjectID }}" fieldLabel="Parking Spaces" type="text" pInputText class="ui-inputtext" placeholder="max">
									</div>
									<!-- {CLOSE:7e30f7b1-859f-47d8-b6ef-6ddb27a03f14} {"sha1":"4E4F1C9C50BC0FBF708A7C719F97E111D2AD5315"} -->
									<!-- {CLOSE:52d8647a-0916-468f-9269-387bf80cc242} {} -->
								</div>
								<!-- {POSTCLOSE:52d8647a-0916-468f-9269-387bf80cc242} {"sha1":"A381A66D660AB311749EF880057D27479B1166BB"} -->
								<!-- {CLOSE:1f7fdba5-bdb1-49c0-a49a-6cc3045c070f} {} -->
							</div>
							<!-- {POSTCLOSE:1f7fdba5-bdb1-49c0-a49a-6cc3045c070f} {"sha1":"549A6971B2AF0C164CB00BC5DEDF452C05B5D75E"} -->
							<!-- {CLOSE:37e5c6a1-767c-4fb6-b0d3-39bf809369ad} {} -->
							<!-- {CLOSE:26a1872e-2b2c-488c-b229-4adaf8f34c5f} {} -->
						</div>
						<!-- {POSTCLOSE:26a1872e-2b2c-488c-b229-4adaf8f34c5f} {"sha1":"079B33F711D32A1BDB5DD1A942BDD82885981728"} -->
						<!-- {CLOSE:868e0d6f-c703-4e04-ba69-2c2b4b00e1e6} {} -->
					</div>
					<!-- {POSTCLOSE:868e0d6f-c703-4e04-ba69-2c2b4b00e1e6} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
					<!-- {CLOSE:0865ad36-35ab-4367-869a-e25cfef78b0c} {} -->
					<!-- {CLOSE:47dcfda8-00bb-460b-8b00-5d6c7ac4ccec} {} -->
				</p-tabPanel>
				<!-- {POSTCLOSE:47dcfda8-00bb-460b-8b00-5d6c7ac4ccec} {"sha1":"FE51611D04120879F4F15959FB133DEB7EA85E40"} -->
				<!-- {PREOPEN:7e484ebf-14f3-43d0-bfc7-0ca1ec84078a} {"sha1":"6656D5A5BB952A1D05A8520E2D6661394A1BEB13"} -->
				<p-tabPanel >
					<!-- {OPEN:7e484ebf-14f3-43d0-bfc7-0ca1ec84078a} {} -->
					<!-- {PREOPEN:7e484ebf-14f3-43d0-bfc7-0ca1ec84078a-HEADER} {"sha1":"1A0C4C94A622BFA2C25C4C4FBAC1BBB393D69DF2"} -->
					<ng-template pTemplate="header">
						<!-- {OPEN:7e484ebf-14f3-43d0-bfc7-0ca1ec84078a-HEADER} {"sha1":"F892A6FEB6D1819234849F13B9B8FF4854788453"} -->
						<span>Company Filters</span>
						<!-- {CLOSE:7e484ebf-14f3-43d0-bfc7-0ca1ec84078a-HEADER} {"sha1":"F892A6FEB6D1819234849F13B9B8FF4854788453"} -->
					</ng-template>
					<!-- {POSTCLOSE:7e484ebf-14f3-43d0-bfc7-0ca1ec84078a-HEADER} {"sha1":"F9FB8641C9605E4F4F0182D5EBA4345041EA9630"} -->
					<!-- {OPEN:a5f1fc1b-c361-49f6-8c42-92568c86920c} {} -->
					<!-- {PREOPEN:76bb2d67-c538-415d-bd17-859eb7d5754e} {"sha1":"8E47AF93A0EDFE97B55BAA3CF5DF54E9C0946D9E"} -->
					<div class="ui-g form-group">
						<!-- {OPEN:76bb2d67-c538-415d-bd17-859eb7d5754e} {} -->
						<!-- {PREOPEN:5cf244bd-1d64-44d2-a5af-cf755d99b57f} {"sha1":"FF61B4EE7559C64F5213E994CA3D7044AC4BC38D"} -->
						<div class="ui-g-12 ui-md-12">
							<!-- {OPEN:5cf244bd-1d64-44d2-a5af-cf755d99b57f} {} -->
							<!-- {OPEN:7d019a39-d90a-4b5d-9819-4798b9b926e2} {"sha1":"C4761824619DD32EA6B69435DCB47E54D9FB3826"} -->
							<label>Enter a word or phrase to search all fields</label>
							<!-- {CLOSE:7d019a39-d90a-4b5d-9819-4798b9b926e2} {"sha1":"C4761824619DD32EA6B69435DCB47E54D9FB3826"} -->
							<!-- {OPEN:87292eb3-e6cd-400a-9689-b175647be246} {"sha1":"D5DCC233264C59C1A31951861BCC49FE93565C95"} -->
							<div class="ui-fluid" [style.paddingTop.px]="'10'">
								 <span class="ui-float-label sticky">
									<input [(ngModel)]="searchRefine.company" name="Company" fieldLabel="company" type="text" pInputText class="ui-inputtext">
									<label>Company</label>
            					</span>
							</div>
							<!-- {CLOSE:87292eb3-e6cd-400a-9689-b175647be246} {"sha1":"D5DCC233264C59C1A31951861BCC49FE93565C95"} -->
							<!-- {CLOSE:5cf244bd-1d64-44d2-a5af-cf755d99b57f} {} -->
						</div>
						<!-- {POSTCLOSE:5cf244bd-1d64-44d2-a5af-cf755d99b57f} {"sha1":"079B33F711D32A1BDB5DD1A942BDD82885981728"} -->
						<!-- {CLOSE:76bb2d67-c538-415d-bd17-859eb7d5754e} {} -->
					</div>
					<!-- {POSTCLOSE:76bb2d67-c538-415d-bd17-859eb7d5754e} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
					<!-- {CLOSE:a5f1fc1b-c361-49f6-8c42-92568c86920c} {} -->
					<!-- {CLOSE:7e484ebf-14f3-43d0-bfc7-0ca1ec84078a} {} -->
				</p-tabPanel>
				<!-- {POSTCLOSE:7e484ebf-14f3-43d0-bfc7-0ca1ec84078a} {"sha1":"FE51611D04120879F4F15959FB133DEB7EA85E40"} -->
				<!-- {PREOPEN:4e5876ee-6932-472c-ac64-e30268e67d4b} {"sha1":"6656D5A5BB952A1D05A8520E2D6661394A1BEB13"} -->
				<p-tabPanel >
					<!-- {OPEN:4e5876ee-6932-472c-ac64-e30268e67d4b} {} -->
					<!-- {PREOPEN:4e5876ee-6932-472c-ac64-e30268e67d4b-HEADER} {"sha1":"1A0C4C94A622BFA2C25C4C4FBAC1BBB393D69DF2"} -->
					<ng-template pTemplate="header">
						<!-- {OPEN:4e5876ee-6932-472c-ac64-e30268e67d4b-HEADER} {"sha1":"4A8C0903A0E04195FBED5C07029FDC02ACA5B9C1"} -->
						<span>Search By Company or Project Distance</span>
						<!-- {CLOSE:4e5876ee-6932-472c-ac64-e30268e67d4b-HEADER} {"sha1":"4A8C0903A0E04195FBED5C07029FDC02ACA5B9C1"} -->
					</ng-template>
					<!-- {POSTCLOSE:4e5876ee-6932-472c-ac64-e30268e67d4b-HEADER} {"sha1":"F9FB8641C9605E4F4F0182D5EBA4345041EA9630"} -->
					<!-- {OPEN:f7c8e9f9-eb7e-4d23-bbf2-321d64740653} {} -->
					<!-- {PREOPEN:32ea4ea4-ec6a-4a22-ac06-4b9402207f83} {"sha1":"8E47AF93A0EDFE97B55BAA3CF5DF54E9C0946D9E"} -->
					<div class="ui-g form-group">
						<!-- {OPEN:32ea4ea4-ec6a-4a22-ac06-4b9402207f83} {} -->
						<!-- {PREOPEN:df426bca-0fc3-4ed7-b66c-608e8b5b4cae} {"sha1":"FF61B4EE7559C64F5213E994CA3D7044AC4BC38D"} -->
						<div class="ui-g-12 ui-md-12">
							<div class="ui-fluid">
								<p-dropdown [autoDisplayFirst] = "false" [(ngModel)]="searchRefine.SearchByCompanyOrProjectDistance" name="SearchByCompanyOrProjectDistance{{ searchRefine.ObjectID }}" fieldLabel="Search By Project Or Company Distance" (onChange)="onDummyChange($event, searchRefine)" [options]="searchByCompanyOrProjectDistanceOptions"  dataKey="value"></p-dropdown>
							</div>
						</div>
						<!-- {POSTCLOSE:df426bca-0fc3-4ed7-b66c-608e8b5b4cae} {"sha1":"079B33F711D32A1BDB5DD1A942BDD82885981728"} -->
						<!-- {CLOSE:32ea4ea4-ec6a-4a22-ac06-4b9402207f83} {} -->
					</div>
					<div class="ui-g form-group">
						<div class="ui-g-12 ui-md-12">
							<div class="ui-fluid" [style.paddingLeft.px]="'20'" [style.paddingRight.px]="'20'">
								<p-slider [step]="'0.5'" [animate]="true" name="Distance" [(ngModel)]="searchRefine.Distance"></p-slider>
							</div>
						</div>
					</div>
					<div class="ui-g form-group" *ngIf="searchRefine?.Distance">
						<div class="ui-g-12 ui-md-12">
							<div [style.text-align]="'center'" [style.paddingTop.px]="'25'">
								<label>{{searchRefine?.Distance}} miles</label>
							</div>
						</div>
					</div>
					<div class="ui-g form-group">
						<div class="ui-g-12 ui-md-12">
							<div [style.text-align]="'center'">
								<oneit-checkbox (onChange)="getCurrentLocation()" [(ngModel)]="searchRefine.UseCurrentLocation" name="UseCurrentLocation{{ searchRefine.ObjectID }}" fieldLabel="35.5 miles" binary="true" label="Use Current Location"></oneit-checkbox>
							</div>
						</div>
					</div>
					<!-- {POSTCLOSE:32ea4ea4-ec6a-4a22-ac06-4b9402207f83} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
					<!-- {CLOSE:f7c8e9f9-eb7e-4d23-bbf2-321d64740653} {} -->
					<!-- {CLOSE:4e5876ee-6932-472c-ac64-e30268e67d4b} {} -->
				</p-tabPanel>
				<!-- {POSTCLOSE:4e5876ee-6932-472c-ac64-e30268e67d4b} {"sha1":"FE51611D04120879F4F15959FB133DEB7EA85E40"} -->
				<!-- {PREOPEN:6d7b0376-d7fc-4c21-9847-0d56b6361e9e} {"sha1":"6656D5A5BB952A1D05A8520E2D6661394A1BEB13"} -->
				<p-tabPanel >
					<!-- {OPEN:6d7b0376-d7fc-4c21-9847-0d56b6361e9e} {} -->
					<!-- {PREOPEN:6d7b0376-d7fc-4c21-9847-0d56b6361e9e-HEADER} {"sha1":"1A0C4C94A622BFA2C25C4C4FBAC1BBB393D69DF2"} -->
					<ng-template pTemplate="header">
						<!-- {OPEN:6d7b0376-d7fc-4c21-9847-0d56b6361e9e-HEADER} {"sha1":"9A483224619721D2FB5C63244F785342858805EA"} -->
						<span>Favourite</span>
						<!-- {CLOSE:6d7b0376-d7fc-4c21-9847-0d56b6361e9e-HEADER} {"sha1":"9A483224619721D2FB5C63244F785342858805EA"} -->
					</ng-template>
					<!-- {POSTCLOSE:6d7b0376-d7fc-4c21-9847-0d56b6361e9e-HEADER} {"sha1":"F9FB8641C9605E4F4F0182D5EBA4345041EA9630"} -->
					<!-- {OPEN:95da1ba8-0151-4527-9828-c57d0e34ee84} {} -->
					<!-- {PREOPEN:71009e3d-e578-44d8-bdfd-8f55df66df14} {"sha1":"8E47AF93A0EDFE97B55BAA3CF5DF54E9C0946D9E"} -->
					<div class="ui-g form-group">
						<!-- {OPEN:71009e3d-e578-44d8-bdfd-8f55df66df14} {} -->
						<!-- {PREOPEN:f07b1491-88a1-4dce-a4fe-d85298a76d04} {"sha1":"78E0CE1766DCF3655F1631C109A5444307DCF9C9"} -->
						<div class="ui-g-12 ui-md-12">
							<!-- {OPEN:f07b1491-88a1-4dce-a4fe-d85298a76d04} {} -->
							<!-- {OPEN:a1a9a529-4eac-4190-a9a5-06615e276e7f} {"sha1":"5B5F99974E25B162132EDC4D0E0685C5A05EB043"} -->
							<p-inputSwitch [(ngModel)]="favouriteOnly" name="FavoritesOnly" fieldLabel="Favorites Only"></p-inputSwitch>
							<label>Favorites Only</label>
							<!-- {CLOSE:a1a9a529-4eac-4190-a9a5-06615e276e7f} {"sha1":"5B5F99974E25B162132EDC4D0E0685C5A05EB043"} -->
							<!-- {CLOSE:f07b1491-88a1-4dce-a4fe-d85298a76d04} {} -->
						</div>
						<!-- {POSTCLOSE:f07b1491-88a1-4dce-a4fe-d85298a76d04} {"sha1":"079B33F711D32A1BDB5DD1A942BDD82885981728"} -->
						<!-- {PREOPEN:f61387eb-ed89-4884-9b8d-aaa04bd75912} {"sha1":"D1360E5C6406047DD9865D1661F786AE3C6DAF5A"} -->
						<div class="ui-g-12 ui-md-4">
							<!-- {OPEN:f61387eb-ed89-4884-9b8d-aaa04bd75912} {"sha1":"3EAEE93D661790A598B3ABB8F6AA28ABB45A097D"} -->
							<!-- {CLOSE:f61387eb-ed89-4884-9b8d-aaa04bd75912} {"sha1":"3EAEE93D661790A598B3ABB8F6AA28ABB45A097D"} -->
						</div>
						<!-- {POSTCLOSE:f61387eb-ed89-4884-9b8d-aaa04bd75912} {"sha1":"079B33F711D32A1BDB5DD1A942BDD82885981728"} -->
						<!-- {PREOPEN:e94d5f46-477e-4cb3-aa41-824cb6c2f481} {"sha1":"78E0CE1766DCF3655F1631C109A5444307DCF9C9"} -->
						<div class="ui-g-12 ui-md-2">
							<!-- {OPEN:e94d5f46-477e-4cb3-aa41-824cb6c2f481} {"sha1":"3EAEE93D661790A598B3ABB8F6AA28ABB45A097D"} -->
							<!-- {CLOSE:e94d5f46-477e-4cb3-aa41-824cb6c2f481} {"sha1":"3EAEE93D661790A598B3ABB8F6AA28ABB45A097D"} -->
						</div>
						<!-- {POSTCLOSE:e94d5f46-477e-4cb3-aa41-824cb6c2f481} {"sha1":"079B33F711D32A1BDB5DD1A942BDD82885981728"} -->
						<!-- {PREOPEN:90875366-62af-4048-bee4-796b7a42946d} {"sha1":"D1360E5C6406047DD9865D1661F786AE3C6DAF5A"} -->
						<div class="ui-g-12 ui-md-4">
							<!-- {OPEN:90875366-62af-4048-bee4-796b7a42946d} {"sha1":"3EAEE93D661790A598B3ABB8F6AA28ABB45A097D"} -->
							<!-- {CLOSE:90875366-62af-4048-bee4-796b7a42946d} {"sha1":"3EAEE93D661790A598B3ABB8F6AA28ABB45A097D"} -->
						</div>
						<!-- {POSTCLOSE:90875366-62af-4048-bee4-796b7a42946d} {"sha1":"079B33F711D32A1BDB5DD1A942BDD82885981728"} -->
						<!-- {CLOSE:71009e3d-e578-44d8-bdfd-8f55df66df14} {} -->
					</div>
					<!-- {POSTCLOSE:71009e3d-e578-44d8-bdfd-8f55df66df14} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
					<!-- {CLOSE:95da1ba8-0151-4527-9828-c57d0e34ee84} {} -->
					<!-- {CLOSE:6d7b0376-d7fc-4c21-9847-0d56b6361e9e} {} -->
				</p-tabPanel>
				<!-- {POSTCLOSE:6d7b0376-d7fc-4c21-9847-0d56b6361e9e} {"sha1":"FE51611D04120879F4F15959FB133DEB7EA85E40"} -->
				<!-- {CLOSE:d159b00d-ffa0-4e1e-9709-46eef24c9e96} {} -->
			</p-tabView>
			<!-- {POSTCLOSE:d159b00d-ffa0-4e1e-9709-46eef24c9e96} {"sha1":"6587E3A124AE843F429C42D2137AEA3BA28BFBE3"} -->
			<!-- {CLOSE:74dbe138-f376-4607-b35b-00a60caf8bd7} {} -->
		</div>
		<!-- {POSTCLOSE:74dbe138-f376-4607-b35b-00a60caf8bd7} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
		<!-- {PREOPEN:768e4360-a30a-483f-bc41-4d4d818ea074} {"sha1":"2183A3A9ABCF2FD844436EF69F76172FF147BB12"} -->
		<div class="ui-g-12 ui-md-4">
			<!-- {OPEN:768e4360-a30a-483f-bc41-4d4d818ea074} {} -->
			<!-- {PREOPEN:66d0aade-f69e-4a23-860d-f6c22bfb611c} {"sha1":"A529EF58F6610BD475D34AE604B334D626C4A784"} -->
			<p-fieldset legend="Summary" [toggleable]="false" [collapsed]="false">
				<ng-contanier *ngIf="searchRefine?.project?.length > 0">
					<div class="ui-g-12 ui-md-10 word-break" >
						<a class="cursor-pointer" (click)="activeTabIndex=0" [ngStyle]='{"font-weight":"bold"}'>project_text: {{searchRefine?.project}} </a>
					</div>
					<div class="ui-g-12 ui-md-2">
						<div>
							<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="searchRefine.project = null">X</a>
						</div>
					</div>
				</ng-contanier>
				<ng-container *ngIf="stagesOptionsSelected || searchRefine.Includerefusedwithdrawnabandonedandonhold">
					<ng-container>
						<div class="ui-g-12 ui-md-12">
							<a class="cursor-pointer" (click)="activeTabIndex=1" [ngStyle]='{"font-weight":"bold"}'>Stages:</a>
						</div>
						<ng-container *ngIf="isIncludeAllStagesOptions">
							<div class="ui-g-12 ui-md-10">
								<a class="cursor-pointer" (click)="activeTabIndex=1" [ngStyle]='{"font-weight":"bold"}'>Include all stages</a>
							</div>
							<div class="ui-g-12 ui-md-2">
								<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="isIncludeAllStagesOptions = false ; selectAllOptions(stages , isIncludeAllStagesOptions , true)">X</a>
							</div>
						</ng-container>
						<ng-container *ngIf="isExcludeAllStagesOptions">
							<div class="ui-g-12 ui-md-10">
								<a class="cursor-pointer" (click)="activeTabIndex=1" [ngStyle]='{"font-weight":"bold"}'>Exclude all stages</a>
							</div>
							<div class="ui-g-12 ui-md-2">
								<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="isExcludeAllStagesOptions = false ; selectAllOptions(stages , isExcludeAllStagesOptions , false)">X</a>
							</div>
						</ng-container>
						<ng-container *ngIf="!isIncludeAllStagesOptions && !isExcludeAllStagesOptions">
							<ng-container *ngFor="let stage of stages">
								<ng-container *ngIf="stage?.data?.isIncluded">
									<div class="ui-g-12 ui-md-10">
										<a class="cursor-pointer" (click)="activeTabIndex=1" [ngStyle]='{"font-weight":"bold"}'>Include {{stage?.data?.name}}</a>
									</div>
									<div class="ui-g-12 ui-md-2">
										<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="stage.data.isIncluded = false ; onTableDataCheckBoxValueChange(stage , true , 'isStage')">X</a>
									</div>
								</ng-container>
								<ng-container *ngIf="stage?.data?.isExcluded">
									<div class="ui-g-12 ui-md-10">
										<a class="cursor-pointer" (click)="activeTabIndex=1" [ngStyle]='{"font-weight":"bold"}'>Exclude {{stage?.data?.name}}</a>
									</div>
									<div class="ui-g-12 ui-md-2">
										<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="stage.data.isExcluded = false ; onTableDataCheckBoxValueChange(stage , false , 'isStage')">X</a>
									</div>
								</ng-container>
							</ng-container>
							<ng-container *ngIf="searchRefine?.Includerefusedwithdrawnabandonedandonhold">
								<div class="ui-g-12 ui-md-10">
									<a class="cursor-pointer" (click)="activeTabIndex=1" [ngStyle]='{"font-weight":"bold"}'>Include refused , withdrawn , abandoned and on hold </a>
								</div>
								<div class="ui-g-12 ui-md-2">
									<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="searchRefine.Includerefusedwithdrawnabandonedandonhold = false">X</a>
								</div>
							</ng-container>
						</ng-container>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="categoriesOptionsSelected">
					<ng-container>
						<div class="ui-g-12 ui-md-12">
							<a class="cursor-pointer" (click)="activeTabIndex=2" [ngStyle]='{"font-weight":"bold"}'>Categories:</a>
						</div>
						<ng-container *ngIf="isIncludeAllCategoriesOptions">
							<div class="ui-g-12 ui-md-10">
								<a class="cursor-pointer" (click)="activeTabIndex=2" [ngStyle]='{"font-weight":"bold"}'>Include all categories</a>
							</div>
							<div class="ui-g-12 ui-md-2">
								<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="isIncludeAllCategoriesOptions = false ; selectAllOptions(categories , isIncludeAllCategoriesOptions , true)">X</a>
							</div>
						</ng-container>
						<ng-container *ngIf="isExcludeAllCategoriesOptions">
							<div class="ui-g-12 ui-md-10">
								<a class="cursor-pointer" (click)="activeTabIndex=2" [ngStyle]='{"font-weight":"bold"}'>Exclude all categories</a>
							</div>
							<div class="ui-g-12 ui-md-2">
								<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="isExcludeAllCategoriesOptions = false ; selectAllOptions(categories , isExcludeAllCategoriesOptions , false)">X</a>
							</div>
						</ng-container>
						<ng-container *ngIf="!isIncludeAllCategoriesOptions && !isExcludeAllCategoriesOptions">
							<ng-container *ngFor="let category of categories">
								<ng-container *ngIf="category?.data?.isIncluded">
									<div class="ui-g-12 ui-md-10">
										<a class="cursor-pointer" (click)="activeTabIndex=1" [ngStyle]='{"font-weight":"bold"}'>Include {{category?.data?.name}}</a>
									</div>
									<div class="ui-g-12 ui-md-2">
										<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="category.data.isIncluded = false ; onTableDataCheckBoxValueChange(category , true , 'isCategory')">X</a>
									</div>
								</ng-container>
								<ng-container *ngIf="category?.data?.isExcluded">
									<div class="ui-g-12 ui-md-10">
										<a class="cursor-pointer" (click)="activeTabIndex=1" [ngStyle]='{"font-weight":"bold"}'>Exclude {{category?.data?.name}}</a>
									</div>
									<div class="ui-g-12 ui-md-2">
										<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="category.data.isExcluded = false; onTableDataCheckBoxValueChange(category , false , 'isCategory')">X</a>
									</div>
								</ng-container>
								<ng-container *ngIf="!category?.data?.isExcluded && !category?.data?.isIncluded">
									<ng-container *ngFor="let childCategory of category?.children ">
										<ng-container *ngIf="childCategory?.data?.isIncluded">
											<div class="ui-g-12 ui-md-10">
												<a class="cursor-pointer" (click)="activeTabIndex=1" [ngStyle]='{"font-weight":"bold"}'>Include {{childCategory?.data?.name}}</a>
											</div>
											<div class="ui-g-12 ui-md-2">
												<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="childCategory.data.isIncluded = false ; onTableDataCheckBoxValueChange(childCategory , true , 'isCategory')">X</a>
											</div>
										</ng-container>
										<ng-container *ngIf="childCategory?.data?.isExcluded">
											<div class="ui-g-12 ui-md-10">
												<a class="cursor-pointer" (click)="activeTabIndex=1" [ngStyle]='{"font-weight":"bold"}'>Exclude {{childCategory?.data?.name}}</a>
											</div>
											<div class="ui-g-12 ui-md-2">
												<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="childCategory.data.isExcluded = false ; onTableDataCheckBoxValueChange(childCategory , false , 'isCategory')">X</a>
											</div>
										</ng-container>
									</ng-container>
								</ng-container>

							</ng-container>
						</ng-container>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="locationsOptionsSelected">
					<ng-container>
						<div class="ui-g-12 ui-md-12">
							<a class="cursor-pointer" (click)="activeTabIndex=3" [ngStyle]='{"font-weight":"bold"}'>Locations:</a>
						</div>
						<ng-container *ngIf="isIncludeAllLocationsOptions">
							<div class="ui-g-12 ui-md-10">
								<a class="cursor-pointer" (click)="activeTabIndex=3" [ngStyle]='{"font-weight":"bold"}'>Include all Locations</a>
							</div>
							<div class="ui-g-12 ui-md-2">
								<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="isIncludeAllLocationsOptions = false ; selectAllOptions(locations , isIncludeAllLocationsOptions , true)">X</a>
							</div>
						</ng-container>
						<ng-container *ngIf="isExcludeAllLocationsOptions">
							<div class="ui-g-12 ui-md-10">
								<a class="cursor-pointer" (click)="activeTabIndex=3" [ngStyle]='{"font-weight":"bold"}'>Exclude all locations</a>
							</div>
							<div class="ui-g-12 ui-md-2">
								<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="isExcludeAllLocationsOptions = false ; selectAllOptions(locations , isExcludeAllCategoriesOptions , false)">X</a>
							</div>
						</ng-container>
						<ng-container *ngIf="!isIncludeAllLocationsOptions && !isExcludeAllLocationsOptions">
							<ng-container *ngFor="let location of locations">
								<ng-container *ngIf="location?.data?.isIncluded">
									<div class="ui-g-12 ui-md-10">
										<a class="cursor-pointer" (click)="activeTabIndex=3" [ngStyle]='{"font-weight":"bold"}'>Include {{location?.data?.name}}</a>
									</div>
									<div class="ui-g-12 ui-md-2">
										<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="location.data.isIncluded = false ; onTableDataCheckBoxValueChange(location , true , 'isLocation')">X</a>
									</div>
								</ng-container>
								<ng-container *ngIf="location?.data?.isExcluded">
									<div class="ui-g-12 ui-md-10">
										<a class="cursor-pointer" (click)="activeTabIndex=3" [ngStyle]='{"font-weight":"bold"}'>Exclude {{location?.data?.name}}</a>
									</div>
									<div class="ui-g-12 ui-md-2">
										<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="location.data.isExcluded = false ; onTableDataCheckBoxValueChange(location , false , 'isLocation')">X</a>
									</div>
								</ng-container>
								<ng-container *ngIf="!location?.data?.isIncluded && !location?.data?.isExcluded">
									<ng-container *ngFor="let childLocation of location?.children ">
										<ng-container *ngIf="childLocation?.data?.isIncluded">
											<div class="ui-g-12 ui-md-10">
												<a class="cursor-pointer" (click)="activeTabIndex=4" [ngStyle]='{"font-weight":"bold"}'>Include {{childLocation?.data?.name}}</a>
											</div>
											<div class="ui-g-12 ui-md-2">
												<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="childLocation.data.isIncluded = false ; onTableDataCheckBoxValueChange(childLocation , true , 'isLocation')">X</a>
											</div>
										</ng-container>
										<ng-container *ngIf="childLocation?.data?.isExcluded">
											<div class="ui-g-12 ui-md-10">
												<a class="cursor-pointer" (click)="activeTabIndex=4" [ngStyle]='{"font-weight":"bold"}'>Exclude {{childLocation?.data?.name}}</a>
											</div>
											<div class="ui-g-12 ui-md-2">
												<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="childLocation.data.isExcluded = false ; onTableDataCheckBoxValueChange(childLocation , false , 'isLocation')">X</a>
											</div>
										</ng-container>
										<ng-container *ngIf="!childLocation?.data?.isIncluded && !childLocation?.data?.isExcluded">
											<ng-container *ngFor="let innerChildLocation of childLocation?.children ">
												<ng-container *ngIf="innerChildLocation?.data?.isIncluded">
													<div class="ui-g-12 ui-md-10">
														<a class="cursor-pointer" (click)="activeTabIndex=4" [ngStyle]='{"font-weight":"bold"}'>Include {{innerChildLocation?.data?.name}}</a>
													</div>
													<div class="ui-g-12 ui-md-2">
														<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="innerChildLocation.data.isIncluded = false ; onTableDataCheckBoxValueChange(innerChildLocation , true , 'isLocation')">X</a>
													</div>
												</ng-container>
												<ng-container *ngIf="innerChildLocation?.data?.isExcluded">
													<div class="ui-g-12 ui-md-10">
														<a class="cursor-pointer" (click)="activeTabIndex=4" [ngStyle]='{"font-weight":"bold"}'>Exclude {{innerChildLocation?.data?.name}}</a>
													</div>
													<div class="ui-g-12 ui-md-2">
														<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="innerChildLocation.data.isExcluded = false ; onTableDataCheckBoxValueChange(innerChildLocation , false , 'isLocation')">X</a>
													</div>
												</ng-container>

											</ng-container>
										</ng-container>
									</ng-container>
								</ng-container>

							</ng-container>
						</ng-container>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="developmentTypesOptionsSelected">
					<ng-container class="ui-g form-group">
						<div class="ui-g-12 ui-md-12">
							<a class="cursor-pointer" (click)="activeTabIndex=4" [ngStyle]='{"font-weight":"bold"}'>Development Types:</a>
						</div>
						<ng-container *ngIf="isIncludeAllDevelopmentTypesOptions">
							<div class="ui-g-12 ui-md-10">
								<a class="cursor-pointer" (click)="activeTabIndex=4" [ngStyle]='{"font-weight":"bold"}'>Include all development Types</a>
							</div>
							<div class="ui-g-12 ui-md-2">
								<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="isIncludeAllDevelopmentTypesOptions = false ; selectAllOptions(developmentTypes , isIncludeAllDevelopmentTypesOptions , true)">X</a>
							</div>
						</ng-container>
						<ng-container *ngIf="isExcludeAllDevelopmentTypesOptions">
							<div class="ui-g-12 ui-md-10">
								<a class="cursor-pointer" (click)="activeTabIndex=4" [ngStyle]='{"font-weight":"bold"}'>Exclude all development types</a>
							</div>
							<div class="ui-g-12 ui-md-2">
								<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="isExcludeAllDevelopmentTypesOptions = false ; selectAllOptions(developmentTypes , isExcludeAllDevelopmentTypesOptions , false)">X</a>
							</div>
						</ng-container>
						<ng-container *ngIf="!isIncludeAllDevelopmentTypesOptions && !isExcludeAllDevelopmentTypesOptions">
							<ng-container *ngFor="let developmentType of developmentTypes">
								<ng-container *ngIf="developmentType?.data?.isIncluded">
									<div class="ui-g-12 ui-md-10">
										<a class="cursor-pointer" (click)="activeTabIndex=4" [ngStyle]='{"font-weight":"bold"}'>Include {{developmentType?.data?.name}}</a>
									</div>
									<div class="ui-g-12 ui-md-2">
										<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="developmentType.data.isIncluded = false ; onTableDataCheckBoxValueChange(developmentType , true , 'isDevelopmentType')">X</a>
									</div>
								</ng-container>
								<ng-container *ngIf="developmentType?.data?.isExcluded">
									<div class="ui-g-12 ui-md-10">
										<a class="cursor-pointer" (click)="activeTabIndex=4" [ngStyle]='{"font-weight":"bold"}'>Exclude {{developmentType?.data?.name}}</a>
									</div>
									<div class="ui-g-12 ui-md-2">
										<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="developmentType.data.isExcluded = false ; onTableDataCheckBoxValueChange(developmentType , false , 'isDevelopmentType')">X</a>
									</div>
								</ng-container>
							</ng-container>
						</ng-container>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="contractTypesOptionsSelected">
					<ng-container>
						<div class="ui-g-12 ui-md-12">
							<a class="cursor-pointer" (click)="activeTabIndex=5" [ngStyle]='{"font-weight":"bold"}'>Contract Types:</a>
						</div>
						<ng-container *ngIf="isIncludeAllContractTypesOptions">
							<div class="ui-g-12 ui-md-10">
								<a class="cursor-pointer" (click)="activeTabIndex=5" [ngStyle]='{"font-weight":"bold"}'>Include all contract types</a>
							</div>
							<div class="ui-g-12 ui-md-2">
								<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="isIncludeAllContractTypesOptions = false ; selectAllOptions(contractTypes , isIncludeAllContractTypesOptions , true)">X</a>
							</div>
						</ng-container>
						<ng-container *ngIf="isExcludeAllContractTypesOptions">
							<div class="ui-g-12 ui-md-10">
								<a class="cursor-pointer" (click)="activeTabIndex=5" [ngStyle]='{"font-weight":"bold"}'>Exclude all ContractTypes</a>
							</div>
							<div class="ui-g-12 ui-md-2">
								<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="isExcludeAllContractTypesOptions = false ; selectAllOptions(contractTypes , isExcludeAllContractTypesOptions , false)">X</a>
							</div>
						</ng-container>
						<ng-container *ngIf="!isIncludeAllContractTypesOptions && !isExcludeAllContractTypesOptions">
							<ng-container *ngFor="let contractType of contractTypes">
								<ng-container *ngIf="contractType?.data?.isIncluded">
									<div class="ui-g-12 ui-md-10">
										<a class="cursor-pointer" (click)="activeTabIndex=5" [ngStyle]='{"font-weight":"bold"}'>Include {{contractType?.data?.name}}</a>
									</div>
									<div class="ui-g-12 ui-md-2">
										<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="contractType.data.isIncluded = false ; onTableDataCheckBoxValueChange(contractType , true , 'isContractType')">X</a>
									</div>
								</ng-container>
								<ng-container *ngIf="contractType?.data?.isExcluded">
									<div class="ui-g-12 ui-md-10">
										<a class="cursor-pointer" (click)="activeTabIndex=5" [ngStyle]='{"font-weight":"bold"}'>Exclude {{contractType?.data?.name}}</a>
									</div>
									<div class="ui-g-12 ui-md-2">
										<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="contractType.data.isExcluded = false ; onTableDataCheckBoxValueChange(contractType , false , 'isContractType')">X</a>
									</div>
								</ng-container>
							</ng-container>
						</ng-container>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="rolesOptionsSelected">
					<ng-container>
						<div class="ui-g-12 ui-md-12">
							<a class="cursor-pointer" (click)="activeTabIndex=6" [ngStyle]='{"font-weight":"bold"}'>Roles:</a>
						</div>
						<ng-container *ngIf="isIncludeAllRolesOptions">
							<div class="ui-g-12 ui-md-10">
								<a class="cursor-pointer" (click)="activeTabIndex=6" [ngStyle]='{"font-weight":"bold"}'>Include all roles</a>
							</div>
							<div class="ui-g-12 ui-md-2">
								<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="isIncludeAllRolesOptions = false ; selectAllOptions(roles , isIncludeAllRolesOptions , true)">X</a>
							</div>
						</ng-container>
						<ng-container *ngIf="isExcludeAllRolesOptions">
							<div class="ui-g-12 ui-md-10">
								<a class="cursor-pointer" (click)="activeTabIndex=6" [ngStyle]='{"font-weight":"bold"}'>Exclude all roles</a>
							</div>
							<div class="ui-g-12 ui-md-2">
								<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="isExcludeAllRolesOptions = false ; selectAllOptions(roles , isExcludeAllRolesOptions , false)">X</a>
							</div>
						</ng-container>
						<ng-container *ngIf="!isIncludeAllRolesOptions && !isExcludeAllRolesOptions">
							<ng-container *ngFor="let role of roles">
								<ng-container *ngIf="role?.data?.isIncluded">
									<div class="ui-g-12 ui-md-10">
										<a class="cursor-pointer" (click)="activeTabIndex=6" [ngStyle]='{"font-weight":"bold"}'>Include {{role?.data?.name}}</a>
									</div>
									<div class="ui-g-12 ui-md-2">
										<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="role.data.isIncluded = false ; onTableDataCheckBoxValueChange(role , true , 'isRole')">X</a>
									</div>
								</ng-container>
								<ng-container *ngIf="role?.data?.isExcluded">
									<div class="ui-g-12 ui-md-10">
										<a class="cursor-pointer" (click)="activeTabIndex=6" [ngStyle]='{"font-weight":"bold"}'>Exclude {{role?.data?.name}}</a>
									</div>
									<div class="ui-g-12 ui-md-2">
										<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="role.data.isExcluded = false ; onTableDataCheckBoxValueChange(role , false , 'isRole')">X</a>
									</div>
								</ng-container>
							</ng-container>
						</ng-container>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="materialsOptionsSelected">
					<ng-container>
						<div class="ui-g-12 ui-md-12">
							<a class="cursor-pointer" (click)="activeTabIndex=7" [ngStyle]='{"font-weight":"bold"}'>Materials:</a>
						</div>
						<ng-container *ngIf="isIncludeAllMaterialsOptions">
							<div class="ui-g-12 ui-md-10">
								<a class="cursor-pointer" (click)="activeTabIndex=7" [ngStyle]='{"font-weight":"bold"}'>Include all materials</a>
							</div>
							<div class="ui-g-12 ui-md-2">
								<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="isIncludeAllMaterialsOptions = false ; selectAllOptions(materials , isIncludeAllMaterialsOptions , true)">X</a>
							</div>
						</ng-container>
						<ng-container *ngIf="!isIncludeAllMaterialsOptions">
							<ng-container *ngFor="let material of materials">
								<ng-container *ngIf="material?.data?.isIncluded">
									<div class="ui-g-12 ui-md-10">
										<a class="cursor-pointer" (click)="activeTabIndex=7" [ngStyle]='{"font-weight":"bold"}'>Include {{material?.data?.name}}</a>
									</div>
									<div class="ui-g-12 ui-md-2">
										<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="material.data.isIncluded = false ; onTableDataCheckBoxValueChange(material , true , 'isMaterial')">X</a>
									</div>
								</ng-container>
								<ng-container *ngIf="material?.data?.isExcluded">
									<div class="ui-g-12 ui-md-10">
										<a class="cursor-pointer" (click)="activeTabIndex=7" [ngStyle]='{"font-weight":"bold"}'>Exclude {{material?.data?.name}}</a>
									</div>
									<div class="ui-g-12 ui-md-2">
										<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="material.data.isExcluded = false ; onTableDataCheckBoxValueChange(material , false , 'isMaterial')">X</a>
									</div>
								</ng-container>
								<ng-container *ngIf="!material?.data?.isIncluded && !material?.data?.isExcluded">
									<ng-container *ngFor="let childMaterial of material?.children">
										<ng-container *ngIf="childMaterial?.data?.isIncluded">
											<div class="ui-g-12 ui-md-10">
												<a class="cursor-pointer" (click)="activeTabIndex=7" [ngStyle]='{"font-weight":"bold"}'>Include {{childMaterial?.data?.name}}</a>
											</div>
											<div class="ui-g-12 ui-md-2">
												<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="childMaterial.data.isIncluded = false ; onTableDataCheckBoxValueChange(childMaterial , true , 'isMaterial')">X</a>
											</div>
										</ng-container>
										<ng-container *ngIf="childMaterial?.data?.isExcluded">
											<div class="ui-g-12 ui-md-10">
												<a class="cursor-pointer" (click)="activeTabIndex=7" [ngStyle]='{"font-weight":"bold"}'>Exclude {{childMaterial?.data?.name}}</a>
											</div>
											<div class="ui-g-12 ui-md-2">
												<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="childMaterial.data.isExcluded = false ; onTableDataCheckBoxValueChange(childMaterial , false , 'isMaterial')">X</a>
											</div>
										</ng-container>
										<ng-container *ngIf="!childMaterial?.data?.isIncluded && !childMaterial?.data?.isExcluded">
											<ng-container *ngFor="let innerChildMaterial of childMaterial?.children ">
												<ng-container *ngIf="innerChildMaterial?.data?.isIncluded">
													<div class="ui-g-12 ui-md-10">
														<a class="cursor-pointer" (click)="activeTabIndex=7" [ngStyle]='{"font-weight":"bold"}'>Include {{innerChildMaterial?.data?.name}}</a>
													</div>
													<div class="ui-g-12 ui-md-2">
														<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="innerChildMaterial.data.isIncluded = false ; onTableDataCheckBoxValueChange(innerChildMaterial , true , 'isMaterial')">X</a>
													</div>
												</ng-container>
												<ng-container *ngIf="innerChildMaterial?.data?.isExcluded">
													<div class="ui-g-12 ui-md-10">
														<a class="cursor-pointer" (click)="activeTabIndex=7" [ngStyle]='{"font-weight":"bold"}'>Exclude {{innerChildMaterial?.data?.name}}</a>
													</div>
													<div class="ui-g-12 ui-md-2">
														<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="innerChildMaterial.data.isExcluded = false ; onTableDataCheckBoxValueChange(innerChildMaterial , false , 'isMaterial')">X</a>
													</div>
												</ng-container>
											</ng-container>
										</ng-container>
									</ng-container>
								</ng-container>
							</ng-container>
						</ng-container>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="fundingOptionsSelected">
					<ng-container>
						<div class="ui-g-12 ui-md-12">
							<a class="cursor-pointer" (click)="activeTabIndex=8" [ngStyle]='{"font-weight":"bold"}'>Funding:</a>
						</div>
						<ng-container *ngIf="isIncludeAllFundingOptions">
							<div class="ui-g-12 ui-md-10">
								<a class="cursor-pointer" (click)="activeTabIndex=8" [ngStyle]='{"font-weight":"bold"}'>Include all funding</a>
							</div>
							<div class="ui-g-12 ui-md-2">
								<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="isIncludeAllFundingOptions = false ; selectAllOptions(funding , isIncludeAllFundingOptions , true)">X</a>
							</div>
						</ng-container>
						<ng-container *ngIf="isExcludeAllFundingOptions">
							<div class="ui-g-12 ui-md-10">
								<a class="cursor-pointer" (click)="activeTabIndex=8" [ngStyle]='{"font-weight":"bold"}'>Exclude all funding</a>
							</div>
							<div class="ui-g-12 ui-md-2">
								<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="isExcludeAllRolesOptions = false ; selectAllOptions(funding , isExcludeAllFundingOptions , false)">X</a>
							</div>
						</ng-container>
						<ng-container *ngIf="!isIncludeAllFundingOptions && !isExcludeAllFundingOptions">
							<ng-container *ngFor="let fund of funding">
								<ng-container *ngIf="fund?.data?.isIncluded">
									<div class="ui-g-12 ui-md-10">
										<a class="cursor-pointer" (click)="activeTabIndex=8" [ngStyle]='{"font-weight":"bold"}'>Include {{fund?.data?.name}}</a>
									</div>
									<div class="ui-g-12 ui-md-2">
										<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="fund.data.isIncluded = false ; onTableDataCheckBoxValueChange(fund , true , 'isFunding')">X</a>
									</div>
								</ng-container>
								<ng-container *ngIf="fund?.data?.isExcluded">
									<div class="ui-g-12 ui-md-10">
										<a class="cursor-pointer" (click)="activeTabIndex=8" [ngStyle]='{"font-weight":"bold"}'>Exclude {{fund?.data?.name}}</a>
									</div>
									<div class="ui-g-12 ui-md-2">
										<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="fund.data.isExcluded = false ; onTableDataCheckBoxValueChange(fund , false , 'isFunding')">X</a>
									</div>
								</ng-container>
							</ng-container>
						</ng-container>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="searchRefine.StartDate">
					<div class="ui-g-12 ui-md-10">
						<a class="cursor-pointer" (click)="activeTabIndex=9" [ngStyle]='{"font-weight":"bold"}'>Start Date: {{searchRefine.StartDate}} </a>
					</div>
					<div class="ui-g-12 ui-md-2">
						<div>
							<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="searchRefine.StartDate=null">X</a>
						</div>
					</div>
				</ng-container>
				<ng-contanier *ngIf="searchRefine.EndDate">
					<div class="ui-g-12 ui-md-10">
						<a class="cursor-pointer" (click)="activeTabIndex=9" [ngStyle]='{"font-weight":"bold"}'>End Date: {{searchRefine.EndDate}} </a>
					</div>
					<div class="ui-g-12 ui-md-2">
						<div>
							<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="searchRefine.EndDate=null">X</a>
						</div>
					</div>
				</ng-contanier>
				<ng-contanier *ngIf="searchRefine.TenderCloseDate">
					<div class="ui-g-12 ui-md-10">
						<a class="cursor-pointer" (click)="activeTabIndex=9" [ngStyle]='{"font-weight":"bold"}'>Tender Close Date: {{searchRefine.TenderCloseDate}} </a>
					</div>
					<div class="ui-g-12 ui-md-2">
						<div>
							<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="searchRefine.TenderCloseDate=null">X</a>
						</div>
					</div>
				</ng-contanier>
				<ng-contanier *ngIf="searchRefine.minValue || searchRefine.maxValue">
					<div class="ui-g-12 ui-md-10">
						<a class="cursor-pointer" (click)="activeTabIndex=10" [ngStyle]='{"font-weight":"bold"}'>Value: {{searchRefine.minValue && searchRefine.maxValue ? 'From' : ''}} {{searchRefine.minValue}} {{searchRefine.minValue && searchRefine.maxValue ? 'to' : ''}} {{searchRefine.maxValue}} </a>
					</div>
					<div class="ui-g-12 ui-md-2">
						<div>
							<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="searchRefine.minValue=null; searchRefine.maxValue= null">X</a>
						</div>
					</div>
				</ng-contanier>
				<ng-contanier *ngIf="searchRefine.minUnits || searchRefine.maxUnits">
					<div class="ui-g-12 ui-md-10">
						<a class="cursor-pointer" (click)="activeTabIndex=10" [ngStyle]='{"font-weight":"bold"}'>Units: {{searchRefine.minUnits && searchRefine.maxUnits ? 'From' : ''}} {{searchRefine.minUnits}} {{searchRefine.minUnits && searchRefine.maxUnits ? 'to' : ''}} {{searchRefine.maxUnits}} </a>
					</div>
					<div class="ui-g-12 ui-md-2">
						<div>
							<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="searchRefine.minUnits=null; searchRefine.maxUnits= null">X</a>
						</div>
					</div>
				</ng-contanier>
				<ng-contanier *ngIf="searchRefine.minTotalFloorArea || searchRefine.maxTotalFloorArea">
					<div class="ui-g-12 ui-md-10">
						<a class="cursor-pointer" (click)="activeTabIndex=10" [ngStyle]='{"font-weight":"bold"}'>Total Floor Area(m2): {{searchRefine.minTotalFloorArea && searchRefine.maxTotalFloorArea ? 'From' : ''}} {{searchRefine.minTotalFloorArea}} {{searchRefine.minTotalFloorArea && searchRefine.maxTotalFloorArea ? 'to' : ''}} {{searchRefine.maxTotalFloorArea}} </a>
					</div>
					<div class="ui-g-12 ui-md-2">
						<div>
							<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="searchRefine.minTotalFloorArea=null; searchRefine.maxTotalFloorArea= null">X</a>
						</div>
					</div>
				</ng-contanier>
				<ng-contanier *ngIf="searchRefine.minGroundFloorArea || searchRefine.maxGroundFloorArea">
					<div class="ui-g-12 ui-md-10">
						<a class="cursor-pointer" (click)="activeTabIndex=10" [ngStyle]='{"font-weight":"bold"}'>Ground Floor Area(m2): {{searchRefine.minGroundFloorArea && searchRefine.maxGroundFloorArea ? 'From' : ''}} {{searchRefine.minGroundFloorArea}} {{searchRefine.minGroundFloorArea && searchRefine.maxGroundFloorArea ? 'to' : ''}} {{searchRefine.maxGroundFloorArea}} </a>
					</div>
					<div class="ui-g-12 ui-md-2">
						<div>
							<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="searchRefine.minGroundFloorArea=null; searchRefine.maxGroundFloorArea= null">X</a>
						</div>
					</div>
				</ng-contanier>
				<ng-contanier *ngIf="searchRefine.minSiteArea || searchRefine.maxSiteArea">
					<div class="ui-g-12 ui-md-10">
						<a class="cursor-pointer" (click)="activeTabIndex=10" [ngStyle]='{"font-weight":"bold"}'>Site Area(m2): {{searchRefine.minSiteArea && searchRefine.maxSiteArea ? 'From' : ''}} {{searchRefine.minSiteArea}} {{searchRefine.minSiteArea && searchRefine.maxSiteArea ? 'to' : ''}} {{searchRefine.maxSiteArea}} </a>
					</div>
					<div class="ui-g-12 ui-md-2">
						<div>
							<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="searchRefine.minSiteArea=null; searchRefine.maxSiteArea= null">X</a>
						</div>
					</div>
				</ng-contanier>
				<ng-contanier *ngIf="searchRefine.minStoreysAboveGround || searchRefine.maxStoreysAboveGround">
					<div class="ui-g-12 ui-md-10">
						<a class="cursor-pointer" (click)="activeTabIndex=10" [ngStyle]='{"font-weight":"bold"}'>Storeys Above Ground: {{searchRefine.minStoreysAboveGround && searchRefine.maxStoreysAboveGround ? 'From' : ''}} {{searchRefine.minStoreysAboveGround}} {{searchRefine.minStoreysAboveGround && searchRefine.maxStoreysAboveGround ? 'to' : ''}} {{searchRefine.maxStoreysAboveGround}} </a>
					</div>
					<div class="ui-g-12 ui-md-2">
						<div>
							<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="searchRefine.minStoreysAboveGround=null; searchRefine.maxStoreysAboveGround= null">X</a>
						</div>
					</div>
				</ng-contanier>
				<ng-contanier *ngIf="searchRefine.minStoreysBelowGround || searchRefine.maxStoreysBelowGround">
					<div class="ui-g-12 ui-md-10">
						<a class="cursor-pointer" (click)="activeTabIndex=10" [ngStyle]='{"font-weight":"bold"}'>Storeys Below Ground: {{searchRefine.minStoreysBelowGround && searchRefine.maxStoreysBelowGround ? 'From' : ''}} {{searchRefine.minStoreysBelowGround}} {{searchRefine.minStoreysBelowGround && searchRefine.maxStoreysBelowGround ? 'to' : ''}} {{searchRefine.maxStoreysBelowGround}} </a>
					</div>
					<div class="ui-g-12 ui-md-2">
						<div>
							<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="searchRefine.minStoreysBelowGround=null; searchRefine.maxStoreysBelowGround= null">X</a>
						</div>
					</div>
				</ng-contanier>
				<ng-contanier *ngIf="searchRefine.minParkingSpaces || searchRefine.maxParkingSpaces">
					<div class="ui-g-12 ui-md-10">
						<a class="cursor-pointer" (click)="activeTabIndex=10" [ngStyle]='{"font-weight":"bold"}'>Parking Spaces: {{searchRefine.minParkingSpaces && searchRefine.maxParkingSpaces ? 'From' : ''}} {{searchRefine.minParkingSpaces}} {{searchRefine.minParkingSpaces && searchRefine.maxParkingSpaces ? 'to' : ''}} {{searchRefine.maxParkingSpaces}} </a>
					</div>
					<div class="ui-g-12 ui-md-2">
						<div>
							<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="searchRefine.minParkingSpaces=null; searchRefine.maxParkingSpaces= null">X</a>
						</div>
					</div>
				</ng-contanier>
				<ng-contanier *ngIf="searchRefine?.company">
					<div class="ui-g-12 ui-md-10 word-break">
						<a class="cursor-pointer" (click)="activeTabIndex=11" [ngStyle]='{"font-weight":"bold"}'>company_text: {{searchRefine?.company}} </a>
					</div>
					<div class="ui-g-12 ui-md-2">
						<div>
							<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="searchRefine.company = null">X</a>
						</div>
					</div>
				</ng-contanier>
				<ng-contanier *ngIf="searchRefine.Distance">
					<div class="ui-g-12 ui-md-10">
						<a class="cursor-pointer" (click)="activeTabIndex=12" [ngStyle]='{"font-weight":"bold"}'>
							Search by company or project distance: {{searchRefine?.Distance}} miles
						</a>
					</div>
					<div class="ui-g-12 ui-md-2">
						<div>
							<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="searchRefine.SearchByCompanyOrProjectDistance=null ; searchRefine.UseCurrentLocation = false; searchRefine.Distance = 0">X</a>
						</div>
					</div>
				</ng-contanier>
				<ng-contanier *ngIf="favouriteOnly">
					<div class="ui-g-12 ui-md-10">
						<a class="cursor-pointer" (click)="activeTabIndex=13" [ngStyle]='{"font-weight":"bold"}'>Favorites Only: {{favouriteOnly}} </a>
					</div>
					<div class="ui-g-12 ui-md-2">
						<div>
							<a class="cursor-pointer" [ngStyle]='{"font-weight":"bold"}' (click)="favouriteOnly=false">X</a>
						</div>
					</div>
				</ng-contanier>
				<!-- {POSTCLOSE:66d0aade-f69e-4a23-860d-f6c22bfb611c} {} -->
			</p-fieldset>
			<!-- {POSTCLOSE:66d0aade-f69e-4a23-860d-f6c22bfb611c} {"sha1":"23E1D9C0F4A80D0DB30054660C6131F692F3E48D"} -->
			<!-- {CLOSE:768e4360-a30a-483f-bc41-4d4d818ea074} {} -->
		</div>
		<!-- {POSTCLOSE:768e4360-a30a-483f-bc41-4d4d818ea074} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
		<!-- {CLOSE:032428c8-f08d-4251-89ae-c43a19321b88} {} -->
	</div>
	<!-- {POSTCLOSE:032428c8-f08d-4251-89ae-c43a19321b88} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
	<!-- {PREOPEN:d71d9670-6f1f-4e91-abc9-e45d5ebc7b72} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
	<div class="ui-g form-group">
		<!-- {OPEN:d71d9670-6f1f-4e91-abc9-e45d5ebc7b72} {} -->
		<!-- {PREOPEN:6eb30ea1-986a-4f75-8a33-2c84b5f28992} {"sha1":"4674279FC445E84D87806A639FB40CFE07D3EB0A"} -->
		<div class="ui-g-12 ui-md-8">
			<!-- {OPEN:6eb30ea1-986a-4f75-8a33-2c84b5f28992} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
			<!-- {CLOSE:6eb30ea1-986a-4f75-8a33-2c84b5f28992} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
		</div>
		<!-- {POSTCLOSE:6eb30ea1-986a-4f75-8a33-2c84b5f28992} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
		<!-- {PREOPEN:bf3a4b84-cb37-459a-b2c0-dc542f25a978} {"sha1":"2183A3A9ABCF2FD844436EF69F76172FF147BB12"} -->
		<div class="ui-g-12 ui-md-4">
			<!-- {OPEN:bf3a4b84-cb37-459a-b2c0-dc542f25a978} {} -->
			<!-- {OPEN:13893029-7d5d-4431-b329-b3a97bf3bde3} {"sha1":"64C4EC6B9C4BF22D8600B3BBDFD7C3AEDF713D70"} -->
			<div [style.text-align]="'right'" [style.paddingTop.px]="'10'" [style.paddingLeft.px]="'10'" [style.paddingBottom.px]="'10'" [style.paddingRight.px]="'10'">
				<button [style.borderRadius]="'5px'" pButton type="button" [label]="totalFilters ? 'Search (' + totalFilters + ')' : 'Search'" [style.width]="'250px'" (click)="onSearchClick($event, searchRefine)"></button>
			</div>
			<!-- {CLOSE:13893029-7d5d-4431-b329-b3a97bf3bde3} {"sha1":"64C4EC6B9C4BF22D8600B3BBDFD7C3AEDF713D70"} -->
			<!-- {CLOSE:bf3a4b84-cb37-459a-b2c0-dc542f25a978} {} -->
		</div>
		<!-- {POSTCLOSE:bf3a4b84-cb37-459a-b2c0-dc542f25a978} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
		<!-- {CLOSE:d71d9670-6f1f-4e91-abc9-e45d5ebc7b72} {} -->
	</div>
	<!-- {POSTCLOSE:d71d9670-6f1f-4e91-abc9-e45d5ebc7b72} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
	<!-- {CLOSE:8fd7969e-22ca-4666-a006-8eb461105536} {} -->
	<oneit-confirmDialog></oneit-confirmDialog>
	<p-progressSpinner *ngIf="showLoader"></p-progressSpinner>
	<!-- {CLOSE:2e841203-4c80-4f6a-aa12-40b7f76e88eb} {} -->
</form>
<!-- {POSTCLOSE:2e841203-4c80-4f6a-aa12-40b7f76e88eb} {"sha1":"3FD6E659562C1876D02FC8CF54746B1502442CD2"} -->
