<ul [ngClass]="{'ui-widget-content ui-corner-all ui-shadow ui-submenu-list': !root}" (click)="listClick($event)">
    <ng-template ngFor let-child [ngForOf]="(root ? item : item.items)">
        <li *ngIf="child.separator" class="ui-menu-separator ui-widget-content"
            [ngClass]="{'ui-helper-hidden': child.visible === false}">
        <li *ngIf="!child.separator" #listItem
            [ngClass]="{'ui-menuitem ui-widget ui-corner-all':true,'ui-menuitem-active':listItem==activeItem,'ui-helper-hidden': child.visible === false, 'has-right-icon': child.rightIcon}"
            [class]="child.styleClass" [ngStyle]="child.style" (mouseenter)="onItemMouseEnter($event, listItem, child)"
            (mouseleave)="onItemMouseLeave($event)">
            <ng-container *ngIf="customButtonRef">
                <ng-container *ngTemplateOutlet="customButtonRef; context: {$implicit: child}" >
                </ng-container>
            </ng-container>
            <a *ngIf="!child.routerLink" [href]="child.url||'#'" class="ui-menuitem-link ui-corner-all"
                [attr.target]="child.target" [attr.title]="child.title" [attr.id]="child.id"
                [ngClass]="{'ui-state-disabled':child.disabled}" (click)="itemClick($event, child)">
                <span class="ui-menuitem-icon" *ngIf="child.icon" [ngClass]="child.icon"></span>
                <span class="ui-menuitem-text">{{child.label}}</span>
                <span class="ui-submenu-icon pi pi-fw pi-caret-right" *ngIf="child.items"></span>
            </a>
            <a *ngIf="child.routerLink" [routerLink]="child.routerLink" [queryParams]="child.queryParams"
                [routerLinkActive]="'ui-state-active'"
                [routerLinkActiveOptions]="child.routerLinkActiveOptions||{exact:false}" [href]="child.url||'#'"
                class="ui-menuitem-link ui-corner-all" [attr.target]="child.target" [attr.title]="child.title"
                [attr.id]="child.id" [ngClass]="{'ui-state-disabled':child.disabled}"
                (click)="itemClick($event, child)">
                <span class="ui-menuitem-icon" *ngIf="child.icon" [ngClass]="child.icon"></span>
                <span class="ui-menuitem-text">{{child.label}}</span>
                <span class="ui-submenu-icon pi pi-fw pi-caret-right" *ngIf="child.items"></span>
            </a>
           
            <span class="ui-submenu-icon ui-submenu-right-icon {{ child.rightIcon }}" *ngIf="child.rightIcon"
                (click)="rightIconClick($event, child)" [title]="child.rightIconTitle"></span>
            <custom-tieredMenuSub class="ui-submenu" [item]="child" *ngIf="child.items" [baseZIndex]="baseZIndex"
                [autoZIndex]="autoZIndex" [hideDelay]="hideDelay"></custom-tieredMenuSub>
        </li>
    </ng-template>
</ul>
