import { Injectable } from '@angular/core';
import _ from 'lodash';
import { AppConstants } from '../constants/app-constants';

@Injectable()
export class LocalStorageService {

  constructor() { }

  setModule(module) : void {
    localStorage.setItem(AppConstants.MODULE, module);
  }

  getModule() : string {
    let module = localStorage.getItem(AppConstants.MODULE);
    if(_.isNil(module) || _.isEmpty(module)) {
      module = AppConstants.DEFAULT_MODULE;
      this.setModule(module);
  }
    return module;
  }

  setRefineFilters(filters) {
    localStorage.setItem(AppConstants.REFINE_FILTERS, JSON.stringify({module: this.getModule(), filters}));
  }

  clearRefineFilters() {
    localStorage.removeItem(AppConstants.REFINE_FILTERS);
  }

  getRefineFilters() : any {
   return localStorage.getItem(AppConstants.REFINE_FILTERS);
  }


  getCacheFilters() : any {
    let filters = localStorage.getItem(AppConstants.CACHE_FILTERS);
    if(filters) return JSON.parse(filters);
    return {};
  }


  getSearchResults () {
    return localStorage.getItem(AppConstants.SEARCH_RESULTS);
  }

  setSearchResults(data : any) {
    localStorage.setItem(AppConstants.SEARCH_RESULTS, data);
  }

}
