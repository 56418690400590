import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ConfigService {

    settings: any;

    constructor(
        public httpClient: HttpClient
    ) {

    }

    load(): Promise<any> {
        let oneitConfig = this.httpClient.get('app/oneit/assets/data/oneit.config.json');
        let projectConfig = this.httpClient.get('assets/data/config.json');

        return forkJoin([oneitConfig, projectConfig]).pipe(map(
            result => {
                let oneitJSON = result[0];
                let projectJSON = result[1];

                this.settings = Object.assign(oneitJSON, projectJSON);
            }
        )).toPromise();
    }
}