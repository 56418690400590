<iframe #iframeElem 
        appIframeResizer 
        appIframeTracker 
        (iframeClick)="onIframeClick()" 
        width="100%" 
        [src]="pageURL | safe: 'url'"
        scrolling="no" 
        frameborder="0" 
        style="margin-left: -17px; width: calc(100% + 34px); margin-bottom: -20px;" 
        (load)="onLoad(iframeElem)">
</iframe>
<p-progressSpinner animationDuration="1s" *ngIf="showLoader"></p-progressSpinner>