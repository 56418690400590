import { DataProviderService } from './../../cbos/data-providers/data-provider.service';
/* {OPEN:IMPORT} {"sha1":"BD0FAC4F6E50B6AD8E4609955863444718E96C2A"} */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseDataProviderCrmSelectionComponent } from './base-data-provider-crm-selection.component';
/* {CLOSE:IMPORT} {"sha1":"BD0FAC4F6E50B6AD8E4609955863444718E96C2A"} */
import { ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../oneit/services/user.service';
import { UtilsService } from '../../oneit/services/utils.service';
import { SearchService } from '../../oneit/services/search.service';
import { AppService } from '../../app.service';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { DataProviderCrmSelectionService } from './data-provider-crm-selection.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng';
import { AddDataProviderComponent } from '../add-data-provider/add-data-provider.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MsgsService } from '../../oneit/services/msgs.service';
import { OptionsObservable, OptionsWrapper } from '../../oneit/models/options.model';
import { CRMLoginComponent } from '../crm-login/crm-login.component';
/* {POSTCLOSE:IMPORT} {"sha1":"BD0FAC4F6E50B6AD8E4609955863444718E96C2A"} */

/* {PREOPEN:CLASS} {"sha1":"585FF2EC1713E4BD30EF769A80E1FC232433722F"} */
@Component({
    selector: 'app-data-provider-crm-selection',
    templateUrl: './data-provider-crm-selection.component.html',
    providers: [DialogService]
})
export class DataProviderCrmSelectionComponent extends BaseDataProviderCrmSelectionComponent implements OnInit, OnDestroy {
    /* {OPEN:CLASS} {} */

    user: any = null;

    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
        super.ngOnInit();
        /* {CLOSE:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
    }

    ngOnDestroy() {
        /* {OPEN:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
        super.ngOnDestroy();
        /* {CLOSE:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
    }

    /* {CLOSE:CLASS} {} */
    @ViewChild('form') form: NgForm;
    componentInView = new Subject();
    errorMsgs;
    dataProviderOptions: SelectItem[] = [];
    isAddDataProviderDialogOpen = false;
    isCRMDialogOpen = false;

    //@override
    constructor(
        public router: Router,
        public userService: UserService,
        public utilsService: UtilsService,
        public searchService: SearchService,
        public appService: AppService,
        public confirmationService: ConfirmationService,
        public dataProviderCrmSelectionService: DataProviderCrmSelectionService,
        public dialogService: DialogService,
        public msgsService: MsgsService,
        public dialogRef: DynamicDialogRef,
        public dialogConfig: DynamicDialogConfig,
        private dataProviderService : DataProviderService,
    ) {
        super(router, userService, utilsService, searchService, appService, confirmationService, dataProviderCrmSelectionService);
        this.user = this.dialogConfig.data.user;
    }

    //@override
    executeScreenSpecificInitCode() {
        this.subscriptions.push(
            this.msgsService.errorMsgsUpdated.subscribe(errorMsgs => {
                this.errorMsgs = errorMsgs;
            })
        );
    }

    //@override
    getPleaseselecttheDataProviderOptions(): OptionsWrapper {
        return new OptionsObservable(false, this.appService.getDataProvidersObservable());
    }

    //@override
    resetPleaseselecttheDataProviderOptions() {
      this.dataProviderOptions= [];
        this.showLoader = true;
        let wrapper: OptionsWrapper = this.getPleaseselecttheDataProviderOptions();

        if ( wrapper ) {
            this.isPleaseselecttheDataProviderVolatile = wrapper.isVolatile;

            this.subscriptions.push(
                wrapper.getObservable().subscribe(data => {
                    this.showLoader = false;
                    for ( let item in data ) {
                      if(data[item].registered) this.dataProviderOptions.push(this.dataProviderService.formatDataProviderItem(data, item));
                    }
                }, error => {
                    this.showLoader = false;
                    this.utilsService.handleError(error);
                })
            );
        }
    }

    //@override
    onPleaseselecttheDataProviderChange(event, searchDataProviderCrmSelection) {
        localStorage.setItem('DATA_PROVIDER', JSON.stringify(this.searchDataProviderCrmSelection.DataProvider));
    }

    //@override
    onAddClick(event, searchDataProviderCrmSelection) {
        this.utilsService.clearErrorMessages();

        this.openAddDataProviderDialog();
    }

    //@override
    onNextClick(event, searchDataProviderCrmSelection) {
        this.utilsService.clearErrorMessages();

        if ( !this.form.valid ) {
            this.utilsService.showAllErrorMessages();
            return;
        }

        this.showLoader = true;

        this.subscriptions.push(
            this.dataProviderCrmSelectionService.login().subscribe(() => {
                this.showLoader = false;
                this.userLoggedIn();
            }, error => {
                this.showLoader = false;
                this.utilsService.handleError(error);
            })
        );
    }

    openAddDataProviderDialog() {
        this.isAddDataProviderDialogOpen = true;

        this.dialogService.open(AddDataProviderComponent, {
            header: 'Add Data Provider',
            ...this.appService.dynamicDialogConfig,
            width: '480px',
            height: '380px'
        }).onClose.pipe(takeUntil(this.componentInView)).subscribe((isSuccessfulRes) => {
            this.utilsService.clearErrorMessages();
            this.isAddDataProviderDialogOpen = false;
            if ( isSuccessfulRes ) {
                this.resetPleaseselecttheDataProviderOptions();
                //this.userLoggedIn();
            }
        });
    }

    userLoggedIn() {
        localStorage.setItem('USER_ID', this.user.id);
        localStorage.setItem('USER', JSON.stringify(this.user));

        this.appService.getRefineSearchFilters();
        this.appService.getCacheSearchFilters();


        this.getCRMOptions();
    }

    getCRMOptions() {
        this.showLoader = true;

        this.subscriptions.push(
            this.appService.getCRMOptions().subscribe(data => {
                const isDefaultRegistered = data.find(item => item.default && item.registered);

                if(isDefaultRegistered) {
                    localStorage.setItem('CRM' , isDefaultRegistered);
                    this.crmLogin(isDefaultRegistered.crmDetails.apiKey);
                    return;
                }

                this.showLoader = false;

                this.dialogRef.close();
                //this.router.navigate(['./dashboard']);

                if(!isDefaultRegistered) {
                    this.isCRMDialogOpen = true;
                    this.dialogService.open(CRMLoginComponent , {
                        header: 'CRM Selection',
                        ...this.appService.dynamicDialogConfig,
                        width: '480px',
                        height: '380px',
                        data: {CRM_Options: data}
                    }).onClose.pipe(takeUntil(this.componentInView)).subscribe(() => {
                        this.isCRMDialogOpen = false;
                        this.utilsService.clearErrorMessages();
                    });
                }
            }, error => {
                this.showLoader = false;
                this.utilsService.handleError(error);
            })
        );
    }

    crmLogin(key) {
        this.subscriptions.push(
            this.appService.crmLogin(key).subscribe(() => {
                this.showLoader = false;
                this.dialogRef.close();
                this.appService.getCRMConfigOptions();
                this.router.navigate(['./dashboard']);
            }, error => {
                this.showLoader = false;
                this.utilsService.handleError(error);
            })
        );

    }

}

/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */
