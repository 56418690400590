export class SearchProject {
    /* {OPEN:SearchProject} {"sha1":"6376B33E5123BF09A271789A272103F957C8F38D"} */
    /* {CLOSE:SearchProject} {"sha1":"6376B33E5123BF09A271789A272103F957C8F38D"} */
    ObjectID;
    SearchProjectCompany;
    Search;
    /* {POSTCLOSE:SearchProject} {"sha1":"6376B33E5123BF09A271789A272103F957C8F38D"} */
}

/* {OPEN:ADDIONAL_MODELS} {"sha1":"ADC83B19E793491B1C6EA0FD8B46CD9F32E592FC"} */
/* {CLOSE:ADDIONAL_MODELS} {"sha1":"ADC83B19E793491B1C6EA0FD8B46CD9F32E592FC"} */
export class SavedSearch {
    object_type;
    query
    saved_search_id;
    saved_search_name;
    is_default;
}
/* {POSTCLOSE:ADDIONAL_MODELS} {"sha1":"ADC83B19E793491B1C6EA0FD8B46CD9F32E592FC"} */
