/* {OPEN:IMPORT} {"sha1":"BAEC8F95BD3A2239F7F3F73DCC61F252E6E4B619"} */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SaveService } from './../../oneit/services/save.service';
import { SearchService } from './../../oneit/services/search.service';
import { UtilsService } from './../../oneit/services/utils.service';
/* {CLOSE:IMPORT} {"sha1":"BAEC8F95BD3A2239F7F3F73DCC61F252E6E4B619"} */
import { AppService } from '../../app.service';
/* {POSTCLOSE:IMPORT} {"sha1":"BAEC8F95BD3A2239F7F3F73DCC61F252E6E4B619"} */

@Injectable()
/* {PREOPEN:CLASS} {"sha1":"960A2824D62BF76E1CC86F021DB7A63C9F60FE09"} */
export class ResetPasswordService {
    /* {OPEN:CLASS} {} */

    constructor(
        /* {OPEN:constructor} {"sha1":"DCCC484B4DA23ED55D1380457121E9DAC4BB6B04"} */
        private searchService: SearchService,
        private saveService: SaveService,
        private utilsService: UtilsService,
        /* {CLOSE:constructor} {"sha1":"DCCC484B4DA23ED55D1380457121E9DAC4BB6B04"} */
        private appService: AppService
        /* {POSTCLOSE:constructor} {"sha1":"DCCC484B4DA23ED55D1380457121E9DAC4BB6B04"} */
    ) {
    }

    /* {PREOPEN:getResetPasswordByID} {"sha1":"E984E6523207938BCB9765D936FE33D1FA918E52"} */
    getResetPasswordByID(id, assocs) : Observable<any> {
        /* {OPEN:getResetPasswordByID} {"sha1":"02FE2C3A50E6D675C817FE71F7ABB778DE5332A7"} */
        return this.searchService.getObjectById('ResetPasswords', id, assocs)
            .pipe(map(
                data => {
                    let objs = this.utilsService.convertResponseToObjects(data, assocs);

                    if (objs && objs.length > 0) {
                        return objs[0];
                    }
                }
            ));
        /* {CLOSE:getResetPasswordByID} {"sha1":"02FE2C3A50E6D675C817FE71F7ABB778DE5332A7"} */
    }
    /* {POSTCLOSE:getResetPasswordByID} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {CLOSE:CLASS} {} */

    validateToken(params): Observable<any> {
        return this.appService.validateToken('auth/validate-token' , params);
    }

    resetPassword(params): Observable<any> {
        return this.appService.changePassword('auth/change-password' , params);
    }
}
/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */
