/* {OPEN:IMPORT} {"sha1":"49C030785F360E6D31961A17D28AFFBC695B71E7"} */
import { Component, DoCheck, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import { Message, MenuItem, ConfirmationService } from 'primeng/api';
import { AppService } from './../../app.service';
import { OptionsObservable, OptionsWrapper } from './../../oneit/models/options.model';
import { UserService } from './../../oneit/services/user.service';
import { UtilsService } from './../../oneit/services/utils.service';
import { SearchService } from './../../oneit/services/search.service';
import { AddCrmService } from './add-crm.service';
import { SearchCrm } from './add-crm.model';
/* {CLOSE:IMPORT} {"sha1":"49C030785F360E6D31961A17D28AFFBC695B71E7"} */

@Component({
    selector: 'app-base-add-crm',
    template: ''
})
/* {PREOPEN:CLASS} {"sha1":"A5744A4441E3776BC34BA89D6480004D941BCC4D"} */
export class BaseAddCrmComponent implements DoCheck, OnInit, OnDestroy {
    /* {OPEN:CLASS} {} */
    
    /* {OPEN:COMMON_VARS} {"sha1":"672A211DEEA9A3559D121CBDC5978BBA47BD6B7D"} */
    @Input() parent : any;
    pageTitle : string;
    /* {CLOSE:COMMON_VARS} {"sha1":"672A211DEEA9A3559D121CBDC5978BBA47BD6B7D"} */

    /* {OPEN:VARS} {"sha1":"EE9950814D5338373CFA5BB35BC9CE03312E9F9B"} */
    searchCrm : SearchCrm = new SearchCrm();
    cRMOptions : any[] = [];
    isCRMVolatile : boolean = false;
    /* {CLOSE:VARS} {"sha1":"EE9950814D5338373CFA5BB35BC9CE03312E9F9B"} */
    
    showResults : boolean = false;
    showLoader : boolean = false;
    subscriptions : Array<Subscription> = [];

    constructor(
        /* {OPEN:constructor} {"sha1":"EDC34B34B01D17DB8088126D0FF7B9D5C7A0ED5B"} */
        public router : Router,
        public userService : UserService,
        public utilsService : UtilsService,
        public searchService : SearchService,
        public appService : AppService,
        public confirmationService: ConfirmationService,
        public addCrmService : AddCrmService,
        /* {CLOSE:constructor} {"sha1":"EDC34B34B01D17DB8088126D0FF7B9D5C7A0ED5B"} */
    ){
        
    }

    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"5FBDAAB044BC4F999356808F755A7334E11EE273"} */
        this.resetInitVariables();
        this.calculateEverything();
        this.executeScreenSpecificInitCode();
        /* {CLOSE:ngOnInit} {"sha1":"5FBDAAB044BC4F999356808F755A7334E11EE273"} */
    }

    /* {PREOPEN:executeScreenSpecificInitCode} {"sha1":"DDD081544316B33777AB7B7FFD796FB9E663E681"} */
    executeScreenSpecificInitCode() {
        /* {OPEN:executeScreenSpecificInitCode} {"sha1":"54DB41AD32937D86C7318EB4BE666A1AD7B6B618"} */
        if(!this.parent) {
        }
        /* {CLOSE:executeScreenSpecificInitCode} {"sha1":"54DB41AD32937D86C7318EB4BE666A1AD7B6B618"} */
    }
    /* {POSTCLOSE:executeScreenSpecificInitCode} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getCRMOptions} {"sha1":"2370CE059C13FE8ABD93EE68B4E682D6BC889B52"} */
    getCRMOptions(): OptionsWrapper {
        /* {OPEN:getCRMOptions} {"sha1":"1D779AEE99B71576BB3840AFBB723434B6CF15E2"} */
        return new OptionsObservable(false, this.appService.getCrmsObservable());
        /* {CLOSE:getCRMOptions} {"sha1":"1D779AEE99B71576BB3840AFBB723434B6CF15E2"} */
    }
    /* {POSTCLOSE:getCRMOptions} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:resetCRMOptions} {"sha1":"027E0E04476F4CE77AC2F177A1713FE525E224DA"} */
    resetCRMOptions() {
        /* {OPEN:resetCRMOptions} {"sha1":"D570CEE455AF5FC059D71804459602B2B46958E5"} */
        if(this.parent) {
            this.parent.resetCRMOptions();
        } else {
            let wrapper : OptionsWrapper = this.getCRMOptions();

            if (wrapper) {
                this.isCRMVolatile = wrapper.isVolatile;

                this.subscriptions.push(wrapper.getObservable()
                    .subscribe (
                        data => {
                            this.cRMOptions = data;
                        },
                        error => {
                            this.utilsService.handleError(error);
                        }
                    )
                );
            }
        }
        /* {CLOSE:resetCRMOptions} {"sha1":"D570CEE455AF5FC059D71804459602B2B46958E5"} */
    }
    /* {POSTCLOSE:resetCRMOptions} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onCRMChange} {"sha1":"BE277DC4E3F12233E407A3E594C91508E6CD162F"} */
    onCRMChange(event, searchCrm) {
        /* {OPEN:onCRMChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:onCRMChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:onCRMChange} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onAddClick} {"sha1":"E9DD92FB65FCACD96D588892B4884AF879DA947F"} */
    onAddClick(event, searchCrm) {
        /* {OPEN:onAddClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onAddClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onAddClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:resetInitVariables} {"sha1":"846C0DF64879342578D595FE17BC00B96F0BAED6"} */
    resetInitVariables() {
        /* {OPEN:resetInitVariables} {} */
        /* {OPEN:resetInitVariables_resetCRMOptions} {"sha1":"40CD7137BA0E1A51D510FA0906F5AFD70E563317"} */
        try {
            this.resetCRMOptions();
        }
        catch (error) {
            console.error('Error occured when executing resetCRMOptions', error);
        }
        /* {CLOSE:resetInitVariables_resetCRMOptions} {"sha1":"40CD7137BA0E1A51D510FA0906F5AFD70E563317"} */
        /* {CLOSE:resetInitVariables} {} */
    }
    /* {POSTCLOSE:resetInitVariables} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getPageTitle} {"sha1":"174A3D30FFA103DD7BE180EE89CC0CF5EB6D11B8"} */
    getPageTitle() {
        /* {OPEN:getPageTitle} {"sha1":"B13813A7577FE1900F94E7B7A42C937F74C8200A"} */
        return 'Add CRM';
        /* {CLOSE:getPageTitle} {"sha1":"B13813A7577FE1900F94E7B7A42C937F74C8200A"} */
    }
    /* {POSTCLOSE:getPageTitle} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:calculateEverything} {"sha1":"CE17BF2375171825A37C544A1DCBF3A565DD5181"} */
    calculateEverything() {
        /* {OPEN:calculateEverything} {} */
        /* {OPEN:calculateEverything_pageTitle} {"sha1":"B3CA5EAE3ED54D1F0D95EA2CD05351A4A6D7480F"} */
        try {
            this.pageTitle = this.getPageTitle();
        }
        catch (error) {
            console.error('Error occured when setting pageTitle');
        }
        /* {CLOSE:calculateEverything_pageTitle} {"sha1":"B3CA5EAE3ED54D1F0D95EA2CD05351A4A6D7480F"} */
        /* {OPEN:calculateEverything_isCRMVolatile} {"sha1":"7F63970C237062123AC28AF6EF04EEFBA4EAAA51"} */
        try {
            if(this.isCRMVolatile) {
                this.resetCRMOptions();
            }
        }
        catch (error) {
            console.error('Error occured when resetting cRMOptions');
        }
        /* {CLOSE:calculateEverything_isCRMVolatile} {"sha1":"7F63970C237062123AC28AF6EF04EEFBA4EAAA51"} */
        /* {CLOSE:calculateEverything} {} */
    }
    /* {POSTCLOSE:calculateEverything} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:ngDoCheck} {"sha1":"75839B27D41E20648CE296AC51D61E24D541A70A"} */
    ngDoCheck() {
        /* {OPEN:ngDoCheck} {"sha1":"A154E0663059B6D385B4F6C11094F2084FF5E184"} */
        this.calculateEverything();
        /* {CLOSE:ngDoCheck} {"sha1":"A154E0663059B6D385B4F6C11094F2084FF5E184"} */
    }
    /* {POSTCLOSE:ngDoCheck} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:ngOnDestroy} {"sha1":"C21B2137D2BD86DF7050E24A39F08355F04DA4E9"} */
    ngOnDestroy() {
        /* {OPEN:ngOnDestroy} {"sha1":"85AA65230B2C97013ABDD30D48BEB93C04E8EE54"} */
        this.utilsService.unsubscribeSubscriptions(this.subscriptions);
        /* {CLOSE:ngOnDestroy} {"sha1":"85AA65230B2C97013ABDD30D48BEB93C04E8EE54"} */
    }
    /* {POSTCLOSE:ngOnDestroy} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {CLOSE:CLASS} {} */
}
/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */