<p-messages [(value)]="errorMsgs" *ngIf="errorMsgs && errorMsgs.length > 0"></p-messages>
<!-- {PREOPEN:026541cb-8c24-4879-badb-655f200d12f0} {"sha1":"8C4A0E2E0EFD768CAB5850B359AF669A56A45AB4"} -->
<form #form="ngForm" [class.pointer-events-none]="this.showLoader">
    <!-- {OPEN:026541cb-8c24-4879-badb-655f200d12f0} {} -->
    <!-- {OPEN:ec272337-5f87-4c57-a8f4-fac4cd24bb87} {} -->
    <!-- {PREOPEN:a55b4b20-0c9b-4cad-82bb-81ccc3f5de5e} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
    <div class="ui-g form-group">
        <!-- {OPEN:a55b4b20-0c9b-4cad-82bb-81ccc3f5de5e} {} -->
        <!-- {PREOPEN:9972a8b4-f80a-4027-8f65-8298ae729dd2} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
        <div class="ui-g-12 ui-md-3">
            <!-- {OPEN:9972a8b4-f80a-4027-8f65-8298ae729dd2} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
            <!-- {CLOSE:9972a8b4-f80a-4027-8f65-8298ae729dd2} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
        </div>
        <!-- {POSTCLOSE:9972a8b4-f80a-4027-8f65-8298ae729dd2} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:71814a43-37b9-444a-a46a-ce9282c32f27} {"sha1":"EA45B5538DA9EF952A17324A1926BE826076CCEE"} -->
        <div class="ui-g-12 ui-md-6">
            <!-- {OPEN:71814a43-37b9-444a-a46a-ce9282c32f27} {} -->
            <!-- {OPEN:153a9648-e3f2-4132-9fd5-06d6090fe9aa} {"sha1":"FBFA4B90E4B6D6EF8D6289200D8BB5C5AEFC3D1F"} -->
            <div class="ui-fluid">
                <span class="ui-float-label sticky">
                    <p-dropdown required [autoDisplayFirst]="false" [(ngModel)]="searchProjectDealPopUp.Owner" name="Owner{{ searchProjectDealPopUp.ObjectID }}" fieldLabel="Owner" optionLabel="name" (onChange)="onOwnerChange($event, searchProjectDealPopUp)" [options]="ownerOptions"></p-dropdown>
                    <label>Owner</label>
                </span>
            </div>
            <!-- {CLOSE:153a9648-e3f2-4132-9fd5-06d6090fe9aa} {"sha1":"FBFA4B90E4B6D6EF8D6289200D8BB5C5AEFC3D1F"} -->
            <!-- {CLOSE:71814a43-37b9-444a-a46a-ce9282c32f27} {} -->
        </div>
        <!-- {POSTCLOSE:71814a43-37b9-444a-a46a-ce9282c32f27} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:e07c49e5-5c83-43a2-907e-9590045ddf6e} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
        <div class="ui-g-12 ui-md-3">
            <!-- {OPEN:e07c49e5-5c83-43a2-907e-9590045ddf6e} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
            <!-- {CLOSE:e07c49e5-5c83-43a2-907e-9590045ddf6e} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
        </div>
        <!-- {POSTCLOSE:e07c49e5-5c83-43a2-907e-9590045ddf6e} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {CLOSE:a55b4b20-0c9b-4cad-82bb-81ccc3f5de5e} {} -->
    </div>
    <!-- {POSTCLOSE:a55b4b20-0c9b-4cad-82bb-81ccc3f5de5e} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
    <!-- {PREOPEN:7f858c5a-6c3c-4826-8736-bc17649738d2} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
    <div class="ui-g form-group">
        <!-- {OPEN:7f858c5a-6c3c-4826-8736-bc17649738d2} {} -->
        <!-- {PREOPEN:49f5b116-8d37-4f74-8f42-8d204ddc24fe} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
        <div class="ui-g-12 ui-md-3">
            <!-- {OPEN:49f5b116-8d37-4f74-8f42-8d204ddc24fe} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
            <!-- {CLOSE:49f5b116-8d37-4f74-8f42-8d204ddc24fe} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
        </div>
        <!-- {POSTCLOSE:49f5b116-8d37-4f74-8f42-8d204ddc24fe} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:6b107cd0-1674-419d-9b82-bbc10998abc1} {"sha1":"EA45B5538DA9EF952A17324A1926BE826076CCEE"} -->
        <div class="ui-g-12 ui-md-6">
            <!-- {OPEN:6b107cd0-1674-419d-9b82-bbc10998abc1} {} -->
            <!-- {OPEN:2f9cb96b-453c-4b72-99b2-e918046bd359} {"sha1":"A47F00F64E07E787D90862BE4B4757D424A89142"} -->
            <div class="ui-fluid">
                <span class="ui-float-label sticky">
                    <p-dropdown required [autoDisplayFirst]="false" [(ngModel)]="searchProjectDealPopUp.Stage" name="Stage{{ searchProjectDealPopUp.ObjectID }}" fieldLabel="Stage" optionLabel="value" (onChange)="onStageChange($event, searchProjectDealPopUp)" [options]="stageOptions" ></p-dropdown>
                    <label>Stage</label>
                </span>
            </div>
            <!-- {CLOSE:2f9cb96b-453c-4b72-99b2-e918046bd359} {"sha1":"A47F00F64E07E787D90862BE4B4757D424A89142"} -->
            <!-- {CLOSE:6b107cd0-1674-419d-9b82-bbc10998abc1} {} -->
        </div>
        <!-- {POSTCLOSE:6b107cd0-1674-419d-9b82-bbc10998abc1} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:420e8e8c-979a-4e65-8594-b33000c66ae5} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
        <div class="ui-g-12 ui-md-3">
            <!-- {OPEN:420e8e8c-979a-4e65-8594-b33000c66ae5} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
            <!-- {CLOSE:420e8e8c-979a-4e65-8594-b33000c66ae5} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
        </div>
        <!-- {POSTCLOSE:420e8e8c-979a-4e65-8594-b33000c66ae5} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {CLOSE:7f858c5a-6c3c-4826-8736-bc17649738d2} {} -->
    </div>
    <!-- {POSTCLOSE:7f858c5a-6c3c-4826-8736-bc17649738d2} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
    <!-- {PREOPEN:89d3332b-418a-4934-9810-7ece8b169933} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
    <div class="ui-g form-group">
        <!-- {OPEN:89d3332b-418a-4934-9810-7ece8b169933} {} -->
        <!-- {PREOPEN:dcb4ab2a-68d8-476e-99ad-480d6bbbde2d} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
        <div class="ui-g-12 ui-md-3">
            <!-- {OPEN:dcb4ab2a-68d8-476e-99ad-480d6bbbde2d} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
            <!-- {CLOSE:dcb4ab2a-68d8-476e-99ad-480d6bbbde2d} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
        </div>
        <!-- {POSTCLOSE:dcb4ab2a-68d8-476e-99ad-480d6bbbde2d} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:3e297047-db59-4649-aa90-e4b756b4da0a} {"sha1":"EA45B5538DA9EF952A17324A1926BE826076CCEE"} -->
        <div class="ui-g-12 ui-md-6">
            <!-- {OPEN:3e297047-db59-4649-aa90-e4b756b4da0a} {} -->
            <!-- {OPEN:a6a5d4e1-ca2e-4adc-856e-f1bf36038678} {"sha1":"5DD2B4BCC529DA67B2D469F290A6EC4548698766"} -->
            <div class="ui-fluid">
                <span class="ui-float-label sticky">
                    <p-dropdown [autoDisplayFirst]="false" [(ngModel)]="searchProjectDealPopUp.IncludeCompanyAsAccount" name="IncludeCompanyasAccount{{ searchProjectDealPopUp.ObjectID }}" fieldLabel="Include Company as Account" optionLabel="name" (onChange)="onIncludeCompanyasAccountChange($event, searchProjectDealPopUp)" [options]="includeCompanyasAccountOptions" ></p-dropdown>
                    <label>Include Company as Account</label>
                </span>
            </div>
            <!-- {CLOSE:a6a5d4e1-ca2e-4adc-856e-f1bf36038678} {"sha1":"5DD2B4BCC529DA67B2D469F290A6EC4548698766"} -->
            <!-- {CLOSE:3e297047-db59-4649-aa90-e4b756b4da0a} {} -->
        </div>
        <!-- {POSTCLOSE:3e297047-db59-4649-aa90-e4b756b4da0a} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:cdcbc06c-441f-423f-86e5-3ce6c6cddf81} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
        <div class="ui-g-12 ui-md-3">
            <!-- {OPEN:cdcbc06c-441f-423f-86e5-3ce6c6cddf81} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
            <!-- {CLOSE:cdcbc06c-441f-423f-86e5-3ce6c6cddf81} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
        </div>
        <!-- {POSTCLOSE:cdcbc06c-441f-423f-86e5-3ce6c6cddf81} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {CLOSE:89d3332b-418a-4934-9810-7ece8b169933} {} -->
    </div>
    <!-- {POSTCLOSE:89d3332b-418a-4934-9810-7ece8b169933} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
    <!-- {PREOPEN:56f40c1f-2cea-4774-a269-186adf316cf0} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
    <div class="ui-g form-group">
        <!-- {OPEN:56f40c1f-2cea-4774-a269-186adf316cf0} {} -->
        <!-- {PREOPEN:cb3b5063-9952-42a1-b0e2-afc1d3a939a4} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
        <div class="ui-g-12 ui-md-3">
            <!-- {OPEN:cb3b5063-9952-42a1-b0e2-afc1d3a939a4} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
            <!-- {CLOSE:cb3b5063-9952-42a1-b0e2-afc1d3a939a4} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
        </div>
        <!-- {POSTCLOSE:cb3b5063-9952-42a1-b0e2-afc1d3a939a4} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:6808ed7b-e068-49e3-ae36-21f65db2b8e8} {"sha1":"EA45B5538DA9EF952A17324A1926BE826076CCEE"} -->
        <div class="ui-g-12 ui-md-6">
            <!-- {OPEN:6808ed7b-e068-49e3-ae36-21f65db2b8e8} {} -->
            <!-- {OPEN:9795e7cd-6c39-438f-909b-54ed6d1144e6} {"sha1":"AC5A33A7E12D4852DCA8F2F63F43E2CDAD132C91"} -->
            <div class="ui-fluid">
                <span class="ui-float-label sticky">
                    <input required [(ngModel)]="searchProjectDealPopUp.DealName" name="DealName{{ searchProjectDealPopUp.ObjectID }}" fieldLabel="Deal Name" type="text" pInputText class="ui-inputtext">
                    <label>Deal Name</label>
                </span>
            </div>
            <!-- {CLOSE:9795e7cd-6c39-438f-909b-54ed6d1144e6} {"sha1":"AC5A33A7E12D4852DCA8F2F63F43E2CDAD132C91"} -->
            <!-- {CLOSE:6808ed7b-e068-49e3-ae36-21f65db2b8e8} {} -->
        </div>
        <!-- {POSTCLOSE:6808ed7b-e068-49e3-ae36-21f65db2b8e8} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:56ecc310-b13a-4e09-ad36-c3ed9860009a} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
        <div class="ui-g-12 ui-md-3">
            <!-- {OPEN:56ecc310-b13a-4e09-ad36-c3ed9860009a} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
            <!-- {CLOSE:56ecc310-b13a-4e09-ad36-c3ed9860009a} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
        </div>
        <!-- {POSTCLOSE:56ecc310-b13a-4e09-ad36-c3ed9860009a} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {CLOSE:56f40c1f-2cea-4774-a269-186adf316cf0} {} -->
    </div>
    <!-- {POSTCLOSE:56f40c1f-2cea-4774-a269-186adf316cf0} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
    <!-- {PREOPEN:77149aaa-6076-4144-a704-1a18e5239068} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
    <div class="ui-g form-group">
        <!-- {OPEN:77149aaa-6076-4144-a704-1a18e5239068} {} -->
        <!-- {PREOPEN:22495a66-b25c-4a76-ba14-7e15739d681d} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
        <div class="ui-g-12 ui-md-3">
            <!-- {OPEN:22495a66-b25c-4a76-ba14-7e15739d681d} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
            <!-- {CLOSE:22495a66-b25c-4a76-ba14-7e15739d681d} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
        </div>
        <!-- {POSTCLOSE:22495a66-b25c-4a76-ba14-7e15739d681d} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:ce4f621e-3f1a-44ab-aaaa-0ec629c46fa7} {"sha1":"EA45B5538DA9EF952A17324A1926BE826076CCEE"} -->
        <div class="ui-g-12 ui-md-6">
            <!-- {OPEN:ce4f621e-3f1a-44ab-aaaa-0ec629c46fa7} {} -->
            <!-- {OPEN:380f5a08-d22a-4772-9dc7-022eeab3c8bd} {"sha1":"12FDD40B400A69D95437A30F05E5E261690D25CC"} -->
            <oneit-checkbox [(ngModel)]="searchProjectDealPopUp.NotifyOwner" name="Notifyowner{{ searchProjectDealPopUp.ObjectID }}" fieldLabel="" binary="true" label="Notify owner"></oneit-checkbox>
            <!-- {CLOSE:380f5a08-d22a-4772-9dc7-022eeab3c8bd} {"sha1":"12FDD40B400A69D95437A30F05E5E261690D25CC"} -->
            <!-- {CLOSE:ce4f621e-3f1a-44ab-aaaa-0ec629c46fa7} {} -->
        </div>
        <!-- {POSTCLOSE:ce4f621e-3f1a-44ab-aaaa-0ec629c46fa7} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:a1fdd9d0-3f38-410e-ab7b-9b1a9f9b7694} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
        <div class="ui-g-12 ui-md-3">
            <!-- {OPEN:a1fdd9d0-3f38-410e-ab7b-9b1a9f9b7694} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
            <!-- {CLOSE:a1fdd9d0-3f38-410e-ab7b-9b1a9f9b7694} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
        </div>
        <!-- {POSTCLOSE:a1fdd9d0-3f38-410e-ab7b-9b1a9f9b7694} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {CLOSE:77149aaa-6076-4144-a704-1a18e5239068} {} -->
    </div>
    <!-- {POSTCLOSE:77149aaa-6076-4144-a704-1a18e5239068} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
    <!-- {PREOPEN:f3619e6f-d5e5-48a5-a84b-0c0debcf9c8d} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
    <div class="ui-g form-group">
        <!-- {OPEN:f3619e6f-d5e5-48a5-a84b-0c0debcf9c8d} {} -->
        <!-- {PREOPEN:218d38f1-9d0d-456d-891a-fcf06c62bc5a} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
        <div class="ui-g-12 ui-md-3">
            <!-- {OPEN:218d38f1-9d0d-456d-891a-fcf06c62bc5a} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
            <!-- {CLOSE:218d38f1-9d0d-456d-891a-fcf06c62bc5a} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
        </div>
        <!-- {POSTCLOSE:218d38f1-9d0d-456d-891a-fcf06c62bc5a} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:35d4cf1e-b1fb-4da5-92d3-826b78f03d09} {"sha1":"EA45B5538DA9EF952A17324A1926BE826076CCEE"} -->
        <div class="ui-g-12 ui-md-6">
            <!-- {OPEN:35d4cf1e-b1fb-4da5-92d3-826b78f03d09} {} -->
            <!-- {OPEN:67cb3ee8-ee58-4f93-ab59-5a19846bd8e2} {"sha1":"C3A6402067A27CBB04520DE1AF315AB72087238F"} -->
            <div class="ui-fluid">
                <button pButton type="button" label="Create Deal" (click)="onCreateDealClick($event, searchProjectDealPopUp)"></button>
            </div>
            <!-- {CLOSE:67cb3ee8-ee58-4f93-ab59-5a19846bd8e2} {"sha1":"C3A6402067A27CBB04520DE1AF315AB72087238F"} -->
            <!-- {CLOSE:35d4cf1e-b1fb-4da5-92d3-826b78f03d09} {} -->
        </div>
        <!-- {POSTCLOSE:35d4cf1e-b1fb-4da5-92d3-826b78f03d09} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:c6ad24a4-ecb3-4160-97b5-f5945c35d00d} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
        <div class="ui-g-12 ui-md-3">
            <!-- {OPEN:c6ad24a4-ecb3-4160-97b5-f5945c35d00d} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
            <!-- {CLOSE:c6ad24a4-ecb3-4160-97b5-f5945c35d00d} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
        </div>
        <!-- {POSTCLOSE:c6ad24a4-ecb3-4160-97b5-f5945c35d00d} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {CLOSE:f3619e6f-d5e5-48a5-a84b-0c0debcf9c8d} {} -->
    </div>
    <!-- {POSTCLOSE:f3619e6f-d5e5-48a5-a84b-0c0debcf9c8d} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
    <!-- {CLOSE:ec272337-5f87-4c57-a8f4-fac4cd24bb87} {} -->
    <p-progressSpinner *ngIf="showLoader"></p-progressSpinner>
    <!-- {CLOSE:026541cb-8c24-4879-badb-655f200d12f0} {} -->
</form>
<!-- {POSTCLOSE:026541cb-8c24-4879-badb-655f200d12f0} {"sha1":"3FD6E659562C1876D02FC8CF54746B1502442CD2"} -->
