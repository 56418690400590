/* {OPEN:IMPORT} {"sha1":"6A38FD7AF7D2B6B052C2CFEBBC3A779B0B201CE8"} */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseConvertedDealsComponent } from './base-converted-deals.component';
/* {CLOSE:IMPORT} {"sha1":"6A38FD7AF7D2B6B052C2CFEBBC3A779B0B201CE8"} */
import { SearchConvertedDeal } from './converted-deals.model';
/* {POSTCLOSE:IMPORT} {"sha1":"6A38FD7AF7D2B6B052C2CFEBBC3A779B0B201CE8"} */

/* {PREOPEN:CLASS} {"sha1":"6DEBBDC03FB291F18C6C12A01240C5E0D9F27C64"} */
@Component({
    selector: 'app-converted-deals',
    templateUrl: './converted-deals.component.html'
})
export class ConvertedDealsComponent extends BaseConvertedDealsComponent implements OnInit, OnDestroy {
    /* {OPEN:CLASS} {} */

    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
        super.ngOnInit();
        /* {CLOSE:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
    }

    ngOnDestroy() {
        /* {OPEN:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
        super.ngOnDestroy();
        /* {CLOSE:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
    }

    /* {CLOSE:CLASS} {} */

    convertedDeals;
    pinSearchOpen = true;
    selectedConvertedDeals;

    //@override
    executeScreenSpecificInitCode() {
       this.getConvertedDeals();
    }

    getConvertedDeals() {
        this.showLoader = true;

        this.subscriptions.push(
            this.appService.searchConvertedDeals().subscribe(data => {
                this.showLoader = false;

                if(data && data.length > 0) {
                    const stages = JSON.parse(localStorage.getItem('Stages'));
                    const Owners = JSON.parse(localStorage.getItem('Owners'));

                    data.map(item => {
                        stages.forEach(stage => {
                            if(item.stageName === stage.id) {
                                item.stage = stage.value;
                            }
                        });

                        Owners.forEach(owner => {
                            if(item.ownerId === owner.id) {
                                item.owner = owner.name;
                            }
                        })
                    });
                }

                if(this.pinSearchOpen && (this.searchConvertedDeal.Details || this.searchConvertedDeal.DateFrom || this.searchConvertedDeal.DateTo)) {
                    this.convertedDeals = (data || []).filter(item => {
                        if(item.name && this.searchConvertedDeal.Details && item.name.toLowerCase().includes(this.searchConvertedDeal.Details.toLowerCase().trim())) {
                            return item;
                        }

                        if(item.projectName && this.searchConvertedDeal.Details && item.projectName.toLowerCase().includes(this.searchConvertedDeal.Details.toLowerCase().trim())) {
                            return item;
                        }

                        if(item.projectId && this.searchConvertedDeal.Details && item.projectId.toString().includes(this.searchConvertedDeal.Details.toLowerCase().trim())) {
                            return item;
                        }

                        if(item.stage && this.searchConvertedDeal.Details && item.stage.toLowerCase().includes(this.searchConvertedDeal.Details.toLowerCase().trim())) {
                            return item;
                        }

                        if(item.owner && this.searchConvertedDeal.Details && item.owner.toLowerCase().includes(this.searchConvertedDeal.Details.toLowerCase().trim())) {
                            return item;
                        }

                        if(item.createdDate && this.searchConvertedDeal.DateFrom && this.searchConvertedDeal.DateTo && item.createdDate >= this.searchConvertedDeal.DateFrom.trim() && item.createdDate <= this.searchConvertedDeal.DateTo.trim()) {
                            return item;
                        }

                        if(item.createdDate && this.searchConvertedDeal.DateFrom && item.createdDate >= this.searchConvertedDeal.DateFrom.trim() && !this.searchConvertedDeal.DateTo) {
                            return item;
                        }

                        if(item.createdDate && this.searchConvertedDeal.DateTo && item.createdDate <= this.searchConvertedDeal.DateTo.trim() && !this.searchConvertedDeal.DateFrom) {
                            return item;
                        }
                    });
                } else  {
                    this.convertedDeals = data;
                }

                this.selectedConvertedDeals = this.convertedDeals;

            }, error => {
                this.showLoader = false;
                this.utilsService.handleError(error);
            })
        );
    }
    //@override
    onSearchClick(event, searchConvertedDeal) {
        this.utilsService.clearErrorMessages();

        this.getConvertedDeals();
    }

    onClearClick(event, searcselectedDealshConvertedDeal) {
        this.utilsService.clearErrorMessages();

        this.searchConvertedDeal = new SearchConvertedDeal();
    }

    onUpdateCrmClick(event, searchConvertedDeal) {
        this.utilsService.clearErrorMessages();

        this.showLoader = true;

        const params = {
            projects: this.selectedConvertedDeals.map(deal => deal.projectId).filter((value, i, self) => self.indexOf(value) === i)
        };

        this.subscriptions.push(
            this.appService.updateCRM(params).subscribe(() => {
                this.showLoader = false;
                this.utilsService.handleSuccessMessage('CRM updated successfully');
            }, error => {
                this.showLoader = false;
                this.utilsService.handleError(error);
            })
        );
    }

    //@override
    getResultsTableCols() : any[] {
        return [
            { field: 'isSelected', header: '', width: '2px', noSort: true, noFilter: true },
            { field: 'projectName', header: 'Project Name', width: '10px', noSort: false, noFilter: true },
            { field: 'projectId', header: 'Project ID', width: '10px', noSort: false, noFilter: true },
            { field: 'name', header: 'Deal Name', width: '10px', noSort: false, noFilter: true },
            { field: 'owner', header: 'Owner', width: '10px', noSort: false, noFilter: true },
            { field: 'stage', header: 'Stage', width: '10px', noSort: false, noFilter: true },
            { field: 'convertedDate', header: 'Converted Date', width: '10px', noSort: false, noFilter: true }
        ];
    }

    //@override
    getResultsTableTitle() : string {
        return 'Search Results';
    }
}
/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */
