import { animate, state, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { ScrollPanel } from 'primeng/scrollpanel';
import { Subscription } from 'rxjs';
import { UtilsService } from '../../services/utils.service';
import { FullLayoutComponent } from '../full-layout/full-layout.component';
import { MenuService } from './../../services/menu.service';

declare var jQuery: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit, AfterViewInit {

    @Input() reset: boolean;

    mainMenuItems: any[];
    activeIndexes: any[] = [];
    subscriptions: Array<Subscription> = [];
    logoURL : string = 'assets/images/logo.png';

    @ViewChild('scrollPanel', { static: true }) layoutMenuScrollerViewChild: ScrollPanel;

    constructor(
        public app: FullLayoutComponent,
        private utilsService: UtilsService,
        private menuService: MenuService
    ) {

    }

    ngOnInit() {
        this.subscriptions.push(this.menuService.menuUpdated.subscribe(
            data => {
                this.mainMenuItems  =   data.mainMenuItems;
            }
        ));

        this.subscriptions.push(this.utilsService.logoUpdated.subscribe(
            data => {
                if(data) {
                    this.logoURL = data;
                }
            }
        ));
    }

    ngAfterViewInit() {
        // setTimeout(() => {this.layoutMenuScrollerViewChild.moveBar(); }, 100);
    }

    changeTheme(theme) {
        const themeLink: HTMLLinkElement = <HTMLLinkElement> document.getElementById('theme-css');
        themeLink.href = 'app/oneit/assets/theme/theme-' + theme + '.css';
    }

    changeLayout(theme) {
        const layoutLink: HTMLLinkElement = <HTMLLinkElement> document.getElementById('layout-css');
        layoutLink.href = 'app/oneit/assets/layout/css/layout-' + theme + '.css';
    }
}

@Component({
    /* tslint:disable:component-selector */
    selector: '[app-submenu]',
    /* tslint:enable:component-selector */
    template: `
        <ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
            <li [ngClass]="{'active-menuitem': isActive(i)}" [class]="child.badgeStyleClass">
                <a [href]="child.url||'#'" (click)="itemClick($event,child,i)" *ngIf="!child.routerLink"
                   [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target"
                   (mouseenter)="onMouseEnter(i)" class="ripplelink">
                    <i [class]="child.icon" *ngIf="child.icon"></i>
                    <div class="menuitem-text">{{child.label}}</div>
                    <i class="material-icons layout-submenu-toggler" *ngIf="child.items">keyboard_arrow_down</i>
                    <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
                </a>

                <a (click)="itemClick($event,child,i)" *ngIf="child.routerLink"
                    [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink"
                   [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target"
                   (mouseenter)="onMouseEnter(i)" class="ripplelink">
                   <i [class]="child.icon" *ngIf="child.icon"></i>
                    <div class="menuitem-text">{{child.label}}</div>
                    <i class="material-icons layout-submenu-toggler" *ngIf="child.items">>keyboard_arrow_down</i>
                    <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
                </a>
                <ul app-submenu [item]="child" *ngIf="child.items" [visible]="isActive(i)" [reset]="reset"
                    [@children]="(app.isHorizontal)&&root ? isActive(i) ?
                    'visible' : 'hidden' : isActive(i) ? 'visibleAnimated' : 'hiddenAnimated'" [level]="level + 1"></ul>
            </li>
        </ng-template>
    `,
    animations: [
        trigger('children', [
            state('hiddenAnimated', style({
                height: '0px'
            })),
            state('visibleAnimated', style({
                height: '*'
            })),
            state('visible', style({
                height: '*',
                'z-index': 100
            })),
            state('hidden', style({
                height: '0px',
                'z-index': '*'
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class AppSubMenuComponent {

    @Input() item: MenuItem;

    @Input() root: boolean;

    @Input() visible: boolean;

    @Input() level: number;

    _reset: boolean;

    constructor(public app: FullLayoutComponent, public router: Router, public location: Location, public appMenu: SidebarComponent) {}

    itemClick(event: Event, item: MenuItem, index: number) {
        if (this.root) {
            this.app.menuHoverActive = !this.app.menuHoverActive;
            event.preventDefault();
        }

        // avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return true;
        }

        // activate current item and deactivate active sibling if any
        if (item.routerLink || item.items || item.command || item.url) {
            this.setActiveIndex((this.getActiveIndex() === index) ? -1 : index);
        }

        // execute command
        if (item.command) {
            item.command({originalEvent: event, item: item});
        }

        // prevent hash change
        if (item.items || (!item.url && !item.routerLink)) {
        //   setTimeout(() => {
        //     this.appMenu.layoutMenuScrollerViewChild.moveBar();
        //   }, 450);
            event.preventDefault();
        }

        // hide menu
        if (!item.items) {
            if (this.app.isMobile()) {
                this.app.sidebarActive = false;
                this.app.mobileMenuActive = false;
            }

            if (this.app.isHorizontal) {
                this.app.resetMenu = true;
            } else {
                this.app.resetMenu = false;
            }

            this.app.menuHoverActive = !this.app.menuHoverActive;
        }
    }

    onMouseEnter(index: number) {
        if (this.root && this.app.menuHoverActive && this.app.isHorizontal
            && !this.app.isMobile() && !this.app.isTablet()) {
            this.setActiveIndex(index);
        }
    }

    isActive(index: number): boolean {
        return this.getActiveIndex() === index;
    }

    getActiveIndex() : number {
        return this.appMenu ? this.appMenu.activeIndexes[this.level] : -1;
    }

    setActiveIndex(index: number) {
        if(this.appMenu) {
            this.appMenu.activeIndexes[this.level] = index;
        }
    }

    @Input() get reset(): boolean {
        return this._reset;
    }

    set reset(val: boolean) {
        this._reset = val;

        if (this._reset && (this.app.isHorizontal || this.app.isOverlay)) {
            this.setActiveIndex(null);
        }
    }
}
