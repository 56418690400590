/* {OPEN:IMPORT} {"sha1":"45EB6DFC023F0C03BAF7BE08C03B0B998155BF9B"} */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseCreateAccountComponent } from './base-create-account.component';
/* {CLOSE:IMPORT} {"sha1":"45EB6DFC023F0C03BAF7BE08C03B0B998155BF9B"} */
import { ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../oneit/services/user.service';
import { UtilsService } from '../../oneit/services/utils.service';
import { SearchService } from '../../oneit/services/search.service';
import { AppService } from '../../app.service';
import { ConfirmationService } from 'primeng/api';
import { CreateAccountService } from './create-account.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng';
import { MsgsService } from '../../oneit/services/msgs.service';
/* {POSTCLOSE:IMPORT} {"sha1":"45EB6DFC023F0C03BAF7BE08C03B0B998155BF9B"} */

/* {PREOPEN:CLASS} {"sha1":"EAFD418A09415EC6E836F668D6460837AF78D843"} */
@Component({
    selector: 'app-create-account',
    templateUrl: './create-account.component.html'
})
export class CreateAccountComponent extends BaseCreateAccountComponent implements OnInit, OnDestroy {
    /* {OPEN:CLASS} {} */

    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
        super.ngOnInit();
        /* {CLOSE:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
    }
    
    ngOnDestroy() {
        /* {OPEN:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
        super.ngOnDestroy();
        /* {CLOSE:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
    }

    /* {CLOSE:CLASS} {} */

    @ViewChild('form') form: NgForm;
    errorMsgs;

    //@override
    constructor(
        public router : Router,
        public userService : UserService,
        public utilsService : UtilsService,
        public searchService : SearchService,
        public appService : AppService,
        public confirmationService: ConfirmationService,
        public createAccountService : CreateAccountService,
        public dialogRef : DynamicDialogRef,
        public dialogConfig : DynamicDialogConfig,
        public msgsService : MsgsService
    ) {
        super(router , userService , utilsService , searchService , appService , confirmationService , createAccountService);
    }

    //@override
    executeScreenSpecificInitCode() {
        this.subscriptions.push(
            this.msgsService.errorMsgsUpdated.subscribe(errorMsgs => {
                this.errorMsgs = errorMsgs;
            })
        );

        this.getCRMConfigOptions();

    }

    getCRMConfigOptions() {
        if(localStorage.getItem('Owners')) {
            this.ownerOptions = JSON.parse(localStorage.getItem('Owners'));
        }
    }

    //@override
    onCreateAccountClick(event, searchCreateAccount) {
        this.utilsService.clearErrorMessages();

        if(!this.form.valid) {
            this.utilsService.showAllErrorMessages();
            return;
        }

        this.showLoader = true;

        const params = {
            accountName: this.searchCreateAccount.AccountName,
            createAccounts: true,
            importContacts: this.searchCreateAccount.CreateContact,
            ownerId: this.searchCreateAccount.Owner.id,
            companies: [this.dialogConfig.data.company_id],
        };

        this.subscriptions.push(
            this.createAccountService.createAccount(params).subscribe(()=> {
                this.showLoader = false;
                this.dialogRef.close();
                this.utilsService.handleSuccessMessage('Account created successfully');
            },error => {
                this.showLoader = false;
                this.utilsService.handleError(error);
            })
        );
    }

    //@override
    getOwnerOptions(): any {
        return;
    }
}
/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */
