/* {OPEN:IMPORT} {"sha1":"BAEC8F95BD3A2239F7F3F73DCC61F252E6E4B619"} */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SaveService } from './../../oneit/services/save.service';
import { SearchService } from './../../oneit/services/search.service';
import { UtilsService } from './../../oneit/services/utils.service';
import { AppService } from '../../app.service';
/* {CLOSE:IMPORT} {"sha1":"BAEC8F95BD3A2239F7F3F73DCC61F252E6E4B619"} */

@Injectable()
/* {PREOPEN:CLASS} {"sha1":"1A54C8A2D620BEC5791BEFA25AA1E4AAA69DF7CD"} */
export class ForgotPasswordService {
    /* {OPEN:CLASS} {} */

    constructor(
        /* {OPEN:constructor} {"sha1":"DCCC484B4DA23ED55D1380457121E9DAC4BB6B04"} */
        private searchService: SearchService,
        private saveService: SaveService,
        private utilsService: UtilsService,
        /* {CLOSE:constructor} {"sha1":"DCCC484B4DA23ED55D1380457121E9DAC4BB6B04"} */
        private appService: AppService
    ) {
    }

    /* {CLOSE:CLASS} {} */

    forgotPassword(params): Observable<any> {
        return this.appService.forgotPassword('auth/reset-password' , params);
    }
}
/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */
