<form #form="ngForm" (keyup)="handleKeyUp($event)" [class.pointer-events-none]="this.showLoader">
	<div class="ui-g form-group">
		<div class="ui-g-12 ui-md-12">
			<h4 style="margin-top: 0px">Reset password</h4>
		</div>
	</div>

	<div class="ui-g form-group">
		<div class="ui-g-12 ui-md-12">
			<div class="ui-fluid">
				<span class="ui-float-label sticky">
					<input id="new-password" required [readonly]="!isScreenEditable" [(ngModel)]="resetPassword.EnterPassword" name="EnterPassword{{ resetPassword.ObjectID }}" fieldLabel="Password" type="password" pInputText class="ui-inputtext">
					<label for="new-password">Enter Password *</label>
				</span>
			</div>
		</div>
	</div>
	<div class="ui-g form-group">
		<div class="ui-g-12 ui-md-12">
			<div class="ui-fluid">
				<span class="ui-float-label sticky">
					<input id="confirm-password" required [readonly]="!isScreenEditable" [(ngModel)]="resetPassword.ConfirmPassword" name="ConfirmPassword{{ resetPassword.ObjectID }}" fieldLabel="Confirm Password" type="password" pInputText class="ui-inputtext">
					<label for="confirm-password">Confirm Password *</label>
				</span>
			</div>
		</div>
	</div>
	<div class="ui-g form-group">
		<div class="ui-g-12 ui-md-12">
			<div *ngIf="isScreenEditable">
				<p-button [disabled]="disableResetPasswordBtn" label="Reset Password" icon="ui-icon-send" class="box-full-width-btn"  (onClick)="onResetPasswordClick($event, resetPassword)" ></p-button>
			</div>
		</div>
	</div>
	<p-progressSpinner *ngIf="showLoader"></p-progressSpinner>
</form>
