import { Injectable, isDevMode } from '@angular/core';
import { IFrameComponent, IFrameObject } from 'iframe-resizer';
import { Subject } from 'rxjs';
import { environment } from './../../../environments/environment';

@Injectable()
export class IframeMsgHandlingService {

    iframeComponent: IFrameComponent;
    iframeResizerComponent: IFrameObject;
    msgReceived: Subject<any> = new Subject();

    constructor() { }

    setIframeComponent(_iframeComponent: IFrameComponent) {
        this.iframeComponent = _iframeComponent;
    }

    setIframeResizerComponent(_iframeResizerComponent: IFrameObject) {
        this.iframeResizerComponent = _iframeResizerComponent;
    }

    postMessage(message: any) {
        if (this.iframeResizerComponent) {
            this.iframeResizerComponent.sendMessage(message, environment.baseUrl);

            if (isDevMode) {
                console.log('Message sent successfully:', message);
            }
        } else {
            console.error('Error occurred when posting message. Iframe resizer component not found.');
        }
    }

    receiveMessage(message: any) {
        if (isDevMode) {
            console.log("receiveMessage called:", message);
        }
        this.msgReceived.next(message.message);
    }
}