
  <mat-card>
    <mat-card-header>
      <mat-card-title>Profile Information</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form class="form-container">
         <mat-form-field class="full-width">
                <mat-label>First Name</mat-label>
                <input  matInput  placeholder="First name"  name="fname"  [(ngModel)]="user.firstName" required>
             </mat-form-field>
             <mat-form-field class="full-width">
                <mat-label>Last Name</mat-label>
                <input  matInput  placeholder="Last Name" name="lname" [(ngModel)]="user.lastName"  required>
             </mat-form-field>
             <mat-form-field class="full-width">
                <mat-label>Email</mat-label>
                <input  matInput  placeholder="Email" name="email"  [(ngModel)]="user.email"required>
             </mat-form-field>
             <mat-form-field class="full-width">
                <mat-label>Password</mat-label>
                <input  matInput  placeholder="Password"  name="password"  [(ngModel)]="user.password" required>
             </mat-form-field>
             <mat-form-field class="full-width">
              <mat-label>Confirm Password</mat-label>
              <input matInput placeholder="Confirm Password" name="confirmPassword"  required />
            </mat-form-field>
            <br/>
            <mat-selection-list [(ngModel)]="user.roles" #roles>
              <mat-label>Roles:</mat-label>
              <mat-list-option  [checkboxPosition]="'before'" selected disabled [value]="'ROLE_USER'"> User </mat-list-option>
              <mat-list-option [checkboxPosition]="'before'" [value]="'ROLE_ADMIN'"> Admin </mat-list-option>
            </mat-selection-list>
              <br/>
              <button mat-button (click)="saveUser()">Save</button>
      </form>
    </mat-card-content>
  </mat-card>
