<!-- {PREOPEN:9c09bdb9-5601-4221-810d-146bb0a9c0c0} {"sha1":"8C4A0E2E0EFD768CAB5850B359AF669A56A45AB4"} -->
<form #form="ngForm" [class.pointer-events-none]="this.showLoader">
    <!-- {OPEN:9c09bdb9-5601-4221-810d-146bb0a9c0c0} {} -->
    <!-- {OPEN:fecdf4b5-57e3-4149-a7ee-45c4d0ddacf4} {} -->
    <!-- {PREOPEN:9fed89ee-7998-4068-aa43-fa3a23e7bb13} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
    <div class="ui-g form-group">
        <!-- {OPEN:9fed89ee-7998-4068-aa43-fa3a23e7bb13} {} -->
        <!-- {PREOPEN:83646601-ff78-4ef9-be16-20c53cad0cb0} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
        <div class="ui-g-12 ui-md-3">
            <!-- {OPEN:83646601-ff78-4ef9-be16-20c53cad0cb0} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
            <!-- {CLOSE:83646601-ff78-4ef9-be16-20c53cad0cb0} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
        </div>
        <!-- {POSTCLOSE:83646601-ff78-4ef9-be16-20c53cad0cb0} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:fa18dc39-0287-409f-82c7-7687642e4ee6} {"sha1":"EA45B5538DA9EF952A17324A1926BE826076CCEE"} -->
        <div class="ui-g-12 ui-md-6">
            <!-- {OPEN:fa18dc39-0287-409f-82c7-7687642e4ee6} {} -->
            <!-- {OPEN:5c6b957a-053a-4c50-bb62-02f6cdc5b523} {"sha1":"D306B9239989743BC5D4BD0E100AA48FE15CE7F7"} -->
            <div class="ui-fluid">
                <span class="ui-float-label sticky">
                    <p-dropdown [autoDisplayFirst]="false" required [(ngModel)]="searchCreateContact.Owner" name="Owner{{ searchCreateContact.ObjectID }}" fieldLabel="Owner" optionLabel="name" (onChange)="onOwnerChange($event, searchCreateContact)" [options]="ownerOptions" ></p-dropdown>
                    <label>Owner</label>
                </span>
            </div>
            <!-- {CLOSE:5c6b957a-053a-4c50-bb62-02f6cdc5b523} {"sha1":"D306B9239989743BC5D4BD0E100AA48FE15CE7F7"} -->
            <!-- {CLOSE:fa18dc39-0287-409f-82c7-7687642e4ee6} {} -->
        </div>
        <!-- {POSTCLOSE:fa18dc39-0287-409f-82c7-7687642e4ee6} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:3088b18e-2d65-4bb4-a822-b091a7474df0} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
        <div class="ui-g-12 ui-md-3">
            <!-- {OPEN:3088b18e-2d65-4bb4-a822-b091a7474df0} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
            <!-- {CLOSE:3088b18e-2d65-4bb4-a822-b091a7474df0} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
        </div>
        <!-- {POSTCLOSE:3088b18e-2d65-4bb4-a822-b091a7474df0} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {CLOSE:9fed89ee-7998-4068-aa43-fa3a23e7bb13} {} -->
    </div>
    <!-- {POSTCLOSE:9fed89ee-7998-4068-aa43-fa3a23e7bb13} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
    <!-- {PREOPEN:1e3ce06c-7beb-4ec4-89fa-90f734664b9c} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
    <div class="ui-g form-group">
        <!-- {OPEN:1e3ce06c-7beb-4ec4-89fa-90f734664b9c} {} -->
        <!-- {PREOPEN:098092f4-abe8-40db-a8d8-412d102787d6} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
        <div class="ui-g-12 ui-md-3">
            <!-- {OPEN:098092f4-abe8-40db-a8d8-412d102787d6} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
            <!-- {CLOSE:098092f4-abe8-40db-a8d8-412d102787d6} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
        </div>
        <!-- {POSTCLOSE:098092f4-abe8-40db-a8d8-412d102787d6} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:22f5741b-07ca-4413-84b9-ec95ec874825} {"sha1":"EA45B5538DA9EF952A17324A1926BE826076CCEE"} -->
        <div class="ui-g-12 ui-md-6">
            <!-- {OPEN:22f5741b-07ca-4413-84b9-ec95ec874825} {} -->
            <!-- {OPEN:975a5488-9d15-45b3-9fbf-7e44e03256cb} {"sha1":"7E4166B68D34089BC222CF33BE4BBCFA2AD97D94"} -->
            <div [style.text-align]="'center'" [style.paddingTop.px]="'10'" [style.paddingLeft.px]="'10'" [style.paddingBottom.px]="'10'" [style.paddingRight.px]="'10'">
                <button pButton type="button" label="Create Contact" (click)="onCreateContactClick($event, searchCreateContact)"></button>
            </div>
            <!-- {CLOSE:975a5488-9d15-45b3-9fbf-7e44e03256cb} {"sha1":"7E4166B68D34089BC222CF33BE4BBCFA2AD97D94"} -->
            <!-- {CLOSE:22f5741b-07ca-4413-84b9-ec95ec874825} {} -->
        </div>
        <!-- {POSTCLOSE:22f5741b-07ca-4413-84b9-ec95ec874825} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:667f4ae9-9ea9-4fcd-b5d8-a27d94fb3a50} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
        <div class="ui-g-12 ui-md-3">
            <!-- {OPEN:667f4ae9-9ea9-4fcd-b5d8-a27d94fb3a50} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
            <!-- {CLOSE:667f4ae9-9ea9-4fcd-b5d8-a27d94fb3a50} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
        </div>
        <!-- {POSTCLOSE:667f4ae9-9ea9-4fcd-b5d8-a27d94fb3a50} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {CLOSE:1e3ce06c-7beb-4ec4-89fa-90f734664b9c} {} -->
    </div>
    <!-- {POSTCLOSE:1e3ce06c-7beb-4ec4-89fa-90f734664b9c} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
    <!-- {CLOSE:fecdf4b5-57e3-4149-a7ee-45c4d0ddacf4} {} -->
    <p-progressSpinner *ngIf="showLoader"></p-progressSpinner>
    <!-- {CLOSE:9c09bdb9-5601-4221-810d-146bb0a9c0c0} {} -->
</form>
<!-- {POSTCLOSE:9c09bdb9-5601-4221-810d-146bb0a9c0c0} {"sha1":"3FD6E659562C1876D02FC8CF54746B1502442CD2"} -->
