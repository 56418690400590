import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from './../services/translate.service';

@Pipe({
    name: 'oneit_translate'
})

//Format: {{CLASS_NAME.FIELD_NAME | oneit_translate : DEFAULT_VAL : SUBTYPE : MODES}}
//Everything "optional" except FIELD_NAME
//Example:  {{'Sample.Name' | oneit_translate}}
export class TranslatePipe implements PipeTransform {

    constructor(private translateService: TranslateService) {

    }

    transform(fieldName: any, defaultValue?: string, subType?: string, modes?: string[]) {
        return this.translateService.translateLabel(fieldName, subType, defaultValue, modes);
    }
}