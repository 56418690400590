import { LocalStorageService } from './../../cbos/services/local-storage.service';
import { GoogleMapsService } from './../../cbos/services/google-maps.service';
import { CisFilterService } from './../../cbos/cis/cis-filter.service';
/* {OPEN:IMPORT} {"sha1":"3DE85F2BAE58570326E5D24179B700AA3F70EF71"} */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseSearchProjectsComponent } from './base-search-projects.component';
/* {CLOSE:IMPORT} {"sha1":"3DE85F2BAE58570326E5D24179B700AA3F70EF71"} */
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../oneit/services/user.service';
import { UtilsService } from '../../oneit/services/utils.service';
import { SearchService } from '../../oneit/services/search.service';
import { AppService } from '../../app.service';
import { ConfirmationService } from 'primeng/api';
import { SearchProjectsService } from './search-projects.service';
import { DialogService } from 'primeng';
import { AddDataProviderComponent } from '../add-data-provider/add-data-provider.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FullLayoutComponent } from '../../oneit/components/full-layout/full-layout.component';
import { SaveSearchComponent } from '../save-search/save-search.component';
import { DateDiff } from '../../oneit/utils/date-diff';
import { SavedSearch } from './search-projects.model';
import { AppConstants } from 'src/app/cbos/constants/app-constants';
import _ from 'lodash';
/* {POSTCLOSE:IMPORT} {"sha1":"3DE85F2BAE58570326E5D24179B700AA3F70EF71"} */

/* {PREOPEN:CLASS} {"sha1":"F67E64D7B0A4367CB3FC04E33C9C275ED857E021"} */
@Component({
    selector: 'app-search-projects',
    templateUrl: './search-projects.component.html'
})
export class SearchProjectsComponent extends BaseSearchProjectsComponent implements OnInit, OnDestroy {
    /* {OPEN:CLASS} {} */

    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
        super.ngOnInit();
        /* {CLOSE:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
    }

    ngOnDestroy() {
        /* {OPEN:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
        super.ngOnDestroy();
        /* {CLOSE:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
    }

    /* {CLOSE:CLASS} {} */

    componentInView = new Subject();
    hoveredCompanyID;
    isCardHidden = false;
    projectsOrCompanies = [];
    filterParam: any = {};
    savedSearch = new SavedSearch();
    isTableView = false;
    params = null;
    module: any;
    isGoogleMapInfoWindowOpen = false;
    infoWindow;
    currentUserLongitude;
    currentUserLatitude;
    totalRecords;
    isShowMoreClicked = false;
    convertedDeals = [];
    tableView: boolean;

    //@override
    constructor(
        public router: Router,
        public userService: UserService,
        public utilsService: UtilsService,
        public searchService: SearchService,
        public appService: AppService,
        public confirmationService: ConfirmationService,
        public searchProjectsService: SearchProjectsService,
        public dialogService: DialogService,
        public fullLayoutComponent: FullLayoutComponent,
        public activatedRoute: ActivatedRoute,
        private cisFilterService: CisFilterService,
        private googleMapService: GoogleMapsService,
        private localStorageService : LocalStorageService,
    ) {
        super(router, userService, utilsService, searchService, appService, confirmationService, searchProjectsService);
    }

    //@override
    executeScreenSpecificInitCode() {
        this.subscriptions.push(
            this.activatedRoute.params.subscribe(param => {
              this.setModule();
                if (param.id) {
                    this.getSavedSearch(param.id);
                    return;
                }

                if (localStorage.getItem('Refine_Search_Filters')) {
                    this.setLocalStorageRefineSearchFilters();
                    return;
                }

                this.setParams();

                this.getProjectsOrCompanies(this.params , this.module);
            })
        );
        if (localStorage.getItem('TableView')) {
            this.isTableView = JSON.parse(localStorage.getItem('TableView'))
        }

        this.setModule();

        this.getConvertedDeals();
    }


    setModule() {
      this.module = this.localStorageService.getModule();
      this.appService.selectedModule = this.module;
    }

    setParams(offset = 0) {
        this.params = {
            offset: offset,
            // limit: 10,
            query: this.filterParam && Object.keys(this.filterParam).length > 0? encodeURIComponent(JSON.stringify(this.filterParam)) : null,
            sort: '-project_value,project_id'
        };

        //Override parameters for CIS
        if(this.isCIS()) {
          this.params.filters = this.cisFilterService.searchFilters;
          this.params.query = null;
          this.params.sort= null;
        }

    }

    showTable(){
        this.isTableView = true;
        localStorage.setItem('TableView', JSON.stringify(this.isTableView));
    }

    showMap(){
        this.isTableView = false;
        localStorage.setItem('TableView', JSON.stringify(this.isTableView));
    }

    setLocalStorageRefineSearchFilters() {
        this.showLoader = true;

        const searchFilters = JSON.parse(localStorage.getItem('Refine_Search_Filters'));
        this.module = searchFilters.module;
        this.filterParam = searchFilters.filters;

        setTimeout(()=>{
            this.getData();
            this.totalRecords = JSON.parse(localStorage.getItem('TOTAL_RECORDS'));
            this.showLoader = false;
        });
    }

    getConvertedDeals() {
        if(localStorage.getItem('CRM')) {
            this.showLoader = true;
            this.appService.searchConvertedDeals().subscribe(data => {
                this.showLoader = false;
                this.convertedDeals = data;
            }, error => {
                this.showLoader = false;
                this.utilsService.handleError(error);
            })
        }
    }

    onAddClick() {
        this.utilsService.clearErrorMessages();

        this.fullLayoutComponent.dialogOpen = true;

        this.dialogService.open(AddDataProviderComponent, {
            header: 'Add Data Providers',
            ...this.appService.dynamicDialogConfig
        }).onClose.pipe(takeUntil(this.componentInView)).subscribe(() => {
            this.utilsService.clearErrorMessages();
            this.fullLayoutComponent.dialogOpen = false;
        });
    }

    //@override
    onSearchClick(event, searchProject) {
        this.module = event.module;
        this.filterParam = event.filters;
        this.params = event.params;

        this.getProjectsOrCompanies(this.params , this.module);
    }

    getProjectsOrCompanies(params , module , refresh = false) {
      this.localStorageService.setModule(module);
        this.showLoader = true;

        this.currentUserLongitude = null;
        this.currentUserLatitude = null;
        this.isGoogleMapInfoWindowOpen = false;
        this.infoWindow = null;

        this.setRefineSearchFilters();

        this.subscriptions.push(
            this.searchProjectsService.getProjectsOrCompanies(params, module).subscribe(data => {
                if(data && data.records && data.records.length > 0) {
                    data.records.forEach(item => {
                        if(item.start) {
                            item.start = DateDiff.convertDateToString(DateDiff.convertStringToDate(item.start), 'DD-MMMM-YYYY');
                        }
                        item.module = module
                    });

                    this.projectsOrCompanies = this.isShowMoreClicked || refresh ? [...this.projectsOrCompanies , ...data.records] : data.records;
                } else {
                  this.projectsOrCompanies = [];
                }

                if(data && data.totalRecords) {
                    this.totalRecords = data.totalRecords;
                }

                if(!data) {
                    this.projectsOrCompanies = [];
                }

                this.saveData()
                localStorage.setItem('TOTAL_RECORDS' , JSON.stringify(this.totalRecords));

                this.showLoader = false;
                this.isShowMoreClicked = false;
            }, error => {
                this.showLoader = false;
                this.isShowMoreClicked = false;
                this.utilsService.handleError(error);
            })
        );
    }


    getData() {
      let data = this.localStorageService.getSearchResults();
      this.projectsOrCompanies = JSON.parse(data);
    }

    saveData() {
      this.localStorageService.setSearchResults(JSON.stringify(this.projectsOrCompanies));
    }


    setRefineSearchFilters() {
      localStorage.setItem('Refine_Search_Filters' , JSON.stringify({
        module:this.module,
        filters: this.isCIS()? this.params.filters : this.filterParam,
        params: this.params
      }));
    }


    //@override
    onShowMoreClick(event, searchProject) {
        this.utilsService.clearErrorMessages();
        this.isCardHidden = false;

        this.showLoader = true;

        this.isShowMoreClicked = true;

        this.setParams(this.projectsOrCompanies.length);

        this.getProjectsOrCompanies(this.params , this.module);
    }

    onChevronClick(event, searchProject) {
        this.utilsService.clearErrorMessages();
        this.isCardHidden = !this.isCardHidden;
    }

    //@override
    onTableViewClick($event, searchProject) {
        this.utilsService.clearErrorMessages();
        this.router.navigate(['./table-view']);
    }

    //@override
    onSaveSearchClick(event, searchProject) {
        this.utilsService.clearErrorMessages();

        this.savedSearch.object_type = this.module === 'projects' ? 'Project' : 'Company';
        this.savedSearch.is_default = false;
        this.savedSearch.query = this.isCIS()? this.cisFilterService.searchFilters : this.filterParam;

        this.fullLayoutComponent.dialogOpen = true;

        this.dialogService.open(SaveSearchComponent, {
            header: 'Save Search',
            ...this.appService.dynamicDialogConfig,
            data: this.savedSearch
        }).onClose.pipe(takeUntil(this.componentInView)).subscribe(() => {
            this.utilsService.clearErrorMessages();
            this.fullLayoutComponent.dialogOpen = false;
        });
    }

    isCIS() {
      return this.appService.isCIS();
    }
    searchCIS() {
      this.setParams();
      this.getProjectsOrCompanies(this.params , this.module);
    }

    getSavedSearch(savedSearchID) {
      if(this.isCIS()) {
        this.searchCIS();
      } else {
        this.showLoader = true;
        this.searchProjectsService.getSavedSearchByID(savedSearchID).subscribe(data => {
            this.showLoader = false;
            this.savedSearch = data;
            this.setSavedSearch();
        }, error => {
            this.showLoader = false;
            this.utilsService.handleError(error);
        })
      }
    }

    setSavedSearch() {
        this.searchProject.Search = [];
        if(this.savedSearch.object_type === 'Company') {
            this.module = 'companies';
        }

        this.filterParam = this.savedSearch.query;

        this.setParams();

        this.getProjectsOrCompanies(this.params , this.module);
    }

    markFavouriteStatus(item, status) {
      this.projectsOrCompanies.some(row => {
        if(row.id == item.id) {
          row.favourite = status;
          return true;
        }
      })
    }

    onAddFavouriteClick(item) {
      let favourite = true;
        if(item?.favourite) favourite = false;

        this.utilsService.clearErrorMessages();
        this.showLoader = true;

        let params = {
            favourite
        };

        this.subscriptions.push(
            this.searchProjectsService.markModuleAsFavorite(params , this.module , item.id).subscribe(() => {
              this.showLoader = false;
              this.markFavouriteStatus(item, favourite);
              this.saveData();
              // this.getProjectsOrCompanies(this.params , this.module);
                // this.isGoogleMapInfoWindowOpen = false;
                // this.infoWindow = null;
            }, error => {
                this.showLoader = false;
                this.utilsService.handleError(error);
            })
        );
    }

    onMarkerClicked(infoWindow) {
        if(!this.isGoogleMapInfoWindowOpen) {
            this.infoWindow = infoWindow;
            this.isGoogleMapInfoWindowOpen = true;
            return;
        }

        if(this.isGoogleMapInfoWindowOpen) {
            this.infoWindow.close();
            this.infoWindow = infoWindow;
            return;
        }
    }


    onHoverInCard(item) {
      this.hoveredCompanyID = item;
    }

    //@override
    getPageTitle(): string {
        return 'Dashboard';
    }
}

/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */
