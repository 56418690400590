/* {OPEN:IMPORT} {"sha1":"BAEC8F95BD3A2239F7F3F73DCC61F252E6E4B619"} */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SaveService } from './../../oneit/services/save.service';
import { SearchService } from './../../oneit/services/search.service';
import { UtilsService } from './../../oneit/services/utils.service';
/* {CLOSE:IMPORT} {"sha1":"BAEC8F95BD3A2239F7F3F73DCC61F252E6E4B619"} */
import { AppService } from '../../app.service';
/* {POSTCLOSE:IMPORT} {"sha1":"BAEC8F95BD3A2239F7F3F73DCC61F252E6E4B619"} */

@Injectable()
/* {PREOPEN:CLASS} {"sha1":"98768B19B5A149B3BA49F1FF75F745C1567730FC"} */
export class SavedSearchesService {
    /* {OPEN:CLASS} {} */

    constructor(
        /* {OPEN:constructor} {"sha1":"DCCC484B4DA23ED55D1380457121E9DAC4BB6B04"} */
        private searchService: SearchService,
        private saveService: SaveService,
        private utilsService: UtilsService,
        /* {CLOSE:constructor} {"sha1":"DCCC484B4DA23ED55D1380457121E9DAC4BB6B04"} */
        private appService: AppService
        /* {POSTCLOSE:constructor} {"sha1":"DCCC484B4DA23ED55D1380457121E9DAC4BB6B04"} */
    ) {
    }

    /* {CLOSE:CLASS} {} */

    getSavedSearches(): Observable<any> {
        return this.appService.getSavedSearches(`${this.appService.getSelectedDataProvider()}/saved_searches` , null);
    }

    getCacheFilters(): Observable<any> {
      return this.appService.getCacheFilters(`${this.appService.getSelectedDataProvider()}/cache` , null);
    }

}
/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */
