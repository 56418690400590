<p-messages [(value)]="errorMsgs" *ngIf="errorMsgs && errorMsgs.length > 0"></p-messages>
<!-- {PREOPEN:3875d5f8-7071-4353-a0d9-9a3379b2a4c9} {"sha1":"8C4A0E2E0EFD768CAB5850B359AF669A56A45AB4"} -->
<form #form="ngForm" [class.pointer-events-none]="this.showLoader">
	<!-- {OPEN:3875d5f8-7071-4353-a0d9-9a3379b2a4c9} {} -->
	<!-- {OPEN:3b87dd03-5344-470c-819e-06ee177f1634} {} -->
	<!-- {PREOPEN:d68c1a73-a6ef-40b5-b17a-13f871755e4e} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
	<div class="ui-g form-group">
		<!-- {OPEN:d68c1a73-a6ef-40b5-b17a-13f871755e4e} {} -->
		<!-- {PREOPEN:11201271-f61e-4968-84dd-0f5aab253a87} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
		<div class="ui-g-12 ui-md-3">
			<!-- {OPEN:11201271-f61e-4968-84dd-0f5aab253a87} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
			<!-- {CLOSE:11201271-f61e-4968-84dd-0f5aab253a87} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
		</div>
		<!-- {POSTCLOSE:11201271-f61e-4968-84dd-0f5aab253a87} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
		<!-- {PREOPEN:8d8da710-badf-407f-93ef-b792f64bb116} {"sha1":"EA45B5538DA9EF952A17324A1926BE826076CCEE"} -->
		<div class="ui-g-12 ui-md-6">
			<!-- {OPEN:8d8da710-badf-407f-93ef-b792f64bb116} {} -->
			<!-- {OPEN:a6ac7c26-a076-4d93-940f-b1a9ec7b8599} {"sha1":"09901B8F9BBFF44A2E9FD0119E8692889555E2D1"} -->
			<div class="ui-fluid">
                <span class="ui-float-label sticky">
                    <p-dropdown [autoDisplayFirst] = "false" required [(ngModel)]="searchMultipleProjectDealPopUp.Owner" name="Owner{{ searchMultipleProjectDealPopUp.ObjectID }}" fieldLabel="Owner" optionLabel="name" (onChange)="onOwnerChange($event, searchMultipleProjectDealPopUp)" [options]="ownerOptions"></p-dropdown>
                    <label>Owner</label>
                </span>
			</div>
			<!-- {CLOSE:a6ac7c26-a076-4d93-940f-b1a9ec7b8599} {"sha1":"09901B8F9BBFF44A2E9FD0119E8692889555E2D1"} -->
			<!-- {CLOSE:8d8da710-badf-407f-93ef-b792f64bb116} {} -->
		</div>
		<!-- {POSTCLOSE:8d8da710-badf-407f-93ef-b792f64bb116} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
		<!-- {PREOPEN:1f1e1854-1f30-4e27-b592-61a8c23080de} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
		<div class="ui-g-12 ui-md-3">
			<!-- {OPEN:1f1e1854-1f30-4e27-b592-61a8c23080de} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
			<!-- {CLOSE:1f1e1854-1f30-4e27-b592-61a8c23080de} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
		</div>
		<!-- {POSTCLOSE:1f1e1854-1f30-4e27-b592-61a8c23080de} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
		<!-- {CLOSE:d68c1a73-a6ef-40b5-b17a-13f871755e4e} {} -->
	</div>
	<!-- {POSTCLOSE:d68c1a73-a6ef-40b5-b17a-13f871755e4e} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
	<!-- {PREOPEN:9e567ad8-9370-4890-b8e6-31908563290c} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
	<div class="ui-g form-group">
		<!-- {OPEN:9e567ad8-9370-4890-b8e6-31908563290c} {} -->
		<!-- {PREOPEN:aee7c53e-6fd6-4ac5-b38c-0da4faf6867f} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
		<div class="ui-g-12 ui-md-3">
			<!-- {OPEN:aee7c53e-6fd6-4ac5-b38c-0da4faf6867f} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
			<!-- {CLOSE:aee7c53e-6fd6-4ac5-b38c-0da4faf6867f} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
		</div>
		<!-- {POSTCLOSE:aee7c53e-6fd6-4ac5-b38c-0da4faf6867f} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
		<!-- {PREOPEN:bc9f383d-b885-4ad3-a79c-f3de9af0bba0} {"sha1":"EA45B5538DA9EF952A17324A1926BE826076CCEE"} -->
		<div class="ui-g-12 ui-md-6">
			<!-- {OPEN:bc9f383d-b885-4ad3-a79c-f3de9af0bba0} {} -->
			<!-- {OPEN:7af9f458-9423-47a6-95a9-746a9a7be241} {"sha1":"D4AD88651BFF702765325ED09791C0C319935438"} -->
			<div class="ui-fluid">
                <span class="ui-float-label sticky">
                    <p-dropdown [autoDisplayFirst] = "false" required [(ngModel)]="searchMultipleProjectDealPopUp.Stage" name="Stage{{ searchMultipleProjectDealPopUp.ObjectID }}" fieldLabel="Stage" optionLabel="value" (onChange)="onStageChange($event, searchMultipleProjectDealPopUp)" [options]="stageOptions"></p-dropdown>
                    <label>Stage</label>
                </span>
			</div>
			<!-- {CLOSE:7af9f458-9423-47a6-95a9-746a9a7be241} {"sha1":"D4AD88651BFF702765325ED09791C0C319935438"} -->
			<!-- {CLOSE:bc9f383d-b885-4ad3-a79c-f3de9af0bba0} {} -->
		</div>
		<!-- {POSTCLOSE:bc9f383d-b885-4ad3-a79c-f3de9af0bba0} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
		<!-- {PREOPEN:f5b57962-01a8-4f5b-bf96-8ddf21d1fdba} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
		<div class="ui-g-12 ui-md-3">
			<!-- {OPEN:f5b57962-01a8-4f5b-bf96-8ddf21d1fdba} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
			<!-- {CLOSE:f5b57962-01a8-4f5b-bf96-8ddf21d1fdba} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
		</div>
		<!-- {POSTCLOSE:f5b57962-01a8-4f5b-bf96-8ddf21d1fdba} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
		<!-- {CLOSE:9e567ad8-9370-4890-b8e6-31908563290c} {} -->
	</div>
	<!-- {POSTCLOSE:9e567ad8-9370-4890-b8e6-31908563290c} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
	<!-- {PREOPEN:5cab934d-4467-4150-af04-a35042df6a43} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
	<div class="ui-g form-group">
		<!-- {OPEN:5cab934d-4467-4150-af04-a35042df6a43} {} -->
		<!-- {PREOPEN:96f932e1-a092-478c-bce3-30fd52582f4e} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
		<div class="ui-g-12 ui-md-3">
			<!-- {OPEN:96f932e1-a092-478c-bce3-30fd52582f4e} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
			<!-- {CLOSE:96f932e1-a092-478c-bce3-30fd52582f4e} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
		</div>
		<!-- {POSTCLOSE:96f932e1-a092-478c-bce3-30fd52582f4e} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
		<!-- {PREOPEN:6f7cd35c-7b32-4d34-9107-d7d7de0a131a} {"sha1":"EA45B5538DA9EF952A17324A1926BE826076CCEE"} -->
		<div class="ui-g-12 ui-md-6">
			<!-- {OPEN:6f7cd35c-7b32-4d34-9107-d7d7de0a131a} {} -->
			<!-- {OPEN:86303dde-c7fa-4b1f-8610-ce8a31018ecf} {"sha1":"3AA86B46AD7B72B0B01CF976373418C56C55421C"} -->
			<oneit-checkbox [(ngModel)]="searchMultipleProjectDealPopUp.Notifyowner" name="Notifyowner{{ searchMultipleProjectDealPopUp.ObjectID }}" fieldLabel="" binary="true" label="Notify owner"></oneit-checkbox>
			<!-- {CLOSE:86303dde-c7fa-4b1f-8610-ce8a31018ecf} {"sha1":"3AA86B46AD7B72B0B01CF976373418C56C55421C"} -->
			<!-- {CLOSE:6f7cd35c-7b32-4d34-9107-d7d7de0a131a} {} -->
		</div>
		<!-- {POSTCLOSE:6f7cd35c-7b32-4d34-9107-d7d7de0a131a} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
		<!-- {PREOPEN:a4f2ef61-f174-4483-96fc-74f7faf1b34a} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
		<div class="ui-g-12 ui-md-3">
			<!-- {OPEN:a4f2ef61-f174-4483-96fc-74f7faf1b34a} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
			<!-- {CLOSE:a4f2ef61-f174-4483-96fc-74f7faf1b34a} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
		</div>
		<!-- {POSTCLOSE:a4f2ef61-f174-4483-96fc-74f7faf1b34a} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
		<!-- {CLOSE:5cab934d-4467-4150-af04-a35042df6a43} {} -->
	</div>
	<!-- {POSTCLOSE:5cab934d-4467-4150-af04-a35042df6a43} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
	<!-- {PREOPEN:1dc18d7a-33bc-41ac-a97b-180505fff827} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
	<div class="ui-g form-group">
		<!-- {OPEN:1dc18d7a-33bc-41ac-a97b-180505fff827} {} -->
		<!-- {PREOPEN:75f49e2a-1790-4737-a3dd-52568e01b7db} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
		<div class="ui-g-12 ui-md-3">
			<!-- {OPEN:75f49e2a-1790-4737-a3dd-52568e01b7db} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
			<!-- {CLOSE:75f49e2a-1790-4737-a3dd-52568e01b7db} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
		</div>
		<!-- {POSTCLOSE:75f49e2a-1790-4737-a3dd-52568e01b7db} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
		<!-- {PREOPEN:7ed3aed2-d411-4a6b-981a-bdfefa9bca49} {"sha1":"EA45B5538DA9EF952A17324A1926BE826076CCEE"} -->
		<div class="ui-g-12 ui-md-6">
			<!-- {OPEN:7ed3aed2-d411-4a6b-981a-bdfefa9bca49} {} -->
			<!-- {OPEN:e5ff8a49-d64c-47eb-91f0-c03a6421b95b} {"sha1":"20A96C28672259B2A0D72B0CDB571B2622273D17"} -->
			<div class="ui-fluid">
				<button pButton class="buttonStyle" type="button" label="Create Deal" (click)="onCreateDealClick($event, searchMultipleProjectDealPopUp)"></button>
			</div>
			<!-- {CLOSE:e5ff8a49-d64c-47eb-91f0-c03a6421b95b} {"sha1":"20A96C28672259B2A0D72B0CDB571B2622273D17"} -->
			<!-- {CLOSE:7ed3aed2-d411-4a6b-981a-bdfefa9bca49} {} -->
		</div>
		<!-- {POSTCLOSE:7ed3aed2-d411-4a6b-981a-bdfefa9bca49} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
		<!-- {PREOPEN:417b97a6-5e65-4d4c-bc6a-7f8c0081306d} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
		<div class="ui-g-12 ui-md-3">
			<!-- {OPEN:417b97a6-5e65-4d4c-bc6a-7f8c0081306d} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
			<!-- {CLOSE:417b97a6-5e65-4d4c-bc6a-7f8c0081306d} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
		</div>
		<!-- {POSTCLOSE:417b97a6-5e65-4d4c-bc6a-7f8c0081306d} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
		<!-- {CLOSE:1dc18d7a-33bc-41ac-a97b-180505fff827} {} -->
	</div>
	<!-- {POSTCLOSE:1dc18d7a-33bc-41ac-a97b-180505fff827} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
	<!-- {CLOSE:3b87dd03-5344-470c-819e-06ee177f1634} {} -->
	<p-progressSpinner *ngIf="showLoader"></p-progressSpinner>
	<!-- {CLOSE:3875d5f8-7071-4353-a0d9-9a3379b2a4c9} {} -->
</form>
<!-- {POSTCLOSE:3875d5f8-7071-4353-a0d9-9a3379b2a4c9} {"sha1":"3FD6E659562C1876D02FC8CF54746B1502442CD2"} -->
