<!-- {PREOPEN:238057c7-2796-44ac-80d7-e9ad37d17eb0} {"sha1":"8C4A0E2E0EFD768CAB5850B359AF669A56A45AB4"} -->
<form #form="ngForm" [class.pointer-events-none]="this.showLoader">
	<!-- {OPEN:238057c7-2796-44ac-80d7-e9ad37d17eb0} {} -->
	<!-- {OPEN:Toolbar} {"sha1":"58C85CCF9FF02F3BAA012023D0982BED8C08259E"} -->
	<p-toolbar>
		<div class="ui-toolbar-group-left">
			{{ pageTitle }}
		</div>
	</p-toolbar>
	<!-- {CLOSE:Toolbar} {"sha1":"58C85CCF9FF02F3BAA012023D0982BED8C08259E"} -->
	<!-- {PREOPEN:a6eba59f-687b-4f8b-9cd4-b9bb3afa9132} {"sha1":"7B0B39525499D19275B693A8C388A6F9F1746F8B"} -->
	<p-fieldset (onBeforeToggle)="pinSearchOpen = true" legend="Search" [toggleable]="!pinSearchOpen" [collapsed]="!pinSearchOpen">
		<!-- {OPEN:a6eba59f-687b-4f8b-9cd4-b9bb3afa9132} {} -->
		<!-- {OPEN:8d4aa57e-4bd6-4b88-a2ce-5fee19712a9a} {} -->
		<!-- {PREOPEN:c629d739-8a03-4c6e-b5a3-18d4a4c2f706} {"sha1":"66120DE8739BDA5285D0BBAAE097CD1502DA20C8"} -->
		<div class="ui-g form-group">
			<!-- {OPEN:c629d739-8a03-4c6e-b5a3-18d4a4c2f706} {} -->
			<!-- {PREOPEN:081ff071-dcf1-4b06-b385-fc3be166a22f} {"sha1":"22B61780C63FCF89B1298EEC6B3C5A859E82882D"} -->
			<div class="ui-g-12 ui-md-12">
				<!-- {OPEN:081ff071-dcf1-4b06-b385-fc3be166a22f} {} -->
				<!-- {OPEN:f625b84a-8fd4-40c2-9020-6554e8af0d6c} {"sha1":"E11EE60F5EA1DDCFD9C4BEE9026C26D842DB79AC"} -->
				<div class="ui-fluid">
                    <span class="ui-float-label sticky">
                        <input [(ngModel)]="searchConvertedDeal.Details" name="Details{{ searchConvertedDeal.ObjectID }}" fieldLabel="Details" type="text" pInputText class="ui-inputtext">
                        <label>Details</label>
                    </span>
				</div>
				<!-- {CLOSE:f625b84a-8fd4-40c2-9020-6554e8af0d6c} {"sha1":"E11EE60F5EA1DDCFD9C4BEE9026C26D842DB79AC"} -->
				<!-- {CLOSE:081ff071-dcf1-4b06-b385-fc3be166a22f} {} -->
			</div>
			<!-- {POSTCLOSE:081ff071-dcf1-4b06-b385-fc3be166a22f} {"sha1":"797F18B33CA2DAC7B5A389B76ABFD8D91F547465"} -->
			<!-- {CLOSE:c629d739-8a03-4c6e-b5a3-18d4a4c2f706} {} -->
		</div>
		<!-- {POSTCLOSE:c629d739-8a03-4c6e-b5a3-18d4a4c2f706} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
		<!-- {PREOPEN:c2272bc6-a904-4045-b188-caf5eb672bfb} {"sha1":"66120DE8739BDA5285D0BBAAE097CD1502DA20C8"} -->
		<div class="ui-g form-group">
			<!-- {OPEN:c2272bc6-a904-4045-b188-caf5eb672bfb} {} -->
			<!-- {PREOPEN:eacbdc9e-bd40-4c71-8435-83ac0081f567} {"sha1":"2B732D9EFD5E930E87F2F52918896EBD4796AD3F"} -->
			<div class="ui-g-12 ui-md-3">
				<!-- {OPEN:eacbdc9e-bd40-4c71-8435-83ac0081f567} {} -->
				<!-- {OPEN:1984046e-70c8-4a69-9699-7e5fb5413a2d} {"sha1":"DB935A36D9CDA0BFFAEDF3E2E535C89E3E4FAABF"} -->
				<div class="ui-fluid">
                    <span class="ui-float-label sticky">
                        <p-calendar [(ngModel)]="searchConvertedDeal.DateFrom" name="DateFrom{{ searchConvertedDeal.ObjectID }}" fieldLabel="Date  From" [showIcon]="true" dataType="string" [dateFormat]="'dd-mm-yy'" [view]="'date'" [showTime]="false" [timeOnly]="false"></p-calendar>
                        <label>Date  From</label>
                    </span>
				</div>
				<!-- {CLOSE:1984046e-70c8-4a69-9699-7e5fb5413a2d} {"sha1":"DB935A36D9CDA0BFFAEDF3E2E535C89E3E4FAABF"} -->
				<!-- {CLOSE:eacbdc9e-bd40-4c71-8435-83ac0081f567} {} -->
			</div>
			<!-- {POSTCLOSE:eacbdc9e-bd40-4c71-8435-83ac0081f567} {"sha1":"797F18B33CA2DAC7B5A389B76ABFD8D91F547465"} -->
			<!-- {PREOPEN:c8ebac55-dafb-4177-952d-f87a9b566406} {"sha1":"2B732D9EFD5E930E87F2F52918896EBD4796AD3F"} -->
			<div class="ui-g-12 ui-md-3">
				<!-- {OPEN:c8ebac55-dafb-4177-952d-f87a9b566406} {} -->
				<!-- {OPEN:39c83c7d-bff0-4f9d-be93-b29a4f44d743} {"sha1":"9FC738ACED0FDAF23D3E74840317E982FB289B60"} -->
				<div class="ui-fluid">
                    <span class="ui-float-label sticky">
                        <p-calendar [(ngModel)]="searchConvertedDeal.DateTo" name="DateTo{{ searchConvertedDeal.ObjectID }}" fieldLabel="Date To" [showIcon]="true" dataType="string" [dateFormat]="'dd-mm-yy'" [view]="'date'" [showTime]="false" [timeOnly]="false"></p-calendar>
                        <label>Date To</label>
                    </span>
				</div>
				<!-- {CLOSE:39c83c7d-bff0-4f9d-be93-b29a4f44d743} {"sha1":"9FC738ACED0FDAF23D3E74840317E982FB289B60"} -->
				<!-- {CLOSE:c8ebac55-dafb-4177-952d-f87a9b566406} {} -->
			</div>
			<!-- {POSTCLOSE:c8ebac55-dafb-4177-952d-f87a9b566406} {"sha1":"797F18B33CA2DAC7B5A389B76ABFD8D91F547465"} -->
			<!-- {PREOPEN:f3a7032a-0ec0-486f-ae13-141c930aaa0b} {"sha1":"2B732D9EFD5E930E87F2F52918896EBD4796AD3F"} -->
			<div class="ui-g-12 ui-md-3">
				<!-- {OPEN:f3a7032a-0ec0-486f-ae13-141c930aaa0b} {"sha1":"E75D56C7BF9827762CA66D821DEFF8DE19010D1C"} -->
				<!-- {CLOSE:f3a7032a-0ec0-486f-ae13-141c930aaa0b} {"sha1":"E75D56C7BF9827762CA66D821DEFF8DE19010D1C"} -->
			</div>
			<!-- {POSTCLOSE:f3a7032a-0ec0-486f-ae13-141c930aaa0b} {"sha1":"797F18B33CA2DAC7B5A389B76ABFD8D91F547465"} -->
			<!-- {PREOPEN:75eb8a06-3343-47b8-a603-0864aec6319c} {"sha1":"2B732D9EFD5E930E87F2F52918896EBD4796AD3F"} -->
			<div class="ui-g-12 ui-md-3">
				<!-- {OPEN:75eb8a06-3343-47b8-a603-0864aec6319c} {"sha1":"E75D56C7BF9827762CA66D821DEFF8DE19010D1C"} -->
				<!-- {CLOSE:75eb8a06-3343-47b8-a603-0864aec6319c} {"sha1":"E75D56C7BF9827762CA66D821DEFF8DE19010D1C"} -->
			</div>
			<!-- {POSTCLOSE:75eb8a06-3343-47b8-a603-0864aec6319c} {"sha1":"797F18B33CA2DAC7B5A389B76ABFD8D91F547465"} -->
			<!-- {CLOSE:c2272bc6-a904-4045-b188-caf5eb672bfb} {} -->
		</div>
		<!-- {POSTCLOSE:c2272bc6-a904-4045-b188-caf5eb672bfb} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
		<!-- {PREOPEN:d3e8639a-0a56-42d0-a23c-df2a1d16e131} {"sha1":"66120DE8739BDA5285D0BBAAE097CD1502DA20C8"} -->
		<div class="ui-g form-group">
			<!-- {OPEN:d3e8639a-0a56-42d0-a23c-df2a1d16e131} {} -->
			<!-- {PREOPEN:824ad5d1-7ec0-4d64-ba12-bccca814c67b} {"sha1":"2B732D9EFD5E930E87F2F52918896EBD4796AD3F"} -->
			<div class="ui-g-12 ui-md-3">
				<!-- {OPEN:824ad5d1-7ec0-4d64-ba12-bccca814c67b} {} -->
				<!-- {OPEN:d232886d-db05-474e-8151-4a9f5206dc6a} {"sha1":"2CD2FB121B901A0AC3B222DDBC83A1B28F25D55E"} -->
				<oneit-checkbox [(ngModel)]="pinSearchOpen" name="PinSearchOpen{{ searchConvertedDeal.ObjectID }}" fieldLabel="" binary="true" label="Pin Search Open"></oneit-checkbox>
				<!-- {CLOSE:d232886d-db05-474e-8151-4a9f5206dc6a} {"sha1":"2CD2FB121B901A0AC3B222DDBC83A1B28F25D55E"} -->
				<!-- {CLOSE:824ad5d1-7ec0-4d64-ba12-bccca814c67b} {} -->
			</div>
			<!-- {POSTCLOSE:824ad5d1-7ec0-4d64-ba12-bccca814c67b} {"sha1":"797F18B33CA2DAC7B5A389B76ABFD8D91F547465"} -->
			<!-- {PREOPEN:84923224-5d70-4533-9798-74cebe202c00} {"sha1":"2B732D9EFD5E930E87F2F52918896EBD4796AD3F"} -->
			<div class="ui-g-12 ui-md-3">
				<!-- {OPEN:84923224-5d70-4533-9798-74cebe202c00} {"sha1":"E75D56C7BF9827762CA66D821DEFF8DE19010D1C"} -->
				<!-- {CLOSE:84923224-5d70-4533-9798-74cebe202c00} {"sha1":"E75D56C7BF9827762CA66D821DEFF8DE19010D1C"} -->
			</div>
			<!-- {POSTCLOSE:84923224-5d70-4533-9798-74cebe202c00} {"sha1":"797F18B33CA2DAC7B5A389B76ABFD8D91F547465"} -->
			<!-- {PREOPEN:7a414e6e-6b5c-4ad7-b062-89d17e6dc831} {"sha1":"2B732D9EFD5E930E87F2F52918896EBD4796AD3F"} -->
			<div class="ui-g-12 ui-md-3">
				<!-- {OPEN:7a414e6e-6b5c-4ad7-b062-89d17e6dc831} {"sha1":"E75D56C7BF9827762CA66D821DEFF8DE19010D1C"} -->
				<!-- {CLOSE:7a414e6e-6b5c-4ad7-b062-89d17e6dc831} {"sha1":"E75D56C7BF9827762CA66D821DEFF8DE19010D1C"} -->
			</div>
			<!-- {POSTCLOSE:7a414e6e-6b5c-4ad7-b062-89d17e6dc831} {"sha1":"797F18B33CA2DAC7B5A389B76ABFD8D91F547465"} -->
			<!-- {PREOPEN:b194dccf-9f00-42e5-bb0a-e12465ae936c} {"sha1":"2B732D9EFD5E930E87F2F52918896EBD4796AD3F"} -->
			<div class="ui-g-12 ui-md-3">
				<!-- {OPEN:b194dccf-9f00-42e5-bb0a-e12465ae936c} {"sha1":"E75D56C7BF9827762CA66D821DEFF8DE19010D1C"} -->
				<!-- {CLOSE:b194dccf-9f00-42e5-bb0a-e12465ae936c} {"sha1":"E75D56C7BF9827762CA66D821DEFF8DE19010D1C"} -->
			</div>
			<!-- {POSTCLOSE:b194dccf-9f00-42e5-bb0a-e12465ae936c} {"sha1":"797F18B33CA2DAC7B5A389B76ABFD8D91F547465"} -->
			<!-- {CLOSE:d3e8639a-0a56-42d0-a23c-df2a1d16e131} {} -->
		</div>
		<!-- {POSTCLOSE:d3e8639a-0a56-42d0-a23c-df2a1d16e131} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
		<!-- {CLOSE:8d4aa57e-4bd6-4b88-a2ce-5fee19712a9a} {} -->

		<!-- {OPEN:2b7aeeab-880f-4875-9a43-0ce7de18d168} {} -->
		<!-- {PREOPEN:1260ca49-26c4-43c6-90e7-5fd1f089f59e} {"sha1":"66120DE8739BDA5285D0BBAAE097CD1502DA20C8"} -->
		<div class="ui-g form-group">
			<!-- {OPEN:1260ca49-26c4-43c6-90e7-5fd1f089f59e} {} -->
			<!-- {PREOPEN:6f878dcb-36e5-4a2b-8e85-5f9d8a0a3b52} {"sha1":"6FA1551590948E4C8B351C89F6B2A217F365FAB3"} -->
			<div class="ui-g-12 ui-md-1 mr-20">
				<!-- {OPEN:6f878dcb-36e5-4a2b-8e85-5f9d8a0a3b52} {} -->
				<!-- {OPEN:a4f0653d-01c1-452a-8135-cb09626218a0} {"sha1":"19051B38F1A440335206FDE84267D391CFD7CAA3"} -->
				<button pButton type="button" label="Search" icon="ui-icon-search" (click)="onSearchClick($event, searchConvertedDeal)"></button>
				<!-- {CLOSE:a4f0653d-01c1-452a-8135-cb09626218a0} {"sha1":"19051B38F1A440335206FDE84267D391CFD7CAA3"} -->
				<!-- {CLOSE:6f878dcb-36e5-4a2b-8e85-5f9d8a0a3b52} {} -->
			</div>
			<!-- {POSTCLOSE:6f878dcb-36e5-4a2b-8e85-5f9d8a0a3b52} {"sha1":"797F18B33CA2DAC7B5A389B76ABFD8D91F547465"} -->
			<!-- {PREOPEN:cb457444-1f05-4b40-8141-a4bc0289aab7} {"sha1":"6FA1551590948E4C8B351C89F6B2A217F365FAB3"} -->
			<div class="ui-g-12 ui-md-1 mr-20 ml-20">
				<!-- {OPEN:cb457444-1f05-4b40-8141-a4bc0289aab7} {} -->
				<!-- {OPEN:429ab797-dacf-401b-87d9-552c29b25b83} {"sha1":"C733D0AC2F71B585B92FF4310FF9848B8BE95D2E"} -->
				<button pButton type="button" label="Clear" icon="ui-icon-clear" class="btn-tertiary" (click)="onClearClick($event, searchConvertedDeal)"></button>
				<!-- {CLOSE:429ab797-dacf-401b-87d9-552c29b25b83} {"sha1":"C733D0AC2F71B585B92FF4310FF9848B8BE95D2E"} -->
				<!-- {CLOSE:cb457444-1f05-4b40-8141-a4bc0289aab7} {} -->
			</div>
			<!-- {POSTCLOSE:cb457444-1f05-4b40-8141-a4bc0289aab7} {"sha1":"797F18B33CA2DAC7B5A389B76ABFD8D91F547465"} -->
			<!-- {PREOPEN:c121c7c9-fa1d-4d8e-9aeb-b08e76ab915b} {"sha1":"D357C1EEDC5658A75E776CE4A5216D91ADD4DC15"} -->
			<div class="ui-g-12 ui-md-1 ml-20 w-16 w-24" *ngIf="convertedDeals && convertedDeals.length > 0" >
				<!-- {OPEN:c121c7c9-fa1d-4d8e-9aeb-b08e76ab915b} {} -->
				<!-- {OPEN:ad4899c0-fa66-42e9-84a9-3501205f553e} {"sha1":"D5E8658A7E42178FCA6FA4DCB5362AD21A116FB2"} -->
				<button pButton type="button" label="Update CRM" icon="ui-icon-update" [class]='["btn-secondary"]' (click)="onUpdateCrmClick($event, searchConvertedDeal)"></button>
				<!-- {CLOSE:ad4899c0-fa66-42e9-84a9-3501205f553e} {"sha1":"D5E8658A7E42178FCA6FA4DCB5362AD21A116FB2"} -->
				<!-- {CLOSE:c121c7c9-fa1d-4d8e-9aeb-b08e76ab915b} {} -->
			</div>
			<!-- {POSTCLOSE:c121c7c9-fa1d-4d8e-9aeb-b08e76ab915b} {"sha1":"797F18B33CA2DAC7B5A389B76ABFD8D91F547465"} -->
			<!-- {PREOPEN:dfddd7e0-8960-4da5-9663-8170484b4b9f} {"sha1":"D357C1EEDC5658A75E776CE4A5216D91ADD4DC15"} -->
			<div class="ui-g-12 ui-md-1" *ngIf="false">
				<!-- {OPEN:dfddd7e0-8960-4da5-9663-8170484b4b9f} {"sha1":"E75D56C7BF9827762CA66D821DEFF8DE19010D1C"} -->
				<!-- {CLOSE:dfddd7e0-8960-4da5-9663-8170484b4b9f} {"sha1":"E75D56C7BF9827762CA66D821DEFF8DE19010D1C"} -->
			</div>
			<!-- {POSTCLOSE:dfddd7e0-8960-4da5-9663-8170484b4b9f} {"sha1":"797F18B33CA2DAC7B5A389B76ABFD8D91F547465"} -->
			<!-- {PREOPEN:00d4bb6c-255d-435a-8d62-166d9d75585e} {"sha1":"D357C1EEDC5658A75E776CE4A5216D91ADD4DC15"} -->
			<div class="ui-g-12 ui-md-2" *ngIf="false">
				<!-- {OPEN:00d4bb6c-255d-435a-8d62-166d9d75585e} {"sha1":"E75D56C7BF9827762CA66D821DEFF8DE19010D1C"} -->
				<!-- {CLOSE:00d4bb6c-255d-435a-8d62-166d9d75585e} {"sha1":"E75D56C7BF9827762CA66D821DEFF8DE19010D1C"} -->
			</div>
			<!-- {POSTCLOSE:00d4bb6c-255d-435a-8d62-166d9d75585e} {"sha1":"797F18B33CA2DAC7B5A389B76ABFD8D91F547465"} -->
			<!-- {PREOPEN:66defabe-8c1d-41db-a37c-547b2f6ef246} {"sha1":"D357C1EEDC5658A75E776CE4A5216D91ADD4DC15"} -->
			<div class="ui-g-12 ui-md-2" *ngIf="false">
				<!-- {OPEN:66defabe-8c1d-41db-a37c-547b2f6ef246} {"sha1":"E75D56C7BF9827762CA66D821DEFF8DE19010D1C"} -->
				<!-- {CLOSE:66defabe-8c1d-41db-a37c-547b2f6ef246} {"sha1":"E75D56C7BF9827762CA66D821DEFF8DE19010D1C"} -->
			</div>
			<!-- {POSTCLOSE:66defabe-8c1d-41db-a37c-547b2f6ef246} {"sha1":"797F18B33CA2DAC7B5A389B76ABFD8D91F547465"} -->
			<!-- {PREOPEN:df9b63aa-a673-4408-b9a9-5f2fdea0a1d4} {"sha1":"D357C1EEDC5658A75E776CE4A5216D91ADD4DC15"} -->
			<div class="ui-g-12 ui-md-2" *ngIf="false">
				<!-- {OPEN:df9b63aa-a673-4408-b9a9-5f2fdea0a1d4} {"sha1":"E75D56C7BF9827762CA66D821DEFF8DE19010D1C"} -->
				<!-- {CLOSE:df9b63aa-a673-4408-b9a9-5f2fdea0a1d4} {"sha1":"E75D56C7BF9827762CA66D821DEFF8DE19010D1C"} -->
			</div>
			<!-- {POSTCLOSE:df9b63aa-a673-4408-b9a9-5f2fdea0a1d4} {"sha1":"797F18B33CA2DAC7B5A389B76ABFD8D91F547465"} -->
			<!-- {CLOSE:1260ca49-26c4-43c6-90e7-5fd1f089f59e} {} -->
		</div>
		<!-- {POSTCLOSE:1260ca49-26c4-43c6-90e7-5fd1f089f59e} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
		<!-- {CLOSE:2b7aeeab-880f-4875-9a43-0ce7de18d168} {} -->

		<!-- {CLOSE:a6eba59f-687b-4f8b-9cd4-b9bb3afa9132} {} -->
	</p-fieldset>
	<!-- {POSTCLOSE:a6eba59f-687b-4f8b-9cd4-b9bb3afa9132} {"sha1":"6B298AF7D979106E721F4D434037961D8874FB75"} -->
	<!-- {PREOPEN:0c7ab02b-5754-44b5-9dcf-055e70e9f513} {"sha1":"1D0C907A47E6DB32FC7B4BBB9A7C94E91398154E"} -->
	<p-table #resultsTable [columns]="resultsTableCols" [value]="convertedDeals" [(selection)]="selectedConvertedDeals"
			 [selectionMode]="resultsTableSelectionMode" (onRowSelect)="onResultsTableRowSelect($event.data)" [paginator]="true" [rows]="10" [rowsPerPageOptions]='["10", "20", "30"]'>
		<!-- {OPEN:0c7ab02b-5754-44b5-9dcf-055e70e9f513} {} -->
		<!-- {PREOPEN:CAPTION-0c7ab02b-5754-44b5-9dcf-055e70e9f513} {"sha1":"EE4A026D8E348759F09A114C1F0A1407209DFB32"} -->
		<ng-template pTemplate="caption">
			<!-- {OPEN:CAPTION-0c7ab02b-5754-44b5-9dcf-055e70e9f513} {"sha1":"2E135F7BE408FF10EBBB01A26B4BCC74FA572F92"} -->
			<div class="ui-table-caption-container ui-helper-clearfix">
				<span class="table-caption">{{ resultsTableTitle }}({{this.convertedDeals && this.convertedDeals.length ? this.convertedDeals.length : '0'}})</span>
				<div class="ui-table-buttons-container">
				</div>
			</div>
			<!-- {CLOSE:CAPTION-0c7ab02b-5754-44b5-9dcf-055e70e9f513} {"sha1":"2E135F7BE408FF10EBBB01A26B4BCC74FA572F92"} -->
		</ng-template>
		<!-- {POSTCLOSE:CAPTION-0c7ab02b-5754-44b5-9dcf-055e70e9f513} {"sha1":"B49156045CB826E5C1A4343CDBBFB703D415ECE1"} -->
		<!-- {PREOPEN:COLGROUP-0c7ab02b-5754-44b5-9dcf-055e70e9f513} {"sha1":"1F108DAF4B774A5CCBA28843160983341B2896F5"} -->
		<ng-template pTemplate="colgroup" let-columns>
			<!-- {OPEN:COLGROUP-0c7ab02b-5754-44b5-9dcf-055e70e9f513} {"sha1":"DB57B00639822DF5A70B3BE86233E5FF2E3285CF"} -->
			<colgroup>
				<col *ngFor="let col of columns" [style.width]="col.width">
			</colgroup>
			<!-- {CLOSE:COLGROUP-0c7ab02b-5754-44b5-9dcf-055e70e9f513} {"sha1":"DB57B00639822DF5A70B3BE86233E5FF2E3285CF"} -->
		</ng-template>
		<!-- {POSTCLOSE:COLGROUP-0c7ab02b-5754-44b5-9dcf-055e70e9f513} {"sha1":"B49156045CB826E5C1A4343CDBBFB703D415ECE1"} -->
		<!-- {PREOPEN:HEADER-0c7ab02b-5754-44b5-9dcf-055e70e9f513} {"sha1":"A80411AECFDC496FC955192B403A638A750F836E"} -->
		<ng-template pTemplate="header" let-columns>
			<!-- {OPEN:HEADER-0c7ab02b-5754-44b5-9dcf-055e70e9f513} {"sha1":"1BFD5C655633AA2A536C4FB43E600974FC665007"} -->
			<tr>
				<th *ngFor="let col of columns" [pSortableColumn]="col.sortField || col.field" [pSortableColumnDisabled]="col.noSort">
					<ng-container *ngIf="col.header">
						{{ col.header }}
					</ng-container>
					<ng-container *ngIf="!col.header">
						<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
					</ng-container>
					<p-sortIcon [field]="col.sortField || col.field" *ngIf="!col.noSort"></p-sortIcon>
				</th>
			</tr>
			<!-- {CLOSE:HEADER-0c7ab02b-5754-44b5-9dcf-055e70e9f513} {"sha1":"1BFD5C655633AA2A536C4FB43E600974FC665007"} -->
		</ng-template>
		<!-- {POSTCLOSE:HEADER-0c7ab02b-5754-44b5-9dcf-055e70e9f513} {"sha1":"B49156045CB826E5C1A4343CDBBFB703D415ECE1"} -->
		<!-- {PREOPEN:BODY-0c7ab02b-5754-44b5-9dcf-055e70e9f513} {"sha1":"38D9964DD46452A9D25EF789795DC8F2F8EA0076"} -->
		<ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
			<!-- {OPEN:BODY-0c7ab02b-5754-44b5-9dcf-055e70e9f513} {} -->
			<!-- {PREOPEN:b0253d37-cd56-4e75-83ca-201185714079} {"sha1":"71F334728994128639065D5CF32482B59D11AC1C"} -->
			<tr [pSelectableRow]="rowData" [pSelectableRowDisabled]="resultsTableSelectionMode ? false : true">
				<!-- {OPEN:b0253d37-cd56-4e75-83ca-201185714079} {} -->
				<!-- {PREOPEN:d2f56df2-7ff1-49df-bad4-1918814eefe8} {"sha1":"BD296C2D0009EBE3FF12F3F146D5096A5C6E80DA"} -->
				<td>
					<p-tableCheckbox [value]="rowData"></p-tableCheckbox>
				</td>
				<td>
					<!-- {OPEN:d2f56df2-7ff1-49df-bad4-1918814eefe8} {} -->
					<!-- {OPEN:a69bfa49-da2e-4c14-8897-348e1f3fa064} {"sha1":"55DB084A0DC5F918F5C6244E052721B20223BA79"} -->
					<a>{{ rowData.projectName }}</a>
					<!-- {CLOSE:a69bfa49-da2e-4c14-8897-348e1f3fa064} {"sha1":"55DB084A0DC5F918F5C6244E052721B20223BA79"} -->
					<!-- {CLOSE:d2f56df2-7ff1-49df-bad4-1918814eefe8} {} -->
				</td>
				<!-- {POSTCLOSE:d2f56df2-7ff1-49df-bad4-1918814eefe8} {"sha1":"494D982B5C26FB64E63D5708771E13C61BFE8C6E"} -->
				<!-- {PREOPEN:ec14b365-5060-4fa4-9725-cd260c58e42e} {"sha1":"BD296C2D0009EBE3FF12F3F146D5096A5C6E80DA"} -->
				<td>
					<!-- {OPEN:ec14b365-5060-4fa4-9725-cd260c58e42e} {} -->
					<!-- {OPEN:21933d33-85f0-47ba-85f7-4e9cf6521cc6} {"sha1":"891DF6ED9F652E06A2616827534C72F0474EB59D"} -->
					<label>{{ rowData.projectId }}</label>
					<!-- {CLOSE:21933d33-85f0-47ba-85f7-4e9cf6521cc6} {"sha1":"891DF6ED9F652E06A2616827534C72F0474EB59D"} -->
					<!-- {CLOSE:ec14b365-5060-4fa4-9725-cd260c58e42e} {} -->
				</td>
				<!-- {POSTCLOSE:ec14b365-5060-4fa4-9725-cd260c58e42e} {"sha1":"494D982B5C26FB64E63D5708771E13C61BFE8C6E"} -->
				<!-- {PREOPEN:20557018-014f-4979-a9b0-a4ce52f63e74} {"sha1":"BD296C2D0009EBE3FF12F3F146D5096A5C6E80DA"} -->
				<td>
					<!-- {OPEN:20557018-014f-4979-a9b0-a4ce52f63e74} {} -->
					<!-- {OPEN:33fefd2a-e56f-441d-b959-71e8f3b2b8f6} {"sha1":"E9B378681BF452E1497D036ECD75A69D915D7CB5"} -->
					<label>{{ rowData.name }}</label>
					<!-- {CLOSE:33fefd2a-e56f-441d-b959-71e8f3b2b8f6} {"sha1":"E9B378681BF452E1497D036ECD75A69D915D7CB5"} -->
					<!-- {CLOSE:20557018-014f-4979-a9b0-a4ce52f63e74} {} -->
				</td>
				<td>
					<label>{{ rowData.owner }}</label>
				</td>
				<td>
					<label>{{ rowData.stage }}</label>
				</td>
				<!-- {POSTCLOSE:20557018-014f-4979-a9b0-a4ce52f63e74} {"sha1":"494D982B5C26FB64E63D5708771E13C61BFE8C6E"} -->
				<!-- {PREOPEN:d22acbca-a299-4f02-8080-6dbe347a1808} {"sha1":"BD296C2D0009EBE3FF12F3F146D5096A5C6E80DA"} -->
				<!-- {POSTCLOSE:d22acbca-a299-4f02-8080-6dbe347a1808} {"sha1":"494D982B5C26FB64E63D5708771E13C61BFE8C6E"} -->
				<!-- {PREOPEN:18bc4876-f61c-4e02-ba7d-391f39039538} {"sha1":"BD296C2D0009EBE3FF12F3F146D5096A5C6E80DA"} -->
				<td>
					<!-- {OPEN:18bc4876-f61c-4e02-ba7d-391f39039538} {} -->
					<!-- {OPEN:2a4eaa78-36f3-4981-8ca1-c653f1d8b5f4} {"sha1":"E6A46298B87581A8530C7A87DFA01548B58A87B1"} -->
					<label>{{ rowData.createdDate }}</label>
					<!-- {CLOSE:2a4eaa78-36f3-4981-8ca1-c653f1d8b5f4} {"sha1":"E6A46298B87581A8530C7A87DFA01548B58A87B1"} -->
					<!-- {CLOSE:18bc4876-f61c-4e02-ba7d-391f39039538} {} -->
				</td>
				<!-- {POSTCLOSE:18bc4876-f61c-4e02-ba7d-391f39039538} {"sha1":"494D982B5C26FB64E63D5708771E13C61BFE8C6E"} -->
				<!-- {CLOSE:b0253d37-cd56-4e75-83ca-201185714079} {} -->
			</tr>
			<!-- {POSTCLOSE:b0253d37-cd56-4e75-83ca-201185714079} {"sha1":"D6A68C730B603867745E9FB409805C0BA8CA314A"} -->
			<!-- {CLOSE:BODY-0c7ab02b-5754-44b5-9dcf-055e70e9f513} {} -->
		</ng-template>
		<!-- {POSTCLOSE:BODY-0c7ab02b-5754-44b5-9dcf-055e70e9f513} {"sha1":"B49156045CB826E5C1A4343CDBBFB703D415ECE1"} -->
		<!-- {CLOSE:0c7ab02b-5754-44b5-9dcf-055e70e9f513} {} -->
	</p-table>
	<!-- {POSTCLOSE:0c7ab02b-5754-44b5-9dcf-055e70e9f513} {"sha1":"12061B5414F51422BD3E7F83907B78364E6F0405"} -->
	<oneit-confirmDialog></oneit-confirmDialog>
	<p-progressSpinner *ngIf="showLoader"></p-progressSpinner>
	<!-- {CLOSE:238057c7-2796-44ac-80d7-e9ad37d17eb0} {} -->
</form>
<!-- {POSTCLOSE:238057c7-2796-44ac-80d7-e9ad37d17eb0} {"sha1":"3FD6E659562C1876D02FC8CF54746B1502442CD2"} -->
