import { Input } from '@angular/core';
import { CISTextFilterComponent } from '../cis-text-filter/cis-text-filter.component';
import { CisGeoFilterComponent } from '../cis-geo-filter/cis-geo-filter.component';
import { CISTrackingFilterComponent } from '../cis-tracking-filter/cis-tracking-filter.component';
import { CisDateFilterComponent } from '../cis-date-filter/cis-date-filter.component';
import { CisFilterService } from '../cis-filter.service';
import { Constants } from 'src/app/cbos/cis/consts';
import { AppService } from '../../../app.service';
import { TreeFilterComponent } from '../../tree-filter/tree-filter.component';
import { Component, ViewChildren, QueryList, OnInit, ViewChild } from '@angular/core';
import _ from 'lodash';
import { CisMetricFilterComponent } from '../cis-metric-filter/cis-metric-filter.component';

@Component({
  selector: 'cis-refine-filters',
  templateUrl: './cis-refine-filters.component.html',
  styleUrls: ['./cis-refine-filters.component.scss']
})
export class CISRefineFiltersComponent implements OnInit {

  items=Constants.LOOKUP_FILTERS;
  lookupFilters: {} = {};
  dateFilters = Constants.DATE_FILTERS_OPTIONS;
  metricFilters = Constants.METRIC_FILTERS;
  allFilters = Constants.FILTERS;

  @Input()
  module: string;

  readonly Constants = Constants;

  @ViewChildren(TreeFilterComponent) lookupChildren: QueryList<TreeFilterComponent>;
  @ViewChildren(CisDateFilterComponent) dateChildren: QueryList<CisDateFilterComponent>;
  @ViewChildren(CisMetricFilterComponent) metricChildren: QueryList<CisMetricFilterComponent>;
  @ViewChild(CISTrackingFilterComponent) trackingFilter: CISTrackingFilterComponent;
  @ViewChild(CisGeoFilterComponent) geoFilter: CisGeoFilterComponent;
  @ViewChild(CISTextFilterComponent) textFilter: CISTextFilterComponent;

  index = 0;

  childrenMap = {};

  constructor(private appService: AppService,
    public filterService: CisFilterService) {
  }

  ngOnInit() {
    this.getModuleFilters();
    // this.filterService.initializeSelectedFilters();
  }

  ngOnDestroy()
  {
    this.filterService.initializeSelectedFilters();
  }

  ngAfterViewInit() {
    this.buildChildrenMap();
  }

  getAllFilters() {

    if(this.appService.isCompaniesSelected()) {
      this.allFilters = Constants.COMPANIES_FILTERS;
    }
    return this.allFilters;
  }

  getModuleFilters() {
    const filters = JSON.parse(localStorage.getItem('moduleFilters'));
    this.setRefineSearchFilters(filters);
  }

  setRefineSearchFilters(filters: {}) {
    Object.keys(filters).forEach((key: string) => {
      this.lookupFilters[key] = filters[key]['values']
    });
  }

  buildChildrenMap() {
    this.lookupChildren.forEach(children => {
      this.childrenMap[children.getKey()] = children;
    });
    this.dateChildren.forEach(children => {
      this.childrenMap[children.getKey()] = children;
    });
    this.metricChildren.forEach(children => {
      this.childrenMap[children.getKey()] = children;
    });

    if(!_.isEmpty(this.trackingFilter)) this.childrenMap[this.trackingFilter.getKey()] = this.trackingFilter;
    if(!_.isEmpty(this.geoFilter))this.childrenMap[this.geoFilter.getKey()] = this.geoFilter;
    if(!_.isEmpty(this.textFilter))this.childrenMap[this.textFilter.getKey()] = this.textFilter;
  }

  removeFilter(key) {
    this.childrenMap[key].onClear();
    this.filterService.transformFiltersToSearch(key, null);
  }

  getFilterChange(filter) : void {
    let filters = this.filterService.transformFiltersToSearch(filter.key, filter.data);
    if(filter.key != Constants.TEXT_FILTER) {
      if(_.isNil(filters) || _.isEmpty(filters))   this.filterService.transformFiltersToSearch(filter.key, null);
      else this.filterService.selectedFilters.fields[filter.key] = filters;
    }
  }

  isLookupFilter(filter: string) : boolean {
    return Constants.LOOKUP_FILTERS.indexOf(filter) != -1;
  }

  isMetricFilter(filter: string) : boolean {
    return Constants.METRIC_FILTER_DISPLAY === filter ;
  }

  isDateFilter(filter: string) : boolean {
    return Constants.DATE_FILTER_DISPLAY === filter ;
  }

  getLookupFilterValues(key) {
    if(Constants.LOOKUP_FILTERS_KEYMAP.hasOwnProperty(key)) key = Constants.LOOKUP_FILTERS_KEYMAP[key];
    return this.lookupFilters[key];
  }


  selectFilter(index) {
    this.index = index;
  }

  onSearchClick() {
    this.filterService.search();

  }
  onResetClick() {
    this.filterService.selectedFilters.fields = {};
    Object.keys(this.childrenMap).forEach(key => {
      this.childrenMap[key].onClear();
    })
  }
}
