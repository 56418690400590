import { Constants } from 'src/app/cbos/cis/consts';
import { CISTextModel } from './../models/cis-text.model';
import { CISMetricModel } from '../models/cis-metric.model';
import { Component, Input, OnInit } from '@angular/core';
import { BaseFilterComponent } from '../../base-filter/base-filter.component';

@Component({
  selector: 'cis-text-filter',
  templateUrl: './cis-text-filter.component.html',
  styleUrls: ['./cis-text-filter.component.scss']
})
export class CISTextFilterComponent extends BaseFilterComponent implements OnInit {


  @Input()
  textInput = new CISTextModel();
  textData: CISTextModel = new CISTextModel();


  searchTypes = Constants.TEXT_SEARCH_OPTIONS;

  ngOnInit(): void {
    this.textData = this.textInput;
  }

  onChangeText() {
    this.emitValues();
  }
  onChangeTextType() {
    this.emitValues();
  }

  emitValues() {
    super.emitValues(this.textData);
  }

  onClear() {
    this.textData = new CISTextModel();
  }


}
