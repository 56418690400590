import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Constants } from '../consts';
import { BaseFilterComponent } from './../../base-filter/base-filter.component';
import { CISDate } from './cis-date.model';


@Component({
  selector: 'cis-date-filter',
  templateUrl: './cis-date-filter.component.html',
  styleUrls: ['./cis-date-filter.component.scss']
})
export class CisDateFilterComponent extends BaseFilterComponent implements OnInit {

  constructor() {
    super();
  }

  @Input()
  dateData: CISDate = new CISDate();

  dateFilter = new CISDate();

  @Input()
  pastOptions: boolean = true;

  @Input()
  futureOptions: boolean = true;

  @Input()
  betweenOptions: boolean = true;

  dropdownOptions: Map<String, String> = new Map([]);

  ngOnInit(): void {
    this.initializeDropdownOptions();
    this.dateFilter = this.dateData;
  }


  initializeDropdownOptions() {
    if(this.pastOptions) this.dropdownOptions = new Map([...this.dropdownOptions, ...Constants.DATE_OPTIONS_PAST]);
    if(this.futureOptions) this.dropdownOptions = new Map([...this.dropdownOptions, ...Constants.DATE_OPTIONS_FUTURE]);
    if(this.betweenOptions) this.dropdownOptions.set(Constants.CUSTOM_DATE,'Between Dates');
  }

  isBetweenSelected(): boolean {
    return this.dateFilter.selected === Constants.CUSTOM_DATE;
  }


  asIsOrder(a, b) {return 1;}


  onChange(target): void {
    this.emitValues();
  }

  setDates(dateRange: CISDate):void {
    if(_.isNil(dateRange) || _.isEmpty(dateRange)) this.onClear();
    this.dateFilter.selected = dateRange.selected;
    this.dateFilter.from = dateRange.from;
    this.dateFilter.to = dateRange.to;
    this.emitValues();
  }


  shouldEmit() : boolean {
    if(_.isNil(this.dateFilter) || _.isEmpty(this.dateFilter)) return false;
    if(this.isBetweenSelected() && (_.isNil(this.dateFilter.from) || _.isEmpty(this.dateFilter.from)) && (_.isNil(this.dateFilter.to) || _.isEmpty(this.dateFilter.to))) return false;
    return true;
  }
  emitValues() {
      if(this.shouldEmit()) super.emitValues(this.dateFilter);
  }

  //  @override
  onClear() {
    this.dateFilter = new CISDate();
  }
  onApplyFilter() {
    this.dateFilter = new CISDate();
  }

}
