/* {OPEN:IMPORT} {"sha1":"34AD2F04B5A4E16D2A6C01B146826881EA9E58A5"} */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseAddCrmComponent } from './base-add-crm.component';
/* {CLOSE:IMPORT} {"sha1":"34AD2F04B5A4E16D2A6C01B146826881EA9E58A5"} */
import { NgForm } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../oneit/services/user.service';
import { UtilsService } from '../../oneit/services/utils.service';
import { SearchService } from '../../oneit/services/search.service';
import { AppService } from '../../app.service';
import { ConfirmationService } from 'primeng/api';
import { AddCrmService } from './add-crm.service';
import { DynamicDialogRef } from 'primeng';
/* {POSTCLOSE:IMPORT} {"sha1":"34AD2F04B5A4E16D2A6C01B146826881EA9E58A5"} */

/* {PREOPEN:CLASS} {"sha1":"89E1D9FD9EB901C26B7BEF585B9164B728607986"} */
@Component({
    selector: 'app-add-crm',
    templateUrl: './add-crm.component.html'
})
export class AddCrmComponent extends BaseAddCrmComponent implements OnInit, OnDestroy {
    /* {OPEN:CLASS} {} */

    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
        super.ngOnInit();
        /* {CLOSE:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
    }
    
    ngOnDestroy() {
        /* {OPEN:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
        super.ngOnDestroy();
        /* {CLOSE:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
    }

    /* {CLOSE:CLASS} {} */

    @ViewChild('form') form: NgForm;

    //@override
    constructor(
        public router : Router,
        public userService : UserService,
        public utilsService : UtilsService,
        public searchService : SearchService,
        public appService : AppService,
        public confirmationService: ConfirmationService,
        public addCrmService : AddCrmService,
        public dialogRef : DynamicDialogRef
    ){
        super(router, userService, utilsService, searchService, appService, confirmationService, addCrmService)
    }

    //@override
    executeScreenSpecificInitCode() {
        this.showLoader = true;
        this.subscriptions.push(
            this.appService.getCRMOptions().subscribe(data => {
                this.showLoader = false;
                this.cRMOptions = data
            },error => {
                this.showLoader = false;
                this.utilsService.handleError(error);
            })
        );
    }

    onAddClick(event, searchCrm) {
        this.utilsService.clearErrorMessages();

        if(!this.form.valid) {
           this.utilsService.showAllErrorMessages();
           return;
        }

        this.showLoader = true;

        this.subscriptions.push(
            this.appService.registerCRM(this.searchCrm.CRM).subscribe(data => {
                this.showLoader = false;
                this.dialogRef.close();
                window.open(data);
            },error => {
               this.showLoader = false;
               this.utilsService.handleError(error);
            })
        );
    }

    //@override
    resetCRMOptions() {
        return;
    }
}
/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */
