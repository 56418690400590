/* {OPEN:IMPORT} {"sha1":"532A13303EAAD47C9DBA93655574E815021BE0B8"} */
import { Component, DoCheck, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import { Message, MenuItem, ConfirmationService } from 'primeng/api';
import { AppService } from './../../app.service';
import { OptionsObservable, OptionsWrapper } from './../../oneit/models/options.model';
import { UserService } from './../../oneit/services/user.service';
import { UtilsService } from './../../oneit/services/utils.service';
import { SearchService } from './../../oneit/services/search.service';
import { RefineSearchService } from './refine-search.service';
import { SearchRefine } from './refine-search.model';
import { SearchProjectsService } from '../search-projects/search-projects.service';
/* {CLOSE:IMPORT} {"sha1":"532A13303EAAD47C9DBA93655574E815021BE0B8"} */

@Component({
    selector: 'app-base-refine-search',
    template: ''
})
/* {PREOPEN:CLASS} {"sha1":"C6A9108D0DA396774AD532564F42E9AF3E1CAD97"} */
export class BaseRefineSearchComponent implements DoCheck, OnInit, OnDestroy {
    /* {OPEN:CLASS} {} */
    
    /* {OPEN:COMMON_VARS} {"sha1":"672A211DEEA9A3559D121CBDC5978BBA47BD6B7D"} */
    @Input() parent : any;
    pageTitle : string;
    /* {CLOSE:COMMON_VARS} {"sha1":"672A211DEEA9A3559D121CBDC5978BBA47BD6B7D"} */

    /* {OPEN:VARS} {"sha1":"6A1EB577E6F8AB7C6137627B7DA61E5FFFA12E3F"} */
    searchRefine : SearchRefine = new SearchRefine();
    activeTabIndex : number = 0;
    enterawordorphrasetosearchallfieldOptions : any[] = [];
    stages : any[] = [];
    stagesTableSelectionMode : string;
    stagesTableCols : any[] = [];
    stagesTableTitle : string;
    categories : any[] = [];
    categoriesTableSelectionMode : string;
    categoriesTableCols : any[] = [];
    categoriesTableTitle : string;
    locations : any[] = [];
    locationsTableSelectionMode : string;
    locationsTableCols : any[] = [];
    locationsTableTitle : string;
    developmentTypes : any[] = [];
    developmentTypesTableSelectionMode : string;
    developmentTypesTableCols : any[] = [];
    developmentTypesTableTitle : string;
    contractTypes : any[] = [];
    contractTypesTableSelectionMode : string;
    contractTypesTableCols : any[] = [];
    contractTypesTableTitle : string;
    roles : any[] = [];
    rolesTableSelectionMode : string;
    rolesTableCols : any[] = [];
    rolesTableTitle : string;
    materials : any[] = [];
    materialsTableSelectionMode : string;
    materialsTableCols : any[] = [];
    materialsTableTitle : string;
    fundings : any[] = [];
    fundingsTableSelectionMode : string;
    fundingsTableCols : any[] = [];
    fundingsTableTitle : string;
    dummyOptions : any[] = [];
    isDummyVolatile : boolean = false;
    /* {CLOSE:VARS} {"sha1":"6A1EB577E6F8AB7C6137627B7DA61E5FFFA12E3F"} */
    
    showResults : boolean = false;
    showLoader : boolean = false;
    subscriptions : Array<Subscription> = [];

    constructor(
        /* {OPEN:constructor} {"sha1":"89510E96F43B14310DD1075E96248909C5E7EBC9"} */
        public router : Router,
        public userService : UserService,
        public utilsService : UtilsService,
        public searchService : SearchService,
        public appService : AppService,
        public confirmationService: ConfirmationService,
        public refineSearchService : RefineSearchService,
        /* {PRECLOSE:constructor} {"sha1":"89510E96F43B14310DD1075E96248909C5E7EBC9"} */
        public searchProjectsService: SearchProjectsService
        /* {CLOSE:constructor} {"sha1":"89510E96F43B14310DD1075E96248909C5E7EBC9"} */
    ){
        
    }

    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"5FBDAAB044BC4F999356808F755A7334E11EE273"} */
        this.resetInitVariables();
        this.calculateEverything();
        this.executeScreenSpecificInitCode();
        /* {CLOSE:ngOnInit} {"sha1":"5FBDAAB044BC4F999356808F755A7334E11EE273"} */
    }

    /* {PREOPEN:executeScreenSpecificInitCode} {"sha1":"DDD081544316B33777AB7B7FFD796FB9E663E681"} */
    executeScreenSpecificInitCode() {
        /* {OPEN:executeScreenSpecificInitCode} {"sha1":"54DB41AD32937D86C7318EB4BE666A1AD7B6B618"} */
        if(!this.parent) {
        }
        /* {CLOSE:executeScreenSpecificInitCode} {"sha1":"54DB41AD32937D86C7318EB4BE666A1AD7B6B618"} */
    }
    /* {POSTCLOSE:executeScreenSpecificInitCode} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onCloseClick} {"sha1":"B1B4FA4890A23A81AD4D750BB0F4847EA0AE323D"} */
    onCloseClick(event, searchRefine) {
        /* {OPEN:onCloseClick} {"sha1":"999E5462196194ACACE528B609E0E94624B9574A"} */
        this.router.navigate(['/search-projects']);
        /* {CLOSE:onCloseClick} {"sha1":"999E5462196194ACACE528B609E0E94624B9574A"} */
    }
    /* {POSTCLOSE:onCloseClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onClearAllClick} {"sha1":"CAF9A6F0D15D8FCEBCA6EBC9D534580461783FA8"} */
    onClearAllClick(event, searchRefine) {
        /* {OPEN:onClearAllClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onClearAllClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onClearAllClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onTabChange} {"sha1":"0967F77AC09F467AA1FF3C20B8CCC2C0A2C76C5A"} */
    onTabChange(event, searchRefine) : void {
        /* {OPEN:onTabChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:onTabChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:onTabChange} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:autocompleteEnterawordorphrasetosearchallfieldOptions} {"sha1":"E310369DC7DE52C64E370721EB0C2D22FE4C2ECC"} */
    autocompleteEnterawordorphrasetosearchallfieldOptions(event, searchRefine) : void {
        /* {OPEN:autocompleteEnterawordorphrasetosearchallfieldOptions} {"sha1":"10AAAA745C6145287CC7F5E84699971A2A2FD2D6"} */
        this.subscriptions.push(this.getAutocompleteEnterawordorphrasetosearchallfieldOptionsObservable(event, searchRefine)
            .subscribe (
                data => {
                    this.enterawordorphrasetosearchallfieldOptions = data;
                },
                error => {
                    this.utilsService.handleError(error);
                }
            )
        );
        /* {CLOSE:autocompleteEnterawordorphrasetosearchallfieldOptions} {"sha1":"10AAAA745C6145287CC7F5E84699971A2A2FD2D6"} */
    }
    /* {POSTCLOSE:autocompleteEnterawordorphrasetosearchallfieldOptions} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onEnterawordorphrasetosearchallfieldSelect} {"sha1":"7A28869CEE909D23E872586393C26CCA0F6E3F63"} */
    onEnterawordorphrasetosearchallfieldSelect(event, searchRefine) {
        /* {OPEN:onEnterawordorphrasetosearchallfieldSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:onEnterawordorphrasetosearchallfieldSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:onEnterawordorphrasetosearchallfieldSelect} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onEnterawordorphrasetosearchallfieldClear} {"sha1":"D82A2EA416A9AC927B3D8239BEABE54DF4A3C2CF"} */
    onEnterawordorphrasetosearchallfieldClear(event, searchRefine) : void {
        /* {OPEN:onEnterawordorphrasetosearchallfieldClear} {"sha1":"8396FAB9E4CCD64A08E4FDF6207C52BA0EA759B5"} */
        searchRefine.Enterawordorphrasetosearchallfields = null;
        /* {CLOSE:onEnterawordorphrasetosearchallfieldClear} {"sha1":"8396FAB9E4CCD64A08E4FDF6207C52BA0EA759B5"} */
    }
    /* {POSTCLOSE:onEnterawordorphrasetosearchallfieldClear} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getAutocompleteEnterawordorphrasetosearchallfieldOptionsObservable} {"sha1":"B3CA690A45C358CA82D24BE8CC44ACA1A4C5F3B4"} */
    getAutocompleteEnterawordorphrasetosearchallfieldOptionsObservable(event, searchRefine) : Observable<any> {
        /* {OPEN:getAutocompleteEnterawordorphrasetosearchallfieldOptionsObservable} {"sha1":"7BE48C435D2C5F4866D7A213D673246F81130D7F"} */
        if(this.parent) {
            return this.parent.getAutocompleteEnterawordorphrasetosearchallfieldOptionsObservable(event, searchRefine);
        }
        throw new Error('Not Implemented');
        /* {CLOSE:getAutocompleteEnterawordorphrasetosearchallfieldOptionsObservable} {"sha1":"7BE48C435D2C5F4866D7A213D673246F81130D7F"} */
    }
    /* {POSTCLOSE:getAutocompleteEnterawordorphrasetosearchallfieldOptionsObservable} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getStagesTableCols} {"sha1":"F8CBC6050D28C3AD347233D03AB8DB57AEDBF0F3"} */
    getStagesTableCols() : any[] {
        /* {OPEN:getStagesTableCols} {"sha1":"8D98D3E025C41EF754D293E4746BF9D481F004BD"} */
        return [
            { field: 'Include', header: 'Include', width: '10px', noSort: true, noFilter: true },
            { field: 'Exclude', header: 'Exclude', width: '10px', noSort: true, noFilter: true }
        ];
        /* {CLOSE:getStagesTableCols} {"sha1":"8D98D3E025C41EF754D293E4746BF9D481F004BD"} */
    }
    /* {POSTCLOSE:getStagesTableCols} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getStagesTableTitle} {"sha1":"B948897BD08163F223C1E1D4CF8C355A674EACD1"} */
    getStagesTableTitle() : string {
        /* {OPEN:getStagesTableTitle} {"sha1":"CD69531BBB3D6093542AF8EA30A8AB05098F920F"} */
        return "Stages";
        /* {CLOSE:getStagesTableTitle} {"sha1":"CD69531BBB3D6093542AF8EA30A8AB05098F920F"} */
    }
    /* {POSTCLOSE:getStagesTableTitle} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getStages} {"sha1":"F9156C539569B0BB4FE3089E4ED707DAFBED7F05"} */
    getStages(saveSearch: boolean) {
        /* {OPEN:getStages} {"sha1":"D0862D0E27F4C72404CF5A39AE0BB01A993176BA"} */
        this.showResults = true;
        this.showLoader = true;
        let assocs = [];
        
        this.subscriptions.push(this.getStagesObservable(saveSearch)
            .subscribe(
                data => {
                    this.stages = data;
                    this.postStagesTableChange();
                    this.showLoader = false;
                },
                error => {
                    this.showLoader = false;
                    this.utilsService.handleError(error);
                }
            )
        );
        /* {CLOSE:getStages} {"sha1":"D0862D0E27F4C72404CF5A39AE0BB01A993176BA"} */
    }
    /* {POSTCLOSE:getStages} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:postStagesTableChange} {"sha1":"CFAB468E67893BF7E30ED80205507B4C1848C125"} */
    postStagesTableChange() {
        /* {OPEN:postStagesTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:postStagesTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:postStagesTableChange} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getStagesObservable} {"sha1":"8E7FB553382EB6EA8372E0510FA5CC54C8484CE9"} */
    getStagesObservable(saveSearch): Observable<any> {
        /* {OPEN:getStagesObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
        return of({});
        /* {CLOSE:getStagesObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
    }
    /* {POSTCLOSE:getStagesObservable} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onStagesTableRowSelect} {"sha1":"919828D991258E2A04693597D00A37041B2B17CC"} */
    onStagesTableRowSelect(data) {
        /* {OPEN:onStagesTableRowSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:onStagesTableRowSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:onStagesTableRowSelect} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getStagesTableSelectionMode} {"sha1":"48176AB78EA0AB48F902A563A23D7A252DD82946"} */
    getStagesTableSelectionMode() : string {
        /* {OPEN:getStagesTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
        return null;
        /* {CLOSE:getStagesTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
    }
    /* {POSTCLOSE:getStagesTableSelectionMode} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getCategoriesTableCols} {"sha1":"F41665EF3343B554A3EFB960D7B86D4546452D5D"} */
    getCategoriesTableCols() : any[] {
        /* {OPEN:getCategoriesTableCols} {"sha1":"8D98D3E025C41EF754D293E4746BF9D481F004BD"} */
        return [
            { field: 'Include', header: 'Include', width: '10px', noSort: true, noFilter: true },
            { field: 'Exclude', header: 'Exclude', width: '10px', noSort: true, noFilter: true }
        ];
        /* {CLOSE:getCategoriesTableCols} {"sha1":"8D98D3E025C41EF754D293E4746BF9D481F004BD"} */
    }
    /* {POSTCLOSE:getCategoriesTableCols} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getCategoriesTableTitle} {"sha1":"6B9D4D87B829E7EE49AAE50E35D21AE484D6BC14"} */
    getCategoriesTableTitle() : string {
        /* {OPEN:getCategoriesTableTitle} {"sha1":"8E170D80DDA4AD3259D0F0EBC07C8B382AB403F8"} */
        return "Categories";
        /* {CLOSE:getCategoriesTableTitle} {"sha1":"8E170D80DDA4AD3259D0F0EBC07C8B382AB403F8"} */
    }
    /* {POSTCLOSE:getCategoriesTableTitle} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getCategories} {"sha1":"31B337015D0E75E85F7BD79C1EDA37C395598198"} */
    getCategories(saveSearch: boolean) {
        /* {OPEN:getCategories} {"sha1":"B59B76664C7D209443F663D50DAE781E5F9BBD27"} */
        this.showResults = true;
        this.showLoader = true;
        let assocs = [];
        
        this.subscriptions.push(this.getCategoriesObservable(saveSearch)
            .subscribe(
                data => {
                    this.categories = data;
                    this.postCategoriesTableChange();
                    this.showLoader = false;
                },
                error => {
                    this.showLoader = false;
                    this.utilsService.handleError(error);
                }
            )
        );
        /* {CLOSE:getCategories} {"sha1":"B59B76664C7D209443F663D50DAE781E5F9BBD27"} */
    }
    /* {POSTCLOSE:getCategories} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:postCategoriesTableChange} {"sha1":"DC427D924CD92AA551E7A950E58E13A9AEDA0E5C"} */
    postCategoriesTableChange() {
        /* {OPEN:postCategoriesTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:postCategoriesTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:postCategoriesTableChange} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getCategoriesObservable} {"sha1":"C1C360FE60F0DDC7996B1F536DC84EA355DD08DB"} */
    getCategoriesObservable(saveSearch): Observable<any> {
        /* {OPEN:getCategoriesObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
        return of({});
        /* {CLOSE:getCategoriesObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
    }
    /* {POSTCLOSE:getCategoriesObservable} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onCategoriesTableRowSelect} {"sha1":"6CE6DEF270E5AD36CBAD374EC41993FF023523E3"} */
    onCategoriesTableRowSelect(data) {
        /* {OPEN:onCategoriesTableRowSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:onCategoriesTableRowSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:onCategoriesTableRowSelect} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getCategoriesTableSelectionMode} {"sha1":"68CEE659D54951991DEAD357E605BA9FC5FA07B0"} */
    getCategoriesTableSelectionMode() : string {
        /* {OPEN:getCategoriesTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
        return null;
        /* {CLOSE:getCategoriesTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
    }
    /* {POSTCLOSE:getCategoriesTableSelectionMode} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getLocationsTableCols} {"sha1":"8836482B4643F4944444BD238B343C57D6F111ED"} */
    getLocationsTableCols() : any[] {
        /* {OPEN:getLocationsTableCols} {"sha1":"8D98D3E025C41EF754D293E4746BF9D481F004BD"} */
        return [
            { field: 'Include', header: 'Include', width: '10px', noSort: true, noFilter: true },
            { field: 'Exclude', header: 'Exclude', width: '10px', noSort: true, noFilter: true }
        ];
        /* {CLOSE:getLocationsTableCols} {"sha1":"8D98D3E025C41EF754D293E4746BF9D481F004BD"} */
    }
    /* {POSTCLOSE:getLocationsTableCols} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getLocationsTableTitle} {"sha1":"2E921C9476406E6AB3ADA5C6149CCD74BCA4CD77"} */
    getLocationsTableTitle() : string {
        /* {OPEN:getLocationsTableTitle} {"sha1":"E875D353E7A2FFCF2817FA3FC4F9A7FA2167BC04"} */
        return "Location";
        /* {CLOSE:getLocationsTableTitle} {"sha1":"E875D353E7A2FFCF2817FA3FC4F9A7FA2167BC04"} */
    }
    /* {POSTCLOSE:getLocationsTableTitle} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getLocations} {"sha1":"FCE71D4D4EC2FB3774FF352AB5231A4560F71A84"} */
    getLocations(saveSearch: boolean) {
        /* {OPEN:getLocations} {"sha1":"6D174254FFC0D018DC89AF2FA7515E1702479634"} */
        this.showResults = true;
        this.showLoader = true;
        let assocs = [];
        
        this.subscriptions.push(this.getLocationsObservable(saveSearch)
            .subscribe(
                data => {
                    this.locations = data;
                    this.postLocationsTableChange();
                    this.showLoader = false;
                },
                error => {
                    this.showLoader = false;
                    this.utilsService.handleError(error);
                }
            )
        );
        /* {CLOSE:getLocations} {"sha1":"6D174254FFC0D018DC89AF2FA7515E1702479634"} */
    }
    /* {POSTCLOSE:getLocations} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:postLocationsTableChange} {"sha1":"2200BA12EFFD1542F3E9484F205827F019B86115"} */
    postLocationsTableChange() {
        /* {OPEN:postLocationsTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:postLocationsTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:postLocationsTableChange} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getLocationsObservable} {"sha1":"A7EAB9AD955DACD2A44CB765A6356AEFA29A7A20"} */
    getLocationsObservable(saveSearch): Observable<any> {
        /* {OPEN:getLocationsObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
        return of({});
        /* {CLOSE:getLocationsObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
    }
    /* {POSTCLOSE:getLocationsObservable} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onLocationsTableRowSelect} {"sha1":"EB360A8899B082047D1C6A3A5A0DDEC74CCB5573"} */
    onLocationsTableRowSelect(data) {
        /* {OPEN:onLocationsTableRowSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:onLocationsTableRowSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:onLocationsTableRowSelect} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getLocationsTableSelectionMode} {"sha1":"6292D45BD44020C794B21905FF4AA9D1EC58C838"} */
    getLocationsTableSelectionMode() : string {
        /* {OPEN:getLocationsTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
        return null;
        /* {CLOSE:getLocationsTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
    }
    /* {POSTCLOSE:getLocationsTableSelectionMode} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getDevelopmentTypesTableCols} {"sha1":"006576C0876AD45240CF97519AA439C433FA8CAB"} */
    getDevelopmentTypesTableCols() : any[] {
        /* {OPEN:getDevelopmentTypesTableCols} {"sha1":"8D98D3E025C41EF754D293E4746BF9D481F004BD"} */
        return [
            { field: 'Include', header: 'Include', width: '10px', noSort: true, noFilter: true },
            { field: 'Exclude', header: 'Exclude', width: '10px', noSort: true, noFilter: true }
        ];
        /* {CLOSE:getDevelopmentTypesTableCols} {"sha1":"8D98D3E025C41EF754D293E4746BF9D481F004BD"} */
    }
    /* {POSTCLOSE:getDevelopmentTypesTableCols} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getDevelopmentTypesTableTitle} {"sha1":"02E9F76A069976CC71B796D226C90545711CE8BC"} */
    getDevelopmentTypesTableTitle() : string {
        /* {OPEN:getDevelopmentTypesTableTitle} {"sha1":"3304BC683398BAD39354AA244CF257D592A8BE3A"} */
        return "Development Type";
        /* {CLOSE:getDevelopmentTypesTableTitle} {"sha1":"3304BC683398BAD39354AA244CF257D592A8BE3A"} */
    }
    /* {POSTCLOSE:getDevelopmentTypesTableTitle} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getDevelopmentTypes} {"sha1":"A977D9CF3D11B8CF318A7CAAAB9A6427E89B666C"} */
    getDevelopmentTypes(saveSearch: boolean) {
        /* {OPEN:getDevelopmentTypes} {"sha1":"AB51FC22387F397915B42DFD4E4173BC6040CECD"} */
        this.showResults = true;
        this.showLoader = true;
        let assocs = [];
        
        this.subscriptions.push(this.getDevelopmentTypesObservable(saveSearch)
            .subscribe(
                data => {
                    this.developmentTypes = data;
                    this.postDevelopmentTypesTableChange();
                    this.showLoader = false;
                },
                error => {
                    this.showLoader = false;
                    this.utilsService.handleError(error);
                }
            )
        );
        /* {CLOSE:getDevelopmentTypes} {"sha1":"AB51FC22387F397915B42DFD4E4173BC6040CECD"} */
    }
    /* {POSTCLOSE:getDevelopmentTypes} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:postDevelopmentTypesTableChange} {"sha1":"76046ABAF7FEF335D7506203F00DB759D2C54B7F"} */
    postDevelopmentTypesTableChange() {
        /* {OPEN:postDevelopmentTypesTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:postDevelopmentTypesTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:postDevelopmentTypesTableChange} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getDevelopmentTypesObservable} {"sha1":"B03D2D6DA78F043860DEF7E09A5951F7C658F68E"} */
    getDevelopmentTypesObservable(saveSearch): Observable<any> {
        /* {OPEN:getDevelopmentTypesObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
        return of({});
        /* {CLOSE:getDevelopmentTypesObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
    }
    /* {POSTCLOSE:getDevelopmentTypesObservable} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onDevelopmentTypesTableRowSelect} {"sha1":"2539E5219EC769E8D0B132BBA32CD0869982E1D6"} */
    onDevelopmentTypesTableRowSelect(data) {
        /* {OPEN:onDevelopmentTypesTableRowSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:onDevelopmentTypesTableRowSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:onDevelopmentTypesTableRowSelect} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getDevelopmentTypesTableSelectionMode} {"sha1":"9C4C7E463533101D8D29685236C3876EB0AAAE98"} */
    getDevelopmentTypesTableSelectionMode() : string {
        /* {OPEN:getDevelopmentTypesTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
        return null;
        /* {CLOSE:getDevelopmentTypesTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
    }
    /* {POSTCLOSE:getDevelopmentTypesTableSelectionMode} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getContractTypesTableCols} {"sha1":"531331F2E1B2347241C417E574061DA679CB687F"} */
    getContractTypesTableCols() : any[] {
        /* {OPEN:getContractTypesTableCols} {"sha1":"8D98D3E025C41EF754D293E4746BF9D481F004BD"} */
        return [
            { field: 'Include', header: 'Include', width: '10px', noSort: true, noFilter: true },
            { field: 'Exclude', header: 'Exclude', width: '10px', noSort: true, noFilter: true }
        ];
        /* {CLOSE:getContractTypesTableCols} {"sha1":"8D98D3E025C41EF754D293E4746BF9D481F004BD"} */
    }
    /* {POSTCLOSE:getContractTypesTableCols} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getContractTypesTableTitle} {"sha1":"DDDE6D8CC2497DB6AB23555330A047F53456C117"} */
    getContractTypesTableTitle() : string {
        /* {OPEN:getContractTypesTableTitle} {"sha1":"6C8D05D29FC54294677722C664125788D387B9F7"} */
        return "Contract Type";
        /* {CLOSE:getContractTypesTableTitle} {"sha1":"6C8D05D29FC54294677722C664125788D387B9F7"} */
    }
    /* {POSTCLOSE:getContractTypesTableTitle} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getContractTypes} {"sha1":"B392E83649DE1BEE3A0948D39CD4699764154647"} */
    getContractTypes(saveSearch: boolean) {
        /* {OPEN:getContractTypes} {"sha1":"2E41DB518FE9E8BE80163900945D41F1F7388058"} */
        this.showResults = true;
        this.showLoader = true;
        let assocs = [];
        
        this.subscriptions.push(this.getContractTypesObservable(saveSearch)
            .subscribe(
                data => {
                    this.contractTypes = data;
                    this.postContractTypesTableChange();
                    this.showLoader = false;
                },
                error => {
                    this.showLoader = false;
                    this.utilsService.handleError(error);
                }
            )
        );
        /* {CLOSE:getContractTypes} {"sha1":"2E41DB518FE9E8BE80163900945D41F1F7388058"} */
    }
    /* {POSTCLOSE:getContractTypes} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:postContractTypesTableChange} {"sha1":"921CDBF88349489D1AAFD3D798F4F5B4F0C3DC62"} */
    postContractTypesTableChange() {
        /* {OPEN:postContractTypesTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:postContractTypesTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:postContractTypesTableChange} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getContractTypesObservable} {"sha1":"34F6FBE6A2A5B3A7D7C92EC3A5E769D2D957FC10"} */
    getContractTypesObservable(saveSearch): Observable<any> {
        /* {OPEN:getContractTypesObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
        return of({});
        /* {CLOSE:getContractTypesObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
    }
    /* {POSTCLOSE:getContractTypesObservable} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onContractTypesTableRowSelect} {"sha1":"D62329575F8C4AF06160E427A2033E840C959A34"} */
    onContractTypesTableRowSelect(data) {
        /* {OPEN:onContractTypesTableRowSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:onContractTypesTableRowSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:onContractTypesTableRowSelect} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getContractTypesTableSelectionMode} {"sha1":"15BBC9972163B4A97B5A107E57BA7D2DDCE63A3E"} */
    getContractTypesTableSelectionMode() : string {
        /* {OPEN:getContractTypesTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
        return null;
        /* {CLOSE:getContractTypesTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
    }
    /* {POSTCLOSE:getContractTypesTableSelectionMode} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRolesTableCols} {"sha1":"7AB572CFB446ED34B4C6A69E55F2476E3AAF422A"} */
    getRolesTableCols() : any[] {
        /* {OPEN:getRolesTableCols} {"sha1":"8D98D3E025C41EF754D293E4746BF9D481F004BD"} */
        return [
            { field: 'Include', header: 'Include', width: '10px', noSort: true, noFilter: true },
            { field: 'Exclude', header: 'Exclude', width: '10px', noSort: true, noFilter: true }
        ];
        /* {CLOSE:getRolesTableCols} {"sha1":"8D98D3E025C41EF754D293E4746BF9D481F004BD"} */
    }
    /* {POSTCLOSE:getRolesTableCols} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRolesTableTitle} {"sha1":"8420353A82D12A83ED4109D7226E019C012BE5ED"} */
    getRolesTableTitle() : string {
        /* {OPEN:getRolesTableTitle} {"sha1":"C59C0295BAB8F53A6EC9FB4561F7FFF674FC920B"} */
        return "Roles";
        /* {CLOSE:getRolesTableTitle} {"sha1":"C59C0295BAB8F53A6EC9FB4561F7FFF674FC920B"} */
    }
    /* {POSTCLOSE:getRolesTableTitle} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRoles} {"sha1":"916EE16C237921BB4226EEAFF49A6EF60DC42077"} */
    getRoles(saveSearch: boolean) {
        /* {OPEN:getRoles} {"sha1":"0AF9BEA461D32B7F6820EDACDEFD79B417429DBE"} */
        this.showResults = true;
        this.showLoader = true;
        let assocs = [];
        
        this.subscriptions.push(this.getRolesObservable(saveSearch)
            .subscribe(
                data => {
                    this.roles = data;
                    this.postRolesTableChange();
                    this.showLoader = false;
                },
                error => {
                    this.showLoader = false;
                    this.utilsService.handleError(error);
                }
            )
        );
        /* {CLOSE:getRoles} {"sha1":"0AF9BEA461D32B7F6820EDACDEFD79B417429DBE"} */
    }
    /* {POSTCLOSE:getRoles} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:postRolesTableChange} {"sha1":"A0091AEDE6049EDE55A2D6D4928B55F7CC4C00C6"} */
    postRolesTableChange() {
        /* {OPEN:postRolesTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:postRolesTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:postRolesTableChange} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRolesObservable} {"sha1":"FEF1C894552009E3E7BA1A44CC799EFE9B3666B7"} */
    getRolesObservable(saveSearch): Observable<any> {
        /* {OPEN:getRolesObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
        return of({});
        /* {CLOSE:getRolesObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
    }
    /* {POSTCLOSE:getRolesObservable} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onRolesTableRowSelect} {"sha1":"31F763447C56BE842B67D1F96AB8354C45F75545"} */
    onRolesTableRowSelect(data) {
        /* {OPEN:onRolesTableRowSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:onRolesTableRowSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:onRolesTableRowSelect} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRolesTableSelectionMode} {"sha1":"F2C708D4032B583069DCA398783CA1AE40A625B9"} */
    getRolesTableSelectionMode() : string {
        /* {OPEN:getRolesTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
        return null;
        /* {CLOSE:getRolesTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
    }
    /* {POSTCLOSE:getRolesTableSelectionMode} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getMaterialsTableCols} {"sha1":"0C90EC3E0A229977652785DB6F00EFB75554E60C"} */
    getMaterialsTableCols() : any[] {
        /* {OPEN:getMaterialsTableCols} {"sha1":"8D98D3E025C41EF754D293E4746BF9D481F004BD"} */
        return [
            { field: 'Include', header: 'Include', width: '10px', noSort: true, noFilter: true },
            { field: 'Exclude', header: 'Exclude', width: '10px', noSort: true, noFilter: true }
        ];
        /* {CLOSE:getMaterialsTableCols} {"sha1":"8D98D3E025C41EF754D293E4746BF9D481F004BD"} */
    }
    /* {POSTCLOSE:getMaterialsTableCols} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getMaterialsTableTitle} {"sha1":"757BC8CC74F8FA64BC9172B3DB8F8BF9C4C6F6E8"} */
    getMaterialsTableTitle() : string {
        /* {OPEN:getMaterialsTableTitle} {"sha1":"967F6FAEB74413C7677C793DC4E4366D6E767783"} */
        return "Materials";
        /* {CLOSE:getMaterialsTableTitle} {"sha1":"967F6FAEB74413C7677C793DC4E4366D6E767783"} */
    }
    /* {POSTCLOSE:getMaterialsTableTitle} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getMaterials} {"sha1":"76B0B59E1DF7CBAC1F87256417C285DAB9762D26"} */
    getMaterials(saveSearch: boolean) {
        /* {OPEN:getMaterials} {"sha1":"E390BEA569C1861F45C71055990B9FBD40EC5308"} */
        this.showResults = true;
        this.showLoader = true;
        let assocs = [];
        
        this.subscriptions.push(this.getMaterialsObservable(saveSearch)
            .subscribe(
                data => {
                    this.materials = data;
                    this.postMaterialsTableChange();
                    this.showLoader = false;
                },
                error => {
                    this.showLoader = false;
                    this.utilsService.handleError(error);
                }
            )
        );
        /* {CLOSE:getMaterials} {"sha1":"E390BEA569C1861F45C71055990B9FBD40EC5308"} */
    }
    /* {POSTCLOSE:getMaterials} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:postMaterialsTableChange} {"sha1":"04A5F8344851B341F6F9F4E33390A8D4F1D63005"} */
    postMaterialsTableChange() {
        /* {OPEN:postMaterialsTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:postMaterialsTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:postMaterialsTableChange} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getMaterialsObservable} {"sha1":"600083B1893F6213F9E2F78C0EAF840A997822E3"} */
    getMaterialsObservable(saveSearch): Observable<any> {
        /* {OPEN:getMaterialsObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
        return of({});
        /* {CLOSE:getMaterialsObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
    }
    /* {POSTCLOSE:getMaterialsObservable} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onMaterialsTableRowSelect} {"sha1":"DD8511FC602248927E8B1B5E207CDD7623B4E6D0"} */
    onMaterialsTableRowSelect(data) {
        /* {OPEN:onMaterialsTableRowSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:onMaterialsTableRowSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:onMaterialsTableRowSelect} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getMaterialsTableSelectionMode} {"sha1":"B69420F9DD42E6051E64F50B482C6763443953AB"} */
    getMaterialsTableSelectionMode() : string {
        /* {OPEN:getMaterialsTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
        return null;
        /* {CLOSE:getMaterialsTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
    }
    /* {POSTCLOSE:getMaterialsTableSelectionMode} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getFundingsTableCols} {"sha1":"6491703914756B4A333A717CF9E2AD18D620F63B"} */
    getFundingsTableCols() : any[] {
        /* {OPEN:getFundingsTableCols} {"sha1":"8D98D3E025C41EF754D293E4746BF9D481F004BD"} */
        return [
            { field: 'Include', header: 'Include', width: '10px', noSort: true, noFilter: true },
            { field: 'Exclude', header: 'Exclude', width: '10px', noSort: true, noFilter: true }
        ];
        /* {CLOSE:getFundingsTableCols} {"sha1":"8D98D3E025C41EF754D293E4746BF9D481F004BD"} */
    }
    /* {POSTCLOSE:getFundingsTableCols} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getFundingsTableTitle} {"sha1":"84458E7EFC758D8AF33802F73B492D2A746B54A0"} */
    getFundingsTableTitle() : string {
        /* {OPEN:getFundingsTableTitle} {"sha1":"091D2F1DD2C6AA8E09A952558E8623826F37C5C0"} */
        return "Funding";
        /* {CLOSE:getFundingsTableTitle} {"sha1":"091D2F1DD2C6AA8E09A952558E8623826F37C5C0"} */
    }
    /* {POSTCLOSE:getFundingsTableTitle} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getFundings} {"sha1":"34EE3A81F88E2D7DC53743077742CAF72B16C4B5"} */
    getFundings(saveSearch: boolean) {
        /* {OPEN:getFundings} {"sha1":"A882846D53D396A1F3382B95F4CB23881082F101"} */
        this.showResults = true;
        this.showLoader = true;
        let assocs = [];
        
        this.subscriptions.push(this.getFundingsObservable(saveSearch)
            .subscribe(
                data => {
                    this.fundings = data;
                    this.postFundingsTableChange();
                    this.showLoader = false;
                },
                error => {
                    this.showLoader = false;
                    this.utilsService.handleError(error);
                }
            )
        );
        /* {CLOSE:getFundings} {"sha1":"A882846D53D396A1F3382B95F4CB23881082F101"} */
    }
    /* {POSTCLOSE:getFundings} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:postFundingsTableChange} {"sha1":"ECEA121FB681215FE828B13438DF715B5934C37C"} */
    postFundingsTableChange() {
        /* {OPEN:postFundingsTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:postFundingsTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:postFundingsTableChange} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getFundingsObservable} {"sha1":"B72898588A6D4FF2C14E80F1D52B883F09B236C7"} */
    getFundingsObservable(saveSearch): Observable<any> {
        /* {OPEN:getFundingsObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
        return of({});
        /* {CLOSE:getFundingsObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
    }
    /* {POSTCLOSE:getFundingsObservable} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onFundingsTableRowSelect} {"sha1":"CE8083914E9FE6A29F4044DD440EF49F63B8F575"} */
    onFundingsTableRowSelect(data) {
        /* {OPEN:onFundingsTableRowSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:onFundingsTableRowSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:onFundingsTableRowSelect} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getFundingsTableSelectionMode} {"sha1":"8A5B5B5BF93138F53A7BA9C2ACD077B694D881CD"} */
    getFundingsTableSelectionMode() : string {
        /* {OPEN:getFundingsTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
        return null;
        /* {CLOSE:getFundingsTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
    }
    /* {POSTCLOSE:getFundingsTableSelectionMode} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getDummyOptions} {"sha1":"A06B54553B45A6A44310CF53D9E12D0BEA774740"} */
    getDummyOptions(): OptionsWrapper {
        /* {OPEN:getDummyOptions} {"sha1":"8292172453929307C72E17285E9F39255E56E748"} */
        return new OptionsObservable(false, this.appService.getSearchByDistancesObservable());
        /* {CLOSE:getDummyOptions} {"sha1":"8292172453929307C72E17285E9F39255E56E748"} */
    }
    /* {POSTCLOSE:getDummyOptions} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:resetDummyOptions} {"sha1":"5DAEE832657FEE856E5BFB4B0ECAC5AD6D4272F8"} */
    resetDummyOptions() {
        /* {OPEN:resetDummyOptions} {"sha1":"78E293EBA8C3361ED23C141F12C9CAFEBD611EB0"} */
        if(this.parent) {
            this.parent.resetDummyOptions();
        } else {
            let wrapper : OptionsWrapper = this.getDummyOptions();

            if (wrapper) {
                this.isDummyVolatile = wrapper.isVolatile;

                this.subscriptions.push(wrapper.getObservable()
                    .subscribe (
                        data => {
                            this.dummyOptions = data;
                        },
                        error => {
                            this.utilsService.handleError(error);
                        }
                    )
                );
            }
        }
        /* {CLOSE:resetDummyOptions} {"sha1":"78E293EBA8C3361ED23C141F12C9CAFEBD611EB0"} */
    }
    /* {POSTCLOSE:resetDummyOptions} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onDummyChange} {"sha1":"822E798EFCDD34D36DD485A10290AA49964BB95C"} */
    onDummyChange(event, searchRefine) {
        /* {OPEN:onDummyChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:onDummyChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:onDummyChange} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRouterLinkForStagesIncludePlanningStageExcludeContractStage} {"sha1":"5151414476EB6C25D6C48E014A6A1002817DB661"} */
    getRouterLinkForStagesIncludePlanningStageExcludeContractStage(searchRefine) {
        /* {OPEN:getRouterLinkForStagesIncludePlanningStageExcludeContractStage} {"sha1":"82901B29072C4A509BBB256D8D5BA631ED4385EA"} */
        return ['/refine-search'];
        /* {CLOSE:getRouterLinkForStagesIncludePlanningStageExcludeContractStage} {"sha1":"82901B29072C4A509BBB256D8D5BA631ED4385EA"} */
    }
    /* {POSTCLOSE:getRouterLinkForStagesIncludePlanningStageExcludeContractStage} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRouterLinkForX} {"sha1":"374D2976B6EC81520B48B53A043DD1C2CC42834D"} */
    getRouterLinkForX(searchRefine) {
        /* {OPEN:getRouterLinkForX} {"sha1":"82901B29072C4A509BBB256D8D5BA631ED4385EA"} */
        return ['/refine-search'];
        /* {CLOSE:getRouterLinkForX} {"sha1":"82901B29072C4A509BBB256D8D5BA631ED4385EA"} */
    }
    /* {POSTCLOSE:getRouterLinkForX} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRouterLinkForTotalFloorAreaFrom120To130} {"sha1":"DE86035FF2881D1F02C417C0F3B83681D2090034"} */
    getRouterLinkForTotalFloorAreaFrom120To130(searchRefine) {
        /* {OPEN:getRouterLinkForTotalFloorAreaFrom120To130} {"sha1":"82901B29072C4A509BBB256D8D5BA631ED4385EA"} */
        return ['/refine-search'];
        /* {CLOSE:getRouterLinkForTotalFloorAreaFrom120To130} {"sha1":"82901B29072C4A509BBB256D8D5BA631ED4385EA"} */
    }
    /* {POSTCLOSE:getRouterLinkForTotalFloorAreaFrom120To130} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRouterLinkForCategoriesAllAreSelected} {"sha1":"ABF442446D046D2EFD5250F49E8B37CA0DD0D32E"} */
    getRouterLinkForCategoriesAllAreSelected(searchRefine) {
        /* {OPEN:getRouterLinkForCategoriesAllAreSelected} {"sha1":"82901B29072C4A509BBB256D8D5BA631ED4385EA"} */
        return ['/refine-search'];
        /* {CLOSE:getRouterLinkForCategoriesAllAreSelected} {"sha1":"82901B29072C4A509BBB256D8D5BA631ED4385EA"} */
    }
    /* {POSTCLOSE:getRouterLinkForCategoriesAllAreSelected} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onSearchClick} {"sha1":"F9AA9C954261131EE2DD429198D29267C52D1BD3"} */
    onSearchClick(event, searchRefine) {
        /* {OPEN:onSearchClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onSearchClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onSearchClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:resetInitVariables} {"sha1":"846C0DF64879342578D595FE17BC00B96F0BAED6"} */
    resetInitVariables() {
        /* {OPEN:resetInitVariables} {} */
        /* {OPEN:resetInitVariables_stagesTableCols} {"sha1":"630EA96A9A9056523B664FE48C50F2E9489FA00A"} */
        try {
            this.stagesTableCols = this.getStagesTableCols();
        }
        catch (error) {
            console.error('Error occured when setting stagesTableCols', error);
        }
        /* {CLOSE:resetInitVariables_stagesTableCols} {"sha1":"630EA96A9A9056523B664FE48C50F2E9489FA00A"} */
        /* {OPEN:resetInitVariables_stagesTableSelectionMode} {"sha1":"DEBDA419A92C92B8D90A7CB41231335FD9ADEE46"} */
        try {
            this.stagesTableSelectionMode = this.getStagesTableSelectionMode();
        }
        catch (error) {
            console.error('Error occured when setting stagesTableSelectionMode', error);
        }
        /* {CLOSE:resetInitVariables_stagesTableSelectionMode} {"sha1":"DEBDA419A92C92B8D90A7CB41231335FD9ADEE46"} */
        /* {OPEN:resetInitVariables_categoriesTableCols} {"sha1":"E7A373BE207ACBDEA9F510CCA45A102F00D53984"} */
        try {
            this.categoriesTableCols = this.getCategoriesTableCols();
        }
        catch (error) {
            console.error('Error occured when setting categoriesTableCols', error);
        }
        /* {CLOSE:resetInitVariables_categoriesTableCols} {"sha1":"E7A373BE207ACBDEA9F510CCA45A102F00D53984"} */
        /* {OPEN:resetInitVariables_categoriesTableSelectionMode} {"sha1":"A7D66F08CFF827A51C552DC0632F4B5C417064DC"} */
        try {
            this.categoriesTableSelectionMode = this.getCategoriesTableSelectionMode();
        }
        catch (error) {
            console.error('Error occured when setting categoriesTableSelectionMode', error);
        }
        /* {CLOSE:resetInitVariables_categoriesTableSelectionMode} {"sha1":"A7D66F08CFF827A51C552DC0632F4B5C417064DC"} */
        /* {OPEN:resetInitVariables_locationsTableCols} {"sha1":"DEE700F824334D1F8434EC649E87F74165F0A5B8"} */
        try {
            this.locationsTableCols = this.getLocationsTableCols();
        }
        catch (error) {
            console.error('Error occured when setting locationsTableCols', error);
        }
        /* {CLOSE:resetInitVariables_locationsTableCols} {"sha1":"DEE700F824334D1F8434EC649E87F74165F0A5B8"} */
        /* {OPEN:resetInitVariables_locationsTableSelectionMode} {"sha1":"72B3D50530290CC85907D3964EC65F7C48289053"} */
        try {
            this.locationsTableSelectionMode = this.getLocationsTableSelectionMode();
        }
        catch (error) {
            console.error('Error occured when setting locationsTableSelectionMode', error);
        }
        /* {CLOSE:resetInitVariables_locationsTableSelectionMode} {"sha1":"72B3D50530290CC85907D3964EC65F7C48289053"} */
        /* {OPEN:resetInitVariables_developmentTypesTableCols} {"sha1":"DF3AA49B52919B292E6FF299B4ED516AED508A51"} */
        try {
            this.developmentTypesTableCols = this.getDevelopmentTypesTableCols();
        }
        catch (error) {
            console.error('Error occured when setting developmentTypesTableCols', error);
        }
        /* {CLOSE:resetInitVariables_developmentTypesTableCols} {"sha1":"DF3AA49B52919B292E6FF299B4ED516AED508A51"} */
        /* {OPEN:resetInitVariables_developmentTypesTableSelectionMode} {"sha1":"A108C8CD940EB337550C0536D1E14578F9768E04"} */
        try {
            this.developmentTypesTableSelectionMode = this.getDevelopmentTypesTableSelectionMode();
        }
        catch (error) {
            console.error('Error occured when setting developmentTypesTableSelectionMode', error);
        }
        /* {CLOSE:resetInitVariables_developmentTypesTableSelectionMode} {"sha1":"A108C8CD940EB337550C0536D1E14578F9768E04"} */
        /* {OPEN:resetInitVariables_contractTypesTableCols} {"sha1":"7662C999373D6ABEDBCE26F996F17174F8580A8A"} */
        try {
            this.contractTypesTableCols = this.getContractTypesTableCols();
        }
        catch (error) {
            console.error('Error occured when setting contractTypesTableCols', error);
        }
        /* {CLOSE:resetInitVariables_contractTypesTableCols} {"sha1":"7662C999373D6ABEDBCE26F996F17174F8580A8A"} */
        /* {OPEN:resetInitVariables_contractTypesTableSelectionMode} {"sha1":"E9C3BD733123716B525E307384B10183FADA08B1"} */
        try {
            this.contractTypesTableSelectionMode = this.getContractTypesTableSelectionMode();
        }
        catch (error) {
            console.error('Error occured when setting contractTypesTableSelectionMode', error);
        }
        /* {CLOSE:resetInitVariables_contractTypesTableSelectionMode} {"sha1":"E9C3BD733123716B525E307384B10183FADA08B1"} */
        /* {OPEN:resetInitVariables_rolesTableCols} {"sha1":"641EC665968ACB1C67A018C17B85E33EBEDD6474"} */
        try {
            this.rolesTableCols = this.getRolesTableCols();
        }
        catch (error) {
            console.error('Error occured when setting rolesTableCols', error);
        }
        /* {CLOSE:resetInitVariables_rolesTableCols} {"sha1":"641EC665968ACB1C67A018C17B85E33EBEDD6474"} */
        /* {OPEN:resetInitVariables_rolesTableSelectionMode} {"sha1":"7B14C85E38A139B0A9F42C09D35592D9558207C0"} */
        try {
            this.rolesTableSelectionMode = this.getRolesTableSelectionMode();
        }
        catch (error) {
            console.error('Error occured when setting rolesTableSelectionMode', error);
        }
        /* {CLOSE:resetInitVariables_rolesTableSelectionMode} {"sha1":"7B14C85E38A139B0A9F42C09D35592D9558207C0"} */
        /* {OPEN:resetInitVariables_materialsTableCols} {"sha1":"9BB7946EA1A8BAE4C03BB2FE5912AB9358F3A033"} */
        try {
            this.materialsTableCols = this.getMaterialsTableCols();
        }
        catch (error) {
            console.error('Error occured when setting materialsTableCols', error);
        }
        /* {CLOSE:resetInitVariables_materialsTableCols} {"sha1":"9BB7946EA1A8BAE4C03BB2FE5912AB9358F3A033"} */
        /* {OPEN:resetInitVariables_materialsTableSelectionMode} {"sha1":"929542B6EB84A4784ED14A36B9B8C0B488184F88"} */
        try {
            this.materialsTableSelectionMode = this.getMaterialsTableSelectionMode();
        }
        catch (error) {
            console.error('Error occured when setting materialsTableSelectionMode', error);
        }
        /* {CLOSE:resetInitVariables_materialsTableSelectionMode} {"sha1":"929542B6EB84A4784ED14A36B9B8C0B488184F88"} */
        /* {OPEN:resetInitVariables_fundingsTableCols} {"sha1":"827A16022447D993C130FFAF2F866A1F627C210E"} */
        try {
            this.fundingsTableCols = this.getFundingsTableCols();
        }
        catch (error) {
            console.error('Error occured when setting fundingsTableCols', error);
        }
        /* {CLOSE:resetInitVariables_fundingsTableCols} {"sha1":"827A16022447D993C130FFAF2F866A1F627C210E"} */
        /* {OPEN:resetInitVariables_fundingsTableSelectionMode} {"sha1":"87B5C9844899B271B350043273A4DCB0BC50372B"} */
        try {
            this.fundingsTableSelectionMode = this.getFundingsTableSelectionMode();
        }
        catch (error) {
            console.error('Error occured when setting fundingsTableSelectionMode', error);
        }
        /* {CLOSE:resetInitVariables_fundingsTableSelectionMode} {"sha1":"87B5C9844899B271B350043273A4DCB0BC50372B"} */
        /* {OPEN:resetInitVariables_resetDummyOptions} {"sha1":"7D87262EE5F0A0AD24978439EF5E8411E0CF4A9E"} */
        try {
            this.resetDummyOptions();
        }
        catch (error) {
            console.error('Error occured when executing resetDummyOptions', error);
        }
        /* {CLOSE:resetInitVariables_resetDummyOptions} {"sha1":"7D87262EE5F0A0AD24978439EF5E8411E0CF4A9E"} */
        /* {CLOSE:resetInitVariables} {} */
    }
    /* {POSTCLOSE:resetInitVariables} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getPageTitle} {"sha1":"174A3D30FFA103DD7BE180EE89CC0CF5EB6D11B8"} */
    getPageTitle() {
        /* {OPEN:getPageTitle} {"sha1":"400CB7F2FA42B132F80883C5B99CFA9585C83832"} */
        return 'Refine search';
        /* {CLOSE:getPageTitle} {"sha1":"400CB7F2FA42B132F80883C5B99CFA9585C83832"} */
    }
    /* {POSTCLOSE:getPageTitle} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:calculateEverything} {"sha1":"CE17BF2375171825A37C544A1DCBF3A565DD5181"} */
    calculateEverything() {
        /* {OPEN:calculateEverything} {} */
        /* {OPEN:calculateEverything_pageTitle} {"sha1":"B3CA5EAE3ED54D1F0D95EA2CD05351A4A6D7480F"} */
        try {
            this.pageTitle = this.getPageTitle();
        }
        catch (error) {
            console.error('Error occured when setting pageTitle');
        }
        /* {CLOSE:calculateEverything_pageTitle} {"sha1":"B3CA5EAE3ED54D1F0D95EA2CD05351A4A6D7480F"} */
        /* {OPEN:calculateEverything_stagesTableTitle} {"sha1":"68F73A9CB125654247D3EDCC157EE002DDD0E0AD"} */
        try {
            this.stagesTableTitle = this.getStagesTableTitle();
        }
        catch (error) {
            console.error('Error occured when setting stagesTableTitle');
        }
        /* {CLOSE:calculateEverything_stagesTableTitle} {"sha1":"68F73A9CB125654247D3EDCC157EE002DDD0E0AD"} */
        /* {OPEN:calculateEverything_categoriesTableTitle} {"sha1":"0A4F29F96E358DD17952D56FA2960A3BFE1FEE43"} */
        try {
            this.categoriesTableTitle = this.getCategoriesTableTitle();
        }
        catch (error) {
            console.error('Error occured when setting categoriesTableTitle');
        }
        /* {CLOSE:calculateEverything_categoriesTableTitle} {"sha1":"0A4F29F96E358DD17952D56FA2960A3BFE1FEE43"} */
        /* {OPEN:calculateEverything_locationsTableTitle} {"sha1":"040195F5F544C9CBB22242B9FE0511E5475F371C"} */
        try {
            this.locationsTableTitle = this.getLocationsTableTitle();
        }
        catch (error) {
            console.error('Error occured when setting locationsTableTitle');
        }
        /* {CLOSE:calculateEverything_locationsTableTitle} {"sha1":"040195F5F544C9CBB22242B9FE0511E5475F371C"} */
        /* {OPEN:calculateEverything_developmentTypesTableTitle} {"sha1":"4D504E5DA4F0FEAA23D736D48AD3BCCD7FBD5AAE"} */
        try {
            this.developmentTypesTableTitle = this.getDevelopmentTypesTableTitle();
        }
        catch (error) {
            console.error('Error occured when setting developmentTypesTableTitle');
        }
        /* {CLOSE:calculateEverything_developmentTypesTableTitle} {"sha1":"4D504E5DA4F0FEAA23D736D48AD3BCCD7FBD5AAE"} */
        /* {OPEN:calculateEverything_contractTypesTableTitle} {"sha1":"B4844AF4E0D834E6E29553B3B6FD09324B294462"} */
        try {
            this.contractTypesTableTitle = this.getContractTypesTableTitle();
        }
        catch (error) {
            console.error('Error occured when setting contractTypesTableTitle');
        }
        /* {CLOSE:calculateEverything_contractTypesTableTitle} {"sha1":"B4844AF4E0D834E6E29553B3B6FD09324B294462"} */
        /* {OPEN:calculateEverything_rolesTableTitle} {"sha1":"440AC56779160A4546FADA7F2AA320F44C0789CD"} */
        try {
            this.rolesTableTitle = this.getRolesTableTitle();
        }
        catch (error) {
            console.error('Error occured when setting rolesTableTitle');
        }
        /* {CLOSE:calculateEverything_rolesTableTitle} {"sha1":"440AC56779160A4546FADA7F2AA320F44C0789CD"} */
        /* {OPEN:calculateEverything_materialsTableTitle} {"sha1":"C6C41832EDAABA982BC5B8DA79937E8C788E8C19"} */
        try {
            this.materialsTableTitle = this.getMaterialsTableTitle();
        }
        catch (error) {
            console.error('Error occured when setting materialsTableTitle');
        }
        /* {CLOSE:calculateEverything_materialsTableTitle} {"sha1":"C6C41832EDAABA982BC5B8DA79937E8C788E8C19"} */
        /* {OPEN:calculateEverything_fundingsTableTitle} {"sha1":"8719FB96C13FEFAFF27ED1B29EA78EB499BC7503"} */
        try {
            this.fundingsTableTitle = this.getFundingsTableTitle();
        }
        catch (error) {
            console.error('Error occured when setting fundingsTableTitle');
        }
        /* {CLOSE:calculateEverything_fundingsTableTitle} {"sha1":"8719FB96C13FEFAFF27ED1B29EA78EB499BC7503"} */
        /* {OPEN:calculateEverything_isDummyVolatile} {"sha1":"C5A67038A4CFAFF0ECC78118AC46E5840646DFDC"} */
        try {
            if(this.isDummyVolatile) {
                this.resetDummyOptions();
            }
        }
        catch (error) {
            console.error('Error occured when resetting dummyOptions');
        }
        /* {CLOSE:calculateEverything_isDummyVolatile} {"sha1":"C5A67038A4CFAFF0ECC78118AC46E5840646DFDC"} */
        /* {CLOSE:calculateEverything} {} */
    }
    /* {POSTCLOSE:calculateEverything} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:ngDoCheck} {"sha1":"75839B27D41E20648CE296AC51D61E24D541A70A"} */
    ngDoCheck() {
        /* {OPEN:ngDoCheck} {"sha1":"A154E0663059B6D385B4F6C11094F2084FF5E184"} */
        this.calculateEverything();
        /* {CLOSE:ngDoCheck} {"sha1":"A154E0663059B6D385B4F6C11094F2084FF5E184"} */
    }
    /* {POSTCLOSE:ngDoCheck} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:ngOnDestroy} {"sha1":"C21B2137D2BD86DF7050E24A39F08355F04DA4E9"} */
    ngOnDestroy() {
        /* {OPEN:ngOnDestroy} {"sha1":"85AA65230B2C97013ABDD30D48BEB93C04E8EE54"} */
        this.utilsService.unsubscribeSubscriptions(this.subscriptions);
        /* {CLOSE:ngOnDestroy} {"sha1":"85AA65230B2C97013ABDD30D48BEB93C04E8EE54"} */
    }
    /* {POSTCLOSE:ngOnDestroy} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {CLOSE:CLASS} {} */
}
/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */