import { Component, ContentChildren, Input, OnInit, QueryList, TemplateRef } from '@angular/core';
import { PrimeTemplate } from 'primeng/api';

@Component({
    selector: 'app-button-dropdown',
    templateUrl: './button-dropdown.component.html',
    styleUrls: ['./button-dropdown.component.scss']
})
export class ButtonDropdownComponent implements OnInit {

    @Input() label      : string;
    @Input() model      : any[];
    @Input() position   : string    =   'left';
    @Input() icon       : string    =   '';
    @Input() iconPos    : string    =   'right';
    @Input() btnClass   : string    =   '';

    childButtonTemplate : TemplateRef<any>;
    parentButtonTemplate : TemplateRef<any>;
    
    @ContentChildren(PrimeTemplate) templates: QueryList<any>;

    constructor() { }
    
    ngOnInit() {
        
    }

    ngAfterContentInit() {
        this.templates.forEach((item) => {
            switch(item.getType()) {
                case 'parent-button':
                    this.parentButtonTemplate = item.template;
                break;

                case 'child-button':
                    this.childButtonTemplate = item.template;
                break;
            }
        });
    }
    
}