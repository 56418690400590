import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageService } from 'primeng/api';
import { DomHandler } from 'primeng/dom';
import { PrimeNGModules } from './../utils/common.modules.import';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { BreadcrumbService } from './components/breadcrumb/breadcrumb.service';
import { ButtonDropdownComponent } from './components/button-dropdown/button-dropdown.component';
import { CustomTieredMenuSub } from './components/custom-tiered-menu-sub/custom-tiered-menu-sub.component';
import { CustomTieredMenuComponent } from './components/custom-tiered-menu/custom-tiered-menu.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { FeedbackService } from './components/feedback/feedback.service';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { IframePageComponent } from './components/iframe-page/iframe-page.component';
import { AppSubMenuComponent, SidebarComponent } from './components/sidebar/sidebar.component';
import { AtleastOneRequiredValidatorDirective } from './directives/atleast-one-required-validator.directive';
import { AutoCompleteDirective } from './directives/auto-complete.directive';
import { EmailValidatorDirective } from './directives/email-validator.directive';
import { FocusDirective } from './directives/focus.directive';
import { IFrameResizerDirective } from './directives/iframe-resizer.directive';
import { IframeTrackerDirective } from './directives/iframe-tracker.directive';
import { MarkedDirective } from './directives/marked.directive';
import { MaxValueValidatorDirective } from './directives/max-value-validator.directive';
import { MinValueValidatorDirective } from './directives/min-value-validator.directive';
import { ValidationErrorMessageDirective } from './directives/validation-error-message.directive';
import { AuthGuard } from './guards/auth.guard';
import { NoopHttpInterceptor } from './interceptor/noop.Interceptor';
import { MessageSourceConfig } from './models/message-source.model';
import { OneITDatePipe } from './pipes/date.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { ShortenPipe } from './pipes/shorten.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { TranslatePipe } from './pipes/translate.pipe';
import { ApiService } from './services/api.service';
import { ConfigService } from './services/config.service';
import { EnumService } from './services/enum.service';
import { IframeMsgHandlingService } from './services/iframe-msg-handling.service';
import { MenuService } from './services/menu.service';
import { MsgsService } from './services/msgs.service';
import { SaveService } from './services/save.service';
import { SearchService } from './services/search.service';
import { StartUpService } from './services/startup.service';
import { TranslateService } from './services/translate.service';
import { UserService } from './services/user.service';
import { UtilsService } from './services/utils.service';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        BrowserAnimationsModule,
        ...PrimeNGModules
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: startup,
            deps: [StartUpService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            multi: true,
            useClass: NoopHttpInterceptor
        },
        ApiService,
        ConfigService,
        UtilsService,
        SearchService,
        EnumService,
        SaveService,
        AuthGuard,
        BreadcrumbService,
        FeedbackService,
        IframeMsgHandlingService,
        MessageSourceConfig,
        MsgsService,
        MenuService,
        StartUpService,
        TranslateService,
        UserService,
        DecimalPipe,
        OneITDatePipe,
        MessageService,
        DomHandler,
        DatePipe
    ],
    declarations: [
        HeaderComponent,
        FooterComponent,
        IframePageComponent,
        SidebarComponent,
        HomeComponent,
        BreadcrumbComponent,
        AppSubMenuComponent,
        FeedbackComponent,
        EmailValidatorDirective,
        AccessDeniedComponent,
        ButtonDropdownComponent,
        SafePipe,
        SortPipe,
        FilterPipe,
        ShortenPipe,
        TranslatePipe,
        OneITDatePipe,
        ValidationErrorMessageDirective,
        AtleastOneRequiredValidatorDirective,
        IFrameResizerDirective,
        IframeTrackerDirective,
        MinValueValidatorDirective,
        MaxValueValidatorDirective,
        MarkedDirective,
        AutoCompleteDirective,
        CustomTieredMenuComponent,
        CustomTieredMenuSub,
        FocusDirective
    ],
    exports: [
        ...PrimeNGModules,
        ButtonDropdownComponent,
        CustomTieredMenuComponent,
        CustomTieredMenuSub,
        HeaderComponent,
        SidebarComponent,
        FooterComponent,
        FeedbackComponent,
        EmailValidatorDirective,
        SafePipe,
        SortPipe,
        FilterPipe,
        ShortenPipe,
        TranslatePipe,
        OneITDatePipe,
        ValidationErrorMessageDirective,
        AtleastOneRequiredValidatorDirective,
        IFrameResizerDirective,
        IframeTrackerDirective,
        MinValueValidatorDirective,
        MaxValueValidatorDirective,
        MarkedDirective,
        AutoCompleteDirective,
        FocusDirective
    ]
})
export class OneITModule { }

export function startup(startUpService: StartUpService) {
    return () => {
        return startUpService.start();
    }
}
