import { AppConstants } from 'src/app/cbos/constants/app-constants';
/* {OPEN:IMPORT} {"sha1":"A0D12EEF6E9FFCC275DEF27C39F44D6D3F3A7F94"} */
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { BaseAppService } from './base-app.service';
/* {CLOSE:IMPORT} {"sha1":"A0D12EEF6E9FFCC275DEF27C39F44D6D3F3A7F94"} */
import { EnumService } from './oneit/services/enum.service';
import { MsgsService } from './oneit/services/msgs.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { UtilsService } from './oneit/services/utils.service';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver/FileSaver';
/* {POSTCLOSE:IMPORT} {"sha1":"A0D12EEF6E9FFCC275DEF27C39F44D6D3F3A7F94"} *

/* {PREOPEN:CLASS} {"sha1":"C0FF2A8EE843D507088A5922E3DA655BC25D7AAE"} */
@Injectable()
export class AppService extends BaseAppService {
    /* {OPEN:CLASS} {"sha1":"DC08B5B6CA432B46CCA94F1F297491E1B08736EA"} */

    /* {CLOSE:CLASS} {"sha1":"DC08B5B6CA432B46CCA94F1F297491E1B08736EA"} */
    isProjectOrCompany: any;
    dynamicDialogConfig = {
        width: '90%',
        height: '60%',
        baseZIndex: 10000
    };
    errorMsgs = [];
    backURL = environment.baseUrl;
    selectedModule;
    filterMap = {
        project_text: 'project_text',
        stages: 'project_stages',
        categories: 'project_categories',
        development_types: 'project_development_types',
        materials: 'project_materials',
        roles: 'project_roles',
        contract_types: 'project_contract_types',
        funding: 'project_funding',
        value: 'project_value',
        units: 'project_units',
        parking: 'project_parking',
        floor_area: 'project_floor_area',
        storeys_above: 'project_storeys_above',
        storeys_below: 'project_storeys_below',
        site_area: 'project_site_area',
        plan_area: 'project_plan_area',
        project_start: 'project_start',
        project_end: 'project_end',
        project_tender_close_display: 'project_tender_close_display',
        company_text: 'company_text',
        project_latitude: 'project_latitude',
        project_longitude: 'project_longitude',
        project_distance: 'project_distance',
        company_latitude: 'company_latitude',
        company_longitude: 'company_longitude',
        company_distance: 'company_distance',
        project_geocode: 'project_geocode',
        company_geocode: 'company_geocode'
    }
    datesKeys = ['project_start','project_end','project_tender_close_display'];
    measurementKeys = ['project_value','project_units','project_storeys_above','project_storeys_below','project_site_area'
    ,'project_plan_area','project_parking','project_floor_area'];
    calLengthKeys = ['project_stages','project_categories','project_geocode','project_development_types','project_contract_types','project_materials','roles','project_funding'];

    //@override
    constructor(
        public enumService: EnumService,
        public msgsService: MsgsService,
        public httpClient: HttpClient,
        private router: Router,
        private utilsService: UtilsService
    ) {
        super(enumService);
    }

    validatePassword(password) {
        this.errorMsgs  = [];
        if ( password && password.length < 8 ) {
            this.errorMsgs.push('Please enter a password of at least 8 characters.');
        }

        if ( password && !(/\d/.test(password)) ) {
            this.errorMsgs.push('Please enter a password that includes 1 number.');
        }

        if ( password && !(/[a-z]/.test(password)) ) {
            this.errorMsgs.push('Please enter a password that includes 1 lower case letter');
        }

        if ( password && !(/[A-Z]/.test(password)) ) {
            this.errorMsgs.push('Please enter a password that includes 1 upper case letter');
        }

        if ( password && !(/[!@#$%^&*()_\-+<>?/"^~`,.'|;:}{=\[\]\\]/.test(password)) ) {
            this.errorMsgs.push('Please enter a password that includes 1 special character');
        }

        if ( this.errorMsgs.length > 0 ) {
            this.msgsService.addErrorMessages(this.errorMsgs);
            this.errorMsgs = [];
            return true;
        }

        return false;
    }

    authenticate(svc, params): Observable<any> {
        return this.httpPostRequest(this.backURL + svc, params);
    }

    forgotPassword(svc, params): Observable<any> {
        return this.httpPostRequest(this.backURL + svc, params);
    }

    changePassword(svc, params): Observable<any> {
        return this.httpPostRequest(this.backURL + svc, params);
    }

    doLogin(svc, params): Observable<any> {
        return this.httpGetRequest(this.backURL + svc, params);
    }

    getModuleFilters(svc, params): Observable<any> {
        return this.httpGetRequest(this.backURL + svc, params);
    }

    validateToken(svc, params): Observable<any> {
        const httpParams = new HttpParams().set('token', params.token);
        return this.httpGetRequest(this.backURL + svc, httpParams);
    }

    createSavedSearch(svc, params): Observable<any> {
        return this.httpPostRequest(this.backURL + svc, params)
    }

    updateSavedSearch(svc, params): Observable<any> {
        return this.httpPutRequest(this.backURL + svc, params)
    }

    createDeal(params): Observable<any> {
        return this.httpPostRequest(this.backURL + 'crm/projects', params)
    }

    searchConvertedDeals(): Observable<any> {
        return this.httpGetRequest(this.backURL + 'crm/deals', null);
    }

    getDataProvidersObservable(): Observable<any> {
        return this.httpGetRequest(this.backURL + 'data-providers/list', null);
    }

    getDataProviders(): Observable<any> {
      return this.httpGetRequest(this.backURL + 'data-providers/list', null);
  }

    addDataProvider(svc, params): Observable<any> {
        return this.httpPostRequest(this.backURL + svc, params);
    }

    getProjectsOrCompanies(svc, params): Observable<any> {
        return this.httpPostRequest(this.backURL + svc, params);
    }

    getCacheFilters(svc, params): Observable<any> {
      return this.httpGetRequest(this.backURL + svc, params);
    }

    getSavedSearches(svc, params): Observable<any> {
        return this.httpGetRequest(this.backURL + svc, params);
    }

    getSavedSearchByID(svc, params): Observable<any> {
        return this.httpGetRequest(this.backURL + svc, params);
    }

    getModuleByID(svc, params): Observable<any> {
        return this.httpGetRequest(this.backURL + svc, params);
    }

    markModuleAsFavorite(svc , params): Observable<any> {
        return this.httpPutRequest(this.backURL + svc , params);
    }

    createAccount(svc , params) {
        return this.httpPostRequest(this.backURL + svc, params);
    }

    createContact(svc, params): Observable<any> {
        return this.httpPostRequest(this.backURL + svc, params);
    }

    getModuleTabDetails(svc , params): Observable<any> {
        return this.httpGetRequest(this.backURL + svc, params);
    }

    getCRMConfigOptions() {
        this.httpGetRequest(this.backURL + 'crm/config' , null).subscribe(data => {
            if(data.users) {
                localStorage.setItem('Owners' ,  JSON.stringify(data.users));
            }

            if(data.stages) {
                localStorage.setItem('Stages' ,  JSON.stringify(data.stages));
            }
        }, error =>  {
            this.utilsService.handleError(error);
        });
    }

    updateCRM(params) :Observable<any> {
        return this.httpPostRequest(this.backURL + 'crm/projects' , params);
    }

    logout(svc, params) {
        this.httpGetRequest(this.backURL + svc, params).subscribe(() => {
            localStorage.clear();
            this.router.navigate(['./login']);
        }, error => {
            this.utilsService.handleError(error);
        });
    }

    getFullDataProvider() {
        let provider = localStorage.getItem('DATA_PROVIDER');
        return provider ? JSON.parse(provider) : null;
    }

    getSelectedDataProvider() {
        let dataProvider = this.getFullDataProvider();
        return dataProvider ? dataProvider : null;
    }

    getCRMOptions(): Observable<any> {
        return this.httpGetRequest(this.backURL + 'crm/list' , null);
    }

    crmLogin(key): Observable<any> {
        return this.httpGetRequest(this.backURL + `${key}/login` , null);
    }

    setDefaultCRM(crmName): Observable<any> {
        return this.httpPutRequest(`${this.backURL}crm/default?crmName=${crmName}`, null);
    }

    getModuleDeals(id, module): Observable<any> {
        return this.httpGetRequest(`${this.backURL}crm/${module}/${id}/deals` , null)
    }

    getContactByID(id): Observable<any> {
        return this.httpGetRequest(`${this.backURL}${this.getSelectedDataProvider()}/people/${id}`, null)
    }

    getContactRolesByID(id): Observable<any> {
        return this.httpGetRequest(`${this.backURL}${this.getSelectedDataProvider()}/people/${id}/roles`, null)
    }

    registerCRM(crm): Observable<any> {
        return this.httpGetRequest(`${this.backURL}${crm.crmDetails.apiKey}/register` , null);
    }

    httpPostRequest(url, params): Observable<any> {
        return this.httpClient.post(url, params, {withCredentials: true});
    }

    httpGetRequest(url, params): Observable<any> {
        return this.httpClient.get(url, {params, withCredentials: true});
    }

    httpPutRequest(url, params): Observable<any> {
        return this.httpClient.put(url, params);
    }

    setFilteredParamsForSearchField(data) {
        const search = [];
        if(data) {
            for ( const queryKey in this.filterMap ) {
                if(data.hasOwnProperty(this.filterMap[queryKey])) {
                    if(Array.isArray(data[this.filterMap[queryKey]])) {
                        this.setArrayData(data, this.filterMap[queryKey], queryKey, search);
                    }

                    if(!Array.isArray(data[this.filterMap[queryKey]])) {
                        this.setObjectData(data, this.filterMap[queryKey], queryKey, search , this.isCalculateLength(this.filterMap[queryKey]));
                    }
                }
            }
        }
        return search;
    }

    isCalculateLength(query) {
        return this.calLengthKeys.includes(query);
    }

    setArrayData(data, query, key, search) {
        if(data[query] && data[query].length > 0) {
            data[query].forEach(item => {
                if(item.operator === '=' && item.value.length > 0) {
                    search.push(`${ key }: ${ item.value.length } included`);
                }

                if(item.operator === '!=' && item.value.length > 0) {
                    search.push(`${ key }: ${ item.value.length } excluded`);
                }
            })
        }
    }

    setObjectData(data, query, key, search , calculateLength = false)  {
        if ( data[query].value1 && data[query].value2 ) {
            search.push(`${ key }: ${ data[query].value1 } ${ data[query].operator } ${ data[query].value2 }`)
            return;
        }

        if(calculateLength) {
            if ( data[query].operator === '=' && data[query].value.length > 0 ) {
                search.push(`${ key }: ${ data[query].value.length } included`);
            }

            if ( data[query].operator === '!=' && data[query].value.length > 0 ) {
                search.push(`${ key }: ${ data[query].value.length } excluded`);
            }
            return;
        }

        if ( data[query].value ) {
            search.push(`${ key }: ${ data[query].value }`)
            return;
        }
    }

    removeFilterParam(value , filters) {
        let key = value.split(':')[0];

        if(key === 'stages' && JSON.parse(localStorage.getItem('Includerefusedwithdrawnabandonedandonhold'))) {
            localStorage.setItem('Includerefusedwithdrawnabandonedandonhold' , JSON.stringify(false));
        }

        if(value.includes('excluded')) {
            this.removeItem(filters , this.filterMap[key] , '!=')
        } else if(value.includes('included')) {
            this.removeItem(filters , this.filterMap[key] , '=')
        } else {
            delete filters[this.filterMap[key]];
            if(key === 'project_distance' || key === 'company_distance') {
                delete filters['project_latitude'];
                delete filters['project_longitude'];
                delete filters['company_latitude'];
                delete filters['company_longitude'];
            }
        }

        return filters;
    }

    removeItem(filters , key , operator) {
        if(!Array.isArray(filters[key])) {
            delete filters[key];
            return;
        }

        const index = filters[key].indexOf(filters[key].find(item => item.operator === operator));

        filters[key].splice(index, 1);

        if(filters[key].length === 0 || filters[key][0].value.length === 0 ) {
            delete filters[key];
        }
    }

    calculateTotalNumberOfFiltersApplied(filters) {
        let totalFilters = 0
        let isDateFilterChecked = false;
        let isMeasurementFilterChecked = false;
        for ( let key in filters ) {
            if ( key !== 'project_longitude' && key !== 'project_latitude' && key !== 'company_longitude' && key !== 'company_latitude' ) {
                if ( !isDateFilterChecked && this.datesKeys.includes(key)) {
                    isDateFilterChecked = true;
                    totalFilters++;
                }

                if ( !isMeasurementFilterChecked && this.measurementKeys.includes(key) ) {
                    isMeasurementFilterChecked = true;
                    totalFilters++;
                }

                if ( !this.datesKeys.includes(key) && !this.measurementKeys.includes(key)) {
                    totalFilters++;
                }
            }
        }
        return totalFilters;
    }

    getRefineSearchFilters() {
        this.getModuleFilters(`${this.getSelectedDataProvider()}/filters` , null).subscribe(data => {
            localStorage.setItem('moduleFilters', JSON.stringify(data));
        }, error => {
            this.utilsService.handleError(error);
        });
    }

    getCacheSearchFilters() {
      this.getModuleFilters(`${this.getSelectedDataProvider()}/cache` , null).subscribe(data => {
          localStorage.setItem('CACHE_FILTERS', JSON.stringify(data));
      }, error => {
          this.utilsService.handleError(error);
      });
  }

    getModuleAddress(moduleObj) {

        if(moduleObj) {
            return this.utilsService.joinAttribs(moduleObj, ', ', ['address1', 'address2', 'address3', 'address4']);
        }

        return '';
    }

    exportXLSX(exportData) {
        const workSheet = XLSX.utils.aoa_to_sheet(exportData, { cellStyles: true });
        const workBook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workBook, workSheet, 'export');
        XLSX.writeFile(workBook, `export.xlsx`, { cellStyles: true });
    }

    exportCSV(exportData) {
        const csvArray = exportData.join('\r\n');
        const blob = new Blob([csvArray], { type: 'text/csv' });
        saveAs(blob, 'export.csv');
    }

    isAdminPanelAccessible() {
      let userData: any = JSON.parse(localStorage.getItem(AppConstants.USER_INFO));
      if(userData?.roles && userData.roles.includes('ROLE_SUPERADMIN')) return true;
      return false;
    }


    isBarbour() : boolean {
      return this.getSelectedDataProvider() === 'barbour';
    }
    isCIS() : boolean {
      return this.getSelectedDataProvider() === 'cis';
    }

    isProjectsSelected(): boolean {
      return this.selectedModule === AppConstants.PROJECTS;
    }
    isCompaniesSelected(): boolean {
      return this.selectedModule === AppConstants.COMPANIES;
    }
  }

/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */
