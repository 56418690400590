/* {OPEN:IMPORT} {"sha1":"3C51F444130F34B569F70D16DE068B68836B2991"} */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BoxLayoutComponent } from '../../oneit/components/box-layout/box-layout.component';
import { BaseLoginPageComponent } from './base-login-page.component';
/* {CLOSE:IMPORT} {"sha1":"3C51F444130F34B569F70D16DE068B68836B2991"} */
import { ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../oneit/services/user.service';
import { UtilsService } from '../../oneit/services/utils.service';
import { SearchService } from '../../oneit/services/search.service';
import { AppService } from '../../app.service';
import { ConfirmationService } from 'primeng/api';
import { LoginPageService } from './login-page.service';
import { DialogService } from 'primeng';
import { DataProviderCrmSelectionComponent } from '../data-provider-crm-selection/data-provider-crm-selection.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CRMLoginComponent } from '../crm-login/crm-login.component';
/* {POSTCLOSE:IMPORT} {"sha1":"3C51F444130F34B569F70D16DE068B68836B2991"} */

/* {PREOPEN:CLASS} {"sha1":"12564D23B96A201BC968E50AFCEEB53C810BA217"} */
@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent extends BaseLoginPageComponent implements OnInit, OnDestroy {
    /* {OPEN:CLASS} {} */

    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
        super.ngOnInit();
        /* {CLOSE:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
    }

    ngOnDestroy() {
        /* {OPEN:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
        super.ngOnDestroy();
        /* {CLOSE:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
    }

    /* {CLOSE:CLASS} {} */

    @ViewChild('form') form: NgForm;
    componentInView = new Subject();
    errorMsgs = [];
    disableLoginBtn = false;

    //@override
    constructor(
        public router: Router,
        public userService: UserService,
        public utilsService: UtilsService,
        public searchService: SearchService,
        public appService: AppService,
        public confirmationService: ConfirmationService,
        public loginPageService: LoginPageService,
        public fullLayoutComponent: BoxLayoutComponent,
        public dialogService: DialogService
    ) {
        super(router, userService, utilsService, searchService, appService, confirmationService, loginPageService);
    }

    //@override
    onSignMeInClick(event, searchLoginPage) {
        this.utilsService.clearErrorMessages();
        this.disableLoginBtn = true;

        if ( !this.form.valid ) {
            this.utilsService.showAllErrorMessages();
            this.disableLoginBtn =false;
            return;
        }

        this.showLoader = true;

        const params = {
            email: this.searchLoginPage.Username,
            password: this.searchLoginPage.Password
        };

        this.authenticate(params);
    }

    authenticate(params) {
        this.subscriptions.push(
            this.loginPageService.authenticate(params).subscribe((user) => {
                this.showLoader = false;
                this.disableLoginBtn = false;

                this.dialogService.open(DataProviderCrmSelectionComponent, {
                    header: 'Data Provider & CRM Selection',
                    ...this.appService.dynamicDialogConfig,
                    width: '480px',
                    height: '380px',
                    data: {user: user}
                }).onClose.pipe(takeUntil(this.componentInView)).subscribe(() => {
                    this.utilsService.clearErrorMessages();
                });
            }, error => {
                this.showLoader = false;
                this.disableLoginBtn = false;
                this.utilsService.handleError(error);
            })
        );
    }
}

/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */
