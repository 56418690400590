import { CbosModule } from './cbos/cbos.module';
import { AppMaterialModule } from './app-material/app-material.module';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormBuilder, FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ConfirmationService } from 'primeng/api';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { BoxLayoutComponent } from './oneit/components/box-layout/box-layout.component';
import { FullLayoutComponent } from './oneit/components/full-layout/full-layout.component';
import { OneITModule } from './oneit/oneit.module';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { LoginPageService } from './components/login-page/login-page.service';
import { AppService } from './app.service';
import { DataProviderCrmSelectionService } from './components/data-provider-crm-selection/data-provider-crm-selection.service';
import { DataProviderCrmSelectionComponent } from './components/data-provider-crm-selection/data-provider-crm-selection.component';
import { DialogService, SliderModule, TreeTableModule } from 'primeng';
import { AddDataProviderComponent } from './components/add-data-provider/add-data-provider.component';
import { AddDataProviderService } from './components/add-data-provider/add-data-provider.service';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ForgotPasswordService } from './components/forgot-password/forgot-password.service';
import { SearchProjectsComponent } from './components/search-projects/search-projects.component';
import { SearchProjectsService } from './components/search-projects/search-projects.service';
import { ResetPasswordService } from './components/reset-password/reset-password.service';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
// import { AgmCoreModule } from '@agm/core';
import { TableViewComponent } from './components/table-view/table-view.component';
import { TableViewService } from './components/table-view/table-view.service';
import { SaveSearchComponent } from './components/save-search/save-search.component';
import { SaveSearchService } from './components/save-search/save-search.service';
import { MultipleProjectDealPopUpService } from './components/multiple-project-deal-pop-up/multiple-project-deal-pop-up.service';
import { MultipleProjectDealPopUpComponent } from './components/multiple-project-deal-pop-up/multiple-project-deal-pop-up.component';
import { RefineSearchComponent } from './components/refine-search/refine-search.component';
import { RefineSearchService } from './components/refine-search/refine-search.service';
import { ConvertedDealsComponent } from './components/converted-deals/converted-deals.component';
import { ConvertedDealsService } from './components/converted-deals/converted-deals.service';
import { ProjectComponent } from './components/project/project.component';
import { ProjectService } from './components/project/project.service';
import { ProjectDealPopUpService } from './components/project-deal-pop-up/project-deal-pop-up.service';
import { ProjectDealPopUpComponent } from './components/project-deal-pop-up/project-deal-pop-up.component';
import { SavedSearchesComponent } from './components/saved-searches/saved-searches.component';
import { SavedSearchesService } from './components/saved-searches/saved-searches.service';
import { SearcherComponent } from './searcher/searcher.component';
import { CompanyComponent } from './components/company/company.component';
import { CompanyService } from './components/company/company.service';
import { CreateAccountService } from './components/create-account/create-account.service';
import { CreateAccountComponent } from './components/create-account/create-account.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { ContactsService } from './components/contacts/contacts.service';
import { CreateContactComponent } from './components/create-contact/create-contact.component';
import { CreateContactService } from './components/create-contact/create-contact.service';
import { CRMLoginComponent } from './components/crm-login/crm-login.component';
import { AddCrmComponent } from './components/add-crm/add-crm.component';
import { AddCrmService } from './components/add-crm/add-crm.service';
import { DashboardCardsComponent } from './components/dashboard-cards/dashboard-cards.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { MAT_DATE_LOCALE } from '@angular/material/core';

@NgModule({
    imports: [
        AppRoutingModule,
        AppMaterialModule,
        FormsModule,
        BrowserModule,
        CommonModule,
        HttpClientModule,
        OneITModule,
        // AgmCoreModule.forRoot({
        //     apiKey: 'AIzaSyCaBh-MsMb8BBYJD_NcFzoCmygQbt5-QSw'
        // }),
        TreeTableModule,
        SliderModule,
        BrowserAnimationsModule,
        LayoutModule,
        CbosModule,
    ],
    declarations: [
        AppComponent,
        FullLayoutComponent,
        BoxLayoutComponent,
        LoginPageComponent,
        DataProviderCrmSelectionComponent,
        AddDataProviderComponent,
        ForgotPasswordComponent,
        SearchProjectsComponent,
        ResetPasswordComponent,
        TableViewComponent,
        SaveSearchComponent,
        MultipleProjectDealPopUpComponent,
        RefineSearchComponent,
        ConvertedDealsComponent,
        ProjectComponent,
        ProjectDealPopUpComponent,
        SavedSearchesComponent,
        SearcherComponent,
        CompanyComponent,
        CreateAccountComponent,
        ContactsComponent,
        CreateContactComponent,
        CRMLoginComponent,
        AddCrmComponent,
        DashboardCardsComponent,
    ],
    providers: [
        FormBuilder,
        ConfirmationService,
        DialogService,
        LoginPageService,
        AppService,
        DataProviderCrmSelectionService,
        AddDataProviderService,
        ForgotPasswordService,
        SearchProjectsService,
        ResetPasswordService,
        TableViewService,
        SaveSearchService,
        MultipleProjectDealPopUpService,
        RefineSearchService,
        ConvertedDealsService,
        ProjectService,
        ProjectDealPopUpService,
        SavedSearchesService,
        CompanyService,
        CreateAccountService,
        ContactsService,
        CreateContactService,
        AddCrmService,
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
    constructor() {

    }
}
