import { CISRefineFiltersComponent } from './../cbos/cis/cis-refine-filters/cis-refine-filters.component';
import { AppConstants } from 'src/app/cbos/constants/app-constants';
import { LocalStorageService } from './../cbos/services/local-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { CisFilterService } from './../cbos/cis/cis-filter.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { AppService } from '../app.service';
import { SearchProjectsService } from '../components/search-projects/search-projects.service';
import { SearchTableView } from '../components/table-view/table-view.model';
import { UtilsService } from '../oneit/services/utils.service';
import { FullLayoutComponent } from '../oneit/components/full-layout/full-layout.component';
import { DialogService } from 'primeng';
import { RefineSearchComponent } from '../components/refine-search/refine-search.component';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-searcher',
    templateUrl: './searcher.component.html',
    styleUrls: ['./searcher.component.scss'],
})
export class SearcherComponent implements OnInit {

    searchProjectCompanyOptions: any[] = [];
    searchOptions: any[] = [];
    searcher: SearchTableView = new SearchTableView();
    totalFilters = 0;
    componentInView: Subject<any> = new Subject<any>();
    filters;
    module;
    showMore = false;
    favouriteOnly;

    @Output() search = new EventEmitter<any>();

    @Input() set setModule(module) {
        this.module =  module;
    }

    @Input() set setFilters(filters) {
        this.filters = filters;
        this.totalFilters = 0;
        if(this.isCIS()) {
          this.getCISChips();
        } else {
          this.searcher.Search = this.appService.setFilteredParamsForSearchField(this.filters);
          this.totalFilters = this.appService.calculateTotalNumberOfFiltersApplied(this.filters);
        }
        if(this.favouriteOnly) {
            this.totalFilters++;
            this.searcher.Search.push('FavouriteOnly: true');
        }
    }

    subscriptions: Array<Subscription> = [];

    constructor(private searchProjectsService: SearchProjectsService,
                private utilsService: UtilsService,
                private appService: AppService,
                private router: Router,
                private fullLayoutComponent : FullLayoutComponent,
                private dialogService : DialogService,
                private cisFilterService: CisFilterService,
                private localStorageService: LocalStorageService,
                private dialog: MatDialog) {
        this.resetSearchProjectCompanyOptions();
    }

    ngOnInit(): void {
        if(this.module === AppConstants.COMPANIES) {
            this.searcher.SearchProjectCompany = {
                value: 'companies',
                Description: 'Companies'
            };
        }

        if(this.module === AppConstants.PROJECTS) {
            this.searcher.SearchProjectCompany = {
                value: 'projects',
                Description: 'Projects'
            };
        }
    }

    resetSearchProjectCompanyOptions() {
        this.subscriptions.push(this.appService.getProjectsOrCompaniesObservable()
            .subscribe(
                data => {
                    this.searchProjectCompanyOptions = data;
                },
                error => {
                    this.utilsService.handleError(error);
                }
            )
        );
    }

    onSearchProjectCompanyChange() {
        this.appService.selectedModule = this.searcher.SearchProjectCompany.value;

        this.searcher.Search = null;
        this.totalFilters = 0;
        this.filters = null;
        this.cisFilterService.resetSearchFilters();
        this.cisFilterService.resetSelectedFilters();
    }

    onFiltersClick() {
        this.utilsService.clearErrorMessages();
        this.fullLayoutComponent.dialogOpen = true;
        if(this.isCIS()) {

          const dialogRef = this.dialog.open(CISRefineFiltersComponent, {
            panelClass: 'app-full-dialog',
            width: '95%',
            // minHeight: 'calc(100vh - 90px)',
            height : 'auto',
          });
          dialogRef.componentInstance.module = this.module;
          dialogRef.beforeClosed().subscribe(result => {
            this.utilsService.clearErrorMessages();
            this.fullLayoutComponent.dialogOpen = false;
            if(result) {
              this.setRefineSearchData({});
            }
          });
      }
        else {
        this.dialogService.open(RefineSearchComponent , {
            showHeader: false,
            ...this.appService.dynamicDialogConfig,
            data: {
                module: this.searcher.SearchProjectCompany.value ,
                filters: this.totalFilters ? this.filters : [] ,
                favoriteOnly: this.favouriteOnly
            },
            height: '85%'
        }).onClose.pipe(takeUntil(this.componentInView)).subscribe(data => {
            this.utilsService.clearErrorMessages();
            this.fullLayoutComponent.dialogOpen = false;
            if(data) {
                this.setRefineSearchData(data);
            }
        });
      }
    }

    setRefineSearchData(data) {
        this.searcher.Search = [];

        this.filters = data.Query;

        this.favouriteOnly = data.FavouriteOnly;

        this.onSearchClick( false);
    }

    onSearchClick(showHiddenFilters) {
        this.utilsService.clearErrorMessages();

        this.showMore = showHiddenFilters;

        let params = {
            offset: 0,
            // limit: 10,
            favourite: this.favouriteOnly ? true : undefined,
            query: this.filters && Object.keys(this.filters).length > 0 ?encodeURIComponent(JSON.stringify(this.filters)) : null,
            sort: '-project_value,project_id',
            filters : null,
        };

        if(this.isCIS()) {
          params.query = null;
          params.filters = this.cisFilterService.searchFilters;
          this.getCISChips();
          params.sort= null;
        }

        const searchFilters = {
            module: this.searcher.SearchProjectCompany.value,
            params: params,
            filters: this.filters
        };

        this.search.emit(searchFilters);
    }

    onRemoveFilterParam(event) {
        if(event.value === 'FavouriteOnly: true') {
            this.favouriteOnly = false;
            this.totalFilters--;
            return;
        }

        if(this.isCIS()) {
          let key = event.value.split(":")[0];
          this.filters = this.cisFilterService.removeFiltersFromSearch(key);
          this.getCISChips();

        } else {
          this.filters = this.appService.removeFilterParam(event.value , this.filters);
          this.searcher.Search = this.appService.setFilteredParamsForSearchField(this.filters);
          this.totalFilters = this.appService.calculateTotalNumberOfFiltersApplied(this.filters);
          if( this.searcher.Search.includes('FavouriteOnly : true')){
            this.totalFilters++;
        }
        }
    }

    onClearClick() {
        this.utilsService.clearErrorMessages();
        this.searcher.Search = null;
        this.totalFilters = 0;
        this.filters = null;
        if(this.isCIS()) {
          this.cisFilterService.resetSearchFilters();
        } else {
        localStorage.setItem('Includerefusedwithdrawnabandonedandonhold', JSON.stringify(false));
        }
    }

    handleKeyUp(event) {
        if(event.keyCode === 13) {
            this.onSearchClick(true);
        }
    }

    ngOnDestroy() {
        this.utilsService.unsubscribeSubscriptions(this.subscriptions);
    }


    getCISChips() {
      this.filters = this.cisFilterService.searchFilters.fields;
      this.searcher.Search = this.cisFilterService.getSearchChips();
      this.totalFilters = this.cisFilterService.searchFilters?.fields?.length;
    }

    isCIS() {
      return this.appService.isCIS();
    }
}
