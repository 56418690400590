<p-messages [(value)]="errorMsgs" *ngIf="errorMsgs && errorMsgs.length > 0"></p-messages>
<!-- {PREOPEN:db182f05-e674-404a-882e-96991cfc14de} {"sha1":"8C4A0E2E0EFD768CAB5850B359AF669A56A45AB4"} -->
<form #form="ngForm" [class.pointer-events-none]="this.showLoader">
	<!-- {OPEN:db182f05-e674-404a-882e-96991cfc14de} {} -->
	<!-- {OPEN:dabfcd9f-a881-4f63-b29c-4e7dd5241315} {} -->
	<!-- {PREOPEN:f907845e-8528-4b67-a04d-96b40d6c3d8e} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
	<div class="ui-g form-group">
		<!-- {OPEN:f907845e-8528-4b67-a04d-96b40d6c3d8e} {} -->
		<!-- {PREOPEN:8bd2708f-7e36-4eb5-8675-db7d68f3ccce} {"sha1":"EA45B5538DA9EF952A17324A1926BE826076CCEE"} -->
		<div class="ui-g-12 ui-md-6">
			<!-- {OPEN:8bd2708f-7e36-4eb5-8675-db7d68f3ccce} {} -->
			<!-- {OPEN:c6337878-94f5-41a5-bb15-9c3df7ce11b7} {"sha1":"B5EC3A9E2DBBF5D7099C7C54DF67943CE8ECC869"} -->
			<div class="ui-fluid">
                <span class="ui-float-label sticky">
                    <input required [(ngModel)]="saveSearch.saved_search_name? saveSearch.saved_search_name : saveSearch.query.name" name="EnterSearchName{{ searchSave.ObjectID }}" fieldLabel="Search Name" type="text" pInputText class="ui-inputtext">
                    <label>Enter Search Name</label>
                </span>
			</div>
			<!-- {CLOSE:c6337878-94f5-41a5-bb15-9c3df7ce11b7} {"sha1":"B5EC3A9E2DBBF5D7099C7C54DF67943CE8ECC869"} -->
			<!-- {CLOSE:8bd2708f-7e36-4eb5-8675-db7d68f3ccce} {} -->
		</div>
		<!-- {POSTCLOSE:8bd2708f-7e36-4eb5-8675-db7d68f3ccce} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
		<!-- {PREOPEN:e00ba5a5-9acc-4d49-977c-1ae7ebdbea84} {"sha1":"EA45B5538DA9EF952A17324A1926BE826076CCEE"} -->
		<div class="ui-g-12 ui-md-6">
			<!-- {OPEN:e00ba5a5-9acc-4d49-977c-1ae7ebdbea84} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
			<!-- {CLOSE:e00ba5a5-9acc-4d49-977c-1ae7ebdbea84} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
		</div>
		<!-- {POSTCLOSE:e00ba5a5-9acc-4d49-977c-1ae7ebdbea84} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
		<!-- {CLOSE:f907845e-8528-4b67-a04d-96b40d6c3d8e} {} -->
	</div>
	<!-- {POSTCLOSE:f907845e-8528-4b67-a04d-96b40d6c3d8e} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
	<!-- {CLOSE:dabfcd9f-a881-4f63-b29c-4e7dd5241315} {} -->
	<!-- {OPEN:24c3e6f2-64f9-4efc-ad0b-200667e7146f} {} -->
	<!-- {PREOPEN:03f7e293-f58c-4e65-947c-c4702370110e} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
	<div class="ui-g form-group">
		<!-- {OPEN:03f7e293-f58c-4e65-947c-c4702370110e} {} -->
		<!-- {PREOPEN:75fb0ef5-3464-4e3f-9ab1-62f58ebd8dd3} {"sha1":"BF98E243D90A3FDE39FE41DE5FABB43C26BDEF12"} -->
		<div class="ui-g-12 ui-md-2">
			<!-- {OPEN:75fb0ef5-3464-4e3f-9ab1-62f58ebd8dd3} {} -->
			<!-- {OPEN:4bf07724-faef-4b11-b6f6-7744fd7d0322} {"sha1":"BFDEDFBB8093D2734BD41FDD114A145A5A9A7C5E"} -->
			<button class="buttonStyle" pButton type="button" label="Save" [style.width]="'150px'" (click)="onSaveClick($event, searchSave)"></button>
			<!-- {CLOSE:4bf07724-faef-4b11-b6f6-7744fd7d0322} {"sha1":"BFDEDFBB8093D2734BD41FDD114A145A5A9A7C5E"} -->
			<!-- {CLOSE:75fb0ef5-3464-4e3f-9ab1-62f58ebd8dd3} {} -->
		</div>
		<!-- {POSTCLOSE:75fb0ef5-3464-4e3f-9ab1-62f58ebd8dd3} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
		<!-- {PREOPEN:4cf55665-783d-4fdd-9533-f932d751fd2f} {"sha1":"2183A3A9ABCF2FD844436EF69F76172FF147BB12"} -->
		<div class="ui-g-12 ui-md-4">
			<!-- {OPEN:4cf55665-783d-4fdd-9533-f932d751fd2f} {} -->
			<!-- {OPEN:b5bcab58-254e-4a92-9ca5-d68ccb3d2759} {"sha1":"A18A4CBC9781B15519167F2E5FF97F4E2661BEFE"} -->
			<button pButton type="button" class="btn-tertiary buttonStyle" label="Cancel" [style.width]="'150px'" (click)="onCancelClick($event, searchSave)"></button>
			<!-- {CLOSE:b5bcab58-254e-4a92-9ca5-d68ccb3d2759} {"sha1":"A18A4CBC9781B15519167F2E5FF97F4E2661BEFE"} -->
			<!-- {CLOSE:4cf55665-783d-4fdd-9533-f932d751fd2f} {} -->
		</div>
		<!-- {POSTCLOSE:4cf55665-783d-4fdd-9533-f932d751fd2f} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
		<!-- {PREOPEN:0ecb4606-841f-461d-a97d-3c1035539d04} {"sha1":"BF98E243D90A3FDE39FE41DE5FABB43C26BDEF12"} -->
		<div class="ui-g-12 ui-md-2">
			<!-- {OPEN:0ecb4606-841f-461d-a97d-3c1035539d04} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
			<!-- {CLOSE:0ecb4606-841f-461d-a97d-3c1035539d04} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
		</div>
		<!-- {POSTCLOSE:0ecb4606-841f-461d-a97d-3c1035539d04} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
		<!-- {PREOPEN:730d440a-f0f8-4743-b8cc-b93845dad0b1} {"sha1":"2183A3A9ABCF2FD844436EF69F76172FF147BB12"} -->
		<div class="ui-g-12 ui-md-4">
			<!-- {OPEN:730d440a-f0f8-4743-b8cc-b93845dad0b1} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
			<!-- {CLOSE:730d440a-f0f8-4743-b8cc-b93845dad0b1} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
		</div>
		<!-- {POSTCLOSE:730d440a-f0f8-4743-b8cc-b93845dad0b1} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
		<!-- {CLOSE:03f7e293-f58c-4e65-947c-c4702370110e} {} -->
	</div>
	<!-- {POSTCLOSE:03f7e293-f58c-4e65-947c-c4702370110e} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
	<!-- {CLOSE:24c3e6f2-64f9-4efc-ad0b-200667e7146f} {} -->
	<p-progressSpinner *ngIf="showLoader"></p-progressSpinner>
	<!-- {CLOSE:db182f05-e674-404a-882e-96991cfc14de} {} -->
</form>
<!-- {POSTCLOSE:db182f05-e674-404a-882e-96991cfc14de} {"sha1":"3FD6E659562C1876D02FC8CF54746B1502442CD2"} -->
