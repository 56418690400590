import { Observable } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BarbourUtilServiceService {

  constructor(private appService: AppService) { }


  dataProvider = this.appService.getSelectedDataProvider();


  getSavedSearches(module): Observable<any> {
    return this.appService.getSavedSearches(`${this.appService.getSelectedDataProvider()}/saved_searches` , null);
  }

  getCacheFilters(): Observable<any> {
    return this.appService.getCacheFilters(`${this.appService.getSelectedDataProvider()}/cache` , null);
  }

  getRelativeDateFilterText(value) {

    switch(value) {
      case '-1d':
        return 'Yesterday';
      case '-7d':
        return 'In the last Week';
      case '-1':
        return 'In the last Month';
      case '-2':
        return 'In the last 2 Months';
      case '-3':
        return 'In the last 3 Months';
      case '-6':
        return 'In the last 6 Months';
      case '7d':
        return 'In the next Week';
      case '1':
        return 'In the next Month';
      case '2':
        return 'In the next 2 Months';
      case '3':
        return 'In the next 3 Months';
      case '6':
        return 'In the next 6 Months';
    }

  }
  getDisplayValuesForFilter(filterMap) {
    if(!filterMap.hasOwnProperty('filterType')) {
      return '';
    }
    switch(filterMap.filterType) {
      case 'Compare':
        if (filterMap.hasOwnProperty('value') && filterMap.value != null && filterMap.value !==undefined && filterMap.value != "")
          return filterMap.value;
        else return filterMap.idValue;
      case 'Between':
        return filterMap.filterType + " " + filterMap.from + ' - ' + filterMap.to;
      case 'RelativeDate':
        return this.getRelativeDateFilterText(filterMap.value);
      case 'LessThan':
        return filterMap.filterType + " " + filterMap.value;
      case 'GreaterThan':
        return filterMap.filterType + " " + filterMap.value;
      case 'GeographicalRange':
        return 'Lat: ' + filterMap.lat + ', Lng: ' + filterMap.lng + ', Range: ' + filterMap.range;
    }
  }


}
