export class CISDate {
  from;
  to;
  selected = '';



  constructor() {

  }



}
