<section class="add-org">
  <div layout="row" flex>
  <mat-form-field appearance="outline">
    <mat-label>Organization Name</mat-label>
    <input
      matInput
      [(ngModel)]="addOrgTitle"
    />
  </mat-form-field>
  <button mat-raised-button class="demo-button" (click)="addOrganization()">
    Add Organization
  </button>

</div>
</section>
<br/>
<table mat-table [dataSource]="organizations" class="mat-elevation-z8">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <ng-container matColumnDef="orgId">
    <th mat-header-cell *matHeaderCellDef>ID</th>
    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
  </ng-container>

  <ng-container matColumnDef="created">
    <th mat-header-cell *matHeaderCellDef>Created At</th>
    <td mat-cell *matCellDef="let element"> {{element.createdAt}} </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let element">
      <a [routerLink]="element.id">
        <button mat-raised-button class="demo-button">
          View Org
        </button>
      </a>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
