import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { UtilsService } from './utils.service';

@Injectable()
export class SaveService {

    constructor(
        private apiService: ApiService,
        private utilsService: UtilsService,
    ) {

    }

    saveObjectsWithDefaultSvc(createdObjs: any, updatedObjs: any, deletedObjs: any): Observable<any> {
        return this.saveObjects('svc/Save', createdObjs, updatedObjs, deletedObjs);
    }

    saveObjects(serviceName: string, createdObjs: any, updatedObjs: any, deletedObjs: any, additionalParams: any = {}, replaceParams: boolean = true): Observable<any> {
        let params: any = {
            environment: environment.envName
        }
        if(additionalParams){
            for(let key in additionalParams){
                params[key] =   replaceParams ? this.utilsService.replaceObjectsWithIDIfExists(additionalParams[key]) : additionalParams[key];
            }
        }
        if (createdObjs) {
            let updatedCreateObjs: any = {};
            var keys: string[] = Object.keys(createdObjs);

            keys.forEach(key => {
                updatedCreateObjs[key] = this.utilsService.replaceObjectsWithIDIfExists(createdObjs[key]);
            });
            params.create = updatedCreateObjs;
        }

        if (updatedObjs) {
            let updatedUpdateObjs: any = {};
            var keys: string[] = Object.keys(updatedObjs);

            keys.forEach(key => {
                updatedUpdateObjs[key] = this.utilsService.replaceObjectsWithIDIfExists(updatedObjs[key]);
            });
            params.update = updatedUpdateObjs;
        }
        if (deletedObjs) {
            params.delete = deletedObjs;
        }
        return this.apiService.post(serviceName, params).pipe(
            map(
                data => {
                    if (this.utilsService.isSuccessfulResponse(data)) {
                        return data;
                    }
                }
            ));
    }
}