/* {OPEN:IMPORT} {"sha1":"27D91630244AC93316847A2AE7D49E366BF4B082"} */
import { Component, DoCheck, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import { Message, MenuItem, ConfirmationService } from 'primeng/api';
import { AppService } from './../../app.service';
import { OptionsObservable, OptionsWrapper } from './../../oneit/models/options.model';
import { UserService } from './../../oneit/services/user.service';
import { UtilsService } from './../../oneit/services/utils.service';
import { SearchService } from './../../oneit/services/search.service';
import { ProjectDealPopUpService } from './project-deal-pop-up.service';
import { SearchProjectDealPopUp } from './project-deal-pop-up.model';
/* {CLOSE:IMPORT} {"sha1":"27D91630244AC93316847A2AE7D49E366BF4B082"} */

@Component({
    selector: 'app-base-project-deal-pop-up',
    template: ''
})
/* {PREOPEN:CLASS} {"sha1":"1209FE690FC5503343B10F336D822589BB6B9956"} */
export class BaseProjectDealPopUpComponent implements DoCheck, OnInit, OnDestroy {
    /* {OPEN:CLASS} {} */
    
    /* {OPEN:COMMON_VARS} {"sha1":"672A211DEEA9A3559D121CBDC5978BBA47BD6B7D"} */
    @Input() parent : any;
    pageTitle : string;
    /* {CLOSE:COMMON_VARS} {"sha1":"672A211DEEA9A3559D121CBDC5978BBA47BD6B7D"} */

    /* {OPEN:VARS} {"sha1":"10FA1C799A794984627765B90A59E8B161A0D2FE"} */
    searchProjectDealPopUp : SearchProjectDealPopUp = new SearchProjectDealPopUp();
    ownerOptions : any[] = [];
    isOwnerVolatile : boolean = false;
    stageOptions : any[] = [];
    isStageVolatile : boolean = false;
    includeCompanyasAccountOptions : any[] = [];
    isIncludeCompanyasAccountVolatile : boolean = false;
    /* {CLOSE:VARS} {"sha1":"10FA1C799A794984627765B90A59E8B161A0D2FE"} */
    
    showResults : boolean = false;
    showLoader : boolean = false;
    subscriptions : Array<Subscription> = [];

    constructor(
        /* {OPEN:constructor} {"sha1":"2AF4AD4268ABF720B3C30931D80971462D868BB9"} */
        public router : Router,
        public userService : UserService,
        public utilsService : UtilsService,
        public searchService : SearchService,
        public appService : AppService,
        public confirmationService: ConfirmationService,
        public projectDealPopUpService : ProjectDealPopUpService,
        /* {CLOSE:constructor} {"sha1":"2AF4AD4268ABF720B3C30931D80971462D868BB9"} */
    ){
        
    }

    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"5FBDAAB044BC4F999356808F755A7334E11EE273"} */
        this.resetInitVariables();
        this.calculateEverything();
        this.executeScreenSpecificInitCode();
        /* {CLOSE:ngOnInit} {"sha1":"5FBDAAB044BC4F999356808F755A7334E11EE273"} */
    }

    /* {PREOPEN:executeScreenSpecificInitCode} {"sha1":"DDD081544316B33777AB7B7FFD796FB9E663E681"} */
    executeScreenSpecificInitCode() {
        /* {OPEN:executeScreenSpecificInitCode} {"sha1":"54DB41AD32937D86C7318EB4BE666A1AD7B6B618"} */
        if(!this.parent) {
        }
        /* {CLOSE:executeScreenSpecificInitCode} {"sha1":"54DB41AD32937D86C7318EB4BE666A1AD7B6B618"} */
    }
    /* {POSTCLOSE:executeScreenSpecificInitCode} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getOwnerOptions} {"sha1":"B7C3D054D8C37125BCD0A6D2DCC8314937D74226"} */
    getOwnerOptions(): OptionsWrapper {
        /* {OPEN:getOwnerOptions} {"sha1":"2CCE92B016D8820895B6D0C37D467A12AC4638A2"} */
        return new OptionsObservable(false, this.appService.getOwnersObservable());
        /* {CLOSE:getOwnerOptions} {"sha1":"2CCE92B016D8820895B6D0C37D467A12AC4638A2"} */
    }
    /* {POSTCLOSE:getOwnerOptions} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:resetOwnerOptions} {"sha1":"C0002ADF0D36ECC9F333B937316FCE475A36793D"} */
    resetOwnerOptions() {
        /* {OPEN:resetOwnerOptions} {"sha1":"6C60EEA694426858FB1E15ADBD02AB094D5C5A04"} */
        if(this.parent) {
            this.parent.resetOwnerOptions();
        } else {
            let wrapper : OptionsWrapper = this.getOwnerOptions();

            if (wrapper) {
                this.isOwnerVolatile = wrapper.isVolatile;

                this.subscriptions.push(wrapper.getObservable()
                    .subscribe (
                        data => {
                            this.ownerOptions = data;
                        },
                        error => {
                            this.utilsService.handleError(error);
                        }
                    )
                );
            }
        }
        /* {CLOSE:resetOwnerOptions} {"sha1":"6C60EEA694426858FB1E15ADBD02AB094D5C5A04"} */
    }
    /* {POSTCLOSE:resetOwnerOptions} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onOwnerChange} {"sha1":"6DDDD5CAF024E0CFEF246797B8C20476BC97D801"} */
    onOwnerChange(event, searchProjectDealPopUp) {
        /* {OPEN:onOwnerChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:onOwnerChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:onOwnerChange} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getStageOptions} {"sha1":"D6745B97AEE96CF6DC8D0413F9BA7FBFA8CF36BD"} */
    getStageOptions(): OptionsWrapper {
        /* {OPEN:getStageOptions} {"sha1":"591753F8E8D98BCFF335AC2891B8E3C742827D5D"} */
        return new OptionsObservable(false, this.appService.getDealStagesObservable());
        /* {CLOSE:getStageOptions} {"sha1":"591753F8E8D98BCFF335AC2891B8E3C742827D5D"} */
    }
    /* {POSTCLOSE:getStageOptions} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:resetStageOptions} {"sha1":"268A7D0AC27CBB34D7E13CB2034D9192462B073B"} */
    resetStageOptions() {
        /* {OPEN:resetStageOptions} {"sha1":"8A96B133CC754F383E69B2E204D8FE5110C2EE71"} */
        if(this.parent) {
            this.parent.resetStageOptions();
        } else {
            let wrapper : OptionsWrapper = this.getStageOptions();

            if (wrapper) {
                this.isStageVolatile = wrapper.isVolatile;

                this.subscriptions.push(wrapper.getObservable()
                    .subscribe (
                        data => {
                            this.stageOptions = data;
                        },
                        error => {
                            this.utilsService.handleError(error);
                        }
                    )
                );
            }
        }
        /* {CLOSE:resetStageOptions} {"sha1":"8A96B133CC754F383E69B2E204D8FE5110C2EE71"} */
    }
    /* {POSTCLOSE:resetStageOptions} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onStageChange} {"sha1":"CF3DD1C4EF2857627E527DC5B1E2BD706737B04B"} */
    onStageChange(event, searchProjectDealPopUp) {
        /* {OPEN:onStageChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:onStageChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:onStageChange} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getIncludeCompanyasAccountOptions} {"sha1":"AEDF1391AD69A644E837003CF690376CC386B1C0"} */
    getIncludeCompanyasAccountOptions(): OptionsWrapper {
        /* {OPEN:getIncludeCompanyasAccountOptions} {"sha1":"9107E7B641B74D29C83F10727C13F5AF29A7EFD7"} */
        return new OptionsObservable(false, this.appService.getIncludeCompanyAsAccountsObservable());
        /* {CLOSE:getIncludeCompanyasAccountOptions} {"sha1":"9107E7B641B74D29C83F10727C13F5AF29A7EFD7"} */
    }
    /* {POSTCLOSE:getIncludeCompanyasAccountOptions} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:resetIncludeCompanyasAccountOptions} {"sha1":"6358462B7344FCB96C0B3C9AEA51AF0661479E8F"} */
    resetIncludeCompanyasAccountOptions() {
        /* {OPEN:resetIncludeCompanyasAccountOptions} {"sha1":"D60D6F0A793EB642C2F6535C118E39BCCF2752F5"} */
        if(this.parent) {
            this.parent.resetIncludeCompanyasAccountOptions();
        } else {
            let wrapper : OptionsWrapper = this.getIncludeCompanyasAccountOptions();

            if (wrapper) {
                this.isIncludeCompanyasAccountVolatile = wrapper.isVolatile;

                this.subscriptions.push(wrapper.getObservable()
                    .subscribe (
                        data => {
                            this.includeCompanyasAccountOptions = data;
                        },
                        error => {
                            this.utilsService.handleError(error);
                        }
                    )
                );
            }
        }
        /* {CLOSE:resetIncludeCompanyasAccountOptions} {"sha1":"D60D6F0A793EB642C2F6535C118E39BCCF2752F5"} */
    }
    /* {POSTCLOSE:resetIncludeCompanyasAccountOptions} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onIncludeCompanyasAccountChange} {"sha1":"C45D45473C0716F4933B15B2CF32B2848B6FB6DA"} */
    onIncludeCompanyasAccountChange(event, searchProjectDealPopUp) {
        /* {OPEN:onIncludeCompanyasAccountChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:onIncludeCompanyasAccountChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:onIncludeCompanyasAccountChange} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onCreateDealClick} {"sha1":"E5851715109B7C3A2B32BF301B7D47935A7C516A"} */
    onCreateDealClick(event, searchProjectDealPopUp) {
        /* {OPEN:onCreateDealClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onCreateDealClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onCreateDealClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:resetInitVariables} {"sha1":"846C0DF64879342578D595FE17BC00B96F0BAED6"} */
    resetInitVariables() {
        /* {OPEN:resetInitVariables} {} */
        /* {OPEN:resetInitVariables_resetOwnerOptions} {"sha1":"7632B5F0C727E48D4A9E49547CFB1612150AA57C"} */
        try {
            this.resetOwnerOptions();
        }
        catch (error) {
            console.error('Error occured when executing resetOwnerOptions', error);
        }
        /* {CLOSE:resetInitVariables_resetOwnerOptions} {"sha1":"7632B5F0C727E48D4A9E49547CFB1612150AA57C"} */
        /* {OPEN:resetInitVariables_resetStageOptions} {"sha1":"C616D87E1BE44DDDAE700ABF30854423139380BF"} */
        try {
            this.resetStageOptions();
        }
        catch (error) {
            console.error('Error occured when executing resetStageOptions', error);
        }
        /* {CLOSE:resetInitVariables_resetStageOptions} {"sha1":"C616D87E1BE44DDDAE700ABF30854423139380BF"} */
        /* {OPEN:resetInitVariables_resetIncludeCompanyasAccountOptions} {"sha1":"4E7988689F267A6A67904B6902518171B4773241"} */
        try {
            this.resetIncludeCompanyasAccountOptions();
        }
        catch (error) {
            console.error('Error occured when executing resetIncludeCompanyasAccountOptions', error);
        }
        /* {CLOSE:resetInitVariables_resetIncludeCompanyasAccountOptions} {"sha1":"4E7988689F267A6A67904B6902518171B4773241"} */
        /* {CLOSE:resetInitVariables} {} */
    }
    /* {POSTCLOSE:resetInitVariables} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getPageTitle} {"sha1":"174A3D30FFA103DD7BE180EE89CC0CF5EB6D11B8"} */
    getPageTitle() {
        /* {OPEN:getPageTitle} {"sha1":"AD2897E7DECB79889FF7C635B0F05323760698DC"} */
        return 'Project deal POP-UP';
        /* {CLOSE:getPageTitle} {"sha1":"AD2897E7DECB79889FF7C635B0F05323760698DC"} */
    }
    /* {POSTCLOSE:getPageTitle} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:calculateEverything} {"sha1":"CE17BF2375171825A37C544A1DCBF3A565DD5181"} */
    calculateEverything() {
        /* {OPEN:calculateEverything} {} */
        /* {OPEN:calculateEverything_pageTitle} {"sha1":"B3CA5EAE3ED54D1F0D95EA2CD05351A4A6D7480F"} */
        try {
            this.pageTitle = this.getPageTitle();
        }
        catch (error) {
            console.error('Error occured when setting pageTitle');
        }
        /* {CLOSE:calculateEverything_pageTitle} {"sha1":"B3CA5EAE3ED54D1F0D95EA2CD05351A4A6D7480F"} */
        /* {OPEN:calculateEverything_isOwnerVolatile} {"sha1":"0EA14568C9FACF325AE62E1AA25205C4BF7C3CB5"} */
        try {
            if(this.isOwnerVolatile) {
                this.resetOwnerOptions();
            }
        }
        catch (error) {
            console.error('Error occured when resetting ownerOptions');
        }
        /* {CLOSE:calculateEverything_isOwnerVolatile} {"sha1":"0EA14568C9FACF325AE62E1AA25205C4BF7C3CB5"} */
        /* {OPEN:calculateEverything_isStageVolatile} {"sha1":"035D97D9D2BB7D7DAB800E939FA96CF515C6EEC0"} */
        try {
            if(this.isStageVolatile) {
                this.resetStageOptions();
            }
        }
        catch (error) {
            console.error('Error occured when resetting stageOptions');
        }
        /* {CLOSE:calculateEverything_isStageVolatile} {"sha1":"035D97D9D2BB7D7DAB800E939FA96CF515C6EEC0"} */
        /* {OPEN:calculateEverything_isIncludeCompanyasAccountVolatile} {"sha1":"EC1913BBB9A44C6DEF52271F8130A05AA0E2EED4"} */
        try {
            if(this.isIncludeCompanyasAccountVolatile) {
                this.resetIncludeCompanyasAccountOptions();
            }
        }
        catch (error) {
            console.error('Error occured when resetting includeCompanyasAccountOptions');
        }
        /* {CLOSE:calculateEverything_isIncludeCompanyasAccountVolatile} {"sha1":"EC1913BBB9A44C6DEF52271F8130A05AA0E2EED4"} */
        /* {CLOSE:calculateEverything} {} */
    }
    /* {POSTCLOSE:calculateEverything} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:ngDoCheck} {"sha1":"75839B27D41E20648CE296AC51D61E24D541A70A"} */
    ngDoCheck() {
        /* {OPEN:ngDoCheck} {"sha1":"A154E0663059B6D385B4F6C11094F2084FF5E184"} */
        this.calculateEverything();
        /* {CLOSE:ngDoCheck} {"sha1":"A154E0663059B6D385B4F6C11094F2084FF5E184"} */
    }
    /* {POSTCLOSE:ngDoCheck} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:ngOnDestroy} {"sha1":"C21B2137D2BD86DF7050E24A39F08355F04DA4E9"} */
    ngOnDestroy() {
        /* {OPEN:ngOnDestroy} {"sha1":"85AA65230B2C97013ABDD30D48BEB93C04E8EE54"} */
        this.utilsService.unsubscribeSubscriptions(this.subscriptions);
        /* {CLOSE:ngOnDestroy} {"sha1":"85AA65230B2C97013ABDD30D48BEB93C04E8EE54"} */
    }
    /* {POSTCLOSE:ngOnDestroy} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {CLOSE:CLASS} {} */
}
/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */
