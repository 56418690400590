import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UserService } from '../../services/user.service';
import { FeedbackInfo } from './feedback.model';
import { FeedbackService } from './feedback.service';
declare function feedback({ }): any;

@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FeedbackComponent implements OnInit {

    @Input() overrideParams: any;

    constructor(
        private feedbackService: FeedbackService,
        private userService: UserService
    ) { }

    ngOnInit() {
        if(!this.feedbackService.feedbackInitiated) {   //This check will make sure that we dont call feeback() twice even when FullLayoutComponent is initialised
            this.feedbackService.getFeedbackInfo().subscribe(
                data => {
                    let feedbackInfo: FeedbackInfo = data;

                    if(this.overrideParams && feedbackInfo.extraPostParams) {
                        Object.assign(feedbackInfo.extraPostParams, this.overrideParams);
                    }

                    if (feedbackInfo.enabled && (!feedbackInfo.extraPostParams.userBasedVisibility || this.userService.hasPrivilege('showFeedbackTool'))) {
                        this.feedbackService.feedbackInitiated  =   true;
                        feedback(feedbackInfo);
                    }
                },
                error => {
                    console.log(error);
                }
            );
        }
    }
}