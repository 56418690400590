import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
    selector: '[oneitemail][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: EmailValidatorDirective, multi: true }
    ]
})
export class EmailValidatorDirective implements Validator {
    constructor() { }

    validate(c: AbstractControl): { [key: string]: any } {
        if (!c.value || /^([a-zA-Z0-9_\-\.'])+@([a-zA-Z0-9\-])+(\.[a-zA-Z0-9\-]+)+$/.test(c.value)) {
            return null;
        } else {
            return {
                email: {
                    valid: false
                }
            };
        }
    }
}