/* {OPEN:IMPORT} {"sha1":"3478769087F2BB94F1CFEC327A9E151C5C84B70D"} */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseSaveSearchComponent } from './base-save-search.component';
/* {CLOSE:IMPORT} {"sha1":"3478769087F2BB94F1CFEC327A9E151C5C84B70D"} */
import { ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../oneit/services/user.service';
import { UtilsService } from '../../oneit/services/utils.service';
import { SearchService } from '../../oneit/services/search.service';
import { AppService } from '../../app.service';
import { ConfirmationService } from 'primeng/api';
import { SaveSearchService } from './save-search.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng';
import { MsgsService } from '../../oneit/services/msgs.service';
import { SavedSearchesService } from '../saved-searches/saved-searches.service';
import { CisUtilServiceService } from 'src/app/cbos/cis/cis-util-service.service';
import { BarbourUtilServiceService } from 'src/app/cbos/barbour/barbour-util-service.service';
/* {POSTCLOSE:IMPORT} {"sha1":"3478769087F2BB94F1CFEC327A9E151C5C84B70D"} */

/* {PREOPEN:CLASS} {"sha1":"8359D4CE98F43CFF3EDCEC80CA5CF7548AD93A28"} */
@Component({
    selector: 'app-save-search',
    templateUrl: './save-search.component.html'
})
export class SaveSearchComponent extends BaseSaveSearchComponent implements OnInit, OnDestroy {
    /* {OPEN:CLASS} {} */

    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
        super.ngOnInit();
        /* {CLOSE:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
    }

    ngOnDestroy() {
        /* {OPEN:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
        super.ngOnDestroy();
        /* {CLOSE:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
    }

    /* {CLOSE:CLASS} {} */

    @ViewChild('form') form: NgForm;
    errorMsgs = [];
    saveSearch;
    savedSearches;
    dataProvider;
    //override
    constructor(
        public router : Router,
        public userService : UserService,
        public utilsService : UtilsService,
        public searchService : SearchService,
        public appService : AppService,
        public confirmationService: ConfirmationService,
        public saveSearchService : SaveSearchService,
        public dialogRef :  DynamicDialogRef,
        public dialogConfig: DynamicDialogConfig,
        public msgsService : MsgsService,
        public savedSearchesService : SavedSearchesService,
        public cisService : CisUtilServiceService,
        public barbourService : BarbourUtilServiceService
    ) {
        super(router , userService , utilsService , searchService , appService , confirmationService , saveSearchService);
    }

    //@override
    executeScreenSpecificInitCode() {
        this.saveSearch = this.dialogConfig.data;
        this.dataProvider = this.appService.getSelectedDataProvider();
        console.log(this.saveSearch)

        this.subscriptions.push(
          this.getSearchService().getSavedSearches(this.appService.selectedModule).subscribe(data => {
              this.showLoader = false;
              this.savedSearches = data;
          }, error => {
              this.showLoader = false;
              this.utilsService.handleError(error);
          })
      );

        this.subscriptions.push(
            this.msgsService.errorMsgsUpdated.subscribe(errorMsgs => {
                this.errorMsgs = errorMsgs;
            })
        );
    }

    getSearchService() {

      switch(this.dataProvider) {
        case 'barbour':
          return this.barbourService
        case 'cis':
        return this.cisService;
      }

    }
    //@override
    onSaveClick(event, searchSave) {
        this.utilsService.clearErrorMessages();
        console.log(searchSave)

        if(!this.form.valid) {
            this.utilsService.showAllErrorMessages();
            return;
        }

        this.showLoader = true;

        if(this.saveSearch.saved_search_id) {
            this.updateSavedSearch();
        }

        if(!this.saveSearch.saved_search_id) {
            this.createSavedSearch();
        }
    }

    updateSavedSearch() {
        this.subscriptions.push(
            this.saveSearchService.updateSavedSearch(this.saveSearch).subscribe(() => {
                this.showLoader = false;
                this.utilsService.handleSuccessMessage('Search saved successfully');
                this.dialogRef.close();
            }, error => {
                this.showLoader = false;
                this.utilsService.handleError(error);
            })
        );
    }

    createSavedSearch() {
        this.subscriptions.push(
            this.saveSearchService.createSavedSearch(this.saveSearch).subscribe(() => {
                this.showLoader = false;
                this.utilsService.handleSuccessMessage('search saved created successfully');
                this.dialogRef.close();
            }, error => {
                this.showLoader = false;
                this.utilsService.handleError(error);
            })
        );
    }

    //@override
    onCancelClick(event, searchSave) {
        this.utilsService.clearErrorMessages();
        this.dialogRef.close();
    }
}
/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */
