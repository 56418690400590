import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Constants } from '../consts';
import { BaseFilterComponent } from '../../base-filter/base-filter.component';


@Component({
  selector: 'cis-tracking-filter',
  templateUrl: './cis-tracking-filter.component.html',
  styleUrls: ['./cis-tracking-filter.component.scss']
})
export class CISTrackingFilterComponent extends BaseFilterComponent implements OnInit {

  constructor() {
    super();
  }

  @Input()
  trackingStatus = '';

  dateFilter = '';


  dropdownOptions = Constants.TRACKING_OPTIONS;

  ngOnInit(): void {
    this.dateFilter = this.trackingStatus;
  }

  asIsOrder(a, b) {return 1;}


  onChange(target): void {
    this.emitValues();
  }



  emitValues() {
      super.emitValues(this.dateFilter);
  }

  //  @override
  onClear() {
    this.dateFilter = '';
  }


}
