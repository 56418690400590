import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from './../services/utils.service';

@Pipe({
    name: 'sort',
    pure: true
})
export class SortPipe implements PipeTransform {

    constructor(
        private utilsService: UtilsService
    ) {

    }

    transform(array: any[], fields: any[], orders: number[] = [], nullAtTop?: boolean): any {
        return this.utilsService.sort(array, fields, orders, nullAtTop);
    }
}