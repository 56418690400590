/* {OPEN:IMPORT} {"sha1":"39D70AE21E7CF4C1F082BE657F37B2E8D7BEE495"} */
import { Component, DoCheck, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import { Message, MenuItem, ConfirmationService } from 'primeng/api';
import { AppService } from './../../app.service';
import { OptionsObservable, OptionsWrapper } from './../../oneit/models/options.model';
import { UserService } from './../../oneit/services/user.service';
import { UtilsService } from './../../oneit/services/utils.service';
import { SearchService } from './../../oneit/services/search.service';
import { LoginPageService } from './login-page.service';
import { SearchLoginPage } from './login-page.model';
/* {CLOSE:IMPORT} {"sha1":"39D70AE21E7CF4C1F082BE657F37B2E8D7BEE495"} */

@Component({
    selector: 'app-base-login-page',
    template: ''
})
/* {PREOPEN:CLASS} {"sha1":"32CAAF9A506BDEED52BB66853730FCA4CBE75A88"} */
export class BaseLoginPageComponent implements DoCheck, OnInit, OnDestroy {
    /* {OPEN:CLASS} {} */
    
    /* {OPEN:COMMON_VARS} {"sha1":"672A211DEEA9A3559D121CBDC5978BBA47BD6B7D"} */
    @Input() parent : any;
    pageTitle : string;
    /* {CLOSE:COMMON_VARS} {"sha1":"672A211DEEA9A3559D121CBDC5978BBA47BD6B7D"} */

    /* {OPEN:VARS} {"sha1":"4DD5561AC0F59DE3128C5620BCF3EACA439052AF"} */
    searchLoginPage : SearchLoginPage = new SearchLoginPage();
    /* {CLOSE:VARS} {"sha1":"4DD5561AC0F59DE3128C5620BCF3EACA439052AF"} */
    
    showResults : boolean = false;
    showLoader : boolean = false;
    subscriptions : Array<Subscription> = [];

    constructor(
        /* {OPEN:constructor} {"sha1":"E05F5EE98B4D865E254A6F2DDBB690D2BC121BC5"} */
        public router : Router,
        public userService : UserService,
        public utilsService : UtilsService,
        public searchService : SearchService,
        public appService : AppService,
        public confirmationService: ConfirmationService,
        public loginPageService : LoginPageService,
        /* {CLOSE:constructor} {"sha1":"E05F5EE98B4D865E254A6F2DDBB690D2BC121BC5"} */
    ){
        
    }

    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"5FBDAAB044BC4F999356808F755A7334E11EE273"} */
        this.resetInitVariables();
        this.calculateEverything();
        this.executeScreenSpecificInitCode();
        /* {CLOSE:ngOnInit} {"sha1":"5FBDAAB044BC4F999356808F755A7334E11EE273"} */
    }

    /* {PREOPEN:executeScreenSpecificInitCode} {"sha1":"DDD081544316B33777AB7B7FFD796FB9E663E681"} */
    executeScreenSpecificInitCode() {
        /* {OPEN:executeScreenSpecificInitCode} {"sha1":"54DB41AD32937D86C7318EB4BE666A1AD7B6B618"} */
        if(!this.parent) {
        }
        /* {CLOSE:executeScreenSpecificInitCode} {"sha1":"54DB41AD32937D86C7318EB4BE666A1AD7B6B618"} */
    }
    /* {POSTCLOSE:executeScreenSpecificInitCode} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onSignMeInClick} {"sha1":"40ED6415ADF666C1E3E95667612D4C42AC19309F"} */
    onSignMeInClick(event, searchLoginPage) {
        /* {OPEN:onSignMeInClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onSignMeInClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onSignMeInClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRouterLinkForForgotPassword} {"sha1":"A0ADB5748F3E6E4903E3D96D2AC9F6E774443F01"} */
    getRouterLinkForForgotPassword(searchLoginPage) {
        /* {OPEN:getRouterLinkForForgotPassword} {"sha1":"88413CC33D8868FD34E770A1DE84907D854B7011"} */
        return ['/forgot-password'];
        /* {CLOSE:getRouterLinkForForgotPassword} {"sha1":"88413CC33D8868FD34E770A1DE84907D854B7011"} */
    }
    /* {POSTCLOSE:getRouterLinkForForgotPassword} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:resetInitVariables} {"sha1":"846C0DF64879342578D595FE17BC00B96F0BAED6"} */
    resetInitVariables() {
        /* {OPEN:resetInitVariables} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:resetInitVariables} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:resetInitVariables} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getPageTitle} {"sha1":"174A3D30FFA103DD7BE180EE89CC0CF5EB6D11B8"} */
    getPageTitle() {
        /* {OPEN:getPageTitle} {"sha1":"2319264CE12643DDB1AE8A1DD5A2B94C811CE972"} */
        return 'Login page';
        /* {CLOSE:getPageTitle} {"sha1":"2319264CE12643DDB1AE8A1DD5A2B94C811CE972"} */
    }
    /* {POSTCLOSE:getPageTitle} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:calculateEverything} {"sha1":"CE17BF2375171825A37C544A1DCBF3A565DD5181"} */
    calculateEverything() {
        /* {OPEN:calculateEverything} {} */
        /* {OPEN:calculateEverything_pageTitle} {"sha1":"B3CA5EAE3ED54D1F0D95EA2CD05351A4A6D7480F"} */
        try {
            this.pageTitle = this.getPageTitle();
        }
        catch (error) {
            console.error('Error occured when setting pageTitle');
        }
        /* {CLOSE:calculateEverything_pageTitle} {"sha1":"B3CA5EAE3ED54D1F0D95EA2CD05351A4A6D7480F"} */
        /* {CLOSE:calculateEverything} {} */
    }
    /* {POSTCLOSE:calculateEverything} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:ngDoCheck} {"sha1":"75839B27D41E20648CE296AC51D61E24D541A70A"} */
    ngDoCheck() {
        /* {OPEN:ngDoCheck} {"sha1":"A154E0663059B6D385B4F6C11094F2084FF5E184"} */
        this.calculateEverything();
        /* {CLOSE:ngDoCheck} {"sha1":"A154E0663059B6D385B4F6C11094F2084FF5E184"} */
    }
    /* {POSTCLOSE:ngDoCheck} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:ngOnDestroy} {"sha1":"C21B2137D2BD86DF7050E24A39F08355F04DA4E9"} */
    ngOnDestroy() {
        /* {OPEN:ngOnDestroy} {"sha1":"85AA65230B2C97013ABDD30D48BEB93C04E8EE54"} */
        this.utilsService.unsubscribeSubscriptions(this.subscriptions);
        /* {CLOSE:ngOnDestroy} {"sha1":"85AA65230B2C97013ABDD30D48BEB93C04E8EE54"} */
    }
    /* {POSTCLOSE:ngOnDestroy} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {CLOSE:CLASS} {} */
}
/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */