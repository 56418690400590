/* {OPEN:IMPORT} {"sha1":"E929DDF6586C76A6D0851806A1E287EFF86ACFEB"} */
import { Component, DoCheck, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import { Message, MenuItem, ConfirmationService } from 'primeng/api';
import { AppService } from './../../app.service';
import { OptionsObservable, OptionsWrapper } from './../../oneit/models/options.model';
import { UserService } from './../../oneit/services/user.service';
import { UtilsService } from './../../oneit/services/utils.service';
import { SearchService } from './../../oneit/services/search.service';
import { DataProviderCrmSelectionService } from './data-provider-crm-selection.service';
import { SearchDataProviderCrmSelection } from './data-provider-crm-selection.model';
/* {CLOSE:IMPORT} {"sha1":"E929DDF6586C76A6D0851806A1E287EFF86ACFEB"} */

@Component({
    selector: 'app-base-data-provider-crm-selection',
    template: ''
})
/* {PREOPEN:CLASS} {"sha1":"17F0B517BAC4F337E287C078F23D1000CD7BE491"} */
export class BaseDataProviderCrmSelectionComponent implements DoCheck, OnInit, OnDestroy {
    /* {OPEN:CLASS} {} */

    /* {OPEN:COMMON_VARS} {"sha1":"672A211DEEA9A3559D121CBDC5978BBA47BD6B7D"} */
    @Input() parent : any;
    pageTitle : string;
    /* {CLOSE:COMMON_VARS} {"sha1":"672A211DEEA9A3559D121CBDC5978BBA47BD6B7D"} */

    /* {OPEN:VARS} {"sha1":"5EB76AF8359F5F1D7B71752723894034E664689D"} */
    searchDataProviderCrmSelection : SearchDataProviderCrmSelection = new SearchDataProviderCrmSelection();
    pleaseselecttheDataProviderOptions : any[] = [];
    isPleaseselecttheDataProviderVolatile : boolean = false;
    /* {CLOSE:VARS} {"sha1":"5EB76AF8359F5F1D7B71752723894034E664689D"} */

    showResults : boolean = false;
    showLoader : boolean = false;
    subscriptions : Array<Subscription> = [];

    constructor(
        /* {OPEN:constructor} {"sha1":"198C3C42601C6958C0751E9E94074525C6F76B6B"} */
        public router : Router,
        public userService : UserService,
        public utilsService : UtilsService,
        public searchService : SearchService,
        public appService : AppService,
        public confirmationService: ConfirmationService,
        public dataProviderCrmSelectionService : DataProviderCrmSelectionService,
        /* {CLOSE:constructor} {"sha1":"198C3C42601C6958C0751E9E94074525C6F76B6B"} */
    ){

    }

    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"5FBDAAB044BC4F999356808F755A7334E11EE273"} */
        this.resetInitVariables();
        this.calculateEverything();
        this.executeScreenSpecificInitCode();
        /* {CLOSE:ngOnInit} {"sha1":"5FBDAAB044BC4F999356808F755A7334E11EE273"} */
    }

    /* {PREOPEN:executeScreenSpecificInitCode} {"sha1":"DDD081544316B33777AB7B7FFD796FB9E663E681"} */
    executeScreenSpecificInitCode() {
        /* {OPEN:executeScreenSpecificInitCode} {"sha1":"54DB41AD32937D86C7318EB4BE666A1AD7B6B618"} */
        if(!this.parent) {
        }
        /* {CLOSE:executeScreenSpecificInitCode} {"sha1":"54DB41AD32937D86C7318EB4BE666A1AD7B6B618"} */
    }
    /* {POSTCLOSE:executeScreenSpecificInitCode} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getPleaseselecttheDataProviderOptions} {"sha1":"3735D880AB1ACBEB6E33DE2165195A295F4D12FA"} */
    getPleaseselecttheDataProviderOptions(): OptionsWrapper {
        /* {OPEN:getPleaseselecttheDataProviderOptions} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:getPleaseselecttheDataProviderOptions} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:getPleaseselecttheDataProviderOptions} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:resetPleaseselecttheDataProviderOptions} {"sha1":"A41D13C0EF86B241B8DC5E9384CCB1D8A0EA26BB"} */
    resetPleaseselecttheDataProviderOptions() {
        /* {OPEN:resetPleaseselecttheDataProviderOptions} {"sha1":"19B87A930D702A86ACB65DEFF3E5759757702A7E"} */
        if(this.parent) {
            this.parent.resetPleaseselecttheDataProviderOptions();
        } else {
            let wrapper : OptionsWrapper = this.getPleaseselecttheDataProviderOptions();

            if (wrapper) {
                this.isPleaseselecttheDataProviderVolatile = wrapper.isVolatile;

                this.subscriptions.push(wrapper.getObservable()
                    .subscribe (
                        data => {
                            this.pleaseselecttheDataProviderOptions = data;
                        },
                        error => {
                            this.utilsService.handleError(error);
                        }
                    )
                );
            }
        }
        /* {CLOSE:resetPleaseselecttheDataProviderOptions} {"sha1":"19B87A930D702A86ACB65DEFF3E5759757702A7E"} */
    }
    /* {POSTCLOSE:resetPleaseselecttheDataProviderOptions} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onPleaseselecttheDataProviderChange} {"sha1":"38C783FF5B63EA8E410A846C890409A5FEF1FFAC"} */
    onPleaseselecttheDataProviderChange(event, searchDataProviderCrmSelection) {
        /* {OPEN:onPleaseselecttheDataProviderChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:onPleaseselecttheDataProviderChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:onPleaseselecttheDataProviderChange} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onAddClick} {"sha1":"7FF81701CB89F39B5F49B39EE9245C1626D9BE6E"} */
    onAddClick(event, searchDataProviderCrmSelection) {
        /* {OPEN:onAddClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onAddClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onAddClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onNextClick} {"sha1":"2C0610F798F5D7C471F2ED3F33AC5417789AAD62"} */
    onNextClick(event, searchDataProviderCrmSelection) {
        /* {OPEN:onNextClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onNextClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onNextClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:resetInitVariables} {"sha1":"846C0DF64879342578D595FE17BC00B96F0BAED6"} */
    resetInitVariables() {
        /* {OPEN:resetInitVariables} {} */
        /* {OPEN:resetInitVariables_resetPleaseselecttheDataProviderOptions} {"sha1":"A3DD3AF4424DE7BAD161854E01014E825EDC6A23"} */
        try {
            this.resetPleaseselecttheDataProviderOptions();
        }
        catch (error) {
            console.error('Error occured when executing resetPleaseselecttheDataProviderOptions', error);
        }
        /* {CLOSE:resetInitVariables_resetPleaseselecttheDataProviderOptions} {"sha1":"A3DD3AF4424DE7BAD161854E01014E825EDC6A23"} */
        /* {CLOSE:resetInitVariables} {} */
    }
    /* {POSTCLOSE:resetInitVariables} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getPageTitle} {"sha1":"174A3D30FFA103DD7BE180EE89CC0CF5EB6D11B8"} */
    getPageTitle() {
        /* {OPEN:getPageTitle} {"sha1":"9D3F22F0162A27ECBF9EB323D3329AED30A6C9CC"} */
        return 'Data Provider & CRM Selection';
        /* {CLOSE:getPageTitle} {"sha1":"9D3F22F0162A27ECBF9EB323D3329AED30A6C9CC"} */
    }
    /* {POSTCLOSE:getPageTitle} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:calculateEverything} {"sha1":"CE17BF2375171825A37C544A1DCBF3A565DD5181"} */
    calculateEverything() {
        /* {OPEN:calculateEverything} {} */
        /* {OPEN:calculateEverything_pageTitle} {"sha1":"B3CA5EAE3ED54D1F0D95EA2CD05351A4A6D7480F"} */
        try {
            this.pageTitle = this.getPageTitle();
        }
        catch (error) {
            console.error('Error occured when setting pageTitle');
        }
        /* {CLOSE:calculateEverything_pageTitle} {"sha1":"B3CA5EAE3ED54D1F0D95EA2CD05351A4A6D7480F"} */
        /* {OPEN:calculateEverything_isPleaseselecttheDataProviderVolatile} {"sha1":"904BFEC497BAFC37CEABEBA08A12F73F1F7B0C26"} */
        try {
            if(this.isPleaseselecttheDataProviderVolatile) {
                this.resetPleaseselecttheDataProviderOptions();
            }
        }
        catch (error) {
            console.error('Error occured when resetting pleaseselecttheDataProviderOptions');
        }
        /* {CLOSE:calculateEverything_isPleaseselecttheDataProviderVolatile} {"sha1":"904BFEC497BAFC37CEABEBA08A12F73F1F7B0C26"} */
        /* {CLOSE:calculateEverything} {} */
    }
    /* {POSTCLOSE:calculateEverything} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:ngDoCheck} {"sha1":"75839B27D41E20648CE296AC51D61E24D541A70A"} */
    ngDoCheck() {
        /* {OPEN:ngDoCheck} {"sha1":"A154E0663059B6D385B4F6C11094F2084FF5E184"} */
        this.calculateEverything();
        /* {CLOSE:ngDoCheck} {"sha1":"A154E0663059B6D385B4F6C11094F2084FF5E184"} */
    }
    /* {POSTCLOSE:ngDoCheck} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:ngOnDestroy} {"sha1":"C21B2137D2BD86DF7050E24A39F08355F04DA4E9"} */
    ngOnDestroy() {
        /* {OPEN:ngOnDestroy} {"sha1":"85AA65230B2C97013ABDD30D48BEB93C04E8EE54"} */
        this.utilsService.unsubscribeSubscriptions(this.subscriptions);
        /* {CLOSE:ngOnDestroy} {"sha1":"85AA65230B2C97013ABDD30D48BEB93C04E8EE54"} */
    }
    /* {POSTCLOSE:ngOnDestroy} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {CLOSE:CLASS} {} */
}
/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */
