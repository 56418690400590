import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Subject, Subscription } from 'rxjs';
import { ApiService } from './api.service';
import { ConfigService } from './config.service';
import { UtilsService } from './utils.service';
import { AppService } from '../../app.service';

@Injectable()
export class MenuService {

    private menu: any;

    menuUpdated     =   new Subject<any>();
    badgeUpdated    =   new Subject<any>();

    isStaticLayout: boolean = false;

    constructor(
        private apiService: ApiService,
        private configService: ConfigService,
        private utilsService: UtilsService,
        private appService: AppService
    ) {
        this.isStaticLayout = (this.configService.settings.layoutMode == 'static');

        this.badgeUpdated.subscribe(
            (data) => {
                if(this.menu && data.Tag && data.Badge !== undefined) {
                    setTimeout(() => {
                        if(this.menu.mainMenuItems) {
                            this.updateMenuForTag(this.menu.mainMenuItems, data.Tag, data.Badge, data.AdditionalAttributes);
                        }

                        if(this.menu.topMenuItems) {
                            this.updateMenuForTag(this.menu.topMenuItems, data.Tag, data.Badge, data.AdditionalAttributes);
                        }
                        this.menuUpdated.next(this.utilsService.cloneObject(this.menu));
                    }, 1000);   //Workaround to ignore menu service update triggered from onRouteUpdate(), otherwise this value will get override.
                }
            }
        )
    }

    private updateMenuForTag(menuItems: any[], tag: string, badge: string, additionalAttributes) {
        menuItems.forEach(menu => {
            if(menu.Tag && menu.Tag == tag) {
                menu.Badge                  =   badge;
                menu.AdditionalAttributes   =   additionalAttributes;
            }
        });
    }

    reloadMenuData(subscriptions: Array<Subscription>) {
        setTimeout(() => {
            let mainMenuItems : MenuItem[] = [];

            mainMenuItems.push({
                icon: "ui-icon-assignment",
                label: "Dashboard",
                routerLink: "/dashboard"
            });

            mainMenuItems.push({
                icon: "ui-icon-local-activity",
                label: "Converted Deals",
                routerLink: "/converted-deals"
            });

            // mainMenuItems.push({
            //     icon: "ui-icon-list",
            //     label: "Saved Searches",
            //     routerLink: "/saved-searches"
            // });
            mainMenuItems.push({
              icon: "ui-icon-list",
              label: "Saved Searches",
              routerLink: "/saved-searches"
            });


            if(this.appService.isAdminPanelAccessible()) {
              mainMenuItems.push({
                icon: "ui-icon-list",
                label: "Admin Panel",
                routerLink: "/admin-panel"
              });

            }

            mainMenuItems.push({
                icon: "ui-icon-exit-to-app",
                label: "Logout",
                command: () => {
                    this.appService.logout('auth/logout' , null);
                }
            });

            let _menu = {
                "mainMenuItems": mainMenuItems
            };
            this.menuUpdated.next(this.utilsService.cloneObject(_menu));
            this.menu = _menu;

        }, 100);
    }
}
