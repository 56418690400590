import { Observable } from 'rxjs';
import { data } from 'jquery';
import { CISGeoModel } from './../models/cis-geo.model';
import { Input } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseFilterComponent } from '../../base-filter/base-filter.component';
import { GoogleMap, MapCircle, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { toNumber } from 'lodash';

@Component({
  selector: 'cis-geo-filter',
  templateUrl: './cis-geo-filter.component.html',
  styleUrls: ['./cis-geo-filter.component.scss']
})
export class CisGeoFilterComponent extends BaseFilterComponent implements OnInit {

  @Input()
  geoData : CISGeoModel = new CISGeoModel();

  mapLoaded;
  zoom = 7;

  data : CISGeoModel = new CISGeoModel();

  circleOptions: google.maps.CircleOptions = {
    draggable: true,
    strokeColor: "#152733",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#152733",
    fillOpacity: 0.35,
  }

  options : google.maps.MapOptions;
  center: google.maps.LatLngLiteral;

  circleCenter: google.maps.LatLngLiteral;

  @ViewChild(MapCircle) circle : MapCircle;

  formatLabel(value: number) {
    if (value < 1000) return value + 'm';
    return Math.round(value / 1000) + 'km';
  }

  ngOnInit(): void {
    this.data = this.geoData;
    this.initializeCentre();
    this.initializeMapOptions();
    this.initializeCircleCentre();
    this.zoom = this.data?.range < 20000? 10: 7;
  }

  initializeCentre() {

    this.center = {lat: toNumber(this.data.latitude), lng: toNumber(this.data.longitude)};
  }


  // initialize(latitude, longitude) {
  //   this.data.latitude = latitude;
  //    this.data.longitude =  longitude;
  //    this.initializeCentre();
  //    this.initializeMapOptions();
  //    this.initializeCircleCentre();
  //    this.mapLoaded.subscribe();
  // }


  initializeMapOptions() {
      this.options = {
      streetViewControl: false,
    };
  }

  initializeCircleCentre() {
    this.circleCenter = {lat: toNumber(this.data.latitude), lng: toNumber(this.data.longitude)};
  }


  onChangeLatLng(event) {
    let center = {lat: this.data.latitude, lng: this.data.longitude};
    this.center.lat = this.data.latitude;
    this.center.lng = this.data.longitude;
    this.circle.center = center;
  }

  centerChanged = () => {
    let center = this.circle.getCenter().toJSON();
    this.data.latitude = center.lat;
    this.data.longitude = center.lng;
    center = {lat: this.data.latitude, lng: this.data.longitude};

  }

  applyFilter() {
    super.emitValues(this.data);
  }

  onClear() {
    this.data = new CISGeoModel();
    super.emitValues(null);
  }
}
