/* {OPEN:IMPORT} {"sha1":"8642E0CDACF27B949C1C07F9F42200C404E42814"} */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseSavedSearchesProviderComponent } from './base-saved-searches-provider.component';
/* {CLOSE:IMPORT} {"sha1":"8642E0CDACF27B949C1C07F9F42200C404E42814"} */
import { NgForm } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { NavigationExtras, provideRoutes } from '@angular/router';
import { data } from 'jquery';
/* {POSTCLOSE:IMPORT} {"sha1":"8642E0CDACF27B949C1C07F9F42200C404E42814"} */

/* {PREOPEN:CLASS} {"sha1":"5708FB180AE0B2129E1D877A56FA086B85436EEC"} */
@Component({
    selector: 'app-saved-searches-provider',
    templateUrl: './saved-searches-provider.component.html',
    styleUrls: ['./saved-searches-provider.scss'],
})
export class SavedSearchesProviderComponent extends BaseSavedSearchesProviderComponent implements OnInit, OnDestroy {
    /* {OPEN:CLASS} {} */




    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
        super.ngOnInit();
        /* {CLOSE:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
    }

    ngOnDestroy() {
        /* {OPEN:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
        super.ngOnDestroy();
        /* {CLOSE:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
    }

    /* {CLOSE:CLASS} {} */

    @ViewChild('form') form: NgForm;
    savedSearches = [];
    cacheFilters = {};
    module = "projects";
    dataProvider = "";

    activeLink = this.module;

    setActive(module) {
      this.module = module;
      if(this.activeLink != module) this.getSavedSearches();
      this.activeLink = module;

    }

    //@override
    executeScreenSpecificInitCode() {
      this.dataProvider = this.appService.getSelectedDataProvider();
      this.getCacheFilters().then(() =>
      this.getSavedSearches());
    }

    getSearchService() {
      switch(this.dataProvider) {
        case 'barbour':
          return this.barbourService
        case 'cis':
        return this.cisService;
      }

    }


    getLinks() {
      switch(this.dataProvider) {
        case 'barbour':
          return { 'projects': 'All Searches'}
        case 'cis':
          return {'projects': 'Projects', 'companies': 'Companies'};
      }

    }

    getCacheFilters() {
      this.showLoader = true;

        return new Promise<void>((resolve) => {
          this.subscriptions.push(
            this.getSearchService().getCacheFilters().subscribe(data => {
                this.showLoader = false;
                this.cacheFilters = data;
                if(this.dataProvider == 'barbour') {
                  this.cacheFilters[this.dataProvider + '_stages'] = {...this.cacheFilters[this.dataProvider + '_contract_stage'],
                  ...this.cacheFilters[this.dataProvider + '_planning_stage'],
                  ...this.cacheFilters[this.dataProvider + '_stopped']}
                }
                resolve();
             }, error => {
                this.showLoader = false;
                this.utilsService.handleError(error);
                resolve();
            })
        );

      });
    }

    getSavedSearches() {
        this.showLoader = true;
        this.subscriptions.push(
            this.getSearchService().getSavedSearches(this.module).subscribe(data => {
                this.showLoader = false;
                this.savedSearches = data;
            }, error => {
                this.showLoader = false;
                this.utilsService.handleError(error);
            })
        );
    }
}
/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */
