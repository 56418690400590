<div class="layout-wrapper">
	<div class="layout-main" [class.custom-background]="showLoginBackground" [ngStyle]="{'background-image' :  customBackgroundImage}"   >

		<div class="layout-content">
			<div class="ui-g">
				<p-toast key="default" [style]="{marginTop: '65px'}"></p-toast>
			</div>
			<div class="ui-g ui-fluid">
				<div class="ui-g-12">
					<div class="ui-g form-group">
						<div class="card card-w-title">
							<div class="ui-g form-group">
								<div class="ui-g-12 ui-md-12 box-header">
									<img alt="logo" src="assets/images/login_logo.jpg" />
								</div>
							</div>
							<div class="ui-g form-group" *ngIf="errorMsgs && errorMsgs.length > 0">
								<div class="ui-g-12 ui-md-12">
									<p-messages [(value)]="errorMsgs"></p-messages>
								</div>
							</div>
							<router-outlet (activate)="onRouteUpdate()"></router-outlet>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
