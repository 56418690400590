/* {OPEN:IMPORT} {"sha1":"2A8A1D5B4020FF39FDC465A40640E64FC52485FC"} */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseContactsComponent } from './base-contacts.component';
/* {CLOSE:IMPORT} {"sha1":"2A8A1D5B4020FF39FDC465A40640E64FC52485FC"} */
import { CreateAccountComponent } from '../create-account/create-account.component';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../oneit/services/user.service';
import { UtilsService } from '../../oneit/services/utils.service';
import { SearchService } from '../../oneit/services/search.service';
import { AppService } from '../../app.service';
import { ConfirmationService } from 'primeng/api';
import { ContactsService } from './contacts.service';
import { FullLayoutComponent } from '../../oneit/components/full-layout/full-layout.component';
import { DialogService } from 'primeng';
import { Subject } from 'rxjs';
import { CreateContactComponent } from '../create-contact/create-contact.component';
/* {POSTCLOSE:IMPORT} {"sha1":"2A8A1D5B4020FF39FDC465A40640E64FC52485FC"} */

/* {PREOPEN:CLASS} {"sha1":"1E0FF83780D94541386D3552D670573CAED942BE"} */
@Component({
    selector: 'app-contacts',
    templateUrl: './contacts.component.html'
})
export class ContactsComponent extends BaseContactsComponent implements OnInit, OnDestroy {
    /* {OPEN:CLASS} {} */

    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
        super.ngOnInit();
        /* {CLOSE:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
    }
    
    ngOnDestroy() {
        /* {OPEN:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
        super.ngOnDestroy();
        /* {CLOSE:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
    }

    /* {CLOSE:CLASS} {} */

    componentInView = new Subject();
    contact;
    contactRoles;

    //@override
    constructor(
        public router : Router,
        public userService : UserService,
        public utilsService : UtilsService,
        public searchService : SearchService,
        public appService : AppService,
        public confirmationService: ConfirmationService,
        public contactsService : ContactsService,
        public fullLayoutComponent : FullLayoutComponent,
        public dialogService : DialogService,
        public activatedRoute : ActivatedRoute
    ) {
        super(router , userService , utilsService , searchService , appService , confirmationService , contactsService);
    }

    //@override
    executeScreenSpecificInitCode() {
        this.activatedRoute.params.subscribe(params => {
            if(params.id) {
                this.getContactByID(params.id);
                this.getContactRolesByID(params.id);
            }
        })
    }

    getContactByID(id) {
        this.showLoader = true;

        this.subscriptions.push(
            this.appService.getContactByID(id).subscribe(data => {
                this.showLoader = false;
                this.contact = data;
            }, error => {
                this.showLoader = false;
                this.utilsService.handleError(error);
            })
        );
    }

    getContactRolesByID(id) {
        this.showLoader = true;

        this.subscriptions.push(
            this.appService.getContactRolesByID(id).subscribe(data => {
                this.showLoader = false;
                this.contactRoles = data;
            }, error => {
                this.showLoader = false;
                this.utilsService.handleError(error);
            })
        );
    }

    //@override
    onCreateContactClick(event, searchCompany) {
        this.utilsService.clearErrorMessages();

        this.fullLayoutComponent.dialogOpen = true;

        this.dialogService.open(CreateContactComponent, {
            header: 'Create Contact',
            data: {contactID: this.contact.id},
            ...this.appService.dynamicDialogConfig
        }).onClose.pipe(takeUntil(this.componentInView)).subscribe(() => {
            this.utilsService.clearErrorMessages();
            this.fullLayoutComponent.dialogOpen = false;
        });
    }

    onCloseClick(event, searchContact) {
        this.utilsService.clearErrorMessages();

        this.router.navigate(['./dashboard']);
    }
}
/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */
