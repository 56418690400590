/* {OPEN:IMPORT} {"sha1":"1AC100369DAA8B24AC4DC25324296A0CC096F1CD"} */
import { Component, DoCheck, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import { Message, MenuItem, ConfirmationService } from 'primeng/api';
import { AppService } from './../../app.service';
import { OptionsObservable, OptionsWrapper } from './../../oneit/models/options.model';
import { UserService } from './../../oneit/services/user.service';
import { UtilsService } from './../../oneit/services/utils.service';
import { SearchService } from './../../oneit/services/search.service';
import { SearchProjectsService } from './search-projects.service';
import { SearchProject } from './search-projects.model';
/* {CLOSE:IMPORT} {"sha1":"1AC100369DAA8B24AC4DC25324296A0CC096F1CD"} */

@Component({
    selector: 'app-base-search-projects',
    template: ''
})
/* {PREOPEN:CLASS} {"sha1":"4484CA651C52276ABB9FC8089BEA3006DA052BA9"} */
export class BaseSearchProjectsComponent implements DoCheck, OnInit, OnDestroy {
    /* {OPEN:CLASS} {} */
    
    /* {OPEN:COMMON_VARS} {"sha1":"672A211DEEA9A3559D121CBDC5978BBA47BD6B7D"} */
    @Input() parent : any;
    pageTitle : string;
    /* {CLOSE:COMMON_VARS} {"sha1":"672A211DEEA9A3559D121CBDC5978BBA47BD6B7D"} */

    /* {OPEN:VARS} {"sha1":"17E29474A873B0B3EFCA5DAAA420FF424D3B11B7"} */
    searchProject : SearchProject = new SearchProject();
    activeTabIndex : number = 0;
    searchProjectCompanyOptions : any[] = [];
    isSearchProjectCompanyVolatile : boolean = false;
    searchOptions : any[] = [];
    /* {CLOSE:VARS} {"sha1":"17E29474A873B0B3EFCA5DAAA420FF424D3B11B7"} */
    
    showResults : boolean = false;
    showLoader : boolean = false;
    subscriptions : Array<Subscription> = [];

    constructor(
        /* {OPEN:constructor} {"sha1":"E83D36B5C53FC047B8D50AB49CCC146C6EBC5878"} */
        public router : Router,
        public userService : UserService,
        public utilsService : UtilsService,
        public searchService : SearchService,
        public appService : AppService,
        public confirmationService: ConfirmationService,
        public searchProjectsService : SearchProjectsService,
        /* {CLOSE:constructor} {"sha1":"E83D36B5C53FC047B8D50AB49CCC146C6EBC5878"} */
    ){
        
    }

    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"5FBDAAB044BC4F999356808F755A7334E11EE273"} */
        this.resetInitVariables();
        this.calculateEverything();
        this.executeScreenSpecificInitCode();
        /* {CLOSE:ngOnInit} {"sha1":"5FBDAAB044BC4F999356808F755A7334E11EE273"} */
    }

    /* {PREOPEN:executeScreenSpecificInitCode} {"sha1":"DDD081544316B33777AB7B7FFD796FB9E663E681"} */
    executeScreenSpecificInitCode() {
        /* {OPEN:executeScreenSpecificInitCode} {"sha1":"54DB41AD32937D86C7318EB4BE666A1AD7B6B618"} */
        if(!this.parent) {
        }
        /* {CLOSE:executeScreenSpecificInitCode} {"sha1":"54DB41AD32937D86C7318EB4BE666A1AD7B6B618"} */
    }
    /* {POSTCLOSE:executeScreenSpecificInitCode} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onTabChange} {"sha1":"1ED28AFCAE22B24A9A2E176B664DE8A9010493DD"} */
    onTabChange(event, searchProject) : void {
        /* {OPEN:onTabChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:onTabChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:onTabChange} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getSearchProjectCompanyOptions} {"sha1":"123C2A0E687F716342CDEB65E4A270BA933884C0"} */
    getSearchProjectCompanyOptions(): OptionsWrapper {
        /* {OPEN:getSearchProjectCompanyOptions} {"sha1":"FE042567447A6636189C18A1F39914BE4E4B3408"} */
        return new OptionsObservable(false, this.appService.getProjectsOrCompaniesObservable());
        /* {CLOSE:getSearchProjectCompanyOptions} {"sha1":"FE042567447A6636189C18A1F39914BE4E4B3408"} */
    }
    /* {POSTCLOSE:getSearchProjectCompanyOptions} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:resetSearchProjectCompanyOptions} {"sha1":"6603B0A99D31AF5E29FD33F31E28081AABF882E9"} */
    resetSearchProjectCompanyOptions() {
        /* {OPEN:resetSearchProjectCompanyOptions} {"sha1":"8C3FA325BC5BA7A62DD72ECE2AC7DB66149C6A51"} */
        if(this.parent) {
            this.parent.resetSearchProjectCompanyOptions();
        } else {
            let wrapper : OptionsWrapper = this.getSearchProjectCompanyOptions();

            if (wrapper) {
                this.isSearchProjectCompanyVolatile = wrapper.isVolatile;

                this.subscriptions.push(wrapper.getObservable()
                    .subscribe (
                        data => {
                            this.searchProjectCompanyOptions = data;
                        },
                        error => {
                            this.utilsService.handleError(error);
                        }
                    )
                );
            }
        }
        /* {CLOSE:resetSearchProjectCompanyOptions} {"sha1":"8C3FA325BC5BA7A62DD72ECE2AC7DB66149C6A51"} */
    }
    /* {POSTCLOSE:resetSearchProjectCompanyOptions} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onSearchProjectCompanyChange} {"sha1":"4EF7B3316AAEDBC195D30B0A1C347F4AA170FA52"} */
    onSearchProjectCompanyChange(event, searchProject) {
        /* {OPEN:onSearchProjectCompanyChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:onSearchProjectCompanyChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:onSearchProjectCompanyChange} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:autocompleteSearchOptions} {"sha1":"9F47BBB42E9C973B8B9B12BBEB996A57CC985C05"} */
    autocompleteSearchOptions(event, searchProject) : void {
        /* {OPEN:autocompleteSearchOptions} {"sha1":"CE1A31B89DEFDCE0728BB21FBFFEF15EB26C0A27"} */
        this.subscriptions.push(this.getAutocompleteSearchOptionsObservable(event, searchProject)
            .subscribe (
                data => {
                    this.searchOptions = data;
                },
                error => {
                    this.utilsService.handleError(error);
                }
            )
        );
        /* {CLOSE:autocompleteSearchOptions} {"sha1":"CE1A31B89DEFDCE0728BB21FBFFEF15EB26C0A27"} */
    }
    /* {POSTCLOSE:autocompleteSearchOptions} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onSearchSelect} {"sha1":"7525CCDA493A45B844CF35A4B6227E75529EEAD5"} */
    onSearchSelect(event, searchProject) {
        /* {OPEN:onSearchSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:onSearchSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:onSearchSelect} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onSearchClear} {"sha1":"BC8431D8B9EF02EA5C9910B3338600C0FEE9411B"} */
    onSearchClear(event, searchProject) : void {
        /* {OPEN:onSearchClear} {"sha1":"64573A3DFC09437D85DAE172E7101488C5E543F2"} */
        searchProject.Search = null;
        /* {CLOSE:onSearchClear} {"sha1":"64573A3DFC09437D85DAE172E7101488C5E543F2"} */
    }
    /* {POSTCLOSE:onSearchClear} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getAutocompleteSearchOptionsObservable} {"sha1":"08A84D36844FC64769DA147E2CEA7716F429282C"} */
    getAutocompleteSearchOptionsObservable(event, searchProject) : Observable<any> {
        /* {OPEN:getAutocompleteSearchOptionsObservable} {"sha1":"6842A8F800AAAC1C344579A4631A95D715A72815"} */
        if(this.parent) {
            return this.parent.getAutocompleteSearchOptionsObservable(event, searchProject);
        }
        throw new Error('Not Implemented');
        /* {CLOSE:getAutocompleteSearchOptionsObservable} {"sha1":"6842A8F800AAAC1C344579A4631A95D715A72815"} */
    }
    /* {POSTCLOSE:getAutocompleteSearchOptionsObservable} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onClearClick} {"sha1":"ECCBBF7E15B81D0554D4DF51BDCB63DF360A6392"} */
    onClearClick(event, searchProject) {
        /* {OPEN:onClearClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onClearClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onClearClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onFiltersClick} {"sha1":"76A4894BB9A5065827BE523B224E24226B642784"} */
    onFiltersClick(event, searchProject) {
        /* {OPEN:onFiltersClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onFiltersClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onFiltersClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onSearchClick} {"sha1":"3C0175EA1F487765642BB9DC32B19057A6DB98DA"} */
    onSearchClick(event, searchProject) {
        /* {OPEN:onSearchClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onSearchClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onSearchClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onTableViewClick} {"sha1":"3ACEBA2CEB620A7232E0BA6C7E5FFE7075434FE8"} */
    onTableViewClick(event, searchProject) {
        /* {OPEN:onTableViewClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onTableViewClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onTableViewClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onSaveSearchClick} {"sha1":"BD5F739DC688520887649969CB8ACA3DAA46260D"} */
    onSaveSearchClick(event, searchProject) {
        /* {OPEN:onSaveSearchClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onSaveSearchClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onSaveSearchClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onShowMoreClick} {"sha1":"7DD2F91116EAA7B703CE5C7AFD9F57DF3DDDB665"} */
    onShowMoreClick(event, searchProject) {
        /* {OPEN:onShowMoreClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onShowMoreClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onShowMoreClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onChevronLeftClick} {"sha1":"D31CF46DD4F3003A6F18E78693D2C5132697837F"} */
    onChevronLeftClick(event, searchProject) {
        /* {OPEN:onChevronLeftClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onChevronLeftClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onChevronLeftClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRouterLinkForStamfordHouseRetailUnits} {"sha1":"2F1EA7DC8E6CD58197E959FA01BEE974991EAC08"} */
    getRouterLinkForStamfordHouseRetailUnits(searchProject) {
        /* {OPEN:getRouterLinkForStamfordHouseRetailUnits} {"sha1":"59FA4B2AD9C5B4E0012DA0C1CC3DA22AE8ED0C36"} */
        return ['/project'];
        /* {CLOSE:getRouterLinkForStamfordHouseRetailUnits} {"sha1":"59FA4B2AD9C5B4E0012DA0C1CC3DA22AE8ED0C36"} */
    }
    /* {POSTCLOSE:getRouterLinkForStamfordHouseRetailUnits} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRouterLinkForDetailPlanning208000} {"sha1":"F7ABFE82F5CBC5E0336A8B895277035DD831C264"} */
    getRouterLinkForDetailPlanning208000(searchProject) {
        /* {OPEN:getRouterLinkForDetailPlanning208000} {"sha1":"59FA4B2AD9C5B4E0012DA0C1CC3DA22AE8ED0C36"} */
        return ['/project'];
        /* {CLOSE:getRouterLinkForDetailPlanning208000} {"sha1":"59FA4B2AD9C5B4E0012DA0C1CC3DA22AE8ED0C36"} */
    }
    /* {POSTCLOSE:getRouterLinkForDetailPlanning208000} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRouterLinkForCommercialAndRetailLeeds} {"sha1":"6F1BB976EF76EFA0B3D401C5740E470718A42448"} */
    getRouterLinkForCommercialAndRetailLeeds(searchProject) {
        /* {OPEN:getRouterLinkForCommercialAndRetailLeeds} {"sha1":"59FA4B2AD9C5B4E0012DA0C1CC3DA22AE8ED0C36"} */
        return ['/project'];
        /* {CLOSE:getRouterLinkForCommercialAndRetailLeeds} {"sha1":"59FA4B2AD9C5B4E0012DA0C1CC3DA22AE8ED0C36"} */
    }
    /* {POSTCLOSE:getRouterLinkForCommercialAndRetailLeeds} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRouterLinkFor04March2021} {"sha1":"AF8D5548A9C26DC915C59C9C237AE36F8B6050D4"} */
    getRouterLinkFor04March2021(searchProject) {
        /* {OPEN:getRouterLinkFor04March2021} {"sha1":"59FA4B2AD9C5B4E0012DA0C1CC3DA22AE8ED0C36"} */
        return ['/project'];
        /* {CLOSE:getRouterLinkFor04March2021} {"sha1":"59FA4B2AD9C5B4E0012DA0C1CC3DA22AE8ED0C36"} */
    }
    /* {POSTCLOSE:getRouterLinkFor04March2021} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRouterLinkForGroveRoadOverdale81Houses} {"sha1":"15C7281D8738200466ECB0473CBEC4BA38BC5028"} */
    getRouterLinkForGroveRoadOverdale81Houses(searchProject) {
        /* {OPEN:getRouterLinkForGroveRoadOverdale81Houses} {"sha1":"59FA4B2AD9C5B4E0012DA0C1CC3DA22AE8ED0C36"} */
        return ['/project'];
        /* {CLOSE:getRouterLinkForGroveRoadOverdale81Houses} {"sha1":"59FA4B2AD9C5B4E0012DA0C1CC3DA22AE8ED0C36"} */
    }
    /* {POSTCLOSE:getRouterLinkForGroveRoadOverdale81Houses} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRouterLinkForDetailPlanning708000} {"sha1":"EFF5F490F6DE7F0C59198F1EF1F37F5819AF8F3D"} */
    getRouterLinkForDetailPlanning708000(searchProject) {
        /* {OPEN:getRouterLinkForDetailPlanning708000} {"sha1":"59FA4B2AD9C5B4E0012DA0C1CC3DA22AE8ED0C36"} */
        return ['/project'];
        /* {CLOSE:getRouterLinkForDetailPlanning708000} {"sha1":"59FA4B2AD9C5B4E0012DA0C1CC3DA22AE8ED0C36"} */
    }
    /* {POSTCLOSE:getRouterLinkForDetailPlanning708000} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRouterLinkForResidentialBournemouth} {"sha1":"1E00F87D369E0E06A37DD514827B2A909912FFE7"} */
    getRouterLinkForResidentialBournemouth(searchProject) {
        /* {OPEN:getRouterLinkForResidentialBournemouth} {"sha1":"59FA4B2AD9C5B4E0012DA0C1CC3DA22AE8ED0C36"} */
        return ['/project'];
        /* {CLOSE:getRouterLinkForResidentialBournemouth} {"sha1":"59FA4B2AD9C5B4E0012DA0C1CC3DA22AE8ED0C36"} */
    }
    /* {POSTCLOSE:getRouterLinkForResidentialBournemouth} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRouterLinkForCambridgeRoads100Flats} {"sha1":"C3E6453824EF3656580C16541FBF083462640E7B"} */
    getRouterLinkForCambridgeRoads100Flats(searchProject) {
        /* {OPEN:getRouterLinkForCambridgeRoads100Flats} {"sha1":"59FA4B2AD9C5B4E0012DA0C1CC3DA22AE8ED0C36"} */
        return ['/project'];
        /* {CLOSE:getRouterLinkForCambridgeRoads100Flats} {"sha1":"59FA4B2AD9C5B4E0012DA0C1CC3DA22AE8ED0C36"} */
    }
    /* {POSTCLOSE:getRouterLinkForCambridgeRoads100Flats} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onShowTheProjectDetailsClick} {"sha1":"D55CF10CB53600A978AFE9C24382A5A5CE555D14"} */
    onShowTheProjectDetailsClick(event, searchProject) {
        /* {OPEN:onShowTheProjectDetailsClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onShowTheProjectDetailsClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onShowTheProjectDetailsClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:resetInitVariables} {"sha1":"846C0DF64879342578D595FE17BC00B96F0BAED6"} */
    resetInitVariables() {
        /* {OPEN:resetInitVariables} {} */
        /* {OPEN:resetInitVariables_resetSearchProjectCompanyOptions} {"sha1":"7C64B817F0B11B594A93F6490B3DBCA02DD36E16"} */
        try {
            this.resetSearchProjectCompanyOptions();
        }
        catch (error) {
            console.error('Error occured when executing resetSearchProjectCompanyOptions', error);
        }
        /* {CLOSE:resetInitVariables_resetSearchProjectCompanyOptions} {"sha1":"7C64B817F0B11B594A93F6490B3DBCA02DD36E16"} */
        /* {CLOSE:resetInitVariables} {} */
    }
    /* {POSTCLOSE:resetInitVariables} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getPageTitle} {"sha1":"174A3D30FFA103DD7BE180EE89CC0CF5EB6D11B8"} */
    getPageTitle() {
        /* {OPEN:getPageTitle} {"sha1":"8DD78CEFAB83ACA4C2571EA8E41723BADD39F64D"} */
        return 'Search Projects';
        /* {CLOSE:getPageTitle} {"sha1":"8DD78CEFAB83ACA4C2571EA8E41723BADD39F64D"} */
    }
    /* {POSTCLOSE:getPageTitle} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:calculateEverything} {"sha1":"CE17BF2375171825A37C544A1DCBF3A565DD5181"} */
    calculateEverything() {
        /* {OPEN:calculateEverything} {} */
        /* {OPEN:calculateEverything_pageTitle} {"sha1":"B3CA5EAE3ED54D1F0D95EA2CD05351A4A6D7480F"} */
        try {
            this.pageTitle = this.getPageTitle();
        }
        catch (error) {
            console.error('Error occured when setting pageTitle');
        }
        /* {CLOSE:calculateEverything_pageTitle} {"sha1":"B3CA5EAE3ED54D1F0D95EA2CD05351A4A6D7480F"} */
        /* {OPEN:calculateEverything_isSearchProjectCompanyVolatile} {"sha1":"21146C126409B3DD4C66944640EB173E2BE8EB3E"} */
        try {
            if(this.isSearchProjectCompanyVolatile) {
                this.resetSearchProjectCompanyOptions();
            }
        }
        catch (error) {
            console.error('Error occured when resetting searchProjectCompanyOptions');
        }
        /* {CLOSE:calculateEverything_isSearchProjectCompanyVolatile} {"sha1":"21146C126409B3DD4C66944640EB173E2BE8EB3E"} */
        /* {CLOSE:calculateEverything} {} */
    }
    /* {POSTCLOSE:calculateEverything} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:ngDoCheck} {"sha1":"75839B27D41E20648CE296AC51D61E24D541A70A"} */
    ngDoCheck() {
        /* {OPEN:ngDoCheck} {"sha1":"A154E0663059B6D385B4F6C11094F2084FF5E184"} */
        this.calculateEverything();
        /* {CLOSE:ngDoCheck} {"sha1":"A154E0663059B6D385B4F6C11094F2084FF5E184"} */
    }
    /* {POSTCLOSE:ngDoCheck} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:ngOnDestroy} {"sha1":"C21B2137D2BD86DF7050E24A39F08355F04DA4E9"} */
    ngOnDestroy() {
        /* {OPEN:ngOnDestroy} {"sha1":"85AA65230B2C97013ABDD30D48BEB93C04E8EE54"} */
        this.utilsService.unsubscribeSubscriptions(this.subscriptions);
        /* {CLOSE:ngOnDestroy} {"sha1":"85AA65230B2C97013ABDD30D48BEB93C04E8EE54"} */
    }
    /* {POSTCLOSE:ngOnDestroy} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {CLOSE:CLASS} {} */
}
/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */