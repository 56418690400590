<div class="demo-button-container">

  <h1>Admin Organization Dashboard</h1>

<section class="add-org">
  <div layout="row" flex>
  <button mat-raised-button (click)="openAddUserPopup()">
    Add User
  </button>
</div>
</section>
<br/>
<br/>
</div>

<table mat-table [dataSource]="users" class="mat-elevation-z8">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <ng-container matColumnDef="userId">
    <th mat-header-cell *matHeaderCellDef> Id </th>
    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> Name </th>
    <td mat-cell *matCellDef="let element"> {{element.firstName + " " + element.lastName}} </td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef> Email </th>
    <td mat-cell *matCellDef="let element"> {{element.email}} </td>
  </ng-container>

  <ng-container matColumnDef="roles">
    <th mat-header-cell *matHeaderCellDef> Roles </th>
    <td mat-cell *matCellDef="let element"> {{element.roles}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
