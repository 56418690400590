export abstract class Constants {

  // Value for Between Date Select Item
  public static CUSTOM_DATE = "custom";

  public static TEXT_FILTER : string = 'textFilter';
  public static TRACKING_FILTER: string = 'trackingFilter';
  public static GEOLOCATION: string = 'Location';


  // Date Filters
  public static DATE_UPDATED: string = 'DateUpdated';
  public static APPLICATION_DATE: string = 'ApplicationDate';
  public static TENDER_DEADLINE: string = 'TenderDeadline';
  public static DECISION_DATE: string = 'DecisionDate';
  public static PROJECT_START: string = 'ProjectStartDate';
  public static PROJECT_FINISH: string = 'FinishDate';

  // Lookups
  public static CATEGORIES: string = 'Categories';
  public static MATERIALS: string = 'Materials';
  public static COUNTIES: string = 'County';
  public static CONSTRUCTION_TYPES: string = 'ConstructionType';
  public static ROLES: string = 'Companies.RoleName';
  public static COMPANY_ROLES: string = 'DefaultRole';

  public static FUNDING_TYPES: string = 'FundingType';
  public static PLANNING_AUTHORITIES: string = 'PlanningAuthority';
  public static STAGE : string ='Stage';

  // Metric Filters
  public static VALUE: string = 'Value';
  public static FLOOR_AREA: string = 'FloorArea';
  public static PARKING_SPACES: string = 'NumberOfParkingSpaces';
  public static SITE_AREA: string = 'SiteArea';
  public static STOREYS: string = 'NumberOfStoreys';
  public static UNITS: string = 'NumberOfUnits';

  public static METRIC_FILTER_DISPLAY : string = "Metrics";
  public static DATE_FILTER_DISPLAY : string = "Dates";


  public static LOCATION: string = 'Location';

  public static Tracking_FILTER = 'trackingFilter';

  static readonly LOOKUP_FILTERS: string[] = [
    Constants.CATEGORIES,
    Constants.MATERIALS,
    Constants.COUNTIES,
    Constants.CONSTRUCTION_TYPES,
    Constants.ROLES,
    Constants.COMPANY_ROLES,
    Constants.FUNDING_TYPES,
    Constants.PLANNING_AUTHORITIES,
    Constants.STAGE,
  ];
  static readonly DATE_FILTERS: string[] = [
    Constants.APPLICATION_DATE,
    Constants.TENDER_DEADLINE,
    Constants.DECISION_DATE,
    Constants.PROJECT_START,
    Constants.PROJECT_FINISH,
  ];

  static METRIC_FILTERS = [
    Constants.FLOOR_AREA,
    Constants.SITE_AREA,
    Constants.PARKING_SPACES,
    Constants.STOREYS,
    Constants.UNITS,
  ]


  //These lists are for mapping and conversion to filter components and vice versa.
  static readonly LOOKUP_FILTERS_LIST: string[] = [
    Constants.CATEGORIES,
    Constants.MATERIALS,
    Constants.COUNTIES,
    Constants.CONSTRUCTION_TYPES,
    Constants.ROLES,
    Constants.COMPANY_ROLES,
    Constants.FUNDING_TYPES,
    Constants.PLANNING_AUTHORITIES,
    Constants.STAGE,
  ];

  static readonly DATE_FILTERS_LIST: string[] = [
    Constants.DATE_UPDATED,
    Constants.APPLICATION_DATE,
    Constants.TENDER_DEADLINE,
    Constants.DECISION_DATE,
    Constants.PROJECT_START,
    Constants.PROJECT_FINISH,
  ];

  static METRIC_FILTERS_LIST = [
    Constants.VALUE,
    Constants.FLOOR_AREA,
    Constants.SITE_AREA,
    Constants.PARKING_SPACES,
    Constants.STOREYS,
    Constants.UNITS,
  ]

  static readonly TEXT_SEARCH_OPTIONS = {
    0: 'Any',
    1: 'All',
    2: 'Exact',
  }


  static readonly DATE_FILTERS_OPTIONS = {
    [Constants.APPLICATION_DATE]: {"pastOptions": true, "futureOptions": false, "betweenOptions": true},
    [Constants.TENDER_DEADLINE]: {"pastOptions": true, "futureOptions": true, "betweenOptions": true},
    [Constants.DECISION_DATE]: {"pastOptions": true, "futureOptions": true, "betweenOptions": true},
    [Constants.PROJECT_START]: {"pastOptions": true, "futureOptions": true, "betweenOptions": true},
    [Constants.PROJECT_FINISH]: {"pastOptions": true, "futureOptions": true, "betweenOptions": true},
  };

  static DATE_OPTIONS_PAST : Map<String, String> = new Map([
    ['-1d', 'Yesterday'],
    ['-7d', 'In the last Week'],
    ['-1', 'In the last Month'],
    ['-2', 'In the last 2 Months'],
    ['-3', 'In the last 3 Months'],
    ['-6', 'In the last 6 Months']
  ]);

  static DATE_OPTIONS_FUTURE : Map<String, String> = new Map([
    ['7d', 'In the next Week'],
    ['1', 'In the next Month'],
    ['2', 'In the next 2 Months'],
    ['3', 'In the next 3 Months'],
    ['6', 'In the next 6 Months']
  ]);

  static TRACKING_OPTIONS : Map<String, String> = new Map([
    ['', 'None'],
    ['Tracked', 'Tracked by me'],
    ['Not Tracked', 'Not tracked by me'],
    ['Company', 'Tracked by my company'],
    ['Company Not Tracked', 'Not tracked by my company']
  ]);

  static FILTERS = [
    Constants.TEXT_FILTER,
    Constants.CATEGORIES,
    Constants.COUNTIES,
    Constants.MATERIALS,
    Constants.STAGE,
    Constants.VALUE,
    Constants.DATE_UPDATED,
    Constants.GEOLOCATION,
    Constants.Tracking_FILTER,
    Constants.CONSTRUCTION_TYPES,
    Constants.DATE_FILTER_DISPLAY,
    Constants.METRIC_FILTER_DISPLAY,
    Constants.ROLES,
    Constants.FUNDING_TYPES,
    Constants.PLANNING_AUTHORITIES,
  ];

  static COMPANIES_FILTERS = [
    Constants.TEXT_FILTER,
    Constants.COUNTIES,
    Constants.COMPANY_ROLES,
    Constants.DATE_UPDATED,
    Constants.GEOLOCATION,
  ];

  static FILTERS_UI = {
    [Constants.TEXT_FILTER]: 'Text Search',
    [Constants.CATEGORIES]: 'Category',
    [Constants.COUNTIES]: 'Location',
    [Constants.MATERIALS]: 'Materials',
    [Constants.STAGE]: 'Stage',
    [Constants.VALUE]: 'Value',
    [Constants.DATE_UPDATED]: 'Date Updated',
    [Constants.APPLICATION_DATE]: 'Application Date',
    [Constants.TENDER_DEADLINE]: 'Tender Deadline Date',
    [Constants.DECISION_DATE]: 'Decision Date',
    [Constants.PROJECT_START]: 'Start Date',
    [Constants.PROJECT_FINISH]: 'Finish Date',
    [Constants.GEOLOCATION]: 'Geolocation',
    [Constants.Tracking_FILTER]: 'Tracking Status',
    [Constants.CONSTRUCTION_TYPES]: 'Construction Types',
    [Constants.DATE_FILTER_DISPLAY]: 'Dates',
    [Constants.METRIC_FILTER_DISPLAY]: 'Metrics',
    [Constants.FLOOR_AREA]:'Floor Area',
    [Constants.SITE_AREA]:'Site Area',
    [Constants.PARKING_SPACES]:'Parking Spaces',
    [Constants.STOREYS]:'Storeys',
    [Constants.UNITS]:'Units',
    [Constants.ROLES]: 'Roles',
    [Constants.COMPANY_ROLES]: 'Roles',
    [Constants.FUNDING_TYPES]: 'Funding Types',
    [Constants.PLANNING_AUTHORITIES]: 'Planning Authorities',
  }

  static DATE_FORMAT = 'yyyy-MM-dd';

  public static LOOKUP_FILTERS_KEYMAP = {
    [this.COMPANY_ROLES] : Constants.ROLES,
  };

  static FIELD_LABEL_MAP = {
    DATE_UPDATED : "Date Updated",
    APPLICATION_DATE : "Application Date",
    TENDER_DEADLINE : "Tender Deadline",
    STAGE : "Stages",
    MATERIALS : "Materials",
    CATEGORIES : "Categories",
    COUNTIES : "Location",
    CONSTRUCTION_TYPES : "Construction Types",

  }
}
