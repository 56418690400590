import { CISDate } from './../../cbos/cis/cis-date-filter/cis-date.model';
/* {OPEN:IMPORT} {"sha1":"F703355CC5186C805CB2482478B711D1730EC0E0"} */
import { Component, OnInit } from '@angular/core';
import { BaseRefineSearchComponent } from './base-refine-search.component';
/* {CLOSE:IMPORT} {"sha1":"F703355CC5186C805CB2482478B711D1730EC0E0"} */
import { QueryParams, SearchRefine } from './refine-search.model';
import { ConfirmationService, TreeNode } from 'primeng/api';
import { OptionsWrapper } from '../../oneit/models/options.model';
import { NgForm } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { UserService } from 'src/app/oneit/services/user.service';
import { UtilsService } from 'src/app/oneit/services/utils.service';
import { SearchService } from 'src/app/oneit/services/search.service';
import { AppService } from 'src/app/app.service';
import { RefineSearchService } from './refine-search.service';
import { SearchProjectsService } from 'src/app/components/search-projects/search-projects.service';
import { Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng';
import { MsgsService } from '../../oneit/services/msgs.service';
import { AppConstants } from 'src/app/cbos/constants/app-constants';
/* {POSTCLOSE:IMPORT} {"sha1":"F703355CC5186C805CB2482478B711D1730EC0E0"} */

/* {PREOPEN:CLASS} {"sha1":"38D48DA5C35EC8E54D7102AAD8D326582C033617"} */
@Component({
    selector: 'app-refine-search',
    templateUrl: './refine-search.component.html',
    styleUrls: ['./refine-search.component.scss']
})
export class RefineSearchComponent extends BaseRefineSearchComponent implements OnInit {
    /* {OPEN:CLASS} {} */
    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
        super.ngOnInit();
        /* {CLOSE:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
    }

    ngOnDestroy() {
        /* {OPEN:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
        super.ngOnDestroy();
        /* {CLOSE:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
    }
    /* {CLOSE:CLASS} {} */

    @ViewChild('form') form: NgForm;
    stages: TreeNode[] = [];
    categories: TreeNode[] = [];
    locations: TreeNode[] = [];
    developmentTypes: TreeNode[] = [];
    contractTypes: TreeNode[] = [];
    roles: TreeNode[] = [];
    materials: TreeNode[] = [];
    funding: TreeNode[] = [];
    totalFilters = 0;
    isExcludeAllStagesOptions = false;
    isIncludeAllStagesOptions = false;
    stagesOptionsSelected = false;
    isExcludeAllCategoriesOptions = false;
    isIncludeAllCategoriesOptions = false;
    categoriesOptionsSelected = false;
    isExcludeAllLocationsOptions = false;
    isIncludeAllLocationsOptions = false;
    locationsOptionsSelected = false;
    isExcludeAllDevelopmentTypesOptions = false;
    isIncludeAllDevelopmentTypesOptions = false;
    developmentTypesOptionsSelected = false;
    isExcludeAllContractTypesOptions = false;
    isIncludeAllContractTypesOptions = false;
    contractTypesOptionsSelected = false;
    isExcludeAllRolesOptions = false;
    isIncludeAllRolesOptions = false;
    rolesOptionsSelected = false;
    isIncludeAllMaterialsOptions = false;
    materialsOptionsSelected = false;
    isExcludeAllFundingOptions = false;
    isIncludeAllFundingOptions = false;
    fundingOptionsSelected = false;
    searchByCompanyOrProjectDistanceOptions = [
        {label: 'Company Distance', value: 'Company Distance'},
        {label: 'Project Distance', value: 'Project Distance'}
    ];
    queryParams = new QueryParams();
    projects : any[] = [];
    companies : any[] = [];
    filterParams : any;
    position = {
        lng: 0,
        lat: 0
    };
    favouriteOnly;
    errorMsgs;

    constructor(
        public router : Router,
        public userService : UserService,
        public utilsService : UtilsService,
        public searchService : SearchService,
        public appService : AppService,
        public confirmationService: ConfirmationService,
        public refineSearchService : RefineSearchService,
        public searchProjectsService : SearchProjectsService,
        public dialogConfig : DynamicDialogConfig,
        public dialogRef : DynamicDialogRef,
        public msgsService : MsgsService
    ) {
        super(router, userService, utilsService, searchService, appService, confirmationService,refineSearchService, searchProjectsService);

    }

    //@override
    executeScreenSpecificInitCode() {
        this.subscriptions.push(
            this.msgsService.errorMsgsUpdated.subscribe(errorMsgs => {
                this.errorMsgs = errorMsgs;
            })
        );

        this.filterParams = this.dialogConfig.data.filters;
        this.favouriteOnly = this.dialogConfig.data.favouriteOnly;
        if(this.filterParams) {
            this.setFilterParamsOptions();
        }

        this.getModuleFilters();
    }

    setFilterParamsOptions() {
        if(this.filterParams.project_text) {
            this.searchRefine.project = this.filterParams.project_text.value;
        }

        if(this.filterParams.project_start) {
            this.searchRefine.StartDate = this.filterParams.project_start.value;
        }

        if(this.filterParams.project_end) {
            this.searchRefine.EndDate = this.filterParams.project_end.value;
        }

        if(this.filterParams.project_tender_close_display) {
            this.searchRefine.TenderCloseDate = this.filterParams.project_tender_close_display.value;
        }

        if(this.filterParams.project_value) {
          let values = this.getMinMaxValuesFromFilters(this.filterParams.project_value);
          this.searchRefine.minValue = values.value1;
          this.searchRefine.maxValue = values.value2;
        }

        if(this.filterParams.project_units) {
            let values = this.getMinMaxValuesFromFilters(this.filterParams.project_units);
            this.searchRefine.minUnits = values.value1;
            this.searchRefine.maxUnits = values.value2;
        }

        if(this.filterParams.project_plan_area) {
            let values = this.getMinMaxValuesFromFilters(this.filterParams.project_plan_area);
            this.searchRefine.minTotalFloorArea = values.value1;
            this.searchRefine.maxTotalFloorArea = values.value2;
        }

        if(this.filterParams.project_floor_area) {
            let values = this.getMinMaxValuesFromFilters(this.filterParams.project_floor_area);
            this.searchRefine.minGroundFloorArea = values.value1;
            this.searchRefine.maxGroundFloorArea = values.value2;
        }

        if(this.filterParams.project_site_area) {
            let values = this.getMinMaxValuesFromFilters(this.filterParams.project_site_area);
            this.searchRefine.minSiteArea = values.value1;
            this.searchRefine.maxSiteArea = values.value2;
        }

        if(this.filterParams.project_storeys_above) {
            let values = this.getMinMaxValuesFromFilters(this.filterParams.project_storeys_above);
            this.searchRefine.minStoreysAboveGround = values.value1;
            this.searchRefine.maxStoreysAboveGround = values.value2;
        }

        if(this.filterParams.project_storeys_below) {
            let values = this.getMinMaxValuesFromFilters(this.filterParams.project_storeys_below);
            this.searchRefine.minStoreysBelowGround = values.value1;
            this.searchRefine.maxStoreysBelowGround = values.value2;
        }

        if(this.filterParams.project_parking) {
            let values = this.getMinMaxValuesFromFilters(this.filterParams.project_parking);
            this.searchRefine.minParkingSpaces = values.value1;
            this.searchRefine.maxParkingSpaces = values.value2;
        }

        if(this.filterParams.company_text) {
            this.searchRefine.company = this.filterParams.company_text.value;
        }

        if(this.filterParams.project_distance) {
            this.searchRefine.Distance = this.filterParams.project_distance.value
        }

        if(this.filterParams.company_distance) {
            this.searchRefine.Distance = this.filterParams.company_distance.value
        }

        if((this.filterParams.project_longitude && this.filterParams.project_longitude.value) ||
            (this.filterParams.project_latitude && this.filterParams.project_latitude.value) ||
            (this.filterParams.company_longitude && this.filterParams.company_longitude.value) ||
            (this.filterParams.company_latitude && this.filterParams.company_latitude.value)) {
            this.searchRefine.UseCurrentLocation = true;
        }

        if(localStorage.getItem('Includerefusedwithdrawnabandonedandonhold')) {
            this.searchRefine.Includerefusedwithdrawnabandonedandonhold = JSON.parse(localStorage.getItem('Includerefusedwithdrawnabandonedandonhold'));
        }
    }

    private getMinMaxValuesFromFilters(filter) {
      if(filter.operator == '..') return {value1: filter.value1, value2: filter.value2};
      else if(filter.operator == '>') return {value1: filter.value, value2: undefined};
      else if(filter.operator == '<') return {value1: undefined, value2: filter.value};
      return {value1: undefined, value2: undefined};
    }

    getModuleFilters() {
        const filters = JSON.parse(localStorage.getItem('moduleFilters'));
        this.setRefineSearchFilters(filters);
    }

    setRefineSearchFilters(filters) {
        if ( filters.stage ) {
            const values = this.getIncludeExcludeValues('project_stages');
            this.stagesOptionsSelected = this.setSearchOptions(filters.stage, this.stages, values);

        }
        if ( filters.category ) {
            const values = this.getIncludeExcludeValues('project_categories');
            this.categoriesOptionsSelected = this.setSearchOptions(filters.category, this.categories, values);
        }
        if ( filters.geocode ) {
            const values = this.getIncludeExcludeValues('project_geocode');
            this.locationsOptionsSelected = this.setSearchOptions(filters.geocode, this.locations, values);
        }
        if ( filters.development_type ) {
            const values = this.getIncludeExcludeValues('project_development_types');
            this.developmentTypesOptionsSelected = this.setSearchOptions(filters.development_type, this.developmentTypes, values);
        }
        if ( filters.contract_type ) {
            const values = this.getIncludeExcludeValues('project_contract_types');
            this.contractTypesOptionsSelected = this.setSearchOptions(filters.contract_type, this.contractTypes, values);
        }
        if ( filters.role ) {
            const values = this.getIncludeExcludeValues('project_roles');
            this.rolesOptionsSelected = this.setSearchOptions(filters.role, this.roles, values);
        }
        if ( filters.material ) {
            const values = this.getIncludeExcludeValues('project_materials');
            this.materialsOptionsSelected = this.setSearchOptions(filters.material, this.materials, values);
        }
        if ( filters.funding ) {
            const values = this.getIncludeExcludeValues('project_funding');
            this.fundingOptionsSelected = this.setSearchOptions(filters.funding, this.funding, values);
        }
    }

    setSearchOptions(options, array, values, isOptionSelected = false) {
        let optionSelected = isOptionSelected;
        options.forEach(item => {
            if(item.description === '-') {
                optionSelected = this.setSearchOptions(item.children, array, values, optionSelected);
            }

            if(item.description !== '-') {
                const node: TreeNode = {};

                if (item.children && item.children.length > 0 ) {
                    node.children = [];
                    optionSelected = this.setSearchOptions(item.children, node.children, values, optionSelected);
                }

                node.data = {
                    name: item.description,
                    id: item.id,
                    isIncluded: this.isValuesChecked(values, 'included', item),
                    isExcluded: this.isValuesChecked(values, 'excluded', item),
                };

                array.push(node);

                if(node.data.isIncluded || node.data.isExcluded) {
                    optionSelected = true;
                }
            }
        });

        return optionSelected;
    }

    getIncludeExcludeValues(queryParam) {
        if (!this.filterParams || (this.filterParams && !this.filterParams[queryParam])) {
            return;
        }

        const saved_values = this.filterParams[queryParam];
        let values = {
            included : [],
            excluded : []
        }
        let included;
        let excluded;


        if(Array.isArray(saved_values)) {
            if(saved_values[0]) {
                if(saved_values[0].operator === '=') {
                    included = saved_values[0];
                }

                if(saved_values[0].operator === '!=') {
                    excluded = saved_values[0];
                }
            }

            if(saved_values[1] && saved_values[1].operator === '!=') {
                excluded = saved_values[1];
            }
        }

        if(!Array.isArray(saved_values)) {
            if(saved_values.operator === '=') {
                included = saved_values;
            }

            if(saved_values.operator === '!=') {
                excluded = saved_values;
            }
        }

        if (included && included.value) {
            values.included = included.value;
        }

        if (excluded && excluded.value) {
            values.excluded = excluded.value;
        }

        return values;
    }

    isValuesChecked(values, param, item) {
        if (!values) {
            return false;
        }

        if(this.searchRefine.Includerefusedwithdrawnabandonedandonhold && param === 'included') {
            values[param].forEach((value,index) => {
                if(value == 15 || value == 16 || value == 19 || value == 20 || value == 30) {
                    values[param].splice(index , 1);
                }
            });
        }

        return (values[param] || []).some(v => v == item.id);
    }

    selectAllOptions(options, isSelected, isIncludeOptionChange) {
        options.forEach(item => {
            if ( isIncludeOptionChange ) {
                item.data.isIncluded = isSelected;
                item.data.isExcluded = false;
            }

            if ( !isIncludeOptionChange ) {
                item.data.isIncluded = false;
                item.data.isExcluded = isSelected;
            }

            if ( item.children && item.children.length > 0 ) {
                this.selectAllOptions(item.children, isSelected, isIncludeOptionChange);
            }
        });

        this.setSummaryField();
    }

    onTableDataCheckBoxValueChange(options, isIncludeOptionChange, type) {
        if ( options && options.children ) {
            this.setOptions(options, isIncludeOptionChange, true);
        }

        if ( options && options.parent ) {
            this.setOptions(options.parent, isIncludeOptionChange, false);
        }

        this.setSummaryField();

        this.checkIsAllOptionsSelected(type);
    }

    setOptions(options, isIncludeOptionChange, isParentNodeChange) {
        if ( isParentNodeChange && options.children.length > 0 ) {
            options.children.forEach(item => {
                if ( isIncludeOptionChange ) {
                    item.data.isIncluded = options.data.isIncluded;
                    item.data.isExcluded = options.data.isExcluded;
                }

                if ( !isIncludeOptionChange ) {
                    item.data.isIncluded = options.data.isIncluded;
                    item.data.isExcluded = options.data.isExcluded;
                }

                if ( item.children && item.children.length > 0 ) {
                    this.setOptions(item, isIncludeOptionChange, true);
                }
            });
        }

        if ( !isParentNodeChange && options.children.length > 0 ) {
            let isAllChildrenSelected = true;
            options.children.forEach(item => {
                if ( isIncludeOptionChange && !item.data.isIncluded ) {
                    isAllChildrenSelected = false;
                }

                if ( !isIncludeOptionChange && !item.data.isExcluded ) {
                    isAllChildrenSelected = false;
                }
            });

            if ( !isIncludeOptionChange && !isAllChildrenSelected ) {
                options.data.isExcluded = false;
            }

            if ( !isIncludeOptionChange && isAllChildrenSelected ) {
                options.data.isExcluded = true;
            }

            if ( isIncludeOptionChange && !isAllChildrenSelected ) {
                options.data.isIncluded = false;
            }

            if ( isIncludeOptionChange && isAllChildrenSelected ) {
                options.data.isIncluded = true;
            }

            if ( options && options.parent ) {
                this.setOptions(options.parent, isIncludeOptionChange, false)
            }
        }

    }

    setSummaryField() {
        this.stagesOptionsSelected = this.isFilterOptionsSelected(this.stages, this.stagesOptionsSelected = false);
        this.categoriesOptionsSelected = this.isFilterOptionsSelected(this.categories, this.categoriesOptionsSelected = false);
        this.locationsOptionsSelected = this.isFilterOptionsSelected(this.locations, this.locationsOptionsSelected = false);
        this.developmentTypesOptionsSelected = this.isFilterOptionsSelected(this.developmentTypes, this.developmentTypesOptionsSelected = false);
        this.contractTypesOptionsSelected = this.isFilterOptionsSelected(this.contractTypes, this.contractTypesOptionsSelected = false);
        this.rolesOptionsSelected = this.isFilterOptionsSelected(this.roles, this.rolesOptionsSelected = false);
        this.materialsOptionsSelected = this.isFilterOptionsSelected(this.materials, this.materialsOptionsSelected = false);
        this.fundingOptionsSelected = this.isFilterOptionsSelected(this.funding, this.fundingOptionsSelected = false);
    }

    isFilterOptionsSelected(options, isSelected) {
        if ( options && options.length > 0 ) {
            if ( !isSelected ) {
                options.forEach(item => {
                    if ( !isSelected && item.data && (item.data.isIncluded || item.data.isExcluded) ) {
                        isSelected = true;
                    }

                    if ( !isSelected && item.children && item.children.length > 0 ) {
                        isSelected = this.isFilterOptionsSelected(item.children, isSelected);
                    }
                });
            }
        }

        return isSelected;
    }

    checkIsAllOptionsSelected(type) {
        if ( type === 'isStage' ) {
           const isAllSelected = this.isAllOptionsSelected(this.stages, type);
           this.isIncludeAllStagesOptions = isAllSelected.isIncludeAllOptions;
           this.isExcludeAllStagesOptions = isAllSelected.isExcludeAllOptions;
        }

        if ( type === 'isCategory' ) {
            const isAllSelected = this.isAllOptionsSelected(this.categories, type);
            this.isIncludeAllCategoriesOptions = isAllSelected.isIncludeAllOptions;
            this.isExcludeAllCategoriesOptions = isAllSelected.isExcludeAllOptions;
          }

        if ( type === 'isLocation' ) {
            const isAllSelected = this.isAllOptionsSelected(this.locations, type);
            this.isIncludeAllLocationsOptions = isAllSelected.isIncludeAllOptions;
            this.isExcludeAllLocationsOptions = isAllSelected.isExcludeAllOptions;
        }

        if ( type === 'isDevelopmentType' ) {
            const isAllSelected = this.isAllOptionsSelected(this.developmentTypes, type);
            this.isIncludeAllDevelopmentTypesOptions = isAllSelected.isIncludeAllOptions;
            this.isExcludeAllDevelopmentTypesOptions = isAllSelected.isExcludeAllOptions;
        }

        if ( type === 'isContractType' ) {
            const isAllSelected =  this.isAllOptionsSelected(this.contractTypes, type);
            this.isIncludeAllContractTypesOptions = isAllSelected.isIncludeAllOptions;
            this.isExcludeAllContractTypesOptions = isAllSelected.isExcludeAllOptions;
        }

        if ( type === 'isRole' ) {
            const isAllSelected = this.isAllOptionsSelected(this.roles, type);
            this.isIncludeAllRolesOptions = isAllSelected.isIncludeAllOptions;
            this.isExcludeAllRolesOptions = isAllSelected.isExcludeAllOptions;
        }

        if ( type === 'isMaterial' ) {
            const isAllSelected = this.isAllOptionsSelected(this.materials, type);
            this.isIncludeAllMaterialsOptions = isAllSelected.isIncludeAllOptions;
        }

        if ( type === 'isFunding' ) {
            const isAllSelected =  this.isAllOptionsSelected(this.funding, type);
            this.isIncludeAllFundingOptions = isAllSelected.isIncludeAllOptions;
            this.isExcludeAllFundingOptions = isAllSelected.isExcludeAllOptions;
        }
    }

    isAllOptionsSelected(options, type) {
        let isAllIncludedOptionsSelected = true;
        let isAllExcludedOptionsSelected = true;
        options.forEach(item => {
            if ( isAllIncludedOptionsSelected && !item.data.isIncluded ) {
                isAllIncludedOptionsSelected = false;
            }

            if ( isAllExcludedOptionsSelected && !item.data.isExcluded ) {
                isAllExcludedOptionsSelected = false;
            }

            if ( item.children && item.children.length > 0 ) {
                this.isAllOptionsSelected(item.children, type);
            }
        });

        return {isIncludeAllOptions: isAllIncludedOptionsSelected , isExcludeAllOptions : isAllExcludedOptionsSelected}
    }

    //@override
    onClearAllClick(event, searchRefine) {
        this.utilsService.clearErrorMessages();

        this.searchRefine = new SearchRefine();

        this.form.resetForm();

        this.stagesOptionsSelected = false;
        this.categoriesOptionsSelected = false;
        this.locationsOptionsSelected = false;
        this.developmentTypesOptionsSelected = false;
        this.contractTypesOptionsSelected = false;
        this.rolesOptionsSelected = false;
        this.materialsOptionsSelected = false;
        this.fundingOptionsSelected = false;

        this.unSelectAllChildren(this.categories);
        this.unSelectAllChildren(this.locations);
        this.unSelectAllChildren(this.materials);
    }

    unSelectAllChildren(options) {
        options.forEach(option => {
            option.data.isIncluded = false;
            option.data.isExcluded = false;

            if ( option.children && option.children.length > 0 ) {
                this.unSelectAllChildren(option.children);
            }
        });
    }

    //@override
    onSearchClick(event, searchRefine) {
        this.utilsService.clearErrorMessages();

        this.showLoader = true;

        this.setFilterOptionsForSearch();

        this.getProjectsOrCompanies();
    }

    setFilterOptionsForSearch() {
        this.queryParams = new QueryParams();

        if(this.searchRefine.project) {
            this.queryParams.project_text = {
                'operator' : '=',
                'value' : this.searchRefine.project
            };
        }

        if ( this.stagesOptionsSelected ) {
            this.setQueryParamsForOptions(this.stages, 'project_stages');
        }

        if(this.searchRefine.Includerefusedwithdrawnabandonedandonhold) {
            this.setRefusedWithdrawnAbandonedAndOnHold('project_stages');
        }

        if ( this.categoriesOptionsSelected ) {
            this.setQueryParamsForOptions(this.categories, 'project_categories');
        }

        if ( this.locationsOptionsSelected ) {
            if(this.dialogConfig.data.module === AppConstants.PROJECTS) {
                this.setQueryParamsForOptions(this.locations, 'project_geocode');
            }

            if(this.dialogConfig.data.module === AppConstants.COMPANIES) {
                this.setQueryParamsForOptions(this.locations, 'company_geocode');
            }
        }

        if ( this.developmentTypesOptionsSelected ) {
            this.setQueryParamsForOptions(this.developmentTypes, 'project_development_types');
        }

        if ( this.contractTypesOptionsSelected ) {
            this.setQueryParamsForOptions(this.contractTypes, 'project_contract_types');
        }

        if ( this.rolesOptionsSelected ) {
            this.setQueryParamsForOptions(this.roles, 'project_roles');
        }

        if ( this.materialsOptionsSelected ) {
            this.setQueryParamsForOptions(this.materials , 'project_materials');
        }

        if ( this.fundingOptionsSelected ) {
            this.setQueryParamsForOptions(this.funding, 'project_funding');
        }

        if( this.searchRefine.StartDate) {
            this.queryParams.project_start = {
                'operator' : '<=',
                'value' : this.searchRefine.StartDate
            };
        }

        if( this.searchRefine.EndDate) {
            this.queryParams.project_end = {
                'operator' : '=',
                'value' : this.searchRefine.EndDate
            };
        }

        if( this.searchRefine.TenderCloseDate) {
            this.queryParams.project_tender_close_display = {
                'operator' : '=',
                'value' : this.searchRefine.TenderCloseDate
            };
        }

        this.fillMinMaxQueryParams(this.searchRefine.minValue, this.searchRefine.maxValue, 'project_value');
        this.fillMinMaxQueryParams(this.searchRefine.minUnits, this.searchRefine.maxUnits, 'project_units');
        this.fillMinMaxQueryParams(this.searchRefine.minTotalFloorArea, this.searchRefine.maxTotalFloorArea, 'project_plan_area');
        this.fillMinMaxQueryParams(this.searchRefine.minGroundFloorArea, this.searchRefine.maxGroundFloorArea, 'project_floor_area');
        this.fillMinMaxQueryParams(this.searchRefine.minSiteArea, this.searchRefine.maxSiteArea, 'project_site_area');
        this.fillMinMaxQueryParams(this.searchRefine.minStoreysAboveGround, this.searchRefine.maxStoreysAboveGround, 'project_storeys_above');
        this.fillMinMaxQueryParams(this.searchRefine.minStoreysBelowGround, this.searchRefine.maxStoreysBelowGround, 'project_storeys_below');
        this.fillMinMaxQueryParams(this.searchRefine.minParkingSpaces, this.searchRefine.maxParkingSpaces, 'project_parking');

        if( this.searchRefine.company) {
            this.queryParams.company_text = {
                'operator': '=',
                'value': this.searchRefine.company
            }
        }

        if( this.searchRefine.Distance && this.dialogConfig.data.module) {
            this.fillModuleRelatedQueryParams(this.dialogConfig.data.module);
        }
    }

    private fillModuleRelatedQueryParams(moduleName : string) {
        this.getCurrentLocation();

        this.queryParams[moduleName + '_distance']  = { 'operator': '=', 'value': this.searchRefine.Distance };
        this.queryParams[moduleName + '_latitude']  = { 'operator': '=', 'value': this.position.lat };
        this.queryParams[moduleName + '_longitude'] = { 'operator': '=', 'value': this.position.lng };
    }

    private fillMinMaxQueryParams(minValue : any, maxValue : any, queryAttribute : string) {
        if (minValue && maxValue) {
            this.queryParams[queryAttribute] = {
                'operator': '..',
                'value1': minValue,
                'value2': maxValue,
            }
        } else if (minValue) {
            this.queryParams[queryAttribute] = {'operator': '>', 'value': minValue}
        } else if (maxValue ) {
            this.queryParams[queryAttribute] = {'operator': '<', 'value': maxValue}
        }
    }

    getProjectsOrCompanies() {
        localStorage.setItem('Includerefusedwithdrawnabandonedandonhold' ,JSON.stringify(this.searchRefine.Includerefusedwithdrawnabandonedandonhold ? this.searchRefine.Includerefusedwithdrawnabandonedandonhold : false));
        this.dialogRef.close({
            TotalFilters: this.totalFilters,
            Query: {...this.queryParams},
            FavouriteOnly: this.favouriteOnly
        });
    }

    getCurrentLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                this.position.lat = position.coords.latitude;
                this.position.lng = position.coords.longitude;
            });
        } else {
            this.utilsService.handleError('Geolocation is not supported by this browser');
        }
    }

    setQueryParamsForOptions(options, key) {
        if(options.find(item => item.data.isIncluded) && options.find(item => item.data.isExcluded)) {
            this.setArrayForKey(options , key);
            return;
        }

        if(options.find(item => item.data.isIncluded) && !options.find(item => item.data.isExcluded)) {
            this.setObjectForKey(options , key , 'included');
            return;
        }

        if(!options.find(item => item.data.isIncluded) && options.find(item => item.data.isExcluded)) {
            this.setObjectForKey(options , key , 'excluded');
            return;
        }

        options.forEach(option => {
            if(option.children && option.children.length > 0) {
                this.setQueryParamsForOptions(option.children, key);
            }
        });
    }

    setArrayForKey(options , key) {
        this.queryParams[key] = [];

        this.queryParams[key][0] = {
            operator : '=',
            value : [],
        }

        this.queryParams[key][1] = {
            operator : '!=',
            value : [],
        }

        this.setQueryParams(options , this.queryParams[key]);
    }

    setObjectForKey(options , key , includedExcluded) {
        this.queryParams[key] = {};

        this.queryParams[key] = {
            operator : includedExcluded === 'included' ? '=' : '!=',
            value : [],
        }

        this.setQueryParams(options , this.queryParams[key]);
    }

    setQueryParams(options , params) {
        options.forEach(option => {
            if(option.data.isIncluded && params[0]) {
                params[0].value.push(option.data.id);
            }

            if(option.data.isExcluded && params[1]) {
               params[1].value.push(option.data.id);
            }

            if(option.data.isIncluded && !params[0] && !params[1]) {
                params.value.push(option.data.id);
            }

            if(option.data.isExcluded && !params[0] && !params[1]) {
                params.value.push(option.data.id);
            }

            if(option.children && option.children.length > 0) {
                this.setQueryParams(option.children , params);
            }
        });
    }

    setRefusedWithdrawnAbandonedAndOnHold(key) {
        if(!this.stagesOptionsSelected) {
            this.queryParams[key]= {
                operator : '=',
                value : [],
            }
        }

        if(this.stages && this.stages.length > 0) {
            this.stages.forEach(stage => {
                if(stage.data.name === 'Outline refusal' || stage.data.name === 'Detail refusal' ||
                    stage.data.name === 'Abandoned' || stage.data.name === 'Withdrawn' || stage.data.name === 'On hold') {

                    if(Array.isArray(this.queryParams[key])) {
                        this.queryParams[key][0].value.push(stage.data.id);
                    }

                    if(!Array.isArray(this.queryParams[key])) {
                        this.queryParams[key].value.push(stage.data.id);
                    }
                }
            });
        }
    }

    //@override
    onCloseClick(event, searchRefine) {
        this.utilsService.clearErrorMessages();

        this.dialogRef.close();
    }

    //@override
    getStagesTableCols(): any[] {
        return [
            {field: 'name', header: '', width: '20px', noSort: true, noFilter: false},
            {field: 'Include', header: 'Include', width: '10px', noSort: true, noFilter: true},
            {field: 'Exclude', header: 'Exclude', width: '10px', noSort: true, noFilter: true}
        ];
    }

    //@override
    getCategoriesTableCols(): any[] {
        return [
            {field: 'name', header: '', width: '20px', noSort: true, noFilter: false},
            {field: 'Include', header: 'Include', width: '10px', noSort: true, noFilter: true},
            {field: 'Exclude', header: 'Exclude', width: '10px', noSort: true, noFilter: true}
        ];
    }

    //@override
    getLocationsTableCols(): any[] {
        return [
            {field: 'name', header: '', width: '20px', noSort: true, noFilter: false},
            {field: 'Include', header: 'Include', width: '10px', noSort: true, noFilter: true},
            {field: 'Exclude', header: 'Exclude', width: '10px', noSort: true, noFilter: true}
        ];
    }

    //@override
    getDevelopmentTypesTableCols(): any[] {
        return [
            {field: 'name', header: '', width: '20px', noSort: true, noFilter: false},
            {field: 'Include', header: 'Include', width: '10px', noSort: true, noFilter: true},
            {field: 'Exclude', header: 'Exclude', width: '10px', noSort: true, noFilter: true}
        ];
    }

    //@override
    getContractTypesTableCols(): any[] {
        return [
            {field: 'name', header: '', width: '20px', noSort: true, noFilter: false},
            {field: 'Include', header: 'Include', width: '10px', noSort: true, noFilter: true},
            {field: 'Exclude', header: 'Exclude', width: '10px', noSort: true, noFilter: true}
        ];
    }

    //@override
    getRolesTableCols(): any[] {
        return [
            {field: 'name', header: '', width: '20px', noSort: true, noFilter: false},
            {field: 'Include', header: 'Include', width: '10px', noSort: true, noFilter: true},
            {field: 'Exclude', header: 'Exclude', width: '10px', noSort: true, noFilter: true}
        ];
    }

    //@override
    getMaterialsTableCols(): any[] {
        return [
            {field: 'name', header: '', width: '20px', noSort: true, noFilter: false},
            {field: 'Include', header: 'Include', width: '10px', noSort: true, noFilter: true},
        ];
    }

    //@override
    getFundingsTableCols(): any[] {
        return [
            {field: 'name', header: '', width: '20px', noSort: true, noFilter: false},
            {field: 'Include', header: 'Include', width: '10px', noSort: true, noFilter: true},
            {field: 'Exclude', header: 'Exclude', width: '10px', noSort: true, noFilter: true}
        ];
    }

    calculateTotalNumberOfFiltersApplied() {
        this.totalFilters = 0;

        this.setFilterOptionsForSearch();

        this.totalFilters = this.appService.calculateTotalNumberOfFiltersApplied(this.queryParams);

        if(this.favouriteOnly) {
            this.totalFilters++;
        }

    }

    //@override
    getDummyOptions(): OptionsWrapper {
        return null;
    }

    //@override
    ngDoCheck() {
        this.calculateEverything();
        this.calculateTotalNumberOfFiltersApplied();
    }
}

/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */
