import { Constants } from 'src/app/cbos/cis/consts';
import { LocalStorageService } from './../../services/local-storage.service';
import { CisFilterService } from './../cis-filter.service';
import { CisUtilServiceService } from './../cis-util-service.service';
import { SavedSearchesProviderService } from './../../saved-searches-provider/saved-searches-provider.service';
import { Subscription } from 'rxjs';
import { UtilsService } from 'src/app/oneit/services/utils.service';
import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cis-saved-search',
  templateUrl: './cis-saved-search.component.html',
  styleUrls: ['./cis-saved-search.component.scss']
})
export class CisSavedSearchComponent implements OnInit {

  constructor(
    public router : Router,
    public utilsService : UtilsService,
    public searchService : SavedSearchesProviderService,
    public cisUtilService : CisUtilServiceService,
  private filterService : CisFilterService,
  private localStorageService : LocalStorageService,) {
   }

   readonly Constants = Constants;
  ngOnInit(): void {
    this.getSavedSearchBody();
  }

  ngOnDestroy() {
    this.utilsService.unsubscribeSubscriptions(this.subscriptions);
  }

  @Input() savedSearch : any;
  @Input() cache : any;
  @Input () module : string = "projects";


  showResults : boolean = false;
  isLoaded : boolean = false;
  subscriptions : Array<Subscription> = [];
  searchBody;

  getSavedSearchBody() {

    this.subscriptions.push(
        this.searchService.getSavedSearchBody(this.module, this.savedSearch.userAzureSearchID).subscribe(data => {
            this.searchBody = data;
            this.isLoaded = true;
           // this.setSavedSearches();
        }, error => {
          this.utilsService.handleError(error);
        })
    );
}



getDisplayFiltersForField(field) {

  let filterText = [];
  let fieldName = field.fieldName;

  field.filters.forEach(element => {
    let filterVal = this.cisUtilService.getDisplayValuesForFilter(element);
    if(this.cache.hasOwnProperty(fieldName)) filterVal = this.cache[fieldName][filterVal];
    filterText.push(filterVal);
  });

  return filterText.join(' ; ');
}



  onSearchClick(savedSearch) {
    this.utilsService.clearErrorMessages();
    this.filterService.searchUsingSearchID(this.module, savedSearch);
    this.localStorageService.setModule(this.module);
    this.router.navigate(['/dashboard/' + savedSearch.id]);
}

}
