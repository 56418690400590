import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { EnumService } from './oneit/services/enum.service';

export class BaseAppService {

    constructor(
        public enumService: EnumService
    ) {
    }

    getProjectsOrCompaniesObservable() : Observable<any> {
        return new BehaviorSubject([
            {
                value: "projects",
                Description: "Projects"
            },
            {
                value: "companies",
                Description: "Companies"
            }
        ]).asObservable();
    }

    getSortingOptionsObservable() : Observable<any> {
        return this.enumService.getEnumsWithNULLOption("SortingOptions");
    }

    getOrderSearchesObservable() : Observable<any> {
        return this.enumService.getEnumsWithNULLOption("OrderSearches");
    }

    getStagesObservable() : Observable<any> {
        return this.enumService.getEnumsWithNULLOption("Stages");
    }

    getLocationsObservable() : Observable<any> {
        return this.enumService.getEnumsWithNULLOption("Locations");
    }

    getDevelopmentTypesObservable() : Observable<any> {
        return this.enumService.getEnumsWithNULLOption("DevelopmentTypes");
    }

    getSearchByDistancesObservable() : Observable<any> {
        return this.enumService.getEnumsWithNULLOption("SearchByDistances");
    }

    getOwnersObservable() : Observable<any> {
        return this.enumService.getEnumsWithNULLOption("Owners");
    }

    getDealStagesObservable() : Observable<any> {
        return this.enumService.getEnumsWithNULLOption("DealStages");
    }

    getIncludeCompanyAsAccountsObservable() : Observable<any> {
        return this.enumService.getEnumsWithNULLOption("IncludeCompanyAsAccounts");
    }

    getDataProvidersObservable() : Observable<any> {
        return this.enumService.getEnumsWithNULLOption("DataProviders");
    }

    getCrmsObservable() : Observable<any> {
        return this.enumService.getEnumsWithNULLOption("Crms");
    }

}
