import { AppConstants } from 'src/app/cbos/constants/app-constants';
import { LocalStorageService } from './../../services/local-storage.service';
import { BarbourUtilServiceService } from '../barbour-util-service.service';
import { UtilsService } from 'src/app/oneit/services/utils.service';
import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-barbour-saved-search',
  templateUrl: './barbour-saved-search.component.html',
  styleUrls: ['./barbour-saved-search.component.scss'],
})
export class BarbourSavedSearchComponent implements OnInit {
  constructor(
    public router: Router,
    public utilsService: UtilsService,
    public barbourUtilService: BarbourUtilServiceService,
    private localStorageService : LocalStorageService,
  ) {}

  ngOnInit(): void {
    this.setSavedSearch();
  }
  @Input() savedSearch: any;
  @Input() cache: any;

  dataProvider = 'barbour';
  showResults: boolean = false;
  showLoader: boolean = false;

  filterMap = {
    project_stages: 'stages',
    project_contract_stage: 'stage',
    project_categories: 'category',
    project_development_types: 'development_type',
    project_materials: 'material',
    project_roles: 'role',
    project_contract_types: 'contract_type',
    project_geocode: 'geocode',
    company_geocode: 'geocode',
  };

  getDisplayQuery(str) {
    return _.startCase(str);
  }

  getDisplayFiltersForField(field) {
    let filterText = [];
    field.filters.forEach((element) => {
      filterText.push(
        ` ${this.barbourUtilService.getDisplayValuesForFilter(element)}`
      );
    });

    return filterText.join(' ; ');
  }

  setSavedSearch() {
    this.savedSearch.setQueries = [];
    for (const query in this.savedSearch.query) {
      if (this.savedSearch.query[query].length > 0) {
        this.savedSearch.query[query].forEach((item) => {
          this.savedSearch.setQueries.push({ name: query, ...item });
        });
      }

      if (!Array.isArray(this.savedSearch.query[query])) {
        this.savedSearch.setQueries.push({
          name: query,
          ...this.savedSearch.query[query],
        });
      }
    }
  }

  setOperatorValue(query) {
    if (
      query.name !== 'project_text' &&
      query.name !== 'company_name' &&
      query.name !== 'project_build_phase'
    ) {
      switch (query.operator) {
        case '=':
          return 'Included ';
        case '!=':
          return 'Excluded ';
        case '..':
          return 'Range From ';
        case '>':
          return 'Greater than ';
        case '<':
          return 'Less than ';
        case '>=':
          return 'Greater Than and Equal To ';
        case '<=':
          return 'Less Than and Equal To ';
        default:
          return '';
      }
    }
    return '';
  }

  convertQueryCodeToValue(query, codes) {
    if (!codes) return '';
    if (!this.filterMap[query]) return codes;

    let key = this.dataProvider + '_' + this.filterMap[query];
    let values = [];

    codes.forEach((element) => {
      values.push(this.cache?.[key]?.[element]);
    });

    return values.join(' ; ');
  }

  onSearchClick() {
    let module = AppConstants.PROJECTS;
    this.utilsService.clearErrorMessages();
    if(this.savedSearch.object_type === 'Company') {
      module = AppConstants.COMPANIES;
    }
    this.localStorageService.setModule(module);

    this.router.navigate(['/dashboard/' + this.savedSearch.saved_search_id]);
  }
}
