import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {
  public isApiLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isApiLoaded$ = this.isApiLoaded.asObservable();

  constructor(httpClient: HttpClient) {
    const key = environment.googleMapsApiKey;
    httpClient
      .jsonp(`https://maps.googleapis.com/maps/api/js?key=${key}`, 'callback')
      .subscribe(
        () => {
          this.isApiLoaded.next(true);
        },
        (error) => {
          console.log(
            '🚀 ~ GoogleMapsService ~ google maps api cannot be loaded',
            error
          );
        }
      );
  }
}
