import { DataProviderService } from './data-providers/data-provider.service';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { GoogleMapsService } from './services/google-maps.service';
import { CISTextFilterComponent } from './cis/cis-text-filter/cis-text-filter.component';
import { FiveDigitDecimaNumberDirective } from './directives/five-decimal-input.directive';
import { CISTrackingFilterComponent } from './cis/cis-tracking-filter/cis-tracking-filter.component';
import { LocalStorageService } from './services/local-storage.service';
import { CisUtilServiceService } from './cis/cis-util-service.service';
import { CisFilterService } from './cis/cis-filter.service';
import { SavedSearchesProviderService } from './saved-searches-provider/saved-searches-provider.service';
import { OneITModule } from './../oneit/oneit.module';
import { BarbourSavedSearchesComponent } from './barbour/barbour-saved-searches/barbour-saved-searches.component';
import { BarbourSavedSearchComponent } from './barbour/barbour-saved-search/barbour-saved-search.component';
import { TreeFilterComponent } from './tree-filter/tree-filter.component';
import { SavedSearchesProviderComponent } from './saved-searches-provider/saved-searches-provider.component';
import { CisSavedSearchComponent } from './cis/cis-saved-search/cis-saved-search.component';
import { CisSummarySearchComponent } from './cis/cis-summary-search/cis-summary-search.component';
import { CISRefineFiltersComponent } from './cis/cis-refine-filters/cis-refine-filters.component';
import { CisDateFilterComponent } from './cis/cis-date-filter/cis-date-filter.component';
import { CbosDateFilterComponent } from './cbos-date-filter/cbos-date-filter.component';
import { AppMaterialModule } from './../app-material/app-material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CisMetricFilterComponent } from './cis/cis-metric-filter/cis-metric-filter.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { CisGeoFilterComponent } from './cis/cis-geo-filter/cis-geo-filter.component';
import { MapSearchViewComponent } from './map-search-view/map-search-view.component';
import { CompanyCardComponent } from './company-card/company-card.component';
import { ProjectCardComponent } from './project-card/project-card.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { AddOrganizationComponent } from './admin/add-organization/add-organization.component';
import { AddUserComponent } from './admin/add-user/add-user.component';
import { ListOrgUsersComponent } from './admin/list-org-users/list-org-users.component';
import { ListOrgsComponent } from './admin/list-orgs/list-orgs.component';
@NgModule({
  declarations: [
    BarbourSavedSearchComponent,
    BarbourSavedSearchesComponent,
    CbosDateFilterComponent,
    CisDateFilterComponent,
    CisSavedSearchComponent,
    CisSummarySearchComponent,
    CISRefineFiltersComponent,
    CisMetricFilterComponent,
    CISTrackingFilterComponent,
    CisGeoFilterComponent,
    CISTextFilterComponent,
    FiveDigitDecimaNumberDirective,
    SavedSearchesProviderComponent,
    TreeFilterComponent,
    MapSearchViewComponent,
    CompanyCardComponent,
    ProjectCardComponent,
    AdminDashboardComponent,
    AddOrganizationComponent,
    AddUserComponent,
    ListOrgUsersComponent,
    ListOrgsComponent,
  ],
  imports: [
    AppMaterialModule,
    CommonModule,
    FormsModule,
    OneITModule,
    HttpClientModule,
    HttpClientJsonpModule,
    GoogleMapsModule,
  ],
  providers: [
    SavedSearchesProviderService,
    CisFilterService,
    CisUtilServiceService,
    DataProviderService,
    LocalStorageService,
    GoogleMapsService,
  ],
  exports:[
    SavedSearchesProviderComponent,
    CISRefineFiltersComponent,
    MapSearchViewComponent,
  ]
})
export class CbosModule { }
