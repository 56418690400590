/* {OPEN:IMPORT} {"sha1":"BAEC8F95BD3A2239F7F3F73DCC61F252E6E4B619"} */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SaveService } from './../../oneit/services/save.service';
import { SearchService } from './../../oneit/services/search.service';
import { UtilsService } from './../../oneit/services/utils.service';
/* {CLOSE:IMPORT} {"sha1":"BAEC8F95BD3A2239F7F3F73DCC61F252E6E4B619"} */

@Injectable()
/* {PREOPEN:CLASS} {"sha1":"73EFC6F86E6E2B10D7DDDB79045FEA6AD82841EC"} */
export class RefineSearchService {
    /* {OPEN:CLASS} {} */

    constructor(
        /* {OPEN:constructor} {"sha1":"DCCC484B4DA23ED55D1380457121E9DAC4BB6B04"} */
        private searchService: SearchService,
        private saveService: SaveService,
        private utilsService: UtilsService,
        /* {CLOSE:constructor} {"sha1":"DCCC484B4DA23ED55D1380457121E9DAC4BB6B04"} */
    ) {
    }

    /* {PREOPEN:getEnterawordorphrasetosearchallfieldsForAutocomplete} {"sha1":"58E2D3429A5E9A9DC5F40FA052866769D61B6C8C"} */
    getEnterawordorphrasetosearchallfieldsForAutocomplete(queryParams, assocs) : Observable<any> {
        /* {OPEN:getEnterawordorphrasetosearchallfieldsForAutocomplete} {"sha1":"40A1F1EE1EBAC25EFAAE688EB2972A58972F1490"} */
        return this.searchService.getObjects('Enterawordorphrasetosearchallfields', 'All', queryParams, assocs, null, null, null, null)
            .pipe(map(
                data => {
                    return this.utilsService.convertResponseToObjects(data, assocs);
                }
            ));
        /* {CLOSE:getEnterawordorphrasetosearchallfieldsForAutocomplete} {"sha1":"40A1F1EE1EBAC25EFAAE688EB2972A58972F1490"} */
    }
    /* {POSTCLOSE:getEnterawordorphrasetosearchallfieldsForAutocomplete} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {CLOSE:CLASS} {} */
}
/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */
