export class SearchLoginPage {
    /* {OPEN:SearchLoginPage} {"sha1":"6376B33E5123BF09A271789A272103F957C8F38D"} */
    /* {CLOSE:SearchLoginPage} {"sha1":"6376B33E5123BF09A271789A272103F957C8F38D"} */
    Username;
    Password;
    ObjectID;
    /* {POSTCLOSE:SearchLoginPage} {"sha1":"6376B33E5123BF09A271789A272103F957C8F38D"} */
}

/* {OPEN:ADDIONAL_MODELS} {"sha1":"ADC83B19E793491B1C6EA0FD8B46CD9F32E592FC"} */
/* {CLOSE:ADDIONAL_MODELS} {"sha1":"ADC83B19E793491B1C6EA0FD8B46CD9F32E592FC"} */
