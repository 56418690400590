import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { ConfigService } from '../services/config.service';
import { UtilsService } from '../services/utils.service';

@Pipe({
    name: 'oneitdate'
})
export class OneITDatePipe implements PipeTransform {

    constructor(
        private configService   :   ConfigService,
        private datePipe        :   DatePipe
        ) {
    }

    transform(value: any, format : string = 'date', inputFormat? : string): any {
        if (!value) { return ""; }
        if (typeof value == 'string') {
            value   =   UtilsService.convertStringToDate(value, inputFormat);
        }
        let dateFormats =   this.configService.settings.dateFormats;

        if (dateFormats && dateFormats[format]) {
            format  =   dateFormats[format];
        }
        return this.datePipe.transform(value, format);
    }
}