/* {OPEN:IMPORT} {"sha1":"D40EC5EFD2BEF6BB6899938AF5E7CB5C6A4AD348"} */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseCreateContactComponent } from './base-create-contact.component';
/* {CLOSE:IMPORT} {"sha1":"D40EC5EFD2BEF6BB6899938AF5E7CB5C6A4AD348"} */
import { NgForm } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../oneit/services/user.service';
import { UtilsService } from '../../oneit/services/utils.service';
import { SearchService } from '../../oneit/services/search.service';
import { AppService } from '../../app.service';
import { ConfirmationService } from 'primeng/api';
import { CreateContactService } from './create-contact.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng';
/* {POSTCLOSE:IMPORT} {"sha1":"D40EC5EFD2BEF6BB6899938AF5E7CB5C6A4AD348"} */

/* {PREOPEN:CLASS} {"sha1":"A16A96EAE668737ADADFE9465A310A100418F00A"} */
@Component({
    selector: 'app-create-contact',
    templateUrl: './create-contact.component.html'
})
export class CreateContactComponent extends BaseCreateContactComponent implements OnInit, OnDestroy {
    /* {OPEN:CLASS} {} */

    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
        super.ngOnInit();
        /* {CLOSE:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
    }
    
    ngOnDestroy() {
        /* {OPEN:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
        super.ngOnDestroy();
        /* {CLOSE:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
    }

    /* {CLOSE:CLASS} {} */

    @ViewChild('form') form : NgForm;
    contactID;

    //@override
    constructor(
        public router : Router,
        public userService : UserService,
        public utilsService : UtilsService,
        public searchService : SearchService,
        public appService : AppService,
        public confirmationService: ConfirmationService,
        public createContactService: CreateContactService,
        public dialogRef: DynamicDialogRef,
        public dialogConfig: DynamicDialogConfig
    ) {
        super(router , userService , utilsService , searchService , appService , confirmationService , createContactService);
    }

    //@override
    executeScreenSpecificInitCode() {
        this.contactID = this.dialogConfig.data.contactID;
        this.getCRMConfigOptions();
    }

    getCRMConfigOptions() {
        if(localStorage.getItem('Owners')) {
            this.ownerOptions = JSON.parse(localStorage.getItem('Owners'));
        }
    }

    //@override
    onCreateContactClick(event, searchCreateContact) {
        this.utilsService.clearErrorMessages();

        if(!this.form.valid) {
            this.utilsService.showAllErrorMessages();
            return;
        }

        this.showLoader = true;

        const params = {
            contacts: [this.contactID],
            createAccounts: true,
            ownerId: this.searchCreateContact.Owner.id
        };

        this.subscriptions.push(
            this.createContactService.createContact(params).subscribe(() => {
                this.showLoader = false;
                this.dialogRef.close();
                this.utilsService.handleSuccessMessage('Contact created successfully');
            }, error => {
                this.showLoader = false;
                this.utilsService.handleError(error);
            })
        );
    }

    //@override
    getOwnerOptions(): any {
        return;
    }
}
/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */
