/* {OPEN:IMPORT} {"sha1":"BDC5071CF88928E95C995E4AE0E4A1428EA4247F"} */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseProjectComponent } from './base-project.component';
/* {CLOSE:IMPORT} {"sha1":"BDC5071CF88928E95C995E4AE0E4A1428EA4247F"} */
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../oneit/services/user.service';
import { UtilsService } from '../../oneit/services/utils.service';
import { SearchService } from '../../oneit/services/search.service';
import { AppService } from '../../app.service';
import { ConfirmationService } from 'primeng/api';
import { ProjectService } from './project.service';
import { FullLayoutComponent } from '../../oneit/components/full-layout/full-layout.component';
import { DialogService } from 'primeng';
import { ProjectDealPopUpComponent } from '../project-deal-pop-up/project-deal-pop-up.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ProjectDetailsService } from '../project-details/project-details.service';
import { Location } from '@angular/common';
/* {POSTCLOSE:IMPORT} {"sha1":"BDC5071CF88928E95C995E4AE0E4A1428EA4247F"} */

/* {PREOPEN:CLASS} {"sha1":"A7F45A1DFF9879D90F7DFFD162B9F23C8108BA13"} */
@Component({
    selector: 'app-project',
    templateUrl: './project-fix.component.html'
})
export class ProjectComponent extends BaseProjectComponent implements OnInit, OnDestroy {
    /* {OPEN:CLASS} {} */

    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
        super.ngOnInit();
        /* {CLOSE:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
    }

    ngOnDestroy() {
        /* {OPEN:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
        super.ngOnDestroy();
        /* {CLOSE:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
    }

    /* {CLOSE:CLASS} {} */

    project;
    roles;
    updates;
    module = 'projects';
    projectDetailsCol = [{header: 'Project Details', noSort: true, noFilter: true }];
    projectStatusCol = [{header: 'Project Status', noSort: true, noFilter: true }];
    categoryTableCol = [{header: 'Category', noSort: true, noFilter: true }];
    sectorTableCol = [{header: 'Sector', noSort: true, noFilter: true }];
    stagesTableCol = [{header: 'Stages ', noSort: true, noFilter: true }];
    planinfoTableCol = [{header: 'Planning Information', noSort: true, noFilter: true}];
    sizeTableCol = [{header: 'Size ', noSort: true, noFilter: true }];
    timingTableCol = [{header: 'Timing (Confirmed)', noSort: true, noFilter: true }];
    developmentTypeTableCol = [{header: 'Development Type', noSort: true, noFilter: true }];
    materialsTableCol = [{header: 'Materials', noSort: true, noFilter: true}];
    additionalInfoTableCol = [{header: 'Additional Information', noSort: true, noFilter: true}];
    componentInView = new Subject();
    owners;
    stages;
    rolesFetched = false;
    updatesFetched = false;
    dealsFetched = false;

    //@override
    constructor(
        public router: Router,
        public userService: UserService,
        public utilsService: UtilsService,
        public searchService: SearchService,
        public appService: AppService,
        public confirmationService: ConfirmationService,
        public projectService: ProjectService,
        public fullLayoutComponent: FullLayoutComponent,
        public dialogService: DialogService,
        public activatedRoute: ActivatedRoute,
        private location: Location
    ) {
        super(router, userService, utilsService, searchService, appService, confirmationService, projectService);
    }

    //@override
    executeScreenSpecificInitCode() {
        this.getProjectRelatedData();
        this.owners = JSON.parse(localStorage.getItem('Owners'));
        this.stages = JSON.parse(localStorage.getItem('Stages'));
    }

    getProjectRelatedData() {
        this.subscriptions.push(
            this.activatedRoute.params.subscribe(params => {
                if(params.id) {
                    this.getProjectByID(params.id);
                }
            })
        );
    }

    getProjectByID(id) {
        this.showLoader = true;

        this.subscriptions.push(
            this.projectService.getProjectByID(id , this.module).subscribe(data => {
                this.project = data;
                this.getProjectTabDetails(id , 'roles');
                this.getProjectTabDetails(id , 'updates');
                this.getProjectDeals(id);
            }, error => {
                this.showLoader = false;
                this.utilsService.handleError(error);
            })
        );
    }

    getProjectTabDetails(id , tab) {
        this.showLoader = true;

        this.subscriptions.push(
            this.projectService.getProjectTabDetails(id , this.module , tab).subscribe(data => {

                if(tab === 'roles') {
                    this.roles = data;
                    this.rolesFetched = true;
                }

                if(tab === 'updates') {
                    this.updates = data;
                    this.updatesFetched = true;
                }

                if(this.dealsFetched && this.rolesFetched && this.updatesFetched) {
                    this.showLoader = false;
                }

            }, error => {
                this.showLoader = false;
                this.utilsService.handleError(error);
            })
        );
    }

    getProjectDeals(id) {
        this.showLoader = true;

        this.appService.getModuleDeals(id, this.module).subscribe(data => {
            this.dealsFetched = true;
            this.deals = data;

            this.deals && this.deals.forEach(deal => {
                this.owners.forEach(owner => {
                    if(deal.ownerId === owner.id) {
                        deal.ownerName = owner.name;
                    }
                })
                this.stages.forEach(stage => {
                  if(deal.stageName === stage.id) {
                      deal.stageName = stage.value;
                  }
              })
            });

            if(this.dealsFetched && this.rolesFetched && this.updatesFetched) {
                this.showLoader = false;
            }
        }, error => {
            this.showLoader = false;
            this.utilsService.handleError(error);
        })
    }

    //@override
    onUpdateCrmClick(event, searchProject) {
        this.utilsService.clearErrorMessages();

        this.showLoader = true;

        const params = {
            projects: [this.project.id]
        };

        this.subscriptions.push(
            this.appService.updateCRM(params).subscribe(() => {
                this.showLoader = false;
                this.utilsService.handleSuccessMessage('CRM updated successfully');
                this.getProjectRelatedData();
            }, error => {
                this.showLoader = false;
                this.utilsService.handleError(error);
            })
        );
    }

    //@override
    onCreateDealClick(event, searchProject) {
        this.utilsService.clearErrorMessages();

        this.fullLayoutComponent.dialogOpen = true;

        this.dialogService.open(ProjectDealPopUpComponent, {
            header: 'Create Deal',
            ...this.appService.dynamicDialogConfig,
            data: {project: this.project,  companies: this.roles.map(role => role.company)}
        }).onClose.pipe(takeUntil(this.componentInView)).subscribe(() => {
            this.utilsService.clearErrorMessages();
            this.fullLayoutComponent.dialogOpen = false;
            this.getProjectRelatedData();
        });
    }

    //@override
    onCloseClick(event, searchProject) {
        this.utilsService.clearErrorMessages();
        // this.location.back();
        this.router.navigate(['./dashboard']);
    }

    //@override
    onStarClick(event, searchProject) {
        this.utilsService.clearErrorMessages();

        if(this.project.favourite) {
            return;
        }

        this.showLoader = true;

        let params = {
            favourite: true
        };

        this.subscriptions.push(
            this.projectService.markModuleAsFavorite(params , this.module , this.project.id).subscribe(() => {
                this.showLoader = false;
                this.getProjectByID(this.project.id);
            }, error => {
                this.showLoader = false;
                this.utilsService.handleError(error);
            })
        );
    }

    //@override
    getRolesTableCols() : any[] {
        return [
            { field: 'role.roleDescription', header: 'Role', width: '10px', noSort: false, noFilter: false },
            { field: 'company.name', header: 'Company', width: '10px', noSort: false, noFilter: false },
            { field: 'people.fullName', header: 'Contact', width: '10px', noSort: false, noFilter: false }
        ];
    }

    //@override
    getDealsTableCols() : any[] {
        return [
            { field: 'name', header: 'Name', width: '10px', noSort: false, noFilter: false },
            { field: 'stageName', header: 'Stage', width: '10px', noSort: false, noFilter: false },
            { field: 'ownerName', header: 'Owner', width: '10px', noSort: false, noFilter: false },
            { field: 'amount', header: 'Amount', width: '10px', noSort: false, noFilter: false },
            { field: 'closeDate', header: 'Closing Date', width: '10px', noSort: false, noFilter: false },
            { field: 'createdDate', header: 'Created', width: '10px', noSort: false, noFilter: false },
            { field: 'lastUpdated', header: 'Last Updated', width: '10px', noSort: false, noFilter: false }
        ];
    }

    //@override
    getUpdatesTableCols() : any[] {
        return [
            { field: 'description', header: 'Updates', width: '10px', noSort: false, noFilter: true },
            { field: 'updateDate', header: 'Date', width: '4px', noSort: false, noFilter: true }
        ];
    }

}

/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */
