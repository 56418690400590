import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UtilsService } from '../../services/utils.service';
import { SearchService } from './../../services/search.service';

@Injectable()
export class FeedbackService {

    feedbackInfo: any;
    public feedbackInitiated: boolean;
    
    constructor(
        private searchService: SearchService,
        private utilsService: UtilsService
    ) {
    }

    getFeedbackInfo(): Observable<any> {
        if (!this.feedbackInfo) {
            return this.searchService.getObjects("FeedbackInfo", null, {}, [], null, null, null, null).pipe(map(
                data => {
                    this.feedbackInfo = this.utilsService.cloneObject(data);
                    return data;
                }
            ));
        }
        return of(this.utilsService.cloneObject(this.feedbackInfo));
    }
}