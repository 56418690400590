<p-messages key="sendPasswordResetEmail"></p-messages>
<form #form="ngForm" (keyup)="handleKeyUp($event)" [class.pointer-events-none]="this.showLoader">
	<div class="ui-g form-group">
		<div class="ui-g-12 ui-md-12">
			<h4 style="margin-top: 0px">Forgot your password?</h4>
		</div>
	</div>
	<div>
		<div class="ui-g form-group login-form-field">
			<div class="ui-g-12 ui-md-12">
                <span class="ui-float-label">
                    <input id="username-input" [(ngModel)]="searchForgotPassword.EnterEmailAddress" required name="EmailAddress" fieldLabel="EmailAddress" type="text" pInputText class="ui-inputtext">
                    <label for="username-input">Please enter your email address</label>
                </span>
			</div>
		</div>
		<div class="ui-g form-group">
			<div class="ui-g-12 ui-md-12  ui-fluid">
				<p-button [disabled]="disablePasswordResetBtn" id="requestPasswordReset" label="Request Password Reset" icon="ui-icon-send" class="box-full-width-btn"  (onClick)="onContinueClick($event, searchForgotPassword)" ></p-button>
			</div>
		</div>
	</div>
	<div class="ui-g form-group">
		<div class="ui-g-12 ui-md-12  ui-fluid" style="text-align: center">
			<a [routerLink]="['/login']" >Back to Login</a>
		</div>
	</div>
	<p-progressSpinner *ngIf="showLoader"></p-progressSpinner>
</form>
