import { AppService } from 'src/app/app.service';
import { Input, Output, EventEmitter } from '@angular/core';
import { GoogleMapsService } from './../services/google-maps.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { callbackify } from 'util';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';

@Component({
  selector: 'map-search-view',
  templateUrl: './map-search-view.component.html',
  styleUrls: ['./map-search-view.component.scss']
})
export class MapSearchViewComponent implements OnInit {

  @Input()
  projectsOrCompanies = [];

  @Input()
  hoveredItem;

  @Output()
  addFavouriteEmitter = new EventEmitter();

  options : google.maps.MapOptions;
  center: google.maps.LatLngLiteral;
  selectedItem;

  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;

  constructor(private mapsService: GoogleMapsService,
    private appService: AppService) {
  }

  apiLoaded;

  ngOnInit(): void {
    this.center = {lat: 53.445, lng: 2.554};
    this.setCurrentLocation()
    this.apiLoaded = this.mapsService.isApiLoaded$;
  }

  initializeMapOptions() {
    this.options = {
    streetViewControl: false,
    };
  }

  setCentre(lat, lng) {
    this.center ={ lat: lat, lng: lng};
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        let latitude = position.coords.latitude;
        let longitude = position.coords.longitude;
        this.setCentre(latitude, longitude);
      });
    }
  }

  getMarkerPosition(item) {
    let latlng = new google.maps.LatLng({lat: Number(item.latitude), lng: Number(item.longitude)});
    return latlng;
  }

  openInfoWindow(marker: MapMarker, item) {
    this.selectedItem = item;
    this.infoWindow.open(marker);
  }

  closeInfoWindow() {
    this.infoWindow.close();
  }

  onAddFavouriteClick(item) {
    this.addFavouriteEmitter.emit(item);
  }


  getMarkerOptions(item) {
    let anim = null;
    // if(this.hoveredItem?.id === item?.id) anim=google.maps.Animation.BOUNCE;
    return {draggable: false, animation: anim};
  }

  getItemTitle(item) {
    if(this.appService.isProjectsSelected()) return item?.title;
    if(this.appService.isCompaniesSelected()) return item?.name;
  }

}
