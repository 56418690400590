import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SearchService } from "../../services/search.service";
import { UtilsService } from './../../services/utils.service';

@Injectable()
export class HeaderService {

    constructor(
        private searchService: SearchService,
        private utilsService: UtilsService
    ) {
    }

    getOmniOptionsForAutocomplete(searchText, assocs = []): Observable<any[]> {
        return this.searchService.getObjects('OmniSearch', 'All', { Details: searchText }, assocs, null, null, null, null)
            .pipe(
                map(
                    data => {
                        return this.utilsService.convertResponseToObjects(data, assocs);
                    }
                )
            );
    }
}