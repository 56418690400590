/* {OPEN:IMPORT} {"sha1":"C1FD52450EF0A9E2810655818FF06F2035195AE5"} */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseForgotPasswordComponent } from './base-forgot-password.component';
/* {CLOSE:IMPORT} {"sha1":"C1FD52450EF0A9E2810655818FF06F2035195AE5"} */
import { ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../oneit/services/user.service';
import { UtilsService } from '../../oneit/services/utils.service';
import { SearchService } from '../../oneit/services/search.service';
import { AppService } from '../../app.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ForgotPasswordService } from './forgot-password.service';
/* {POSTCLOSE:IMPORT} {"sha1":"C1FD52450EF0A9E2810655818FF06F2035195AE5"} */

/* {PREOPEN:CLASS} {"sha1":"431BCBA46EA188C6D152D2DD0EE0CF38B2961241"} */
@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent extends BaseForgotPasswordComponent implements OnInit, OnDestroy {
    /* {OPEN:CLASS} {} */

    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
        super.ngOnInit();
        /* {CLOSE:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
    }

    ngOnDestroy() {
        /* {OPEN:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
        super.ngOnDestroy();
        /* {CLOSE:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
    }

    /* {CLOSE:CLASS} {} */

    @ViewChild('form') form: NgForm;
    disablePasswordResetBtn = false;

    //@override
    constructor(
        public router : Router,
        public userService : UserService,
        public utilsService : UtilsService,
        public searchService : SearchService,
        public appService : AppService,
        public confirmationService: ConfirmationService,
        public forgotPasswordService : ForgotPasswordService,
        public messageService :  MessageService
    ) {
        super(router , userService , utilsService ,searchService , appService , confirmationService , forgotPasswordService);
    }

    //@override
    onContinueClick(event, searchForgotPassword) {
        this.utilsService.clearErrorMessages();
        this.messageService.clear();
        this.disablePasswordResetBtn = true;

        if ( !this.form.valid ) {
            this.utilsService.showAllErrorMessages();
            this.disablePasswordResetBtn = false;
            return;
        }

        this.showLoader = true;

        const params = {
            userEmail: this.searchForgotPassword.EnterEmailAddress
        };

        this.forgotPasswordService.forgotPassword(params).subscribe(() => {
            this.showLoader = false;
            this.disablePasswordResetBtn = false;
            this.messageService.add({key:'sendPasswordResetEmail' , severity:'info', detail:'You should soon receive an email allowing you to reset your password. Please make sure to check your spam and trash if you can\'t find the email.' , life: 2000});
            this.form.reset();
        }, error => {
            this.showLoader = false;
            this.disablePasswordResetBtn = false;
            this.utilsService.handleError(error);
        })
    }

    handleKeyUp(event) {
        if(event.keyCode === 13 && !this.disablePasswordResetBtn){
            document.getElementById('requestPasswordReset').click();
        }
    }
}

/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */
