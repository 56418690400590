import { FusionUser } from './../add-user/user.model';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppService } from 'src/app/app.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class  AdminDashboardService{

  constructor(
    private appService: AppService,
    public httpClient: HttpClient,
    ) {}
    backURL = environment.baseUrl;

  getOrganizations(): Observable<any> {
    return this.appService.httpGetRequest(`${this.backURL}organizations` , null);
  }

  createOrganization(params): Observable<any> {
    return this.appService.httpPostRequest(`${this.backURL}organizations` , params);
  }

  getUsersForOrg(id): Observable<any> {
    return this.appService.httpGetRequest(`${this.backURL}organizations/${id}/users` , null);
  }

  addUserToOrg(user: FusionUser) : Observable<any> {
    return this.appService.httpPostRequest(`${this.backURL}users` , user);
  }
}
