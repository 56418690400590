import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[oneitfocus]'
})

export class FocusDirective implements OnInit {

    @Input('oneitfocus') isFocused: boolean;

    constructor(
        private element: ElementRef
    ) { }

    ngOnInit() {
        this.focus();
    }

    resetFocus() {
        this.focus();
    }

    private focus() {
        setTimeout(() => {
            if (this.isFocused) {
                this.element.nativeElement.focus();
            }
        });
    }
}
