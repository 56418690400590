/* {OPEN:IMPORT} {"sha1":"579A0B279580316C0C23A1AFD7A71FDFCD7217F0"} */
import { Component, DoCheck, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import { Message, MenuItem, ConfirmationService } from 'primeng/api';
import { AppService } from './../../app.service';
import { OptionsObservable, OptionsWrapper } from './../../oneit/models/options.model';
import { UserService } from './../../oneit/services/user.service';
import { UtilsService } from './../../oneit/services/utils.service';
import { SearchService } from './../../oneit/services/search.service';
import { SaveService } from './../../oneit/services/save.service';
import { ResetPasswordService } from './reset-password.service';
import { ResetPassword } from './reset-password.model';
/* {CLOSE:IMPORT} {"sha1":"579A0B279580316C0C23A1AFD7A71FDFCD7217F0"} */

@Component({
    selector: 'app-base-reset-password',
    template: ''
})
/* {PREOPEN:CLASS} {"sha1":"3C0BE9BB5A7BE99C7746F226EDE8C96AD3D533FA"} */
export class BaseResetPasswordComponent implements DoCheck, OnInit, OnDestroy {
    /* {OPEN:CLASS} {} */
    
    /* {OPEN:COMMON_VARS} {"sha1":"C1F402099938C2833736AE7A36CDE508644AC66E"} */
    @Input() parent : any;
    @ViewChild('form') form: NgForm;
    createdObjs =   {};
    updatedObjs =   {};
    deletedObjs =   {};
    isScreenEditable: boolean = true;
    pageTitle : string;
    /* {CLOSE:COMMON_VARS} {"sha1":"C1F402099938C2833736AE7A36CDE508644AC66E"} */

    /* {OPEN:VARS} {"sha1":"AFB15ED73C9D39BC58B41320A6C73170DFAA668F"} */
    resetPassword : ResetPassword = new ResetPassword();
    /* {CLOSE:VARS} {"sha1":"AFB15ED73C9D39BC58B41320A6C73170DFAA668F"} */
    
    showResults : boolean = false;
    showLoader : boolean = false;
    subscriptions : Array<Subscription> = [];

    constructor(
        /* {OPEN:constructor} {"sha1":"42F7D1A4C64AC7BD43359F1B3599F9A3E6581CC8"} */
        public router : Router,
        public activatedRoute : ActivatedRoute,
        public userService : UserService,
        public utilsService : UtilsService,
        public searchService : SearchService,
        public saveService : SaveService,
        public appService : AppService,
        public confirmationService: ConfirmationService,
        public resetPasswordService : ResetPasswordService,
        /* {CLOSE:constructor} {"sha1":"42F7D1A4C64AC7BD43359F1B3599F9A3E6581CC8"} */
    ){
        
    }

    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"5FBDAAB044BC4F999356808F755A7334E11EE273"} */
        this.resetInitVariables();
        this.calculateEverything();
        this.executeScreenSpecificInitCode();
        /* {CLOSE:ngOnInit} {"sha1":"5FBDAAB044BC4F999356808F755A7334E11EE273"} */
    }

    /* {PREOPEN:executeScreenSpecificInitCode} {"sha1":"DDD081544316B33777AB7B7FFD796FB9E663E681"} */
    executeScreenSpecificInitCode() {
        /* {OPEN:executeScreenSpecificInitCode} {"sha1":"41259DFB8F466E5D534A5140DBB704990FB30495"} */
        if(!this.parent) {
            this.utilsService.resetCounter();
            
            this.subscriptions.push(this.activatedRoute.params
                .subscribe(
                    (params: Params) => {
                        this.showLoader = true;
                        if (params.id && params.id !== '0') {
                            this.getResetPasswordByID(params.id);
                        } else {
                            this.createNewObjects();
                            this.showLoader = false;
                        }
                    }
            ));
        }
        /* {CLOSE:executeScreenSpecificInitCode} {"sha1":"41259DFB8F466E5D534A5140DBB704990FB30495"} */
    }
    /* {POSTCLOSE:executeScreenSpecificInitCode} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:createNewObjects} {"sha1":"8ECE6780C82DF87169B6F229C72D15FA10367809"} */
    createNewObjects() {
        /* {OPEN:createNewObjects} {"sha1":"6012B1C08BED05057304C0DEAA70977CC4D6085A"} */
        this.utilsService.createObject(this.resetPassword, this.createdObjs);
        this.resetScreenEditability();
        /* {CLOSE:createNewObjects} {"sha1":"6012B1C08BED05057304C0DEAA70977CC4D6085A"} */
    }
    /* {POSTCLOSE:createNewObjects} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getResetPasswordByID} {"sha1":"998BA9E0FD4294C7887BFABE7E20C8AEE826D214"} */
    getResetPasswordByID(id) {
        /* {OPEN:getResetPasswordByID} {"sha1":"B563FF89B448971ABBCCDFCB8F76F25855D76F51"} */
        this.showLoader = true;
        this.createdObjs = {};
        this.updatedObjs = {};
        this.deletedObjs = {};
        this.utilsService.resetCounter();
        this.utilsService.clearErrorMessages();
        
        this.subscriptions.push(this.getResetPasswordByIDObservable(id)
            .subscribe(
                data => {
                    this.onResetPasswordByIDSuccess(data);
                },
                error => {
                    this.showLoader = false;
                    this.utilsService.handleError(error);
                }
            )
        );
        /* {CLOSE:getResetPasswordByID} {"sha1":"B563FF89B448971ABBCCDFCB8F76F25855D76F51"} */
    }
    /* {POSTCLOSE:getResetPasswordByID} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */
    
    /* {PREOPEN:getResetPasswordByIDObservable} {"sha1":"4A9CCC75BCD717367929C25293282940C3AB4CAB"} */
    getResetPasswordByIDObservable(id) : Observable<any> {
        /* {OPEN:getResetPasswordByIDObservable} {"sha1":"1877E33AF8B04002BABD49B31FEC03A950FEE0BE"} */
        return this.resetPasswordService.getResetPasswordByID(id, this.getResetPasswordByIDAssocs());
        /* {CLOSE:getResetPasswordByIDObservable} {"sha1":"1877E33AF8B04002BABD49B31FEC03A950FEE0BE"} */
    }
    /* {POSTCLOSE:getResetPasswordByIDObservable} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getResetPasswordByIDAssocs} {"sha1":"D6C17C8B932F18146C1A7A1337CFCB687D6B98C6"} */
    getResetPasswordByIDAssocs() : string[] {
        /* {OPEN:getResetPasswordByIDAssocs} {"sha1":"88D53B76212DB5616439DB2F89023EFDD52D4337"} */
        return [];
        /* {CLOSE:getResetPasswordByIDAssocs} {"sha1":"88D53B76212DB5616439DB2F89023EFDD52D4337"} */
    }
    /* {POSTCLOSE:getResetPasswordByIDAssocs} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onResetPasswordByIDSuccess} {"sha1":"89963174023853B1FF732B6A895FF2A70F41EFDA"} */
    onResetPasswordByIDSuccess (data) {
        /* {OPEN:onResetPasswordByIDSuccess} {"sha1":"4492926E150CB29C070278CC8DB0B82CBFC7495D"} */
        this.resetPassword = data;
        this.updatedObjs[this.resetPassword.ObjectID] = this.resetPassword;
        this.calculateEverything();
        this.resetScreenEditability();
        this.onResetPasswordByIDPostSuccess(data);
        this.showLoader = false;
        /* {CLOSE:onResetPasswordByIDSuccess} {"sha1":"4492926E150CB29C070278CC8DB0B82CBFC7495D"} */
    }
    /* {POSTCLOSE:onResetPasswordByIDSuccess} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onResetPasswordByIDPostSuccess} {"sha1":"5A70862EF48152CDFC73091BD2CFCF298511CBF7"} */
    onResetPasswordByIDPostSuccess(data) {
        /* {OPEN:onResetPasswordByIDPostSuccess} {"sha1":"3AA7D8790DF28B84EA45CCE1CF8BE125FDB6DBC0"} */
        //Loop over other multirefs here, which can be updated on screen
        /* {CLOSE:onResetPasswordByIDPostSuccess} {"sha1":"3AA7D8790DF28B84EA45CCE1CF8BE125FDB6DBC0"} */
    }
    /* {POSTCLOSE:onResetPasswordByIDPostSuccess} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:saveResetPassword} {"sha1":"BEF7D14C9AF9ACF72078CE3F75F2C6E26722C8E5"} */
    saveResetPassword(reload: boolean) {
        /* {OPEN:saveResetPassword} {"sha1":"92626ED2812F8EC9891209C35B862FC0E13AEC1A"} */
        this.utilsService.clearErrorMessages();
        
        if (this.form.invalid) {
            this.utilsService.showAllErrorMessages();
        } else {
            this.showLoader = true;
            this.subscriptions.push(this.getSaveResetPasswordObservable()
                .subscribe(
                    data => {
                        this.onSaveResetPasswordSuccess(data, reload);
                    },
                    error => {
                        this.showLoader = false;
                        this.utilsService.handleError(error);
                    }
                )
            );
        }
        /* {CLOSE:saveResetPassword} {"sha1":"92626ED2812F8EC9891209C35B862FC0E13AEC1A"} */
    }
    /* {POSTCLOSE:saveResetPassword} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */
    
    /* {PREOPEN:getSaveResetPasswordObservable} {"sha1":"DA452CC6683CC28DA82E88B83945A52320CB5C4C"} */
    getSaveResetPasswordObservable() : Observable<any> {
        /* {OPEN:getSaveResetPasswordObservable} {"sha1":"CA9A64995542A8ADB856CBDCED69CB39ECAF0E80"} */
        return this.saveService.saveObjectsWithDefaultSvc(this.createdObjs, this.updatedObjs, this.deletedObjs);
        /* {CLOSE:getSaveResetPasswordObservable} {"sha1":"CA9A64995542A8ADB856CBDCED69CB39ECAF0E80"} */
    }
    /* {POSTCLOSE:getSaveResetPasswordObservable} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onSaveResetPasswordSuccess} {"sha1":"629CD66FE3C7FF876C256418F63FB955B4A7FFC2"} */
    onSaveResetPasswordSuccess(data, reload) {
        /* {OPEN:onSaveResetPasswordSuccess} {"sha1":"A69C428F748409DB43DBB2D432E097B0C49F406E"} */
        this.utilsService.handleSuccess();
        if (reload) {
            let idToNavigate = this.resetPassword.ObjectID;
            if (data["created"][idToNavigate]) {
                idToNavigate = data["created"][idToNavigate];
                this.router.navigate(this.getSaveResetPasswordReloadRoute(data, idToNavigate));
                return;
            }
            this.getResetPasswordByID(idToNavigate);
        } else {
            this.router.navigate(this.getSaveResetPasswordBackRoute(data));
        }
        /* {CLOSE:onSaveResetPasswordSuccess} {"sha1":"A69C428F748409DB43DBB2D432E097B0C49F406E"} */
    }
    /* {POSTCLOSE:onSaveResetPasswordSuccess} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getSaveResetPasswordReloadRoute} {"sha1":"03D95DB657D2FF57C676BC07F5D06A15F9AF752E"} */
    getSaveResetPasswordReloadRoute(data, idToNavigate) {
        /* {OPEN:getSaveResetPasswordReloadRoute} {"sha1":"CDFFEF4C2E600C7EBF0D0F618D72DC862793751A"} */
        return ['/reset-password', idToNavigate];
        /* {CLOSE:getSaveResetPasswordReloadRoute} {"sha1":"CDFFEF4C2E600C7EBF0D0F618D72DC862793751A"} */
    }
    /* {POSTCLOSE:getSaveResetPasswordReloadRoute} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getSaveResetPasswordBackRoute} {"sha1":"AC77D22B089CB6B7F856A213C4986C3FB84E23D5"} */
    getSaveResetPasswordBackRoute(data) {
        /* {OPEN:getSaveResetPasswordBackRoute} {"sha1":"F502F30E7D5E94547070F04EAF9F51EA3B67B4E8"} */
        return [''];
        /* {CLOSE:getSaveResetPasswordBackRoute} {"sha1":"F502F30E7D5E94547070F04EAF9F51EA3B67B4E8"} */
    }
    /* {POSTCLOSE:getSaveResetPasswordBackRoute} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onResetPasswordClick} {"sha1":"BFF9B5C313F95A226B045A7716E225A3C9DE8613"} */
    onResetPasswordClick(event, resetPassword) {
        /* {OPEN:onResetPasswordClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onResetPasswordClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onResetPasswordClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:resetInitVariables} {"sha1":"846C0DF64879342578D595FE17BC00B96F0BAED6"} */
    resetInitVariables() {
        /* {OPEN:resetInitVariables} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:resetInitVariables} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:resetInitVariables} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getPageTitle} {"sha1":"174A3D30FFA103DD7BE180EE89CC0CF5EB6D11B8"} */
    getPageTitle() {
        /* {OPEN:getPageTitle} {"sha1":"278957CD311BC82687C654A948A10BA52364A6B3"} */
        return 'Reset Password';
        /* {CLOSE:getPageTitle} {"sha1":"278957CD311BC82687C654A948A10BA52364A6B3"} */
    }
    /* {POSTCLOSE:getPageTitle} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:calculateEverything} {"sha1":"CE17BF2375171825A37C544A1DCBF3A565DD5181"} */
    calculateEverything() {
        /* {OPEN:calculateEverything} {} */
        /* {OPEN:calculateEverything_pageTitle} {"sha1":"B3CA5EAE3ED54D1F0D95EA2CD05351A4A6D7480F"} */
        try {
            this.pageTitle = this.getPageTitle();
        }
        catch (error) {
            console.error('Error occured when setting pageTitle');
        }
        /* {CLOSE:calculateEverything_pageTitle} {"sha1":"B3CA5EAE3ED54D1F0D95EA2CD05351A4A6D7480F"} */
        /* {CLOSE:calculateEverything} {} */
    }
    /* {POSTCLOSE:calculateEverything} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:ngDoCheck} {"sha1":"75839B27D41E20648CE296AC51D61E24D541A70A"} */
    ngDoCheck() {
        /* {OPEN:ngDoCheck} {"sha1":"A154E0663059B6D385B4F6C11094F2084FF5E184"} */
        this.calculateEverything();
        /* {CLOSE:ngDoCheck} {"sha1":"A154E0663059B6D385B4F6C11094F2084FF5E184"} */
    }
    /* {POSTCLOSE:ngDoCheck} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:ngOnDestroy} {"sha1":"C21B2137D2BD86DF7050E24A39F08355F04DA4E9"} */
    ngOnDestroy() {
        /* {OPEN:ngOnDestroy} {"sha1":"85AA65230B2C97013ABDD30D48BEB93C04E8EE54"} */
        this.utilsService.unsubscribeSubscriptions(this.subscriptions);
        /* {CLOSE:ngOnDestroy} {"sha1":"85AA65230B2C97013ABDD30D48BEB93C04E8EE54"} */
    }
    /* {POSTCLOSE:ngOnDestroy} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getIsScreenEditable} {"sha1":"7C78F3BEC815C21A6B96B7D9D6D1B5E295A3376A"} */
    getIsScreenEditable() {
        /* {OPEN:getIsScreenEditable} {"sha1":"9974894E3F1937A8E94179C434ACCFBCA163CA3D"} */
        return true;
        /* {CLOSE:getIsScreenEditable} {"sha1":"9974894E3F1937A8E94179C434ACCFBCA163CA3D"} */
    }
    /* {POSTCLOSE:getIsScreenEditable} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:resetScreenEditability} {"sha1":"A8CEFA011FA6C65180314F9EF1C8CAA514F0D18F"} */
    resetScreenEditability() {
        /* {OPEN:resetScreenEditability} {"sha1":"F95489415BA82AB7C8A76C44060D0134417B36BE"} */
        try {
            this.isScreenEditable = this.getIsScreenEditable();
        }
        catch (error) {
            console.error('Error occured when setting isScreenEditable');
        }
        /* {CLOSE:resetScreenEditability} {"sha1":"F95489415BA82AB7C8A76C44060D0134417B36BE"} */
    }
    /* {POSTCLOSE:resetScreenEditability} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */
    /* {CLOSE:CLASS} {} */
}
/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */