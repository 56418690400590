<!-- {PREOPEN:60f70a3e-71bd-4b29-adf9-a72f567c191d} {"sha1":"8C4A0E2E0EFD768CAB5850B359AF669A56A45AB4"} -->
<form #form="ngForm" [class.pointer-events-none]="this.showLoader">
	<!-- {OPEN:60f70a3e-71bd-4b29-adf9-a72f567c191d} {} -->
	<!-- {OPEN:Toolbar} {"sha1":"58C85CCF9FF02F3BAA012023D0982BED8C08259E"} -->
	<p-toolbar>
		<div class="ui-toolbar-group-left">
			{{ pageTitle }}
		</div>
	</p-toolbar>


<nav mat-tab-nav-bar [backgroundColor]="'primary'" [tabPanel]="tabPanel">
  <a
    mat-tab-link
    *ngFor="let link of getLinks() | keyvalue"
    (click)="setActive(link.key)"
    [active]="activeLink == link.key"
  >
   <span style="color: white;"> {{link.value}}
     </span>
  </a>
</nav>
<mat-tab-nav-panel #tabPanel>  <!-- {CLOSE:Toolbar} {"sha1":"58C85CCF9FF02F3BAA012023D0982BED8C08259E"} -->
	<!-- {PREOPEN:4a0cdde1-1ba1-4309-b7e9-1a57fc9bb48a} {"sha1":"D6CC3F938EF776CE850CFACB2F7FE7CBF60D8D36"} -->
	<ng-container *ngFor="let savedSearch of savedSearches">
    <container-element [ngSwitch]="dataProvider">
      <app-cis-saved-search *ngSwitchCase="'cis'" [savedSearch]="savedSearch" [cache] ="cacheFilters" [module]="module"></app-cis-saved-search>
      <app-barbour-saved-search *ngSwitchCase="'barbour'" [savedSearch]="savedSearch" [cache] ="cacheFilters"></app-barbour-saved-search>
    </container-element>
  </ng-container>
</mat-tab-nav-panel>
  <!-- {POSTCLOSE:4a0cdde1-1ba1-4309-b7e9-1a57fc9bb48a} {"sha1":"6B298AF7D979106E721F4D434037961D8874FB75"} -->
	<!-- {PREOPEN:c82a9897-03cc-4ada-b729-91f9849154e1} {"sha1":"5C82E9279C03EE56B871417DFD573023A16B0211"} -->
	<!-- {POSTCLOSE:c82a9897-03cc-4ada-b729-91f9849154e1} {"sha1":"6B298AF7D979106E721F4D434037961D8874FB75"} -->
	<oneit-confirmDialog></oneit-confirmDialog>
	<p-progressSpinner *ngIf="showLoader"></p-progressSpinner>
	<!-- {CLOSE:60f70a3e-71bd-4b29-adf9-a72f567c191d} {} -->
</form>
<!-- {POSTCLOSE:60f70a3e-71bd-4b29-adf9-a72f567c191d} {"sha1":"3FD6E659562C1876D02FC8CF54746B1502442CD2"} -->
