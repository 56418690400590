import { BarbourUtilServiceService } from './../barbour/barbour-util-service.service';
import { CisUtilServiceService } from './../cis/cis-util-service.service';
/* {OPEN:IMPORT} {"sha1":"A6FF44A9C0407C1F43EFE65CA387E1BC55EA5E90"} */
import { Component, DoCheck, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import { Message, MenuItem, ConfirmationService } from 'primeng/api';
import { AppService } from '../../app.service';
import { OptionsObservable, OptionsWrapper } from '../../oneit/models/options.model';
import { UserService } from '../../oneit/services/user.service';
import { UtilsService } from '../../oneit/services/utils.service';
import { SearchService } from '../../oneit/services/search.service';
import { SavedSearchesProviderService } from './saved-searches-provider.service';
import { SearchSavedProvider } from './saved-searches-provider.model';
/* {CLOSE:IMPORT} {"sha1":"A6FF44A9C0407C1F43EFE65CA387E1BC55EA5E90"} */

@Component({
    selector: 'app-base-saved-searches-provider',
    template: ''
})
/* {PREOPEN:CLASS} {"sha1":"B0E6442A83D68CFE8ECC7E36EB16B007498A0EF1"} */
export class BaseSavedSearchesProviderComponent implements DoCheck, OnInit, OnDestroy {
    /* {OPEN:CLASS} {} */

    /* {OPEN:COMMON_VARS} {"sha1":"672A211DEEA9A3559D121CBDC5978BBA47BD6B7D"} */
    @Input() parent : any;
    pageTitle : string;
    /* {CLOSE:COMMON_VARS} {"sha1":"672A211DEEA9A3559D121CBDC5978BBA47BD6B7D"} */

    /* {OPEN:VARS} {"sha1":"DE9B1AED43A4226E3AF628636E7B9320051A4420"} */
    searchSavedE : SearchSavedProvider = new SearchSavedProvider();
    /* {CLOSE:VARS} {"sha1":"DE9B1AED43A4226E3AF628636E7B9320051A4420"} */

    showResults : boolean = false;
    showLoader : boolean = false;
    subscriptions : Array<Subscription> = [];

    constructor(
        /* {OPEN:constructor} {"sha1":"4754F9B866FE5D1AE666004361CAE542003EF827"} */
        public router : Router,
        public userService : UserService,
        public utilsService : UtilsService,
        public searchService : SearchService,
        public appService : AppService,
        public confirmationService: ConfirmationService,
        public savedSearchesService : SavedSearchesProviderService,
        public cisService : CisUtilServiceService,
        public barbourService : BarbourUtilServiceService,
        /* {CLOSE:constructor} {"sha1":"4754F9B866FE5D1AE666004361CAE542003EF827"} */
    ){}

    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"5FBDAAB044BC4F999356808F755A7334E11EE273"} */
        this.resetInitVariables();
        this.calculateEverything();
        this.executeScreenSpecificInitCode();
        /* {CLOSE:ngOnInit} {"sha1":"5FBDAAB044BC4F999356808F755A7334E11EE273"} */
    }

    /* {PREOPEN:executeScreenSpecificInitCode} {"sha1":"DDD081544316B33777AB7B7FFD796FB9E663E681"} */
    executeScreenSpecificInitCode() {
        /* {OPEN:executeScreenSpecificInitCode} {"sha1":"54DB41AD32937D86C7318EB4BE666A1AD7B6B618"} */
        if(!this.parent) {
        }
        /* {CLOSE:executeScreenSpecificInitCode} {"sha1":"54DB41AD32937D86C7318EB4BE666A1AD7B6B618"} */
    }
    /* {POSTCLOSE:executeScreenSpecificInitCode} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRouterLinkForDevelopmentProject} {"sha1":"C35802C1EADD3CCC6421EB90895C2EE7A6762C0B"} */
    getRouterLinkForDevelopmentProject(searchSavedE) {
        /* {OPEN:getRouterLinkForDevelopmentProject} {"sha1":"77D181A879CC55BFA61DD79A4E31FE7E30BE059F"} */
        return ['/search-projects'];
        /* {CLOSE:getRouterLinkForDevelopmentProject} {"sha1":"77D181A879CC55BFA61DD79A4E31FE7E30BE059F"} */
    }
    /* {POSTCLOSE:getRouterLinkForDevelopmentProject} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRouterLinkFor01March2021} {"sha1":"A844145CBA6CC8A451EE90AED8A63855A678D52B"} */
    getRouterLinkFor01March2021(searchSavedE) {
        /* {OPEN:getRouterLinkFor01March2021} {"sha1":"77D181A879CC55BFA61DD79A4E31FE7E30BE059F"} */
        return ['/search-projects'];
        /* {CLOSE:getRouterLinkFor01March2021} {"sha1":"77D181A879CC55BFA61DD79A4E31FE7E30BE059F"} */
    }
    /* {POSTCLOSE:getRouterLinkFor01March2021} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRouterLinkForDesignProjects} {"sha1":"78A8CE882FEF6D250553E3334E3BE3FD1D2A715F"} */
    getRouterLinkForDesignProjects(searchSavedE) {
        /* {OPEN:getRouterLinkForDesignProjects} {"sha1":"0559A73746492023C935099015D53473799ACC5E"} */
        return ['/table-view'];
        /* {CLOSE:getRouterLinkForDesignProjects} {"sha1":"0559A73746492023C935099015D53473799ACC5E"} */
    }
    /* {POSTCLOSE:getRouterLinkForDesignProjects} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRouterLinkFor15March2021} {"sha1":"C586C85BD49465A6ECB9B1129C1FDFBFA6F0E39A"} */
    getRouterLinkFor15March2021(searchSavedE) {
        /* {OPEN:getRouterLinkFor15March2021} {"sha1":"77D181A879CC55BFA61DD79A4E31FE7E30BE059F"} */
        return ['/search-projects'];
        /* {CLOSE:getRouterLinkFor15March2021} {"sha1":"77D181A879CC55BFA61DD79A4E31FE7E30BE059F"} */
    }
    /* {POSTCLOSE:getRouterLinkFor15March2021} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:resetInitVariables} {"sha1":"846C0DF64879342578D595FE17BC00B96F0BAED6"} */
    resetInitVariables() {
        /* {OPEN:resetInitVariables} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:resetInitVariables} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:resetInitVariables} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getPageTitle} {"sha1":"174A3D30FFA103DD7BE180EE89CC0CF5EB6D11B8"} */
    getPageTitle() {
        /* {OPEN:getPageTitle} {"sha1":"2B92BA95B5CBCAF764EF5FB198D353B08A3C69D0"} */
        return 'Saved Searches';
        /* {CLOSE:getPageTitle} {"sha1":"2B92BA95B5CBCAF764EF5FB198D353B08A3C69D0"} */
    }
    /* {POSTCLOSE:getPageTitle} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:calculateEverything} {"sha1":"CE17BF2375171825A37C544A1DCBF3A565DD5181"} */
    calculateEverything() {
        /* {OPEN:calculateEverything} {} */
        /* {OPEN:calculateEverything_pageTitle} {"sha1":"B3CA5EAE3ED54D1F0D95EA2CD05351A4A6D7480F"} */
        try {
            this.pageTitle = this.getPageTitle();
        }
        catch (error) {
            console.error('Error occured when setting pageTitle');
        }
        /* {CLOSE:calculateEverything_pageTitle} {"sha1":"B3CA5EAE3ED54D1F0D95EA2CD05351A4A6D7480F"} */
        /* {CLOSE:calculateEverything} {} */
    }
    /* {POSTCLOSE:calculateEverything} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:ngDoCheck} {"sha1":"75839B27D41E20648CE296AC51D61E24D541A70A"} */
    ngDoCheck() {
        /* {OPEN:ngDoCheck} {"sha1":"A154E0663059B6D385B4F6C11094F2084FF5E184"} */
        this.calculateEverything();
        /* {CLOSE:ngDoCheck} {"sha1":"A154E0663059B6D385B4F6C11094F2084FF5E184"} */
    }
    /* {POSTCLOSE:ngDoCheck} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:ngOnDestroy} {"sha1":"C21B2137D2BD86DF7050E24A39F08355F04DA4E9"} */
    ngOnDestroy() {
        /* {OPEN:ngOnDestroy} {"sha1":"85AA65230B2C97013ABDD30D48BEB93C04E8EE54"} */
        this.utilsService.unsubscribeSubscriptions(this.subscriptions);
        /* {CLOSE:ngOnDestroy} {"sha1":"85AA65230B2C97013ABDD30D48BEB93C04E8EE54"} */
    }
    /* {POSTCLOSE:ngOnDestroy} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {CLOSE:CLASS} {} */
}
/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */
