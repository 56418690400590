<div class="layout-sidebar" 
    [ngClass]="{'layout-sidebar-active': app.sidebarActive, 
                'layout-sidebar-dark': app.darkMenu,
                'layout-sidebar-mobile': app.isMobile()}"
    (click)="app.onSidebarClick($event)" (mouseover)="app.sidebarActive=true" (mouseleave)="app.sidebarActive=false">
    <div class="sidebar-logo" *ngIf="app.isMobile()">
        <a routerLink="/">
            <img alt="logo" [src]="logoURL" />
            <span class="app-name"></span>
        </a>
    </div>

    <div class="scrollable-menu-container">
        <ul app-submenu [item]="mainMenuItems" root="true" class="layout-menu" visible="true" [reset]="reset" [level]="0"></ul>
    </div>

</div>