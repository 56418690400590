<div>
  <mat-form-field appearance="outline">
    <mat-label>Minimum</mat-label>
    <input
      matInput
      type="number"
      min="0"
      [(ngModel)]="metricData.minValue"
      (change)="onChangeMin($event)"
    />
  </mat-form-field>
  <br />
  <mat-form-field appearance="outline">
    <mat-label>Maximum</mat-label>
    <input
      matInput
      type="number"
      min="0"
      [(ngModel)]="metricData.maxValue"
      (change)="onChangeMax($event)"
    />
  </mat-form-field>
</div>
