<p-messages [(value)]="errorMsgs" *ngIf="errorMsgs && errorMsgs.length > 0"></p-messages>
<!-- {PREOPEN:a7d6a0e4-0826-470a-aa50-a718a36fafe9} {"sha1":"8C4A0E2E0EFD768CAB5850B359AF669A56A45AB4"} -->
<form #form="ngForm" [class.pointer-events-none]="this.showLoader">
	<!-- {OPEN:a7d6a0e4-0826-470a-aa50-a718a36fafe9} {} -->
	<!-- {OPEN:a2fd18b0-bd8f-49a6-928e-53312861c6d6} {} -->
	<!-- {PREOPEN:01a6f32f-673f-40dc-826d-8cc50ff2eb88} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
	<div class="ui-g form-group">
		<!-- {OPEN:01a6f32f-673f-40dc-826d-8cc50ff2eb88} {} -->
		<!-- {PREOPEN:13154de0-1d0a-47fc-b974-f8805cd6333a} {"sha1":"2183A3A9ABCF2FD844436EF69F76172FF147BB12"} -->
		<div class="ui-g-12 ui-md-12">
			<!-- {OPEN:13154de0-1d0a-47fc-b974-f8805cd6333a} {} -->
			<!-- {OPEN:e2e09310-cf2b-4a3e-b592-9550fcc49ba4} {"sha1":"D2E90307FB491B1908508DE578DFBF1E0E130D6D"} -->
			<div class="ui-fluid" *ngIf="!showLoader">
                <span class="ui-float-label sticky">
                    <p-dropdown required [(ngModel)]="searchDataProvider.DataProvider"
								name="DataProvider{{ searchDataProvider.ObjectID }}"
                optionLabel="label"
                optionValue="value"
								fieldLabel="Data Provider"
								(onChange)="onDataProviderChange($event, searchDataProvider)"
								[options]="dataProviderOptions"></p-dropdown>
                    <label>Data Provider</label>
                </span>
			</div>
			<!-- {CLOSE:e2e09310-cf2b-4a3e-b592-9550fcc49ba4} {"sha1":"D2E90307FB491B1908508DE578DFBF1E0E130D6D"} -->
			<!-- {CLOSE:13154de0-1d0a-47fc-b974-f8805cd6333a} {} -->
		</div>
		<!-- {POSTCLOSE:13154de0-1d0a-47fc-b974-f8805cd6333a} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
		<!-- {CLOSE:01a6f32f-673f-40dc-826d-8cc50ff2eb88} {} -->
	</div>
	<!-- {POSTCLOSE:01a6f32f-673f-40dc-826d-8cc50ff2eb88} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
	<!-- {PREOPEN:1656bc07-34fb-46a3-a4c5-07ad7d91a1a0} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
  <div *ngIf="searchDataProvider.DataProvider.value === 'barbour'">
  	<div class="ui-g form-group">
		<!-- {OPEN:1656bc07-34fb-46a3-a4c5-07ad7d91a1a0} {} -->
		<!-- {PREOPEN:98587c7c-2fa9-4af1-b1b7-66cdc4fc8c67} {"sha1":"2183A3A9ABCF2FD844436EF69F76172FF147BB12"} -->
		<div class="ui-g-12 ui-md-12">
			<!-- {OPEN:98587c7c-2fa9-4af1-b1b7-66cdc4fc8c67} {} -->
			<!-- {OPEN:b615db6b-de39-48cd-8ad2-16477a1f3b32} {"sha1":"BB8340824B753B2C40A579D235EB0B611FF955BF"} -->
			<div class="ui-fluid">
                <span class="ui-float-label sticky">
                    <input required [(ngModel)]="searchDataProvider.Username" name="Username{{ searchDataProvider.ObjectID }}" fieldLabel="Username" type="text" pInputText class="ui-inputtext">
                    <label>Username</label>
                </span>
			</div>
			<!-- {CLOSE:b615db6b-de39-48cd-8ad2-16477a1f3b32} {"sha1":"BB8340824B753B2C40A579D235EB0B611FF955BF"} -->
			<!-- {CLOSE:98587c7c-2fa9-4af1-b1b7-66cdc4fc8c67} {} -->
		</div>
		<!-- {POSTCLOSE:98587c7c-2fa9-4af1-b1b7-66cdc4fc8c67} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
		<!-- {CLOSE:1656bc07-34fb-46a3-a4c5-07ad7d91a1a0} {} -->
	</div>
	<!-- {POSTCLOSE:1656bc07-34fb-46a3-a4c5-07ad7d91a1a0} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
	<!-- {PREOPEN:dde77b2e-5b2b-4635-a15e-9618c07475cd} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
	<div class="ui-g form-group">
		<!-- {OPEN:dde77b2e-5b2b-4635-a15e-9618c07475cd} {} -->
		<!-- {PREOPEN:41df3680-da17-42d0-bd39-ff9001725e5b} {"sha1":"2183A3A9ABCF2FD844436EF69F76172FF147BB12"} -->
		<div class="ui-g-12 ui-md-12">
			<!-- {OPEN:41df3680-da17-42d0-bd39-ff9001725e5b} {} -->
			<!-- {OPEN:379fee80-c8cf-44a5-9402-6a763d276d04} {"sha1":"95C8975FD4FE74BA13582427475A0C2CBB9DBB0B"} -->
			<div class="ui-fluid">
                <span class="ui-float-label sticky">
                    <input required [(ngModel)]="searchDataProvider.Password" name="Password{{ searchDataProvider.ObjectID }}" fieldLabel="Password" type="password" pInputText class="ui-inputtext">
                    <label>Password</label>
                </span>
			</div>
			<!-- {CLOSE:379fee80-c8cf-44a5-9402-6a763d276d04} {"sha1":"95C8975FD4FE74BA13582427475A0C2CBB9DBB0B"} -->
			<!-- {CLOSE:41df3680-da17-42d0-bd39-ff9001725e5b} {} -->
		</div>
		<!-- {POSTCLOSE:41df3680-da17-42d0-bd39-ff9001725e5b} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
		<!-- {CLOSE:dde77b2e-5b2b-4635-a15e-9618c07475cd} {} -->
	</div>
	<div class="ui-g form-group">
		<!-- {OPEN:dde77b2e-5b2b-4635-a15e-9618c07475cd} {} -->
		<!-- {PREOPEN:41df3680-da17-42d0-bd39-ff9001725e5b} {"sha1":"2183A3A9ABCF2FD844436EF69F76172FF147BB12"} -->
		<div class="ui-g-12 ui-md-12">
			<!-- {OPEN:41df3680-da17-42d0-bd39-ff9001725e5b} {} -->
			<!-- {OPEN:379fee80-c8cf-44a5-9402-6a763d276d04} {"sha1":"95C8975FD4FE74BA13582427475A0C2CBB9DBB0B"} -->
			<div class="ui-fluid">
                <span class="ui-float-label sticky">
                    <input required [(ngModel)]="searchDataProvider.ApiKey" name="ApiKey{{ searchDataProvider.ObjectID }}" fieldLabel="ApiKey" type="text" pInputText class="ui-inputtext">
                    <label>API Key</label>
                </span>
			</div>
			<!-- {CLOSE:379fee80-c8cf-44a5-9402-6a763d276d04} {"sha1":"95C8975FD4FE74BA13582427475A0C2CBB9DBB0B"} -->
			<!-- {CLOSE:41df3680-da17-42d0-bd39-ff9001725e5b} {} -->
		</div>
		<!-- {POSTCLOSE:41df3680-da17-42d0-bd39-ff9001725e5b} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
		<!-- {CLOSE:dde77b2e-5b2b-4635-a15e-9618c07475cd} {} -->
	</div>

</div>
	<!-- {POSTCLOSE:dde77b2e-5b2b-4635-a15e-9618c07475cd} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
	<!-- {PREOPEN:f1ed2736-a708-4081-977c-5176e43388fd} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
	<div class="ui-g form-group">
		<!-- {OPEN:f1ed2736-a708-4081-977c-5176e43388fd} {} -->
		<!-- {PREOPEN:ffb647b8-6751-4817-a0c6-3a51b3dc240b} {"sha1":"2183A3A9ABCF2FD844436EF69F76172FF147BB12"} -->
		<div class="ui-g-12 ui-md-12">
			<!-- {OPEN:ffb647b8-6751-4817-a0c6-3a51b3dc240b} {} -->
			<!-- {OPEN:f25d78aa-04ca-49aa-974c-de56cc33c852} {"sha1":"47D3862D6391644730712A04F7F6AA74E2447BCD"} -->
			<div class="ui-fluid">
				<button class="buttonStyle" pButton type="button" label="Add" (click)="onAddClick()"></button>
			</div>
			<!-- {CLOSE:f25d78aa-04ca-49aa-974c-de56cc33c852} {"sha1":"47D3862D6391644730712A04F7F6AA74E2447BCD"} -->
			<!-- {CLOSE:ffb647b8-6751-4817-a0c6-3a51b3dc240b} {} -->
		</div>
		<!-- {POSTCLOSE:ffb647b8-6751-4817-a0c6-3a51b3dc240b} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
		<!-- {CLOSE:f1ed2736-a708-4081-977c-5176e43388fd} {} -->
	</div>
	<!-- {POSTCLOSE:f1ed2736-a708-4081-977c-5176e43388fd} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
	<!-- {CLOSE:a2fd18b0-bd8f-49a6-928e-53312861c6d6} {} -->
	<p-progressSpinner *ngIf="showLoader"></p-progressSpinner>
	<!-- {CLOSE:a7d6a0e4-0826-470a-aa50-a718a36fafe9} {} -->
</form>
<!-- {POSTCLOSE:a7d6a0e4-0826-470a-aa50-a718a36fafe9} {"sha1":"3FD6E659562C1876D02FC8CF54746B1502442CD2"} -->
