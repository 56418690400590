import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { TranslateService } from './translate.service';

@Injectable()
export class StartUpService {

    constructor(
        private configService: ConfigService,
        private translateService: TranslateService
    ) {

    }

    start(): Promise<any> {
        return this.configService.load().then(  //First load configs and only after that call Translate as translate uses config.settings
            data => {
                return this.translateService.loadMessages();
            }
        )
    }
}