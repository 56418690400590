import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DefaultMessageSourceConfig, MessageSourceConfig } from './../models/message-source.model';
import { ApiService } from './api.service';
import { ConfigService } from './config.service';
import { UtilsService } from './utils.service';

@Injectable()
export class TranslateService {

    configs: MessageSourceConfig[] = [];

    constructor(
        private apiService: ApiService,
        private utilsService: UtilsService,
        private configService: ConfigService,
    ) {

    }

    loadMessages() {
        if (this.configService.settings.loadMessageSource) {
            let params: any = {
                environment: environment.envName
            }

            return this.apiService.post("svc/MessageSource", params)
                .toPromise()
                .then(
                    data => {
                        if (this.utilsService.isSuccessfulResponse(data)) {

                            for (let configData of data.results) {

                                var config: MessageSourceConfig = new MessageSourceConfig(this.utilsService);

                                Object.assign(config, configData);

                                this.configs.push(config);
                            }

                            for (var i = 0; i < this.configs.length - 1; i++) {
                                this.configs[i].NextMessageSourceConfig = this.configs[i + 1];
                            }
                            this.configs[this.configs.length - 1].NextMessageSourceConfig = new DefaultMessageSourceConfig(this.utilsService);
                        }
                        return this.configs;
                    },
                    err => {
                        console.error(err);
                    }
                );
        }
    }

    translateLabel(fieldName: string, subType: string, defaultValue: string, modes: string[]): string {
        if (this.configs && this.configs.length) {
            return this.configs[0].translateLabel(fieldName, subType, defaultValue, modes);
        }
        return defaultValue ? defaultValue : fieldName;
    }

    translateErrorMsg(fieldName: string, errorType: string, tokenReplacements?: any, modes?: string[]): string {
        if (this.configs && this.configs.length) {
            return this.configs[0].getFieldErrorMsgWithType(fieldName, errorType, tokenReplacements, modes);
        }
        return errorType;
    }
}