import { AddUserComponent } from './../add-user/add-user.component';
import { ActivatedRoute } from '@angular/router';
import { UtilsService } from './../../../oneit/services/utils.service';
import { AdminDashboardService } from './../admin-dashboard/admin-dashboard.service';
import { Subscription } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'cbos-list-org-users',
  templateUrl: './list-org-users.component.html',
  styleUrls: ['./list-org-users.component.scss']
})
export class ListOrgUsersComponent implements OnInit {
  subscriptions : Array<Subscription> = [];
  constructor( private dashboardService: AdminDashboardService,
    private utilsService: UtilsService,
    public activatedRoute : ActivatedRoute,
    private dialog: MatDialog,) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      if(params.id) {
        this.orgId = params.id;
        this.fetchOrgUsers();
      }
    });
    }

  @Input()
  orgId;

  users;
  displayedColumns: string[] = ['userId', 'name', 'email', 'roles'];

  fetchOrgUsers() {
    // this.showLoader = true;
    this.subscriptions.push(
        this.dashboardService.getUsersForOrg(this.orgId).subscribe(data => {
            // this.showLoader = false;
            this.users = data;
            console.log(this.users);
        }, error => {
            // this.showLoader = false;
            this.utilsService.handleError(error);
        })
    );
  }

  openAddUserPopup = () => {
    const dialogRef = this.dialog.open(AddUserComponent, {
      panelClass: 'app-full-dialog',
      width: '25%',
      // minHeight: 'calc(100vh - 90px)',
      height : 'auto',
    });
    dialogRef.componentInstance.orgId = this.orgId;
  }

}
