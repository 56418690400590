<div class="layout-wrapper" (click)="onWrapperClick()"
    [ngClass]="{'layout-wrapper-static': forceStaticLayout || layoutMode === 'static',
                'layout-wrapper-force-static': forceStaticLayout,
                'layout-wrapper-static-active': layoutMode === 'static' && isSidebarActive,
                'layout-wrapper-active': mobileMenuActive,
                'layout-menu-horizontal': layoutMode === 'horizontal',
                'layout-rtl': isRTL}">

    <app-header *ngIf="(showDefaultHeader || showSidebar) && isStatic && !isMobile()" [staticTopBar]="true" [customTopBarHeader]="customTopBarHeader" 
                [showDefaultHeader]="showDefaultHeader" [showSidebar]="showSidebar">
    </app-header>
    <app-sidebar [reset]="resetMenu" *ngIf="showSidebar && isStatic"></app-sidebar>

    <div class="layout-main" [ngClass]="{'no-sidebar' : (isStatic && !showSidebar), 
                                        'no-static-topbar' : (isStatic && !showDefaultHeader)}">
        <app-header *ngIf="showDefaultHeader && (isHorizontal || isMobile())" [showMenu]="showMenu"></app-header>
        <ng-template #customHeader></ng-template>
        <!-- <app-breadcrumb></app-breadcrumb> -->

        <div class="layout-content" 
            [ngClass]="{'showErrors': showAllErrorsCalled,
                        'defaultHeaderPadding': (showDefaultHeader && !customHeader), 
                        'customHeaderPadding': (customHeader && !showDefaultHeader),
                        'layout-no-top-menu': isStatic,
                        'defaultAndCustomHeaderPadding': (customHeader && showDefaultHeader)}">
            <div class="ui-g">
                <div [ngClass]="{'ui-g-12 nopad' : (errorMsgs && errorMsgs.length > 0)}">
                    <p-messages [(value)]="errorMsgs" *ngIf="!dialogOpen"></p-messages>
                    <p-toast key="default" [style]="{marginTop: '65px'}"></p-toast> <!-- 65px is based on topbar height (64px) -->
                </div>
            </div>
            <router-outlet (activate)="onRouteUpdate()"></router-outlet>
        </div>

        <app-feedback *ngIf="showFeedback"></app-feedback>
        <ng-template #customFooter></ng-template>
        <app-footer *ngIf="showDefaultFooter"></app-footer>
        <div class="layout-main-mask" *ngIf="mobileMenuActive"></div>
    </div>
</div>