import { Component, Input, Inject, forwardRef, TemplateRef } from '@angular/core';
import { CustomTieredMenuComponent } from '../custom-tiered-menu/custom-tiered-menu.component';
import { DomHandler } from 'primeng/dom';
import { CustomMenuItem } from '../custom-tiered-menu/custom-tiered-menu.model';

@Component({
    selector: 'custom-tieredMenuSub',
    templateUrl: './custom-tiered-menu-sub.component.html',
    styleUrls: ['./custom-tiered-menu-sub.component.scss'],
    providers: [DomHandler]
})
export class CustomTieredMenuSub {

    @Input() item: CustomMenuItem;

    @Input() root: boolean;

    @Input() autoZIndex: boolean = true;

    @Input() baseZIndex: number = 0;

    @Input() hideDelay: number = 250;

    @Input() customButtonRef: TemplateRef<any>;

    constructor(@Inject(forwardRef(() => CustomTieredMenuComponent)) public tieredMenu: CustomTieredMenuComponent, public domHandler: DomHandler) { }

    activeItem: HTMLLIElement;

    hideTimeout: any;

    onItemMouseEnter(event: Event, item: HTMLLIElement, menuitem: CustomMenuItem) {
        if (menuitem.disabled) {
            return;
        }

        if (this.hideTimeout) {
            clearTimeout(this.hideTimeout);
            this.hideTimeout = null;
        }

        this.activeItem = item;
        let nextElement: HTMLElement = <HTMLElement>item.children[0].nextElementSibling;
        if (nextElement) {
            let sublist: HTMLElement = <HTMLElement>nextElement.children[0];
            if (sublist) {
                if (this.autoZIndex) {
                    sublist.style.zIndex = String(this.baseZIndex + (++DomHandler.zindex));
                }
                sublist.style.zIndex = String(++DomHandler.zindex);

                sublist.style.top = '0px';
                sublist.style.left = DomHandler.getOuterWidth(item.children[0]) + 'px';
            }
        }
    }

    onItemMouseLeave(event: Event) {
        this.hideTimeout = setTimeout(() => {
            this.activeItem = null;
        }, this.hideDelay);
    }

    itemClick(event: Event, item: CustomMenuItem) {
        if (item.disabled) {
            event.preventDefault();
            return true;
        }

        if (!item.url) {
            event.preventDefault();
        }

        if (item.command) {
            item.command({
                originalEvent: event,
                item: item
            });
        }

        if (!item.items && this.tieredMenu.popup) {
            this.tieredMenu.hide();
        }
    }

    rightIconClick(event: Event, item: CustomMenuItem) {
        if (item.rightIconClick) {
            item.rightIconClick({
                originalEvent: event,
                item: item
            });
        }
    }

    listClick(event: Event) {
        this.activeItem = null;
    }
}

