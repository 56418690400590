/* {OPEN:IMPORT} {"sha1":"BAEC8F95BD3A2239F7F3F73DCC61F252E6E4B619"} */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SaveService } from './../../oneit/services/save.service';
import { SearchService } from './../../oneit/services/search.service';
import { UtilsService } from './../../oneit/services/utils.service';
/* {CLOSE:IMPORT} {"sha1":"BAEC8F95BD3A2239F7F3F73DCC61F252E6E4B619"} */
import { AppService } from '../../app.service';
/* {POSTCLOSE:IMPORT} {"sha1":"BAEC8F95BD3A2239F7F3F73DCC61F252E6E4B619"} */

@Injectable()
/* {PREOPEN:CLASS} {"sha1":"DEDA411781A9D27F794E9713335EEDCBA89E9F26"} */
export class ProjectService {
    /* {OPEN:CLASS} {} */

    constructor(
        /* {OPEN:constructor} {"sha1":"DCCC484B4DA23ED55D1380457121E9DAC4BB6B04"} */
        private searchService: SearchService,
        private saveService: SaveService,
        private utilsService: UtilsService,
        /* {CLOSE:constructor} {"sha1":"DCCC484B4DA23ED55D1380457121E9DAC4BB6B04"} */
        private appService: AppService
        /* {CLOSE:constructor} {"sha1":"DCCC484B4DA23ED55D1380457121E9DAC4BB6B04"} */
    ) {
    }

    /* {CLOSE:CLASS} {} */

    getProjectByID(id , module) {
        return this.appService.getModuleByID(`${this.appService.getSelectedDataProvider()}/${module}/${id}` , null);
    }

    markModuleAsFavorite(params , module , id) {
        return this.appService.markModuleAsFavorite(`${this.appService.getSelectedDataProvider()}/${module}/favourites/${id}` , params);
    }

    getProjectTabDetails(id , module , tab) {
        return this.appService.getModuleTabDetails(`${this.appService.getSelectedDataProvider()}/${module}/${id}/${tab}` , null)
    }
}
/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */
