export class SearchRefine {
    /* {OPEN:SearchRefine} {"sha1":"6376B33E5123BF09A271789A272103F957C8F38D"} */
    /* {CLOSE:SearchRefine} {"sha1":"6376B33E5123BF09A271789A272103F957C8F38D"} */
    ObjectID;
    project;
    company;
    StartDate;
    EndDate;
    TenderCloseDate;
    minValue;
    maxValue
    minUnits;
    maxUnits;
    minTotalFloorArea;
    maxTotalFloorArea;
    minGroundFloorArea;
    maxGroundFloorArea;
    minSiteArea;
    maxSiteArea;
    minStoreysAboveGround;
    maxStoreysAboveGround;
    minParkingSpaces;
    maxParkingSpaces;
    minStoreysBelowGround;
    maxStoreysBelowGround;
    UseCurrentLocation;
    SearchByCompanyOrProjectDistance;
    Distance;
    Company;
    Project;
    Includerefusedwithdrawnabandonedandonhold;
    /* {POSTCLOSE:SearchRefine} {"sha1":"6376B33E5123BF09A271789A272103F957C8F38D"} */
}

/* {OPEN:ADDIONAL_MODELS} {"sha1":"ADC83B19E793491B1C6EA0FD8B46CD9F32E592FC"} */
/* {CLOSE:ADDIONAL_MODELS} {"sha1":"ADC83B19E793491B1C6EA0FD8B46CD9F32E592FC"} */
export class QueryParams {
    project_stages;
    project_parking;
    project_floor_area;
    project_value;
    project_units;
    project_storeys_above;
    project_storeys_below;
    project_site_area;
    project_text;
    project_plan_area;
    company_text;
    project_start;
    project_end;
    project_tender_close_display;
    project_latitude;
    project_longitude;
    project_distance;
    company_latitude;
    company_longitude;
    company_distance;
    project_geocode;
    company_geocode
}
/* {POSTCLOSE:ADDIONAL_MODELS} {"sha1":"ADC83B19E793491B1C6EA0FD8B46CD9F32E592FC"} */
