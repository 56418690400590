import { UtilsService } from './../../../oneit/services/utils.service';
import { Subscription } from 'rxjs';
import { FusionUser } from './user.model';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators, ValidatorFn } from '@angular/forms';
import { AdminDashboardService } from '../admin-dashboard/admin-dashboard.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  form: FormGroup;
  user: FusionUser;
  subscriptions : Array<Subscription> = [];

  @Input()
  orgId: string;

  constructor(private dashboardService: AdminDashboardService,
    private utilsService: UtilsService,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
   this.clearUser();
   this.form = this.formBuilder.group({
    email: [null, [Validators.required, Validators.email]],
    password: [null, Validators.required],
    firstName: [null, Validators.required],
    lastName: [null, Validators.required]
  });
  }

  clearUser = () => {
    this.user = new FusionUser();
    this.user.organization.id = this.orgId;
  }

  saveUser = () => {
    // console.log(this.user);
    this.subscriptions.push(
      this.dashboardService.addUserToOrg(this.user).subscribe(() => {
          this.utilsService.handleSuccessMessage('User created successfully');
      }, error => {
          this.utilsService.handleError(error);
      })
  );
  }

};
