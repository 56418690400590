<p-messages [(value)]="errorMsgs" *ngIf="errorMsgs && errorMsgs.length > 0 && !isAddCRMDialogOpen"></p-messages>
<form #form="ngForm" [class.pointer-events-none]="this.showLoader">
	<div class="ui-g form-group">
		<div class="ui-g-12 ui-md-8" *ngIf="canSetDefaultCRM">
			<label>Please select the CRM</label><br/>
			<span class="ui-float-label sticky" style="margin-top: 16px;">
				<p-selectButton optionDisabled="disabled"  [(ngModel)]="selectedCRM" name="CRM" fieldLabel="CRM" required  [options]="CRM_Options"></p-selectButton>
			</span>
		</div>
		<div class="ui-g-12 ui-md-4">
			<button class="buttonStyle" pButton type="button" label="Add" icon="ui-icon-add" (click)="onAddClick()"></button>
		</div>
		<div class="ui-g-12 ui-md-12" *ngIf="canSetDefaultCRM">
			<oneit-checkbox name="default" [label]="'Make Default CRM'" [(ngModel)]="makeDefaultCRM" binary="true"></oneit-checkbox>
		</div>
	</div>
	<div class="ui-g form-group" *ngIf="CRM_Options && CRM_Options?.length > 0">
		<div>
			<button class="buttonStyle" pButton type="button" label="Next" [style.width]="'250px'" (click)="onNextClick()"></button>
		</div>
	</div>
	<p-progressSpinner *ngIf="showLoader"></p-progressSpinner>
</form>
