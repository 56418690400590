<div class="ui-g form-group" (keyup) = "handleKeyUp($event)">
    <!-- {OPEN:eb5999d5-f74c-44b8-9796-979695f1397e} {} -->
    <!-- {PREOPEN:51d65416-be6e-4d3f-8c6e-ebf372b5af04} {"sha1":"D7D7C86781ACFD0FC50A8E98DC55A989939B33B1"} -->
    <div class="ui-g-12 ui-md-8">
        <!-- {OPEN:51d65416-be6e-4d3f-8c6e-ebf372b5af04} {} -->
        <!-- {OPEN:535af8d5-4179-4e26-9499-b851bff081a5} {} -->
        <!-- {PREOPEN:5e432841-99a4-4085-8fca-a9991ceb3515} {"sha1":"8E47AF93A0EDFE97B55BAA3CF5DF54E9C0946D9E"} -->
        <div class="ui-g form-group">
            <!-- {OPEN:5e432841-99a4-4085-8fca-a9991ceb3515} {} -->
            <!-- {PREOPEN:bbe1718e-57b1-48f5-bc76-323aca75379d} {"sha1":"78E0CE1766DCF3655F1631C109A5444307DCF9C9"} -->
            <div class="ui-g-12 ui-md-4">
                <!-- {OPEN:bbe1718e-57b1-48f5-bc76-323aca75379d} {} -->
                <!-- {OPEN:2436b78d-3b39-45a9-a217-2d7d1a6dc61c} {"sha1":"30225CC3B66E5775218D0E87AA713B607F31C12B"} -->
                <span class="ui-float-label sticky">
                    <p-dropdown [(ngModel)]="searcher.SearchProjectCompany" name="SearchProjectCompany{{ searcher.ObjectID }}" fieldLabel="Search Project/Company" optionLabel="Description" (onChange)="onSearchProjectCompanyChange()" [options]="searchProjectCompanyOptions" appendTo="body"></p-dropdown>
                    <label>Search Project/Company</label>
                </span>
                <!-- {CLOSE:2436b78d-3b39-45a9-a217-2d7d1a6dc61c} {"sha1":"30225CC3B66E5775218D0E87AA713B607F31C12B"} -->
                <!-- {CLOSE:bbe1718e-57b1-48f5-bc76-323aca75379d} {} -->
            </div>
            <!-- {POSTCLOSE:bbe1718e-57b1-48f5-bc76-323aca75379d} {"sha1":"079B33F711D32A1BDB5DD1A942BDD82885981728"} -->
            <!-- {PREOPEN:39795619-9f4b-4df4-8c63-6a7fcfc318bc} {"sha1":"52B91E5F13ADB7491AD65DA81601CA6F9CD8C844"} -->
            <div class="ui-g-12 ui-md-7">
                <!-- {OPEN:39795619-9f4b-4df4-8c63-6a7fcfc318bc} {} -->
                <!-- {OPEN:5fdeb733-c8cd-43e7-85d6-a0a4031b902a} {"sha1":"2A4646B2F1521D5A06708830AFC9EE692545B330"} -->
				<span class="ui-float-label sticky">
					<div class="search-module">
						<div class="chips">
							<p-chips [class.setHeight]="!showMore" (onRemove)="onRemoveFilterParam($event)" class="search-project" [(ngModel)]="searcher.Search" name="Search{{ searcher.ObjectID }}">
								<ng-template let-item pTemplate="item" let-index="index">
									<div class="chips-item">
										{{item}}
									</div>
								</ng-template>
							</p-chips>
						</div>
						<div class="search-icon">
							<i class="pi pi-search cursor-pointer" (click)="showMore = !showMore"></i>
						</div>
					</div>
					<label>Search</label>
                    </span>
                <!-- {CLOSE:5fdeb733-c8cd-43e7-85d6-a0a4031b902a} {"sha1":"2A4646B2F1521D5A06708830AFC9EE692545B330"} -->
                <!-- {CLOSE:39795619-9f4b-4df4-8c63-6a7fcfc318bc} {} -->
            </div>
            <!-- {POSTCLOSE:39795619-9f4b-4df4-8c63-6a7fcfc318bc} {"sha1":"079B33F711D32A1BDB5DD1A942BDD82885981728"} -->
            <!-- {PREOPEN:ea5462a5-f4d3-45d4-b5f5-7a1a7fa3a83a} {"sha1":"3D1030834F97F79122E75D1A135E977C94D4CC17"} -->
            <div class="ui-g-12 ui-md-1">
                <!-- {OPEN:ea5462a5-f4d3-45d4-b5f5-7a1a7fa3a83a} {} -->
                <!-- {OPEN:4db410a9-23aa-4259-93b3-eacc95faea0a} {"sha1":"503B4BA1CBDFBBFD8074B3145EC76179880A04A3"} -->
                <div class="ui-fluid">
                    <button pButton type="button" icon="ui-icon-clear" class="clear-button" (click)="onClearClick()" [class]='["btn-tertiary"]'></button>
                </div>
                <!-- {CLOSE:4db410a9-23aa-4259-93b3-eacc95faea0a} {"sha1":"503B4BA1CBDFBBFD8074B3145EC76179880A04A3"} -->
                <!-- {CLOSE:ea5462a5-f4d3-45d4-b5f5-7a1a7fa3a83a} {} -->
            </div>
            <!-- {POSTCLOSE:ea5462a5-f4d3-45d4-b5f5-7a1a7fa3a83a} {"sha1":"079B33F711D32A1BDB5DD1A942BDD82885981728"} -->
            <!-- {CLOSE:5e432841-99a4-4085-8fca-a9991ceb3515} {} -->
        </div>
        <!-- {POSTCLOSE:5e432841-99a4-4085-8fca-a9991ceb3515} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
        <!-- {CLOSE:535af8d5-4179-4e26-9499-b851bff081a5} {} -->
        <!-- {CLOSE:51d65416-be6e-4d3f-8c6e-ebf372b5af04} {} -->
    </div>
    <!-- {POSTCLOSE:51d65416-be6e-4d3f-8c6e-ebf372b5af04} {"sha1":"C4A15ED98D4B138EEB595266FB1CF4C91CF45CA5"} -->
    <!-- {PREOPEN:4e6fccd7-3f63-4534-8fbb-3fdee5c7023d} {"sha1":"3667EDCEE4A1EEFBEB428C1F8C7DD175186DC0E7"} -->
    <div class="ui-g-12 ui-md-2">
        <!-- {OPEN:4e6fccd7-3f63-4534-8fbb-3fdee5c7023d} {} -->
        <!-- {OPEN:f380b82f-d644-4a5d-b6e4-5145f5feaaeb} {"sha1":"2B536CA5443D2C7CDE4714BCECCFC571994D8F40"} -->
        <div class="ui-fluid">
            <button pButton type="button" class="btn-secondary"  [label]="totalFilters ? 'Filters(' + totalFilters + ')': 'Filters'" icon="ui-icon-filter" (click)="onFiltersClick()"></button>
        </div>
        <!-- {CLOSE:f380b82f-d644-4a5d-b6e4-5145f5feaaeb} {"sha1":"2B536CA5443D2C7CDE4714BCECCFC571994D8F40"} -->
        <!-- {CLOSE:4e6fccd7-3f63-4534-8fbb-3fdee5c7023d} {} -->
    </div>
    <!-- {POSTCLOSE:4e6fccd7-3f63-4534-8fbb-3fdee5c7023d} {"sha1":"C4A15ED98D4B138EEB595266FB1CF4C91CF45CA5"} -->
    <!-- {PREOPEN:94ed7899-9426-4e39-9bda-063158b3824c} {"sha1":"3667EDCEE4A1EEFBEB428C1F8C7DD175186DC0E7"} -->
    <div class="ui-g-12 ui-md-2">
        <!-- {OPEN:94ed7899-9426-4e39-9bda-063158b3824c} {} -->
        <!-- {OPEN:b3ab669b-7e9a-4119-8330-cb878fdc4f74} {"sha1":"E0A878520E3B2F3AE30259A7F6E31D04747215BA"} -->
        <div class="ui-fluid">
            <button pButton type="button" label="Search" icon="ui-icon-search" (click)="onSearchClick(true)"></button>
        </div>
        <!-- {CLOSE:b3ab669b-7e9a-4119-8330-cb878fdc4f74} {"sha1":"E0A878520E3B2F3AE30259A7F6E31D04747215BA"} -->
        <!-- {CLOSE:94ed7899-9426-4e39-9bda-063158b3824c} {} -->
    </div>
    <!-- {POSTCLOSE:94ed7899-9426-4e39-9bda-063158b3824c} {"sha1":"C4A15ED98D4B138EEB595266FB1CF4C91CF45CA5"} -->
    <!-- {CLOSE:eb5999d5-f74c-44b8-9796-979695f1397e} {} -->
</div>
