/* {OPEN:IMPORT} {"sha1":"24FA1027E2E7D19479A2936651E87138AD34B778"} */
import { Component, DoCheck, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import { Message, MenuItem, ConfirmationService } from 'primeng/api';
import { AppService } from './../../app.service';
import { OptionsObservable, OptionsWrapper } from './../../oneit/models/options.model';
import { UserService } from './../../oneit/services/user.service';
import { UtilsService } from './../../oneit/services/utils.service';
import { SearchService } from './../../oneit/services/search.service';
import { TableViewService } from './table-view.service';
import { SearchTableView } from './table-view.model';
/* {CLOSE:IMPORT} {"sha1":"24FA1027E2E7D19479A2936651E87138AD34B778"} */

@Component({
    selector: 'app-base-table-view',
    template: ''
})
/* {PREOPEN:CLASS} {"sha1":"BC802B3918C70480FC83B397F04B98D2FBC763FE"} */
export class BaseTableViewComponent implements DoCheck, OnInit, OnDestroy {
    /* {OPEN:CLASS} {} */
    
    /* {OPEN:COMMON_VARS} {"sha1":"672A211DEEA9A3559D121CBDC5978BBA47BD6B7D"} */
    @Input() parent : any;
    pageTitle : string;
    /* {CLOSE:COMMON_VARS} {"sha1":"672A211DEEA9A3559D121CBDC5978BBA47BD6B7D"} */

    /* {OPEN:VARS} {"sha1":"E8B4D2EF10ED31E7AA781027E2197C6AF3F0F6C7"} */
    searchTableView : SearchTableView = new SearchTableView();
    activeTabIndex : number = 0;
    searchProjectCompanyOptions : any[] = [];
    isSearchProjectCompanyVolatile : boolean = false;
    searchOptions : any[] = [];
    exportBtnItems : MenuItem[] = [];
    columnsBtnItems : MenuItem[] = [];
    barbours : any[] = [];
    barboursTableSelectionMode : string;
    barboursTableCols : any[] = [];
    /* {CLOSE:VARS} {"sha1":"E8B4D2EF10ED31E7AA781027E2197C6AF3F0F6C7"} */
    
    showResults : boolean = false;
    showLoader : boolean = false;
    subscriptions : Array<Subscription> = [];

    constructor(
        /* {OPEN:constructor} {"sha1":"C1B95BFB5A58AAAD9B003689B5F7BCE79B9B7D0F"} */
        public router : Router,
        public userService : UserService,
        public utilsService : UtilsService,
        public searchService : SearchService,
        public appService : AppService,
        public confirmationService: ConfirmationService,
        public tableViewService : TableViewService,
        /* {CLOSE:constructor} {"sha1":"C1B95BFB5A58AAAD9B003689B5F7BCE79B9B7D0F"} */
    ){
        
    }

    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"21DA96047931B77EBF6A949BEE6ABF486F8599A0"} */
        this.exportBtnItems = this.getExportBtnItems();
        this.columnsBtnItems = this.getColumnsBtnItems();
        this.resetInitVariables();
        this.calculateEverything();
        this.executeScreenSpecificInitCode();
        /* {CLOSE:ngOnInit} {"sha1":"21DA96047931B77EBF6A949BEE6ABF486F8599A0"} */
    }

    /* {PREOPEN:executeScreenSpecificInitCode} {"sha1":"DDD081544316B33777AB7B7FFD796FB9E663E681"} */
    executeScreenSpecificInitCode() {
        /* {OPEN:executeScreenSpecificInitCode} {"sha1":"54DB41AD32937D86C7318EB4BE666A1AD7B6B618"} */
        if(!this.parent) {
        }
        /* {CLOSE:executeScreenSpecificInitCode} {"sha1":"54DB41AD32937D86C7318EB4BE666A1AD7B6B618"} */
    }
    /* {POSTCLOSE:executeScreenSpecificInitCode} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onTabChange} {"sha1":"977F513A655099DE2AAC204D8ACAFFE93F4915F0"} */
    onTabChange(event, searchTableView) : void {
        /* {OPEN:onTabChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:onTabChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:onTabChange} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getSearchProjectCompanyOptions} {"sha1":"123C2A0E687F716342CDEB65E4A270BA933884C0"} */
    getSearchProjectCompanyOptions(): OptionsWrapper {
        /* {OPEN:getSearchProjectCompanyOptions} {"sha1":"FE042567447A6636189C18A1F39914BE4E4B3408"} */
        return new OptionsObservable(false, this.appService.getProjectsOrCompaniesObservable());
        /* {CLOSE:getSearchProjectCompanyOptions} {"sha1":"FE042567447A6636189C18A1F39914BE4E4B3408"} */
    }
    /* {POSTCLOSE:getSearchProjectCompanyOptions} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:resetSearchProjectCompanyOptions} {"sha1":"6603B0A99D31AF5E29FD33F31E28081AABF882E9"} */
    resetSearchProjectCompanyOptions() {
        /* {OPEN:resetSearchProjectCompanyOptions} {"sha1":"8C3FA325BC5BA7A62DD72ECE2AC7DB66149C6A51"} */
        if(this.parent) {
            this.parent.resetSearchProjectCompanyOptions();
        } else {
            let wrapper : OptionsWrapper = this.getSearchProjectCompanyOptions();

            if (wrapper) {
                this.isSearchProjectCompanyVolatile = wrapper.isVolatile;

                this.subscriptions.push(wrapper.getObservable()
                    .subscribe (
                        data => {
                            this.searchProjectCompanyOptions = data;
                        },
                        error => {
                            this.utilsService.handleError(error);
                        }
                    )
                );
            }
        }
        /* {CLOSE:resetSearchProjectCompanyOptions} {"sha1":"8C3FA325BC5BA7A62DD72ECE2AC7DB66149C6A51"} */
    }
    /* {POSTCLOSE:resetSearchProjectCompanyOptions} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onSearchProjectCompanyChange} {"sha1":"B80B4B4EF267FE4430A1FF46E8A610BF1F6180A8"} */
    onSearchProjectCompanyChange(event, searchTableView) {
        /* {OPEN:onSearchProjectCompanyChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:onSearchProjectCompanyChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:onSearchProjectCompanyChange} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:autocompleteSearchOptions} {"sha1":"405BC072B66708D0EA038C03469379B28311BE65"} */
    autocompleteSearchOptions(event, searchTableView) : void {
        /* {OPEN:autocompleteSearchOptions} {"sha1":"84AAB9DA39F47E0898EDB12330F83F9989D656A6"} */
        this.subscriptions.push(this.getAutocompleteSearchOptionsObservable(event, searchTableView)
            .subscribe (
                data => {
                    this.searchOptions = data;
                },
                error => {
                    this.utilsService.handleError(error);
                }
            )
        );
        /* {CLOSE:autocompleteSearchOptions} {"sha1":"84AAB9DA39F47E0898EDB12330F83F9989D656A6"} */
    }
    /* {POSTCLOSE:autocompleteSearchOptions} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onSearchSelect} {"sha1":"0B7044D060F77045502EED77089A8A1D8E4D190E"} */
    onSearchSelect(event, searchTableView) {
        /* {OPEN:onSearchSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:onSearchSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:onSearchSelect} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onSearchClear} {"sha1":"3DB0BBE394AEB9AAC5B8ECC3AE960376F803BF2C"} */
    onSearchClear(event, searchTableView) : void {
        /* {OPEN:onSearchClear} {"sha1":"4A5C772BA7FFEF67B535D8B32E5C8666BC3246DD"} */
        searchTableView.Search = null;
        /* {CLOSE:onSearchClear} {"sha1":"4A5C772BA7FFEF67B535D8B32E5C8666BC3246DD"} */
    }
    /* {POSTCLOSE:onSearchClear} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getAutocompleteSearchOptionsObservable} {"sha1":"9B1183B3A69B1AD50C55CCCAE5F1B599B7736772"} */
    getAutocompleteSearchOptionsObservable(event, searchTableView) : Observable<any> {
        /* {OPEN:getAutocompleteSearchOptionsObservable} {"sha1":"DFD311384A9998E4976E903F9D97FEA103754E49"} */
        if(this.parent) {
            return this.parent.getAutocompleteSearchOptionsObservable(event, searchTableView);
        }
        throw new Error('Not Implemented');
        /* {CLOSE:getAutocompleteSearchOptionsObservable} {"sha1":"DFD311384A9998E4976E903F9D97FEA103754E49"} */
    }
    /* {POSTCLOSE:getAutocompleteSearchOptionsObservable} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onClearClick} {"sha1":"B56D49AD4A577617D8044BE5445D7C2EA05E91CF"} */
    onClearClick(event, searchTableView) {
        /* {OPEN:onClearClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onClearClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onClearClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onFiltersClick} {"sha1":"C585F2A43F2BE2210B46D0F4128777A21B57E556"} */
    onFiltersClick(event, searchTableView) {
        /* {OPEN:onFiltersClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onFiltersClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onFiltersClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onSearchClick} {"sha1":"D1CCB876E43A1DF955EDB7D00EFC167CBEA8A7B7"} */
    onSearchClick(event, searchTableView) {
        /* {OPEN:onSearchClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onSearchClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onSearchClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onMapViewClick} {"sha1":"1010A2DB0683C38F191E0F337D4C307C3435ABC3"} */
    onMapViewClick(event, searchTableView) {
        /* {OPEN:onMapViewClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onMapViewClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onMapViewClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onSaveSearchClick} {"sha1":"9A2D4802A3B486BF2B96F38201825CD1F89830E6"} */
    onSaveSearchClick(event, searchTableView) {
        /* {OPEN:onSaveSearchClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onSaveSearchClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onSaveSearchClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onCreateDealsClick} {"sha1":"DF5332F42F9B1DE43700213F52FDB1446E39431E"} */
    onCreateDealsClick(event, searchTableView) {
        /* {OPEN:onCreateDealsClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onCreateDealsClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onCreateDealsClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getExportBtnItems} {"sha1":"CDDA828AF23B5E83700DEBC4BA0432264E17F679"} */
    getExportBtnItems() : MenuItem[] {
        /* {OPEN:getExportBtnItems} {"sha1":"F76A4CC36D3F8966AC8C88F82562224CD5D630DD"} */
        return [
            {
                label: 'Excel',
                command: () => {
                    this.onExcelClick();
                }
            },
            {
                label: 'CSV',
                command: () => {
                    this.onCsvClick();
                }
            }
        ];
        /* {CLOSE:getExportBtnItems} {"sha1":"F76A4CC36D3F8966AC8C88F82562224CD5D630DD"} */
    }
    /* {POSTCLOSE:getExportBtnItems} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onExportClick} {"sha1":"36650FC2AC540807AE118544D8F918B647D902B3"} */
    onExcelClick() {
        /* {OPEN:onExportClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onExportClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onExportClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onExportClick} {"sha1":"3D930B79B48EBF1300D3E4FEC75DB90D0775EFAC"} */
    onCsvClick() {
        /* {OPEN:onExportClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onExportClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onExportClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getColumnsBtnItems} {"sha1":"C869B5D68F65056A0A574F6E2E326BAB9A1CE82C"} */
    getColumnsBtnItems() : MenuItem[] {
        /* {OPEN:getColumnsBtnItems} {"sha1":"156FB76A4D7DFD4789C3CA45A35D949C568B0277"} */
        return [
            {
                label: 'ID',
                command: () => {
                    this.onIdClick();
                }
            },
            {
                label: 'Title',
                command: () => {
                    this.onTitleClick();
                }
            },
            {
                label: 'Town',
                command: () => {
                    this.onTownClick();
                }
            },
            {
                label: 'Country',
                command: () => {
                    this.onCountryClick();
                }
            },
            {
                label: 'Planning Stage',
                command: () => {
                    this.onPlanningStageClick();
                }
            },
            {
                label: 'Contract Stage',
                command: () => {
                    this.onContractStageClick();
                }
            },
            {
                label: 'Value',
                command: () => {
                    this.onValueClick();
                }
            },
            {
                label: 'Sector',
                command: () => {
                    this.onSectorClick();
                }
            },
            {
                label: 'Category',
                command: () => {
                    this.onCategoryClick();
                }
            },
            {
                label: 'Units',
                command: () => {
                    this.onUnitsClick();
                }
            },
            {
                label: 'Start Date',
                command: () => {
                    this.onStartDateClick();
                }
            },
            {
                label: 'Converted',
                command: () => {
                    this.onConvertedClick();
                }
            }
        ];
        /* {CLOSE:getColumnsBtnItems} {"sha1":"156FB76A4D7DFD4789C3CA45A35D949C568B0277"} */
    }
    /* {POSTCLOSE:getColumnsBtnItems} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onColumnsClick} {"sha1":"4757735E0945E7CB7E04555388318EBC7E0C6853"} */
    onColumnsClick() {
        /* {OPEN:onColumnsClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onColumnsClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onColumnsClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onColumnsClick} {"sha1":"248BE0F1F59C42F3BE5CBB85D2374B97DF230B78"} */
    onIdClick() {
        /* {OPEN:onColumnsClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onColumnsClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onColumnsClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onColumnsClick} {"sha1":"1FC19DA29C71A009A4CFF468A099424258F1F7DE"} */
    onTitleClick() {
        /* {OPEN:onColumnsClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onColumnsClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onColumnsClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onColumnsClick} {"sha1":"6F5CAD5A56843D83EE82A11888A72E322579D922"} */
    onTownClick() {
        /* {OPEN:onColumnsClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onColumnsClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onColumnsClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onColumnsClick} {"sha1":"DD5E5DFDC2E1418251C62ACF36C2E9459B67B3EA"} */
    onCountryClick() {
        /* {OPEN:onColumnsClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onColumnsClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onColumnsClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onColumnsClick} {"sha1":"FC91A53DF45B29A8F1E8160FD52CE7D8DF2C81BD"} */
    onPlanningStageClick() {
        /* {OPEN:onColumnsClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onColumnsClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onColumnsClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onColumnsClick} {"sha1":"58601BE3810F97C912279D160080F606A2A1D1ED"} */
    onContractStageClick() {
        /* {OPEN:onColumnsClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onColumnsClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onColumnsClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onColumnsClick} {"sha1":"F2B17CC93AAA4599F064204C0A2DC2B7D1438CCC"} */
    onValueClick() {
        /* {OPEN:onColumnsClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onColumnsClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onColumnsClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onColumnsClick} {"sha1":"BAD1327C96BDE3D8F1C44714204681E853C9BF68"} */
    onSectorClick() {
        /* {OPEN:onColumnsClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onColumnsClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onColumnsClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onColumnsClick} {"sha1":"5B59FEFDF3D0801A044AB61194EF110EB0F38D38"} */
    onCategoryClick() {
        /* {OPEN:onColumnsClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onColumnsClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onColumnsClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onColumnsClick} {"sha1":"38D1ED3264A6567743A05D90C4FA176B1F27F52D"} */
    onUnitsClick() {
        /* {OPEN:onColumnsClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onColumnsClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onColumnsClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onColumnsClick} {"sha1":"2CA1B873D315E43D7670036C64A63AF94DE0047C"} */
    onStartDateClick() {
        /* {OPEN:onColumnsClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onColumnsClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onColumnsClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onColumnsClick} {"sha1":"F202A3BD57E8BB83ECD4FAFA12024279652C1423"} */
    onConvertedClick() {
        /* {OPEN:onColumnsClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onColumnsClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onColumnsClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getBarboursTableCols} {"sha1":"6B54EC19C6B56C02D9722FEE968F985E19BBE96D"} */
    getBarboursTableCols() : any[] {
        /* {OPEN:getBarboursTableCols} {"sha1":"B77324C4B9781D20290DD7DF01F9D32B2A82781A"} */
        return [
            { field: 'X', header: '["X"]', width: '10px', noSort: true, noFilter: true },
            { field: '.3MSocialHousingDevelopment', header: 'Project Title', width: '24px', noSort: false, noFilter: false },
            { field: 'Ballymena', header: 'Town', width: '10px', noSort: false, noFilter: false },
            { field: 'Co.Antrim', header: 'County', width: '10px', noSort: false, noFilter: false },
            { field: 'DetailApproval', header: 'Planning Stage', width: '11px', noSort: false, noFilter: false },
            { field: 'Contract', header: 'Contract Stage', width: '10px', noSort: false, noFilter: false },
            { field: 'Dummy', header: 'Value', width: '10px', noSort: false, noFilter: false },
            { field: 'Residential', header: 'Sector', width: '10px', noSort: false, noFilter: false },
            { field: 'SingleHouse', header: 'Category', width: '18px', noSort: false, noFilter: false },
            { field: 'Dummy', header: 'Units', width: '6px', noSort: false, noFilter: false },
            { field: 'Dummy', header: 'Start Date', width: '10px', noSort: false, noFilter: false }
        ];
        /* {CLOSE:getBarboursTableCols} {"sha1":"B77324C4B9781D20290DD7DF01F9D32B2A82781A"} */
    }
    /* {POSTCLOSE:getBarboursTableCols} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getBarbours} {"sha1":"DF24A3944549F1D2C8A246CC0AC8203EB4187E28"} */
    getBarbours(saveSearch: boolean) {
        /* {OPEN:getBarbours} {"sha1":"A510053715C0F4D83F031928785D998CA1D64F57"} */
        this.showResults = true;
        this.showLoader = true;
        let assocs = [];
        
        this.subscriptions.push(this.getBarboursObservable(saveSearch)
            .subscribe(
                data => {
                    this.barbours = data;
                    this.postBarboursTableChange();
                    this.showLoader = false;
                },
                error => {
                    this.showLoader = false;
                    this.utilsService.handleError(error);
                }
            )
        );
        /* {CLOSE:getBarbours} {"sha1":"A510053715C0F4D83F031928785D998CA1D64F57"} */
    }
    /* {POSTCLOSE:getBarbours} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:postBarboursTableChange} {"sha1":"E014336301693769A9265F8666E360D0CCAA7B1C"} */
    postBarboursTableChange() {
        /* {OPEN:postBarboursTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:postBarboursTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:postBarboursTableChange} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getBarboursObservable} {"sha1":"5B41B15DE51D8B4C9CB7B7DA57ECD680EA448F3F"} */
    getBarboursObservable(saveSearch): Observable<any> {
        /* {OPEN:getBarboursObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
        return of({});
        /* {CLOSE:getBarboursObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
    }
    /* {POSTCLOSE:getBarboursObservable} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onBarboursTableRowSelect} {"sha1":"B2AC24E6632BAF1FB7AE808249FF4494B90A2C4E"} */
    onBarboursTableRowSelect(data) {
        /* {OPEN:onBarboursTableRowSelect} {"sha1":"76FFDDBB12D60FFC8CC2440FDE7CBF1C3ED13329"} */
        this.router.navigate(['/project']);
        /* {CLOSE:onBarboursTableRowSelect} {"sha1":"76FFDDBB12D60FFC8CC2440FDE7CBF1C3ED13329"} */
    }
    /* {POSTCLOSE:onBarboursTableRowSelect} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getBarboursTableSelectionMode} {"sha1":"E92127CF2EDB7672B4F8F11F6ABBFA30F54C494B"} */
    getBarboursTableSelectionMode() : string {
        /* {OPEN:getBarboursTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
        return null;
        /* {CLOSE:getBarboursTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
    }
    /* {POSTCLOSE:getBarboursTableSelectionMode} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRouterLinkForBarboursTable3MSocialHousingDevelopment} {"sha1":"16B7978BC1CF014747D6905C6F0327C8702839F2"} */
    getRouterLinkForBarboursTable3MSocialHousingDevelopment(rowData) {
        /* {OPEN:getRouterLinkForBarboursTable3MSocialHousingDevelopment} {"sha1":"59FA4B2AD9C5B4E0012DA0C1CC3DA22AE8ED0C36"} */
        return ['/project'];
        /* {CLOSE:getRouterLinkForBarboursTable3MSocialHousingDevelopment} {"sha1":"59FA4B2AD9C5B4E0012DA0C1CC3DA22AE8ED0C36"} */
    }
    /* {POSTCLOSE:getRouterLinkForBarboursTable3MSocialHousingDevelopment} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:resetInitVariables} {"sha1":"846C0DF64879342578D595FE17BC00B96F0BAED6"} */
    resetInitVariables() {
        /* {OPEN:resetInitVariables} {} */
        /* {OPEN:resetInitVariables_resetSearchProjectCompanyOptions} {"sha1":"7C64B817F0B11B594A93F6490B3DBCA02DD36E16"} */
        try {
            this.resetSearchProjectCompanyOptions();
        }
        catch (error) {
            console.error('Error occured when executing resetSearchProjectCompanyOptions', error);
        }
        /* {CLOSE:resetInitVariables_resetSearchProjectCompanyOptions} {"sha1":"7C64B817F0B11B594A93F6490B3DBCA02DD36E16"} */
        /* {OPEN:resetInitVariables_barboursTableCols} {"sha1":"666D61158D821DC92AEF81DF7488C631AFD1BC48"} */
        try {
            this.barboursTableCols = this.getBarboursTableCols();
        }
        catch (error) {
            console.error('Error occured when setting barboursTableCols', error);
        }
        /* {CLOSE:resetInitVariables_barboursTableCols} {"sha1":"666D61158D821DC92AEF81DF7488C631AFD1BC48"} */
        /* {OPEN:resetInitVariables_barboursTableSelectionMode} {"sha1":"4FC78987704A3A132BAEAA4CDDA6D565F510299E"} */
        try {
            this.barboursTableSelectionMode = this.getBarboursTableSelectionMode();
        }
        catch (error) {
            console.error('Error occured when setting barboursTableSelectionMode', error);
        }
        /* {CLOSE:resetInitVariables_barboursTableSelectionMode} {"sha1":"4FC78987704A3A132BAEAA4CDDA6D565F510299E"} */
        /* {CLOSE:resetInitVariables} {} */
    }
    /* {POSTCLOSE:resetInitVariables} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getPageTitle} {"sha1":"174A3D30FFA103DD7BE180EE89CC0CF5EB6D11B8"} */
    getPageTitle() {
        /* {OPEN:getPageTitle} {"sha1":"DA6728ADE70B4AEF985809F692F64DE046A25F58"} */
        return 'Table View';
        /* {CLOSE:getPageTitle} {"sha1":"DA6728ADE70B4AEF985809F692F64DE046A25F58"} */
    }
    /* {POSTCLOSE:getPageTitle} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:calculateEverything} {"sha1":"CE17BF2375171825A37C544A1DCBF3A565DD5181"} */
    calculateEverything() {
        /* {OPEN:calculateEverything} {} */
        /* {OPEN:calculateEverything_pageTitle} {"sha1":"B3CA5EAE3ED54D1F0D95EA2CD05351A4A6D7480F"} */
        try {
            this.pageTitle = this.getPageTitle();
        }
        catch (error) {
            console.error('Error occured when setting pageTitle');
        }
        /* {CLOSE:calculateEverything_pageTitle} {"sha1":"B3CA5EAE3ED54D1F0D95EA2CD05351A4A6D7480F"} */
        /* {OPEN:calculateEverything_isSearchProjectCompanyVolatile} {"sha1":"21146C126409B3DD4C66944640EB173E2BE8EB3E"} */
        try {
            if(this.isSearchProjectCompanyVolatile) {
                this.resetSearchProjectCompanyOptions();
            }
        }
        catch (error) {
            console.error('Error occured when resetting searchProjectCompanyOptions');
        }
        /* {CLOSE:calculateEverything_isSearchProjectCompanyVolatile} {"sha1":"21146C126409B3DD4C66944640EB173E2BE8EB3E"} */
        /* {CLOSE:calculateEverything} {} */
    }
    /* {POSTCLOSE:calculateEverything} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:ngDoCheck} {"sha1":"75839B27D41E20648CE296AC51D61E24D541A70A"} */
    ngDoCheck() {
        /* {OPEN:ngDoCheck} {"sha1":"A154E0663059B6D385B4F6C11094F2084FF5E184"} */
        this.calculateEverything();
        /* {CLOSE:ngDoCheck} {"sha1":"A154E0663059B6D385B4F6C11094F2084FF5E184"} */
    }
    /* {POSTCLOSE:ngDoCheck} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:ngOnDestroy} {"sha1":"C21B2137D2BD86DF7050E24A39F08355F04DA4E9"} */
    ngOnDestroy() {
        /* {OPEN:ngOnDestroy} {"sha1":"85AA65230B2C97013ABDD30D48BEB93C04E8EE54"} */
        this.utilsService.unsubscribeSubscriptions(this.subscriptions);
        /* {CLOSE:ngOnDestroy} {"sha1":"85AA65230B2C97013ABDD30D48BEB93C04E8EE54"} */
    }
    /* {POSTCLOSE:ngOnDestroy} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {CLOSE:CLASS} {} */
}
/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */
