
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user.model';
import { environment } from './../../../environments/environment';
import { ApiService } from './api.service';
import { UtilsService } from './utils.service';

@Injectable()
export class UserService {

    private userid: string;
    private username: string;
    private fullname: string;
    private privileges: string[];
    userDataUpdated    = new Subject<any>();

    constructor(
        private apiService: ApiService,
        private utilsService: UtilsService
    ) { }

    clearDetails(): void {
        this.userid = null;
        this.username = null;
        this.fullname = null;
        this.privileges = [];
    }

    public setUserid(_userid: string) {
        this.userid = _userid;
    }

    public setUsername(_username: string) {
        this.username = _username;
    }

    public setFullName(_fullname: string) {
        this.fullname = _fullname;
    }

    public setPrivileges(_privileges: string[]) {
        this.privileges = _privileges;
    }

    public getUserid(): string {
        return this.userid;
    }

    public getUsername(): string {
        return this.username;
    }

    public getFullName(): string {
        return this.fullname;
    }

    public getPrivileges(): string[] {
        return this.privileges;
    }

    public hasPrivilege(privName: string): boolean {
        return (this.privileges && this.privileges.indexOf(privName) > -1);
    }

    public hasPrivilegeFromMultiple(privs: string[]): boolean {
        if (privs) {
            for (let priv of privs) {
                if (this.hasPrivilege(priv)) {
                    return true;
                }
            }
        }
        return false;
    }

    public setDetailsFromData(data): void {
        if (data.UserName == "noprivauthtoken") {
            this.clearDetails();
        } else {
            this.setUserid(data.UserID);
            this.setUsername(data.UserName);
            this.setFullName(data.FullName);
            this.setPrivileges(data.Privileges);
        }

        setTimeout(() => {
            this.userDataUpdated.next(data);
        }, 100);
    }

    public reloadUserDetails(): Observable<any> {
        let params = {
            environment: environment.envName,
            queryType: "All"
        }
        return this.apiService.post(`svc/EnvironmentInformation`, params).pipe(map(
            (data) => {
                if (this.utilsService.isSuccessfulResponse(data)) {
                    this.setDetailsFromData(data);
                    return data;
                }
            }
        ));
    }

    //Use this function when you need to set default value as logged in user. Not complete User, but should fulfil whats needed.
    public getLoggedInUserObj() : User {
        let user    =   new User();

        user.ObjectID   =   this.getUserid();
        user.Name       =   this.getFullName();
        user.UserName   =   this.getUsername();

        return user;
    }
}