import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfigService } from '../../services/config.service';
import { MsgsService } from '../../services/msgs.service';
import { UserService } from '../../services/user.service';
import { UtilsService } from '../../services/utils.service';

@Component({
    selector: 'app-box-layout',
    templateUrl: './box-layout.component.html',
    styleUrls: ['./box-layout.component.scss']
})
export class BoxLayoutComponent implements OnInit, OnDestroy {
    subscriptions: Array<Subscription> = [];
    errorMsgs: Message[] = [];
    showAllErrorsCalled: boolean = false;
    cougarVersion: string;
    showLoginBackground: boolean = this.configService.settings.showLoginBackground;

    backgroundImages = ['login-background1.jpg', 'login-background2.jpg'];
    customBackgroundImage: string;

    constructor(
        private utilsService: UtilsService,
        public userService: UserService,
        public msgsService: MsgsService,
        public configService: ConfigService
    ) {
    }

    ngOnInit() {
        this.customBackgroundImage = this.showLoginBackground ? 'url(\'./assets/images/' + this.backgroundImages[Math.floor(Math.random() * this.backgroundImages.length)] + '\')' : 'none';

        this.subscriptions.push(
            this.msgsService.errorMsgsUpdated.subscribe(
                data => {
                    this.errorMsgs = data;
                }
            )
        );

        this.subscriptions.push(
            this.msgsService.showAllErrors.subscribe((data) => {
                this.showAllErrorsCalled = data;
            })
        );
    }

    onRouteUpdate() {
        this.msgsService.clearErrorMessages();
    }

    ngOnDestroy() {
        this.utilsService.unsubscribeSubscriptions(this.subscriptions);
    }
}
