import * as moment from 'moment';

export class DateDiff {

    static getToday() : Date {
        return this.startOfDay(new Date());
    }

    static startOfDay(date: Date) : Date {
        return moment(date).startOf('day').toDate();
    }

    static endOfDay(date: Date) : Date {
        return moment(date).endOf('day').toDate();
    }

    static startOfWeek(date: Date) : Date {
        return moment(date).startOf('week').toDate();
    }

    static startOfMonth(date: Date) : Date {
        return moment(date).startOf('month').toDate();
    }

    static endOfWeek(date: Date) : Date {
        return moment(date).endOf('week').toDate();
    }

    static addDays(date, days) : Date {
        return moment(date).add(days, 'days').toDate();
    }

    static getWeekDates(date: Date) {
        return this.getDatesBetween(this.startOfWeek(date), this.endOfWeek(date));
    }

    static getDateDiff(startDate: Date, endDate: Date, unit: moment.unitOfTime.DurationConstructor = 'days') {
        if(!startDate || !endDate) {
            return null;
        }
        let date1 = moment(startDate),
            date2 = moment(endDate);

        return date2.diff(date1, unit);
    }

    static getDatesBetween(startDate: Date, endDate: Date) {
        var dates = [];
        var now = moment(startDate).startOf('day');
        var lastDate = moment(endDate).startOf('day');
    
        while (now.isSameOrBefore(lastDate)) {
            dates.push(now.clone().toDate());
            now.add(1, 'days');
        }
        return dates;
    }

    static convertDateToString(date : Date, format : string = 'DD-MM-YYYY HH:mm') : string {
        if (date) {
            return moment(date).format(format);
        }
        return null;
    }

    static convertStringToDate(dateString : string, format : string = 'DD-MM-YYYY HH:mm') : Date {
        if (dateString) {
            return moment(dateString, format).toDate();
        }
        return null;
    }
}