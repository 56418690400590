<mat-form-field appearance="fill">
  <mat-label>Select an option</mat-label>
  <mat-select [(ngModel)]="dateFilter.selected" (ngModelChange)="onChange($event.target)"  placeholder="_key">
    <mat-option [value] = "''">None</mat-option>
    <mat-option
      *ngFor="let option of dropdownOptions | keyvalue: asIsOrder"
      [value]="option.key"
      >{{ option.value }}
    </mat-option>
  </mat-select>
</mat-form-field>
<div>
  <app-cbos-date-filter
    *ngIf="isBetweenSelected()"
    [dateRange]="dateFilter"
    (datesEmitter)="setDates($event)"
  >
  </app-cbos-date-filter>
</div>
