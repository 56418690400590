<div class="layout-topbar" *ngIf="!staticTopBar" [class.pointer-events-none]="pointerNone">

    <a routerLink="/" *ngIf="app.isHorizontal && !app.isMobile()" class="topbar-logo">
        <img alt="logo" [src]="logoURL" />
        <span class="app-name"></span>
    </a>

    <a routerLink="/">
        <img alt="logo" [src]="logoURL" class="mobile-logo" />
    </a>

    <a href="#" class="menu-btn" (click)="app.onMenuButtonClick($event)" *ngIf="app.isStatic">
        <i class="pi pi-arrow-right"></i>
    </a>

    <a href="javascript:;" class="topbar-menu-btn" (click)="app.onTopbarMobileMenuButtonClick($event)" *ngIf="showMenu && mainMenuItems?.length && (app.isHorizontal || app.isMobile())">
        <i class="material-icons">&#xE5D2;</i>
    </a>

    <div class="layout-topbar-menu-wrapper" *ngIf="showMenu && (app.isHorizontal || app.isMobile())">
        <ng-container *ngTemplateOutlet="menuTemplate; context:{ menuItems: (app.isHorizontal ? mainMenuItems : topMenuItems) }">
        </ng-container>
    </div>
</div>

<!-- Cant use same layout-topbar class as above, as styling is nested within layout-main, which is not needed here -->
<div class="layout-static-topbar" *ngIf="staticTopBar" [ngClass]="{'pointer-events-none' : pointerNone, 
                                                                'no-header' : !showDefaultHeader}">
    <div class="layout-topbar-wrapper">
        <div class="layout-topbar-left">
            <a routerLink="/" class="layout-topbar-logo">
                <img alt="logo" [src]="logoURL" />
            </a>
        </div>
        <div class="layout-topbar-right" *ngIf="showDefaultHeader">
            <a class="layout-menu-button" (click)="app.onToggleStaticMenu($event)" title="Toggle Sidebar" *ngIf="showSidebar">
                <i class="pi pi-chevron-right"></i>
            </a>
            <ng-template #customTopBarHeader></ng-template>
            <ng-container *ngTemplateOutlet="omniSearch"></ng-container>
            <ng-container *ngTemplateOutlet="menuTemplate; context:{ menuItems: topMenuItems }">
            </ng-container>
        </div>
    </div>
</div>

<ng-template #menuTemplate let-menuItems="menuItems">
    <ul class="topbar-menu" [ngClass]="{'topbar-menu-active': app.topbarMenuActive}" (click)="app.onTopbarMenuClick($event)">
        <li *ngFor="let menuItem of menuItems; let i = index" [attr.id]="menuItem.id" 
            [ngClass]="{'active-topmenuitem': app.activeTopbarItem === menuItem}"
            (click)="app.onTopbarRootItemClick($event, menuItem)">

            <ng-container *ngTemplateOutlet="menuItemContentTemplate;context:{menuItem: menuItem, level: 0}"></ng-container>

            <a href="javascript:;" *ngIf="menuItem.items?.length && !menuItem.url && !menuItem.routerLink"
                title="{{menuItem.title ? menuItem.title : menuItem.label}}">
                <ng-container *ngTemplateOutlet="menuItemSubContentTemplate;context:{menuItem: menuItem, level: 0, dontApplyTopBarCls: true}"></ng-container>
                <span class="caret"></span>
            </a>

            <ul *ngIf="(menuItem && menuItem.items && menuItem.items.length > 0 && !menuItem.url && !menuItem.routerLink)">
                <ng-container *ngTemplateOutlet="childMenuItemsTemplate;context:{menuItem: menuItem, level: 1}">
                </ng-container>
            </ul>
        </li>
    </ul>
</ng-template>

<ng-template #menuItemTemplate let-menuItem="menuItem" let-level="level || 0" let-index="index || 0">
    <ng-container *ngIf="level >= 1 && !menuItem.routerLink && !menuItem.url && menuItem.items.length > 0">
        <li class="divider" *ngIf="index"></li>
        <li class="subnav" [attr.id]="menuItem.id">
            <i [class]="menuItem.icon" *ngIf="menuItem.icon"></i>
            <span [innerHTML]="menuItem.label | safe: 'html'"></span>
        </li>
    </ng-container>
    <li role="menuitem" [attr.id]="menuItem.id" *ngIf="!(level >= 1 && !menuItem.routerLink && !menuItem.url && menuItem.items.length > 0)">
        <ng-container *ngTemplateOutlet="menuItemContentTemplate;context:{menuItem: menuItem, level: level}"></ng-container>
    </li>
    <ng-container *ngIf="level">
        <ng-container *ngTemplateOutlet="childMenuItemsTemplate;context:{menuItem: menuItem, level: level + 1}">
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #childMenuItemsTemplate let-menuItem="menuItem" let-level="level || 0">
    <ng-container *ngFor="let subMenuItem of menuItem.items; let i = index">
        <ng-container *ngTemplateOutlet="menuItemTemplate;context:{menuItem: subMenuItem, level: level, index: i}">
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #menuItemContentTemplate let-menuItem="menuItem" let-level="level || 0">
    <a [routerLink]="menuItem.routerLink" *ngIf="menuItem.routerLink && (menuItem.Badge == undefined || menuItem.Badge > 0)" [attr.title]="!level ? (menuItem.title || menuItem.label) : ''">
        <ng-container *ngTemplateOutlet="menuItemSubContentTemplate;context:{menuItem: menuItem, level: level}"></ng-container>
    </a>
    <a [attr.href]="menuItem.url" *ngIf="menuItem.url && (menuItem.Badge == undefined || menuItem.Badge > 0)" [attr.title]="!level ? (menuItem.title || menuItem.label) : ''">
        <ng-container *ngTemplateOutlet="menuItemSubContentTemplate;context:{menuItem: menuItem, level: level}"></ng-container>
    </a>
</ng-template>

<ng-template #menuItemSubContentTemplate let-menuItem="menuItem" let-level="level || 0" let-dontApplyTopBarCls="dontApplyTopBarCls">
    <i [ngClass]="{'topbar-icon': !level, 'badge-icon': menuItem.Badge}" [class]="menuItem.icon" *ngIf="menuItem.icon"></i>
    <span [ngClass]="{'show-topbar-item-name': !level && !menuItem.Badge, 'topbar-item-name': !level && menuItem.Badge && !dontApplyTopBarCls}" 
        [innerHTML]="menuItem.label | safe: 'html'" *ngIf="menuItem.label">
    </span>
    <span class="topbar-badge animated rubberBand" *ngIf="menuItem.Badge" [ngClass]="{'blinkBG': menuItem.AdditionalAttributes === 'Blink'}">
        {{menuItem.Badge}}
    </span>
</ng-template>

<ng-template #omniSearch>
    <div class="omni-search ui-fluid" *ngIf="showOmniSearch">
        <oneit-autoComplete [(ngModel)]="omniSearchModel" name="GlobalOmniSearch" fieldLabel="Search" field="Description"
            [suggestions]="omniOptionGroups" [group]="true" (completeMethod)="autocompleteOmniOptions($event)" [multiple]="false"
            (onClear)="omniSearchModel = null" (onSelect)="onOmniOptionSelect($event)" [forceSelection]="true"
            [minLength]="3" placeholder="Global Search..." emptyMessage="No records found.">
        </oneit-autoComplete>
    </div>
</ng-template>
