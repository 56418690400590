<p-messages [(value)]="errorMsgs" *ngIf="errorMsgs && errorMsgs.length > 0"></p-messages>
<!-- {PREOPEN:20a472ef-cecf-4bd1-b648-06535db21f55} {"sha1":"8C4A0E2E0EFD768CAB5850B359AF669A56A45AB4"} -->
<form #form="ngForm" [class.pointer-events-none]="this.showLoader">
    <!-- {OPEN:20a472ef-cecf-4bd1-b648-06535db21f55} {} -->
    <!-- {OPEN:3a49e980-deda-4579-8077-ee863b6ffcb6} {} -->
    <!-- {PREOPEN:6a76a9b9-e6fd-43d3-be02-5c443aca9548} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
    <div class="ui-g form-group">
        <!-- {OPEN:6a76a9b9-e6fd-43d3-be02-5c443aca9548} {} -->
        <!-- {PREOPEN:6133f91e-be1b-4a88-9202-6954ec122b54} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
        <div class="ui-g-12 ui-md-3">
            <!-- {OPEN:6133f91e-be1b-4a88-9202-6954ec122b54} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
            <!-- {CLOSE:6133f91e-be1b-4a88-9202-6954ec122b54} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
        </div>
        <!-- {POSTCLOSE:6133f91e-be1b-4a88-9202-6954ec122b54} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:fbf09c70-3d86-491e-9978-ab1b9c206ece} {"sha1":"EA45B5538DA9EF952A17324A1926BE826076CCEE"} -->
        <div class="ui-g-12 ui-md-6">
            <!-- {OPEN:fbf09c70-3d86-491e-9978-ab1b9c206ece} {} -->
            <!-- {OPEN:26bde8ba-0390-40e4-973a-757121078e64} {"sha1":"8EDA2B89CB75FA49E500712D27CC895EEFA2693C"} -->
            <div class="ui-fluid">
                <span class="ui-float-label sticky">
                    <p-dropdown [autoDisplayFirst]="false" required [(ngModel)]="searchCreateAccount.Owner" name="Owner{{ searchCreateAccount.ObjectID }}" fieldLabel="Owner" optionLabel="name" (onChange)="onOwnerChange($event, searchCreateAccount)" [options]="ownerOptions" ></p-dropdown>
                    <label>Owner</label>
                </span>
            </div>
            <!-- {CLOSE:26bde8ba-0390-40e4-973a-757121078e64} {"sha1":"8EDA2B89CB75FA49E500712D27CC895EEFA2693C"} -->
            <!-- {CLOSE:fbf09c70-3d86-491e-9978-ab1b9c206ece} {} -->
        </div>
        <!-- {POSTCLOSE:fbf09c70-3d86-491e-9978-ab1b9c206ece} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:2c44b3ad-dc64-4c3d-8168-f8b2317b10c0} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
        <div class="ui-g-12 ui-md-3">
            <!-- {OPEN:2c44b3ad-dc64-4c3d-8168-f8b2317b10c0} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
            <!-- {CLOSE:2c44b3ad-dc64-4c3d-8168-f8b2317b10c0} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
        </div>
        <!-- {POSTCLOSE:2c44b3ad-dc64-4c3d-8168-f8b2317b10c0} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {CLOSE:6a76a9b9-e6fd-43d3-be02-5c443aca9548} {} -->
    </div>
    <!-- {POSTCLOSE:6a76a9b9-e6fd-43d3-be02-5c443aca9548} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
    <!-- {PREOPEN:e28d6b21-b8bc-4144-8afb-aebebb427a36} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
    <div class="ui-g form-group">
        <!-- {OPEN:e28d6b21-b8bc-4144-8afb-aebebb427a36} {} -->
        <!-- {PREOPEN:37887584-d4f5-4d12-b91e-bc6bff7d8025} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
        <div class="ui-g-12 ui-md-3">
            <!-- {OPEN:37887584-d4f5-4d12-b91e-bc6bff7d8025} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
            <!-- {CLOSE:37887584-d4f5-4d12-b91e-bc6bff7d8025} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
        </div>
        <!-- {POSTCLOSE:37887584-d4f5-4d12-b91e-bc6bff7d8025} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:ea27d08b-fd2f-404a-90f1-42b5fbd15d55} {"sha1":"EA45B5538DA9EF952A17324A1926BE826076CCEE"} -->
        <div class="ui-g-12 ui-md-6">
            <!-- {OPEN:ea27d08b-fd2f-404a-90f1-42b5fbd15d55} {} -->
            <!-- {OPEN:8b354e5b-4465-4537-8e8c-75e92c6ce27e} {"sha1":"62B5726C4DE8664F1CD062630B0A9B15F99C99C9"} -->
            <div class="ui-fluid">
                <span class="ui-float-label sticky">
                    <input required [(ngModel)]="searchCreateAccount.AccountName" name="AccountName{{ searchCreateAccount.ObjectID }}" fieldLabel="Account Name" type="text" pInputText class="ui-inputtext">
                    <label>Account Name</label>
                </span>
            </div>
            <!-- {CLOSE:8b354e5b-4465-4537-8e8c-75e92c6ce27e} {"sha1":"62B5726C4DE8664F1CD062630B0A9B15F99C99C9"} -->
            <!-- {CLOSE:ea27d08b-fd2f-404a-90f1-42b5fbd15d55} {} -->
        </div>
        <!-- {POSTCLOSE:ea27d08b-fd2f-404a-90f1-42b5fbd15d55} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:56b7cb90-0baa-4770-90d7-f0c5a2bc98b7} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
        <div class="ui-g-12 ui-md-3">
            <!-- {OPEN:56b7cb90-0baa-4770-90d7-f0c5a2bc98b7} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
            <!-- {CLOSE:56b7cb90-0baa-4770-90d7-f0c5a2bc98b7} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
        </div>
        <!-- {POSTCLOSE:56b7cb90-0baa-4770-90d7-f0c5a2bc98b7} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {CLOSE:e28d6b21-b8bc-4144-8afb-aebebb427a36} {} -->
    </div>
    <!-- {POSTCLOSE:e28d6b21-b8bc-4144-8afb-aebebb427a36} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
    <!-- {PREOPEN:4523137f-b2e7-409c-9f32-6835800398fa} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
    <div class="ui-g form-group">
        <!-- {OPEN:4523137f-b2e7-409c-9f32-6835800398fa} {} -->
        <!-- {PREOPEN:3b68b06b-8d7b-4133-8a6f-61c595fc9885} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
        <div class="ui-g-12 ui-md-3">
            <!-- {OPEN:3b68b06b-8d7b-4133-8a6f-61c595fc9885} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
            <!-- {CLOSE:3b68b06b-8d7b-4133-8a6f-61c595fc9885} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
        </div>
        <!-- {POSTCLOSE:3b68b06b-8d7b-4133-8a6f-61c595fc9885} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:c46c034b-e5c5-402e-8b4b-fa0a9a73cb2e} {"sha1":"EA45B5538DA9EF952A17324A1926BE826076CCEE"} -->
        <div class="ui-g-12 ui-md-6">
            <!-- {OPEN:c46c034b-e5c5-402e-8b4b-fa0a9a73cb2e} {} -->
            <!-- {OPEN:4748cfa3-3041-4703-938e-acd525b89758} {"sha1":"A5FC69550A1DF07FCABF2707466DCAB29CFAF61B"} -->
            <oneit-checkbox [(ngModel)]="searchCreateAccount.CreateContact" name="CreateContact{{ searchCreateAccount.ObjectID }}" fieldLabel="" binary="true" label="Create Contact"></oneit-checkbox>
            <!-- {CLOSE:4748cfa3-3041-4703-938e-acd525b89758} {"sha1":"A5FC69550A1DF07FCABF2707466DCAB29CFAF61B"} -->
            <!-- {CLOSE:c46c034b-e5c5-402e-8b4b-fa0a9a73cb2e} {} -->
        </div>
        <!-- {POSTCLOSE:c46c034b-e5c5-402e-8b4b-fa0a9a73cb2e} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:ecb27890-b65d-4d44-a448-d822b26e6469} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
        <div class="ui-g-12 ui-md-3">
            <!-- {OPEN:ecb27890-b65d-4d44-a448-d822b26e6469} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
            <!-- {CLOSE:ecb27890-b65d-4d44-a448-d822b26e6469} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
        </div>
        <!-- {POSTCLOSE:ecb27890-b65d-4d44-a448-d822b26e6469} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {CLOSE:4523137f-b2e7-409c-9f32-6835800398fa} {} -->
    </div>
    <!-- {POSTCLOSE:4523137f-b2e7-409c-9f32-6835800398fa} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
    <!-- {PREOPEN:d3e9564a-963c-4b38-a421-9298915a15c3} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
    <div class="ui-g form-group">
        <!-- {OPEN:d3e9564a-963c-4b38-a421-9298915a15c3} {} -->
        <!-- {PREOPEN:fc760962-43f5-4c96-8431-18efebaa825f} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
        <div class="ui-g-12 ui-md-3">
            <!-- {OPEN:fc760962-43f5-4c96-8431-18efebaa825f} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
            <!-- {CLOSE:fc760962-43f5-4c96-8431-18efebaa825f} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
        </div>
        <!-- {POSTCLOSE:fc760962-43f5-4c96-8431-18efebaa825f} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:55ab8244-6572-491b-b654-62955210dd46} {"sha1":"EA45B5538DA9EF952A17324A1926BE826076CCEE"} -->
        <div class="ui-g-12 ui-md-6">
            <!-- {OPEN:55ab8244-6572-491b-b654-62955210dd46} {} -->
            <!-- {OPEN:da4b5e88-3c2e-41bc-8e39-44a348b6f32d} {"sha1":"9797C896DB9DF24A102E4D0E2A99B25F18059D00"} -->
            <div [style.text-align]="'center'" [style.paddingTop.px]="'10'" [style.paddingLeft.px]="'10'" [style.paddingBottom.px]="'10'" [style.paddingRight.px]="'10'">
                <button pButton type="button" label="Create Account" (click)="onCreateAccountClick($event, searchCreateAccount)"></button>
            </div>
            <!-- {CLOSE:da4b5e88-3c2e-41bc-8e39-44a348b6f32d} {"sha1":"9797C896DB9DF24A102E4D0E2A99B25F18059D00"} -->
            <!-- {CLOSE:55ab8244-6572-491b-b654-62955210dd46} {} -->
        </div>
        <!-- {POSTCLOSE:55ab8244-6572-491b-b654-62955210dd46} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:5118d4fb-c08c-47ba-bd43-0a6e983ba972} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
        <div class="ui-g-12 ui-md-3">
            <!-- {OPEN:5118d4fb-c08c-47ba-bd43-0a6e983ba972} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
            <!-- {CLOSE:5118d4fb-c08c-47ba-bd43-0a6e983ba972} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
        </div>
        <!-- {POSTCLOSE:5118d4fb-c08c-47ba-bd43-0a6e983ba972} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {CLOSE:d3e9564a-963c-4b38-a421-9298915a15c3} {} -->
    </div>
    <!-- {POSTCLOSE:d3e9564a-963c-4b38-a421-9298915a15c3} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
    <!-- {CLOSE:3a49e980-deda-4579-8077-ee863b6ffcb6} {} -->
    <p-progressSpinner *ngIf="showLoader"></p-progressSpinner>
    <!-- {CLOSE:20a472ef-cecf-4bd1-b648-06535db21f55} {} -->
</form>
<!-- {POSTCLOSE:20a472ef-cecf-4bd1-b648-06535db21f55} {"sha1":"3FD6E659562C1876D02FC8CF54746B1502442CD2"} -->
