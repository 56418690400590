import { DataProviderService } from './../../cbos/data-providers/data-provider.service';
/* {OPEN:IMPORT} {"sha1":"BCD4DDD350F97E98365FAC65E02E27BC9E7D2430"} */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseAddDataProviderComponent } from './base-add-data-provider.component';
/* {CLOSE:IMPORT} {"sha1":"BCD4DDD350F97E98365FAC65E02E27BC9E7D2430"} */
import { ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../oneit/services/user.service';
import { UtilsService } from '../../oneit/services/utils.service';
import { SearchService } from '../../oneit/services/search.service';
import { AppService } from '../../app.service';
import { ConfirmationService } from 'primeng/api';
import { AddDataProviderService } from './add-data-provider.service';
import { DynamicDialogRef } from 'primeng';
import { MsgsService } from '../../oneit/services/msgs.service';
import { OptionsWrapper } from '../../oneit/models/options.model';
/* {POSTCLOSE:IMPORT} {"sha1":"BCD4DDD350F97E98365FAC65E02E27BC9E7D2430"} */

/* {PREOPEN:CLASS} {"sha1":"9B01B790D6F0A576E6134F5E8DA57474870EDB92"} */
@Component({
    selector: 'app-add-data-provider',
    templateUrl: './add-data-provider.component.html'
})
export class AddDataProviderComponent extends BaseAddDataProviderComponent implements OnInit, OnDestroy {
    /* {OPEN:CLASS} {} */

    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
        super.ngOnInit();
        /* {CLOSE:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
        this.subscriptions.push(
            this.msgsService.errorMsgsUpdated.subscribe(errorMsgs => {
                this.errorMsgs = errorMsgs;
            })
        );
        /* {POSTCLOSE:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
    }

    ngOnDestroy() {
        /* {OPEN:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
        super.ngOnDestroy();
        /* {CLOSE:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
    }

    /* {CLOSE:CLASS} {} */

    @ViewChild('form') form: NgForm;
    errorMsgs;

    //@override
    constructor(
        public router : Router,
        public userService : UserService,
        public utilsService : UtilsService,
        public searchService : SearchService,
        public appService : AppService,
        public confirmationService: ConfirmationService,
        private dataProviderService : DataProviderService,
        public dialogRef :  DynamicDialogRef,
        public msgsService: MsgsService
    ) {
        super(router , userService , utilsService , searchService , appService , confirmationService);
    }

    //@override
    resetDataProviderOptions() {
        this.showLoader = true;
        let wrapper : OptionsWrapper = this.getDataProviderOptions();

        if(wrapper){
            this.isDataProviderVolatile = wrapper.isVolatile;

            this.subscriptions.push(
                wrapper.getObservable().subscribe (data => {
                this.showLoader = false;
                for (let item in data) {
                  this.dataProviderOptions.push(this.dataProviderService.formatDataProviderItem(data, item));
                }
                this.searchDataProvider.DataProvider = this.dataProviderOptions[0];
                }, error => {
                    this.showLoader = false;
                    this.utilsService.handleError(error);
                })
            );
        }
    }

    //@override
    onAddClick() {
        this.utilsService.clearErrorMessages();

        if(!this.form.valid) {
            this.utilsService.showAllErrorMessages();
            return;
        }

        localStorage.setItem('DATA_PROVIDER', JSON.stringify(this.searchDataProvider.DataProvider.value));

        this.showLoader = true;

        const params = {
            username : this.searchDataProvider.Username,
            password : this.searchDataProvider.Password,
            apiKey: this.searchDataProvider.ApiKey
        };

        this.subscriptions.push(
            this.dataProviderService.addDataProvider(params).subscribe((data) => {

              if(this.searchDataProvider.DataProvider.value === 'cis') {
                window.open(data);
              }
                this.showLoader = false
                this.dialogRef.close(true);
            },error => {
                this.showLoader = false;
                this.utilsService.handleError(error);
            })
        );
    }
}
/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */
