import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { environment } from '../../../environments/environment';
import { ApiService } from '../services/api.service';
import { ConfigService } from '../services/config.service';
import { UserService } from '../services/user.service';
import { UtilsService } from '../services/utils.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private appService: AppService,
        private utilServices: UtilsService,
        private userService: UserService,
        private configService: ConfigService,
        private apiService: ApiService
    ) {

    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const routePath = route.routeConfig.path;

        if (!localStorage.getItem("USER_ID")) {
            if (routePath == 'login') {
                return of(true);
            }

            this.router.navigate(['/login']);
            return of(false);
        }

        return this.appService.doLogin(`users/${localStorage.getItem("USER_ID")}`, null).pipe(map(data => {
                if (routePath == 'login') {
                    this.router.navigate(['/dashboard']);
                }
                return true;
            }, error => {
                localStorage.clear();
                this.router.navigate(['/login']);
                return false;
            }),
            catchError(
                err => {
                    console.log('Handling error locally and   it...', err);
                    localStorage.clear();
                    this.router.navigate(['/login']);
                    return of(false);
                }
            ));
    }
}