import { Directive, Input } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[oneitMinValue][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: MinValueValidatorDirective, multi: true }
    ]
})

export class MinValueValidatorDirective implements Validator {

    @Input()
    oneitMinValue: Number;

    constructor() { }

    validate(c: AbstractControl): { [key: string]: any } {

        if (!c.value || c.value >= this.oneitMinValue) {
            return null;
        } else {
            return {
                minValue: {
                    minValue: this.oneitMinValue
                }
            };
        }
    }
}