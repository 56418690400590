export abstract class AppConstants {

  // Value for Between Date Select Item
  public static MODULE : string = "MODULE";

  public static GOOGLE_MAPS_API_KEY = 'AIzaSyCaBh-MsMb8BBYJD_NcFzoCmygQbt5-QSw';

  // Available Modules
  public static PROJECTS : string = "projects";
  public static COMPANIES : string = "companies";

  public static DEFAULT_MODULE : string = AppConstants.PROJECTS;

  public static REFINE_FILTERS : string = 'Refine_Search_Filters';
  public static CACHE_FILTERS : string = 'CACHE_FILTERS';

  public static USER_INFO : string  = 'USER';

  public static SEARCH_RESULTS : string = "SEARCH_RESULTS";

}
