import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';
import { IFrameComponent, iframeResizer } from 'iframe-resizer';
import { IframeMsgHandlingService } from './../services/iframe-msg-handling.service';

@Directive({
    selector: '[appIframeResizer]'
})
export class IFrameResizerDirective implements AfterViewInit, OnDestroy {
    component: IFrameComponent;

    constructor(
        public element: ElementRef,
        public iIframeMsgHandlingService: IframeMsgHandlingService
    ) {

    }

    ngAfterViewInit() {
        var isOldIE = (window.navigator.userAgent.indexOf("MSIE") !== -1); // Detect IE10 and below

        const components = iframeResizer({
            checkOrigin: false,
            heightCalculationMethod: isOldIE ? 'max' : 'lowestElement',
            log: false,
            messageCallback: message => {
                this.iIframeMsgHandlingService.receiveMessage(message);
            }
        }, this.element.nativeElement);

        /* save component reference so we can close it later */
        this.component = components && components.length > 0 ? components[0] : null;

        if(this.component) {
            this.iIframeMsgHandlingService.setIframeComponent(this.component);

            if(this.component.iFrameResizer) {
                this.iIframeMsgHandlingService.setIframeResizerComponent(this.component.iFrameResizer);
            }
        }
    }

    ngOnDestroy(): void {
        if (this.component && this.component.iFrameResizer) {
            this.iIframeMsgHandlingService.setIframeComponent(null);
            this.iIframeMsgHandlingService.setIframeResizerComponent(null);
            this.component.iFrameResizer.close();
        }
    }
}