/* {OPEN:IMPORT} {"sha1":"5B6495F2EB6F640685AF7C90D3E2518D578406D5"} */
import { Component, DoCheck, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import { Message, MenuItem, ConfirmationService } from 'primeng/api';
import { AppService } from './../../app.service';
import { OptionsObservable, OptionsWrapper } from './../../oneit/models/options.model';
import { UserService } from './../../oneit/services/user.service';
import { UtilsService } from './../../oneit/services/utils.service';
import { SearchService } from './../../oneit/services/search.service';
import { CreateAccountService } from './create-account.service';
import { SearchCreateAccount } from './create-account.model';
/* {CLOSE:IMPORT} {"sha1":"5B6495F2EB6F640685AF7C90D3E2518D578406D5"} */

@Component({
    selector: 'app-base-create-account',
    template: ''
})
/* {PREOPEN:CLASS} {"sha1":"1B9E54885CF64DB4D6C5118A3842152E07163D7D"} */
export class BaseCreateAccountComponent implements DoCheck, OnInit, OnDestroy {
    /* {OPEN:CLASS} {} */
    
    /* {OPEN:COMMON_VARS} {"sha1":"672A211DEEA9A3559D121CBDC5978BBA47BD6B7D"} */
    @Input() parent : any;
    pageTitle : string;
    /* {CLOSE:COMMON_VARS} {"sha1":"672A211DEEA9A3559D121CBDC5978BBA47BD6B7D"} */

    /* {OPEN:VARS} {"sha1":"059691018225110361060C6E682EFB5C78FEF542"} */
    searchCreateAccount : SearchCreateAccount = new SearchCreateAccount();
    ownerOptions : any[] = [];
    isOwnerVolatile : boolean = false;
    /* {CLOSE:VARS} {"sha1":"059691018225110361060C6E682EFB5C78FEF542"} */
    
    showResults : boolean = false;
    showLoader : boolean = false;
    subscriptions : Array<Subscription> = [];

    constructor(
        /* {OPEN:constructor} {"sha1":"B0BDC87A4799637E0E3CDAB9481B4672332C684C"} */
        public router : Router,
        public userService : UserService,
        public utilsService : UtilsService,
        public searchService : SearchService,
        public appService : AppService,
        public confirmationService: ConfirmationService,
        public createAccountService : CreateAccountService,
        /* {CLOSE:constructor} {"sha1":"B0BDC87A4799637E0E3CDAB9481B4672332C684C"} */
    ){
        
    }

    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"5FBDAAB044BC4F999356808F755A7334E11EE273"} */
        this.resetInitVariables();
        this.calculateEverything();
        this.executeScreenSpecificInitCode();
        /* {CLOSE:ngOnInit} {"sha1":"5FBDAAB044BC4F999356808F755A7334E11EE273"} */
    }

    /* {PREOPEN:executeScreenSpecificInitCode} {"sha1":"DDD081544316B33777AB7B7FFD796FB9E663E681"} */
    executeScreenSpecificInitCode() {
        /* {OPEN:executeScreenSpecificInitCode} {"sha1":"54DB41AD32937D86C7318EB4BE666A1AD7B6B618"} */
        if(!this.parent) {
        }
        /* {CLOSE:executeScreenSpecificInitCode} {"sha1":"54DB41AD32937D86C7318EB4BE666A1AD7B6B618"} */
    }
    /* {POSTCLOSE:executeScreenSpecificInitCode} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getOwnerOptions} {"sha1":"B7C3D054D8C37125BCD0A6D2DCC8314937D74226"} */
    getOwnerOptions(): OptionsWrapper {
        /* {OPEN:getOwnerOptions} {"sha1":"2CCE92B016D8820895B6D0C37D467A12AC4638A2"} */
        return new OptionsObservable(false, this.appService.getOwnersObservable());
        /* {CLOSE:getOwnerOptions} {"sha1":"2CCE92B016D8820895B6D0C37D467A12AC4638A2"} */
    }
    /* {POSTCLOSE:getOwnerOptions} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:resetOwnerOptions} {"sha1":"C0002ADF0D36ECC9F333B937316FCE475A36793D"} */
    resetOwnerOptions() {
        /* {OPEN:resetOwnerOptions} {"sha1":"6C60EEA694426858FB1E15ADBD02AB094D5C5A04"} */
        if(this.parent) {
            this.parent.resetOwnerOptions();
        } else {
            let wrapper : OptionsWrapper = this.getOwnerOptions();

            if (wrapper) {
                this.isOwnerVolatile = wrapper.isVolatile;

                this.subscriptions.push(wrapper.getObservable()
                    .subscribe (
                        data => {
                            this.ownerOptions = data;
                        },
                        error => {
                            this.utilsService.handleError(error);
                        }
                    )
                );
            }
        }
        /* {CLOSE:resetOwnerOptions} {"sha1":"6C60EEA694426858FB1E15ADBD02AB094D5C5A04"} */
    }
    /* {POSTCLOSE:resetOwnerOptions} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onOwnerChange} {"sha1":"E373905D1E10001EF36A388CE6F427D039AB6379"} */
    onOwnerChange(event, searchCreateAccount) {
        /* {OPEN:onOwnerChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:onOwnerChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:onOwnerChange} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onCreateAccountClick} {"sha1":"1ADEF3C0148D2EA25903E54D616F255076FB261A"} */
    onCreateAccountClick(event, searchCreateAccount) {
        /* {OPEN:onCreateAccountClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onCreateAccountClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onCreateAccountClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:resetInitVariables} {"sha1":"846C0DF64879342578D595FE17BC00B96F0BAED6"} */
    resetInitVariables() {
        /* {OPEN:resetInitVariables} {} */
        /* {OPEN:resetInitVariables_resetOwnerOptions} {"sha1":"7632B5F0C727E48D4A9E49547CFB1612150AA57C"} */
        try {
            this.resetOwnerOptions();
        }
        catch (error) {
            console.error('Error occured when executing resetOwnerOptions', error);
        }
        /* {CLOSE:resetInitVariables_resetOwnerOptions} {"sha1":"7632B5F0C727E48D4A9E49547CFB1612150AA57C"} */
        /* {CLOSE:resetInitVariables} {} */
    }
    /* {POSTCLOSE:resetInitVariables} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getPageTitle} {"sha1":"174A3D30FFA103DD7BE180EE89CC0CF5EB6D11B8"} */
    getPageTitle() {
        /* {OPEN:getPageTitle} {"sha1":"A3C75DF984C6C65A5BE9BB92E1899DDECA9DED70"} */
        return 'Create Account';
        /* {CLOSE:getPageTitle} {"sha1":"A3C75DF984C6C65A5BE9BB92E1899DDECA9DED70"} */
    }
    /* {POSTCLOSE:getPageTitle} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:calculateEverything} {"sha1":"CE17BF2375171825A37C544A1DCBF3A565DD5181"} */
    calculateEverything() {
        /* {OPEN:calculateEverything} {} */
        /* {OPEN:calculateEverything_pageTitle} {"sha1":"B3CA5EAE3ED54D1F0D95EA2CD05351A4A6D7480F"} */
        try {
            this.pageTitle = this.getPageTitle();
        }
        catch (error) {
            console.error('Error occured when setting pageTitle');
        }
        /* {CLOSE:calculateEverything_pageTitle} {"sha1":"B3CA5EAE3ED54D1F0D95EA2CD05351A4A6D7480F"} */
        /* {OPEN:calculateEverything_isOwnerVolatile} {"sha1":"0EA14568C9FACF325AE62E1AA25205C4BF7C3CB5"} */
        try {
            if(this.isOwnerVolatile) {
                this.resetOwnerOptions();
            }
        }
        catch (error) {
            console.error('Error occured when resetting ownerOptions');
        }
        /* {CLOSE:calculateEverything_isOwnerVolatile} {"sha1":"0EA14568C9FACF325AE62E1AA25205C4BF7C3CB5"} */
        /* {CLOSE:calculateEverything} {} */
    }
    /* {POSTCLOSE:calculateEverything} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:ngDoCheck} {"sha1":"75839B27D41E20648CE296AC51D61E24D541A70A"} */
    ngDoCheck() {
        /* {OPEN:ngDoCheck} {"sha1":"A154E0663059B6D385B4F6C11094F2084FF5E184"} */
        this.calculateEverything();
        /* {CLOSE:ngDoCheck} {"sha1":"A154E0663059B6D385B4F6C11094F2084FF5E184"} */
    }
    /* {POSTCLOSE:ngDoCheck} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:ngOnDestroy} {"sha1":"C21B2137D2BD86DF7050E24A39F08355F04DA4E9"} */
    ngOnDestroy() {
        /* {OPEN:ngOnDestroy} {"sha1":"85AA65230B2C97013ABDD30D48BEB93C04E8EE54"} */
        this.utilsService.unsubscribeSubscriptions(this.subscriptions);
        /* {CLOSE:ngOnDestroy} {"sha1":"85AA65230B2C97013ABDD30D48BEB93C04E8EE54"} */
    }
    /* {POSTCLOSE:ngOnDestroy} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {CLOSE:CLASS} {} */
}
/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */