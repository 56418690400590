import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as pako from 'pako';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class ApiService {

    apiBase: string;
    debounceTime: number;

    constructor(
        private httpClient: HttpClient
    ) {
        this.apiBase = environment.baseUrl; //fetch this using environments;
    }

    get(url: string): Observable<any> {
        let apiURL = this.apiBase + url;
        return this.httpClient.get(apiURL).pipe(map(res => res));
    }

    post(url: string, param?, compress: boolean = false): Observable<any> {
        let apiURL = this.apiBase + url;

        if (compress) {
            const headers: HttpHeaders = new HttpHeaders({ 'Content-Encoding': 'gzip', 'Content-Type': 'application/octet-stream' });

            return this.httpClient.post(apiURL, pako.gzip(JSON.stringify(param)).buffer, { headers: headers, responseType: "json" });
        } else {
            return this.httpClient.post(apiURL, param);
        }
    }

    put(url: string, param?): Observable<any> {
        let apiURL = this.apiBase + url;
        return this.httpClient.patch(apiURL, param).pipe(map(res => res));
    }

    delete(url: string): Observable<any> {
        let apiURL = this.apiBase + url;
        return this.httpClient.delete(apiURL).pipe(map(res => res));
    }

    postBlobRequest(url: string, param?): Observable<any> {
        let apiURL = this.apiBase + url;
        return this.httpClient.post(
            apiURL, param, {
                responseType: 'blob',
                observe: 'response' //When this is not defined, it will directly return only BLOB
            }
        ).pipe(map(res => ({ content: res.body })));
    }

    uploadFile(formData): Observable<any> {
        let headers = new HttpHeaders();
        return this.httpClient.post(environment.baseUrl + 'uploadFile', formData, { reportProgress: true, observe: 'events', headers: headers, withCredentials: true });
    }
}