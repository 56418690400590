import { LocalStorageService } from './../../cbos/services/local-storage.service';
import { AppConstants } from 'src/app/cbos/constants/app-constants';
/* {OPEN:IMPORT} {"sha1":"B3C4A582F89DA6A2EAE379204910BE6FE74466ED"} */
import { Component, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { BaseTableViewComponent } from './base-table-view.component';
/* {CLOSE:IMPORT} {"sha1":"B3C4A582F89DA6A2EAE379204910BE6FE74466ED"} */
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserService } from '../../oneit/services/user.service';
import { UtilsService } from '../../oneit/services/utils.service';
import { SearchService } from '../../oneit/services/search.service';
import { AppService } from '../../app.service';
import {ConfirmationService, MenuItem} from 'primeng/api';
import { TableViewService } from './table-view.service';
import { Subject } from 'rxjs';
import { MultipleProjectDealPopUpComponent } from '../multiple-project-deal-pop-up/multiple-project-deal-pop-up.component';
import { FullLayoutComponent } from '../../oneit/components/full-layout/full-layout.component';
import { DialogService, Table } from 'primeng';
import { Input } from '@angular/core';
/* {POSTCLOSE:IMPORT} {"sha1":"B3C4A582F89DA6A2EAE379204910BE6FE74466ED"} */

/* {PREOPEN:CLASS} {"sha1":"D841E9C2FCF75F35195E07D7C3EB4A3AE4AC1567"} */
@Component({
    selector: 'app-table-view',
    templateUrl: './table-view.component.html'
})
export class TableViewComponent extends BaseTableViewComponent implements OnInit, OnDestroy {
  /* {OPEN:CLASS} {} */

    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
        super.ngOnInit();
        /* {CLOSE:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */

    }

  ngOnDestroy() {
    /* {OPEN:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
    super.ngOnDestroy();
    /* {CLOSE:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
  }

  /* {CLOSE:CLASS} {} */
  pageIndex;
  first: number = 0;
  rows = 30;

  @Input() set setProjectsOrCompanies(data) {
    this.projectsOrCompanies = data;
      if (this.appService.isProjectsSelected()) {
          this.barboursTableCols = this.getBarboursTableCols();
          this.columnsBtnItems = this.settingProjectColoumns();
      }
      if (this.appService.isCompaniesSelected()) {
          this.barboursTableCols = this.getCompaniesCols();
          this.columnsBtnItems = this.settingCompanyColoumns();
      }
    this.postProjectCompanyTableChange();
    if (localStorage.getItem('rows')) {
      this.rows = parseInt(JSON.parse(localStorage.getItem('rows')));
    }
    if (localStorage.getItem('pageChange')) {
      this.pageIndex = parseInt(JSON.parse(localStorage.getItem('pageChange')));
      this.first = (this.pageIndex * this.rows) - this.rows;
    } else {
      this.first = 0;
    }
  }

  @ViewChild('barboursTable') table: Table;

  @Input() set setConvertedDeals(data) {
    this.convertedDeals = data.map(item => item.projectId).filter((value, i, self) => self.indexOf(value) === i);
    this.setIsConvertedProject();
  }

  componentInView = new Subject();
  projectsOrCompanies = [];
  convertedDeals = [];

  //@override
  constructor(
    public router: Router,
    public userService: UserService,
    public utilsService: UtilsService,
    public searchService: SearchService,
    public appService: AppService,
    public confirmationService: ConfirmationService,
    public tableViewService: TableViewService,
    public fullLayoutComponent: FullLayoutComponent,
    public dialogService: DialogService,
    public localStorageService :LocalStorageService
  ) {
    super(router, userService, utilsService, searchService, appService, confirmationService, tableViewService);

  }

  @Output()
  addFavouriteEmitter = new EventEmitter();

  onAddFavouriteClick(item) {
    this.addFavouriteEmitter.emit(item);
  }

  postProjectCompanyTableChange() {
    if (this.projectsOrCompanies && this.projectsOrCompanies.length > 0) {
      this.projectsOrCompanies.forEach(project => {
        project.sortStartDate = project.start ? new Date(project.start).getTime() : 0;
        project.IsChecked = false;
      });
      this.setIsConvertedProject();
    }
  }

  settingCompanyColoumns(){
      return [
          {
              label: 'ID',
              command: () => {
                  this.onIdClick();
              }
          },
          {
              label: 'Company Name',
              command: () => {
                  this.onNameClick();
              }
          },
          {
              label: 'Company Phone',
              command: () => {
                  this.onPhoneClick();
              }
          },
          {
              label: 'Website',
              command: () => {
                  this.onWebsiteClick();
              }
          }
      ];
  }

  setIsConvertedProject() {
    if (this.projectsOrCompanies && this.projectsOrCompanies.length > 0) {
      this.projectsOrCompanies.forEach(project => {
        if (this.convertedDeals.includes(project.id)) {
            project.isConverted = true;
            project.convertedColor = '#27A0FC'
        }
        else {
            project.convertedColor = '#FFFFFF'
        }
      });
    }
  }

  paginate(event) {
    this.pageIndex = event.first / event.rows + 1;
    this.rows = parseInt(event.rows);
    localStorage.setItem('pageChange', JSON.stringify(this.pageIndex));
    localStorage.setItem('rows', JSON.stringify(this.rows));
  }

  //@override
  onCreateDealsClick(event, searchTableView) {
    this.utilsService.clearErrorMessages();

    this.fullLayoutComponent.dialogOpen = true;

    this.dialogService.open(MultipleProjectDealPopUpComponent, {
      header: 'Create Deal',
      ...this.appService.dynamicDialogConfig,
      data: {projects: this.projectsOrCompanies.filter(item => item.IsChecked).map(item => item.id)}
    }).onClose.pipe(takeUntil(this.componentInView)).subscribe(() => {
      this.utilsService.clearErrorMessages();
      this.fullLayoutComponent.dialogOpen = false;
    });
  }

  //@override
  onExcelClick() {
    this.utilsService.clearErrorMessages();

    this.exportExcelOrCSV(true);
  }

  //@override
  onCsvClick() {
    this.utilsService.clearErrorMessages();

    this.exportExcelOrCSV(false);
  }


  exportExcelOrCSV(isExcel) {
        const filteredArray = this.projectsOrCompanies.filter(item => item.IsChecked);
        if(filteredArray && filteredArray.length === 0) {
            this.utilsService.handleError('Please select at least 1 project or company to export');
            return;
        }
        let keysToFilter;
        let columns;
        if (this.projectsOrCompanies[0].module === "projects") {
            keysToFilter = ['id', 'title', 'address3', 'address4', 'planningStage', 'contractStage', 'value', 'primarySector', 'primaryCategory', 'units', 'start', 'converted'];
            columns = ['ID', 'Project Title', 'Town', 'County', 'Planning Stage', 'Contract Stage', 'Value', 'Sector', 'Category', 'Units', 'Start Date', 'Converted'];
        }
        else if (this.projectsOrCompanies[0].module === "companies") {
            keysToFilter = ['id', 'name', 'phone', 'website'];
            columns = ['ID', 'Company Name', 'Company Phone', 'Website'];
        }
        const exportArray = [];
        filteredArray.forEach((item,index) => {
            exportArray[index] = [];
            (keysToFilter).forEach(key => {
                if(item[key]) {
                    exportArray[index].push(item[key]);
                }

                if(!item[key]) {
                    exportArray[index].push('-');
                }
            })
        });

        exportArray.unshift(columns);
        isExcel ? this.appService.exportXLSX(exportArray) : this.appService.exportCSV(exportArray);
    }

    //@override
    onIdClick() {
        this.utilsService.clearErrorMessages();
        this.barboursTableCols[2].hidden = !this.barboursTableCols[2].hidden;
    }

    //@override
    onTitleClick() {
        this.utilsService.clearErrorMessages();
        this.barboursTableCols[3].hidden = !this.barboursTableCols[3].hidden;
    }

    //@override
    onTownClick() {
        this.utilsService.clearErrorMessages();
        this.barboursTableCols[4].hidden = !this.barboursTableCols[4].hidden;
    }

    //@override
    onCountryClick() {
        this.utilsService.clearErrorMessages();
        this.barboursTableCols[5].hidden = !this.barboursTableCols[5].hidden;
    }

    //@override
    onPlanningStageClick() {
        this.utilsService.clearErrorMessages();
        this.barboursTableCols[6].hidden = !this.barboursTableCols[6].hidden;
    }

    //@override
    onContractStageClick() {
        this.utilsService.clearErrorMessages();
        this.barboursTableCols[7].hidden = !this.barboursTableCols[7].hidden;
    }

    //@override
    onValueClick() {
        this.utilsService.clearErrorMessages();
        this.barboursTableCols[8].hidden = !this.barboursTableCols[8].hidden;
    }

    //@override
    onSectorClick() {
        this.utilsService.clearErrorMessages();
        this.barboursTableCols[9].hidden = !this.barboursTableCols[9].hidden;
    }

    //@override
    onCategoryClick() {
        this.utilsService.clearErrorMessages();
        this.barboursTableCols[10].hidden = !this.barboursTableCols[10].hidden;
    }

    //@override
    onUnitsClick() {
        this.utilsService.clearErrorMessages();
        this.barboursTableCols[11].hidden = !this.barboursTableCols[11].hidden;
    }

    //@override
    onStartDateClick() {
        this.utilsService.clearErrorMessages();
        this.barboursTableCols[12].hidden = !this.barboursTableCols[12].hidden;
    }

    //@override
    onConvertedClick() {
        this.utilsService.clearErrorMessages();
        this.barboursTableCols[13].hidden = !this.barboursTableCols[13].hidden;
    }

    selectAllTableRows() {
        this.utilsService.clearErrorMessages();

        this.projectsOrCompanies.forEach(item => item.IsChecked = !item.IsChecked);
    }

    //@override
    getBarboursTableCols(): any {
       return [
            {field: 'X', header: 'X', width: '5%', noSort: true, noFilter: true, hidden: false},
            {field: 'favourite', header: 'Favourite', width: '10%', noSort: true, noFilter: true, hidden: false},
            {field: 'id', header: 'ID', width: '8%', noSort: false, noFilter: false, hidden: true},
            {field: 'title', header: 'Project Title', width: '20%', noSort: false, noFilter: false, hidden: false},
            {field: 'address3', header: 'Town', width: '10%', noSort: false, noFilter: false, hidden: false},
            {field: 'address4', header: 'County', width: '12%', noSort: false, noFilter: false, hidden: false},
            {field: 'planningStage', header: 'Planning Stage', width: '12%', noSort: false, noFilter: false, hidden: false},
            {field: 'contractStage', header: 'Contract Stage', width: '12%', noSort: false, noFilter: false, hidden: false},
            {field: 'value', header: 'Value', width: '10%', noSort: false, noFilter: false, hidden: false},
            {field: 'primarySector', header: 'Sector', width: '10%', noSort: false, noFilter: false, hidden: false},
            {field: 'primaryCategory', header: 'Category', width: '15%', noSort: false, noFilter: false, hidden: false},
            {field: 'units', header: 'Units', width: '10%', noSort: false, noFilter: false, hidden: false},
            {field: 'start', sortField: 'sortStartDate', header: 'Start Date', width: '15%', noSort: false, noFilter: false, hidden: false},
            {field: 'converted', header: 'Converted', width: '15%', noSort: false, noFilter: false, hidden: false},

        ];
             }
      getCompaniesCols(): any[]{
        return [
          {field: 'X', header: 'X', width: '20px', noSort: true, noFilter: true, hidden: false},
          {field: 'favourite', header: 'Favourite', width: '40px', noSort: true, noFilter: true, hidden: false},
          {field: 'id', header: 'ID', width: '70px', noSort: false, noFilter: true, hidden: false},
          {field: 'name', header: 'Company Name', width: '120px', noSort: false, noFilter: true, hidden: false},
          {field: 'phone', header: 'Company Phone', width: '120px', noSort: false, noFilter: false, hidden: false},
          {field: 'website', header: 'Website', width: '120px', noSort: false, noFilter: false, hidden: false},
        ];
      }


    checkTableColumnsVisibilty() {
        this.barboursTableCols.forEach( (col,index) => {
            if(index === 0 || index === 1) {
                return;
            }

            if(!col.hidden) {
                 this.columnsBtnItems[index-2].styleClass = 'highlightBtn';
            }

            if(col.hidden) {
                this.columnsBtnItems[index-2].styleClass = '';
            }
        })
    }

    //@override
    ngDoCheck() {
        this.checkTableColumnsVisibilty();
        this.calculateEverything();
    }

    settingProjectColoumns(){
      return [
          {
              label: 'ID',
              command: () => {
                  this.onIdClick();
              }
                },
          {
              label: 'Title',
              command: () => {
                  this.onTitleClick();
              }
              },
          {
              label: 'Town',
              command: () => {this.onTownClick();
              }
              },
          {
              label: 'Country',
              command: () => {
                  this.onCountryClick();
              }
              },
          {
              label: 'Planning Stage',
              command: () => {
                  this.onPlanningStageClick();
              }
              },
          {
              label: 'Contract Stage',
              command: () => {
                  this.onContractStageClick();
              }
              },
          {
              label: 'Value',
              command: () => {
                  this.onValueClick();
              }
              },
          {
              label: 'Sector',
              command: () => {
                  this.onSectorClick();
              }
              },
          {
              label: 'Category',
              command: () => {
                  this.onCategoryClick();
              }
              },
          {
              label: 'Units',
              command: () => {
                  this.onUnitsClick();
              }
              },
          {
              label: 'Start Date',
              command: () => {
                  this.onStartDateClick();
              }
              },
          {
              label: 'Converted',
              command: () => {
                  this.onConvertedClick();
              }
          }
          ];
    }

    onNameClick() {
        this.utilsService.clearErrorMessages();
        this.barboursTableCols[3].hidden = !this.barboursTableCols[3].hidden;
    }

    onPhoneClick() {
        this.utilsService.clearErrorMessages();
        this.barboursTableCols[4].hidden = !this.barboursTableCols[4].hidden;
    }

    onWebsiteClick() {
        this.utilsService.clearErrorMessages();
        this.barboursTableCols[5].hidden = !this.barboursTableCols[5].hidden;
    }
}

/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */
