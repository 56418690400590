<!-- {PREOPEN:44eec7eb-1662-4bde-948a-5e7e8cbe19a2} {"sha1":"8C4A0E2E0EFD768CAB5850B359AF669A56A45AB4"} -->
<form #form="ngForm" [class.pointer-events-none]="this.showLoader">
  <!-- {OPEN:44eec7eb-1662-4bde-948a-5e7e8cbe19a2} {} -->
  <!-- {OPEN:Toolbar} {} -->
  <p-toolbar>
      <div class="ui-toolbar-group-left">
          {{ pageTitle }}
      </div>
      <div class="ui-toolbar-group-right actionBtn">
          <!-- {OPEN:9e1941de-e0b0-4d8a-ae81-c54363a54cb4} {"sha1":"09997320232FD9384C0D8F4FB17850C8F2EC7C0B"} -->
          <button pButton type="button" label="Close" icon="ui-icon-cancel"
              (click)="onCloseClick($event, searchProject)"></button>
          <!-- {CLOSE:9e1941de-e0b0-4d8a-ae81-c54363a54cb4} {"sha1":"09997320232FD9384C0D8F4FB17850C8F2EC7C0B"} -->
      </div>
      <div class="ui-toolbar-group-right actionBtn" *ngIf="this.rolesFetched">
          <!-- {OPEN:0fa43ddc-1558-402b-b136-d1cf94034b00} {"sha1":"603CF60FC527C7110F02BBD3C6AF10CB66ACBE28"} -->
          <button pButton type="button" label="Create Deal" icon="ui-icon-assignment-turned-in"
              (click)="onCreateDealClick($event, searchProject)"></button>
          <!-- {CLOSE:0fa43ddc-1558-402b-b136-d1cf94034b00} {"sha1":"603CF60FC527C7110F02BBD3C6AF10CB66ACBE28"} -->
      </div>
      <div class="ui-toolbar-group-right actionBtn" *ngIf="deals && deals.length > 0">
          <!-- {OPEN:e8a4c517-50d0-4eb3-a60d-b2918f8c6ca2} {"sha1":"CB7B36069ECAEDAC83658B84EAEF005B2A7FBB96"} -->
          <button pButton type="button" label="Update CRM" icon="ui-icon-update"
              (click)="onUpdateCrmClick($event, searchProject)"></button>
          <!-- {CLOSE:e8a4c517-50d0-4eb3-a60d-b2918f8c6ca2} {"sha1":"CB7B36069ECAEDAC83658B84EAEF005B2A7FBB96"} -->
      </div>
  </p-toolbar>
  <!-- {CLOSE:Toolbar} {} -->
  <!-- {OPEN:dc802792-25fb-45cf-a4ac-389b121ab6d3} {} -->
  <!-- {PREOPEN:2e6b1725-60e6-4f02-965b-e2a5ae0136f0} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
  <div class="ui-g form-group">
      <!-- {OPEN:2e6b1725-60e6-4f02-965b-e2a5ae0136f0} {} -->
      <!-- {PREOPEN:c92042c5-06da-4395-99f2-8778ec125298} {"sha1":"EBBFF12A35073AE4D8CC2445170CE6BA15B1F43B"} -->
      <div class="ui-g-12 ui-md-7">
          <!-- {OPEN:c92042c5-06da-4395-99f2-8778ec125298} {} -->
          <div [style.paddingTop.px]="'10'" [style.paddingLeft.px]="'0'" [style.paddingBottom.px]="'0'"
              [style.paddingRight.px]="'10'">
              <!-- {OPEN:a3cc92a6-3269-4151-b763-8935d0991293} {} -->
              <!-- {PREOPEN:4befd2a6-bccd-40cf-8cee-e75247c69d23} {"sha1":"3FC2A784D228EBE141639FC8310267F06032ABA6"} -->
              <div class="ui-g form-group">
                  <!-- {OPEN:4befd2a6-bccd-40cf-8cee-e75247c69d23} {} -->
                  <!-- {PREOPEN:68ac0f89-d40d-4d9a-a362-b10c5b259136} {"sha1":"8FAAA1324566FDB026F720E3698CCA2D4762E3F3"} -->
                  <div class="ui-g-12 ui-md-1">
                      <!-- {OPEN:68ac0f89-d40d-4d9a-a362-b10c5b259136} {} -->
                      <!-- {OPEN:2a1f74f3-b81d-4d01-9263-ea4cb42248d7} {"sha1":"1C1DC8ADBEDC6D8C61EC7413B7217CF26961F7D1"} -->
                      <div [style.text-align]="'right'" [style.paddingTop.px]="'10'" [style.paddingLeft.px]="'0'"
                          [style.paddingBottom.px]="'0'" [style.paddingRight.px]="'10'">
                          <i class="ui-icon-insert-chart" [style.font-size]="'1.5em'" [style.color]="'#000000'"></i>
                      </div>
                      <!-- {CLOSE:2a1f74f3-b81d-4d01-9263-ea4cb42248d7} {"sha1":"1C1DC8ADBEDC6D8C61EC7413B7217CF26961F7D1"} -->
                      <!-- {CLOSE:68ac0f89-d40d-4d9a-a362-b10c5b259136} {} -->
                  </div>
                  <!-- {POSTCLOSE:68ac0f89-d40d-4d9a-a362-b10c5b259136} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
                  <!-- {PREOPEN:718fb947-d2c1-4dde-a2ea-53dc75a06012} {"sha1":"52C012B16933145E631C1B06A677824CE132E1D2"} -->
                  <div class="ui-g-12 ui-md-9">
                      <!-- {OPEN:718fb947-d2c1-4dde-a2ea-53dc75a06012} {} -->
                      <!-- {OPEN:ad59fb19-0e35-41ad-b0b2-1016956c8fb2} {"sha1":"B313A1A040AF9F3F2CC8CA6709E8442AE6A9134F"} -->
                      <div class="word-break" [style.paddingTop.px]="'10'" [style.paddingLeft.px]="'0'"
                          [style.paddingBottom.px]="'0'" [style.paddingRight.px]="'10'">
                          <label [ngStyle]='{"font-weight":"bold","font-size":"22px"}'>{{project?.title}}</label>
                      </div>
                      <!-- {CLOSE:ad59fb19-0e35-41ad-b0b2-1016956c8fb2} {"sha1":"B313A1A040AF9F3F2CC8CA6709E8442AE6A9134F"} -->
                      <!-- {CLOSE:718fb947-d2c1-4dde-a2ea-53dc75a06012} {} -->
                  </div>
                  <!-- {POSTCLOSE:718fb947-d2c1-4dde-a2ea-53dc75a06012} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
                  <!-- {PREOPEN:3e57039e-df1d-4b93-82f4-3f164dfa4a4a} {"sha1":"46955B4A1AD32FE6B8A61EB8703D83AC48730BEF"} -->
                  <div class="ui-g-12 ui-md-2">
                      <!-- {OPEN:3e57039e-df1d-4b93-82f4-3f164dfa4a4a} {} -->
                      <!-- {OPEN:517c64bc-0847-4103-b2d2-ef482d66f71b} {"sha1":"2DCC5B228F4D96042FB776726DF3220B597B17C3"} -->
                      <div [style.paddingTop.px]="'10'" [style.paddingLeft.px]="'0'" [style.paddingBottom.px]="'0'"
                          [style.paddingRight.px]="'10'">
                          <button pButton type="button"
                              [icon]="!project?.favourite ? 'ui-icon-star-border' : 'ui-icon-star'"
                              (click)="onStarClick($event, searchProject)"></button>
                      </div>
                      <!-- {CLOSE:517c64bc-0847-4103-b2d2-ef482d66f71b} {"sha1":"2DCC5B228F4D96042FB776726DF3220B597B17C3"} -->
                      <!-- {CLOSE:3e57039e-df1d-4b93-82f4-3f164dfa4a4a} {} -->
                  </div>
                  <!-- {POSTCLOSE:3e57039e-df1d-4b93-82f4-3f164dfa4a4a} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
                  <!-- {CLOSE:4befd2a6-bccd-40cf-8cee-e75247c69d23} {} -->
              </div>
              <!-- {POSTCLOSE:4befd2a6-bccd-40cf-8cee-e75247c69d23} {"sha1":"C4A15ED98D4B138EEB595266FB1CF4C91CF45CA5"} -->
              <!-- {CLOSE:a3cc92a6-3269-4151-b763-8935d0991293} {} -->
          </div>
          <!-- {CLOSE:c92042c5-06da-4395-99f2-8778ec125298} {} -->
      </div>
      <!-- {POSTCLOSE:c92042c5-06da-4395-99f2-8778ec125298} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
      <!-- {PREOPEN:ae4e5edf-2b78-41ef-80d7-28dd52e43d2d} {"sha1":"1DDC99517E71CFAC56BE8D051495D03C2FFA597C"} -->
      <div class="ui-g-12 ui-md-5">
          <!-- {OPEN:ae4e5edf-2b78-41ef-80d7-28dd52e43d2d} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
          <!-- {CLOSE:ae4e5edf-2b78-41ef-80d7-28dd52e43d2d} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
      </div>
      <!-- {POSTCLOSE:ae4e5edf-2b78-41ef-80d7-28dd52e43d2d} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
      <!-- {CLOSE:2e6b1725-60e6-4f02-965b-e2a5ae0136f0} {} -->
  </div>
  <!-- {POSTCLOSE:2e6b1725-60e6-4f02-965b-e2a5ae0136f0} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
  <!-- {PREOPEN:2a74fb38-23e5-45fd-b945-69c2396a7dea} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
  <div class="ui-g form-group">
      <!-- {OPEN:2a74fb38-23e5-45fd-b945-69c2396a7dea} {} -->
      <!-- {PREOPEN:b0d754c6-2799-4ee7-9845-d652ed766af2} {"sha1":"EBBFF12A35073AE4D8CC2445170CE6BA15B1F43B"} -->
      <div class="ui-g-12 ui-md-7">
          <!-- {OPEN:b0d754c6-2799-4ee7-9845-d652ed766af2} {} -->
          <!-- {OPEN:d0b98fdd-599d-488f-be13-1eb66a188f3a} {"sha1":"26B4AEDCDF0114A6F702FD10D83CA44262396466"} -->
          <div [style.paddingTop.px]="'0'" [style.paddingLeft.px]="'34'" [style.paddingBottom.px]="'0'"
              [style.paddingRight.px]="'10'">
              <label
                  [ngStyle]='{"font-size":"18px","font-style":"italic"}'>{{appService.getModuleAddress(project)}}</label>
          </div>
          <!-- {CLOSE:d0b98fdd-599d-488f-be13-1eb66a188f3a} {"sha1":"26B4AEDCDF0114A6F702FD10D83CA44262396466"} -->
          <!-- {CLOSE:b0d754c6-2799-4ee7-9845-d652ed766af2} {} -->
      </div>
      <!-- {POSTCLOSE:b0d754c6-2799-4ee7-9845-d652ed766af2} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
      <!-- {PREOPEN:31959258-1ca8-4bbf-88ab-9e585cdcce1a} {"sha1":"1DDC99517E71CFAC56BE8D051495D03C2FFA597C"} -->
      <div class="ui-g-12 ui-md-5">
          <!-- {OPEN:31959258-1ca8-4bbf-88ab-9e585cdcce1a} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
          <!-- {CLOSE:31959258-1ca8-4bbf-88ab-9e585cdcce1a} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
      </div>
      <!-- {POSTCLOSE:31959258-1ca8-4bbf-88ab-9e585cdcce1a} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
      <!-- {CLOSE:2a74fb38-23e5-45fd-b945-69c2396a7dea} {} -->
  </div>
  <!-- {POSTCLOSE:2a74fb38-23e5-45fd-b945-69c2396a7dea} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
  <!-- {PREOPEN:60a49b58-02f4-4923-b9f0-89badc268dc9} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
  <div class="ui-g form-group">
      <!-- {OPEN:60a49b58-02f4-4923-b9f0-89badc268dc9} {} -->
      <!-- {PREOPEN:5074b01f-9202-4184-b18e-31b66abda1be} {"sha1":"EBBFF12A35073AE4D8CC2445170CE6BA15B1F43B"} -->
      <div class="ui-g-12 ui-md-7">
          <!-- {OPEN:5074b01f-9202-4184-b18e-31b66abda1be} {} -->
          <div [style.paddingTop.px]="'0'" [style.paddingLeft.px]="'21'" [style.paddingBottom.px]="'10'"
              [style.paddingRight.px]="'10'">
              <!-- {OPEN:6bb31b05-ec4b-4b17-bc25-3f0492328606} {} -->
              <!-- {PREOPEN:d5841423-a35b-4cde-902f-82dbf0d87b5b} {"sha1":"3FC2A784D228EBE141639FC8310267F06032ABA6"} -->
              <div class="ui-g form-group">
                  <!-- {OPEN:d5841423-a35b-4cde-902f-82dbf0d87b5b} {} -->
                  <!-- {PREOPEN:367f0bee-fb9c-41b1-8c74-ccabdca23f6e} {"sha1":"954B341197CDAA53F3513D0137B988210DBE4497"} -->
                  <div class="ui-g-12 ui-md-3">
                      <!-- {OPEN:367f0bee-fb9c-41b1-8c74-ccabdca23f6e} {} -->
                      <!-- {OPEN:9842ec73-51dd-41d8-88dc-23774e00a17e} {"sha1":"D26FA213525387037AB23B7634CD0250938A94A2"} -->
                      <span class="sticky display-grid">
                          <label style="color: grey">First Reported</label>
                          <span [ngStyle]='{}'>{{project?.firstPublished}}</span>
                      </span>
                      <!-- {CLOSE:9842ec73-51dd-41d8-88dc-23774e00a17e} {"sha1":"D26FA213525387037AB23B7634CD0250938A94A2"} -->
                      <!-- {CLOSE:367f0bee-fb9c-41b1-8c74-ccabdca23f6e} {} -->
                  </div>
                  <!-- {POSTCLOSE:367f0bee-fb9c-41b1-8c74-ccabdca23f6e} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
                  <!-- {PREOPEN:d0928fc8-67de-4dfb-a08c-e57a3e22daea} {"sha1":"954B341197CDAA53F3513D0137B988210DBE4497"} -->
                  <div class="ui-g-12 ui-md-3">
                      <!-- {OPEN:d0928fc8-67de-4dfb-a08c-e57a3e22daea} {} -->
                      <!-- {OPEN:f3584e56-7474-4569-819e-c7a3406188ba} {"sha1":"EC0F5268001041E1012AA236E0C8E7C7B6878F35"} -->
                      <span class="sticky display-grid">
                          <label style="color: grey">Value</label>
                          <span [ngStyle]='{}'>{{project?.value}}</span>
                      </span>
                      <!-- {CLOSE:f3584e56-7474-4569-819e-c7a3406188ba} {"sha1":"EC0F5268001041E1012AA236E0C8E7C7B6878F35"} -->
                      <!-- {CLOSE:d0928fc8-67de-4dfb-a08c-e57a3e22daea} {} -->
                  </div>
                  <!-- {POSTCLOSE:d0928fc8-67de-4dfb-a08c-e57a3e22daea} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
                  <!-- {PREOPEN:f93f6887-186b-42e8-ac13-20fe017f8cf3} {"sha1":"954B341197CDAA53F3513D0137B988210DBE4497"} -->
                  <div class="ui-g-12 ui-md-3">
                      <!-- {OPEN:f93f6887-186b-42e8-ac13-20fe017f8cf3} {} -->
                      <!-- {OPEN:afa6670e-4c3d-4c07-baf6-bb4cf03a9ea1} {"sha1":"F35309C1DF093DB18B5F491917D0FF6D1CADADA6"} -->
                      <span class="sticky display-grid">
                          <label style="color: grey">Project ID</label>
                          <span [ngStyle]='{}'>{{project?.id}}</span>
                      </span>
                      <!-- {CLOSE:afa6670e-4c3d-4c07-baf6-bb4cf03a9ea1} {"sha1":"F35309C1DF093DB18B5F491917D0FF6D1CADADA6"} -->
                      <!-- {CLOSE:f93f6887-186b-42e8-ac13-20fe017f8cf3} {} -->
                  </div>
                  <!-- {POSTCLOSE:f93f6887-186b-42e8-ac13-20fe017f8cf3} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
                  <!-- {PREOPEN:1308f162-a911-457b-a23d-b73d64e369cc} {"sha1":"954B341197CDAA53F3513D0137B988210DBE4497"} -->
                  <div class="ui-g-12 ui-md-3">
                      <!-- {OPEN:1308f162-a911-457b-a23d-b73d64e369cc} {} -->
                      <!-- {OPEN:427dcbd3-b1e1-4c01-836f-6ae91adf4de0} {"sha1":"EC7DEB59B0D899EDA8608D0E7211EB22F2A5DE4C"} -->
                      <span class="sticky display-grid">
                          <label style="color: grey">Last Updated</label>
                          <span [ngStyle]='{}'>{{project?.lastUpdated}}</span>
                      </span>
                      <!-- {CLOSE:427dcbd3-b1e1-4c01-836f-6ae91adf4de0} {"sha1":"EC7DEB59B0D899EDA8608D0E7211EB22F2A5DE4C"} -->
                      <!-- {CLOSE:1308f162-a911-457b-a23d-b73d64e369cc} {} -->
                  </div>
                  <div class="ui-g-12 ui-md-3">
                      <span class="sticky display-grid">
                          <label style="color: grey">Funding Type</label>
                          <span [ngStyle]='{}'>{{project?.funding}}</span>
                      </span>
                  </div>
                  <div class="ui-g-12 ui-md-3">
                      <span class="sticky display-grid">
                          <label style="color: grey">Contract Type</label>
                          <span [ngStyle]='{}'>{{project?.contractType}}</span>
                      </span>
                  </div>
              </div>
              <!-- {POSTCLOSE:d5841423-a35b-4cde-902f-82dbf0d87b5b} {"sha1":"C4A15ED98D4B138EEB595266FB1CF4C91CF45CA5"} -->
              <!-- {CLOSE:6bb31b05-ec4b-4b17-bc25-3f0492328606} {} -->
          </div>
          <!-- {CLOSE:5074b01f-9202-4184-b18e-31b66abda1be} {} -->
      </div>
      <!-- {POSTCLOSE:5074b01f-9202-4184-b18e-31b66abda1be} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
      <!-- {PREOPEN:aac71e61-6185-4988-a5be-ef8a03fa579b} {"sha1":"1DDC99517E71CFAC56BE8D051495D03C2FFA597C"} -->
      <div class="ui-g-12 ui-md-5">
          <!-- {OPEN:aac71e61-6185-4988-a5be-ef8a03fa579b} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
          <!-- {CLOSE:aac71e61-6185-4988-a5be-ef8a03fa579b} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
      </div>
      <!-- {POSTCLOSE:aac71e61-6185-4988-a5be-ef8a03fa579b} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
      <!-- {CLOSE:60a49b58-02f4-4923-b9f0-89badc268dc9} {} -->
  </div>
  <!-- {POSTCLOSE:60a49b58-02f4-4923-b9f0-89badc268dc9} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
  <!-- {CLOSE:dc802792-25fb-45cf-a4ac-389b121ab6d3} {} -->
  <!-- {PREOPEN:ebd7531e-0c95-4f48-943b-a5c9db5d391a} {"sha1":"9FC3CA20BE52A1365300762913A78DF1A87E45AD"} -->
  <p-tabView orientation="top" [(activeIndex)]="activeTabIndex" (onChange)="onTabChange($event, searchProject)">
      <!-- {OPEN:ebd7531e-0c95-4f48-943b-a5c9db5d391a} {} -->
      <!-- {PREOPEN:7a2634aa-5a58-43c0-bdb5-2773aa54042e} {"sha1":"2F4AD25E391142BA75B6407DB1672DEAE5BF17A7"} -->
      <p-tabPanel>
          <!-- {OPEN:7a2634aa-5a58-43c0-bdb5-2773aa54042e} {} -->
          <!-- {PREOPEN:7a2634aa-5a58-43c0-bdb5-2773aa54042e-HEADER} {"sha1":"EFFD8873F35AFEDE07E793ED170A75F21D8735DE"} -->
          <ng-template pTemplate="header">
              <!-- {OPEN:7a2634aa-5a58-43c0-bdb5-2773aa54042e-HEADER} {"sha1":"83CC2616D7A87690F59FCCC1C36FDD5415C812E0"} -->
              <span>Details</span>
              <!-- {CLOSE:7a2634aa-5a58-43c0-bdb5-2773aa54042e-HEADER} {"sha1":"83CC2616D7A87690F59FCCC1C36FDD5415C812E0"} -->
          </ng-template>
          <!-- {POSTCLOSE:7a2634aa-5a58-43c0-bdb5-2773aa54042e-HEADER} {"sha1":"08D2DC238814DBE0D3FB2F9729EBA749BB91A4A1"} -->
          <!-- {OPEN:f18af475-9619-47ff-8551-ee894660216a} {} -->
          <!-- {PREOPEN:99794609-1aa2-4e43-94bc-1340da86e4e7} {"sha1":"5DAE16E2D9FB1E24F06875FEC6B97E9D7BEFDB68"} -->
          <!-- {POSTCLOSE:99794609-1aa2-4e43-94bc-1340da86e4e7} {"sha1":"797F18B33CA2DAC7B5A389B76ABFD8D91F547465"} -->
          <!-- {PREOPEN:de985d69-e8e7-4435-acfc-9a2fe7ca5ff7} {"sha1":"5DAE16E2D9FB1E24F06875FEC6B97E9D7BEFDB68"} -->
          <div class="ui-g form-group">
              <!-- {OPEN:de985d69-e8e7-4435-acfc-9a2fe7ca5ff7} {} -->
              <!-- {PREOPEN:5ff50f09-ef2c-440f-9c3b-85b30958a02e} {"sha1":"6B3C70482CE451DD174A943ADB1ADDD064DE62FE"} -->
              <div class="ui-g-12 ui-md-6">
                  <!-- {OPEN:5ff50f09-ef2c-440f-9c3b-85b30958a02e} {} -->
                  <!-- {PREOPEN:1874768e-4ee0-4a9e-9be6-07561624a9c1} {"sha1":"B0B5C297CBDBE760B00950913061A656502F6E16"} -->
                  <p-table #projectStatue [columns]="projectStatusCol" [value]="[project]"
                      [selectionMode]="oneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableSelectionMode"
                      (onRowSelect)="onOneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableRowSelect($event.data)">
                      <ng-template pTemplate="colgroup" let-columns>
                          <colgroup>
                              <col *ngFor="let col of columns" [style.width]="col.width">
                          </colgroup>
                      </ng-template>
                      <ng-template pTemplate="header" let-columns>
                          <tr>
                              <th *ngFor="let col of columns" colspan="2"
                                  [pSortableColumn]="col.sortField || col.field"
                                  [pSortableColumnDisabled]="col.noSort">
                                  {{ col.header }}
                                  <p-sortIcon [field]="col.sortField || col.field" *ngIf="!col.noSort"></p-sortIcon>
                              </th>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                          <tr>
                              <td>
                                  <label>Project Status</label>
                              </td>
                              <td>
                                  <label>{{rowData?.status}}</label>
                              </td>
                          </tr>
                      </ng-template>
                  </p-table>
                  <p-table #projectDetails [columns]="projectDetailsCol" [value]="[project]"
                      [selectionMode]="oneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableSelectionMode"
                      (onRowSelect)="onOneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableRowSelect($event.data)">
                      <!-- {OPEN:1874768e-4ee0-4a9e-9be6-07561624a9c1} {} -->
                      <!-- {PREOPEN:COLGROUP-1874768e-4ee0-4a9e-9be6-07561624a9c1} {"sha1":"06B732CAB28A8FCC541143DDCCB93017E768A850"} -->
                      <ng-template pTemplate="colgroup" let-columns>
                          <!-- {OPEN:COLGROUP-1874768e-4ee0-4a9e-9be6-07561624a9c1} {"sha1":"2A80AF77883053D89E6690FD50F92C5E7C2A6F0D"} -->
                          <colgroup>
                              <col *ngFor="let col of columns" [style.width]="col.width">
                          </colgroup>
                          <!-- {CLOSE:COLGROUP-1874768e-4ee0-4a9e-9be6-07561624a9c1} {"sha1":"2A80AF77883053D89E6690FD50F92C5E7C2A6F0D"} -->
                      </ng-template>
                      <!-- {POSTCLOSE:COLGROUP-1874768e-4ee0-4a9e-9be6-07561624a9c1} {"sha1":"3CE1C4AC0BAB4AE25B3F909AC2F5139A541DC31F"} -->
                      <!-- {PREOPEN:HEADER-1874768e-4ee0-4a9e-9be6-07561624a9c1} {"sha1":"1C6D18E65390F7F9A2B58F9FA6E629C745E8AFC1"} -->
                      <ng-template pTemplate="header" let-columns>
                          <!-- {OPEN:HEADER-1874768e-4ee0-4a9e-9be6-07561624a9c1} {"sha1":"A0CAEAF707FB309F64C504F5512D9982CBAB9B44"} -->
                          <tr>
                              <th *ngFor="let col of columns" colspan="2"
                                  [pSortableColumn]="col.sortField || col.field"
                                  [pSortableColumnDisabled]="col.noSort">
                                  {{ col.header }}
                                  <p-sortIcon [field]="col.sortField || col.field" *ngIf="!col.noSort"></p-sortIcon>
                              </th>
                          </tr>
                          <!-- {CLOSE:HEADER-1874768e-4ee0-4a9e-9be6-07561624a9c1} {"sha1":"A0CAEAF707FB309F64C504F5512D9982CBAB9B44"} -->
                      </ng-template>
                      <!-- {POSTCLOSE:HEADER-1874768e-4ee0-4a9e-9be6-07561624a9c1} {"sha1":"3CE1C4AC0BAB4AE25B3F909AC2F5139A541DC31F"} -->
                      <!-- {PREOPEN:BODY-1874768e-4ee0-4a9e-9be6-07561624a9c1} {"sha1":"50EF52C055FDC4636414FC5785E6B6EE7C2AD9B4"} -->
                      <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                          <!-- {OPEN:BODY-1874768e-4ee0-4a9e-9be6-07561624a9c1} {} -->
                          <!-- {PREOPEN:b92bbb7a-e636-47d0-bd08-3f9270bfd6f1} {"sha1":"AEA6222C1C201321A898EE4DF44504612D43600B"} -->
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="oneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableSelectionMode ? false : true">
                              <!-- {OPEN:b92bbb7a-e636-47d0-bd08-3f9270bfd6f1} {} -->
                              <!-- {PREOPEN:add11b65-8099-4c47-866d-cb0e1d4a2a43} {"sha1":"51C40D34349347DCAA4D8D2B4FA1FB3CE7961300"} -->
                              <td>
                                  <!-- {OPEN:add11b65-8099-4c47-866d-cb0e1d4a2a43} {} -->
                                  <!-- {OPEN:aa6425bf-6eb3-418d-9a33-b14831fe0af1} {"sha1":"9997CEC78902B8723FE0A9424583C3AF59F44FA7"} -->
                                  <label>Project Details</label>
                                  <!-- {CLOSE:aa6425bf-6eb3-418d-9a33-b14831fe0af1} {"sha1":"9997CEC78902B8723FE0A9424583C3AF59F44FA7"} -->
                                  <!-- {CLOSE:add11b65-8099-4c47-866d-cb0e1d4a2a43} {} -->
                              </td>
                              <!-- {POSTCLOSE:add11b65-8099-4c47-866d-cb0e1d4a2a43} {"sha1":"7886CBA970276FB7D03005E2E88D2D0C8245C924"} -->
                              <!-- {PREOPEN:69de7cf2-73e1-4b73-b54f-8f390ef349a7} {"sha1":"51C40D34349347DCAA4D8D2B4FA1FB3CE7961300"} -->
                              <td>
                                  <!-- {OPEN:69de7cf2-73e1-4b73-b54f-8f390ef349a7} {} -->
                                  <!-- {OPEN:ddc50849-a6c6-424f-8dbf-19d7a1780d00} {"sha1":"A3B645B2AF5C4D21423D66FBD43E37DAF4B10C61"} -->
                                  <div ngClass="desc">{{rowData?.description}}</div>
                                  <!-- {CLOSE:ddc50849-a6c6-424f-8dbf-19d7a1780d00} {"sha1":"A3B645B2AF5C4D21423D66FBD43E37DAF4B10C61"} -->
                                  <!-- {CLOSE:69de7cf2-73e1-4b73-b54f-8f390ef349a7} {} -->
                              </td>
                              <!-- {POSTCLOSE:69de7cf2-73e1-4b73-b54f-8f390ef349a7} {"sha1":"7886CBA970276FB7D03005E2E88D2D0C8245C924"} -->
                              <!-- {CLOSE:b92bbb7a-e636-47d0-bd08-3f9270bfd6f1} {} -->

                          </tr>
                          <!-- {POSTCLOSE:b92bbb7a-e636-47d0-bd08-3f9270bfd6f1} {"sha1":"BEE298D391E7E07C7E409976D339C619CACE464E"} -->
                          <!-- {CLOSE:BODY-1874768e-4ee0-4a9e-9be6-07561624a9c1} {} -->
                      </ng-template>
                      <!-- {POSTCLOSE:BODY-1874768e-4ee0-4a9e-9be6-07561624a9c1} {"sha1":"3CE1C4AC0BAB4AE25B3F909AC2F5139A541DC31F"} -->
                      <!-- {CLOSE:1874768e-4ee0-4a9e-9be6-07561624a9c1} {} -->
                  </p-table>
                  <!-- {POSTCLOSE:1874768e-4ee0-4a9e-9be6-07561624a9c1} {"sha1":"0F574D6E91310BAFBB94CCA38469214C83C014E7"} -->
                  <!-- {PREOPEN:ac4cd7d5-bb6f-48eb-9d2c-205e64f62d88} {"sha1":"C08A17B653AE180E0E604694BAE1DD3C830562C8"} -->
                  <p-table #categoryTable [columns]="categoryTableCol" [value]="[project]"
                      [selectionMode]="detailsTableSelectionMode"
                      (onRowSelect)="onDetailsTableRowSelect($event.data)">
                      <!-- {OPEN:ac4cd7d5-bb6f-48eb-9d2c-205e64f62d88} {} -->
                      <!-- {PREOPEN:COLGROUP-ac4cd7d5-bb6f-48eb-9d2c-205e64f62d88} {"sha1":"06B732CAB28A8FCC541143DDCCB93017E768A850"} -->
                      <ng-template pTemplate="colgroup" let-columns>
                          <!-- {OPEN:COLGROUP-ac4cd7d5-bb6f-48eb-9d2c-205e64f62d88} {"sha1":"2A80AF77883053D89E6690FD50F92C5E7C2A6F0D"} -->
                          <colgroup>
                              <col *ngFor="let col of columns" [style.width]="col.width">
                          </colgroup>
                          <!-- {CLOSE:COLGROUP-ac4cd7d5-bb6f-48eb-9d2c-205e64f62d88} {"sha1":"2A80AF77883053D89E6690FD50F92C5E7C2A6F0D"} -->
                      </ng-template>
                      <!-- {POSTCLOSE:COLGROUP-ac4cd7d5-bb6f-48eb-9d2c-205e64f62d88} {"sha1":"3CE1C4AC0BAB4AE25B3F909AC2F5139A541DC31F"} -->
                      <!-- {PREOPEN:HEADER-ac4cd7d5-bb6f-48eb-9d2c-205e64f62d88} {"sha1":"1C6D18E65390F7F9A2B58F9FA6E629C745E8AFC1"} -->
                      <ng-template pTemplate="header" let-columns>
                          <!-- {OPEN:HEADER-ac4cd7d5-bb6f-48eb-9d2c-205e64f62d88} {"sha1":"A0CAEAF707FB309F64C504F5512D9982CBAB9B44"} -->
                          <tr>
                              <th *ngFor="let col of columns" colspan="2"
                                  [pSortableColumn]="col.sortField || col.field"
                                  [pSortableColumnDisabled]="col.noSort">
                                  {{ col.header }}
                                  <p-sortIcon [field]="col.sortField || col.field" *ngIf="!col.noSort"></p-sortIcon>
                              </th>
                          </tr>
                          <!-- {CLOSE:HEADER-ac4cd7d5-bb6f-48eb-9d2c-205e64f62d88} {"sha1":"A0CAEAF707FB309F64C504F5512D9982CBAB9B44"} -->
                      </ng-template>
                      <!-- {POSTCLOSE:HEADER-ac4cd7d5-bb6f-48eb-9d2c-205e64f62d88} {"sha1":"3CE1C4AC0BAB4AE25B3F909AC2F5139A541DC31F"} -->
                      <!-- {PREOPEN:BODY-ac4cd7d5-bb6f-48eb-9d2c-205e64f62d88} {"sha1":"50EF52C055FDC4636414FC5785E6B6EE7C2AD9B4"} -->
                      <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                          <!-- {OPEN:BODY-ac4cd7d5-bb6f-48eb-9d2c-205e64f62d88} {} -->
                          <!-- {PREOPEN:e92330c2-a34a-4dd1-84d1-d8412add93d4} {"sha1":"6A102E66A84DCCD63E4B5B30FB9715FE5023EE7E"} -->
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">
                              <!-- {OPEN:e92330c2-a34a-4dd1-84d1-d8412add93d4} {} -->
                              <!-- {PREOPEN:ad08287d-d972-472b-865a-af5a345d92a9} {"sha1":"51C40D34349347DCAA4D8D2B4FA1FB3CE7961300"} -->
                              <td>
                                  <!-- {OPEN:ad08287d-d972-472b-865a-af5a345d92a9} {} -->
                                  <!-- {OPEN:cf305cff-858f-406a-94cd-2c184c4b2116} {"sha1":"D8CB4148593E09E77B5E1EFFBF21E3C853D121B3"} -->
                                  <label>Primary Category</label>
                                  <!-- {CLOSE:cf305cff-858f-406a-94cd-2c184c4b2116} {"sha1":"D8CB4148593E09E77B5E1EFFBF21E3C853D121B3"} -->
                                  <!-- {CLOSE:ad08287d-d972-472b-865a-af5a345d92a9} {} -->
                              </td>
                              <!-- {POSTCLOSE:ad08287d-d972-472b-865a-af5a345d92a9} {"sha1":"7886CBA970276FB7D03005E2E88D2D0C8245C924"} -->
                              <!-- {PREOPEN:2b746fcf-5a9a-459d-a555-fe527b038f3f} {"sha1":"51C40D34349347DCAA4D8D2B4FA1FB3CE7961300"} -->
                              <td>
                                  <!-- {OPEN:2b746fcf-5a9a-459d-a555-fe527b038f3f} {} -->
                                  <!-- {OPEN:8da60904-dd54-4f96-ba78-b98719c16688} {"sha1":"8C283C79DD323F90B367365C34163E03BB482171"} -->
                                  <label>{{ rowData?.primaryCategory }}</label>
                                  <!-- {CLOSE:8da60904-dd54-4f96-ba78-b98719c16688} {"sha1":"8C283C79DD323F90B367365C34163E03BB482171"} -->
                                  <!-- {CLOSE:2b746fcf-5a9a-459d-a555-fe527b038f3f} {} -->
                              </td>
                              <!-- {POSTCLOSE:2b746fcf-5a9a-459d-a555-fe527b038f3f} {"sha1":"7886CBA970276FB7D03005E2E88D2D0C8245C924"} -->
                              <!-- {CLOSE:e92330c2-a34a-4dd1-84d1-d8412add93d4} {} -->
                          </tr>
                          <!-- {POSTCLOSE:e92330c2-a34a-4dd1-84d1-d8412add93d4} {"sha1":"BEE298D391E7E07C7E409976D339C619CACE464E"} -->
                          <!-- {CLOSE:BODY-ac4cd7d5-bb6f-48eb-9d2c-205e64f62d88} {} -->
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">
                              <td>
                                  <label>Secondary Category</label>
                              </td>
                              <td>
                                  <label>{{ rowData?.categories }}</label>
                              </td>
                          </tr>
                      </ng-template>
                      <!-- {POSTCLOSE:BODY-ac4cd7d5-bb6f-48eb-9d2c-205e64f62d88} {"sha1":"3CE1C4AC0BAB4AE25B3F909AC2F5139A541DC31F"} -->
                      <!-- {CLOSE:ac4cd7d5-bb6f-48eb-9d2c-205e64f62d88} {} -->
                  </p-table>
                  <!-- {POSTCLOSE:ac4cd7d5-bb6f-48eb-9d2c-205e64f62d88} {"sha1":"0F574D6E91310BAFBB94CCA38469214C83C014E7"} -->
                  <!-- {PREOPEN:e3ecc0b3-34ed-4556-8bde-a638b1334b2e} {"sha1":"C08A17B653AE180E0E604694BAE1DD3C830562C8"} -->
                  <p-table #sectorTable [columns]="sectorTableCol" [value]="[project]"
                      [selectionMode]="detailsTableSelectionMode"
                      (onRowSelect)="onDetailsTableRowSelect($event.data)">
                      <!-- {OPEN:e3ecc0b3-34ed-4556-8bde-a638b1334b2e} {} -->
                      <!-- {PREOPEN:COLGROUP-e3ecc0b3-34ed-4556-8bde-a638b1334b2e} {"sha1":"06B732CAB28A8FCC541143DDCCB93017E768A850"} -->
                      <ng-template pTemplate="colgroup" let-columns>
                          <!-- {OPEN:COLGROUP-e3ecc0b3-34ed-4556-8bde-a638b1334b2e} {"sha1":"2A80AF77883053D89E6690FD50F92C5E7C2A6F0D"} -->
                          <colgroup>
                              <col *ngFor="let col of columns" [style.width]="col.width">
                          </colgroup>
                          <!-- {CLOSE:COLGROUP-e3ecc0b3-34ed-4556-8bde-a638b1334b2e} {"sha1":"2A80AF77883053D89E6690FD50F92C5E7C2A6F0D"} -->
                      </ng-template>
                      <!-- {POSTCLOSE:COLGROUP-e3ecc0b3-34ed-4556-8bde-a638b1334b2e} {"sha1":"3CE1C4AC0BAB4AE25B3F909AC2F5139A541DC31F"} -->
                      <!-- {PREOPEN:HEADER-e3ecc0b3-34ed-4556-8bde-a638b1334b2e} {"sha1":"1C6D18E65390F7F9A2B58F9FA6E629C745E8AFC1"} -->
                      <ng-template pTemplate="header" let-columns>
                          <!-- {OPEN:HEADER-e3ecc0b3-34ed-4556-8bde-a638b1334b2e} {"sha1":"A0CAEAF707FB309F64C504F5512D9982CBAB9B44"} -->
                          <tr>
                              <th *ngFor="let col of columns" colspan="2"
                                  [pSortableColumn]="col.sortField || col.field"
                                  [pSortableColumnDisabled]="col.noSort">
                                  {{ col.header }}
                                  <p-sortIcon [field]="col.sortField || col.field" *ngIf="!col.noSort"></p-sortIcon>
                              </th>
                          </tr>
                          <!-- {CLOSE:HEADER-e3ecc0b3-34ed-4556-8bde-a638b1334b2e} {"sha1":"A0CAEAF707FB309F64C504F5512D9982CBAB9B44"} -->
                      </ng-template>
                      <!-- {POSTCLOSE:HEADER-e3ecc0b3-34ed-4556-8bde-a638b1334b2e} {"sha1":"3CE1C4AC0BAB4AE25B3F909AC2F5139A541DC31F"} -->
                      <!-- {PREOPEN:BODY-e3ecc0b3-34ed-4556-8bde-a638b1334b2e} {"sha1":"50EF52C055FDC4636414FC5785E6B6EE7C2AD9B4"} -->
                      <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                          <!-- {OPEN:BODY-e3ecc0b3-34ed-4556-8bde-a638b1334b2e} {} -->
                          <!-- {PREOPEN:acb865af-fb0b-4ab8-b7c5-113f93233d6f} {"sha1":"6A102E66A84DCCD63E4B5B30FB9715FE5023EE7E"} -->
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">
                              <!-- {OPEN:acb865af-fb0b-4ab8-b7c5-113f93233d6f} {} -->
                              <!-- {PREOPEN:2d02bb5c-d984-496d-89eb-8062fea37673} {"sha1":"51C40D34349347DCAA4D8D2B4FA1FB3CE7961300"} -->
                              <td colspan="2">
                                  <!-- {OPEN:2d02bb5c-d984-496d-89eb-8062fea37673} {} -->
                                  <!-- {OPEN:3832a98d-060c-4daa-ac27-3e6e905990c1} {"sha1":"3D4EDAB88A99559C265425803528BB197D5ED4C4"} -->
                                  <label>{{ rowData?.primarySector }}</label>
                                  <!-- {CLOSE:3832a98d-060c-4daa-ac27-3e6e905990c1} {"sha1":"3D4EDAB88A99559C265425803528BB197D5ED4C4"} -->
                                  <!-- {CLOSE:2d02bb5c-d984-496d-89eb-8062fea37673} {} -->
                              </td>
                              <!-- {POSTCLOSE:2d02bb5c-d984-496d-89eb-8062fea37673} {"sha1":"7886CBA970276FB7D03005E2E88D2D0C8245C924"} -->
                              <!-- {PREOPEN:f3b47859-708a-4595-a26f-3226d37758c5} {"sha1":"51C40D34349347DCAA4D8D2B4FA1FB3CE7961300"} -->
                              <!--                                <td>-->
                              <!-- {OPEN:f3b47859-708a-4595-a26f-3226d37758c5} {"sha1":"F75D3BC9DC4C226780E8B7623C51798D6CBB924A"} -->
                              <!-- {CLOSE:f3b47859-708a-4595-a26f-3226d37758c5} {"sha1":"F75D3BC9DC4C226780E8B7623C51798D6CBB924A"} -->
                              <!--                                </td>-->
                              <!-- {POSTCLOSE:f3b47859-708a-4595-a26f-3226d37758c5} {"sha1":"7886CBA970276FB7D03005E2E88D2D0C8245C924"} -->
                              <!-- {CLOSE:acb865af-fb0b-4ab8-b7c5-113f93233d6f} {} -->
                          </tr>
                          <!-- {POSTCLOSE:acb865af-fb0b-4ab8-b7c5-113f93233d6f} {"sha1":"BEE298D391E7E07C7E409976D339C619CACE464E"} -->
                          <!-- {CLOSE:BODY-e3ecc0b3-34ed-4556-8bde-a638b1334b2e} {} -->
                      </ng-template>
                      <!-- {POSTCLOSE:BODY-e3ecc0b3-34ed-4556-8bde-a638b1334b2e} {"sha1":"3CE1C4AC0BAB4AE25B3F909AC2F5139A541DC31F"} -->
                      <!-- {CLOSE:e3ecc0b3-34ed-4556-8bde-a638b1334b2e} {} -->
                  </p-table>
                  <!-- {POSTCLOSE:e3ecc0b3-34ed-4556-8bde-a638b1334b2e} {"sha1":"0F574D6E91310BAFBB94CCA38469214C83C014E7"} -->
                  <!-- {PREOPEN:ea96b5eb-3aa4-4e49-b3cd-5dd72898baae} {"sha1":"C08A17B653AE180E0E604694BAE1DD3C830562C8"} -->
                  <p-table #stagesTable [columns]="stagesTableCol" [value]="[project]"
                      [selectionMode]="detailsTableSelectionMode"
                      (onRowSelect)="onDetailsTableRowSelect($event.data)">
                      <!-- {OPEN:ea96b5eb-3aa4-4e49-b3cd-5dd72898baae} {} -->
                      <!-- {PREOPEN:COLGROUP-ea96b5eb-3aa4-4e49-b3cd-5dd72898baae} {"sha1":"06B732CAB28A8FCC541143DDCCB93017E768A850"} -->
                      <ng-template pTemplate="colgroup" let-columns>
                          <!-- {OPEN:COLGROUP-ea96b5eb-3aa4-4e49-b3cd-5dd72898baae} {"sha1":"2A80AF77883053D89E6690FD50F92C5E7C2A6F0D"} -->
                          <colgroup>
                              <col *ngFor="let col of columns" [style.width]="col.width">
                          </colgroup>
                          <!-- {CLOSE:COLGROUP-ea96b5eb-3aa4-4e49-b3cd-5dd72898baae} {"sha1":"2A80AF77883053D89E6690FD50F92C5E7C2A6F0D"} -->
                      </ng-template>
                      <!-- {POSTCLOSE:COLGROUP-ea96b5eb-3aa4-4e49-b3cd-5dd72898baae} {"sha1":"3CE1C4AC0BAB4AE25B3F909AC2F5139A541DC31F"} -->
                      <!-- {PREOPEN:HEADER-ea96b5eb-3aa4-4e49-b3cd-5dd72898baae} {"sha1":"1C6D18E65390F7F9A2B58F9FA6E629C745E8AFC1"} -->
                      <ng-template pTemplate="header" let-columns>
                          <!-- {OPEN:HEADER-ea96b5eb-3aa4-4e49-b3cd-5dd72898baae} {"sha1":"A0CAEAF707FB309F64C504F5512D9982CBAB9B44"} -->
                          <tr>
                              <th colspan="2" *ngFor="let col of columns"
                                  [pSortableColumn]="col.sortField || col.field"
                                  [pSortableColumnDisabled]="col.noSort">
                                  {{ col.header }}
                                  <p-sortIcon [field]="col.sortField || col.field" *ngIf="!col.noSort"></p-sortIcon>
                              </th>
                          </tr>
                          <!-- {CLOSE:HEADER-ea96b5eb-3aa4-4e49-b3cd-5dd72898baae} {"sha1":"A0CAEAF707FB309F64C504F5512D9982CBAB9B44"} -->
                      </ng-template>
                      <!-- {POSTCLOSE:HEADER-ea96b5eb-3aa4-4e49-b3cd-5dd72898baae} {"sha1":"3CE1C4AC0BAB4AE25B3F909AC2F5139A541DC31F"} -->
                      <!-- {PREOPEN:BODY-ea96b5eb-3aa4-4e49-b3cd-5dd72898baae} {"sha1":"50EF52C055FDC4636414FC5785E6B6EE7C2AD9B4"} -->
                      <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                          <!-- {OPEN:BODY-ea96b5eb-3aa4-4e49-b3cd-5dd72898baae} {} -->
                          <!-- {PREOPEN:f6954571-6472-4e17-a60d-d30d3fe24787} {"sha1":"6A102E66A84DCCD63E4B5B30FB9715FE5023EE7E"} -->
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">
                              <!-- {OPEN:f6954571-6472-4e17-a60d-d30d3fe24787} {} -->
                              <!-- {PREOPEN:b735e05e-6bd0-4920-a4c3-7574948719a5} {"sha1":"51C40D34349347DCAA4D8D2B4FA1FB3CE7961300"} -->
                              <td>
                                  <!-- {OPEN:b735e05e-6bd0-4920-a4c3-7574948719a5} {} -->
                                  <!-- {OPEN:0c9c3455-7d42-4f5c-8fba-14d8faefed28} {"sha1":"5CD90967A83B0AE230C0D8F91965B110BAA225B2"} -->
                                  <label>Planning Stage</label>
                                  <!-- {CLOSE:0c9c3455-7d42-4f5c-8fba-14d8faefed28} {"sha1":"5CD90967A83B0AE230C0D8F91965B110BAA225B2"} -->
                                  <!-- {CLOSE:b735e05e-6bd0-4920-a4c3-7574948719a5} {} -->
                              </td>
                              <!-- {POSTCLOSE:b735e05e-6bd0-4920-a4c3-7574948719a5} {"sha1":"7886CBA970276FB7D03005E2E88D2D0C8245C924"} -->
                              <!-- {PREOPEN:b69c4992-f6db-41d9-967f-1628f8b0dbd1} {"sha1":"51C40D34349347DCAA4D8D2B4FA1FB3CE7961300"} -->
                              <td>
                                  <!-- {OPEN:b69c4992-f6db-41d9-967f-1628f8b0dbd1} {} -->
                                  <!-- {OPEN:0f8bddfc-051d-4296-80e7-82e113f3a80c} {"sha1":"D2EF1F425F9A35DABDF6C1FD138E0D1C4006B375"} -->
                                  <label>{{ rowData?.planningStage }}</label>
                                  <!-- {CLOSE:0f8bddfc-051d-4296-80e7-82e113f3a80c} {"sha1":"D2EF1F425F9A35DABDF6C1FD138E0D1C4006B375"} -->
                                  <!-- {CLOSE:b69c4992-f6db-41d9-967f-1628f8b0dbd1} {} -->
                              </td>

                              <!-- {POSTCLOSE:b69c4992-f6db-41d9-967f-1628f8b0dbd1} {"sha1":"7886CBA970276FB7D03005E2E88D2D0C8245C924"} -->
                              <!-- {CLOSE:f6954571-6472-4e17-a60d-d30d3fe24787} {} -->
                          </tr>
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">
                              <!-- {OPEN:f6954571-6472-4e17-a60d-d30d3fe24787} {} -->
                              <!-- {PREOPEN:b735e05e-6bd0-4920-a4c3-7574948719a5} {"sha1":"51C40D34349347DCAA4D8D2B4FA1FB3CE7961300"} -->
                              <td>
                                  <!-- {OPEN:b735e05e-6bd0-4920-a4c3-7574948719a5} {} -->
                                  <!-- {OPEN:0c9c3455-7d42-4f5c-8fba-14d8faefed28} {"sha1":"5CD90967A83B0AE230C0D8F91965B110BAA225B2"} -->
                                  <label>Contract Stage</label>
                                  <!-- {CLOSE:0c9c3455-7d42-4f5c-8fba-14d8faefed28} {"sha1":"5CD90967A83B0AE230C0D8F91965B110BAA225B2"} -->
                                  <!-- {CLOSE:b735e05e-6bd0-4920-a4c3-7574948719a5} {} -->
                              </td>
                              <!-- {POSTCLOSE:b735e05e-6bd0-4920-a4c3-7574948719a5} {"sha1":"7886CBA970276FB7D03005E2E88D2D0C8245C924"} -->
                              <!-- {PREOPEN:b69c4992-f6db-41d9-967f-1628f8b0dbd1} {"sha1":"51C40D34349347DCAA4D8D2B4FA1FB3CE7961300"} -->
                              <td>
                                  <!-- {OPEN:b69c4992-f6db-41d9-967f-1628f8b0dbd1} {} -->
                                  <!-- {OPEN:0f8bddfc-051d-4296-80e7-82e113f3a80c} {"sha1":"D2EF1F425F9A35DABDF6C1FD138E0D1C4006B375"} -->
                                  <label>{{ rowData?.contractStage }}</label>
                                  <!-- {CLOSE:0f8bddfc-051d-4296-80e7-82e113f3a80c} {"sha1":"D2EF1F425F9A35DABDF6C1FD138E0D1C4006B375"} -->
                                  <!-- {CLOSE:b69c4992-f6db-41d9-967f-1628f8b0dbd1} {} -->
                              </td>

                              <!-- {POSTCLOSE:b69c4992-f6db-41d9-967f-1628f8b0dbd1} {"sha1":"7886CBA970276FB7D03005E2E88D2D0C8245C924"} -->
                              <!-- {CLOSE:f6954571-6472-4e17-a60d-d30d3fe24787} {} -->
                          </tr>
                          <!-- {POSTCLOSE:f6954571-6472-4e17-a60d-d30d3fe24787} {"sha1":"BEE298D391E7E07C7E409976D339C619CACE464E"} -->
                          <!-- {CLOSE:BODY-ea96b5eb-3aa4-4e49-b3cd-5dd72898baae} {} -->
                      </ng-template>
                      <!-- {POSTCLOSE:BODY-ea96b5eb-3aa4-4e49-b3cd-5dd72898baae} {"sha1":"3CE1C4AC0BAB4AE25B3F909AC2F5139A541DC31F"} -->
                      <!-- {CLOSE:ea96b5eb-3aa4-4e49-b3cd-5dd72898baae} {} -->
                  </p-table>
                  <!-- {POSTCLOSE:ea96b5eb-3aa4-4e49-b3cd-5dd72898baae} {"sha1":"0F574D6E91310BAFBB94CCA38469214C83C014E7"} -->
                  <!-- {CLOSE:5ff50f09-ef2c-440f-9c3b-85b30958a02e} {} -->
              </div>
              <!-- {POSTCLOSE:5ff50f09-ef2c-440f-9c3b-85b30958a02e} {"sha1":"C4A15ED98D4B138EEB595266FB1CF4C91CF45CA5"} -->
              <!-- {PREOPEN:cc0555c3-73fd-43b9-b9f1-bac7fca6ec4e} {"sha1":"3667EDCEE4A1EEFBEB428C1F8C7DD175186DC0E7"} -->
              <div class="ui-g-12 ui-md-1">
                  <!-- {OPEN:cc0555c3-73fd-43b9-b9f1-bac7fca6ec4e} {"sha1":"615A083DFF9260B4218E051E4214096E69490AC5"} -->
                  <!-- {CLOSE:cc0555c3-73fd-43b9-b9f1-bac7fca6ec4e} {"sha1":"615A083DFF9260B4218E051E4214096E69490AC5"} -->
              </div>
              <!-- {POSTCLOSE:cc0555c3-73fd-43b9-b9f1-bac7fca6ec4e} {"sha1":"C4A15ED98D4B138EEB595266FB1CF4C91CF45CA5"} -->
              <!-- {PREOPEN:6db5bfeb-1f4f-4c83-8e39-dbacfcca7ad2} {"sha1":"BEEE88D44FEB478451FAD034A59C257FED00A955"} -->
              <div class="ui-g-12 ui-md-5">

                  <!-- {OPEN:6db5bfeb-1f4f-4c83-8e39-dbacfcca7ad2} {} -->
                  <!-- {PREOPEN:3d0936b4-7b29-4746-81af-6ad925ca30f3} {"sha1":"C08A17B653AE180E0E604694BAE1DD3C830562C8"} -->
                  <p-table #sizeTable [columns]="sizeTableCol" [value]="[project]"
                      [selectionMode]="detailsTableSelectionMode"
                      (onRowSelect)="onDetailsTableRowSelect($event.data)">
                      <!-- {OPEN:3d0936b4-7b29-4746-81af-6ad925ca30f3} {} -->
                      <!-- {PREOPEN:COLGROUP-3d0936b4-7b29-4746-81af-6ad925ca30f3} {"sha1":"06B732CAB28A8FCC541143DDCCB93017E768A850"} -->
                      <ng-template pTemplate="colgroup" let-columns>
                          <!-- {OPEN:COLGROUP-3d0936b4-7b29-4746-81af-6ad925ca30f3} {"sha1":"2A80AF77883053D89E6690FD50F92C5E7C2A6F0D"} -->
                          <colgroup>
                              <col *ngFor="let col of columns" [style.width]="col.width">
                          </colgroup>
                          <!-- {CLOSE:COLGROUP-3d0936b4-7b29-4746-81af-6ad925ca30f3} {"sha1":"2A80AF77883053D89E6690FD50F92C5E7C2A6F0D"} -->
                      </ng-template>
                      <!-- {POSTCLOSE:COLGROUP-3d0936b4-7b29-4746-81af-6ad925ca30f3} {"sha1":"3CE1C4AC0BAB4AE25B3F909AC2F5139A541DC31F"} -->
                      <!-- {PREOPEN:HEADER-3d0936b4-7b29-4746-81af-6ad925ca30f3} {"sha1":"1C6D18E65390F7F9A2B58F9FA6E629C745E8AFC1"} -->
                      <ng-template pTemplate="header" let-columns>
                          <!-- {OPEN:HEADER-3d0936b4-7b29-4746-81af-6ad925ca30f3} {"sha1":"A0CAEAF707FB309F64C504F5512D9982CBAB9B44"} -->
                          <tr>
                              <th colspan="2" *ngFor="let col of columns"
                                  [pSortableColumn]="col.sortField || col.field"
                                  [pSortableColumnDisabled]="col.noSort">
                                  {{ col.header }}
                                  <p-sortIcon [field]="col.sortField || col.field" *ngIf="!col.noSort"></p-sortIcon>
                              </th>
                          </tr>
                          <!-- {CLOSE:HEADER-3d0936b4-7b29-4746-81af-6ad925ca30f3} {"sha1":"A0CAEAF707FB309F64C504F5512D9982CBAB9B44"} -->
                      </ng-template>
                      <!-- {POSTCLOSE:HEADER-3d0936b4-7b29-4746-81af-6ad925ca30f3} {"sha1":"3CE1C4AC0BAB4AE25B3F909AC2F5139A541DC31F"} -->
                      <!-- {PREOPEN:BODY-3d0936b4-7b29-4746-81af-6ad925ca30f3} {"sha1":"50EF52C055FDC4636414FC5785E6B6EE7C2AD9B4"} -->
                      <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                          <!-- {OPEN:BODY-3d0936b4-7b29-4746-81af-6ad925ca30f3} {} -->
                          <!-- {PREOPEN:7e84e772-f7e4-4e48-b5cb-4410c3dff809} {"sha1":"6A102E66A84DCCD63E4B5B30FB9715FE5023EE7E"} -->
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">
                              <!-- {OPEN:7e84e772-f7e4-4e48-b5cb-4410c3dff809} {} -->
                              <!-- {PREOPEN:c721ab7b-12e7-4fce-ac5f-8ed2dc74ce0c} {"sha1":"51C40D34349347DCAA4D8D2B4FA1FB3CE7961300"} -->
                              <td>
                                  <!-- {OPEN:c721ab7b-12e7-4fce-ac5f-8ed2dc74ce0c} {} -->
                                  <!-- {OPEN:0c468cf6-37a5-4a6b-850f-6daa450a9106} {"sha1":"0D41051357D376CF48670F8C478B61F638437426"} -->
                                  <label>Units</label>
                                  <!-- {CLOSE:0c468cf6-37a5-4a6b-850f-6daa450a9106} {"sha1":"0D41051357D376CF48670F8C478B61F638437426"} -->
                                  <!-- {CLOSE:c721ab7b-12e7-4fce-ac5f-8ed2dc74ce0c} {} -->
                              </td>
                              <!-- {POSTCLOSE:c721ab7b-12e7-4fce-ac5f-8ed2dc74ce0c} {"sha1":"7886CBA970276FB7D03005E2E88D2D0C8245C924"} -->
                              <!-- {PREOPEN:e2f6b7bf-a05b-4617-97ad-ade36c3cd69a} {"sha1":"51C40D34349347DCAA4D8D2B4FA1FB3CE7961300"} -->
                              <td>
                                  <!-- {OPEN:e2f6b7bf-a05b-4617-97ad-ade36c3cd69a} {} -->
                                  <!-- {OPEN:c4dd4d6a-b8fb-47ff-bca0-494bbf7c6c9b} {"sha1":"6990507FFAB9A663F1CB0D23C93FD1F3FF95E05E"} -->
                                  <label>{{ rowData?.units }}</label>
                                  <!-- {CLOSE:c4dd4d6a-b8fb-47ff-bca0-494bbf7c6c9b} {"sha1":"6990507FFAB9A663F1CB0D23C93FD1F3FF95E05E"} -->
                                  <!-- {CLOSE:e2f6b7bf-a05b-4617-97ad-ade36c3cd69a} {} -->
                              </td>
                              <!-- {POSTCLOSE:e2f6b7bf-a05b-4617-97ad-ade36c3cd69a} {"sha1":"7886CBA970276FB7D03005E2E88D2D0C8245C924"} -->
                              <!-- {CLOSE:7e84e772-f7e4-4e48-b5cb-4410c3dff809} {} -->
                          </tr>
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">

                              <td>
                                  <label>Area</label>
                              </td>
                              <td>
                                  <label>{{rowData?.area}}</label>
                              </td>
                          </tr>

                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">

                              <td>
                                  <label>Plan Area</label>
                              </td>
                              <td>
                                  <label>{{rowData?.planArea}}</label>
                              </td>
                          </tr>
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">

                              <td>
                                  <label>Volume</label>
                              </td>
                              <td>
                                  <label>{{rowData?.volume}}</label>
                              </td>
                          </tr>
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">

                              <td>
                                  <label>Length</label>
                              </td>
                              <td>
                                  <label>{{rowData?.length}}</label>
                              </td>
                          </tr>
                          <!-- {POSTCLOSE:7e84e772-f7e4-4e48-b5cb-4410c3dff809} {"sha1":"BEE298D391E7E07C7E409976D339C619CACE464E"} -->
                          <!-- {CLOSE:BODY-3d0936b4-7b29-4746-81af-6ad925ca30f3} {} -->
                      </ng-template>
                      <!-- {POSTCLOSE:BODY-3d0936b4-7b29-4746-81af-6ad925ca30f3} {"sha1":"3CE1C4AC0BAB4AE25B3F909AC2F5139A541DC31F"} -->
                      <!-- {CLOSE:3d0936b4-7b29-4746-81af-6ad925ca30f3} {} -->
                  </p-table>
                  <!-- {POSTCLOSE:3d0936b4-7b29-4746-81af-6ad925ca30f3} {"sha1":"0F574D6E91310BAFBB94CCA38469214C83C014E7"} -->
                  <!-- {PREOPEN:a86c5de9-981a-4b67-978c-1ec87621319e} {"sha1":"C08A17B653AE180E0E604694BAE1DD3C830562C8"} -->
                  <p-table #planningInfo [columns]="planinfoTableCol" [value]="[project]"
                      [selectionMode]="detailsTableSelectionMode"
                      (onRowSelect)="onDetailsTableRowSelect($event.data)">
                      <ng-template pTemplate="colgroup" let-columns>
                          <colgroup>
                              <col *ngFor="let col of columns" [style.width]="col.width">
                          </colgroup>
                      </ng-template>
                      <ng-template pTemplate="header" let-columns>
                          <tr>
                              <th colspan="2" *ngFor="let col of columns"
                                  [pSortableColumn]="col.sortField || col.field"
                                  [pSortableColumnDisabled]="col.noSort">
                                  {{ col.header }}
                                  <p-sortIcon [field]="col.sortField || col.field" *ngIf="!col.noSort"></p-sortIcon>
                              </th>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">
                              <td>
                                  <label>Council</label>
                              </td>
                              <td>
                                  <label>{{ rowData?.council}}</label>
                              </td>
                          </tr>
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">
                              <td>
                                  <label>Planning Reference:</label>
                              </td>
                              <td>
                                  <label>{{ rowData?.planningReference}}</label>
                              </td>
                          </tr>
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">
                              <td>
                                  <label>Plans Submitted:</label>
                              </td>
                              <td>
                                  <label>{{ rowData?.plansSubmitted}}</label>
                              </td>
                          </tr>
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">

                              <td>
                                  <label>Site Area</label>
                              </td>
                              <td>
                                  <label>{{rowData?.siteArea}}</label>
                              </td>
                          </tr>
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">

                              <td>
                                  <label>Planning URL</label>
                              </td>
                              <td>
                                  <label>{{rowData?.projectPlanningURL}}</label>
                              </td>
                          </tr>

                      </ng-template>

                  </p-table>

                  <p-table #timingTable [columns]="timingTableCol" [value]="[project]"
                      [selectionMode]="detailsTableSelectionMode"
                      (onRowSelect)="onDetailsTableRowSelect($event.data)">
                      <!-- {OPEN:a86c5de9-981a-4b67-978c-1ec87621319e} {} -->
                      <!-- {PREOPEN:COLGROUP-a86c5de9-981a-4b67-978c-1ec87621319e} {"sha1":"06B732CAB28A8FCC541143DDCCB93017E768A850"} -->
                      <ng-template pTemplate="colgroup" let-columns>
                          <!-- {OPEN:COLGROUP-a86c5de9-981a-4b67-978c-1ec87621319e} {"sha1":"2A80AF77883053D89E6690FD50F92C5E7C2A6F0D"} -->
                          <colgroup>
                              <col *ngFor="let col of columns" [style.width]="col.width">
                          </colgroup>
                          <!-- {CLOSE:COLGROUP-a86c5de9-981a-4b67-978c-1ec87621319e} {"sha1":"2A80AF77883053D89E6690FD50F92C5E7C2A6F0D"} -->
                      </ng-template>
                      <!-- {POSTCLOSE:COLGROUP-a86c5de9-981a-4b67-978c-1ec87621319e} {"sha1":"3CE1C4AC0BAB4AE25B3F909AC2F5139A541DC31F"} -->
                      <!-- {PREOPEN:HEADER-a86c5de9-981a-4b67-978c-1ec87621319e} {"sha1":"1C6D18E65390F7F9A2B58F9FA6E629C745E8AFC1"} -->
                      <ng-template pTemplate="header" let-columns>
                          <!-- {OPEN:HEADER-a86c5de9-981a-4b67-978c-1ec87621319e} {"sha1":"A0CAEAF707FB309F64C504F5512D9982CBAB9B44"} -->
                          <tr>
                              <th colspan="2" *ngFor="let col of columns"
                                  [pSortableColumn]="col.sortField || col.field"
                                  [pSortableColumnDisabled]="col.noSort">
                                  {{ col.header }}
                                  <p-sortIcon [field]="col.sortField || col.field" *ngIf="!col.noSort"></p-sortIcon>
                              </th>
                          </tr>
                          <!-- {CLOSE:HEADER-a86c5de9-981a-4b67-978c-1ec87621319e} {"sha1":"A0CAEAF707FB309F64C504F5512D9982CBAB9B44"} -->
                      </ng-template>
                      <!-- {POSTCLOSE:HEADER-a86c5de9-981a-4b67-978c-1ec87621319e} {"sha1":"3CE1C4AC0BAB4AE25B3F909AC2F5139A541DC31F"} -->
                      <!-- {PREOPEN:BODY-a86c5de9-981a-4b67-978c-1ec87621319e} {"sha1":"50EF52C055FDC4636414FC5785E6B6EE7C2AD9B4"} -->
                      <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                          <!-- {OPEN:BODY-a86c5de9-981a-4b67-978c-1ec87621319e} {} -->
                          <!-- {PREOPEN:f6d1cba1-e077-43d9-b17e-8dbfb762ad82} {"sha1":"6A102E66A84DCCD63E4B5B30FB9715FE5023EE7E"} -->
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">
                              <!-- {OPEN:f6d1cba1-e077-43d9-b17e-8dbfb762ad82} {} -->
                              <!-- {PREOPEN:b936aba7-fa31-415a-aba3-f4f17f94f5e8} {"sha1":"51C40D34349347DCAA4D8D2B4FA1FB3CE7961300"} -->
                              <td>
                                  <!-- {OPEN:b936aba7-fa31-415a-aba3-f4f17f94f5e8} {} -->
                                  <!-- {OPEN:7a836bca-5f12-4724-9a27-61ba73e891f7} {"sha1":"C7E7ECE3CA3E016BFBC497E60D0AB2153C4EF218"} -->
                                  <label>Start</label>
                                  <!-- {CLOSE:7a836bca-5f12-4724-9a27-61ba73e891f7} {"sha1":"C7E7ECE3CA3E016BFBC497E60D0AB2153C4EF218"} -->
                                  <!-- {CLOSE:b936aba7-fa31-415a-aba3-f4f17f94f5e8} {} -->
                              </td>
                              <!-- {POSTCLOSE:b936aba7-fa31-415a-aba3-f4f17f94f5e8} {"sha1":"7886CBA970276FB7D03005E2E88D2D0C8245C924"} -->
                              <!-- {PREOPEN:2c05265f-1111-4d5e-bd99-6d563bda41eb} {"sha1":"51C40D34349347DCAA4D8D2B4FA1FB3CE7961300"} -->
                              <td>
                                  <!-- {OPEN:2c05265f-1111-4d5e-bd99-6d563bda41eb} {} -->
                                  <!-- {OPEN:8e49a0e8-d6fb-4ed9-9cfa-637fc99af4de} {"sha1":"94081D8EA2D57D6991E2413EB33B9A598CE75B57"} -->
                                  <label>{{ rowData?.start}}</label>
                                  <label>{{ rowData?.startText}}</label>
                                  <!-- {CLOSE:8e49a0e8-d6fb-4ed9-9cfa-637fc99af4de} {"sha1":"94081D8EA2D57D6991E2413EB33B9A598CE75B57"} -->
                                  <!-- {CLOSE:2c05265f-1111-4d5e-bd99-6d563bda41eb} {} -->
                              </td>
                              <!-- {POSTCLOSE:2c05265f-1111-4d5e-bd99-6d563bda41eb} {"sha1":"7886CBA970276FB7D03005E2E88D2D0C8245C924"} -->
                              <!-- {CLOSE:f6d1cba1-e077-43d9-b17e-8dbfb762ad82} {} -->

                          </tr>
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">
                              <td>
                                  <label>Finish</label>
                              </td>
                              <td>
                                  <label>{{ rowData?.finish}}</label>
                                  <label>{{ rowData?.finishText}}</label>
                              </td>
                          </tr>
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">
                              <td>
                                  <label>Duration</label>
                              </td>
                              <td>
                                  <label>{{ rowData?.duration}}</label>
                              </td>
                          </tr>
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">
                              <td>
                                  <label>Tender Close</label>
                              </td>
                              <td>
                                  <label>{{ rowData?.tenderClose}}</label>
                              </td>
                          </tr>
                          <!-- {POSTCLOSE:f6d1cba1-e077-43d9-b17e-8dbfb762ad82} {"sha1":"BEE298D391E7E07C7E409976D339C619CACE464E"} -->
                          <!-- {CLOSE:BODY-a86c5de9-981a-4b67-978c-1ec87621319e} {} -->
                      </ng-template>
                      <!-- {POSTCLOSE:BODY-a86c5de9-981a-4b67-978c-1ec87621319e} {"sha1":"3CE1C4AC0BAB4AE25B3F909AC2F5139A541DC31F"} -->
                      <!-- {CLOSE:a86c5de9-981a-4b67-978c-1ec87621319e} {} -->
                  </p-table>
                  <!-- {POSTCLOSE:a86c5de9-981a-4b67-978c-1ec87621319e} {"sha1":"0F574D6E91310BAFBB94CCA38469214C83C014E7"} -->
                  <!-- {PREOPEN:e5f87195-ff55-4d5a-81cb-51387fc5895e} {"sha1":"C08A17B653AE180E0E604694BAE1DD3C830562C8"} -->
                  <p-table #developmentTable [columns]="developmentTypeTableCol" [value]="[project]"
                      [selectionMode]="detailsTableSelectionMode"
                      (onRowSelect)="onDetailsTableRowSelect($event.data)">
                      <!-- {OPEN:e5f87195-ff55-4d5a-81cb-51387fc5895e} {} -->
                      <!-- {PREOPEN:COLGROUP-e5f87195-ff55-4d5a-81cb-51387fc5895e} {"sha1":"06B732CAB28A8FCC541143DDCCB93017E768A850"} -->
                      <ng-template pTemplate="colgroup" let-columns>
                          <!-- {OPEN:COLGROUP-e5f87195-ff55-4d5a-81cb-51387fc5895e} {"sha1":"2A80AF77883053D89E6690FD50F92C5E7C2A6F0D"} -->
                          <colgroup>
                              <col *ngFor="let col of columns" [style.width]="col.width">
                          </colgroup>
                          <!-- {CLOSE:COLGROUP-e5f87195-ff55-4d5a-81cb-51387fc5895e} {"sha1":"2A80AF77883053D89E6690FD50F92C5E7C2A6F0D"} -->
                      </ng-template>
                      <!-- {POSTCLOSE:COLGROUP-e5f87195-ff55-4d5a-81cb-51387fc5895e} {"sha1":"3CE1C4AC0BAB4AE25B3F909AC2F5139A541DC31F"} -->
                      <!-- {PREOPEN:HEADER-e5f87195-ff55-4d5a-81cb-51387fc5895e} {"sha1":"1C6D18E65390F7F9A2B58F9FA6E629C745E8AFC1"} -->
                      <ng-template pTemplate="header" let-columns>
                          <!-- {OPEN:HEADER-e5f87195-ff55-4d5a-81cb-51387fc5895e} {"sha1":"A0CAEAF707FB309F64C504F5512D9982CBAB9B44"} -->
                          <tr>
                              <th *ngFor="let col of columns" colspan="2"
                                  [pSortableColumn]="col.sortField || col.field"
                                  [pSortableColumnDisabled]="col.noSort">
                                  {{ col.header }}
                                  <p-sortIcon [field]="col.sortField || col.field" *ngIf="!col.noSort"></p-sortIcon>
                              </th>
                          </tr>
                          <!-- {CLOSE:HEADER-e5f87195-ff55-4d5a-81cb-51387fc5895e} {"sha1":"A0CAEAF707FB309F64C504F5512D9982CBAB9B44"} -->
                      </ng-template>
                      <!-- {POSTCLOSE:HEADER-e5f87195-ff55-4d5a-81cb-51387fc5895e} {"sha1":"3CE1C4AC0BAB4AE25B3F909AC2F5139A541DC31F"} -->
                      <!-- {PREOPEN:BODY-e5f87195-ff55-4d5a-81cb-51387fc5895e} {"sha1":"50EF52C055FDC4636414FC5785E6B6EE7C2AD9B4"} -->
                      <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                          <!-- {OPEN:BODY-e5f87195-ff55-4d5a-81cb-51387fc5895e} {} -->
                          <!-- {PREOPEN:97edc207-6345-41d1-ba5f-d15d576db0ef} {"sha1":"6A102E66A84DCCD63E4B5B30FB9715FE5023EE7E"} -->
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">
                              <!-- {OPEN:97edc207-6345-41d1-ba5f-d15d576db0ef} {} -->
                              <!-- {PREOPEN:36d72141-f648-4779-b593-bf36592adc63} {"sha1":"51C40D34349347DCAA4D8D2B4FA1FB3CE7961300"} -->
                              <td colspan="2">
                                  <!-- {OPEN:36d72141-f648-4779-b593-bf36592adc63} {} -->
                                  <!-- {OPEN:87059a66-8f66-43a1-8199-32467fb52044} {"sha1":"A46EEB0002C1EEA7E9FBBBBFE3388B5CEC6898D9"} -->
                                  <label>{{rowData?.developmentTypes}}</label>
                                  <!-- {CLOSE:87059a66-8f66-43a1-8199-32467fb52044} {"sha1":"A46EEB0002C1EEA7E9FBBBBFE3388B5CEC6898D9"} -->
                                  <!-- {CLOSE:36d72141-f648-4779-b593-bf36592adc63} {} -->
                              </td>
                              <!-- {POSTCLOSE:36d72141-f648-4779-b593-bf36592adc63} {"sha1":"7886CBA970276FB7D03005E2E88D2D0C8245C924"} -->
                              <!-- {CLOSE:97edc207-6345-41d1-ba5f-d15d576db0ef} {} -->
                          </tr>
                          <!-- {POSTCLOSE:97edc207-6345-41d1-ba5f-d15d576db0ef} {"sha1":"BEE298D391E7E07C7E409976D339C619CACE464E"} -->
                          <!-- {CLOSE:BODY-e5f87195-ff55-4d5a-81cb-51387fc5895e} {} -->
                      </ng-template>
                      <!-- {POSTCLOSE:BODY-e5f87195-ff55-4d5a-81cb-51387fc5895e} {"sha1":"3CE1C4AC0BAB4AE25B3F909AC2F5139A541DC31F"} -->
                      <!-- {CLOSE:e5f87195-ff55-4d5a-81cb-51387fc5895e} {} -->
                  </p-table>
                  <!-- {POSTCLOSE:e5f87195-ff55-4d5a-81cb-51387fc5895e} {"sha1":"0F574D6E91310BAFBB94CCA38469214C83C014E7"} -->
                  <!-- {CLOSE:6db5bfeb-1f4f-4c83-8e39-dbacfcca7ad2} {} -->
                  <p-table #materialsTable [columns]="materialsTableCol" [value]="[project]"
                      [selectionMode]="detailsTableSelectionMode"
                      (onRowSelect)="onDetailsTableRowSelect($event.data)">
                      <ng-template pTemplate="colgroup" let-columns>
                          <colgroup>
                              <col *ngFor="let col of columns" [style.width]="col.width">
                          </colgroup>
                      </ng-template>
                      <ng-template pTemplate="header" let-columns>
                          <tr>
                              <th *ngFor="let col of columns" colspan="2"
                                  [pSortableColumn]="col.sortField || col.field"
                                  [pSortableColumnDisabled]="col.noSort">
                                  {{ col.header }}
                                  <p-sortIcon [field]="col.sortField || col.field" *ngIf="!col.noSort"></p-sortIcon>
                              </th>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">
                              <td colspan="2">
                                  <span *ngFor="let mat of rowData?.materials">
                              <td>
                                  <li>{{mat+", "}}</li>
                              </td>
                              </span>
                              </td>
                          </tr>
                      </ng-template>
                  </p-table>
                  <p-table #additionalInfoTable [columns]="additionalInfoTableCol" [value]="[project]"
                      [selectionMode]="detailsTableSelectionMode"
                      (onRowSelect)="onDetailsTableRowSelect($event.data)">
                      <ng-template pTemplate="colgroup" let-columns>
                          <colgroup>
                              <col *ngFor="let col of columns" [style.width]="col.width">
                          </colgroup>
                      </ng-template>
                      <ng-template pTemplate="header" let-columns>
                          <tr>
                              <th *ngFor="let col of columns" colspan="2"
                                  [pSortableColumn]="col.sortField || col.field"
                                  [pSortableColumnDisabled]="col.noSort">
                                  {{ col.header }}
                                  <p-sortIcon [field]="col.sortField || col.field" *ngIf="!col.noSort"></p-sortIcon>
                              </th>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">
                              <td>
                                  <label>Parking</label>
                              </td>
                              <td>
                                  <label>{{ rowData?.parking}}</label>
                              </td>
                          </tr>
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">
                              <td>
                                  <label>Storeys</label>
                              </td>
                              <td>
                                  <label>{{ rowData?.storeys}}</label>
                              </td>
                          </tr>
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">
                              <td>
                                  <label>Storeys Below</label>
                              </td>
                              <td>
                                  <label>{{ rowData?.storeysBelow}}</label>
                              </td>
                          </tr>
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">
                              <td>
                                  <label>Structures</label>
                              </td>
                              <td>
                                  <label>{{ rowData?.structures}}</label>
                              </td>
                          </tr>
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">
                              <td>
                                  <label>One Bed Houses</label>
                              </td>
                              <td>
                                  <label>{{ rowData?.oneBed}}</label>
                              </td>
                          </tr>
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">
                              <td>
                                  <label>Two Bed Houses</label>
                              </td>
                              <td>
                                  <label>{{ rowData?.twoBed}}</label>
                              </td>
                          </tr>
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">
                              <td>
                                  <label>Three Bed Houses</label>
                              </td>
                              <td>
                                  <label>{{ rowData?.threeBed}}</label>
                              </td>
                          </tr>
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">
                              <td>
                                  <label>Four Bed Houses</label>
                              </td>
                              <td>
                                  <label>{{ rowData?.fourBed}}</label>
                              </td>
                          </tr>
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">
                              <td>
                                  <label>Five Bed Houses</label>
                              </td>
                              <td>
                                  <label>{{ rowData?.fiveBed}}</label>
                              </td>
                          </tr>
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">
                              <td>
                                  <label>Total Flats</label>
                              </td>
                              <td>
                                  <label>{{ rowData?.flats}}</label>
                              </td>
                          </tr>
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">
                              <td>
                                  <label>Total Houses</label>
                              </td>
                              <td>
                                  <label>{{ rowData?.houses}}</label>
                              </td>
                          </tr>
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">
                              <td>
                                  <label>Hotel Beds</label>
                              </td>
                              <td>
                                  <label>{{ rowData?.hotelBeds}}</label>
                              </td>
                          </tr>
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">
                              <td>
                                  <label>Student Beds</label>
                              </td>
                              <td>
                                  <label>{{ rowData?.studentBeds}}</label>
                              </td>
                          </tr>
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">
                              <td>
                                  <label>Hospital Beds</label>
                              </td>
                              <td>
                                  <label>{{ rowData?.hospitalBeds}}</label>
                              </td>
                          </tr>
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">
                              <td>
                                  <label>Care Beds</label>
                              </td>
                              <td>
                                  <label>{{ rowData?.careBeds}}</label>
                              </td>
                          </tr>
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">
                              <td>
                                  <label>Shelter Beds</label>
                              </td>
                              <td>
                                  <label>{{ rowData?.shelteredBeds}}</label>
                              </td>
                          </tr>
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">
                              <td>
                                  <label>Net Retail Floor</label>
                              </td>
                              <td>
                                  <label>{{ rowData?.netRetailFloor}}</label>
                              </td>
                          </tr>
                          <tr [pSelectableRow]="rowData"
                              [pSelectableRowDisabled]="detailsTableSelectionMode ? false : true">
                              <td>
                                  <label>Gross Retail Floor</label>
                              </td>
                              <td>
                                  <label>{{ rowData?.grossRetailFloor}}</label>
                              </td>
                          </tr>
                      </ng-template>
                  </p-table>
              </div>
              <!-- {POSTCLOSE:6db5bfeb-1f4f-4c83-8e3-->
              <!-- {POSTCLOSE:6db5bfeb-1f4f-4c83-8e39-dbacfcca7ad2} {"sha1":"C4A15ED98D4B138EEB595266FB1CF4C91CF45CA5"} -->
              <!-- {CLOSE:de985d69-e8e7-4435-acfc-9a2fe7ca5ff7} {} -->
          </div>
          <!-- {POSTCLOSE:de985d69-e8e7-4435-acfc-9a2fe7ca5ff7} {"sha1":"797F18B33CA2DAC7B5A389B76ABFD8D91F547465"} -->
          <!-- {CLOSE:f18af475-9619-47ff-8551-ee894660216a} {} -->
          <!-- {CLOSE:7a2634aa-5a58-43c0-bdb5-2773aa54042e} {} -->
      </p-tabPanel>
      <!-- {POSTCLOSE:7a2634aa-5a58-43c0-bdb5-2773aa54042e} {"sha1":"F49AEAFEC8DDF0FE02750884DD3DAEAF27C428C6"} -->
      <!-- {PREOPEN:8d28df28-0232-42b3-bc94-f1aa0aa685bc} {"sha1":"2F4AD25E391142BA75B6407DB1672DEAE5BF17A7"} -->
      <p-tabPanel>
          <!-- {OPEN:8d28df28-0232-42b3-bc94-f1aa0aa685bc} {} -->
          <!-- {PREOPEN:8d28df28-0232-42b3-bc94-f1aa0aa685bc-HEADER} {"sha1":"EFFD8873F35AFEDE07E793ED170A75F21D8735DE"} -->
          <ng-template pTemplate="header">
              <!-- {OPEN:8d28df28-0232-42b3-bc94-f1aa0aa685bc-HEADER} {"sha1":"B17697DFA491EF88959623E4E7B5C0223248C258"} -->
              <span>Roles</span>
              <!-- {CLOSE:8d28df28-0232-42b3-bc94-f1aa0aa685bc-HEADER} {"sha1":"B17697DFA491EF88959623E4E7B5C0223248C258"} -->
          </ng-template>
          <!-- {POSTCLOSE:8d28df28-0232-42b3-bc94-f1aa0aa685bc-HEADER} {"sha1":"08D2DC238814DBE0D3FB2F9729EBA749BB91A4A1"} -->
          <!-- {PREOPEN:4f1f4566-c6c7-43bc-9e0c-852914c72e1a} {"sha1":"D1D7623649C05A90DE861964468A92C621F04C93"} -->
          <p-table #rolesTable [columns]="rolesTableCols" [value]="roles" [selectionMode]="rolesTableSelectionMode"
              (onRowSelect)="onRolesTableRowSelect($event.data)" [paginator]="true" [rows]="10"
              [rowsPerPageOptions]='["10", "20", "30"]'>
              <!-- {OPEN:4f1f4566-c6c7-43bc-9e0c-852914c72e1a} {} -->
              <!-- {PREOPEN:COLGROUP-4f1f4566-c6c7-43bc-9e0c-852914c72e1a} {"sha1":"3DC6D8C9B1E7B0223D1CCE76C1C82A288C9A4689"} -->
              <ng-template pTemplate="colgroup" let-columns>
                  <!-- {OPEN:COLGROUP-4f1f4566-c6c7-43bc-9e0c-852914c72e1a} {"sha1":"13C60BD53C8B56A370AADCD09793D14BBC6FE2C9"} -->
                  <colgroup>
                      <col *ngFor="let col of columns" [style.width]="col.width">
                  </colgroup>
                  <!-- {CLOSE:COLGROUP-4f1f4566-c6c7-43bc-9e0c-852914c72e1a} {"sha1":"13C60BD53C8B56A370AADCD09793D14BBC6FE2C9"} -->
              </ng-template>
              <!-- {POSTCLOSE:COLGROUP-4f1f4566-c6c7-43bc-9e0c-852914c72e1a} {"sha1":"6D02DE4A18BCD9958BB50B15D6A5C9E85A898BF6"} -->
              <!-- {PREOPEN:HEADER-4f1f4566-c6c7-43bc-9e0c-852914c72e1a} {"sha1":"A4D3A80E2BCC0A34677F3E2585615CA43EAC9D20"} -->
              <ng-template pTemplate="header" let-columns>
                  <!-- {OPEN:HEADER-4f1f4566-c6c7-43bc-9e0c-852914c72e1a} {"sha1":"204EC507787942D97F16B31A57EBBE68ED191160"} -->
                  <tr>
                      <th *ngFor="let col of columns" [pSortableColumn]="col.sortField || col.field"
                          [pSortableColumnDisabled]="col.noSort">
                          {{ col.header }}
                          <p-sortIcon [field]="col.sortField || col.field" *ngIf="!col.noSort"></p-sortIcon>
                      </th>
                  </tr>
                  <tr>
                      <th *ngFor="let col of columns">
                          <input *ngIf="!col.noFilter" pInputText type="text"
                              (input)="rolesTable.filter($event.target.value, col.field, 'contains')">
                      </th>
                  </tr>
                  <!-- {CLOSE:HEADER-4f1f4566-c6c7-43bc-9e0c-852914c72e1a} {"sha1":"204EC507787942D97F16B31A57EBBE68ED191160"} -->
              </ng-template>
              <!-- {POSTCLOSE:HEADER-4f1f4566-c6c7-43bc-9e0c-852914c72e1a} {"sha1":"6D02DE4A18BCD9958BB50B15D6A5C9E85A898BF6"} -->
              <!-- {PREOPEN:BODY-4f1f4566-c6c7-43bc-9e0c-852914c72e1a} {"sha1":"6345486287546C5AD52CE54658AE62B533BE341D"} -->
              <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                  <!-- {OPEN:BODY-4f1f4566-c6c7-43bc-9e0c-852914c72e1a} {} -->
                  <!-- {PREOPEN:c15a6277-aa13-4b5f-a220-00a2493b2c42} {"sha1":"51821334AB459A557D9D801715E87D5FBA8F8511"} -->
                  <tr [pSelectableRow]="rowData" [pSelectableRowDisabled]="rolesTableSelectionMode ? false : true">
                      <!-- {OPEN:c15a6277-aa13-4b5f-a220-00a2493b2c42} {} -->
                      <!-- {PREOPEN:6398c0d8-03e8-420e-abfa-b80ff7c072d4} {"sha1":"2A2D269FD32FDF0E87FA25980833D1BC3670DAA7"} -->
                      <td>
                          <!-- {OPEN:6398c0d8-03e8-420e-abfa-b80ff7c072d4} {} -->
                          <!-- {OPEN:065d7745-f499-452a-99f0-b032d7864a04} {"sha1":"2FE8EA2CE4BDF27C56F36458018FBA99D0E3CBD6"} -->
                          <label>{{ rowData.role?.roleDescription }}</label>
                          <!-- {CLOSE:065d7745-f499-452a-99f0-b032d7864a04} {"sha1":"2FE8EA2CE4BDF27C56F36458018FBA99D0E3CBD6"} -->
                          <!-- {CLOSE:6398c0d8-03e8-420e-abfa-b80ff7c072d4} {} -->
                      </td>
                      <!-- {POSTCLOSE:6398c0d8-03e8-420e-abfa-b80ff7c072d4} {"sha1":"3481EDE2B787707391B17836799739A723650553"} -->
                      <!-- {PREOPEN:80bc119c-0732-404a-a72f-5a6704bda644} {"sha1":"2A2D269FD32FDF0E87FA25980833D1BC3670DAA7"} -->
                      <td>
                          <!-- {OPEN:80bc119c-0732-404a-a72f-5a6704bda644} {} -->
                          <!-- {OPEN:81eecaef-7ddc-4aac-8817-eb50ee6807a6} {"sha1":"FA26907A94FF977234A00D5A46428448A74AB38C"} -->
                          <a [ngStyle]='{"color":"#0d0c0c"}'
                              [routerLink]="'/search/companies/'+ rowData.company?.id">{{ rowData.company?.name }}</a>
                          <!-- {CLOSE:81eecaef-7ddc-4aac-8817-eb50ee6807a6} {"sha1":"FA26907A94FF977234A00D5A46428448A74AB38C"} -->
                          <!-- {CLOSE:80bc119c-0732-404a-a72f-5a6704bda644} {} -->
                      </td>
                      <!-- {POSTCLOSE:80bc119c-0732-404a-a72f-5a6704bda644} {"sha1":"3481EDE2B787707391B17836799739A723650553"} -->
                      <!-- {PREOPEN:1558110b-93b6-46c1-917f-a82883fb1bae} {"sha1":"2A2D269FD32FDF0E87FA25980833D1BC3670DAA7"} -->
                      <td>
                          <!-- {OPEN:1558110b-93b6-46c1-917f-a82883fb1bae} {} -->
                          <!-- {OPEN:56d6c95e-50e0-455f-8b82-8577479a1428} {"sha1":"74E9547B92A5D2708AAF10A89AEF188F8767864A"} -->
                          <a [ngStyle]='{"color":"#080707"}' [routerLink]="'/contacts/'+rowData.people?.id">{{
                              rowData.people?.fullName }}</a>
                          <!-- {CLOSE:56d6c95e-50e0-455f-8b82-8577479a1428} {"sha1":"74E9547B92A5D2708AAF10A89AEF188F8767864A"} -->
                          <!-- {CLOSE:1558110b-93b6-46c1-917f-a82883fb1bae} {} -->
                      </td>
                      <!-- {POSTCLOSE:1558110b-93b6-46c1-917f-a82883fb1bae} {"sha1":"3481EDE2B787707391B17836799739A723650553"} -->
                      <!-- {CLOSE:c15a6277-aa13-4b5f-a220-00a2493b2c42} {} -->
                  </tr>
                  <!-- {POSTCLOSE:c15a6277-aa13-4b5f-a220-00a2493b2c42} {"sha1":"A88D4F820D8E19D78F33837AE3FDCDC0295AD32E"} -->
                  <!-- {CLOSE:BODY-4f1f4566-c6c7-43bc-9e0c-852914c72e1a} {} -->
              </ng-template>
              <!-- {POSTCLOSE:BODY-4f1f4566-c6c7-43bc-9e0c-852914c72e1a} {"sha1":"6D02DE4A18BCD9958BB50B15D6A5C9E85A898BF6"} -->
              <!-- {CLOSE:4f1f4566-c6c7-43bc-9e0c-852914c72e1a} {} -->
          </p-table>
          <!-- {POSTCLOSE:4f1f4566-c6c7-43bc-9e0c-852914c72e1a} {"sha1":"5EF6146E1F710ACFFC7D61E0ED2D805CCA4FEF5A"} -->
          <!-- {CLOSE:8d28df28-0232-42b3-bc94-f1aa0aa685bc} {} -->
      </p-tabPanel>
      <!-- {POSTCLOSE:8d28df28-0232-42b3-bc94-f1aa0aa685bc} {"sha1":"F49AEAFEC8DDF0FE02750884DD3DAEAF27C428C6"} -->
      <!-- {PREOPEN:cf546925-68fd-4d7a-ba94-b8d292c650e0} {"sha1":"2F4AD25E391142BA75B6407DB1672DEAE5BF17A7"} -->
      <p-tabPanel>
          <!-- {OPEN:cf546925-68fd-4d7a-ba94-b8d292c650e0} {} -->
          <!-- {PREOPEN:cf546925-68fd-4d7a-ba94-b8d292c650e0-HEADER} {"sha1":"EFFD8873F35AFEDE07E793ED170A75F21D8735DE"} -->
          <ng-template pTemplate="header">
              <!-- {OPEN:cf546925-68fd-4d7a-ba94-b8d292c650e0-HEADER} {"sha1":"689A2A394978262833BA97B907EADC8574425179"} -->
              <span>Deals</span>
              <!-- {CLOSE:cf546925-68fd-4d7a-ba94-b8d292c650e0-HEADER} {"sha1":"689A2A394978262833BA97B907EADC8574425179"} -->
          </ng-template>
          <!-- {POSTCLOSE:cf546925-68fd-4d7a-ba94-b8d292c650e0-HEADER} {"sha1":"08D2DC238814DBE0D3FB2F9729EBA749BB91A4A1"} -->
          <!-- {PREOPEN:4dd57591-ca75-42cc-a798-497b68369407} {"sha1":"4AA5DE0FED39371D986AC7C7925BA41A7951F604"} -->
          <p-table #dealsTable [columns]="dealsTableCols" [value]="deals" [selectionMode]="dealsTableSelectionMode"
              (onRowSelect)="onDealsTableRowSelect($event.data)" [paginator]="true" [rows]="10"
              [rowsPerPageOptions]='["10", "20", "30"]'>
              <!-- {OPEN:4dd57591-ca75-42cc-a798-497b68369407} {} -->
              <!-- {PREOPEN:CAPTION-4dd57591-ca75-42cc-a798-497b68369407} {"sha1":"F68BF79523DD4C65B2C57E5DEB66CFEEE948F254"} -->
              <!-- {POSTCLOSE:CAPTION-4dd57591-ca75-42cc-a798-497b68369407} {"sha1":"6D02DE4A18BCD9958BB50B15D6A5C9E85A898BF6"} -->
              <!-- {PREOPEN:COLGROUP-4dd57591-ca75-42cc-a798-497b68369407} {"sha1":"3DC6D8C9B1E7B0223D1CCE76C1C82A288C9A4689"} -->
              <ng-template pTemplate="colgroup" let-columns>
                  <!-- {OPEN:COLGROUP-4dd57591-ca75-42cc-a798-497b68369407} {"sha1":"13C60BD53C8B56A370AADCD09793D14BBC6FE2C9"} -->
                  <colgroup>
                      <col *ngFor="let col of columns" [style.width]="col.width">
                  </colgroup>
                  <!-- {CLOSE:COLGROUP-4dd57591-ca75-42cc-a798-497b68369407} {"sha1":"13C60BD53C8B56A370AADCD09793D14BBC6FE2C9"} -->
              </ng-template>
              <!-- {POSTCLOSE:COLGROUP-4dd57591-ca75-42cc-a798-497b68369407} {"sha1":"6D02DE4A18BCD9958BB50B15D6A5C9E85A898BF6"} -->
              <!-- {PREOPEN:HEADER-4dd57591-ca75-42cc-a798-497b68369407} {"sha1":"A4D3A80E2BCC0A34677F3E2585615CA43EAC9D20"} -->
              <ng-template pTemplate="header" let-columns>
                  <!-- {OPEN:HEADER-4dd57591-ca75-42cc-a798-497b68369407} {"sha1":"8F2D3233316C0D09E1BCA5A96D9FB4131CC56D59"} -->
                  <tr>
                      <th *ngFor="let col of columns" [pSortableColumn]="col.sortField || col.field"
                          [pSortableColumnDisabled]="col.noSort">
                          {{ col.header }}
                          <p-sortIcon [field]="col.sortField || col.field" *ngIf="!col.noSort"></p-sortIcon>
                      </th>
                  </tr>
                  <tr>
                      <th *ngFor="let col of columns">
                          <input *ngIf="!col.noFilter" pInputText type="text"
                              (input)="dealsTable.filter($event.target.value, col.field, 'contains')">
                      </th>
                  </tr>
                  <!-- {CLOSE:HEADER-4dd57591-ca75-42cc-a798-497b68369407} {"sha1":"8F2D3233316C0D09E1BCA5A96D9FB4131CC56D59"} -->
              </ng-template>
              <!-- {POSTCLOSE:HEADER-4dd57591-ca75-42cc-a798-497b68369407} {"sha1":"6D02DE4A18BCD9958BB50B15D6A5C9E85A898BF6"} -->
              <!-- {PREOPEN:BODY-4dd57591-ca75-42cc-a798-497b68369407} {"sha1":"6345486287546C5AD52CE54658AE62B533BE341D"} -->
              <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                  <!-- {OPEN:BODY-4dd57591-ca75-42cc-a798-497b68369407} {} -->
                  <!-- {PREOPEN:7d3aa05a-5649-4b68-959f-9aa619ca1982} {"sha1":"82ACC3AB1345AED2FDA98DCBD0F840019C10F7BB"} -->
                  <tr [pSelectableRow]="rowData" [pSelectableRowDisabled]="dealsTableSelectionMode ? false : true">
                      <!-- {OPEN:7d3aa05a-5649-4b68-959f-9aa619ca1982} {} -->
                      <!-- {PREOPEN:f7aa9439-f696-421d-b50e-aec798f500ec} {"sha1":"2A2D269FD32FDF0E87FA25980833D1BC3670DAA7"} -->
                      <td>
                          <!-- {OPEN:f7aa9439-f696-421d-b50e-aec798f500ec} {} -->
                          <!-- {OPEN:81c714c1-a4d0-4ccb-9b9b-8ff93d263392} {"sha1":"7B6F0319563D22BF233E865567D2B4FD05FC7EB0"} -->
                          <label>{{ rowData.name }}</label>
                          <!-- {CLOSE:81c714c1-a4d0-4ccb-9b9b-8ff93d263392} {"sha1":"7B6F0319563D22BF233E865567D2B4FD05FC7EB0"} -->
                          <!-- {CLOSE:f7aa9439-f696-421d-b50e-aec798f500ec} {} -->
                      </td>
                      <!-- {POSTCLOSE:f7aa9439-f696-421d-b50e-aec798f500ec} {"sha1":"3481EDE2B787707391B17836799739A723650553"} -->
                      <!-- {PREOPEN:a4529b2e-c77d-4b16-ad8d-a1f525f2b4d4} {"sha1":"2A2D269FD32FDF0E87FA25980833D1BC3670DAA7"} -->
                      <td>
                          <!-- {OPEN:a4529b2e-c77d-4b16-ad8d-a1f525f2b4d4} {} -->
                          <!-- {OPEN:f9114bba-fc39-460d-a787-0884dfdc8b8d} {"sha1":"BE44F9971337234667309612D5FE34E7CEA3086D"} -->
                          <label>{{ rowData.stageName }}</label>
                          <!-- {CLOSE:f9114bba-fc39-460d-a787-0884dfdc8b8d} {"sha1":"BE44F9971337234667309612D5FE34E7CEA3086D"} -->
                          <!-- {CLOSE:a4529b2e-c77d-4b16-ad8d-a1f525f2b4d4} {} -->
                      </td>
                      <!-- {POSTCLOSE:a4529b2e-c77d-4b16-ad8d-a1f525f2b4d4} {"sha1":"3481EDE2B787707391B17836799739A723650553"} -->
                      <!-- {PREOPEN:ae4dd5a4-73b9-4402-b393-59fff833d386} {"sha1":"2A2D269FD32FDF0E87FA25980833D1BC3670DAA7"} -->
                      <td>
                          <!-- {OPEN:ae4dd5a4-73b9-4402-b393-59fff833d386} {} -->
                          <!-- {OPEN:e3243096-66d1-41f5-a7bd-4c2b12fa87c1} {"sha1":"A6D9AE8F1617E6E93A1798007C9E9591E540C609"} -->
                          <label>{{ rowData.ownerName }}</label>
                          <!-- {CLOSE:e3243096-66d1-41f5-a7bd-4c2b12fa87c1} {"sha1":"A6D9AE8F1617E6E93A1798007C9E9591E540C609"} -->
                          <!-- {CLOSE:ae4dd5a4-73b9-4402-b393-59fff833d386} {} -->
                      </td>
                      <!-- {POSTCLOSE:ae4dd5a4-73b9-4402-b393-59fff833d386} {"sha1":"3481EDE2B787707391B17836799739A723650553"} -->
                      <!-- {PREOPEN:6f09ae3f-40a5-4829-b18b-4189f47ff762} {"sha1":"2A2D269FD32FDF0E87FA25980833D1BC3670DAA7"} -->
                      <td>
                          <!-- {OPEN:6f09ae3f-40a5-4829-b18b-4189f47ff762} {} -->
                          <!-- {OPEN:ded792ad-1d0a-4462-b8af-f6bd1694031a} {"sha1":"FD45EB741A730419589DA98FB68EE2F657695C03"} -->
                          <label>{{ rowData.amount }}</label>
                          <!-- {CLOSE:ded792ad-1d0a-4462-b8af-f6bd1694031a} {"sha1":"FD45EB741A730419589DA98FB68EE2F657695C03"} -->
                          <!-- {CLOSE:6f09ae3f-40a5-4829-b18b-4189f47ff762} {} -->
                      </td>
                      <!-- {POSTCLOSE:6f09ae3f-40a5-4829-b18b-4189f47ff762} {"sha1":"3481EDE2B787707391B17836799739A723650553"} -->
                      <!-- {PREOPEN:a66bf9af-0a23-4629-8d32-2568883a0f34} {"sha1":"2A2D269FD32FDF0E87FA25980833D1BC3670DAA7"} -->
                      <td>
                          <!-- {OPEN:a66bf9af-0a23-4629-8d32-2568883a0f34} {} -->
                          <!-- {OPEN:fa28d5f9-08cd-4ccf-a436-8af3ce21dfe5} {"sha1":"6E7CAEC153776841C8BED36E81C46CEAA9117806"} -->
                          <label>{{ rowData.closeDate }}</label>
                          <!-- {CLOSE:fa28d5f9-08cd-4ccf-a436-8af3ce21dfe5} {"sha1":"6E7CAEC153776841C8BED36E81C46CEAA9117806"} -->
                          <!-- {CLOSE:a66bf9af-0a23-4629-8d32-2568883a0f34} {} -->
                      </td>
                      <!-- {POSTCLOSE:a66bf9af-0a23-4629-8d32-2568883a0f34} {"sha1":"3481EDE2B787707391B17836799739A723650553"} -->
                      <!-- {PREOPEN:025ae36e-d01c-448c-b648-511f352feb83} {"sha1":"2A2D269FD32FDF0E87FA25980833D1BC3670DAA7"} -->
                      <td>
                          <!-- {OPEN:025ae36e-d01c-448c-b648-511f352feb83} {} -->
                          <!-- {OPEN:076479e7-507b-4c99-b9ef-fe8637514747} {"sha1":"6E7CAEC153776841C8BED36E81C46CEAA9117806"} -->
                          <label>{{ rowData.createdDate }}</label>
                          <!-- {CLOSE:076479e7-507b-4c99-b9ef-fe8637514747} {"sha1":"6E7CAEC153776841C8BED36E81C46CEAA9117806"} -->
                          <!-- {CLOSE:025ae36e-d01c-448c-b648-511f352feb83} {} -->
                      </td>
                      <!-- {POSTCLOSE:025ae36e-d01c-448c-b648-511f352feb83} {"sha1":"3481EDE2B787707391B17836799739A723650553"} -->
                      <!-- {PREOPEN:03cc20e3-d42c-4c80-a167-faf35459c87c} {"sha1":"2A2D269FD32FDF0E87FA25980833D1BC3670DAA7"} -->
                      <td>
                          <!-- {OPEN:03cc20e3-d42c-4c80-a167-faf35459c87c} {} -->
                          <!-- {OPEN:a810221f-0368-4d92-b196-f08a80cc4ca8} {"sha1":"6E7CAEC153776841C8BED36E81C46CEAA9117806"} -->
                          <label>{{ rowData.lastUpdated }}</label>
                          <!-- {CLOSE:a810221f-0368-4d92-b196-f08a80cc4ca8} {"sha1":"6E7CAEC153776841C8BED36E81C46CEAA9117806"} -->
                          <!-- {CLOSE:03cc20e3-d42c-4c80-a167-faf35459c87c} {} -->
                      </td>
                      <!-- {POSTCLOSE:03cc20e3-d42c-4c80-a167-faf35459c87c} {"sha1":"3481EDE2B787707391B17836799739A723650553"} -->
                      <!-- {CLOSE:7d3aa05a-5649-4b68-959f-9aa619ca1982} {} -->
                  </tr>
                  <!-- {POSTCLOSE:7d3aa05a-5649-4b68-959f-9aa619ca1982} {"sha1":"A88D4F820D8E19D78F33837AE3FDCDC0295AD32E"} -->
                  <!-- {CLOSE:BODY-4dd57591-ca75-42cc-a798-497b68369407} {} -->
              </ng-template>
              <!-- {POSTCLOSE:BODY-4dd57591-ca75-42cc-a798-497b68369407} {"sha1":"6D02DE4A18BCD9958BB50B15D6A5C9E85A898BF6"} -->
              <!-- {CLOSE:4dd57591-ca75-42cc-a798-497b68369407} {} -->
          </p-table>
          <!-- {POSTCLOSE:4dd57591-ca75-42cc-a798-497b68369407} {"sha1":"5EF6146E1F710ACFFC7D61E0ED2D805CCA4FEF5A"} -->
          <!-- {CLOSE:cf546925-68fd-4d7a-ba94-b8d292c650e0} {} -->
      </p-tabPanel>
      <!-- {POSTCLOSE:cf546925-68fd-4d7a-ba94-b8d292c650e0} {"sha1":"F49AEAFEC8DDF0FE02750884DD3DAEAF27C428C6"} -->
      <!-- {PREOPEN:b120ba25-1ef8-42e9-b1e4-de4e1d076fca} {"sha1":"2F4AD25E391142BA75B6407DB1672DEAE5BF17A7"} -->
      <p-tabPanel>
          <!-- {OPEN:b120ba25-1ef8-42e9-b1e4-de4e1d076fca} {} -->
          <!-- {PREOPEN:b120ba25-1ef8-42e9-b1e4-de4e1d076fca-HEADER} {"sha1":"EFFD8873F35AFEDE07E793ED170A75F21D8735DE"} -->
          <ng-template pTemplate="header">
              <!-- {OPEN:b120ba25-1ef8-42e9-b1e4-de4e1d076fca-HEADER} {"sha1":"FEFA7F4FF7FD43EF3B96227EC7BE3F258B4E6E31"} -->
              <span>Updates</span>
              <!-- {CLOSE:b120ba25-1ef8-42e9-b1e4-de4e1d076fca-HEADER} {"sha1":"FEFA7F4FF7FD43EF3B96227EC7BE3F258B4E6E31"} -->
          </ng-template>
          <!-- {POSTCLOSE:b120ba25-1ef8-42e9-b1e4-de4e1d076fca-HEADER} {"sha1":"08D2DC238814DBE0D3FB2F9729EBA749BB91A4A1"} -->
          <!-- {PREOPEN:8b93d429-2091-4d58-af33-4853cd7e6e34} {"sha1":"113179B303846669240A2472F9753CA1E25B4E9B"} -->
          <p-table #updatesTable [columns]="updatesTableCols" [value]="updates"
              [selectionMode]="updatesTableSelectionMode" (onRowSelect)="onUpdatesTableRowSelect($event.data)"
              [paginator]="true" [rows]="10" [rowsPerPageOptions]='["10", "20", "30"]'>
              <!-- {OPEN:8b93d429-2091-4d58-af33-4853cd7e6e34} {} -->
              <!-- {PREOPEN:CAPTION-8b93d429-2091-4d58-af33-4853cd7e6e34} {"sha1":"F68BF79523DD4C65B2C57E5DEB66CFEEE948F254"} -->
              <!-- {POSTCLOSE:CAPTION-8b93d429-2091-4d58-af33-4853cd7e6e34} {"sha1":"6D02DE4A18BCD9958BB50B15D6A5C9E85A898BF6"} -->
              <!-- {PREOPEN:COLGROUP-8b93d429-2091-4d58-af33-4853cd7e6e34} {"sha1":"3DC6D8C9B1E7B0223D1CCE76C1C82A288C9A4689"} -->
              <ng-template pTemplate="colgroup" let-columns>
                  <!-- {OPEN:COLGROUP-8b93d429-2091-4d58-af33-4853cd7e6e34} {"sha1":"13C60BD53C8B56A370AADCD09793D14BBC6FE2C9"} -->
                  <colgroup>
                      <col *ngFor="let col of columns" [style.width]="col.width">
                  </colgroup>
                  <!-- {CLOSE:COLGROUP-8b93d429-2091-4d58-af33-4853cd7e6e34} {"sha1":"13C60BD53C8B56A370AADCD09793D14BBC6FE2C9"} -->
              </ng-template>
              <!-- {POSTCLOSE:COLGROUP-8b93d429-2091-4d58-af33-4853cd7e6e34} {"sha1":"6D02DE4A18BCD9958BB50B15D6A5C9E85A898BF6"} -->
              <!-- {PREOPEN:HEADER-8b93d429-2091-4d58-af33-4853cd7e6e34} {"sha1":"A4D3A80E2BCC0A34677F3E2585615CA43EAC9D20"} -->
              <ng-template pTemplate="header" let-columns>
                  <!-- {OPEN:HEADER-8b93d429-2091-4d58-af33-4853cd7e6e34} {"sha1":"1FF6BECC755088F5C7C2BE843A83FE71F853BA89"} -->
                  <tr>
                      <th *ngFor="let col of columns" [pSortableColumn]="col.sortField || col.field"
                          [pSortableColumnDisabled]="col.noSort">
                          {{ col.header }}
                          <p-sortIcon [field]="col.sortField || col.field" *ngIf="!col.noSort"></p-sortIcon>
                      </th>
                  </tr>
                  <!-- {CLOSE:HEADER-8b93d429-2091-4d58-af33-4853cd7e6e34} {"sha1":"1FF6BECC755088F5C7C2BE843A83FE71F853BA89"} -->
              </ng-template>
              <!-- {POSTCLOSE:HEADER-8b93d429-2091-4d58-af33-4853cd7e6e34} {"sha1":"6D02DE4A18BCD9958BB50B15D6A5C9E85A898BF6"} -->
              <!-- {PREOPEN:BODY-8b93d429-2091-4d58-af33-4853cd7e6e34} {"sha1":"6345486287546C5AD52CE54658AE62B533BE341D"} -->
              <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                  <!-- {OPEN:BODY-8b93d429-2091-4d58-af33-4853cd7e6e34} {} -->
                  <!-- {PREOPEN:c66ef384-8319-49c9-8e32-7d2bb2c7106c} {"sha1":"5F0910A9B1CB580E95C37C6DD0C8CE84D3080DB7"} -->
                  <tr [pSelectableRow]="rowData" [pSelectableRowDisabled]="updatesTableSelectionMode ? false : true">
                      <!-- {OPEN:c66ef384-8319-49c9-8e32-7d2bb2c7106c} {} -->
                      <!-- {PREOPEN:797aa75e-3e38-4daa-a772-d32a0386772a} {"sha1":"2A2D269FD32FDF0E87FA25980833D1BC3670DAA7"} -->
                      <td>
                          <!-- {OPEN:797aa75e-3e38-4daa-a772-d32a0386772a} {} -->
                          <!-- {OPEN:a94153cf-efcb-49b1-825c-5b2514f5e0a6} {"sha1":"48C2F949600B8426ADB45346A4AEEBA44CD2E9A8"} -->
                          <label>{{ rowData.description }}</label>
                          <!-- {CLOSE:a94153cf-efcb-49b1-825c-5b2514f5e0a6} {"sha1":"48C2F949600B8426ADB45346A4AEEBA44CD2E9A8"} -->
                          <!-- {CLOSE:797aa75e-3e38-4daa-a772-d32a0386772a} {} -->
                      </td>
                      <!-- {POSTCLOSE:797aa75e-3e38-4daa-a772-d32a0386772a} {"sha1":"3481EDE2B787707391B17836799739A723650553"} -->
                      <!-- {PREOPEN:e021836c-29a0-487a-b4ea-c08bcdc654c8} {"sha1":"2A2D269FD32FDF0E87FA25980833D1BC3670DAA7"} -->
                      <td>
                          <!-- {OPEN:e021836c-29a0-487a-b4ea-c08bcdc654c8} {} -->
                          <!-- {OPEN:ae2e5f01-325c-4c54-bf70-3432169c1090} {"sha1":"6E7CAEC153776841C8BED36E81C46CEAA9117806"} -->
                          <label>{{ rowData.updateDate }}</label>
                          <!-- {CLOSE:ae2e5f01-325c-4c54-bf70-3432169c1090} {"sha1":"6E7CAEC153776841C8BED36E81C46CEAA9117806"} -->
                          <!-- {CLOSE:e021836c-29a0-487a-b4ea-c08bcdc654c8} {} -->
                      </td>
                      <!-- {POSTCLOSE:e021836c-29a0-487a-b4ea-c08bcdc654c8} {"sha1":"3481EDE2B787707391B17836799739A723650553"} -->
                      <!-- {CLOSE:c66ef384-8319-49c9-8e32-7d2bb2c7106c} {} -->
                  </tr>
                  <!-- {POSTCLOSE:c66ef384-8319-49c9-8e32-7d2bb2c7106c} {"sha1":"A88D4F820D8E19D78F33837AE3FDCDC0295AD32E"} -->
                  <!-- {CLOSE:BODY-8b93d429-2091-4d58-af33-4853cd7e6e34} {} -->
              </ng-template>
              <!-- {POSTCLOSE:BODY-8b93d429-2091-4d58-af33-4853cd7e6e34} {"sha1":"6D02DE4A18BCD9958BB50B15D6A5C9E85A898BF6"} -->
              <!-- {CLOSE:8b93d429-2091-4d58-af33-4853cd7e6e34} {} -->
          </p-table>
          <!-- {POSTCLOSE:8b93d429-2091-4d58-af33-4853cd7e6e34} {"sha1":"5EF6146E1F710ACFFC7D61E0ED2D805CCA4FEF5A"} -->
          <!-- {CLOSE:b120ba25-1ef8-42e9-b1e4-de4e1d076fca} {} -->
      </p-tabPanel>
      <!-- {POSTCLOSE:b120ba25-1ef8-42e9-b1e4-de4e1d076fca} {"sha1":"F49AEAFEC8DDF0FE02750884DD3DAEAF27C428C6"} -->
      <!-- {CLOSE:ebd7531e-0c95-4f48-943b-a5c9db5d391a} {} -->
  </p-tabView>
  <!-- {POSTCLOSE:ebd7531e-0c95-4f48-943b-a5c9db5d391a} {"sha1":"5ECEB12B574903FC69B702C1DC533C590ADF76B3"} -->
  <oneit-confirmDialog></oneit-confirmDialog>
  <p-progressSpinner *ngIf="showLoader"></p-progressSpinner>
  <!-- {CLOSE:44eec7eb-1662-4bde-948a-5e7e8cbe19a2} {} -->
</form>
<!-- {POSTCLOSE:44eec7eb-1662-4bde-948a-5e7e8cbe19a2} {"sha1":"3FD6E659562C1876D02FC8CF54746B1502442CD2"} -->
