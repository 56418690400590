import { Component, OnInit, ViewChild } from '@angular/core';
import { MsgsService } from '../../oneit/services/msgs.service';
import { Subject, Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { UtilsService } from '../../oneit/services/utils.service';
import { SelectItem } from 'primeng/api';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng';
import { AppService } from '../../app.service';
import { takeUntil } from 'rxjs/operators';
import { AddCrmComponent } from '../add-crm/add-crm.component';

@Component({
    selector: 'app-crm-login',
    templateUrl: './crm-login.component.html',
    styleUrls: ['./crm-login.component.scss'],
    providers: [DialogService]
})
export class CRMLoginComponent implements OnInit {
    @ViewChild('form') form: NgForm;
    errorMsgs = [];
    subscriptions: Subscription[] = [];
    showLoader = false;
    selectedCRM;
    makeDefaultCRM = false;
    componentInView = new Subject();
    CRM_Options: SelectItem[] = [];
    isAddCRMDialogOpen = false;
    canSetDefaultCRM = false;

    constructor(private msgsService: MsgsService,
                private utilsService: UtilsService,
                private router: Router,
                private appService: AppService,
                private dialogRef: DynamicDialogRef,
                private dialogConfig: DynamicDialogConfig,
                private dialogService: DialogService) {
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.msgsService.errorMsgsUpdated.subscribe(errorMsgs => {
                this.errorMsgs = errorMsgs
            })
        );

        this.dialogConfig.data.CRM_Options.forEach(item => {
            if(item.registered) {
                this.CRM_Options.push({
                    label: item.crmDetails.displayName,
                    value: item,
                    disabled: !item.crmDetails.active
                });

                this.canSetDefaultCRM = true;
            }
        });
    }

    onNextClick() {
        this.utilsService.clearErrorMessages();

        if(!this.form.valid) {
            this.utilsService.showAllErrorMessages();
            return;
        }

        this.showLoader = true;

        if(this.makeDefaultCRM) {
            this.setDefaultCRM();
        }

        if(!this.makeDefaultCRM) {
            this.crmLogin(this.selectedCRM.crmDetails.apiKey);
        }
    }

    setDefaultCRM() {
        const crmName = this.selectedCRM.crmDetails.apiKey;

        this.subscriptions.push(
            this.appService.setDefaultCRM(crmName).subscribe(() => {
                this.crmLogin(this.selectedCRM.crmDetails.apiKey);
            }, error => {
                this.showLoader = false
                this.utilsService.handleError(error);
            })
        );
    }

    crmLogin(key) {
        this.subscriptions.push(
            this.appService.crmLogin(key).subscribe(() => {
                this.showLoader = false;
                localStorage.setItem('CRM' , JSON.stringify(this.selectedCRM));
                this.appService.getCRMConfigOptions();
                this.dialogRef.close();
                this.router.navigate(['./dashboard']);
            }, error => {
                this.showLoader = false
                this.utilsService.handleError(error);
            })
        );
    }

    onAddClick() {
        this.utilsService.clearErrorMessages();

        this.openAddCRMDialog();
    }

    openAddCRMDialog() {
        this.isAddCRMDialogOpen = true;

        this.dialogService.open(AddCrmComponent, {
            header: 'Add CRM',
            ...this.appService.dynamicDialogConfig,
            width: '480px',
            height: '380px'
        }).onClose.pipe(takeUntil(this.componentInView)).subscribe(() => {
            this.isAddCRMDialogOpen = false;
            this.utilsService.clearErrorMessages();
        });
    }
}
