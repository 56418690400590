<div  class="cis-summary-box" >
 <h1 style="text-align:center;">Summary</h1>
<div class="cis-summary-filters">
 <ng-container>
<div *ngIf="selectedFilters?.freeText">
  <label [ngStyle]='{"word-break":"break-word"}'><b> Text Search <button mat-icon-button class="close" color="primary" (click)="onRemoveFilter(Constants.TEXT_FILTER)"><mat-icon>close</mat-icon></button></b><br/></label>
  <mat-chip selected color="primary"> <b>Text: </b> <span style="margin-left: 10px;">{{selectedFilters?.freeText}}</span></mat-chip>
  <mat-chip selected color="primary"> <b>Search Type: </b> <span style="margin-left: 10px;">{{Constants.TEXT_SEARCH_OPTIONS[selectedFilters?.freeTextType]}}</span></mat-chip>
</div>

<div *ngFor="let field of selectedFilters?.fields | keyvalue">
  <label [ngStyle]='{"word-break":"break-word"}'><b>{{Constants.FILTERS_UI[field.key] + ' : ' }} <button mat-icon-button color="primary" class="close" (click)="onRemoveFilter(field.key)"><mat-icon>close</mat-icon></button></b><br/></label>
  <mat-chip-list class="mat-chip-list" aria-label="Color selection">
    <mat-chip *ngFor="let chip of getDisplayFiltersForField(field)" selected color="primary">
      {{chip}}
    </mat-chip>
  </mat-chip-list>
</div>
</ng-container>
</div>
</div>
