import {Directive, ElementRef, Renderer2} from '@angular/core';

@Directive({
    selector: '[oneitAutoComplete]'
})
export class AutoCompleteDirective {

    //DEPRECATED:::: Use <oneit-autoComplete>, which will fix this automatically.
    constructor(private _elRef: ElementRef, private _renderer: Renderer2) {
        setTimeout(() => {
            this._renderer.setAttribute(this._elRef.nativeElement.querySelector('.ui-autocomplete-dropdown'), 'disabled', 'disabled');
        }, 300);
    }
}
