export class ResetPassword {
    /* {OPEN:ResetPassword} {"sha1":"FEBF4CB0D05C7B854BE16043ACF6F27F82CF8A99"} */
    ObjectID : string;
    ObjectClass : string = 'c.orm.ResetPassword';
    IsNewObject : boolean;
    EnterPassword : string;
    ConfirmPassword : string;
    /* {CLOSE:ResetPassword} {"sha1":"FEBF4CB0D05C7B854BE16043ACF6F27F82CF8A99"} */
}

/* {OPEN:ADDIONAL_MODELS} {"sha1":"ADC83B19E793491B1C6EA0FD8B46CD9F32E592FC"} */
/* {CLOSE:ADDIONAL_MODELS} {"sha1":"ADC83B19E793491B1C6EA0FD8B46CD9F32E592FC"} */