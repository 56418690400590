import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IframeMsgHandlingService } from './../../services/iframe-msg-handling.service';
import { MenuService } from './../../services/menu.service';
import { MsgsService } from './../../services/msgs.service';
import { UtilsService } from './../../services/utils.service';
import { FullLayoutComponent } from './../full-layout/full-layout.component';

@Component({
    selector: 'app-iframe-page',
    templateUrl: './iframe-page.component.html',
    styleUrls: ['./iframe-page.component.scss']
})
export class IframePageComponent implements OnInit, OnDestroy {

    pageURL: string;
    pageLoaded: boolean;
    subscriptions: Array<Subscription> = [];
    showLoader: boolean =   true;

    constructor(
        private changeDetector: ChangeDetectorRef,
        public app: FullLayoutComponent,
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private utilsService: UtilsService,
        public menuService: MenuService,
        public msgsService: MsgsService,
        public iframeMsgHandlingService: IframeMsgHandlingService
    ) { }

    ngOnInit() {
        this.subscriptions.push(this.route.params.subscribe(
            (params: Params) => {
                if (params.url) {
                    this.updateLoader(true);
                    this.pageURL = decodeURIComponent(params.url);
                    this.changeDetector.detectChanges();
                    this.msgsService.clearErrorMessages();
                    this.pageLoaded = true;
                }
            }
        ));

        this.subscriptions.push(this.router.events.subscribe(
            (e: any) => {
                // If it is a NavigationEnd event re-initalise the component
                if (e instanceof NavigationEnd) {
                    if (!this.pageLoaded) { //Don't load if params already called
                        this.updateLoader(true);
                        this.pageURL = '';  //Dummy value - else iframe doesnt load when you try to load same url
                        this.changeDetector.detectChanges();
                        setTimeout(() => {
                            this.pageURL = decodeURIComponent(e.url.substring(13)); //Length 13 is based on route path: '/iframe-page/'
                            this.changeDetector.detectChanges();
                            this.msgsService.clearErrorMessages();
                        }, 100);
                    }
                    this.pageLoaded = false;
                }
            }
        ));

        this.subscriptions.push(this.iframeMsgHandlingService.msgReceived.subscribe(
            (message : any) => {
                if(message && message["name"]){
                    if(message["name"] === "loadingCompleted") {
                        this.updateLoader(false);
                    }
                    else if(message["name"] === "loadingStarted") {
                        this.updateLoader(true);
                    }
                }
            }
        ));
    }

    ngOnDestroy() {
        this.utilsService.unsubscribeSubscriptions(this.subscriptions);
    }

    onIframeClick() {
        this.app.onWrapperClick();
    }

    onLoad(iframe) {
        var iframeURL = iframe.contentWindow.location.href;
        if (iframeURL) {
            //this.location.go(this.router.createUrlTree(['../' + encodeURIComponent(iframeURL)], { relativeTo: this.route }).toString());   //So that refresh page works properly
        }
    }

    updateLoader(newValue: boolean){
        this.showLoader =   newValue;
        if(!this.showLoader){
            this.menuService.reloadMenuData(this.subscriptions);  //Reload menu only once loading is completed
        }
        this.changeDetector.detectChanges();
    }
}