/* {OPEN:IMPORT} {"sha1":"8642E0CDACF27B949C1C07F9F42200C404E42814"} */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseSavedSearchesComponent } from './base-saved-searches.component';
/* {CLOSE:IMPORT} {"sha1":"8642E0CDACF27B949C1C07F9F42200C404E42814"} */
import { NgForm } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { NavigationExtras } from '@angular/router';
/* {POSTCLOSE:IMPORT} {"sha1":"8642E0CDACF27B949C1C07F9F42200C404E42814"} */

/* {PREOPEN:CLASS} {"sha1":"5708FB180AE0B2129E1D877A56FA086B85436EEC"} */
@Component({
    selector: 'app-saved-searches',
    templateUrl: './saved-searches.component.html'
})
export class SavedSearchesComponent extends BaseSavedSearchesComponent implements OnInit, OnDestroy {
    /* {OPEN:CLASS} {} */

    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
        super.ngOnInit();
        /* {CLOSE:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
    }

    ngOnDestroy() {
        /* {OPEN:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
        super.ngOnDestroy();
        /* {CLOSE:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
    }

    /* {CLOSE:CLASS} {} */

    @ViewChild('form') form: NgForm;
    savedSearches = [];
    cacheFilters = {};
    dataProvider = "";

    filterMap = {
      'project_stages': 'stages',
      'project_contract_stage': 'stage',
      'project_categories': 'category',
      'project_development_types': 'development_type',
      'project_materials': 'material',
      'project_roles': 'role',
      'project_contract_types': 'contract_type',
      'project_geocode': 'geocode',
      'company_geocode': 'geocode'
  }

    //@override
    executeScreenSpecificInitCode() {
        this.dataProvider = this.appService.getSelectedDataProvider();
        this.getBarbourCacheFilters();
        this.getSavedSearches();
    }


    getBarbourCacheFilters() {
      this.showLoader = true;

        this.subscriptions.push(
            this.savedSearchesService.getCacheFilters().subscribe(data => {
                this.showLoader = false;
                this.cacheFilters = data;
                this.cacheFilters[this.dataProvider + '_stages'] = {...this.cacheFilters[this.dataProvider + '_contract_stage'],
                  ...this.cacheFilters[this.dataProvider + '_planning_stage'],
                  ...this.cacheFilters[this.dataProvider + '_stopped']}

              }, error => {
                this.showLoader = false;
                this.utilsService.handleError(error);
            })
        );
    }

    getSavedSearches() {
        this.showLoader = true;

        this.subscriptions.push(
            this.savedSearchesService.getSavedSearches().subscribe(data => {
                this.showLoader = false;
                this.savedSearches = data;
                this.setSavedSearches();
            }, error => {
                this.showLoader = false;
                this.utilsService.handleError(error);
            })
        );
    }

    setSavedSearches() {
        this.savedSearches.forEach(saveSearch => {
            saveSearch.setQueries = [];
            for ( const query in saveSearch.query ) {

                if(saveSearch.query[query].length > 0) {
                    saveSearch.query[query].forEach(item => {
                        saveSearch.setQueries.push({name: query , ...item});
                    });
                }

                if(!Array.isArray(saveSearch.query[query])) {
                    saveSearch.setQueries.push({name: query , ...saveSearch.query[query]});
                }
            }
        });
    }

    setOperatorValue(query) {
        if(query.name !== 'project_text' && query.name !== 'company_name' && query.name !== 'project_build_phase') {
            if(query.operator === '=') {
                return 'included ';
            }

            if(query.operator === '!=') {
                return 'excluded ';
            }

            if(query.operator === '..') {
                return 'range from ';
            }

            if(query.operator === '>') {
                return 'greater than ';
            }

            if(query.operator === '<') {
                return 'less than ';
            }

            if(query.operator === '>=') {
                return 'greater than and equal to ';
            }

            if(query.operator === '<=') {
                return 'less than and equal to ';
            }
        }
        return '';
    }

    convertQueryCodeToValue(query, codes) {

      if(!codes) return '';

      if(!this.filterMap[query]) return codes;

      let key = this.dataProvider + "_" + this.filterMap[query];
      let values =[];

      codes.forEach(element => {
        values.push( this.cacheFilters?.[key]?.[element]);
      });

      return values.join(', ');
    }
    onSearchClick(savedSearch) {
        this.utilsService.clearErrorMessages();

        this.router.navigate(['/dashboard/' + savedSearch.saved_search_id]);
    }
}
/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */
