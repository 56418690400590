<mat-form-field appearance="fill">
  <mat-label>From</mat-label>
  <input
    matInput
    [matDatepicker]="fromPicker"
    [matDatepickerFilter]="fromValidator"
    (ngModel)="(dateRange.from)"
    [value]="fromDate.value"
    (dateChange)="fromDateUpdate($event)"
  />
  <mat-hint>YYYY-MM-DD</mat-hint>
  <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
  <mat-datepicker #fromPicker></mat-datepicker>
</mat-form-field>
<br />
<mat-form-field appearance="fill">
  <mat-label>To</mat-label>
  <input
    matInput
    [matDatepicker]="toPicker"
    [matDatepickerFilter]="toValidator"
    (ngModel)="(dateRange.to)"
    [value]="toDate.value"
    (dateChange)="toDateUpdate($event)"
    placeholder="YYYY-MM-DD"
  />
  <mat-hint>YYYY-MM-DD</mat-hint>
  <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
  <mat-datepicker #toPicker></mat-datepicker>
</mat-form-field>
