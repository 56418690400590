/* {OPEN:IMPORT} {"sha1":"7DC662E716DD0C11CC742F617181371D4C78E6E3"} */
import { Component, DoCheck, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import { Message, MenuItem, ConfirmationService } from 'primeng/api';
import { AppService } from './../../app.service';
import { OptionsObservable, OptionsWrapper } from './../../oneit/models/options.model';
import { UserService } from './../../oneit/services/user.service';
import { UtilsService } from './../../oneit/services/utils.service';
import { SearchService } from './../../oneit/services/search.service';
import { CompanyService } from './company.service';
import { SearchCompany } from './company.model';
/* {CLOSE:IMPORT} {"sha1":"7DC662E716DD0C11CC742F617181371D4C78E6E3"} */

@Component({
    selector: 'app-base-company',
    template: ''
})
/* {PREOPEN:CLASS} {"sha1":"18244DC830F32950481D9A3A6946415FB3009067"} */
export class BaseCompanyComponent implements DoCheck, OnInit, OnDestroy {
    /* {OPEN:CLASS} {} */
    
    /* {OPEN:COMMON_VARS} {"sha1":"672A211DEEA9A3559D121CBDC5978BBA47BD6B7D"} */
    @Input() parent : any;
    pageTitle : string;
    /* {CLOSE:COMMON_VARS} {"sha1":"672A211DEEA9A3559D121CBDC5978BBA47BD6B7D"} */

    /* {OPEN:VARS} {"sha1":"A37C4B473FAFE047095B4886E999E7B3D010CDF8"} */
    searchCompany : SearchCompany = new SearchCompany();
    activeTabIndex : number = 0;
    roles : any[] = [];
    rolesTableSelectionMode : string;
    rolesTableCols : any[] = [];
    accounts : any[] = [];
    accountsTableSelectionMode : string;
    accountsTableCols : any[] = [];
    updates : any[] = [];
    updatesTableSelectionMode : string;
    updatesTableCols : any[] = [];
    /* {CLOSE:VARS} {"sha1":"A37C4B473FAFE047095B4886E999E7B3D010CDF8"} */
    
    showResults : boolean = false;
    showLoader : boolean = false;
    subscriptions : Array<Subscription> = [];

    constructor(
        /* {OPEN:constructor} {"sha1":"696DCE19341FECF74B987E7D6A7B95CF6CB2180D"} */
        public router : Router,
        public userService : UserService,
        public utilsService : UtilsService,
        public searchService : SearchService,
        public appService : AppService,
        public confirmationService: ConfirmationService,
        public companyService : CompanyService,
        /* {CLOSE:constructor} {"sha1":"696DCE19341FECF74B987E7D6A7B95CF6CB2180D"} */
    ){
        
    }

    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"5FBDAAB044BC4F999356808F755A7334E11EE273"} */
        this.resetInitVariables();
        this.calculateEverything();
        this.executeScreenSpecificInitCode();
        /* {CLOSE:ngOnInit} {"sha1":"5FBDAAB044BC4F999356808F755A7334E11EE273"} */
    }

    /* {PREOPEN:executeScreenSpecificInitCode} {"sha1":"DDD081544316B33777AB7B7FFD796FB9E663E681"} */
    executeScreenSpecificInitCode() {
        /* {OPEN:executeScreenSpecificInitCode} {"sha1":"54DB41AD32937D86C7318EB4BE666A1AD7B6B618"} */
        if(!this.parent) {
        }
        /* {CLOSE:executeScreenSpecificInitCode} {"sha1":"54DB41AD32937D86C7318EB4BE666A1AD7B6B618"} */
    }
    /* {POSTCLOSE:executeScreenSpecificInitCode} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onCloseClick} {"sha1":"E7E8FC2E7EAE518D6FC89C8AD58EDF692C8B6632"} */
    onCloseClick(event, searchCompany) {
        /* {OPEN:onCloseClick} {"sha1":"999E5462196194ACACE528B609E0E94624B9574A"} */
        this.router.navigate(['/search-projects']);
        /* {CLOSE:onCloseClick} {"sha1":"999E5462196194ACACE528B609E0E94624B9574A"} */
    }
    /* {POSTCLOSE:onCloseClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onCreateAccountClick} {"sha1":"601CEDE1C86E4592013022C006D8550B0A744A38"} */
    onCreateAccountClick(event, searchCompany) {
        /* {OPEN:onCreateAccountClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onCreateAccountClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onCreateAccountClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onStarClick} {"sha1":"DE58617DEF027ADCE7D4889EA89322AD9242CFAC"} */
    onStarClick(event, searchCompany) {
        /* {OPEN:onStarClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onStarClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onStarClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onComClick} {"sha1":"DB7370149495F996D435AB8113E2913FF3F8CC59"} */
    onComClick(event, searchCompany) {
        /* {OPEN:onComClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onComClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onComClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onTabChange} {"sha1":"F5FCE0B8C2A188B8B7A86B47A78F70DE945E6266"} */
    onTabChange(event, searchCompany) : void {
        /* {OPEN:onTabChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:onTabChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:onTabChange} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRolesTableCols} {"sha1":"7AB572CFB446ED34B4C6A69E55F2476E3AAF422A"} */
    getRolesTableCols() : any[] {
        /* {OPEN:getRolesTableCols} {"sha1":"A3D99DC817F9CAC03199EEE8569A81F545226C3E"} */
        return [
            { field: 'Agent', header: 'Role', width: '10px', noSort: false, noFilter: false },
            { field: 'StamfordHouseRetailUnits', header: 'Project', width: '22px', noSort: false, noFilter: false },
            { field: 'NoelDillon', header: 'Contact', width: '10px', noSort: false, noFilter: false }
        ];
        /* {CLOSE:getRolesTableCols} {"sha1":"A3D99DC817F9CAC03199EEE8569A81F545226C3E"} */
    }
    /* {POSTCLOSE:getRolesTableCols} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRoles} {"sha1":"916EE16C237921BB4226EEAFF49A6EF60DC42077"} */
    getRoles(saveSearch: boolean) {
        /* {OPEN:getRoles} {"sha1":"0AF9BEA461D32B7F6820EDACDEFD79B417429DBE"} */
        this.showResults = true;
        this.showLoader = true;
        let assocs = [];
        
        this.subscriptions.push(this.getRolesObservable(saveSearch)
            .subscribe(
                data => {
                    this.roles = data;
                    this.postRolesTableChange();
                    this.showLoader = false;
                },
                error => {
                    this.showLoader = false;
                    this.utilsService.handleError(error);
                }
            )
        );
        /* {CLOSE:getRoles} {"sha1":"0AF9BEA461D32B7F6820EDACDEFD79B417429DBE"} */
    }
    /* {POSTCLOSE:getRoles} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:postRolesTableChange} {"sha1":"A0091AEDE6049EDE55A2D6D4928B55F7CC4C00C6"} */
    postRolesTableChange() {
        /* {OPEN:postRolesTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:postRolesTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:postRolesTableChange} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRolesObservable} {"sha1":"FEF1C894552009E3E7BA1A44CC799EFE9B3666B7"} */
    getRolesObservable(saveSearch): Observable<any> {
        /* {OPEN:getRolesObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
        return of({});
        /* {CLOSE:getRolesObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
    }
    /* {POSTCLOSE:getRolesObservable} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onRolesTableRowSelect} {"sha1":"31F763447C56BE842B67D1F96AB8354C45F75545"} */
    onRolesTableRowSelect(data) {
        /* {OPEN:onRolesTableRowSelect} {"sha1":"76FFDDBB12D60FFC8CC2440FDE7CBF1C3ED13329"} */
        this.router.navigate(['/project']);
        /* {CLOSE:onRolesTableRowSelect} {"sha1":"76FFDDBB12D60FFC8CC2440FDE7CBF1C3ED13329"} */
    }
    /* {POSTCLOSE:onRolesTableRowSelect} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRolesTableSelectionMode} {"sha1":"F2C708D4032B583069DCA398783CA1AE40A625B9"} */
    getRolesTableSelectionMode() : string {
        /* {OPEN:getRolesTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
        return null;
        /* {CLOSE:getRolesTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
    }
    /* {POSTCLOSE:getRolesTableSelectionMode} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRouterLinkForRolesTableStamfordHouseRetailUnits} {"sha1":"F0A278AAEC9128CA97EA382FD525A1763670DED4"} */
    getRouterLinkForRolesTableStamfordHouseRetailUnits(rowData) {
        /* {OPEN:getRouterLinkForRolesTableStamfordHouseRetailUnits} {"sha1":"59FA4B2AD9C5B4E0012DA0C1CC3DA22AE8ED0C36"} */
        return ['/project'];
        /* {CLOSE:getRouterLinkForRolesTableStamfordHouseRetailUnits} {"sha1":"59FA4B2AD9C5B4E0012DA0C1CC3DA22AE8ED0C36"} */
    }
    /* {POSTCLOSE:getRouterLinkForRolesTableStamfordHouseRetailUnits} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRouterLinkForRolesTableNoelDillon} {"sha1":"F344183D6D377B6773E5D025E124F162FD965521"} */
    getRouterLinkForRolesTableNoelDillon(rowData) {
        /* {OPEN:getRouterLinkForRolesTableNoelDillon} {"sha1":"821AEF0F59530E8800243B34B9DDDB04C4DC5857"} */
        return ['/contacts'];
        /* {CLOSE:getRouterLinkForRolesTableNoelDillon} {"sha1":"821AEF0F59530E8800243B34B9DDDB04C4DC5857"} */
    }
    /* {POSTCLOSE:getRouterLinkForRolesTableNoelDillon} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getAccountsTableCols} {"sha1":"8DD0D8BFEF6E6667F89EE6CB6EC901BED68A6DCE"} */
    getAccountsTableCols() : any[] {
        /* {OPEN:getAccountsTableCols} {"sha1":"B2C98F5253C8AFEE3FB6FB50DF90E3776512C7F4"} */
        return [
            { field: 'Abhinav', header: 'Accounts', width: '10px', noSort: true, noFilter: true },
            { field: 'AbhinavGandhiabhinavconstructionbos.com', header: 'Owner', width: '10px', noSort: true, noFilter: true },
            { field: 'March2021', header: 'Created', width: '10px', noSort: true, noFilter: true }
        ];
        /* {CLOSE:getAccountsTableCols} {"sha1":"B2C98F5253C8AFEE3FB6FB50DF90E3776512C7F4"} */
    }
    /* {POSTCLOSE:getAccountsTableCols} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getAccounts} {"sha1":"C86D56ED6CBF5BE45C353087AD7D54DD42BB6976"} */
    getAccounts(saveSearch: boolean) {
        /* {OPEN:getAccounts} {"sha1":"99907D7FA3A5EADFFF08BEA5785A9E3F5DE05BC8"} */
        this.showResults = true;
        this.showLoader = true;
        let assocs = [];
        
        this.subscriptions.push(this.getAccountsObservable(saveSearch)
            .subscribe(
                data => {
                    this.accounts = data;
                    this.postAccountsTableChange();
                    this.showLoader = false;
                },
                error => {
                    this.showLoader = false;
                    this.utilsService.handleError(error);
                }
            )
        );
        /* {CLOSE:getAccounts} {"sha1":"99907D7FA3A5EADFFF08BEA5785A9E3F5DE05BC8"} */
    }
    /* {POSTCLOSE:getAccounts} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:postAccountsTableChange} {"sha1":"A572A0B7B534CC983123EAC8B7E6C1861F8B9287"} */
    postAccountsTableChange() {
        /* {OPEN:postAccountsTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:postAccountsTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:postAccountsTableChange} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getAccountsObservable} {"sha1":"E5D3A0877AEFF0FA443B19D8B5851BD8C7CFCBE7"} */
    getAccountsObservable(saveSearch): Observable<any> {
        /* {OPEN:getAccountsObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
        return of({});
        /* {CLOSE:getAccountsObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
    }
    /* {POSTCLOSE:getAccountsObservable} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onAccountsTableRowSelect} {"sha1":"CDD89015E92D3826F808850167B2A7F3D20AC495"} */
    onAccountsTableRowSelect(data) {
        /* {OPEN:onAccountsTableRowSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:onAccountsTableRowSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:onAccountsTableRowSelect} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getAccountsTableSelectionMode} {"sha1":"523470672B30022D5578AA3BC3CB69468A36CF2B"} */
    getAccountsTableSelectionMode() : string {
        /* {OPEN:getAccountsTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
        return null;
        /* {CLOSE:getAccountsTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
    }
    /* {POSTCLOSE:getAccountsTableSelectionMode} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getUpdatesTableCols} {"sha1":"77BF723B53A4DAF7D8A32DC6F8C1DB7FC8828BA2"} */
    getUpdatesTableCols() : any[] {
        /* {OPEN:getUpdatesTableCols} {"sha1":"7F9F8173A596BAA9F4D1B4E8E614B91623838F78"} */
        return [
            { field: 'AddedasBidderonprojectImperialCollegeLondonOfficeRefurbishmentandVaults1120', header: 'Description', width: '10px', noSort: false, noFilter: false },
            { field: 'Mar2021', header: 'Date', width: '2px', noSort: false, noFilter: false }
        ];
        /* {CLOSE:getUpdatesTableCols} {"sha1":"7F9F8173A596BAA9F4D1B4E8E614B91623838F78"} */
    }
    /* {POSTCLOSE:getUpdatesTableCols} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getUpdates} {"sha1":"F23EFB6C096D4E0C97DCD4D608380ED64C552250"} */
    getUpdates(saveSearch: boolean) {
        /* {OPEN:getUpdates} {"sha1":"280E88F5540D029EDD8AF127E490A2B019AAFD16"} */
        this.showResults = true;
        this.showLoader = true;
        let assocs = [];
        
        this.subscriptions.push(this.getUpdatesObservable(saveSearch)
            .subscribe(
                data => {
                    this.updates = data;
                    this.postUpdatesTableChange();
                    this.showLoader = false;
                },
                error => {
                    this.showLoader = false;
                    this.utilsService.handleError(error);
                }
            )
        );
        /* {CLOSE:getUpdates} {"sha1":"280E88F5540D029EDD8AF127E490A2B019AAFD16"} */
    }
    /* {POSTCLOSE:getUpdates} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:postUpdatesTableChange} {"sha1":"F6285CC944FB7CF6CF567F290D47AF5D4E1B397D"} */
    postUpdatesTableChange() {
        /* {OPEN:postUpdatesTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:postUpdatesTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:postUpdatesTableChange} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getUpdatesObservable} {"sha1":"7BA82B464641FA25EA3DA60F4DFD3C5510298D32"} */
    getUpdatesObservable(saveSearch): Observable<any> {
        /* {OPEN:getUpdatesObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
        return of({});
        /* {CLOSE:getUpdatesObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
    }
    /* {POSTCLOSE:getUpdatesObservable} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onUpdatesTableRowSelect} {"sha1":"FB713E6D4EA6F9150549C6D61AB92AD585E7F272"} */
    onUpdatesTableRowSelect(data) {
        /* {OPEN:onUpdatesTableRowSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:onUpdatesTableRowSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:onUpdatesTableRowSelect} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getUpdatesTableSelectionMode} {"sha1":"2792FF8A27DF63E62719BCE5EFA4D63F6987B55B"} */
    getUpdatesTableSelectionMode() : string {
        /* {OPEN:getUpdatesTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
        return null;
        /* {CLOSE:getUpdatesTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
    }
    /* {POSTCLOSE:getUpdatesTableSelectionMode} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:resetInitVariables} {"sha1":"846C0DF64879342578D595FE17BC00B96F0BAED6"} */
    resetInitVariables() {
        /* {OPEN:resetInitVariables} {} */
        /* {OPEN:resetInitVariables_rolesTableCols} {"sha1":"641EC665968ACB1C67A018C17B85E33EBEDD6474"} */
        try {
            this.rolesTableCols = this.getRolesTableCols();
        }
        catch (error) {
            console.error('Error occured when setting rolesTableCols', error);
        }
        /* {CLOSE:resetInitVariables_rolesTableCols} {"sha1":"641EC665968ACB1C67A018C17B85E33EBEDD6474"} */
        /* {OPEN:resetInitVariables_rolesTableSelectionMode} {"sha1":"7B14C85E38A139B0A9F42C09D35592D9558207C0"} */
        try {
            this.rolesTableSelectionMode = this.getRolesTableSelectionMode();
        }
        catch (error) {
            console.error('Error occured when setting rolesTableSelectionMode', error);
        }
        /* {CLOSE:resetInitVariables_rolesTableSelectionMode} {"sha1":"7B14C85E38A139B0A9F42C09D35592D9558207C0"} */
        /* {OPEN:resetInitVariables_accountsTableCols} {"sha1":"9FB0D0137C4951FE7FE94D49270E872A27D4A5DB"} */
        try {
            this.accountsTableCols = this.getAccountsTableCols();
        }
        catch (error) {
            console.error('Error occured when setting accountsTableCols', error);
        }
        /* {CLOSE:resetInitVariables_accountsTableCols} {"sha1":"9FB0D0137C4951FE7FE94D49270E872A27D4A5DB"} */
        /* {OPEN:resetInitVariables_accountsTableSelectionMode} {"sha1":"C729CA43F19AD0BCC6BB2A934E1762351BDAAA5E"} */
        try {
            this.accountsTableSelectionMode = this.getAccountsTableSelectionMode();
        }
        catch (error) {
            console.error('Error occured when setting accountsTableSelectionMode', error);
        }
        /* {CLOSE:resetInitVariables_accountsTableSelectionMode} {"sha1":"C729CA43F19AD0BCC6BB2A934E1762351BDAAA5E"} */
        /* {OPEN:resetInitVariables_updatesTableCols} {"sha1":"A0E697BC8A24E5F033450AADDA8BA93DA4B8F45C"} */
        try {
            this.updatesTableCols = this.getUpdatesTableCols();
        }
        catch (error) {
            console.error('Error occured when setting updatesTableCols', error);
        }
        /* {CLOSE:resetInitVariables_updatesTableCols} {"sha1":"A0E697BC8A24E5F033450AADDA8BA93DA4B8F45C"} */
        /* {OPEN:resetInitVariables_updatesTableSelectionMode} {"sha1":"E9A366478814CF30731ED3490E6FA9AA5ED8EE50"} */
        try {
            this.updatesTableSelectionMode = this.getUpdatesTableSelectionMode();
        }
        catch (error) {
            console.error('Error occured when setting updatesTableSelectionMode', error);
        }
        /* {CLOSE:resetInitVariables_updatesTableSelectionMode} {"sha1":"E9A366478814CF30731ED3490E6FA9AA5ED8EE50"} */
        /* {CLOSE:resetInitVariables} {} */
    }
    /* {POSTCLOSE:resetInitVariables} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getPageTitle} {"sha1":"174A3D30FFA103DD7BE180EE89CC0CF5EB6D11B8"} */
    getPageTitle() {
        /* {OPEN:getPageTitle} {"sha1":"5B751B4A19B2BE49F7E0F4AC4E3DFC8D691E3A2F"} */
        return 'Company';
        /* {CLOSE:getPageTitle} {"sha1":"5B751B4A19B2BE49F7E0F4AC4E3DFC8D691E3A2F"} */
    }
    /* {POSTCLOSE:getPageTitle} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:calculateEverything} {"sha1":"CE17BF2375171825A37C544A1DCBF3A565DD5181"} */
    calculateEverything() {
        /* {OPEN:calculateEverything} {} */
        /* {OPEN:calculateEverything_pageTitle} {"sha1":"B3CA5EAE3ED54D1F0D95EA2CD05351A4A6D7480F"} */
        try {
            this.pageTitle = this.getPageTitle();
        }
        catch (error) {
            console.error('Error occured when setting pageTitle');
        }
        /* {CLOSE:calculateEverything_pageTitle} {"sha1":"B3CA5EAE3ED54D1F0D95EA2CD05351A4A6D7480F"} */
        /* {CLOSE:calculateEverything} {} */
    }
    /* {POSTCLOSE:calculateEverything} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:ngDoCheck} {"sha1":"75839B27D41E20648CE296AC51D61E24D541A70A"} */
    ngDoCheck() {
        /* {OPEN:ngDoCheck} {"sha1":"A154E0663059B6D385B4F6C11094F2084FF5E184"} */
        this.calculateEverything();
        /* {CLOSE:ngDoCheck} {"sha1":"A154E0663059B6D385B4F6C11094F2084FF5E184"} */
    }
    /* {POSTCLOSE:ngDoCheck} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:ngOnDestroy} {"sha1":"C21B2137D2BD86DF7050E24A39F08355F04DA4E9"} */
    ngOnDestroy() {
        /* {OPEN:ngOnDestroy} {"sha1":"85AA65230B2C97013ABDD30D48BEB93C04E8EE54"} */
        this.utilsService.unsubscribeSubscriptions(this.subscriptions);
        /* {CLOSE:ngOnDestroy} {"sha1":"85AA65230B2C97013ABDD30D48BEB93C04E8EE54"} */
    }
    /* {POSTCLOSE:ngOnDestroy} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {CLOSE:CLASS} {} */
}
/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */