<!-- {PREOPEN:291e5c11-30f0-4e94-b5b8-ac1545c44357} {"sha1":"8C4A0E2E0EFD768CAB5850B359AF669A56A45AB4"} -->
<form #form="ngForm" [class.pointer-events-none]="this.showLoader">
    <!-- {OPEN:291e5c11-30f0-4e94-b5b8-ac1545c44357} {} -->
    <!-- {OPEN:e14bd646-aa44-4d95-96c1-42858cf12c74} {} -->
    <!-- {PREOPEN:3f6d4670-0dd3-4af1-8de3-6b5e362261dc} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
    <div class="ui-g form-group">
        <!-- {OPEN:3f6d4670-0dd3-4af1-8de3-6b5e362261dc} {} -->
        <!-- {PREOPEN:24363f02-c6c6-4907-a217-ae8d3032af8d} {"sha1":"2183A3A9ABCF2FD844436EF69F76172FF147BB12"} -->
        <!-- {POSTCLOSE:24363f02-c6c6-4907-a217-ae8d3032af8d} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:cc05189b-67eb-4692-a6c8-e2e721bac7ea} {"sha1":"2183A3A9ABCF2FD844436EF69F76172FF147BB12"} -->
        <div class="ui-g-12 ui-md-12">
            <!-- {OPEN:cc05189b-67eb-4692-a6c8-e2e721bac7ea} {} -->
            <!-- {OPEN:fc45e4d7-ecfa-4b63-a409-1e21b8e1ec4f} {"sha1":"3EC40982E53CE2256FAB77208320B4A50436F32E"} -->
            <div class="ui-fluid">
                <span class="ui-float-label sticky">
                    <p-dropdown [autoDisplayFirst]="false" required [(ngModel)]="searchCrm.CRM" name="CRM{{ searchCrm.ObjectID }}" fieldLabel="CRM" optionLabel="crmDetails.displayName" (onChange)="onCRMChange($event, searchCrm)" [options]="cRMOptions"></p-dropdown>
                    <label>CRM</label>
                </span>
            </div>
            <!-- {CLOSE:fc45e4d7-ecfa-4b63-a409-1e21b8e1ec4f} {"sha1":"3EC40982E53CE2256FAB77208320B4A50436F32E"} -->
            <!-- {CLOSE:cc05189b-67eb-4692-a6c8-e2e721bac7ea} {} -->
        </div>
        <!-- {POSTCLOSE:cc05189b-67eb-4692-a6c8-e2e721bac7ea} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:83945a72-a81c-48e5-8705-65d8b6e63f39} {"sha1":"2183A3A9ABCF2FD844436EF69F76172FF147BB12"} -->
        <!-- {POSTCLOSE:83945a72-a81c-48e5-8705-65d8b6e63f39} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {CLOSE:3f6d4670-0dd3-4af1-8de3-6b5e362261dc} {} -->
    </div>
    <!-- {POSTCLOSE:3f6d4670-0dd3-4af1-8de3-6b5e362261dc} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
    <!-- {PREOPEN:e998ced0-205e-409f-bb3e-1fd09ec520fa} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
    <div class="ui-g form-group">
        <!-- {OPEN:e998ced0-205e-409f-bb3e-1fd09ec520fa} {} -->
        <!-- {PREOPEN:66b24ad2-7a52-471c-b23b-265c2dfbf067} {"sha1":"2183A3A9ABCF2FD844436EF69F76172FF147BB12"} -->
        <div class="ui-g-12 ui-md-4">
            <!-- {OPEN:66b24ad2-7a52-471c-b23b-265c2dfbf067} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
            <!-- {CLOSE:66b24ad2-7a52-471c-b23b-265c2dfbf067} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
        </div>
        <!-- {POSTCLOSE:66b24ad2-7a52-471c-b23b-265c2dfbf067} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:32e6c212-556f-4371-8aeb-6f2096b6aa6c} {"sha1":"2183A3A9ABCF2FD844436EF69F76172FF147BB12"} -->
        <!-- {POSTCLOSE:32e6c212-556f-4371-8aeb-6f2096b6aa6c} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:1bbf733f-53e4-44a4-8d84-76424c0a8f7f} {"sha1":"2183A3A9ABCF2FD844436EF69F76172FF147BB12"} -->
        <!-- {POSTCLOSE:1bbf733f-53e4-44a4-8d84-76424c0a8f7f} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {CLOSE:e998ced0-205e-409f-bb3e-1fd09ec520fa} {} -->
    </div>
    <!-- {POSTCLOSE:e998ced0-205e-409f-bb3e-1fd09ec520fa} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
    <!-- {PREOPEN:89cdb482-1d07-424f-80cf-237f6c959c2d} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
    <!-- {POSTCLOSE:89cdb482-1d07-424f-80cf-237f6c959c2d} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
    <!-- {PREOPEN:6019d68e-df67-499c-bcfc-d5862c839d40} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
    <div class="ui-g form-group">
        <!-- {OPEN:6019d68e-df67-499c-bcfc-d5862c839d40} {} -->
        <!-- {PREOPEN:cd0a0e02-3973-4ee7-93c1-1f795b8b8e17} {"sha1":"2183A3A9ABCF2FD844436EF69F76172FF147BB12"} -->
        <div class="ui-g-12 ui-md-4">
            <!-- {OPEN:cd0a0e02-3973-4ee7-93c1-1f795b8b8e17} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
            <!-- {CLOSE:cd0a0e02-3973-4ee7-93c1-1f795b8b8e17} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
        </div>
        <!-- {POSTCLOSE:cd0a0e02-3973-4ee7-93c1-1f795b8b8e17} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:183b5293-6887-4787-b3dc-fe6010950ace} {"sha1":"2183A3A9ABCF2FD844436EF69F76172FF147BB12"} -->
        <div class="ui-g-12 ui-md-4">
            <!-- {OPEN:183b5293-6887-4787-b3dc-fe6010950ace} {} -->
            <!-- {OPEN:f38b4bf7-4261-4113-8812-b0117fbb57b8} {"sha1":"A8F35D3DA5001D13C0FC0B9809C21CFEE0B5BCB2"} -->
            <div class="ui-fluid">
                <button pButton type="button" label="Add" (click)="onAddClick($event, searchCrm)"></button>
            </div>
            <!-- {CLOSE:f38b4bf7-4261-4113-8812-b0117fbb57b8} {"sha1":"A8F35D3DA5001D13C0FC0B9809C21CFEE0B5BCB2"} -->
            <!-- {CLOSE:183b5293-6887-4787-b3dc-fe6010950ace} {} -->
        </div>
        <!-- {POSTCLOSE:183b5293-6887-4787-b3dc-fe6010950ace} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:7a6dc2ae-034e-4eeb-ae16-7cab05813d80} {"sha1":"2183A3A9ABCF2FD844436EF69F76172FF147BB12"} -->
        <div class="ui-g-12 ui-md-4">
            <!-- {OPEN:7a6dc2ae-034e-4eeb-ae16-7cab05813d80} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
            <!-- {CLOSE:7a6dc2ae-034e-4eeb-ae16-7cab05813d80} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
        </div>
        <!-- {POSTCLOSE:7a6dc2ae-034e-4eeb-ae16-7cab05813d80} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {CLOSE:6019d68e-df67-499c-bcfc-d5862c839d40} {} -->
    </div>
    <!-- {POSTCLOSE:6019d68e-df67-499c-bcfc-d5862c839d40} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
    <!-- {CLOSE:e14bd646-aa44-4d95-96c1-42858cf12c74} {} -->
    <p-progressSpinner *ngIf="showLoader"></p-progressSpinner>
    <!-- {CLOSE:291e5c11-30f0-4e94-b5b8-ac1545c44357} {} -->
</form>
<!-- {POSTCLOSE:291e5c11-30f0-4e94-b5b8-ac1545c44357} {"sha1":"3FD6E659562C1876D02FC8CF54746B1502442CD2"} -->
