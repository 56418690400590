<!-- {PREOPEN:8460368b-224f-4c85-800c-e9bc7939ba3e} {"sha1":"8C4A0E2E0EFD768CAB5850B359AF669A56A45AB4"} -->
<form #form="ngForm" [class.pointer-events-none]="this.showLoader">
	<!-- {OPEN:8460368b-224f-4c85-800c-e9bc7939ba3e} {} -->
	<!-- {OPEN:Toolbar} {"sha1":"58C85CCF9FF02F3BAA012023D0982BED8C08259E"} -->
	<div class="ui-g form-group">
		<!-- {OPEN:3331df83-5e70-448b-8a00-c43a4917659e} {} -->
		<!-- {PREOPEN:0ee2cad0-45fc-4d7e-b0e4-efd2170fe06a} {"sha1":"F527A837874DC25F83449B563E33DC65962148CE"} -->
		<div class="ui-g-12 ui-md-2">
			<!-- {OPEN:0ee2cad0-45fc-4d7e-b0e4-efd2170fe06a} {} -->
			<!-- {OPEN:3d2f4422-0a00-4e2e-8950-5d3d606de438} {"sha1":"69D8D9EE62B2204B6FBBD2B26E9D0755A37A4095"} -->
			<button pButton type="button" label="Create Deals" icon="ui-icon-assignment-turned-in" (click)="onCreateDealsClick($event, searchTableView)"></button>
			<!-- {CLOSE:3d2f4422-0a00-4e2e-8950-5d3d606de438} {"sha1":"69D8D9EE62B2204B6FBBD2B26E9D0755A37A4095"} -->
			<!-- {CLOSE:0ee2cad0-45fc-4d7e-b0e4-efd2170fe06a} {} -->
		</div>
		<!-- {POSTCLOSE:0ee2cad0-45fc-4d7e-b0e4-efd2170fe06a} {"sha1":"C4A15ED98D4B138EEB595266FB1CF4C91CF45CA5"} -->
		<!-- {PREOPEN:e437ea26-5fc7-40ca-bca1-fd023e85b825} {"sha1":"3667EDCEE4A1EEFBEB428C1F8C7DD175186DC0E7"} -->
		<div class="ui-g-12 ui-md-2">
			<!-- {OPEN:e437ea26-5fc7-40ca-bca1-fd023e85b825} {} -->
			<!-- {OPEN:a91c218b-500b-4310-aaff-25ae2cca592e} {"sha1":"08CFDE2E28BE71D87D683F451A72CB91D48B242D"} -->
			<app-button-dropdown [model]="exportBtnItems" icon="ui-icon-arrow-drop-down"  label="Export"></app-button-dropdown>
			<!-- {CLOSE:a91c218b-500b-4310-aaff-25ae2cca592e} {"sha1":"08CFDE2E28BE71D87D683F451A72CB91D48B242D"} -->
			<!-- {CLOSE:e437ea26-5fc7-40ca-bca1-fd023e85b825} {} -->
		</div>
		<!-- {POSTCLOSE:e437ea26-5fc7-40ca-bca1-fd023e85b825} {"sha1":"C4A15ED98D4B138EEB595266FB1CF4C91CF45CA5"} -->
		<!-- {PREOPEN:2bb96b74-658b-4af3-8c4e-568216764fe4} {"sha1":"3667EDCEE4A1EEFBEB428C1F8C7DD175186DC0E7"} -->
		<!-- {POSTCLOSE:2bb96b74-658b-4af3-8c4e-568216764fe4} {"sha1":"C4A15ED98D4B138EEB595266FB1CF4C91CF45CA5"} -->
		<!-- {PREOPEN:e4f8c444-667f-4967-8309-0e2c5a23fca2} {"sha1":"6B3C70482CE451DD174A943ADB1ADDD064DE62FE"} -->
		<div class="ui-g-12 ui-md-6">
			<!-- {OPEN:e4f8c444-667f-4967-8309-0e2c5a23fca2} {} -->
			<!-- {OPEN:d941f238-7c23-4426-bf08-565a73cf755c} {} -->
			<!-- {PREOPEN:62d19a4c-f554-46f1-b52d-004ee9ae9d6e} {"sha1":"8E47AF93A0EDFE97B55BAA3CF5DF54E9C0946D9E"} -->
			<div class="ui-g form-group">
				<!-- {OPEN:62d19a4c-f554-46f1-b52d-004ee9ae9d6e} {} -->
				<!-- {PREOPEN:ea350d61-f5f3-4a2b-aad9-713e04cba39e} {"sha1":"78E0CE1766DCF3655F1631C109A5444307DCF9C9"} -->
				<!-- {POSTCLOSE:ea350d61-f5f3-4a2b-aad9-713e04cba39e} {"sha1":"079B33F711D32A1BDB5DD1A942BDD82885981728"} -->
				<!-- {PREOPEN:cfe7e3b0-6f97-40be-af3a-0de9b37edcb5} {"sha1":"78E0CE1766DCF3655F1631C109A5444307DCF9C9"} -->
				<div class="ui-g-12 ui-md-2">
					<!-- {OPEN:cfe7e3b0-6f97-40be-af3a-0de9b37edcb5} {} -->
					<!-- {OPEN:bd9070b4-962f-4338-8fe2-04ede5561496} {"sha1":"B10EC1E80A6241254A72ABEFFA507BDDDDC66DE0"} -->
					<div [style.paddingTop.px]="'10'" [style.paddingLeft.px]="'10'" [style.paddingBottom.px]="'10'" [style.paddingRight.px]="'10'" [style.background-color]="'#27A0FC'">
						<label></label>
					</div>
					<!-- {CLOSE:bd9070b4-962f-4338-8fe2-04ede5561496} {"sha1":"B10EC1E80A6241254A72ABEFFA507BDDDDC66DE0"} -->
					<!-- {CLOSE:cfe7e3b0-6f97-40be-af3a-0de9b37edcb5} {} -->
				</div>
				<!-- {POSTCLOSE:cfe7e3b0-6f97-40be-af3a-0de9b37edcb5} {"sha1":"079B33F711D32A1BDB5DD1A942BDD82885981728"} -->
				<!-- {PREOPEN:cf7d9e52-383d-4140-9f0d-c32fb065b688} {"sha1":"97695D77D6186CB247653410228A6EC5287DC523"} -->
				<div class="ui-g-12 ui-md-3">
					<!-- {OPEN:cf7d9e52-383d-4140-9f0d-c32fb065b688} {} -->
					<!-- {OPEN:d0afebed-f1fe-4d20-bc22-65ea891c7403} {"sha1":"4E9AE35DC5111938852E766681BD22DEA6F15B18"} -->
					<label>Converted</label>
					<!-- {CLOSE:d0afebed-f1fe-4d20-bc22-65ea891c7403} {"sha1":"4E9AE35DC5111938852E766681BD22DEA6F15B18"} -->
					<!-- {CLOSE:cf7d9e52-383d-4140-9f0d-c32fb065b688} {} -->
				</div>
				<!-- {POSTCLOSE:cf7d9e52-383d-4140-9f0d-c32fb065b688} {"sha1":"079B33F711D32A1BDB5DD1A942BDD82885981728"} -->
				<!-- {PREOPEN:aa785f77-8253-4364-a33e-f17bff61c153} {"sha1":"78E0CE1766DCF3655F1631C109A5444307DCF9C9"} -->
				<!-- {POSTCLOSE:19a523bf-ff51-42be-b72a-5ff747e2b872} {"sha1":"079B33F711D32A1BDB5DD1A942BDD82885981728"} -->
				<!-- {CLOSE:62d19a4c-f554-46f1-b52d-004ee9ae9d6e} {} -->
			</div>
			<!-- {POSTCLOSE:62d19a4c-f554-46f1-b52d-004ee9ae9d6e} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
			<!-- {CLOSE:d941f238-7c23-4426-bf08-565a73cf755c} {} -->
			<!-- {CLOSE:e4f8c444-667f-4967-8309-0e2c5a23fca2} {} -->
		</div>
		<!-- {POSTCLOSE:e4f8c444-667f-4967-8309-0e2c5a23fca2} {"sha1":"C4A15ED98D4B138EEB595266FB1CF4C91CF45CA5"} -->
		<!-- {PREOPEN:5a82450d-bbb3-4049-8f06-39f5e69254af} {"sha1":"F527A837874DC25F83449B563E33DC65962148CE"} -->
		<div class="ui-g-12 ui-md-2">
			<!-- {OPEN:5a82450d-bbb3-4049-8f06-39f5e69254af} {} -->
			<!-- {OPEN:e66d67c5-5192-49d4-abda-9ac624261013} {} -->
			<!-- {PREOPEN:e30050f2-f869-4fcf-96b2-c3c7c74c2db0} {"sha1":"8E47AF93A0EDFE97B55BAA3CF5DF54E9C0946D9E"} -->
			<div class="ui-g form-group">
				<!-- {OPEN:e30050f2-f869-4fcf-96b2-c3c7c74c2db0} {} -->
				<!-- {PREOPEN:5ce081db-9120-413c-a258-3d5c6ca1d949} {"sha1":"5207B47B9FC4BC7542CFEDA957FC300DBB66B0DE"} -->
				<app-button-dropdown [model]="columnsBtnItems" icon="ui-icon-arrow-drop-down"  label="Columns"></app-button-dropdown>
				<!-- {POSTCLOSE:4ea002af-99c9-4b71-9642-2050a84fc259} {"sha1":"079B33F711D32A1BDB5DD1A942BDD82885981728"} -->
				<!-- {CLOSE:e30050f2-f869-4fcf-96b2-c3c7c74c2db0} {} -->
			</div>
			<!-- {POSTCLOSE:e30050f2-f869-4fcf-96b2-c3c7c74c2db0} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
			<!-- {CLOSE:e66d67c5-5192-49d4-abda-9ac624261013} {} -->
			<!-- {CLOSE:5a82450d-bbb3-4049-8f06-39f5e69254af} {} -->
		</div>
		<!-- {POSTCLOSE:5a82450d-bbb3-4049-8f06-39f5e69254af} {"sha1":"C4A15ED98D4B138EEB595266FB1CF4C91CF45CA5"} -->
		<!-- {CLOSE:3331df83-5e70-448b-8a00-c43a4917659e} {} -->
	</div>
	<!-- {POSTCLOSE:3331df83-5e70-448b-8a00-c43a4917659e} {"sha1":"797F18B33CA2DAC7B5A389B76ABFD8D91F547465"} -->
	<!-- {CLOSE:6bbb0c93-7b6c-443a-b2c5-4edc3eb2a3ce} {} -->
	<!-- {PREOPEN:dd914b5e-29f7-4e3d-8473-3e76e7f45c39} {"sha1":"AFC72AF811413B0F97D63CA5F4BE375E21005CB0"} -->
	<div class="ui-g form-group">
		<div class="ui-g-12 ui-md-12">
			<p-table class="tableView" #barboursTable [columns]="barboursTableCols" [value]="projectsOrCompanies" (onPage)="paginate($event)"
					 currentPageReportTemplate="({first} of {totalRecords})" [showCurrentPageReport]="true" [(first)]="first"
					 [selectionMode]="barboursTableSelectionMode" (onRowSelect)="onBarboursTableRowSelect($event.data)" [paginator]="true" [rows]= "rows" [rowsPerPageOptions]='([30,20,10])'>
				<!-- {OPEN:dd914b5e-29f7-4e3d-8473-3e76e7f45c39} {} -->
				<!-- {PREOPEN:COLGROUP-dd914b5e-29f7-4e3d-8473-3e76e7f45c39} {"sha1":"3DC6D8C9B1E7B0223D1CCE76C1C82A288C9A4689"} -->
				<ng-template pTemplate="colgroup" let-columns>
					<colgroup>
						<col *ngFor="let col of columns" [hidden]="col.hidden" [style.width]="col.width">
					</colgroup>
				</ng-template>
				<!-- {POSTCLOSE:COLGROUP-dd914b5e-29f7-4e3d-8473-3e76e7f45c39} {"sha1":"6D02DE4A18BCD9958BB50B15D6A5C9E85A898BF6"} -->
				<!-- {PREOPEN:HEADER-dd914b5e-29f7-4e3d-8473-3e76e7f45c39} {"sha1":"A4D3A80E2BCC0A34677F3E2585615CA43EAC9D20"} -->
				<ng-template pTemplate="header" let-columns>
					<!-- {OPEN:HEADER-dd914b5e-29f7-4e3d-8473-3e76e7f45c39} {"sha1":"30E9E266BC8BF7F65D28EFE8276DDE411049D181"} -->
					<tr>
						<th *ngFor="let col of columns" [hidden]="col.hidden" [pSortableColumn]="col.sortField || col.field" [pSortableColumnDisabled]="col.noSort">
							<ng-container *ngIf="col.header === 'X'">
								<oneit-checkbox (click)="selectAllTableRows()"  name="select-all" fieldLabelbinary="true"></oneit-checkbox>
							</ng-container>
							<ng-container *ngIf="col.header !== 'X'">
								{{ col.header }}
								<p-sortIcon [field]="col.sortField || col.field" *ngIf="!col.noSort"></p-sortIcon>
							</ng-container>
						</th>
					</tr>
					<tr>
						<th *ngFor="let col of columns" [hidden]="col.hidden">
							<input *ngIf="!col.noFilter" pInputText type="text" (input)="barboursTable.filter($event.target.value, col.field, 'contains')">
						</th>
					</tr>
					<!-- {CLOSE:HEADER-dd914b5e-29f7-4e3d-8473-3e76e7f45c39} {"sha1":"30E9E266BC8BF7F65D28EFE8276DDE411049D181"} -->
				</ng-template>
				<!-- {POSTCLOSE:HEADER-dd914b5e-29f7-4e3d-8473-3e76e7f45c39} {"sha1":"6D02DE4A18BCD9958BB50B15D6A5C9E85A898BF6"} -->
				<!-- {PREOPEN:BODY-dd914b5e-29f7-4e3d-8473-3e76e7f45c39} {"sha1":"6345486287546C5AD52CE54658AE62B533BE341D"} -->
				<ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
					<!-- {OPEN:BODY-dd914b5e-29f7-4e3d-8473-3e76e7f45c39} {} -->
					<!-- {PREOPEN:775f0cde-72f3-41df-88f2-0b83e0ee2cf7} {"sha1":"A3E033FE0F9C8BA29C8109FFC1B4FACE2DDDB355"} -->
					<tr [pSelectableRow]="rowData" [pSelectableRowDisabled]="barboursTableSelectionMode ? false : true">
						<!-- {OPEN:775f0cde-72f3-41df-88f2-0b83e0ee2cf7} {} -->
						<!-- {PREOPEN:47c9d15b-2214-4f36-9f1e-c23918722ac5} {"sha1":"2A2D269FD32FDF0E87FA25980833D1BC3670DAA7"} -->
						<td>
							<!-- {OPEN:47c9d15b-2214-4f36-9f1e-c23918722ac5} {} -->
							<!-- {OPEN:dbaeed5d-8709-4cf8-a4f9-b17eb7bc6f63} {"sha1":"E20B28ED048A6CA9B21F7E8D621914FBE571AC5A"} -->
							<div>
								<oneit-checkbox [(ngModel)]="rowData.IsChecked" name="X{{ rowData.id }}" fieldLabel="X" binary="true"></oneit-checkbox>
							</div>
							<!-- {CLOSE:dbaeed5d-8709-4cf8-a4f9-b17eb7bc6f63} {"sha1":"E20B28ED048A6CA9B21F7E8D621914FBE571AC5A"} -->
							<!-- {CLOSE:47c9d15b-2214-4f36-9f1e-c23918722ac5} {} -->
						</td>
						<td [hidden]="barboursTableCols[1].hidden">
							<div>
								<i [class.ui-icon-star-border]="!rowData.favourite" [class.ui-icon-star]="rowData.favourite" (click)="onAddFavouriteClick(rowData)"></i>
							</div>
						</td>
						<td [hidden]="barboursTableCols[2].hidden">
							<div>
								<label>{{ rowData.id }}</label>
							</div>
						</td>
						<!-- {POSTCLOSE:47c9d15b-2214-4f36-9f1e-c23918722ac5} {"sha1":"3481EDE2B787707391B17836799739A723650553"} -->
						<!-- {PREOPEN:5e928814-9dd8-47fa-8aa8-aae1ee479fca} {"sha1":"2A2D269FD32FDF0E87FA25980833D1BC3670DAA7"} -->
						<td [hidden]="barboursTableCols[3].hidden">
							<!-- {OPEN:5e928814-9dd8-47fa-8aa8-aae1ee479fca} {} -->
							<!-- {OPEN:31e7d9f0-76f9-45ae-b517-7d3c4d72208c} {"sha1":"19F8E67DC484FE495F0DCAD5106983C18BF7F8A4"} -->
							<div>
								<a [routerLink]="'/search/' + rowData.module + '/' +rowData?.id">{{  rowData.title }}</a>
							</div>
              <div *ngIf="rowData.module === 'companies'">
                <a [routerLink]="'/search/' + rowData.module + '/' +rowData?.id">{{  rowData.name }}</a>
              </div>
							<!-- {CLOSE:31e7d9f0-76f9-45ae-b517-7d3c4d72208c} {"sha1":"19F8E67DC484FE495F0DCAD5106983C18BF7F8A4"} -->
							<!-- {CLOSE:5e928814-9dd8-47fa-8aa8-aae1ee479fca} {} -->
						</td>
						<!-- {POSTCLOSE:5e928814-9dd8-47fa-8aa8-aae1ee479fca} {"sha1":"3481EDE2B787707391B17836799739A723650553"} -->
						<!-- {PREOPEN:a2617ebc-3462-4b22-a3de-226dbcf28e16} {"sha1":"2A2D269FD32FDF0E87FA25980833D1BC3670DAA7"} -->
						<td [hidden]="barboursTableCols[4].hidden">
							<!-- {OPEN:a2617ebc-3462-4b22-a3de-226dbcf28e16} {} -->
							<!-- {OPEN:9db4b834-1312-4d71-bbe0-8f2be7831b0e} {"sha1":"3A10D7B473CA30C7B9CAFF0BF76385D3704AF14E"} -->
              <div *ngIf="rowData.module === 'projects'">
								<label>{{ rowData.address3 }}</label>
							</div>
              <div *ngIf="rowData.module === 'companies'">
                <label>{{ rowData.phone }}</label>
              </div>
							<!-- {CLOSE:9db4b834-1312-4d71-bbe0-8f2be7831b0e} {"sha1":"3A10D7B473CA30C7B9CAFF0BF76385D3704AF14E"} -->
							<!-- {CLOSE:a2617ebc-3462-4b22-a3de-226dbcf28e16} {} -->
						</td>
						<!-- {POSTCLOSE:a2617ebc-3462-4b22-a3de-226dbcf28e16} {"sha1":"3481EDE2B787707391B17836799739A723650553"} -->
						<!-- {PREOPEN:38ac7bef-3513-4104-93fe-ac74e9621148} {"sha1":"2A2D269FD32FDF0E87FA25980833D1BC3670DAA7"} -->
						<td [hidden]="barboursTableCols[5].hidden">
							<!-- {OPEN:38ac7bef-3513-4104-93fe-ac74e9621148} {} -->
							<!-- {OPEN:d52a8cf0-4045-4c62-a0f4-e9405c2f7df5} {"sha1":"7501083AA91BF82C404E69E98A96B32D13E91CA3"} -->
							<div *ngIf="rowData.module === 'projects'">
								<label>{{ rowData.address4 }}</label>
							</div>
              <div *ngIf="rowData.module === 'companies'">
                <label>{{ rowData.website }}</label>
              </div>
							<!-- {CLOSE:d52a8cf0-4045-4c62-a0f4-e9405c2f7df5} {"sha1":"7501083AA91BF82C404E69E98A96B32D13E91CA3"} -->
							<!-- {CLOSE:38ac7bef-3513-4104-93fe-ac74e9621148} {} -->
						</td>
						<!-- {POSTCLOSE:38ac7bef-3513-4104-93fe-ac74e9621148} {"sha1":"3481EDE2B787707391B17836799739A723650553"} -->
						<!-- {PREOPEN:2b395d5b-9487-4d2c-a6e6-ae0b1c417603} {"sha1":"2A2D269FD32FDF0E87FA25980833D1BC3670DAA7"} -->
						<td [hidden]="barboursTableCols[6].hidden" *ngIf="rowData.module === 'projects'">
							<!-- {OPEN:2b395d5b-9487-4d2c-a6e6-ae0b1c417603} {} -->
							<!-- {OPEN:0ea2c41e-c534-4256-a4ce-5386a0db5fc7} {"sha1":"B2297D786545F73BDE93CBFE3278A8F5CAB59C38"} -->
							<div>
								<label>{{ rowData.planningStage }}</label>
							</div>
							<!-- {CLOSE:0ea2c41e-c534-4256-a4ce-5386a0db5fc7} {"sha1":"B2297D786545F73BDE93CBFE3278A8F5CAB59C38"} -->
							<!-- {CLOSE:2b395d5b-9487-4d2c-a6e6-ae0b1c417603} {} -->
						</td>
						<!-- {POSTCLOSE:2b395d5b-9487-4d2c-a6e6-ae0b1c417603} {"sha1":"3481EDE2B787707391B17836799739A723650553"} -->
						<!-- {PREOPEN:52e839be-d332-41c1-8128-e883f93c478e} {"sha1":"2A2D269FD32FDF0E87FA25980833D1BC3670DAA7"} -->
						<td [hidden]="barboursTableCols[7].hidden" *ngIf="rowData.module === 'projects'">
							<!-- {OPEN:52e839be-d332-41c1-8128-e883f93c478e} {} -->
							<!-- {OPEN:7adfaaa9-5fb0-4abe-b151-98d44e87cb20} {"sha1":"F3BF06D269297BF9CFCD199B601BCB0EE2D3B2FF"} -->
							<div *ngIf="rowData.module === 'projects'">
								<label>{{ rowData.contractStage }}</label>
							</div>
              <div *ngIf="rowData.module === 'companies'">
                <label></label>
              </div>
							<!-- {CLOSE:7adfaaa9-5fb0-4abe-b151-98d44e87cb20} {"sha1":"F3BF06D269297BF9CFCD199B601BCB0EE2D3B2FF"} -->
							<!-- {CLOSE:52e839be-d332-41c1-8128-e883f93c478e} {} -->
						</td>
						<!-- {POSTCLOSE:52e839be-d332-41c1-8128-e883f93c478e} {"sha1":"3481EDE2B787707391B17836799739A723650553"} -->
						<!-- {PREOPEN:de3d9436-e659-44fd-8c13-0405d409deaf} {"sha1":"2A2D269FD32FDF0E87FA25980833D1BC3670DAA7"} -->
						<td [hidden]="barboursTableCols[8].hidden" *ngIf="rowData.module === 'projects'">
							<!-- {OPEN:de3d9436-e659-44fd-8c13-0405d409deaf} {} -->
							<!-- {OPEN:afbfc48b-7d5d-4ffd-802a-927ca65fd4ae} {"sha1":"F9AF51A4B22AE4A9FADFEBD555A682A80D9094D0"} -->
							<div *ngIf="rowData.module === 'projects'">
								<label>{{ rowData.value }}</label>
							</div>
							<!-- {CLOSE:afbfc48b-7d5d-4ffd-802a-927ca65fd4ae} {"sha1":"F9AF51A4B22AE4A9FADFEBD555A682A80D9094D0"} -->
							<!-- {CLOSE:de3d9436-e659-44fd-8c13-0405d409deaf} {} -->
						</td>
						<!-- {POSTCLOSE:de3d9436-e659-44fd-8c13-0405d409deaf} {"sha1":"3481EDE2B787707391B17836799739A723650553"} -->
						<!-- {PREOPEN:254247bf-3c6d-491c-b5b4-0aaab1e07e66} {"sha1":"2A2D269FD32FDF0E87FA25980833D1BC3670DAA7"} -->
						<td [hidden]="barboursTableCols[9].hidden" *ngIf="rowData.module === 'projects'">
							<!-- {OPEN:254247bf-3c6d-491c-b5b4-0aaab1e07e66} {} -->
							<!-- {OPEN:05b39eac-66d1-4bdc-995a-adca2cf53110} {"sha1":"5785A7D9FF09FBB2D6534B0BC4B048A0285086C3"} -->
							<div *ngIf="rowData.module === 'projects'">
								<label>{{ rowData.primarySector }}</label>
							</div>
							<!-- {CLOSE:05b39eac-66d1-4bdc-995a-adca2cf53110} {"sha1":"5785A7D9FF09FBB2D6534B0BC4B048A0285086C3"} -->
							<!-- {CLOSE:254247bf-3c6d-491c-b5b4-0aaab1e07e66} {} -->
						</td>
						<!-- {POSTCLOSE:254247bf-3c6d-491c-b5b4-0aaab1e07e66} {"sha1":"3481EDE2B787707391B17836799739A723650553"} -->
						<!-- {PREOPEN:484908a0-c486-440b-8740-13f173fbd00f} {"sha1":"2A2D269FD32FDF0E87FA25980833D1BC3670DAA7"} -->
						<td [hidden]="barboursTableCols[10].hidden" *ngIf="rowData.module === 'projects'">
							<!-- {OPEN:484908a0-c486-440b-8740-13f173fbd00f} {} -->
							<!-- {OPEN:0b75dd0d-dd67-4a43-af2a-ab93024926da} {"sha1":"7ECEE012915236264622B88015491FBDF70CBFDE"} -->
							<div *ngIf="rowData.module === 'projects'">
								<label>{{ rowData.primaryCategory }}</label>
							</div>
							<!-- {CLOSE:0b75dd0d-dd67-4a43-af2a-ab93024926da} {"sha1":"7ECEE012915236264622B88015491FBDF70CBFDE"} -->
							<!-- {CLOSE:484908a0-c486-440b-8740-13f173fbd00f} {} -->
						</td>
						<!-- {POSTCLOSE:484908a0-c486-440b-8740-13f173fbd00f} {"sha1":"3481EDE2B787707391B17836799739A723650553"} -->
						<!-- {PREOPEN:b5e8f776-b6d0-4314-acf5-d022793c3049} {"sha1":"2A2D269FD32FDF0E87FA25980833D1BC3670DAA7"} -->
						<td [hidden]="barboursTableCols[11].hidden" *ngIf="rowData.module === 'projects'">
							<!-- {OPEN:b5e8f776-b6d0-4314-acf5-d022793c3049} {} -->
							<!-- {OPEN:19584645-cac3-4ecb-81a4-5ca468829d05} {"sha1":"F9AF51A4B22AE4A9FADFEBD555A682A80D9094D0"} -->
							<div *ngIf="rowData.module === 'projects'">
								<label>{{ rowData.units }}</label>
							</div>
							<!-- {CLOSE:19584645-cac3-4ecb-81a4-5ca468829d05} {"sha1":"F9AF51A4B22AE4A9FADFEBD555A682A80D9094D0"} -->
							<!-- {CLOSE:b5e8f776-b6d0-4314-acf5-d022793c3049} {} -->
						</td>
						<!-- {POSTCLOSE:b5e8f776-b6d0-4314-acf5-d022793c3049} {"sha1":"3481EDE2B787707391B17836799739A723650553"} -->
						<!-- {PREOPEN:ae13b86c-075e-4ee3-9dc9-f949ddb5c58d} {"sha1":"2A2D269FD32FDF0E87FA25980833D1BC3670DAA7"} -->
						<td [hidden]="barboursTableCols[12].hidden" *ngIf="rowData.module === 'projects'">
							<!-- {OPEN:ae13b86c-075e-4ee3-9dc9-f949ddb5c58d} {} -->
							<!-- {OPEN:dc94ce12-8c3c-474f-9474-605b5787245c} {"sha1":"FDC8A7E6375EA0AB61B578AFCD3E92C8892C7DE5"} -->
							<div *ngIf="rowData.module === 'projects'">
								<label>{{ rowData.start }}</label>
							</div>
							<!-- {CLOSE:dc94ce12-8c3c-474f-9474-605b5787245c} {"sha1":"FDC8A7E6375EA0AB61B578AFCD3E92C8892C7DE5"} -->
							<!-- {CLOSE:ae13b86c-075e-4ee3-9dc9-f949ddb5c58d} {} -->
						</td>
						<td [hidden]="barboursTableCols[13].hidden" *ngIf="rowData.module === 'projects'">
							<div *ngIf="rowData.module === 'projects'" [style.paddingTop.px]="'10'" [style.paddingLeft.px]="'10'" [style.paddingBottom.px]="'10'" [style.paddingRight.px]="'10'" [style.background-color]="rowData.convertedColor">
								<label>{{ rowData.converted }}</label>
							</div>
						</td>
						<!-- {POSTCLOSE:ae13b86c-075e-4ee3-9dc9-f949ddb5c58d} {"sha1":"3481EDE2B787707391B17836799739A723650553"} -->
						<!-- {CLOSE:775f0cde-72f3-41df-88f2-0b83e0ee2cf7} {} -->
					</tr>
					<!-- {POSTCLOSE:775f0cde-72f3-41df-88f2-0b83e0ee2cf7} {"sha1":"A88D4F820D8E19D78F33837AE3FDCDC0295AD32E"} -->
					<!-- {CLOSE:BODY-dd914b5e-29f7-4e3d-8473-3e76e7f45c39} {} -->
				</ng-template>
				<!-- {POSTCLOSE:BODY-dd914b5e-29f7-4e3d-8473-3e76e7f45c39} {"sha1":"6D02DE4A18BCD9958BB50B15D6A5C9E85A898BF6"} -->
				<!-- {CLOSE:dd914b5e-29f7-4e3d-8473-3e76e7f45c39} {} -->
				<ng-template pTemplate="emptymessage" let-columns>
					<tr>
						<td [attr.colspan]="columns.length">
							No records found
						</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>
	<!-- {POSTCLOSE:1368fb14-1065-4830-a4f5-956c49ea118e} {"sha1":"5ECEB12B574903FC69B702C1DC533C590ADF76B3"} -->
	<oneit-confirmDialog></oneit-confirmDialog>
	<p-progressSpinner *ngIf="showLoader"></p-progressSpinner>
	<!-- {CLOSE:8460368b-224f-4c85-800c-e9bc7939ba3e} {} -->
</form>
<!-- {POSTCLOSE:8460368b-224f-4c85-800c-e9bc7939ba3e} {"sha1":"3FD6E659562C1876D02FC8CF54746B1502442CD2"} -->
