/* {OPEN:IMPORT} {"sha1":"F1853E956E4476918D4C188AA4B13C89571E0AC0"} */
import { Component, DoCheck, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import { Message, MenuItem, ConfirmationService } from 'primeng/api';
import { AppService } from './../../app.service';
import { OptionsObservable, OptionsWrapper } from './../../oneit/models/options.model';
import { UserService } from './../../oneit/services/user.service';
import { UtilsService } from './../../oneit/services/utils.service';
import { SearchService } from './../../oneit/services/search.service';
import { ContactsService } from './contacts.service';
import { SearchContact } from './contacts.model';
/* {CLOSE:IMPORT} {"sha1":"F1853E956E4476918D4C188AA4B13C89571E0AC0"} */

@Component({
    selector: 'app-base-contacts',
    template: ''
})
/* {PREOPEN:CLASS} {"sha1":"A5191861BAE80F1419529C5A7A99FE75B242E465"} */
export class BaseContactsComponent implements DoCheck, OnInit, OnDestroy {
    /* {OPEN:CLASS} {} */
    
    /* {OPEN:COMMON_VARS} {"sha1":"672A211DEEA9A3559D121CBDC5978BBA47BD6B7D"} */
    @Input() parent : any;
    pageTitle : string;
    /* {CLOSE:COMMON_VARS} {"sha1":"672A211DEEA9A3559D121CBDC5978BBA47BD6B7D"} */

    /* {OPEN:VARS} {"sha1":"183DBF772B55E7B0B445CCFBA76D247DFCEF60C0"} */
    searchContact : SearchContact = new SearchContact();
    results : any[] = [];
    resultsTableSelectionMode : string;
    resultsTableCols : any[] = [];
    /* {CLOSE:VARS} {"sha1":"183DBF772B55E7B0B445CCFBA76D247DFCEF60C0"} */
    
    showResults : boolean = false;
    showLoader : boolean = false;
    subscriptions : Array<Subscription> = [];

    constructor(
        /* {OPEN:constructor} {"sha1":"8CABE3BDDA549C5965D401663508A272E2FF4C0C"} */
        public router : Router,
        public userService : UserService,
        public utilsService : UtilsService,
        public searchService : SearchService,
        public appService : AppService,
        public confirmationService: ConfirmationService,
        public contactsService : ContactsService,
        /* {CLOSE:constructor} {"sha1":"8CABE3BDDA549C5965D401663508A272E2FF4C0C"} */
    ){
        
    }

    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"5FBDAAB044BC4F999356808F755A7334E11EE273"} */
        this.resetInitVariables();
        this.calculateEverything();
        this.executeScreenSpecificInitCode();
        /* {CLOSE:ngOnInit} {"sha1":"5FBDAAB044BC4F999356808F755A7334E11EE273"} */
    }

    /* {PREOPEN:executeScreenSpecificInitCode} {"sha1":"DDD081544316B33777AB7B7FFD796FB9E663E681"} */
    executeScreenSpecificInitCode() {
        /* {OPEN:executeScreenSpecificInitCode} {"sha1":"54DB41AD32937D86C7318EB4BE666A1AD7B6B618"} */
        if(!this.parent) {
        }
        /* {CLOSE:executeScreenSpecificInitCode} {"sha1":"54DB41AD32937D86C7318EB4BE666A1AD7B6B618"} */
    }
    /* {POSTCLOSE:executeScreenSpecificInitCode} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onCloseClick} {"sha1":"2755432ADC0667B9AF422677E44A4888AB3E5460"} */
    onCloseClick(event, searchContact) {
        /* {OPEN:onCloseClick} {"sha1":"999E5462196194ACACE528B609E0E94624B9574A"} */
        this.router.navigate(['/search-projects']);
        /* {CLOSE:onCloseClick} {"sha1":"999E5462196194ACACE528B609E0E94624B9574A"} */
    }
    /* {POSTCLOSE:onCloseClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onCreateContactClick} {"sha1":"8646F09331F3E23389881E8882A5DCE413209683"} */
    onCreateContactClick(event, searchContact) {
        /* {OPEN:onCreateContactClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onCreateContactClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onCreateContactClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRouterLinkForMaloneORegan} {"sha1":"667A2EB8097EA2D0549670993DA28F74DD46A56A"} */
    getRouterLinkForMaloneORegan(searchContact) {
        /* {OPEN:getRouterLinkForMaloneORegan} {"sha1":"812EFC4A01CDBE08F76784B8C5601518EB4F4683"} */
        return ['/company'];
        /* {CLOSE:getRouterLinkForMaloneORegan} {"sha1":"812EFC4A01CDBE08F76784B8C5601518EB4F4683"} */
    }
    /* {POSTCLOSE:getRouterLinkForMaloneORegan} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getResultsTableCols} {"sha1":"03C2A19A62274115FB60D7444DBED528F2121DFA"} */
    getResultsTableCols() : any[] {
        /* {OPEN:getResultsTableCols} {"sha1":"A3BDC4D299666A28F5AA5CC941431F79CD0CB649"} */
        return [
            { field: 'Agent', header: 'Role', width: '10px', noSort: false, noFilter: false },
            { field: 'StamfordHouseRetailUnits', header: 'Project', width: '22px', noSort: false, noFilter: false }
        ];
        /* {CLOSE:getResultsTableCols} {"sha1":"A3BDC4D299666A28F5AA5CC941431F79CD0CB649"} */
    }
    /* {POSTCLOSE:getResultsTableCols} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getResults} {"sha1":"FC9A8EBBC65117AC79C54B3E01CB83299350219C"} */
    getResults(saveSearch: boolean) {
        /* {OPEN:getResults} {"sha1":"76A46DB3EE228B5621BFB290D11A4BCC6BB64AED"} */
        this.showResults = true;
        this.showLoader = true;
        let assocs = [];
        
        this.subscriptions.push(this.getResultsObservable(saveSearch)
            .subscribe(
                data => {
                    this.results = data;
                    this.postResultsTableChange();
                    this.showLoader = false;
                },
                error => {
                    this.showLoader = false;
                    this.utilsService.handleError(error);
                }
            )
        );
        /* {CLOSE:getResults} {"sha1":"76A46DB3EE228B5621BFB290D11A4BCC6BB64AED"} */
    }
    /* {POSTCLOSE:getResults} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:postResultsTableChange} {"sha1":"F390E755BD73F85460CB337CBB2369C16E190A2A"} */
    postResultsTableChange() {
        /* {OPEN:postResultsTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:postResultsTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:postResultsTableChange} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getResultsObservable} {"sha1":"5F7F1FE75F21A698CD729B373065D1B57DF8771F"} */
    getResultsObservable(saveSearch): Observable<any> {
        /* {OPEN:getResultsObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
        return of({});
        /* {CLOSE:getResultsObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
    }
    /* {POSTCLOSE:getResultsObservable} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onResultsTableRowSelect} {"sha1":"670E2FC5DB72031CB133DEDC57EEF0100923BCB9"} */
    onResultsTableRowSelect(data) {
        /* {OPEN:onResultsTableRowSelect} {"sha1":"76FFDDBB12D60FFC8CC2440FDE7CBF1C3ED13329"} */
        this.router.navigate(['/project']);
        /* {CLOSE:onResultsTableRowSelect} {"sha1":"76FFDDBB12D60FFC8CC2440FDE7CBF1C3ED13329"} */
    }
    /* {POSTCLOSE:onResultsTableRowSelect} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getResultsTableSelectionMode} {"sha1":"82EF7D5796980870ABF59532A395827E41F0D930"} */
    getResultsTableSelectionMode() : string {
        /* {OPEN:getResultsTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
        return null;
        /* {CLOSE:getResultsTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
    }
    /* {POSTCLOSE:getResultsTableSelectionMode} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRouterLinkForResultsTableStamfordHouseRetailUnits} {"sha1":"7D4A40291C821037A20B0261A44751EFBE7BF3BF"} */
    getRouterLinkForResultsTableStamfordHouseRetailUnits(rowData) {
        /* {OPEN:getRouterLinkForResultsTableStamfordHouseRetailUnits} {"sha1":"59FA4B2AD9C5B4E0012DA0C1CC3DA22AE8ED0C36"} */
        return ['/project'];
        /* {CLOSE:getRouterLinkForResultsTableStamfordHouseRetailUnits} {"sha1":"59FA4B2AD9C5B4E0012DA0C1CC3DA22AE8ED0C36"} */
    }
    /* {POSTCLOSE:getRouterLinkForResultsTableStamfordHouseRetailUnits} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:resetInitVariables} {"sha1":"846C0DF64879342578D595FE17BC00B96F0BAED6"} */
    resetInitVariables() {
        /* {OPEN:resetInitVariables} {} */
        /* {OPEN:resetInitVariables_resultsTableCols} {"sha1":"9D00EFADF5900BE506788A6200EE9EACE68C4DF7"} */
        try {
            this.resultsTableCols = this.getResultsTableCols();
        }
        catch (error) {
            console.error('Error occured when setting resultsTableCols', error);
        }
        /* {CLOSE:resetInitVariables_resultsTableCols} {"sha1":"9D00EFADF5900BE506788A6200EE9EACE68C4DF7"} */
        /* {OPEN:resetInitVariables_resultsTableSelectionMode} {"sha1":"610E7BB1F99472C667B8989466249701357A82CA"} */
        try {
            this.resultsTableSelectionMode = this.getResultsTableSelectionMode();
        }
        catch (error) {
            console.error('Error occured when setting resultsTableSelectionMode', error);
        }
        /* {CLOSE:resetInitVariables_resultsTableSelectionMode} {"sha1":"610E7BB1F99472C667B8989466249701357A82CA"} */
        /* {CLOSE:resetInitVariables} {} */
    }
    /* {POSTCLOSE:resetInitVariables} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getPageTitle} {"sha1":"174A3D30FFA103DD7BE180EE89CC0CF5EB6D11B8"} */
    getPageTitle() {
        /* {OPEN:getPageTitle} {"sha1":"23F6A546D9F55179BFA9487EDB69EE9CF7DA469C"} */
        return 'Contacts';
        /* {CLOSE:getPageTitle} {"sha1":"23F6A546D9F55179BFA9487EDB69EE9CF7DA469C"} */
    }
    /* {POSTCLOSE:getPageTitle} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:calculateEverything} {"sha1":"CE17BF2375171825A37C544A1DCBF3A565DD5181"} */
    calculateEverything() {
        /* {OPEN:calculateEverything} {} */
        /* {OPEN:calculateEverything_pageTitle} {"sha1":"B3CA5EAE3ED54D1F0D95EA2CD05351A4A6D7480F"} */
        try {
            this.pageTitle = this.getPageTitle();
        }
        catch (error) {
            console.error('Error occured when setting pageTitle');
        }
        /* {CLOSE:calculateEverything_pageTitle} {"sha1":"B3CA5EAE3ED54D1F0D95EA2CD05351A4A6D7480F"} */
        /* {CLOSE:calculateEverything} {} */
    }
    /* {POSTCLOSE:calculateEverything} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:ngDoCheck} {"sha1":"75839B27D41E20648CE296AC51D61E24D541A70A"} */
    ngDoCheck() {
        /* {OPEN:ngDoCheck} {"sha1":"A154E0663059B6D385B4F6C11094F2084FF5E184"} */
        this.calculateEverything();
        /* {CLOSE:ngDoCheck} {"sha1":"A154E0663059B6D385B4F6C11094F2084FF5E184"} */
    }
    /* {POSTCLOSE:ngDoCheck} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:ngOnDestroy} {"sha1":"C21B2137D2BD86DF7050E24A39F08355F04DA4E9"} */
    ngOnDestroy() {
        /* {OPEN:ngOnDestroy} {"sha1":"85AA65230B2C97013ABDD30D48BEB93C04E8EE54"} */
        this.utilsService.unsubscribeSubscriptions(this.subscriptions);
        /* {CLOSE:ngOnDestroy} {"sha1":"85AA65230B2C97013ABDD30D48BEB93C04E8EE54"} */
    }
    /* {POSTCLOSE:ngOnDestroy} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {CLOSE:CLASS} {} */
}
/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */