<div class="btn-dropdown" *ngIf="model?.length > 0">
    <custom-tieredMenu #menu [popup]="true" [model]="model" [styleClass]="position" [customButtonRef]="childButtonTemplate"></custom-tieredMenu>
    <ng-container *ngIf="!parentButtonTemplate">
        <p-button type="button" [icon]="icon" [iconPos]="iconPos" [label]="label" (click)="menu.toggle($event)" [styleClass]="btnClass"></p-button>
    </ng-container>
    <ng-container *ngIf="parentButtonTemplate">
        <ng-container *ngTemplateOutlet="parentButtonTemplate;context:{menu: menu}" >
        </ng-container>
    </ng-container>
</div>
