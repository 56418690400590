import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
    selector: '[oneitMaxValue][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: MaxValueValidatorDirective, multi: true }
    ]
})

export class MaxValueValidatorDirective implements Validator {

    @Input()
    oneitMaxValue: Number;

    constructor() { }

    validate(c: AbstractControl): { [key: string]: any } {

        if (!c.value || c.value <= this.oneitMaxValue) {
            return null;
        } else {
            return {
                maxValue: {
                    maxValue: this.oneitMaxValue
                }
            };
        }
    }
}