import { Constants } from 'src/app/cbos/cis/consts';
import { LocalStorageService } from './../../services/local-storage.service';
import { CisFilterService } from './../cis-filter.service';
import { CisUtilServiceService } from '../cis-util-service.service';
import { SavedSearchesProviderService } from '../../saved-searches-provider/saved-searches-provider.service';
import { Subscription } from 'rxjs';
import { UtilsService } from 'src/app/oneit/services/utils.service';
import { Router } from '@angular/router';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cis-summary-search',
  templateUrl: './cis-summary-search.component.html',
  styleUrls: ['./cis-summary-search.component.scss'],
  providers: [CisSummarySearchComponent],
})
export class CisSummarySearchComponent implements OnInit {

  @Output()
  removeEmitter = new EventEmitter<string>();

  readonly Constants = Constants;

  cache : any;
  showResults : boolean = false;
  isLoaded : boolean = false;
  subscriptions : Array<Subscription> = [];
  selectedFilters;

  constructor(
    public router : Router,
    public utilsService : UtilsService,
    public searchService : SavedSearchesProviderService,
    public cisUtilService : CisUtilServiceService,
    private filterService : CisFilterService,
    private localStorageService : LocalStorageService) {
   }

  ngOnInit(): void {
    this.getSelectedFilters();
    this.cache = this.localStorageService.getCacheFilters();
  }

  ngOnDestroy() {
    this.utilsService.unsubscribeSubscriptions(this.subscriptions);
    this.selectedFilters = {};
  }


  getSelectedFilters() {
    this.selectedFilters = this.filterService.getSelectedFilters();
  }


getDisplayFiltersForField(field) {

  let filterText = [];
  let fieldName = field.key;

  field.value.forEach(element => {
    let filterVal = this.cisUtilService.getDisplayValuesForFilter(element);
    if(this.cache.hasOwnProperty(fieldName)) filterVal = this.cache[fieldName][filterVal];
    filterText.push(filterVal);
  });

  return filterText;
}


onRemoveFilter(field) {
  this.removeEmitter.emit(field);
}

onRemoveTextFilter() {
  this.removeEmitter.emit(Constants.TEXT_FILTER);
}

}
