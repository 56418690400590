<!-- {PREOPEN:748a3fa5-7a4c-4acd-999f-ace25d473883} {"sha1":"8C4A0E2E0EFD768CAB5850B359AF669A56A45AB4"} -->
<form #form="ngForm" [class.pointer-events-none]="this.showLoader">
    <!-- {OPEN:748a3fa5-7a4c-4acd-999f-ace25d473883} {} -->
    <!-- {OPEN:Toolbar} {} -->
    <p-toolbar>
        <div class="ui-toolbar-group-left">
            {{ pageTitle }}
        </div>
        <div class="ui-toolbar-group-right">
            <!-- {OPEN:93f9b2bc-3118-4a48-82cf-bd24e1265a86} {"sha1":"3761DF60FE183F6C07F97346B38E18DEBF64422B"} -->
            <button pButton type="button" label="Close" icon="ui-icon-cancel" [class]='["btn-tertiary"]' (click)="onCloseClick($event, searchContact)"></button>
            <!-- {CLOSE:93f9b2bc-3118-4a48-82cf-bd24e1265a86} {"sha1":"3761DF60FE183F6C07F97346B38E18DEBF64422B"} -->
        </div>
        <div class="ui-toolbar-group-right actionBtn">
            <!-- {OPEN:0453b8c8-676d-4790-8af0-03dd7f33068b} {"sha1":"9055AA83F7CEE85A2067906027A8B0D0CDA8C845"} -->
            <button pButton type="button" label="Create Contact" icon="ui-icon-add" (click)="onCreateContactClick($event, searchContact)"></button>
            <!-- {CLOSE:0453b8c8-676d-4790-8af0-03dd7f33068b} {"sha1":"9055AA83F7CEE85A2067906027A8B0D0CDA8C845"} -->
        </div>
    </p-toolbar>
    <!-- {CLOSE:Toolbar} {} -->
    <!-- {OPEN:e146f668-42e2-4009-a6f0-33e64362e123} {} -->
    <!-- {PREOPEN:0510e18a-aa5b-4e57-b0f8-b44d00dcdd25} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
    <div class="ui-g form-group">
        <!-- {OPEN:0510e18a-aa5b-4e57-b0f8-b44d00dcdd25} {} -->
        <!-- {PREOPEN:ce42169c-59f9-47df-830d-80e9fe10c6b1} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
        <div class="ui-g-12 ui-md-3" *ngIf="contact?.fullName">
            <!-- {OPEN:ce42169c-59f9-47df-830d-80e9fe10c6b1} {} -->
            <!-- {OPEN:c6a1b3b5-113a-47cf-b2e3-4790d15b2cbf} {"sha1":"9231503758850D981136C863FE6B7F404E641218"} -->
            <span class="ui-float-label sticky">
                <span>{{contact?.fullName}}</span>
                <label>Name</label>
            </span>
            <!-- {CLOSE:c6a1b3b5-113a-47cf-b2e3-4790d15b2cbf} {"sha1":"9231503758850D981136C863FE6B7F404E641218"} -->
            <!-- {CLOSE:ce42169c-59f9-47df-830d-80e9fe10c6b1} {} -->
        </div>
        <!-- {POSTCLOSE:ce42169c-59f9-47df-830d-80e9fe10c6b1} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:c12e5f3f-e42b-4ab9-b887-b8e6d62f145b} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
        <div class="ui-g-12 ui-md-3" *ngIf="contact?.company">
            <!-- {OPEN:c12e5f3f-e42b-4ab9-b887-b8e6d62f145b} {} -->
            <!-- {OPEN:4d307eef-4d4c-4f71-8b67-0082da163a92} {"sha1":"1FA96FE74376945C866401125FA4D19DF540D915"} -->
            <span class="ui-float-label sticky">
                <a [routerLink]="getRouterLinkForMaloneORegan(searchContact)">{{contact?.company}}</a>
                <label>Company</label>
            </span>
            <!-- {CLOSE:4d307eef-4d4c-4f71-8b67-0082da163a92} {"sha1":"1FA96FE74376945C866401125FA4D19DF540D915"} -->
            <!-- {CLOSE:c12e5f3f-e42b-4ab9-b887-b8e6d62f145b} {} -->
        </div>
        <!-- {POSTCLOSE:c12e5f3f-e42b-4ab9-b887-b8e6d62f145b} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:2fbb06dc-5ced-46ae-8410-1e004a2db99d} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
        <div class="ui-g-12 ui-md-3" *ngIf="contact?.phone">
            <!-- {OPEN:2fbb06dc-5ced-46ae-8410-1e004a2db99d} {} -->
            <!-- {OPEN:6a5e78d1-7eac-4999-83e3-90d3b687294d} {"sha1":"86EF65F30ECFDA498E2E334BD188CE304906B1D1"} -->
            <span class="ui-float-label sticky">
                <span>{{contact?.phone}}</span>
                <label>Phone</label>
            </span>
            <!-- {CLOSE:6a5e78d1-7eac-4999-83e3-90d3b687294d} {"sha1":"86EF65F30ECFDA498E2E334BD188CE304906B1D1"} -->
            <!-- {CLOSE:2fbb06dc-5ced-46ae-8410-1e004a2db99d} {} -->
        </div>
        <!-- {POSTCLOSE:2fbb06dc-5ced-46ae-8410-1e004a2db99d} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:292537e7-a6cf-4363-b1dd-05e5f5af2cc5} {"sha1":"0B3D678300534C23D3FAC2D2765C3DAA7520A586"} -->
        <div class="ui-g-12 ui-md-3" *ngIf="contact?.email">
            <!-- {OPEN:292537e7-a6cf-4363-b1dd-05e5f5af2cc5} {} -->
            <!-- {OPEN:c3036451-3ff9-4a99-b39e-7432f49f104f} {"sha1":"75CB9FE260C69AF51A675B048EC3885F24FB20CC"} -->
            <span class="ui-float-label sticky">
                <span>{{contact?.email}}</span>
                <label>Email</label>
            </span>
            <!-- {CLOSE:c3036451-3ff9-4a99-b39e-7432f49f104f} {"sha1":"75CB9FE260C69AF51A675B048EC3885F24FB20CC"} -->
            <!-- {CLOSE:292537e7-a6cf-4363-b1dd-05e5f5af2cc5} {} -->
        </div>
        <!-- {POSTCLOSE:292537e7-a6cf-4363-b1dd-05e5f5af2cc5} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {CLOSE:0510e18a-aa5b-4e57-b0f8-b44d00dcdd25} {} -->
    </div>
    <!-- {POSTCLOSE:0510e18a-aa5b-4e57-b0f8-b44d00dcdd25} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
    <!-- {CLOSE:e146f668-42e2-4009-a6f0-33e64362e123} {} -->
    <!-- {PREOPEN:504f6201-b2df-4f96-bad2-1adf63075057} {"sha1":"1D0C907A47E6DB32FC7B4BBB9A7C94E91398154E"} -->
    <p-table #resultsTable [columns]="resultsTableCols" [value]="contactRoles"
        [selectionMode]="resultsTableSelectionMode" (onRowSelect)="onResultsTableRowSelect($event.data)" [paginator]="true" [rows]="10" [rowsPerPageOptions]='["10", "20", "30"]'> 
        <!-- {OPEN:504f6201-b2df-4f96-bad2-1adf63075057} {} -->
        <!-- {PREOPEN:COLGROUP-504f6201-b2df-4f96-bad2-1adf63075057} {"sha1":"1F108DAF4B774A5CCBA28843160983341B2896F5"} -->
        <ng-template pTemplate="colgroup" let-columns>
            <!-- {OPEN:COLGROUP-504f6201-b2df-4f96-bad2-1adf63075057} {"sha1":"DB57B00639822DF5A70B3BE86233E5FF2E3285CF"} -->
            <colgroup>
                <col *ngFor="let col of columns" [style.width]="col.width">
            </colgroup>
            <!-- {CLOSE:COLGROUP-504f6201-b2df-4f96-bad2-1adf63075057} {"sha1":"DB57B00639822DF5A70B3BE86233E5FF2E3285CF"} -->
        </ng-template>
        <!-- {POSTCLOSE:COLGROUP-504f6201-b2df-4f96-bad2-1adf63075057} {"sha1":"B49156045CB826E5C1A4343CDBBFB703D415ECE1"} -->
        <!-- {PREOPEN:HEADER-504f6201-b2df-4f96-bad2-1adf63075057} {"sha1":"A80411AECFDC496FC955192B403A638A750F836E"} -->
        <ng-template pTemplate="header" let-columns>
            <!-- {OPEN:HEADER-504f6201-b2df-4f96-bad2-1adf63075057} {"sha1":"68C7806D68FD6D968AC9BB37E0AC2601105641BE"} -->
            <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.sortField || col.field" [pSortableColumnDisabled]="col.noSort">
                    {{ col.header }}
                    <p-sortIcon [field]="col.sortField || col.field" *ngIf="!col.noSort"></p-sortIcon>
                </th>
            </tr>
            <tr>
                <th *ngFor="let col of columns">
                    <input *ngIf="!col.noFilter" pInputText type="text" (input)="resultsTable.filter($event.target.value, col.field, 'contains')">
                </th>
            </tr>
            <!-- {CLOSE:HEADER-504f6201-b2df-4f96-bad2-1adf63075057} {"sha1":"68C7806D68FD6D968AC9BB37E0AC2601105641BE"} -->
        </ng-template>
        <!-- {POSTCLOSE:HEADER-504f6201-b2df-4f96-bad2-1adf63075057} {"sha1":"B49156045CB826E5C1A4343CDBBFB703D415ECE1"} -->
        <!-- {PREOPEN:BODY-504f6201-b2df-4f96-bad2-1adf63075057} {"sha1":"38D9964DD46452A9D25EF789795DC8F2F8EA0076"} -->
        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
            <!-- {OPEN:BODY-504f6201-b2df-4f96-bad2-1adf63075057} {} -->
            <!-- {PREOPEN:1d8b2ff6-06f1-4e6d-9500-b7a50c2983f3} {"sha1":"71F334728994128639065D5CF32482B59D11AC1C"} -->
            <tr [pSelectableRow]="rowData" [pSelectableRowDisabled]="resultsTableSelectionMode ? false : true">
                <!-- {OPEN:1d8b2ff6-06f1-4e6d-9500-b7a50c2983f3} {} -->
                <!-- {PREOPEN:8e262d5c-10fa-426c-b824-28569ee83f15} {"sha1":"BD296C2D0009EBE3FF12F3F146D5096A5C6E80DA"} -->
                <td>
                    <!-- {OPEN:8e262d5c-10fa-426c-b824-28569ee83f15} {} -->
                    <!-- {OPEN:5dcae3e8-9904-49da-b8d1-6cb9edfe6676} {"sha1":"F333DD38CD60475740D00AFE5D3F351B2E7497DD"} -->
                    <label>{{ rowData.role?.roleDescription }}</label>
                    <!-- {CLOSE:5dcae3e8-9904-49da-b8d1-6cb9edfe6676} {"sha1":"F333DD38CD60475740D00AFE5D3F351B2E7497DD"} -->
                    <!-- {CLOSE:8e262d5c-10fa-426c-b824-28569ee83f15} {} -->
                </td>
                <!-- {POSTCLOSE:8e262d5c-10fa-426c-b824-28569ee83f15} {"sha1":"494D982B5C26FB64E63D5708771E13C61BFE8C6E"} -->
                <!-- {PREOPEN:c25fd6d6-7643-4b6f-83c4-31afd14adea8} {"sha1":"BD296C2D0009EBE3FF12F3F146D5096A5C6E80DA"} -->
                <td>
                    <!-- {OPEN:c25fd6d6-7643-4b6f-83c4-31afd14adea8} {} -->
                    <!-- {OPEN:2cf04eee-2879-49c0-9606-f3eea624d0c6} {"sha1":"AB1BA8FAB9DD8D23D63F497281406CD5A4CC0CBF"} -->
                    <a [ngStyle]='{"color":"#050505"}' [routerLink]="'/search/projects/' +rowData.project?.id">{{ rowData.project?.title }}</a>
                    <!-- {CLOSE:2cf04eee-2879-49c0-9606-f3eea624d0c6} {"sha1":"AB1BA8FAB9DD8D23D63F497281406CD5A4CC0CBF"} -->
                    <!-- {CLOSE:c25fd6d6-7643-4b6f-83c4-31afd14adea8} {} -->
                </td>
                <!-- {POSTCLOSE:c25fd6d6-7643-4b6f-83c4-31afd14adea8} {"sha1":"494D982B5C26FB64E63D5708771E13C61BFE8C6E"} -->
                <!-- {CLOSE:1d8b2ff6-06f1-4e6d-9500-b7a50c2983f3} {} -->
            </tr>
            <!-- {POSTCLOSE:1d8b2ff6-06f1-4e6d-9500-b7a50c2983f3} {"sha1":"D6A68C730B603867745E9FB409805C0BA8CA314A"} -->
            <!-- {CLOSE:BODY-504f6201-b2df-4f96-bad2-1adf63075057} {} -->
        </ng-template>
        <!-- {POSTCLOSE:BODY-504f6201-b2df-4f96-bad2-1adf63075057} {"sha1":"B49156045CB826E5C1A4343CDBBFB703D415ECE1"} -->
        <!-- {CLOSE:504f6201-b2df-4f96-bad2-1adf63075057} {} -->
    </p-table>
    <!-- {POSTCLOSE:504f6201-b2df-4f96-bad2-1adf63075057} {"sha1":"12061B5414F51422BD3E7F83907B78364E6F0405"} -->
    <oneit-confirmDialog></oneit-confirmDialog>
    <p-progressSpinner *ngIf="showLoader"></p-progressSpinner>
    <!-- {CLOSE:748a3fa5-7a4c-4acd-999f-ace25d473883} {} -->
</form>
<!-- {POSTCLOSE:748a3fa5-7a4c-4acd-999f-ace25d473883} {"sha1":"3FD6E659562C1876D02FC8CF54746B1502442CD2"} -->
