/* {OPEN:IMPORT} {"sha1":"BAEC8F95BD3A2239F7F3F73DCC61F252E6E4B619"} */
import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Injectable()
export class DataProviderService {

    constructor(private appService: AppService) {}

    getDataProviders() {
      return this.appService.getDataProviders();
    }

    addDataProvider(params) {
        return this.appService.addDataProvider(`${this.appService.getSelectedDataProvider()}/token` , params);
    }

    formatDataProviderItem(data, item) {
      let itemData = data[item].dataProviderDetails;
      let providers = {label: itemData.displayName, value:itemData.apiName ,
        disabled: !itemData.active , icon: itemData.iconResource};
      return providers;
    }



  }
