import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { ConfigService } from '../../services/config.service';

declare var $: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html'
})

export class HomeComponent implements OnInit {

    backendHomeURL: string;

    constructor(
        private configService: ConfigService,
        private router: Router
    ) {

    }

    ngOnInit() {
        this.backendHomeURL = this.configService.settings.backendHomeURL;

        if(this.backendHomeURL) {
            this.router.navigate(['iframe-page', environment.baseUrl + this.backendHomeURL]);
        }
    }
}