/* {OPEN:IMPORT} {"sha1":"BAEC8F95BD3A2239F7F3F73DCC61F252E6E4B619"} */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SaveService } from './../../oneit/services/save.service';
import { SearchService } from './../../oneit/services/search.service';
import { UtilsService } from './../../oneit/services/utils.service';
/* {CLOSE:IMPORT} {"sha1":"BAEC8F95BD3A2239F7F3F73DCC61F252E6E4B619"} */
import { AppService } from '../../app.service';
/* {POSTCLOSE:IMPORT} {"sha1":"BAEC8F95BD3A2239F7F3F73DCC61F252E6E4B619"} */

@Injectable()
/* {PREOPEN:CLASS} {"sha1":"7D4A8C3C9D67E52CD8935E69340A70955E3ABCBD"} */
export class TableViewService {
    /* {OPEN:CLASS} {} */

    constructor(
        /* {OPEN:constructor} {"sha1":"DCCC484B4DA23ED55D1380457121E9DAC4BB6B04"} */
        private searchService: SearchService,
        private saveService: SaveService,
        private utilsService: UtilsService,
        /* {CLOSE:constructor} {"sha1":"DCCC484B4DA23ED55D1380457121E9DAC4BB6B04"} */
        private appService: AppService
        /* {POSTCLOSE:constructor} {"sha1":"DCCC484B4DA23ED55D1380457121E9DAC4BB6B04"} */
    ) {
    }

    /* {PREOPEN:getSearchesForAutocomplete} {"sha1":"49B63B39D25CDB6E1DBE26E9B5FE95108EC5EA9B"} */
    getSearchesForAutocomplete(queryParams, assocs) : Observable<any> {
        /* {OPEN:getSearchesForAutocomplete} {"sha1":"10B4723BE0329E868F7C53AD2ACDA27218F92E57"} */
        return this.searchService.getObjects('SampleSearchConditions', 'All', queryParams, assocs, null, null, null, null)
            .pipe(map(
                data => {
                    return this.utilsService.convertResponseToObjects(data, assocs);
                }
            ));
        /* {CLOSE:getSearchesForAutocomplete} {"sha1":"10B4723BE0329E868F7C53AD2ACDA27218F92E57"} */
    }
    /* {POSTCLOSE:getSearchesForAutocomplete} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {CLOSE:CLASS} {} */

    getProjectsOrCompanies(params , module) {
        return this.appService.getProjectsOrCompanies(`${this.appService.getSelectedDataProvider()}/${module}/search`, params);
    }
}
/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */
