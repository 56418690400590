import { Observable } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { Injectable } from '@angular/core';
import { Constants } from './consts';

@Injectable()
export class CisUtilServiceService {

  constructor(private appService: AppService) {

  }
  getSavedSearches(module): Observable<any> {
    return this.appService.getSavedSearches(`${this.appService.getSelectedDataProvider()}/${module}/saved_searches` , null);
  }

  getCacheFilters(): Observable<any> {
    return this.appService.getCacheFilters(`${this.appService.getSelectedDataProvider()}/cache` , null);
  }

  getFilters(): Observable<any> {
    return this.appService.getCacheFilters(`${this.appService.getSelectedDataProvider()}/filters` , null);
  }


  getRelativeDateFilterText(value) {

    if(Constants.DATE_OPTIONS_PAST.has(value)) {
      return Constants.DATE_OPTIONS_PAST.get(value);
    }
    if(Constants.DATE_OPTIONS_FUTURE.has(value)) {
      return Constants.DATE_OPTIONS_FUTURE.get(value);
    }
    return value;
  }

  getDisplayValuesForFilter(filterMap) {
    if(!filterMap.hasOwnProperty('filterType')) {
      return '';
    }
    switch(filterMap.filterType) {
      case 'Compare':
        if (filterMap.hasOwnProperty('value') && filterMap.value != null && filterMap.value !==undefined && filterMap.value != "")
          return filterMap.value;
        else return filterMap.idValue;
      case 'Between':
        return `${filterMap.filterType} ${filterMap.from} and ${filterMap.to}`;
      case 'RelativeDate':
        return this.getRelativeDateFilterText(filterMap.value);
      case 'LessThan':
        return filterMap.filterType + " " + filterMap.value;
      case 'GreaterThan':
        return filterMap.filterType + " " + filterMap.value;
      case 'GeographicalRange':
        return `Lat: ${filterMap.lat}, Lng: ${filterMap.lng}, Range: ${filterMap.range}`;
    }
  }


}
