<!-- {PREOPEN:424e9751-600b-4beb-ae22-cac243c43386} {"sha1":"8C4A0E2E0EFD768CAB5850B359AF669A56A45AB4"} -->
<form #form="ngForm" [class.pointer-events-none]="this.showLoader">
    <!-- {OPEN:424e9751-600b-4beb-ae22-cac243c43386} {} -->
    <!-- {OPEN:Toolbar} {} -->
    <p-toolbar>
        <div class="ui-toolbar-group-left">
            {{ pageTitle }}
        </div>
        <div class="ui-toolbar-group-right">
            <!-- {OPEN:ee31fac8-1a6d-4a53-933e-634f683f268c} {"sha1":"C80DCD4C3B8C72C728A2C087E55EF98C7AAB7572"} -->
            <button pButton type="button" label="Close" icon="ui-icon-clear" [class]='["btn-tertiary"]' (click)="onCloseClick($event, searchCompany)"></button>
            <!-- {CLOSE:ee31fac8-1a6d-4a53-933e-634f683f268c} {"sha1":"C80DCD4C3B8C72C728A2C087E55EF98C7AAB7572"} -->
        </div>
        <div class="ui-toolbar-group-right actionBtn">
            <!-- {OPEN:630f2aa3-5d0a-4714-997f-b58a6ca6dccf} {"sha1":"896EC0104CD9997D804A75D79C767148CA5BB781"} -->
            <button pButton type="button" label="Create Account" icon="ui-icon-add" (click)="onCreateAccountClick($event, searchCompany)"></button>
            <!-- {CLOSE:630f2aa3-5d0a-4714-997f-b58a6ca6dccf} {"sha1":"896EC0104CD9997D804A75D79C767148CA5BB781"} -->
        </div>
    </p-toolbar>
    <!-- {CLOSE:Toolbar} {} -->
    <!-- {OPEN:65cc669d-daa8-471a-b32b-f0a9650b2ca3} {} -->
    <!-- {PREOPEN:85ce5612-9268-499e-905b-0d1f6b4a68c2} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
    <div class="ui-g form-group">
        <!-- {OPEN:85ce5612-9268-499e-905b-0d1f6b4a68c2} {} -->
        <!-- {PREOPEN:29352308-ed83-4d4f-ae29-0964cc361f86} {"sha1":"EBBFF12A35073AE4D8CC2445170CE6BA15B1F43B"} -->
        <div class="ui-g-12 ui-md-7">
            <!-- {OPEN:29352308-ed83-4d4f-ae29-0964cc361f86} {} -->
            <div [style.paddingTop.px]="'10'" [style.paddingLeft.px]="'0'" [style.paddingBottom.px]="'0'" [style.paddingRight.px]="'10'">
                <!-- {OPEN:060f2713-62d1-4a83-a395-32c68c92799e} {} -->
                <!-- {PREOPEN:c99b2e6d-2e39-454c-9678-65ad691a1b24} {"sha1":"3FC2A784D228EBE141639FC8310267F06032ABA6"} -->
                <div class="ui-g form-group">
                    <!-- {OPEN:c99b2e6d-2e39-454c-9678-65ad691a1b24} {} -->
                    <!-- {PREOPEN:a47cdf12-5dd7-44a5-8e40-3cef25fde4d2} {"sha1":"8FAAA1324566FDB026F720E3698CCA2D4762E3F3"} -->
                    <div class="ui-g-12 ui-md-1">
                        <!-- {OPEN:a47cdf12-5dd7-44a5-8e40-3cef25fde4d2} {} -->
                        <!-- {OPEN:0915ba46-b53c-4bdd-bc15-9260459194e4} {"sha1":"1C1DC8ADBEDC6D8C61EC7413B7217CF26961F7D1"} -->
                        <div [style.text-align]="'right'" [style.paddingTop.px]="'10'" [style.paddingLeft.px]="'0'" [style.paddingBottom.px]="'0'" [style.paddingRight.px]="'10'">
                            <i class="ui-icon-insert-chart" [style.font-size]="'1.5em'" [style.color]="'#000000'"></i>
                        </div>
                        <!-- {CLOSE:0915ba46-b53c-4bdd-bc15-9260459194e4} {"sha1":"1C1DC8ADBEDC6D8C61EC7413B7217CF26961F7D1"} -->
                        <!-- {CLOSE:a47cdf12-5dd7-44a5-8e40-3cef25fde4d2} {} -->
                    </div>
                    <!-- {POSTCLOSE:a47cdf12-5dd7-44a5-8e40-3cef25fde4d2} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
                    <!-- {PREOPEN:14406eb7-bd5c-4f09-880b-7e9cb2bc3766} {"sha1":"52C012B16933145E631C1B06A677824CE132E1D2"} -->
                    <div class="ui-g-12 ui-md-9">
                        <!-- {OPEN:14406eb7-bd5c-4f09-880b-7e9cb2bc3766} {} -->
                        <!-- {OPEN:4dac52a5-da54-4c68-89f3-2225e83d005b} {"sha1":"0E435FA249D7D65FBF844574A28FDA076F10DD4D"} -->
                        <div class="word-break" [style.paddingTop.px]="'10'" [style.paddingLeft.px]="'0'" [style.paddingBottom.px]="'0'" [style.paddingRight.px]="'10'">
                            <label [ngStyle]='{"font-weight":"bold","font-size":"22px"}'>{{company?.name}}</label>
                        </div>
                        <!-- {CLOSE:4dac52a5-da54-4c68-89f3-2225e83d005b} {"sha1":"0E435FA249D7D65FBF844574A28FDA076F10DD4D"} -->
                        <!-- {CLOSE:14406eb7-bd5c-4f09-880b-7e9cb2bc3766} {} -->
                    </div>
                    <!-- {POSTCLOSE:14406eb7-bd5c-4f09-880b-7e9cb2bc3766} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
                    <!-- {PREOPEN:df0debfc-2295-4c76-b387-501aed2ad302} {"sha1":"46955B4A1AD32FE6B8A61EB8703D83AC48730BEF"} -->
                    <div class="ui-g-12 ui-md-2" *ngIf="!company?.favourite">
                        <!-- {OPEN:df0debfc-2295-4c76-b387-501aed2ad302} {} -->
                        <!-- {OPEN:c49e1567-2dcd-4467-a264-70f0f8e207f2} {"sha1":"78C126FF47B79FE7FD4B6A0063F6A2EE6CB0E109"} -->
                        <div [style.paddingTop.px]="'10'" [style.paddingLeft.px]="'0'" [style.paddingBottom.px]="'0'" [style.paddingRight.px]="'10'">
                            <button pButton type="button" icon="ui-icon-star" (click)="onStarClick($event, searchCompany)"></button>
                        </div>
                        <!-- {CLOSE:c49e1567-2dcd-4467-a264-70f0f8e207f2} {"sha1":"78C126FF47B79FE7FD4B6A0063F6A2EE6CB0E109"} -->
                        <!-- {CLOSE:df0debfc-2295-4c76-b387-501aed2ad302} {} -->
                    </div>
                    <!-- {POSTCLOSE:df0debfc-2295-4c76-b387-501aed2ad302} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
                    <!-- {CLOSE:c99b2e6d-2e39-454c-9678-65ad691a1b24} {} -->
                </div>
                <!-- {POSTCLOSE:c99b2e6d-2e39-454c-9678-65ad691a1b24} {"sha1":"C4A15ED98D4B138EEB595266FB1CF4C91CF45CA5"} -->
                <!-- {CLOSE:060f2713-62d1-4a83-a395-32c68c92799e} {} -->
            </div>
            <!-- {CLOSE:29352308-ed83-4d4f-ae29-0964cc361f86} {} -->
        </div>
        <!-- {POSTCLOSE:29352308-ed83-4d4f-ae29-0964cc361f86} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:30e4f834-89ae-4e1b-94fe-ad33ab41e88d} {"sha1":"1DDC99517E71CFAC56BE8D051495D03C2FFA597C"} -->
        <div class="ui-g-12 ui-md-5">
            <!-- {OPEN:30e4f834-89ae-4e1b-94fe-ad33ab41e88d} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
            <!-- {CLOSE:30e4f834-89ae-4e1b-94fe-ad33ab41e88d} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
        </div>
        <!-- {POSTCLOSE:30e4f834-89ae-4e1b-94fe-ad33ab41e88d} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {CLOSE:85ce5612-9268-499e-905b-0d1f6b4a68c2} {} -->
    </div>
    <!-- {POSTCLOSE:85ce5612-9268-499e-905b-0d1f6b4a68c2} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
    <!-- {PREOPEN:37a95b69-a9c8-431d-b2ea-cdfe03d40860} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
    <div class="ui-g form-group">
        <!-- {OPEN:37a95b69-a9c8-431d-b2ea-cdfe03d40860} {} -->
        <!-- {PREOPEN:7e877322-eb42-4b72-9455-4bb25491f857} {"sha1":"EBBFF12A35073AE4D8CC2445170CE6BA15B1F43B"} -->
        <div class="ui-g-12 ui-md-7">
            <!-- {OPEN:7e877322-eb42-4b72-9455-4bb25491f857} {} -->
            <!-- {OPEN:a5a24e20-0ea6-4e3b-b4a7-021db67e7e70} {"sha1":"4CEB47BDAF95A2FE54BD2485C7474B787D7FE995"} -->
            <div [style.paddingTop.px]="'0'" [style.paddingLeft.px]="'34'" [style.paddingBottom.px]="'0'" [style.paddingRight.px]="'10'">
                <label [ngStyle]='{"font-size":"18px","font-style":"italic"}'>{{appService.getModuleAddress(company)}}</label>
            </div>
            <!-- {CLOSE:a5a24e20-0ea6-4e3b-b4a7-021db67e7e70} {"sha1":"4CEB47BDAF95A2FE54BD2485C7474B787D7FE995"} -->
            <!-- {CLOSE:7e877322-eb42-4b72-9455-4bb25491f857} {} -->
        </div>
        <!-- {POSTCLOSE:7e877322-eb42-4b72-9455-4bb25491f857} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:345e3482-7fbf-4be5-8ab2-1835e0fcd237} {"sha1":"1DDC99517E71CFAC56BE8D051495D03C2FFA597C"} -->
        <div class="ui-g-12 ui-md-5">
            <!-- {OPEN:345e3482-7fbf-4be5-8ab2-1835e0fcd237} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
            <!-- {CLOSE:345e3482-7fbf-4be5-8ab2-1835e0fcd237} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
        </div>
        <!-- {POSTCLOSE:345e3482-7fbf-4be5-8ab2-1835e0fcd237} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {CLOSE:37a95b69-a9c8-431d-b2ea-cdfe03d40860} {} -->
    </div>
    <!-- {POSTCLOSE:37a95b69-a9c8-431d-b2ea-cdfe03d40860} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
    <!-- {PREOPEN:54ac3ff7-d0a9-4389-b12a-25426ca190c1} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
    <div class="ui-g form-group">
        <!-- {OPEN:54ac3ff7-d0a9-4389-b12a-25426ca190c1} {} -->
        <!-- {PREOPEN:06844c6d-c75c-406c-bccd-9f1c08cf92eb} {"sha1":"EBBFF12A35073AE4D8CC2445170CE6BA15B1F43B"} -->
        <div class="ui-g-12 ui-md-12">
            <!-- {OPEN:06844c6d-c75c-406c-bccd-9f1c08cf92eb} {} -->
            <div [style.paddingTop.px]="'0'" [style.paddingLeft.px]="'21'" [style.paddingBottom.px]="'10'" [style.paddingRight.px]="'10'">
                <!-- {OPEN:81274a00-06db-4c29-9df0-f7a46908ef44} {} -->
                <!-- {PREOPEN:971e175e-be65-4972-877e-ba026007c377} {"sha1":"3FC2A784D228EBE141639FC8310267F06032ABA6"} -->
                <div class="ui-g form-group">
                    <!-- {OPEN:971e175e-be65-4972-877e-ba026007c377} {} -->
                    <!-- {PREOPEN:a308c232-ae86-4ec1-8411-15d32fab7e43} {"sha1":"954B341197CDAA53F3513D0137B988210DBE4497"} -->
                    <div class="ui-g-12 ui-md-2" *ngIf="company?.id">
                        <!-- {OPEN:a308c232-ae86-4ec1-8411-15d32fab7e43} {} -->
                        <!-- {OPEN:5f1ef0cc-a35c-478b-bb5e-e53ff5440a52} {"sha1":"285E9A08FC39E69FA3D11E87FEBA986E0F1FBE65"} -->
                        <span class="ui-float-label sticky">
                            <span>{{company?.id}}</span>
                            <label>Company ID</label>
                        </span>
                        <!-- {CLOSE:5f1ef0cc-a35c-478b-bb5e-e53ff5440a52} {"sha1":"285E9A08FC39E69FA3D11E87FEBA986E0F1FBE65"} -->
                        <!-- {CLOSE:a308c232-ae86-4ec1-8411-15d32fab7e43} {} -->
                    </div>
                    <!-- {POSTCLOSE:a308c232-ae86-4ec1-8411-15d32fab7e43} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
                    <!-- {PREOPEN:4f1e2f06-6c9f-4fc3-9d7b-c77fee84ba1e} {"sha1":"954B341197CDAA53F3513D0137B988210DBE4497"} -->
                    <div class="ui-g-12 ui-md-3" *ngIf="company?.phone">
                        <!-- {OPEN:4f1e2f06-6c9f-4fc3-9d7b-c77fee84ba1e} {} -->
                        <!-- {OPEN:c9671836-b7cc-407c-8da5-8dead7200ae3} {"sha1":"DC65C996C8F0131A729188EEFDAA0DB83E5E6AF1"} -->
                        <span class="ui-float-label sticky">
                            <span [ngStyle]='{}'>{{company?.phone}}</span>
                            <label>Company Phone</label>
                        </span>
                        <!-- {CLOSE:c9671836-b7cc-407c-8da5-8dead7200ae3} {"sha1":"DC65C996C8F0131A729188EEFDAA0DB83E5E6AF1"} -->
                        <!-- {CLOSE:4f1e2f06-6c9f-4fc3-9d7b-c77fee84ba1e} {} -->
                    </div>
                    <!-- {POSTCLOSE:4f1e2f06-6c9f-4fc3-9d7b-c77fee84ba1e} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
                    <!-- {PREOPEN:6dd9fcc4-0113-426c-b645-4111393ed53c} {"sha1":"954B341197CDAA53F3513D0137B988210DBE4497"} -->
                    <div class="ui-g-12 ui-md-3" *ngIf="company?.website">
                        <!-- {OPEN:6dd9fcc4-0113-426c-b645-4111393ed53c} {} -->
                        <!-- {OPEN:abf30c38-07fb-48ea-b1cb-66e284885905} {"sha1":"D14A7A877CC04BE80D6490F640AE7E26B354AD9F"} -->
                        <span class="ui-float-label sticky">
                            <a [ngStyle]='{"color":"#1d8cb5"}' [href]="company?.website">{{company?.website}}</a>
                            <label>Website</label>
                        </span>
                        <!-- {CLOSE:abf30c38-07fb-48ea-b1cb-66e284885905} {"sha1":"D14A7A877CC04BE80D6490F640AE7E26B354AD9F"} -->
                        <!-- {CLOSE:6dd9fcc4-0113-426c-b645-4111393ed53c} {} -->
                    </div>
                    <!-- {POSTCLOSE:6dd9fcc4-0113-426c-b645-4111393ed53c} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
                    <!-- {PREOPEN:88160c3e-88ec-45c0-a5e2-66f7222fa332} {"sha1":"954B341197CDAA53F3513D0137B988210DBE4497"} -->
                    <div class="ui-g-12 ui-md-3" *ngIf="company?.email">
                        <!-- {OPEN:88160c3e-88ec-45c0-a5e2-66f7222fa332} {} -->
                        <!-- {OPEN:73d1628d-d83d-41b7-bc20-350230a0f119} {"sha1":"9133CA2540D8BF7DD78B45B247D32ED3E4D9B8DB"} -->
                        <span class="ui-float-label sticky">
                            <a [ngStyle]='{"color":"#1d8cb5"}' href="javascript:void(0)" (click)="onComClick($event, searchCompany)">{{company?.email}}</a>
                            <label>Email</label>
                        </span>
                        <!-- {CLOSE:73d1628d-d83d-41b7-bc20-350230a0f119} {"sha1":"9133CA2540D8BF7DD78B45B247D32ED3E4D9B8DB"} -->
                        <!-- {CLOSE:88160c3e-88ec-45c0-a5e2-66f7222fa332} {} -->
                    </div>
                    <!-- {POSTCLOSE:88160c3e-88ec-45c0-a5e2-66f7222fa332} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
                    <!-- {CLOSE:971e175e-be65-4972-877e-ba026007c377} {} -->
                </div>
                <!-- {POSTCLOSE:971e175e-be65-4972-877e-ba026007c377} {"sha1":"C4A15ED98D4B138EEB595266FB1CF4C91CF45CA5"} -->
                <!-- {PREOPEN:e76e19a9-41c7-4be9-b850-c7f55a81fead} {"sha1":"3FC2A784D228EBE141639FC8310267F06032ABA6"} -->
                <div class="ui-g form-group">
                    <!-- {OPEN:e76e19a9-41c7-4be9-b850-c7f55a81fead} {} -->
                    <!-- {PREOPEN:b52304de-8688-4ecb-b432-a59605e29f20} {"sha1":"954B341197CDAA53F3513D0137B988210DBE4497"} -->
                    <div class="ui-g-12 ui-md-3" *ngIf="company?.published">
                        <!-- {OPEN:b52304de-8688-4ecb-b432-a59605e29f20} {} -->
                        <!-- {OPEN:dda87b39-d497-44b6-bd09-f83ca9bee1f6} {"sha1":"595C9714ABA20E06FA56BD45043EAFAD5823896E"} -->
                        <span class="ui-float-label sticky">
                            <span>{{company?.published}}</span>
                            <label>Published</label>
                        </span>
                        <!-- {CLOSE:dda87b39-d497-44b6-bd09-f83ca9bee1f6} {"sha1":"595C9714ABA20E06FA56BD45043EAFAD5823896E"} -->
                        <!-- {CLOSE:b52304de-8688-4ecb-b432-a59605e29f20} {} -->
                    </div>
                    <!-- {POSTCLOSE:b52304de-8688-4ecb-b432-a59605e29f20} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
                    <!-- {PREOPEN:83710d26-7426-41ee-8229-447c27d4a547} {"sha1":"954B341197CDAA53F3513D0137B988210DBE4497"} -->
                    <div class="ui-g-12 ui-md-3" *ngIf="company?.countProjectId">
                        <!-- {OPEN:83710d26-7426-41ee-8229-447c27d4a547} {} -->
                        <!-- {OPEN:8d1b3062-5e58-498a-8842-df6c25264e42} {"sha1":"5862FB5FA0BBC7479FBB0AFB0E77A69662C303AE"} -->
                        <span class="ui-float-label sticky">
                            <span>{{company?.countProjectId}}</span>
                            <label>Projects</label>
                        </span>
                        <!-- {CLOSE:8d1b3062-5e58-498a-8842-df6c25264e42} {"sha1":"5862FB5FA0BBC7479FBB0AFB0E77A69662C303AE"} -->
                        <!-- {CLOSE:83710d26-7426-41ee-8229-447c27d4a547} {} -->
                    </div>
                    <!-- {POSTCLOSE:83710d26-7426-41ee-8229-447c27d4a547} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
                    <!-- {PREOPEN:887ef9af-ca21-41b9-a0c0-12adf253efdb} {"sha1":"954B341197CDAA53F3513D0137B988210DBE4497"} -->
                    <div class="ui-g-12 ui-md-3" *ngIf="company?.value">
                        <!-- {OPEN:887ef9af-ca21-41b9-a0c0-12adf253efdb} {} -->
                        <!-- {OPEN:5ffde0ac-c9c3-4b97-9b25-13c6dc0f736a} {"sha1":"91EEADD41D598AAB4D81BB54BFB5DD03FC11CC67"} -->
                        <span class="ui-float-label sticky">
                            <span [ngStyle]='{}'>{{company?.value}}</span>
                            <label>Value</label>
                        </span>
                        <!-- {CLOSE:5ffde0ac-c9c3-4b97-9b25-13c6dc0f736a} {"sha1":"91EEADD41D598AAB4D81BB54BFB5DD03FC11CC67"} -->
                        <!-- {CLOSE:887ef9af-ca21-41b9-a0c0-12adf253efdb} {} -->
                    </div>
                    <!-- {POSTCLOSE:887ef9af-ca21-41b9-a0c0-12adf253efdb} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
                    <!-- {PREOPEN:70cd828c-b76c-46af-be69-8d457e384130} {"sha1":"954B341197CDAA53F3513D0137B988210DBE4497"} -->
                    <div class="ui-g-12 ui-md-3">
                        <!-- {OPEN:70cd828c-b76c-46af-be69-8d457e384130} {"sha1":"8BEC3C924176AAC3BBA01D8EE03BD3600A7328AD"} -->
                        <!-- {CLOSE:70cd828c-b76c-46af-be69-8d457e384130} {"sha1":"8BEC3C924176AAC3BBA01D8EE03BD3600A7328AD"} -->
                    </div>
                    <!-- {POSTCLOSE:70cd828c-b76c-46af-be69-8d457e384130} {"sha1":"935CDAAB320026ABDA6EF579D089FE20C444D9E7"} -->
                    <!-- {CLOSE:e76e19a9-41c7-4be9-b850-c7f55a81fead} {} -->
                </div>
                <!-- {POSTCLOSE:e76e19a9-41c7-4be9-b850-c7f55a81fead} {"sha1":"C4A15ED98D4B138EEB595266FB1CF4C91CF45CA5"} -->
                <!-- {CLOSE:81274a00-06db-4c29-9df0-f7a46908ef44} {} -->
            </div>
            <!-- {CLOSE:06844c6d-c75c-406c-bccd-9f1c08cf92eb} {} -->
        </div>
        <!-- {POSTCLOSE:06844c6d-c75c-406c-bccd-9f1c08cf92eb} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {PREOPEN:ddc491c4-c14c-4a71-92fc-1d85cf1fb827} {"sha1":"1DDC99517E71CFAC56BE8D051495D03C2FFA597C"} -->
        <div class="ui-g-12 ui-md-5">
            <!-- {OPEN:ddc491c4-c14c-4a71-92fc-1d85cf1fb827} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
            <!-- {CLOSE:ddc491c4-c14c-4a71-92fc-1d85cf1fb827} {"sha1":"0075569A5A4198B9812BE41EAB8956188AA19E55"} -->
        </div>
        <!-- {POSTCLOSE:ddc491c4-c14c-4a71-92fc-1d85cf1fb827} {"sha1":"7B3A335E465C0E9D884659C77CD3394D370EF747"} -->
        <!-- {CLOSE:54ac3ff7-d0a9-4389-b12a-25426ca190c1} {} -->
    </div>
    <!-- {POSTCLOSE:54ac3ff7-d0a9-4389-b12a-25426ca190c1} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
    <!-- {CLOSE:65cc669d-daa8-471a-b32b-f0a9650b2ca3} {} -->
    <!-- {PREOPEN:13587727-626d-4a69-8a2e-9de0d5d6f3ae} {"sha1":"5D2DE1328FBB4926B4A43583E63EC450C39B8F63"} -->
    <p-tabView orientation="top" [(activeIndex)]="activeTabIndex" (onChange)="onTabChange($event, searchCompany)">
        <!-- {OPEN:13587727-626d-4a69-8a2e-9de0d5d6f3ae} {} -->
        <!-- {PREOPEN:8491d978-4a67-4e97-9704-cdb9e210b53e} {"sha1":"2F4AD25E391142BA75B6407DB1672DEAE5BF17A7"} -->
        <p-tabPanel >
            <!-- {OPEN:8491d978-4a67-4e97-9704-cdb9e210b53e} {} -->
            <!-- {PREOPEN:8491d978-4a67-4e97-9704-cdb9e210b53e-HEADER} {"sha1":"EFFD8873F35AFEDE07E793ED170A75F21D8735DE"} -->
            <ng-template pTemplate="header">
                <!-- {OPEN:8491d978-4a67-4e97-9704-cdb9e210b53e-HEADER} {"sha1":"B17697DFA491EF88959623E4E7B5C0223248C258"} -->
                <span>Roles</span>
                <!-- {CLOSE:8491d978-4a67-4e97-9704-cdb9e210b53e-HEADER} {"sha1":"B17697DFA491EF88959623E4E7B5C0223248C258"} -->
            </ng-template>
            <!-- {POSTCLOSE:8491d978-4a67-4e97-9704-cdb9e210b53e-HEADER} {"sha1":"08D2DC238814DBE0D3FB2F9729EBA749BB91A4A1"} -->
            <!-- {PREOPEN:f5a9dfdc-f5cc-438a-9b36-6182c48cd418} {"sha1":"0F6111EEB7B1367909B3AAD116248CD843B17437"} -->
            <p-table #rolesTable [columns]="rolesTableCols" [value]="roles"
                [selectionMode]="rolesTableSelectionMode" (onRowSelect)="onRolesTableRowSelect($event.data)" [paginator]="true" [rows]="10" [rowsPerPageOptions]='["10", "20", "30"]'>
                <!-- {OPEN:f5a9dfdc-f5cc-438a-9b36-6182c48cd418} {} -->
                <!-- {PREOPEN:COLGROUP-f5a9dfdc-f5cc-438a-9b36-6182c48cd418} {"sha1":"3DC6D8C9B1E7B0223D1CCE76C1C82A288C9A4689"} -->
                <ng-template pTemplate="colgroup" let-columns>
                    <!-- {OPEN:COLGROUP-f5a9dfdc-f5cc-438a-9b36-6182c48cd418} {"sha1":"13C60BD53C8B56A370AADCD09793D14BBC6FE2C9"} -->
                    <colgroup>
                        <col *ngFor="let col of columns" [style.width]="col.width">
                    </colgroup>
                    <!-- {CLOSE:COLGROUP-f5a9dfdc-f5cc-438a-9b36-6182c48cd418} {"sha1":"13C60BD53C8B56A370AADCD09793D14BBC6FE2C9"} -->
                </ng-template>
                <!-- {POSTCLOSE:COLGROUP-f5a9dfdc-f5cc-438a-9b36-6182c48cd418} {"sha1":"6D02DE4A18BCD9958BB50B15D6A5C9E85A898BF6"} -->
                <!-- {PREOPEN:HEADER-f5a9dfdc-f5cc-438a-9b36-6182c48cd418} {"sha1":"A4D3A80E2BCC0A34677F3E2585615CA43EAC9D20"} -->
                <ng-template pTemplate="header" let-columns>
                    <!-- {OPEN:HEADER-f5a9dfdc-f5cc-438a-9b36-6182c48cd418} {"sha1":"204EC507787942D97F16B31A57EBBE68ED191160"} -->
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.sortField || col.field" [pSortableColumnDisabled]="col.noSort">
                            {{ col.header }}
                            <p-sortIcon [field]="col.sortField || col.field" *ngIf="!col.noSort"></p-sortIcon>
                        </th>
                    </tr>
                    <tr>
                        <th *ngFor="let col of columns">
                            <input *ngIf="!col.noFilter" pInputText type="text" (input)="rolesTable.filter($event.target.value, col.field, 'contains')">
                        </th>
                    </tr>
                    <!-- {CLOSE:HEADER-f5a9dfdc-f5cc-438a-9b36-6182c48cd418} {"sha1":"204EC507787942D97F16B31A57EBBE68ED191160"} -->
                </ng-template>
                <!-- {POSTCLOSE:HEADER-f5a9dfdc-f5cc-438a-9b36-6182c48cd418} {"sha1":"6D02DE4A18BCD9958BB50B15D6A5C9E85A898BF6"} -->
                <!-- {PREOPEN:BODY-f5a9dfdc-f5cc-438a-9b36-6182c48cd418} {"sha1":"6345486287546C5AD52CE54658AE62B533BE341D"} -->
                <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                    <!-- {OPEN:BODY-f5a9dfdc-f5cc-438a-9b36-6182c48cd418} {} -->
                    <!-- {PREOPEN:393c4c27-8f67-44e4-bddc-2a0f3da9669b} {"sha1":"51821334AB459A557D9D801715E87D5FBA8F8511"} -->
                    <tr [pSelectableRow]="rowData" [pSelectableRowDisabled]="rolesTableSelectionMode ? false : true">
                        <!-- {OPEN:393c4c27-8f67-44e4-bddc-2a0f3da9669b} {} -->
                        <!-- {PREOPEN:8455d35e-5562-44d2-9409-05b8e5b6071e} {"sha1":"2A2D269FD32FDF0E87FA25980833D1BC3670DAA7"} -->
                        <td>
                            <!-- {OPEN:8455d35e-5562-44d2-9409-05b8e5b6071e} {} -->
                            <!-- {OPEN:250bc0cb-686f-4379-a48e-8b272f83d85b} {"sha1":"B116F5821D63A534E37E11AFF76EB3D9985A687A"} -->
                            <label>{{ rowData.role?.roleDescription }}</label>
                            <!-- {CLOSE:250bc0cb-686f-4379-a48e-8b272f83d85b} {"sha1":"B116F5821D63A534E37E11AFF76EB3D9985A687A"} -->
                            <!-- {CLOSE:8455d35e-5562-44d2-9409-05b8e5b6071e} {} -->
                        </td>
                        <!-- {POSTCLOSE:8455d35e-5562-44d2-9409-05b8e5b6071e} {"sha1":"3481EDE2B787707391B17836799739A723650553"} -->
                        <!-- {PREOPEN:3f424672-185a-4730-9790-ce56e6527c4d} {"sha1":"2A2D269FD32FDF0E87FA25980833D1BC3670DAA7"} -->
                        <td>
                            <!-- {OPEN:3f424672-185a-4730-9790-ce56e6527c4d} {} -->
                            <!-- {OPEN:30721ad5-392d-46c5-83b1-e36aa48836b6} {"sha1":"9E60DF298F0494AF276D887CEED05B8C2DFAF199"} -->
                            <a [ngStyle]='{"color":"#050505"}' [routerLink]="'/search/projects/'+ rowData.project?.id">{{ rowData.project?.title }}</a>
                            <!-- {CLOSE:30721ad5-392d-46c5-83b1-e36aa48836b6} {"sha1":"9E60DF298F0494AF276D887CEED05B8C2DFAF199"} -->
                            <!-- {CLOSE:3f424672-185a-4730-9790-ce56e6527c4d} {} -->
                        </td>
                        <!-- {POSTCLOSE:3f424672-185a-4730-9790-ce56e6527c4d} {"sha1":"3481EDE2B787707391B17836799739A723650553"} -->
                        <!-- {PREOPEN:92c0b58b-cf3b-4118-8a54-155f8b275826} {"sha1":"2A2D269FD32FDF0E87FA25980833D1BC3670DAA7"} -->
                        <td>
                            <!-- {OPEN:92c0b58b-cf3b-4118-8a54-155f8b275826} {} -->
                            <!-- {OPEN:a7c3a526-4da2-4d68-b5f7-bcacc18f86f7} {"sha1":"4586CCB5BEC433DFEAFAB128753BF81DF4E385D2"} -->
                            <a [ngStyle]='{"color":"#030303"}' [routerLink]="'/contacts/'+rowData.people?.id">{{ rowData.people?.fullName }}</a>
                            <!-- {CLOSE:a7c3a526-4da2-4d68-b5f7-bcacc18f86f7} {"sha1":"4586CCB5BEC433DFEAFAB128753BF81DF4E385D2"} -->
                            <!-- {CLOSE:92c0b58b-cf3b-4118-8a54-155f8b275826} {} -->
                        </td>
                        <!-- {POSTCLOSE:92c0b58b-cf3b-4118-8a54-155f8b275826} {"sha1":"3481EDE2B787707391B17836799739A723650553"} -->
                        <!-- {CLOSE:393c4c27-8f67-44e4-bddc-2a0f3da9669b} {} -->
                    </tr>
                    <!-- {POSTCLOSE:393c4c27-8f67-44e4-bddc-2a0f3da9669b} {"sha1":"A88D4F820D8E19D78F33837AE3FDCDC0295AD32E"} -->
                    <!-- {CLOSE:BODY-f5a9dfdc-f5cc-438a-9b36-6182c48cd418} {} -->
                </ng-template>
                <!-- {POSTCLOSE:BODY-f5a9dfdc-f5cc-438a-9b36-6182c48cd418} {"sha1":"6D02DE4A18BCD9958BB50B15D6A5C9E85A898BF6"} -->
                <!-- {CLOSE:f5a9dfdc-f5cc-438a-9b36-6182c48cd418} {} -->
            </p-table>
            <!-- {POSTCLOSE:f5a9dfdc-f5cc-438a-9b36-6182c48cd418} {"sha1":"5EF6146E1F710ACFFC7D61E0ED2D805CCA4FEF5A"} -->
            <!-- {CLOSE:8491d978-4a67-4e97-9704-cdb9e210b53e} {} -->
        </p-tabPanel>
        <!-- {POSTCLOSE:8491d978-4a67-4e97-9704-cdb9e210b53e} {"sha1":"F49AEAFEC8DDF0FE02750884DD3DAEAF27C428C6"} -->
        <!-- {PREOPEN:383620d7-71de-406c-9da9-20d8bb6e1640} {"sha1":"2F4AD25E391142BA75B6407DB1672DEAE5BF17A7"} -->
        <p-tabPanel >
            <!-- {OPEN:383620d7-71de-406c-9da9-20d8bb6e1640} {} -->
            <!-- {PREOPEN:383620d7-71de-406c-9da9-20d8bb6e1640-HEADER} {"sha1":"EFFD8873F35AFEDE07E793ED170A75F21D8735DE"} -->
            <ng-template pTemplate="header">
                <!-- {OPEN:383620d7-71de-406c-9da9-20d8bb6e1640-HEADER} {"sha1":"AC7EE11BD1DF0271A229AD1218333E85882C5099"} -->
                <span>Accounts</span>
                <!-- {CLOSE:383620d7-71de-406c-9da9-20d8bb6e1640-HEADER} {"sha1":"AC7EE11BD1DF0271A229AD1218333E85882C5099"} -->
            </ng-template>
            <!-- {POSTCLOSE:383620d7-71de-406c-9da9-20d8bb6e1640-HEADER} {"sha1":"08D2DC238814DBE0D3FB2F9729EBA749BB91A4A1"} -->
            <!-- {PREOPEN:042eef17-3223-4cd4-962e-e085f6e87014} {"sha1":"E6E8A899EE1F68307479D2F61C17FB0803070720"} -->
            <p-table #accountsTable [columns]="accountsTableCols" [value]="accounts"
                [selectionMode]="accountsTableSelectionMode" (onRowSelect)="onAccountsTableRowSelect($event.data)" [paginator]="true" [rows]="10" [rowsPerPageOptions]='["10", "20", "30"]'>
                <!-- {OPEN:042eef17-3223-4cd4-962e-e085f6e87014} {} -->
                <!-- {PREOPEN:COLGROUP-042eef17-3223-4cd4-962e-e085f6e87014} {"sha1":"3DC6D8C9B1E7B0223D1CCE76C1C82A288C9A4689"} -->
                <ng-template pTemplate="colgroup" let-columns>
                    <!-- {OPEN:COLGROUP-042eef17-3223-4cd4-962e-e085f6e87014} {"sha1":"13C60BD53C8B56A370AADCD09793D14BBC6FE2C9"} -->
                    <colgroup>
                        <col *ngFor="let col of columns" [style.width]="col.width">
                    </colgroup>
                    <!-- {CLOSE:COLGROUP-042eef17-3223-4cd4-962e-e085f6e87014} {"sha1":"13C60BD53C8B56A370AADCD09793D14BBC6FE2C9"} -->
                </ng-template>
                <!-- {POSTCLOSE:COLGROUP-042eef17-3223-4cd4-962e-e085f6e87014} {"sha1":"6D02DE4A18BCD9958BB50B15D6A5C9E85A898BF6"} -->
                <!-- {PREOPEN:HEADER-042eef17-3223-4cd4-962e-e085f6e87014} {"sha1":"A4D3A80E2BCC0A34677F3E2585615CA43EAC9D20"} -->
                <ng-template pTemplate="header" let-columns>
                    <!-- {OPEN:HEADER-042eef17-3223-4cd4-962e-e085f6e87014} {"sha1":"1FF6BECC755088F5C7C2BE843A83FE71F853BA89"} -->
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.sortField || col.field" [pSortableColumnDisabled]="col.noSort">
                            {{ col.header }}
                            <p-sortIcon [field]="col.sortField || col.field" *ngIf="!col.noSort"></p-sortIcon>
                        </th>
                    </tr>
                    <!-- {CLOSE:HEADER-042eef17-3223-4cd4-962e-e085f6e87014} {"sha1":"1FF6BECC755088F5C7C2BE843A83FE71F853BA89"} -->
                </ng-template>
                <!-- {POSTCLOSE:HEADER-042eef17-3223-4cd4-962e-e085f6e87014} {"sha1":"6D02DE4A18BCD9958BB50B15D6A5C9E85A898BF6"} -->
                <!-- {PREOPEN:BODY-042eef17-3223-4cd4-962e-e085f6e87014} {"sha1":"6345486287546C5AD52CE54658AE62B533BE341D"} -->
                <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                    <!-- {OPEN:BODY-042eef17-3223-4cd4-962e-e085f6e87014} {} -->
                    <!-- {PREOPEN:fb1d3243-401f-4b90-b8b6-8a2c7ae7039d} {"sha1":"884B02456B8D7717114B57A850CA5FFBD0C312E0"} -->
                    <tr [pSelectableRow]="rowData" [pSelectableRowDisabled]="accountsTableSelectionMode ? false : true">
                        <!-- {OPEN:fb1d3243-401f-4b90-b8b6-8a2c7ae7039d} {} -->
                        <!-- {PREOPEN:9a717b90-92da-427a-906e-201eb533169f} {"sha1":"2A2D269FD32FDF0E87FA25980833D1BC3670DAA7"} -->
                        <td>
                            <!-- {OPEN:9a717b90-92da-427a-906e-201eb533169f} {} -->
                            <!-- {OPEN:2431038b-385c-4268-a4fa-54fe8dd2b28e} {"sha1":"250A2B1CB513007FE475CDAE7D135AC929D30B63"} -->
                            <label>{{ rowData.name }}</label>
                            <!-- {CLOSE:2431038b-385c-4268-a4fa-54fe8dd2b28e} {"sha1":"250A2B1CB513007FE475CDAE7D135AC929D30B63"} -->
                            <!-- {CLOSE:9a717b90-92da-427a-906e-201eb533169f} {} -->
                        </td>
                        <!-- {POSTCLOSE:9a717b90-92da-427a-906e-201eb533169f} {"sha1":"3481EDE2B787707391B17836799739A723650553"} -->
                        <!-- {PREOPEN:44a7ef7e-bd3e-4b99-b1e2-d754540c9d3a} {"sha1":"2A2D269FD32FDF0E87FA25980833D1BC3670DAA7"} -->
                        <td>
                            <!-- {OPEN:44a7ef7e-bd3e-4b99-b1e2-d754540c9d3a} {} -->
                            <!-- {OPEN:d0bd1c2b-342c-4de5-bea1-adbd545dd753} {"sha1":"F658044684F59984D3332625E32CD83502192886"} -->
                            <label>{{ rowData.ownerName }}</label>
                            <!-- {CLOSE:d0bd1c2b-342c-4de5-bea1-adbd545dd753} {"sha1":"F658044684F59984D3332625E32CD83502192886"} -->
                            <!-- {CLOSE:44a7ef7e-bd3e-4b99-b1e2-d754540c9d3a} {} -->
                        </td>
                        <!-- {POSTCLOSE:44a7ef7e-bd3e-4b99-b1e2-d754540c9d3a} {"sha1":"3481EDE2B787707391B17836799739A723650553"} -->
                        <!-- {PREOPEN:486dfd2f-930b-4c06-8f7b-ced9c354d6e5} {"sha1":"2A2D269FD32FDF0E87FA25980833D1BC3670DAA7"} -->
                        <td>
                            <!-- {OPEN:486dfd2f-930b-4c06-8f7b-ced9c354d6e5} {} -->
                            <!-- {OPEN:84d3d026-306e-4822-89e3-adc8b3bcb036} {"sha1":"6E7CAEC153776841C8BED36E81C46CEAA9117806"} -->
                            <label>{{ rowData.createdDate }}</label>
                            <!-- {CLOSE:84d3d026-306e-4822-89e3-adc8b3bcb036} {"sha1":"6E7CAEC153776841C8BED36E81C46CEAA9117806"} -->
                            <!-- {CLOSE:486dfd2f-930b-4c06-8f7b-ced9c354d6e5} {} -->
                        </td>
                        <!-- {POSTCLOSE:486dfd2f-930b-4c06-8f7b-ced9c354d6e5} {"sha1":"3481EDE2B787707391B17836799739A723650553"} -->
                        <!-- {CLOSE:fb1d3243-401f-4b90-b8b6-8a2c7ae7039d} {} -->
                    </tr>
                    <!-- {POSTCLOSE:fb1d3243-401f-4b90-b8b6-8a2c7ae7039d} {"sha1":"A88D4F820D8E19D78F33837AE3FDCDC0295AD32E"} -->
                    <!-- {CLOSE:BODY-042eef17-3223-4cd4-962e-e085f6e87014} {} -->
                </ng-template>
                <!-- {POSTCLOSE:BODY-042eef17-3223-4cd4-962e-e085f6e87014} {"sha1":"6D02DE4A18BCD9958BB50B15D6A5C9E85A898BF6"} -->
                <!-- {CLOSE:042eef17-3223-4cd4-962e-e085f6e87014} {} -->
            </p-table>
            <!-- {POSTCLOSE:042eef17-3223-4cd4-962e-e085f6e87014} {"sha1":"5EF6146E1F710ACFFC7D61E0ED2D805CCA4FEF5A"} -->
            <!-- {CLOSE:383620d7-71de-406c-9da9-20d8bb6e1640} {} -->
        </p-tabPanel>
        <!-- {POSTCLOSE:383620d7-71de-406c-9da9-20d8bb6e1640} {"sha1":"F49AEAFEC8DDF0FE02750884DD3DAEAF27C428C6"} -->
        <!-- {PREOPEN:7c61b98f-d705-4bba-b6b9-8b9536a88820} {"sha1":"2F4AD25E391142BA75B6407DB1672DEAE5BF17A7"} -->
        <p-tabPanel >
            <!-- {OPEN:7c61b98f-d705-4bba-b6b9-8b9536a88820} {} -->
            <!-- {PREOPEN:7c61b98f-d705-4bba-b6b9-8b9536a88820-HEADER} {"sha1":"EFFD8873F35AFEDE07E793ED170A75F21D8735DE"} -->
            <ng-template pTemplate="header">
                <!-- {OPEN:7c61b98f-d705-4bba-b6b9-8b9536a88820-HEADER} {"sha1":"FEFA7F4FF7FD43EF3B96227EC7BE3F258B4E6E31"} -->
                <span>Updates</span>
                <!-- {CLOSE:7c61b98f-d705-4bba-b6b9-8b9536a88820-HEADER} {"sha1":"FEFA7F4FF7FD43EF3B96227EC7BE3F258B4E6E31"} -->
            </ng-template>
            <!-- {POSTCLOSE:7c61b98f-d705-4bba-b6b9-8b9536a88820-HEADER} {"sha1":"08D2DC238814DBE0D3FB2F9729EBA749BB91A4A1"} -->
            <!-- {PREOPEN:966e87d4-e118-455c-bd5f-e3b638ac1b99} {"sha1":"B6DA026B4BB7181F086076BF9861E1441514552F"} -->
            <p-table #updatesTable [columns]="updatesTableCols" [value]="updates"
                [selectionMode]="updatesTableSelectionMode" (onRowSelect)="onUpdatesTableRowSelect($event.data)">
                <!-- {OPEN:966e87d4-e118-455c-bd5f-e3b638ac1b99} {} -->
                <!-- {PREOPEN:COLGROUP-966e87d4-e118-455c-bd5f-e3b638ac1b99} {"sha1":"3DC6D8C9B1E7B0223D1CCE76C1C82A288C9A4689"} -->
                <ng-template pTemplate="colgroup" let-columns>
                    <!-- {OPEN:COLGROUP-966e87d4-e118-455c-bd5f-e3b638ac1b99} {"sha1":"13C60BD53C8B56A370AADCD09793D14BBC6FE2C9"} -->
                    <colgroup>
                        <col *ngFor="let col of columns" [style.width]="col.width">
                    </colgroup>
                    <!-- {CLOSE:COLGROUP-966e87d4-e118-455c-bd5f-e3b638ac1b99} {"sha1":"13C60BD53C8B56A370AADCD09793D14BBC6FE2C9"} -->
                </ng-template>
                <!-- {POSTCLOSE:COLGROUP-966e87d4-e118-455c-bd5f-e3b638ac1b99} {"sha1":"6D02DE4A18BCD9958BB50B15D6A5C9E85A898BF6"} -->
                <!-- {PREOPEN:HEADER-966e87d4-e118-455c-bd5f-e3b638ac1b99} {"sha1":"A4D3A80E2BCC0A34677F3E2585615CA43EAC9D20"} -->
                <ng-template pTemplate="header" let-columns>
                    <!-- {OPEN:HEADER-966e87d4-e118-455c-bd5f-e3b638ac1b99} {"sha1":"D57596F841E3C840D2B8B1CCB5EA0BAF01E03711"} -->
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.sortField || col.field" [pSortableColumnDisabled]="col.noSort">
                            {{ col.header }}
                            <p-sortIcon [field]="col.sortField || col.field" *ngIf="!col.noSort"></p-sortIcon>
                        </th>
                    </tr>
                    <tr>
                        <th *ngFor="let col of columns">
                            <input *ngIf="!col.noFilter" pInputText type="text" (input)="updatesTable.filter($event.target.value, col.field, 'contains')">
                        </th>
                    </tr>
                    <!-- {CLOSE:HEADER-966e87d4-e118-455c-bd5f-e3b638ac1b99} {"sha1":"D57596F841E3C840D2B8B1CCB5EA0BAF01E03711"} -->
                </ng-template>
                <!-- {POSTCLOSE:HEADER-966e87d4-e118-455c-bd5f-e3b638ac1b99} {"sha1":"6D02DE4A18BCD9958BB50B15D6A5C9E85A898BF6"} -->
                <!-- {PREOPEN:BODY-966e87d4-e118-455c-bd5f-e3b638ac1b99} {"sha1":"6345486287546C5AD52CE54658AE62B533BE341D"} -->
                <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                    <!-- {OPEN:BODY-966e87d4-e118-455c-bd5f-e3b638ac1b99} {} -->
                    <!-- {PREOPEN:ed251582-6a73-4867-882a-f5586b43d9e1} {"sha1":"5F0910A9B1CB580E95C37C6DD0C8CE84D3080DB7"} -->
                    <tr [pSelectableRow]="rowData" [pSelectableRowDisabled]="updatesTableSelectionMode ? false : true">
                        <!-- {OPEN:ed251582-6a73-4867-882a-f5586b43d9e1} {} -->
                        <!-- {PREOPEN:584bfec7-5f69-497f-93ca-4e252d296a4e} {"sha1":"2A2D269FD32FDF0E87FA25980833D1BC3670DAA7"} -->
                        <td>
                            <!-- {OPEN:584bfec7-5f69-497f-93ca-4e252d296a4e} {} -->
                            <!-- {OPEN:8c5fbc69-9c4a-448f-8131-f8d5b174a659} {"sha1":"7E520D07C1CC025C2DC1CA5CD4FD4718BCD484B9"} -->
                            <label>{{ rowData.description }}</label>
                            <!-- {CLOSE:8c5fbc69-9c4a-448f-8131-f8d5b174a659} {"sha1":"7E520D07C1CC025C2DC1CA5CD4FD4718BCD484B9"} -->
                            <!-- {CLOSE:584bfec7-5f69-497f-93ca-4e252d296a4e} {} -->
                        </td>
                        <!-- {POSTCLOSE:584bfec7-5f69-497f-93ca-4e252d296a4e} {"sha1":"3481EDE2B787707391B17836799739A723650553"} -->
                        <!-- {PREOPEN:c315ed58-07b8-4405-bd51-adab01b216f1} {"sha1":"2A2D269FD32FDF0E87FA25980833D1BC3670DAA7"} -->
                        <td>
                            <!-- {OPEN:c315ed58-07b8-4405-bd51-adab01b216f1} {} -->
                            <!-- {OPEN:78b58e3a-8311-4984-9f35-eb70d8576d08} {"sha1":"3C9AEE5ADF3630EB7FDC455D4B5D3E0E90D7C9B5"} -->
                            <label>{{ rowData.updateDate }}</label>
                            <!-- {CLOSE:78b58e3a-8311-4984-9f35-eb70d8576d08} {"sha1":"3C9AEE5ADF3630EB7FDC455D4B5D3E0E90D7C9B5"} -->
                            <!-- {CLOSE:c315ed58-07b8-4405-bd51-adab01b216f1} {} -->
                        </td>
                        <!-- {POSTCLOSE:c315ed58-07b8-4405-bd51-adab01b216f1} {"sha1":"3481EDE2B787707391B17836799739A723650553"} -->
                        <!-- {CLOSE:ed251582-6a73-4867-882a-f5586b43d9e1} {} -->
                    </tr>
                    <!-- {POSTCLOSE:ed251582-6a73-4867-882a-f5586b43d9e1} {"sha1":"A88D4F820D8E19D78F33837AE3FDCDC0295AD32E"} -->
                    <!-- {CLOSE:BODY-966e87d4-e118-455c-bd5f-e3b638ac1b99} {} -->
                </ng-template>
                <!-- {POSTCLOSE:BODY-966e87d4-e118-455c-bd5f-e3b638ac1b99} {"sha1":"6D02DE4A18BCD9958BB50B15D6A5C9E85A898BF6"} -->
                <!-- {CLOSE:966e87d4-e118-455c-bd5f-e3b638ac1b99} {} -->
            </p-table>
            <!-- {POSTCLOSE:966e87d4-e118-455c-bd5f-e3b638ac1b99} {"sha1":"5EF6146E1F710ACFFC7D61E0ED2D805CCA4FEF5A"} -->
            <!-- {CLOSE:7c61b98f-d705-4bba-b6b9-8b9536a88820} {} -->
        </p-tabPanel>
        <!-- {POSTCLOSE:7c61b98f-d705-4bba-b6b9-8b9536a88820} {"sha1":"F49AEAFEC8DDF0FE02750884DD3DAEAF27C428C6"} -->
        <!-- {CLOSE:13587727-626d-4a69-8a2e-9de0d5d6f3ae} {} -->
    </p-tabView>
    <!-- {POSTCLOSE:13587727-626d-4a69-8a2e-9de0d5d6f3ae} {"sha1":"5ECEB12B574903FC69B702C1DC533C590ADF76B3"} -->
    <oneit-confirmDialog></oneit-confirmDialog>
    <p-progressSpinner *ngIf="showLoader"></p-progressSpinner>
    <!-- {CLOSE:424e9751-600b-4beb-ae22-cac243c43386} {} -->
</form>
<!-- {POSTCLOSE:424e9751-600b-4beb-ae22-cac243c43386} {"sha1":"3FD6E659562C1876D02FC8CF54746B1502442CD2"} -->
