/* {OPEN:IMPORT} {"sha1":"E4027ACE0DE1931C68ED29418E5CD2E27F5FA02E"} */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseMultipleProjectDealPopUpComponent } from './base-multiple-project-deal-pop-up.component';
/* {CLOSE:IMPORT} {"sha1":"E4027ACE0DE1931C68ED29418E5CD2E27F5FA02E"} */
import { ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../oneit/services/user.service';
import { UtilsService } from '../../oneit/services/utils.service';
import { SearchService } from '../../oneit/services/search.service';
import { AppService } from '../../app.service';
import { ConfirmationService } from 'primeng/api';
import { MultipleProjectDealPopUpService } from './multiple-project-deal-pop-up.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng';
import { MsgsService } from '../../oneit/services/msgs.service';
import { NgForm } from '@angular/forms';
import { OptionsObservable, OptionsWrapper } from '../../oneit/models/options.model';
/* {POSTCLOSE:IMPORT} {"sha1":"E4027ACE0DE1931C68ED29418E5CD2E27F5FA02E"} */

/* {PREOPEN:CLASS} {"sha1":"13634D3EAE2257621B5BC2C8712221CA7791197C"} */
@Component({
    selector: 'app-multiple-project-deal-pop-up',
    templateUrl: './multiple-project-deal-pop-up.component.html'
})
export class MultipleProjectDealPopUpComponent extends BaseMultipleProjectDealPopUpComponent implements OnInit, OnDestroy {
    /* {OPEN:CLASS} {} */

    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
        super.ngOnInit();
        /* {CLOSE:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
    }

    ngOnDestroy() {
        /* {OPEN:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
        super.ngOnDestroy();
        /* {CLOSE:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
    }

    /* {CLOSE:CLASS} {} */

    @ViewChild('form') form : NgForm;
    errorMsgs = [];

    //@override
    constructor(
        public router : Router,
        public userService : UserService,
        public utilsService : UtilsService,
        public searchService : SearchService,
        public appService : AppService,
        public confirmationService: ConfirmationService,
        public multipleProjectDealPopUpService : MultipleProjectDealPopUpService,
        public dialogRef : DynamicDialogRef,
        public dialogConfig : DynamicDialogConfig,
        public msgsService : MsgsService
    ){
        super(router , userService , utilsService , searchService , appService , confirmationService , multipleProjectDealPopUpService);
    }

    //@override
    executeScreenSpecificInitCode() {
        this.subscriptions.push(
            this.msgsService.errorMsgsUpdated.subscribe(errorMsgs => {
                this.errorMsgs = errorMsgs;
            })
        );

        this.getCRMConfigOptions();
    }

    getCRMConfigOptions() {
        if(localStorage.getItem('Stages')) {
            this.stageOptions = JSON.parse(localStorage.getItem('Stages'));

            this.stageOptions.forEach(stage => {
                if(stage.default && !this.searchMultipleProjectDealPopUp.Stage) {
                    this.searchMultipleProjectDealPopUp.Stage = stage;
                }
            });
        }

        if(localStorage.getItem('Owners')) {
            this.ownerOptions = JSON.parse(localStorage.getItem('Owners'));
        }
    }

    //@override
    onCreateDealClick(event, searchMultipleProjectDealPopUp) {
        this.utilsService.clearErrorMessages();

        if(!this.form.valid) {
            this.utilsService.showAllErrorMessages();
            return;
        }

        if(this.dialogConfig.data.projects.length === 0) {
            this.utilsService.handleError('please select at least one project');
            return;
        }

        this.showLoader = true;

        const params = {
            create: true,
            owner:  this.searchMultipleProjectDealPopUp.Owner.id,
            stage:  this.searchMultipleProjectDealPopUp.Stage.id,
            notifyOwner: this.searchMultipleProjectDealPopUp.Notifyowner,
            projects: this.dialogConfig.data.projects
        };

        this.subscriptions.push(
            this.appService.createDeal(params).subscribe(() => {
                this.showLoader = false;
                this.utilsService.handleSuccessMessage('Deal created successfully');
                this.dialogRef.close();
            }, error => {
                this.showLoader = false;
                this.utilsService.handleError(error);
            })
        );
    }

    //@Override
    getOwnerOptions(): OptionsWrapper {
        return;
    }

    //@Override
    getStageOptions(): OptionsWrapper {
        return;
    }
}
/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */
