import { ListOrgsComponent } from './cbos/admin/list-orgs/list-orgs.component';
import { ListOrgUsersComponent } from './cbos/admin/list-org-users/list-org-users.component';
import { AdminDashboardComponent } from './cbos/admin/admin-dashboard/admin-dashboard.component';
import { SavedSearchesProviderComponent } from './cbos/saved-searches-provider/saved-searches-provider.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessDeniedComponent } from './oneit/components/access-denied/access-denied.component';
import { BoxLayoutComponent } from './oneit/components/box-layout/box-layout.component';
import { FullLayoutComponent } from './oneit/components/full-layout/full-layout.component';
import { AuthGuard } from './oneit/guards/auth.guard';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { SearchProjectsComponent } from './components/search-projects/search-projects.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { TableViewComponent } from './components/table-view/table-view.component';
import { RefineSearchComponent } from './components/refine-search/refine-search.component';
import { ConvertedDealsComponent } from './components/converted-deals/converted-deals.component';
import { ProjectComponent } from './components/project/project.component';
import { SavedSearchesComponent } from './components/saved-searches/saved-searches.component';
import { CompanyComponent } from './components/company/company.component';
import { ContactsComponent } from './components/contacts/contacts.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
    },
    {
        path: '',
        component: BoxLayoutComponent,
        data: {
            showDefaultHeader: false,
            showDefaultFooter: false,
            showMenu: false,
            showFeedback: false
        },
        children: [
            {
                path: 'login',
                component: LoginPageComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'forgot-password',
                component: ForgotPasswordComponent
            },
            {
                path: 'password/change',
                component: ResetPasswordComponent
            },
        ]
    },
    {
        path: '',
        component: FullLayoutComponent,
        data: {
            showDefaultHeader: true,
            showDefaultFooter: false,
            showMenu: true,
            showFeedback: false
        },
        children: [
            {
                path: 'access-denied',
                component: AccessDeniedComponent
            },
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full'
            },
            {
                path: 'dashboard',
                component: SearchProjectsComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'dashboard/:id',
                component: SearchProjectsComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'table-view',
                component: TableViewComponent
            },
            {
                path: 'converted-deals',
                component: ConvertedDealsComponent
            },
            {
                path: 'search/projects/:id',
                component: ProjectComponent
            },
            {
                path: 'search/companies/:id',
                component: CompanyComponent
            },
            // {
            //     path: 'saved-searches',
            //     component: SavedSearchesComponent,
            //     canActivate: [AuthGuard],
            // },
            {
              path: 'saved-searches',
              component: SavedSearchesProviderComponent,
              canActivate: [AuthGuard],
            },
            {
              path: 'admin-panel',
              component: AdminDashboardComponent,
              canActivate: [AuthGuard],
              children: [
                {
                  path: '',
                  redirectTo: 'orgs',
                  pathMatch: 'full'
              },
              {
                  path: 'orgs',
                  component: ListOrgsComponent,
              },
                {
                  path: 'orgs/:id',
                  component: ListOrgUsersComponent,

                }

              ]
            },
            {
                path: 'contacts/:id',
                component: ContactsComponent
            }

            // Project ROUTES will appear here, serach-client added for example
            // {
            //     path: 'search-client',
            //     component: SearchClientComponent,
            //     canActivate: [AuthGuard],
            //     data: {privs: [Constants.PRIV_SALES_MANAGER, Constants.PRIV_READ_ONLY]}
            // }
        ]
    },
    // CUSTOM HEADER Example e.g. Public Page
    // {
    //     path: '',
    //     component: FullLayoutComponent,
    //     data: {
    //         defaultFooter : false,
    //         defaultHeader: false,
    //         customHeader: CustomHeaderComponent
    //     },
    //     children: [
    //         {
    //             path: 'publicPage',
    //             component: PublicPageComponent
    //         }
    //     ]
    // }
    // Don't forget to add below line in app.module.ts
    // entryComponents: [CustomHeaderComponent],
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
