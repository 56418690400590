<div [class.overflow-scroll]="projectsOrCompanies.length > 5" class="ui-g-12 ui-md-12">
				<div  [class.overflow-scroll]="projectsOrCompanies.length > 5" *ngFor="let item of projectsOrCompanies; let t = index" >
							 <div *ngIf="!projectsOrCompanies || projectsOrCompanies.length === 0" [style.text-align]="'center'" [style.paddingTop.px]="'10'" [style.paddingLeft.px]="'10'" [style.paddingBottom.px]="'10'" [style.paddingRight.px]="'10'" >
								    <p>No {{item.module}} found</p>
							 </div>

							    <p-fieldset [class.project-list]="projectsOrCompanies?.length > 3" (mouseover)="hoveredCompanyID = item.id"
                          (mouseout)="hoveredCompanyID = null" legend="" [toggleable]="false" [collapsed]="false" >
								      <div class="ui-g form-group">
										      <div  [style.paddingTop.px]="'10'" [style.paddingLeft.px]="'0'" [style.paddingBottom.px]="'0'"
                                [style.paddingRight.px]="'0'" *ngFor="let temp of cardDetails; let i = index" [class.line-break]="i===0 || i === 4"
                                [class.word-space] ='item.module === "projects" && i===3 || item.module === "companies" && i===3 '>
                              <div [class.w-100]="i===0">
                              <a  [ngClass]=" i === 0? 'line-through' : 'line-through2'" [routerLink]="'/search/' + item.module + '/' +item?.id"> {{item[temp]}} </a>
                             </div>
                            </div>

						          </div>
                  </p-fieldset>
        </div>
</div>
