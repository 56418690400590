/* {OPEN:IMPORT} {"sha1":"A1B7519062AA334553AA5BD5F9E9F04EA6F37093"} */
import { Component, DoCheck, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import { Message, MenuItem, ConfirmationService } from 'primeng/api';
import { AppService } from './../../app.service';
import { OptionsObservable, OptionsWrapper } from './../../oneit/models/options.model';
import { UserService } from './../../oneit/services/user.service';
import { UtilsService } from './../../oneit/services/utils.service';
import { SearchService } from './../../oneit/services/search.service';
import { ProjectService } from './project.service';
import { SearchProject } from './project.model';
/* {CLOSE:IMPORT} {"sha1":"A1B7519062AA334553AA5BD5F9E9F04EA6F37093"} */

@Component({
    selector: 'app-base-project',
    template: ''
})
/* {PREOPEN:CLASS} {"sha1":"C6D906AE7D59067B6D2CDDC591D4C9683376B507"} */
export class BaseProjectComponent implements DoCheck, OnInit, OnDestroy {
    /* {OPEN:CLASS} {} */
    
    /* {OPEN:COMMON_VARS} {"sha1":"672A211DEEA9A3559D121CBDC5978BBA47BD6B7D"} */
    @Input() parent : any;
    pageTitle : string;
    /* {CLOSE:COMMON_VARS} {"sha1":"672A211DEEA9A3559D121CBDC5978BBA47BD6B7D"} */

    /* {OPEN:VARS} {"sha1":"EB84F552696105D1E9BE01CC3EE66C1F5BC0075E"} */
    searchProject : SearchProject = new SearchProject();
    activeTabIndex : number = 0;
    oneBedTwoBedAndThreeBedroomsFlatsWithParkings : any[] = [];
    oneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableSelectionMode : string;
    oneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableCols : any[] = [];
    details : any[] = [];
    detailsTableSelectionMode : string;
    detailsTableCols : any[] = [];
    roles : any[] = [];
    rolesTableSelectionMode : string;
    rolesTableCols : any[] = [];
    deals : any[] = [];
    dealsTableSelectionMode : string;
    dealsTableCols : any[] = [];
    dealsTableTitle : string;
    updates : any[] = [];
    updatesTableSelectionMode : string;
    updatesTableCols : any[] = [];
    updatesTableTitle : string;
    /* {CLOSE:VARS} {"sha1":"EB84F552696105D1E9BE01CC3EE66C1F5BC0075E"} */
    
    showResults : boolean = false;
    showLoader : boolean = false;
    subscriptions : Array<Subscription> = [];

    constructor(
        /* {OPEN:constructor} {"sha1":"A4E9F4D4F8531F43D4A0A440A0E4F47731460F53"} */
        public router : Router,
        public userService : UserService,
        public utilsService : UtilsService,
        public searchService : SearchService,
        public appService : AppService,
        public confirmationService: ConfirmationService,
        public projectService : ProjectService,
        /* {CLOSE:constructor} {"sha1":"A4E9F4D4F8531F43D4A0A440A0E4F47731460F53"} */
    ){
        
    }

    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"5FBDAAB044BC4F999356808F755A7334E11EE273"} */
        this.resetInitVariables();
        this.calculateEverything();
        this.executeScreenSpecificInitCode();
        /* {CLOSE:ngOnInit} {"sha1":"5FBDAAB044BC4F999356808F755A7334E11EE273"} */
    }

    /* {PREOPEN:executeScreenSpecificInitCode} {"sha1":"DDD081544316B33777AB7B7FFD796FB9E663E681"} */
    executeScreenSpecificInitCode() {
        /* {OPEN:executeScreenSpecificInitCode} {"sha1":"54DB41AD32937D86C7318EB4BE666A1AD7B6B618"} */
        if(!this.parent) {
        }
        /* {CLOSE:executeScreenSpecificInitCode} {"sha1":"54DB41AD32937D86C7318EB4BE666A1AD7B6B618"} */
    }
    /* {POSTCLOSE:executeScreenSpecificInitCode} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onCloseClick} {"sha1":"B1AB085650283961FBD8FDB8B6ADD75B5254F07C"} */
    onCloseClick(event, searchProject) {
        /* {OPEN:onCloseClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onCloseClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onCloseClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onCreateDealClick} {"sha1":"70FF7398A9563FDA1B5B792CF8D74814B83A14D9"} */
    onCreateDealClick(event, searchProject) {
        /* {OPEN:onCreateDealClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onCreateDealClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onCreateDealClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onUpdateCrmClick} {"sha1":"90FB3438128AEA788327D3C9B7F25A0AC39E505C"} */
    onUpdateCrmClick(event, searchProject) {
        /* {OPEN:onUpdateCrmClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onUpdateCrmClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onUpdateCrmClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onStarClick} {"sha1":"EA5597E66C5FACD1A2A33EE144055F03718946A1"} */
    onStarClick(event, searchProject) {
        /* {OPEN:onStarClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onStarClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onStarClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onTabChange} {"sha1":"1ED28AFCAE22B24A9A2E176B664DE8A9010493DD"} */
    onTabChange(event, searchProject) : void {
        /* {OPEN:onTabChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:onTabChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:onTabChange} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getOneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableCols} {"sha1":"5689B413A9710164F35DE19D8A03D7146B0115AF"} */
    getOneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableCols() : any[] {
        /* {OPEN:getOneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableCols} {"sha1":"4C4440DD9AAE46AC5AEC5645081295E579B502AD"} */
        return [
            { field: 'ContractType', header: 'Project Details', width: '5px', noSort: true, noFilter: true },
            { field: 'GeneralContract', header: '', width: '10px', noSort: true, noFilter: true }
        ];
        /* {CLOSE:getOneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableCols} {"sha1":"4C4440DD9AAE46AC5AEC5645081295E579B502AD"} */
    }
    /* {POSTCLOSE:getOneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableCols} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getOneBedTwoBedAndThreeBedroomsFlatsWithParkings} {"sha1":"732FBE4BBC87022626435DBD538E91520A90D6FF"} */
    getOneBedTwoBedAndThreeBedroomsFlatsWithParkings(saveSearch: boolean) {
        /* {OPEN:getOneBedTwoBedAndThreeBedroomsFlatsWithParkings} {"sha1":"6B8782A005A468DE28957597EE260BBED29E24A5"} */
        this.showResults = true;
        this.showLoader = true;
        let assocs = [];
        
        this.subscriptions.push(this.getOneBedTwoBedAndThreeBedroomsFlatsWithParkingsObservable(saveSearch)
            .subscribe(
                data => {
                    this.oneBedTwoBedAndThreeBedroomsFlatsWithParkings = data;
                    this.postOneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableChange();
                    this.showLoader = false;
                },
                error => {
                    this.showLoader = false;
                    this.utilsService.handleError(error);
                }
            )
        );
        /* {CLOSE:getOneBedTwoBedAndThreeBedroomsFlatsWithParkings} {"sha1":"6B8782A005A468DE28957597EE260BBED29E24A5"} */
    }
    /* {POSTCLOSE:getOneBedTwoBedAndThreeBedroomsFlatsWithParkings} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:postOneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableChange} {"sha1":"D58EA58293C89E98F4B9F453D5AB201A0E6AD815"} */
    postOneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableChange() {
        /* {OPEN:postOneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:postOneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:postOneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableChange} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getOneBedTwoBedAndThreeBedroomsFlatsWithParkingsObservable} {"sha1":"9928FF6D7EA4CC94D2A0A10C591081D6E2716D7B"} */
    getOneBedTwoBedAndThreeBedroomsFlatsWithParkingsObservable(saveSearch): Observable<any> {
        /* {OPEN:getOneBedTwoBedAndThreeBedroomsFlatsWithParkingsObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
        return of({});
        /* {CLOSE:getOneBedTwoBedAndThreeBedroomsFlatsWithParkingsObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
    }
    /* {POSTCLOSE:getOneBedTwoBedAndThreeBedroomsFlatsWithParkingsObservable} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onOneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableRowSelect} {"sha1":"CF8E414362FE769C8E96B8156116077421C554D6"} */
    onOneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableRowSelect(data) {
        /* {OPEN:onOneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableRowSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:onOneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableRowSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:onOneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableRowSelect} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getOneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableSelectionMode} {"sha1":"6FF7B9CFE404B969E71C5A9C5CA58E1FA87C889D"} */
    getOneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableSelectionMode() : string {
        /* {OPEN:getOneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
        return null;
        /* {CLOSE:getOneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
    }
    /* {POSTCLOSE:getOneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableSelectionMode} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getDetailsTableCols} {"sha1":"566C642D35F3CBA0CDE020F143ECA106EBA23B0F"} */
    getDetailsTableCols() : any[] {
        /* {OPEN:getDetailsTableCols} {"sha1":"087EE166717396A339858ABF05B992FD1B2836F6"} */
        return [
            { field: 'PrimaryCategory', header: 'Category', width: '5px', noSort: true, noFilter: true },
            { field: 'FlatsApartments', header: '', width: '10px', noSort: true, noFilter: true }
        ];
        /* {CLOSE:getDetailsTableCols} {"sha1":"087EE166717396A339858ABF05B992FD1B2836F6"} */
    }
    /* {POSTCLOSE:getDetailsTableCols} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getDetails} {"sha1":"09F2ABF052015DB43703AAE047738E2AD4253284"} */
    getDetails(saveSearch: boolean) {
        /* {OPEN:getDetails} {"sha1":"719062BFBACED4889277A37A4B1334A7741A13DF"} */
        this.showResults = true;
        this.showLoader = true;
        let assocs = [];
        
        this.subscriptions.push(this.getDetailsObservable(saveSearch)
            .subscribe(
                data => {
                    this.details = data;
                    this.postDetailsTableChange();
                    this.showLoader = false;
                },
                error => {
                    this.showLoader = false;
                    this.utilsService.handleError(error);
                }
            )
        );
        /* {CLOSE:getDetails} {"sha1":"719062BFBACED4889277A37A4B1334A7741A13DF"} */
    }
    /* {POSTCLOSE:getDetails} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:postDetailsTableChange} {"sha1":"86FB026CC70E9F32BA845B407F44520188CA5E89"} */
    postDetailsTableChange() {
        /* {OPEN:postDetailsTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:postDetailsTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:postDetailsTableChange} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getDetailsObservable} {"sha1":"CB0714888DB30B278AAB5025584430BDCC8DB667"} */
    getDetailsObservable(saveSearch): Observable<any> {
        /* {OPEN:getDetailsObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
        return of({});
        /* {CLOSE:getDetailsObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
    }
    /* {POSTCLOSE:getDetailsObservable} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onDetailsTableRowSelect} {"sha1":"21CFDE229280F8932306228A5EAF5455DD8CCCBB"} */
    onDetailsTableRowSelect(data) {
        /* {OPEN:onDetailsTableRowSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:onDetailsTableRowSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:onDetailsTableRowSelect} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getDetailsTableSelectionMode} {"sha1":"7A4F61B8FB3CCB7AC6F1F564D92D1FA18FA20BB1"} */
    getDetailsTableSelectionMode() : string {
        /* {OPEN:getDetailsTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
        return null;
        /* {CLOSE:getDetailsTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
    }
    /* {POSTCLOSE:getDetailsTableSelectionMode} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRolesTableCols} {"sha1":"7AB572CFB446ED34B4C6A69E55F2476E3AAF422A"} */
    getRolesTableCols() : any[] {
        /* {OPEN:getRolesTableCols} {"sha1":"8F35E999D407E6D4A3E5552F27961E19B6DEF42B"} */
        return [
            { field: 'Architect', header: 'Role', width: '10px', noSort: false, noFilter: false },
            { field: 'JohnMcCallArchitect', header: 'Company', width: '10px', noSort: false, noFilter: false },
            { field: 'DaveSmith', header: 'Contact', width: '10px', noSort: false, noFilter: false }
        ];
        /* {CLOSE:getRolesTableCols} {"sha1":"8F35E999D407E6D4A3E5552F27961E19B6DEF42B"} */
    }
    /* {POSTCLOSE:getRolesTableCols} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRoles} {"sha1":"916EE16C237921BB4226EEAFF49A6EF60DC42077"} */
    getRoles(saveSearch: boolean) {
        /* {OPEN:getRoles} {"sha1":"0AF9BEA461D32B7F6820EDACDEFD79B417429DBE"} */
        this.showResults = true;
        this.showLoader = true;
        let assocs = [];
        
        this.subscriptions.push(this.getRolesObservable(saveSearch)
            .subscribe(
                data => {
                    this.roles = data;
                    this.postRolesTableChange();
                    this.showLoader = false;
                },
                error => {
                    this.showLoader = false;
                    this.utilsService.handleError(error);
                }
            )
        );
        /* {CLOSE:getRoles} {"sha1":"0AF9BEA461D32B7F6820EDACDEFD79B417429DBE"} */
    }
    /* {POSTCLOSE:getRoles} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:postRolesTableChange} {"sha1":"A0091AEDE6049EDE55A2D6D4928B55F7CC4C00C6"} */
    postRolesTableChange() {
        /* {OPEN:postRolesTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:postRolesTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:postRolesTableChange} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRolesObservable} {"sha1":"FEF1C894552009E3E7BA1A44CC799EFE9B3666B7"} */
    getRolesObservable(saveSearch): Observable<any> {
        /* {OPEN:getRolesObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
        return of({});
        /* {CLOSE:getRolesObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
    }
    /* {POSTCLOSE:getRolesObservable} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onRolesTableRowSelect} {"sha1":"31F763447C56BE842B67D1F96AB8354C45F75545"} */
    onRolesTableRowSelect(data) {
        /* {OPEN:onRolesTableRowSelect} {"sha1":"35878015AA3ADB243348A73071E698AC75C4558A"} */
        this.router.navigate(['/company']);
        /* {CLOSE:onRolesTableRowSelect} {"sha1":"35878015AA3ADB243348A73071E698AC75C4558A"} */
    }
    /* {POSTCLOSE:onRolesTableRowSelect} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRolesTableSelectionMode} {"sha1":"F2C708D4032B583069DCA398783CA1AE40A625B9"} */
    getRolesTableSelectionMode() : string {
        /* {OPEN:getRolesTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
        return null;
        /* {CLOSE:getRolesTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
    }
    /* {POSTCLOSE:getRolesTableSelectionMode} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRouterLinkForRolesTableJohnMcCallArchitect} {"sha1":"39B45B74892682AA7244288EA8AC5B36B9203179"} */
    getRouterLinkForRolesTableJohnMcCallArchitect(rowData) {
        /* {OPEN:getRouterLinkForRolesTableJohnMcCallArchitect} {"sha1":"812EFC4A01CDBE08F76784B8C5601518EB4F4683"} */
        return ['/company'];
        /* {CLOSE:getRouterLinkForRolesTableJohnMcCallArchitect} {"sha1":"812EFC4A01CDBE08F76784B8C5601518EB4F4683"} */
    }
    /* {POSTCLOSE:getRouterLinkForRolesTableJohnMcCallArchitect} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getRouterLinkForRolesTableDaveSmith} {"sha1":"477EC155EE82C405305995B4F47585B3230A0BF9"} */
    getRouterLinkForRolesTableDaveSmith(rowData) {
        /* {OPEN:getRouterLinkForRolesTableDaveSmith} {"sha1":"821AEF0F59530E8800243B34B9DDDB04C4DC5857"} */
        return ['/contacts'];
        /* {CLOSE:getRouterLinkForRolesTableDaveSmith} {"sha1":"821AEF0F59530E8800243B34B9DDDB04C4DC5857"} */
    }
    /* {POSTCLOSE:getRouterLinkForRolesTableDaveSmith} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getDealsTableCols} {"sha1":"DB37207A2DCA55CCD869C89C669EC39A3B098468"} */
    getDealsTableCols() : any[] {
        /* {OPEN:getDealsTableCols} {"sha1":"91F5B90F8B5598BD20AF658E31481307700C6887"} */
        return [
            { field: 'NewBidder', header: 'Name', width: '10px', noSort: false, noFilter: false },
            { field: 'DemoArranged', header: 'Stage', width: '10px', noSort: false, noFilter: false },
            { field: 'AbhinavGandhi', header: 'Owner', width: '10px', noSort: false, noFilter: false },
            { field: 'Dummy', header: 'Amount', width: '10px', noSort: false, noFilter: false },
            { field: 'March2021', header: 'Closing Date', width: '10px', noSort: false, noFilter: false },
            { field: 'March2021', header: 'Created', width: '10px', noSort: false, noFilter: false },
            { field: 'March2021', header: 'Last Updated', width: '10px', noSort: false, noFilter: false }
        ];
        /* {CLOSE:getDealsTableCols} {"sha1":"91F5B90F8B5598BD20AF658E31481307700C6887"} */
    }
    /* {POSTCLOSE:getDealsTableCols} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getDealsTableTitle} {"sha1":"822A35E4E5EB94B5ED690B9C96A2B1E302DEBD8F"} */
    getDealsTableTitle() : string {
        /* {OPEN:getDealsTableTitle} {"sha1":"BCCDE9FB6488EC6B99ACF791259580606506428D"} */
        return "Deals";
        /* {CLOSE:getDealsTableTitle} {"sha1":"BCCDE9FB6488EC6B99ACF791259580606506428D"} */
    }
    /* {POSTCLOSE:getDealsTableTitle} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getDeals} {"sha1":"FC7E8240E4AF7CEA644365632791BA3B99ABFD63"} */
    getDeals(saveSearch: boolean) {
        /* {OPEN:getDeals} {"sha1":"FB9BD4D9975397AD0A76A97F25F50A9C0D1B8EA0"} */
        this.showResults = true;
        this.showLoader = true;
        let assocs = [];
        
        this.subscriptions.push(this.getDealsObservable(saveSearch)
            .subscribe(
                data => {
                    this.deals = data;
                    this.postDealsTableChange();
                    this.showLoader = false;
                },
                error => {
                    this.showLoader = false;
                    this.utilsService.handleError(error);
                }
            )
        );
        /* {CLOSE:getDeals} {"sha1":"FB9BD4D9975397AD0A76A97F25F50A9C0D1B8EA0"} */
    }
    /* {POSTCLOSE:getDeals} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:postDealsTableChange} {"sha1":"EFE6AE3652FAB8C8CE5F0E303B0903E3BD1D11C6"} */
    postDealsTableChange() {
        /* {OPEN:postDealsTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:postDealsTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:postDealsTableChange} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getDealsObservable} {"sha1":"CF144F4D3CC7A97CE73673D0960BB521DC41D2D7"} */
    getDealsObservable(saveSearch): Observable<any> {
        /* {OPEN:getDealsObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
        return of({});
        /* {CLOSE:getDealsObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
    }
    /* {POSTCLOSE:getDealsObservable} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onDealsTableRowSelect} {"sha1":"A83B7AD9118E0EE1351C37C3BB841AFE9A628013"} */
    onDealsTableRowSelect(data) {
        /* {OPEN:onDealsTableRowSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:onDealsTableRowSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:onDealsTableRowSelect} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getDealsTableSelectionMode} {"sha1":"38313041B68FE4E5CBE49BC0C617E8E3321F4014"} */
    getDealsTableSelectionMode() : string {
        /* {OPEN:getDealsTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
        return null;
        /* {CLOSE:getDealsTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
    }
    /* {POSTCLOSE:getDealsTableSelectionMode} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getUpdatesTableCols} {"sha1":"77BF723B53A4DAF7D8A32DC6F8C1DB7FC8828BA2"} */
    getUpdatesTableCols() : any[] {
        /* {OPEN:getUpdatesTableCols} {"sha1":"6115BC0E1127B084A8B4061D52DF912C8EDECA0C"} */
        return [
            { field: 'SEPConstructionServiceschangedfromPreferredbiddertoBidder', header: 'Updates', width: '10px', noSort: false, noFilter: true },
            { field: 'March2021', header: 'Date', width: '4px', noSort: false, noFilter: true }
        ];
        /* {CLOSE:getUpdatesTableCols} {"sha1":"6115BC0E1127B084A8B4061D52DF912C8EDECA0C"} */
    }
    /* {POSTCLOSE:getUpdatesTableCols} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getUpdatesTableTitle} {"sha1":"B2AD387F7074B43FA40E26FE72A21D24916BCA06"} */
    getUpdatesTableTitle() : string {
        /* {OPEN:getUpdatesTableTitle} {"sha1":"27F3D7E0B3B4D6040848B42B1FAE6364D7C839D1"} */
        return "Updates";
        /* {CLOSE:getUpdatesTableTitle} {"sha1":"27F3D7E0B3B4D6040848B42B1FAE6364D7C839D1"} */
    }
    /* {POSTCLOSE:getUpdatesTableTitle} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getUpdates} {"sha1":"F23EFB6C096D4E0C97DCD4D608380ED64C552250"} */
    getUpdates(saveSearch: boolean) {
        /* {OPEN:getUpdates} {"sha1":"280E88F5540D029EDD8AF127E490A2B019AAFD16"} */
        this.showResults = true;
        this.showLoader = true;
        let assocs = [];
        
        this.subscriptions.push(this.getUpdatesObservable(saveSearch)
            .subscribe(
                data => {
                    this.updates = data;
                    this.postUpdatesTableChange();
                    this.showLoader = false;
                },
                error => {
                    this.showLoader = false;
                    this.utilsService.handleError(error);
                }
            )
        );
        /* {CLOSE:getUpdates} {"sha1":"280E88F5540D029EDD8AF127E490A2B019AAFD16"} */
    }
    /* {POSTCLOSE:getUpdates} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:postUpdatesTableChange} {"sha1":"F6285CC944FB7CF6CF567F290D47AF5D4E1B397D"} */
    postUpdatesTableChange() {
        /* {OPEN:postUpdatesTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:postUpdatesTableChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:postUpdatesTableChange} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getUpdatesObservable} {"sha1":"7BA82B464641FA25EA3DA60F4DFD3C5510298D32"} */
    getUpdatesObservable(saveSearch): Observable<any> {
        /* {OPEN:getUpdatesObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
        return of({});
        /* {CLOSE:getUpdatesObservable} {"sha1":"D4524D9796B49AC9072627A1F1E4093B61017B9C"} */
    }
    /* {POSTCLOSE:getUpdatesObservable} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onUpdatesTableRowSelect} {"sha1":"FB713E6D4EA6F9150549C6D61AB92AD585E7F272"} */
    onUpdatesTableRowSelect(data) {
        /* {OPEN:onUpdatesTableRowSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:onUpdatesTableRowSelect} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:onUpdatesTableRowSelect} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getUpdatesTableSelectionMode} {"sha1":"2792FF8A27DF63E62719BCE5EFA4D63F6987B55B"} */
    getUpdatesTableSelectionMode() : string {
        /* {OPEN:getUpdatesTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
        return null;
        /* {CLOSE:getUpdatesTableSelectionMode} {"sha1":"89979E3865BA3B6606AAF1750CC1B11F9820EE8A"} */
    }
    /* {POSTCLOSE:getUpdatesTableSelectionMode} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:resetInitVariables} {"sha1":"846C0DF64879342578D595FE17BC00B96F0BAED6"} */
    resetInitVariables() {
        /* {OPEN:resetInitVariables} {} */
        /* {OPEN:resetInitVariables_oneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableCols} {"sha1":"32C3475407DE6A038D3F1D9B386F6769BD197558"} */
        try {
            this.oneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableCols = this.getOneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableCols();
        }
        catch (error) {
            console.error('Error occured when setting oneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableCols', error);
        }
        /* {CLOSE:resetInitVariables_oneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableCols} {"sha1":"32C3475407DE6A038D3F1D9B386F6769BD197558"} */
        /* {OPEN:resetInitVariables_oneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableSelectionMode} {"sha1":"117041175135C91AC72C1BD29F055268B1F3969F"} */
        try {
            this.oneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableSelectionMode = this.getOneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableSelectionMode();
        }
        catch (error) {
            console.error('Error occured when setting oneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableSelectionMode', error);
        }
        /* {CLOSE:resetInitVariables_oneBedTwoBedAndThreeBedroomsFlatsWithParkingsTableSelectionMode} {"sha1":"117041175135C91AC72C1BD29F055268B1F3969F"} */
        /* {OPEN:resetInitVariables_detailsTableCols} {"sha1":"E4EC7170B288A1FCC2434A7317F048042443336A"} */
        try {
            this.detailsTableCols = this.getDetailsTableCols();
        }
        catch (error) {
            console.error('Error occured when setting detailsTableCols', error);
        }
        /* {CLOSE:resetInitVariables_detailsTableCols} {"sha1":"E4EC7170B288A1FCC2434A7317F048042443336A"} */
        /* {OPEN:resetInitVariables_detailsTableSelectionMode} {"sha1":"F7D63F9186744F3ECA0F591D4148DC11A4A19BF9"} */
        try {
            this.detailsTableSelectionMode = this.getDetailsTableSelectionMode();
        }
        catch (error) {
            console.error('Error occured when setting detailsTableSelectionMode', error);
        }
        /* {CLOSE:resetInitVariables_detailsTableSelectionMode} {"sha1":"F7D63F9186744F3ECA0F591D4148DC11A4A19BF9"} */
        /* {OPEN:resetInitVariables_rolesTableCols} {"sha1":"641EC665968ACB1C67A018C17B85E33EBEDD6474"} */
        try {
            this.rolesTableCols = this.getRolesTableCols();
        }
        catch (error) {
            console.error('Error occured when setting rolesTableCols', error);
        }
        /* {CLOSE:resetInitVariables_rolesTableCols} {"sha1":"641EC665968ACB1C67A018C17B85E33EBEDD6474"} */
        /* {OPEN:resetInitVariables_rolesTableSelectionMode} {"sha1":"7B14C85E38A139B0A9F42C09D35592D9558207C0"} */
        try {
            this.rolesTableSelectionMode = this.getRolesTableSelectionMode();
        }
        catch (error) {
            console.error('Error occured when setting rolesTableSelectionMode', error);
        }
        /* {CLOSE:resetInitVariables_rolesTableSelectionMode} {"sha1":"7B14C85E38A139B0A9F42C09D35592D9558207C0"} */
        /* {OPEN:resetInitVariables_dealsTableCols} {"sha1":"74C0EF5F158E0494E37842ABB966D173A0D0FDA6"} */
        try {
            this.dealsTableCols = this.getDealsTableCols();
        }
        catch (error) {
            console.error('Error occured when setting dealsTableCols', error);
        }
        /* {CLOSE:resetInitVariables_dealsTableCols} {"sha1":"74C0EF5F158E0494E37842ABB966D173A0D0FDA6"} */
        /* {OPEN:resetInitVariables_dealsTableSelectionMode} {"sha1":"1D5A1DBED2056BF220CDB016FB69FD879CFB4DCA"} */
        try {
            this.dealsTableSelectionMode = this.getDealsTableSelectionMode();
        }
        catch (error) {
            console.error('Error occured when setting dealsTableSelectionMode', error);
        }
        /* {CLOSE:resetInitVariables_dealsTableSelectionMode} {"sha1":"1D5A1DBED2056BF220CDB016FB69FD879CFB4DCA"} */
        /* {OPEN:resetInitVariables_updatesTableCols} {"sha1":"A0E697BC8A24E5F033450AADDA8BA93DA4B8F45C"} */
        try {
            this.updatesTableCols = this.getUpdatesTableCols();
        }
        catch (error) {
            console.error('Error occured when setting updatesTableCols', error);
        }
        /* {CLOSE:resetInitVariables_updatesTableCols} {"sha1":"A0E697BC8A24E5F033450AADDA8BA93DA4B8F45C"} */
        /* {OPEN:resetInitVariables_updatesTableSelectionMode} {"sha1":"E9A366478814CF30731ED3490E6FA9AA5ED8EE50"} */
        try {
            this.updatesTableSelectionMode = this.getUpdatesTableSelectionMode();
        }
        catch (error) {
            console.error('Error occured when setting updatesTableSelectionMode', error);
        }
        /* {CLOSE:resetInitVariables_updatesTableSelectionMode} {"sha1":"E9A366478814CF30731ED3490E6FA9AA5ED8EE50"} */
        /* {CLOSE:resetInitVariables} {} */
    }
    /* {POSTCLOSE:resetInitVariables} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getPageTitle} {"sha1":"174A3D30FFA103DD7BE180EE89CC0CF5EB6D11B8"} */
    getPageTitle() {
        /* {OPEN:getPageTitle} {"sha1":"452A7142292B4A11183441E01233C81B8034FDBB"} */
        return 'Project';
        /* {CLOSE:getPageTitle} {"sha1":"452A7142292B4A11183441E01233C81B8034FDBB"} */
    }
    /* {POSTCLOSE:getPageTitle} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:calculateEverything} {"sha1":"CE17BF2375171825A37C544A1DCBF3A565DD5181"} */
    calculateEverything() {
        /* {OPEN:calculateEverything} {} */
        /* {OPEN:calculateEverything_pageTitle} {"sha1":"B3CA5EAE3ED54D1F0D95EA2CD05351A4A6D7480F"} */
        try {
            this.pageTitle = this.getPageTitle();
        }
        catch (error) {
            console.error('Error occured when setting pageTitle');
        }
        /* {CLOSE:calculateEverything_pageTitle} {"sha1":"B3CA5EAE3ED54D1F0D95EA2CD05351A4A6D7480F"} */
        /* {OPEN:calculateEverything_dealsTableTitle} {"sha1":"03D518F5555E9760351F29E4B476CE7DDF05D3B3"} */
        try {
            this.dealsTableTitle = this.getDealsTableTitle();
        }
        catch (error) {
            console.error('Error occured when setting dealsTableTitle');
        }
        /* {CLOSE:calculateEverything_dealsTableTitle} {"sha1":"03D518F5555E9760351F29E4B476CE7DDF05D3B3"} */
        /* {OPEN:calculateEverything_updatesTableTitle} {"sha1":"0BE2FFAFBC968540C11D1A298B7D42EAD516A7CF"} */
        try {
            this.updatesTableTitle = this.getUpdatesTableTitle();
        }
        catch (error) {
            console.error('Error occured when setting updatesTableTitle');
        }
        /* {CLOSE:calculateEverything_updatesTableTitle} {"sha1":"0BE2FFAFBC968540C11D1A298B7D42EAD516A7CF"} */
        /* {CLOSE:calculateEverything} {} */
    }
    /* {POSTCLOSE:calculateEverything} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:ngDoCheck} {"sha1":"75839B27D41E20648CE296AC51D61E24D541A70A"} */
    ngDoCheck() {
        /* {OPEN:ngDoCheck} {"sha1":"A154E0663059B6D385B4F6C11094F2084FF5E184"} */
        this.calculateEverything();
        /* {CLOSE:ngDoCheck} {"sha1":"A154E0663059B6D385B4F6C11094F2084FF5E184"} */
    }
    /* {POSTCLOSE:ngDoCheck} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:ngOnDestroy} {"sha1":"C21B2137D2BD86DF7050E24A39F08355F04DA4E9"} */
    ngOnDestroy() {
        /* {OPEN:ngOnDestroy} {"sha1":"85AA65230B2C97013ABDD30D48BEB93C04E8EE54"} */
        this.utilsService.unsubscribeSubscriptions(this.subscriptions);
        /* {CLOSE:ngOnDestroy} {"sha1":"85AA65230B2C97013ABDD30D48BEB93C04E8EE54"} */
    }
    /* {POSTCLOSE:ngOnDestroy} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {CLOSE:CLASS} {} */
}
/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */