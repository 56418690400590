import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../services/utils.service';

@Pipe({
    name: 'filter',
    pure: false
})
export class FilterPipe implements PipeTransform {

    constructor(
        private utilsService: UtilsService
    ) {
    }

    transform(array: any[], fields: any[], filterText: string) : any {
        return this.utilsService.filter(array, fields, filterText, false);
    }
}