import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ConfigService } from '../../services/config.service';
import { MenuService } from './../../services/menu.service';
import { UtilsService } from './../../services/utils.service';
import { FullLayoutComponent } from './../full-layout/full-layout.component';
import { HeaderService } from './header.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [HeaderService]
})
export class HeaderComponent implements OnInit {

    @Input() showDefaultHeader: boolean = true;
    @Input() showSidebar: boolean = true;
    @Input() showMenu: boolean = true;
    @Input() staticTopBar: boolean = false;
    @Input() customTopBarHeader : any;
    @ViewChild('customTopBarHeader', { read: ViewContainerRef, static: false }) topBarHeaderContainerRef: ViewContainerRef;
    mainMenuItems: MenuItem[] = [];
    topMenuItems: MenuItem[] = [];
    subscriptions: Array<Subscription> = [];
    logoURL : string = 'assets/images/logo.png';
    omniSearchModel: any;
    omniOptionGroups: any[] = [];
    showOmniSearch: boolean = this.configService.settings.showOmniSearch;
    pointerNone: boolean = false;

    constructor(
        private headerService: HeaderService,
        public app: FullLayoutComponent,
        private menuService: MenuService,
        private utilsService: UtilsService,
        private router: Router,
        private configService: ConfigService
    ) {

    }

    ngOnInit() {
        this.subscriptions.push(this.menuService.menuUpdated.subscribe(
            data => {
                this.mainMenuItems = data.mainMenuItems ? this.utilsService.cloneObject(data.mainMenuItems).reverse() : [];
                this.topMenuItems = data.topMenuItems ? this.utilsService.cloneObject(data.topMenuItems) : [];

                if (this.app.isMobile() && this.mainMenuItems.length > 0) {
                    this.mainMenuItems.push(this.mainMenuItems.shift());
                    this.mainMenuItems = this.mainMenuItems.reverse();  //So that user menu will be at the top for Mobile, and rest menu items preserve order
                }
                //Commented as this stops whole system when called from iframe component! Assume User will have minor performance issue because of this
                //this.changeDetector.detectChanges();
            }
        ));

        this.subscriptions.push(this.utilsService.logoUpdated.subscribe(
            data => {
                if(data) {
                    this.logoURL = data;
                }
            }
        ));

        this.subscriptions.push(
            this.utilsService.classPointerNone.subscribe((pointerNone) => {
                this.pointerNone = pointerNone;
            })
        );

        setTimeout(() => {
            if (this.customTopBarHeader) {
                this.app.addDynamicComponent(this.customTopBarHeader, this.topBarHeaderContainerRef);
            }
        });
    }

    ngOnDestroy() {
        this.utilsService.unsubscribeSubscriptions(this.subscriptions);
    }

    autocompleteOmniOptions(event) {
        if(event.query && event.query.length > 2) {
            this.subscriptions.push(
                this.headerService.getOmniOptionsForAutocomplete(event.query).subscribe(
                    data => {
                        let groupByGroupName = this.utilsService.groupValues(data, option => option.Group);
                        let groupSuggestions = [];

                        for (let [key, value] of groupByGroupName) {
                            groupSuggestions.push({
                                label: key,
                                items: value
                            });
                        }
                        this.omniOptionGroups = groupSuggestions;
                    }, error => {
                        this.utilsService.handleError(error);
                    }
                )
            );
        } else {
            this.omniOptionGroups = [];
        }
    }

    onOmniOptionSelect(event) {
        if (this.omniSearchModel) {
            if(this.omniSearchModel.RouterLink) {
                this.router.navigate(this.omniSearchModel.RouterLink);
            } else if (this.omniSearchModel.Link) {
                window.location.href = this.omniSearchModel.Link;
            }
        }
    }
}