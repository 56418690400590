
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SearchService } from './search.service';
import { UtilsService } from './utils.service';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';

export class MultiEnumService {
    service: string;
    params: any;
    addNullOption: boolean;
    blankLabel: string;
    storeName: string;

    constructor(serviceName : string, onlyEnabled: boolean = true, addNullOption: boolean = false, blankLabel: string = 'Please Select') {
        this.service          = serviceName;
        this.params           = Object.assign({}, {}, {environment : environment.envName, onlyEnabled: onlyEnabled});
        this.addNullOption    = addNullOption;
        this.blankLabel       = blankLabel;
    }

    setStoreName(storeName : string) : MultiEnumService {
        this.storeName = storeName;
        return this;
    }
}

export class MultiEnumClassService {
    service:         string;
    params:          {};
    addNullOption:   boolean    = false;
    blankLabel:      string;
    storeName:       string;

    constructor(serviceName : string, enumClass : String, onlyEnabled : boolean = true, addNullOption : boolean = false, blankLabel: string = 'Please Select') {
        this.service          = serviceName;
        this.params           = Object.assign({}, {}, {environment : environment.envName, enumClass : enumClass, onlyEnabled: onlyEnabled});
        this.addNullOption    = addNullOption;
        this.blankLabel       = blankLabel;
    }

    setStoreName(storeName : string) : MultiEnumClassService {
        this.storeName = storeName;
        return this;
    }
}

@Injectable()
export class EnumService {

    constructor(
        private searchService: SearchService,
        private utilsService: UtilsService,
        private apiService: ApiService
    ) {

    }

    getEnums(enumName, onlyEnabled: boolean = false): Observable<any> {
        return this.searchService.getObjects(enumName, "All", {}, [], null, null, null, null, { onlyEnabled: onlyEnabled }).pipe(
            map(
                data => {
                    if (this.utilsService.isSuccessfulResponse(data)) {
                        return data.results;
                    }
                }
            ));
    }

    getEnumsByClassName(serviceName, enumClass : string, onlyEnabled: boolean = false, blankLabel?: string): Observable<any> {
        return this.searchService.getObjects(serviceName, "All", {}, [], null, null, null, null, { enumClass: enumClass, onlyEnabled: onlyEnabled }).pipe(
            map(
                data => {
                    if (this.utilsService.isSuccessfulResponse(data)) {
                        let results = data.results;

                        if(blankLabel) {
                            this.addNULLOptionForEnumSelectWithLabel(results, blankLabel);
                        }

                        return results;
                    }
                }
            ));
    }

    getEnumsWithNULLOption(enumName: string, onlyEnabled: boolean = false): Observable<any> {
        return this.getEnumsWithNULLOptionLabel(enumName, 'Please Select', onlyEnabled);
    }

    getEnumsWithNULLOptionLabel(enumName: string, blankLabel: string, onlyEnabled: boolean = false): Observable<any> {
        return this.getEnums(enumName, onlyEnabled).pipe(
            map(
                data => {
                    this.addNULLOptionForEnumSelectWithLabel(data, blankLabel);
                    return data;
                }
            ));
    }

    addNULLOptionForEnumSelect(options: any[]) {
        return this.addNULLOptionForEnumSelectWithLabel(options, 'Please Select');
    }

    addNULLOptionForEnumSelectWithLabel(options: any[], blankLabel: string, fieldName: string = 'Description') {
        return this.utilsService.addNULLOptionForSelectWithLabel(options, fieldName, 'Value', blankLabel);
    }

    filterEnums(event, allEnums: any[], keyName:string = 'Description'):Observable<any[]> {
        let enums = [];
        if (allEnums) {
            for (let i = 0; i < allEnums.length; i++) {
                let enumObj = allEnums[i];
                if (enumObj) {
                    if( event.query && event.query.length > 0) {
                        if (enumObj[keyName].toLowerCase().indexOf(event.query.toLowerCase()) >= 0) {
                            enums.push(enumObj);
                        }
                    } else {
                        enums.push(enumObj);    //return all objects when filter string is empty.
                    }
                }
            }
        }
        return of(enums);
    }

    getMultipleEnumsWithSubscriptions(subscriptions, multiServiceObjs: MultiEnumService[] | MultiEnumClassService[], responseObj, onSuccess = () => {}) {
        subscriptions.push(
            this.getMultipleEnums(multiServiceObjs).subscribe(
                data => {
                    Object.assign(responseObj, data);
                    onSuccess();
                }, error => {
                    this.utilsService.handleError(error);
                }
            )
        );
    }

    getMultipleEnums(multiServiceObjs: MultiEnumService[] | MultiEnumClassService[]) : Observable<any> {
        let finalObj : any  =   {};

        for(let baseServiceObj of multiServiceObjs){
            let serviceObj : any    =   {};

            serviceObj['service']   =   baseServiceObj.service;
            serviceObj['params']    =   baseServiceObj.params;

            finalObj[baseServiceObj.storeName || baseServiceObj.service]  =   serviceObj;
        }

        return this.apiService.post("svc/CallMultipleServices", finalObj).pipe(map(
            data => {
                if (this.utilsService.isSuccessfulResponse(data)) {
                    let finalResp : any=  {};
                    for(let serviceObjs of multiServiceObjs) {
                        let nameOfObj = serviceObjs.storeName || serviceObjs.service;
                        if(data[nameOfObj]) {
                            if(serviceObjs.addNullOption) {
                                this.addNULLOptionForEnumSelectWithLabel(data[nameOfObj].results, serviceObjs.blankLabel);
                            }
                            finalResp[nameOfObj] =   data[nameOfObj].results;
                        } else {
                            finalResp[nameOfObj] =   [];
                        }
                    }
                    return finalResp;
                }
            }
        ));
    }
}