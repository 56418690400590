/* {OPEN:IMPORT} {"sha1":"688287AC5AEAAA4B66791622C5D6238EE9FD12BB"} */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseProjectDealPopUpComponent } from './base-project-deal-pop-up.component';
/* {CLOSE:IMPORT} {"sha1":"688287AC5AEAAA4B66791622C5D6238EE9FD12BB"} */
import { Router } from '@angular/router';
import { UserService } from '../../oneit/services/user.service';
import { UtilsService } from '../../oneit/services/utils.service';
import { SearchService } from '../../oneit/services/search.service';
import { AppService } from '../../app.service';
import { ConfirmationService } from 'primeng/api';
import { ProjectDealPopUpService } from './project-deal-pop-up.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng';
import { MsgsService } from '../../oneit/services/msgs.service';
import { NgForm } from '@angular/forms';
import { ViewChild } from '@angular/core';
/* {POSTCLOSE:IMPORT} {"sha1":"688287AC5AEAAA4B66791622C5D6238EE9FD12BB"} */

/* {PREOPEN:CLASS} {"sha1":"44E8D301FDE91D1A7BE66F5110C74A88259E79E6"} */
@Component({
    selector: 'app-project-deal-pop-up',
    templateUrl: './project-deal-pop-up.component.html'
})
export class ProjectDealPopUpComponent extends BaseProjectDealPopUpComponent implements OnInit, OnDestroy {
    /* {OPEN:CLASS} {} */

    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
        super.ngOnInit();
        /* {CLOSE:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
    }
    
    ngOnDestroy() {
        /* {OPEN:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
        super.ngOnDestroy();
        /* {CLOSE:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
    }

    /* {CLOSE:CLASS} {} */

    @ViewChild('form') form: NgForm;
    errorMsgs;
    companies;

    //@override
    constructor(
        public router : Router,
        public userService : UserService,
        public utilsService : UtilsService,
        public searchService : SearchService,
        public appService : AppService,
        public confirmationService: ConfirmationService,
        public projectDealPopUpService : ProjectDealPopUpService,
        public dialogRef : DynamicDialogRef,
        public dialogConfig: DynamicDialogConfig,
        public msgsService : MsgsService
    ) {
        super(router ,userService , utilsService , searchService , appService , confirmationService ,projectDealPopUpService);
    }

    //@override
    executeScreenSpecificInitCode() {
        this.subscriptions.push(
            this.msgsService.errorMsgsUpdated.subscribe(errorMsgs => {
                this.errorMsgs = errorMsgs
            })
        );

        this.getCRMConfigOptions();

        this.includeCompanyasAccountOptions = [...new Map(this.dialogConfig.data.companies.map(item => [item['id'], item])).values()];

        this.utilsService.addNULLOptionForSelectWithLabel(this.includeCompanyasAccountOptions , 'name' , 'id' , 'Please Select');

        this.searchProjectDealPopUp.DealName = this.dialogConfig.data.project.title;
    }

    getCRMConfigOptions() {
        if(localStorage.getItem('Stages')) {
            this.stageOptions = JSON.parse(localStorage.getItem('Stages'));

            this.stageOptions.forEach(stage => {
                if(stage.default && !this.searchProjectDealPopUp.Stage) {
                    this.searchProjectDealPopUp.Stage = stage;
                }
            });
        }

        if(localStorage.getItem('Owners')) {
            this.ownerOptions = JSON.parse(localStorage.getItem('Owners'));
        }
    }

    //@override
    onCreateDealClick(event, searchProjectDealPopUp) {
        this.utilsService.clearErrorMessages();

        if(!this.form.valid) {
            this.utilsService.showAllErrorMessages();
            return;
        }

        this.showLoader = true;

        const params = {
            create: true,
            companyAccount: this.searchProjectDealPopUp.IncludeCompanyAsAccount ? this.searchProjectDealPopUp.IncludeCompanyAsAccount.id : undefined,
            dealName: this.searchProjectDealPopUp.DealName,
            notifyOwner: this.searchProjectDealPopUp.NotifyOwner,
            owner: this.searchProjectDealPopUp.Owner ? this.searchProjectDealPopUp.Owner.id : undefined,
            projects: [this.dialogConfig.data.project.id],
            stage: this.searchProjectDealPopUp.Stage ? this.searchProjectDealPopUp.Stage.id : undefined
        }

        this.subscriptions.push(
            this.appService.createDeal(params).subscribe(()=> {
                this.showLoader = false;
                this.utilsService.handleSuccessMessage('Deal created successfully');
                this.dialogRef.close();
            },error => {
                this.showLoader = false;
                this.utilsService.handleError(error);
            })
        );
    }


    //@override
    resetOwnerOptions() {
        return;
    }

    //@override
    resetStageOptions() {
        return;
    }

    //@override
    resetIncludeCompanyasAccountOptions() {
        return;
    }
}
/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */
