
<section class="text-search">
  <div layout="row" flex>
  <mat-form-field appearance="outline">
    <mat-label>Search Text</mat-label>
    <input
      matInput
      [(ngModel)]="textData.text"
      (change)="onChangeText()"
    />
  </mat-form-field>
    <mat-button-toggle-group matSuffix [(ngModel)]="textData.textType" (change)="onChangeTextType()">
      <mat-button-toggle *ngFor="let option of searchTypes | keyvalue" [value]="option.key" >{{option.value}}</mat-button-toggle>
    </mat-button-toggle-group>
</div>
</section>
