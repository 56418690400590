import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'access-denied',
    templateUrl: './access-denied.component.html'
})
export class AccessDeniedComponent implements OnInit {

    constructor(
        private router: Router
    ) { }

    ngOnInit() {
    }

    gotoHome() {
        this.router.navigate(['/']);
    }
}