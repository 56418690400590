<p-messages [(value)]="errorMsgs" *ngIf="errorMsgs && errorMsgs.length > 0 && !isAddDataProviderDialogOpen && !isCRMDialogOpen"></p-messages>
<!-- {PREOPEN:40214b84-f313-4653-9c89-859374395d3d} {"sha1":"8C4A0E2E0EFD768CAB5850B359AF669A56A45AB4"} -->
<form #form="ngForm" [class.pointer-events-none]="this.showLoader">
	<!-- {OPEN:40214b84-f313-4653-9c89-859374395d3d} {} -->
	<!-- {OPEN:ad34e86f-58e1-4fa3-a9f7-2223584de734} {} -->
	<div class="ui-g form-group">
		<!-- {OPEN:1b2ac1e3-3e06-4aec-9d78-7d6abb3797c0} {} -->
		<!-- {PREOPEN:f8114bfb-39f8-4ba4-84bc-d4fe886f29cf} {"sha1":"B9078A6B5A65352DBBD42879342F6D0A6CC4BA1E"} -->
		<div class="ui-g-12 ui-md-8">
			<!-- {OPEN:f8114bfb-39f8-4ba4-84bc-d4fe886f29cf} {} -->
			<!-- {OPEN:600a7fd3-ae7e-4d18-8df5-bf7c8c0bd7b4} {"sha1":"49B752CF75B8A15090EEFE2B9E6246F78BE8B1F4"} -->
			<label>Please select the Data Provider</label><br/>
			<span class="ui-float-label sticky" style="margin-top: 16px;">
				<p-selectButton [(ngModel)]="searchDataProviderCrmSelection.DataProvider" name="PleaseselecttheDataProvider{{ searchDataProviderCrmSelection.ObjectID }}" fieldLabel="Data Provider" required (onChange)="onPleaseselecttheDataProviderChange($event, searchDataProviderCrmSelection)" [options]="dataProviderOptions"></p-selectButton>
			</span>
			<!-- {CLOSE:600a7fd3-ae7e-4d18-8df5-bf7c8c0bd7b4} {"sha1":"49B752CF75B8A15090EEFE2B9E6246F78BE8B1F4"} -->
			<!-- {CLOSE:f8114bfb-39f8-4ba4-84bc-d4fe886f29cf} {} -->
		</div>
		<!-- {POSTCLOSE:f8114bfb-39f8-4ba4-84bc-d4fe886f29cf} {"sha1":"C4A15ED98D4B138EEB595266FB1CF4C91CF45CA5"} -->
		<!-- {PREOPEN:f5ac5d58-6199-4333-9447-2090713bb8af} {"sha1":"F527A837874DC25F83449B563E33DC65962148CE"} -->
		<div class="ui-g-12 ui-md-4">
			<!-- {OPEN:f5ac5d58-6199-4333-9447-2090713bb8af} {} -->
			<!-- {OPEN:19683ef0-fd8d-40c1-9b9e-e08019944db6} {"sha1":"7E4C1E30AFB7969D32B4D083066EB1AA3944C5F3"} -->
			<button class="buttonStyle" pButton type="button"  icon="ui-icon-refresh" (click)="resetPleaseselecttheDataProviderOptions()"></button>

			<button class="buttonStyle" pButton type="button" label="Add" icon="ui-icon-add" (click)="onAddClick($event, searchDataProviderCrmSelection)"></button>
			<!-- {CLOSE:19683ef0-fd8d-40c1-9b9e-e08019944db6} {"sha1":"7E4C1E30AFB7969D32B4D083066EB1AA3944C5F3"} -->
			<!-- {CLOSE:f5ac5d58-6199-4333-9447-2090713bb8af} {} -->
		</div>
	</div>
	<!-- {PREOPEN:042f3007-283e-4b21-a79a-69add589ed08} {"sha1":"724FD490F8585CB5C0DBA23B397395FE2B89AF83"} -->
	<div class="ui-g form-group">
		<div>
			<button class="buttonStyle" pButton type="button" label="Next" [style.width]="'250px'" (click)="onNextClick($event, searchDataProviderCrmSelection)"></button>
		</div>
	</div>
	<!-- {POSTCLOSE:042f3007-283e-4b21-a79a-69add589ed08} {"sha1":"C405F71ECE174620B27EB19690C23419C76698C5"} -->
	<!-- {CLOSE:ad34e86f-58e1-4fa3-a9f7-2223584de734} {} -->
	<p-progressSpinner *ngIf="showLoader"></p-progressSpinner>
	<!-- {CLOSE:40214b84-f313-4653-9c89-859374395d3d} {} -->
</form>
<!-- {POSTCLOSE:40214b84-f313-4653-9c89-859374395d3d} {"sha1":"3FD6E659562C1876D02FC8CF54746B1502442CD2"} -->
