import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2 } from '@angular/core';
import * as marked from 'marked';

@Directive({
    selector: '[appMarked]'
})
export class MarkedDirective implements OnInit, OnChanges {

    @Input('data') data: string;
    // @Input('highlightRegExs') highlightRegExs: RegExp[] = [];
    // @Input('highlightClasses') highlightClasses: string[] = [];
    @Input('replaceWords') replaceWords: ReplaceWord[] = [];

    constructor(private elementRef: ElementRef,
        private renderer: Renderer2) { }

    ngOnInit() {
        // deliberate use of innerHTML because we might have HTML and markdown
        // mixed together
        // const markText = this.elementRef.nativeElement.innerHTML;

        // this.convertMarked(markText);
    }

    ngOnChanges() {
        this.convertMarked(this.data);
    }

    convertMarked(toConvert) {
        if (toConvert && toConvert.length > 0) {
            for (const replaceWord of this.replaceWords) {
                toConvert = toConvert.replace(replaceWord.word, replaceWord.replaceWith);
            }
            const markdownHtml = marked(toConvert);
            this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', markdownHtml);
        }
    }
}

export class ReplaceWord {
    word: string;
    replaceWith: string;
}