import { Injectable } from '@angular/core';
import { Message } from 'primeng/api';
import { Subject } from 'rxjs';
import { ConfigService } from './config.service';

@Injectable()
export class MsgsService {

    errorMsgs: any[] = [];
    frontEndErrors: Map<string, string[]> = new Map();

    errorMsgsUpdated = new Subject<any>();
    showAllErrors = new Subject<boolean>(); //This is used to identify if all fields error should be displayed e.g. form is submitted

    constructor(
        private configService: ConfigService
    ) {

    }

    addErrorMessages(messages: any[]) {
        [].push.apply(this.errorMsgs, messages);
        this.errorMsgsUpdated.next(this.getErrorMessages());
    }

    getErrorMessages(): Message[] {
        let finalMsgs: Message[] = [];
        let uniqueMsgs: Set<string> = new Set();

        if ((this.errorMsgs && this.errorMsgs.length > 0) || (this.frontEndErrors && this.frontEndErrors.size > 0)) {
            finalMsgs.push({ severity: 'error', summary: this.configService.settings.messages.FORM_ERROR, detail: '' });

            for (let i = 0; i < this.errorMsgs.length; i++) {
                uniqueMsgs.add(this.errorMsgs[i]);
            }

            this.frontEndErrors.forEach((value: string[], key: string) => {
                for (let i = 0; i < value.length; i++) {
                    uniqueMsgs.add(value[i]);
                }
            });

            uniqueMsgs.forEach((uniqueMsg: string) => {
                finalMsgs.push({ severity: 'error', summary: '', detail: uniqueMsg });
            });
        }
        return finalMsgs;
    }

    clearErrorMessages() {
        this.errorMsgs = [];
        this.frontEndErrors.clear();
        this.errorMsgsUpdated.next(this.getErrorMessages());
        this.showAllErrors.next(false);
    }

    addFrontEndError(controlName: string, messages: string[]) {
        if (messages && messages.length > 0) {
            this.frontEndErrors.set(controlName, messages);
        }
        else {
            this.clearFrontEndErrors(controlName);
        }
        this.errorMsgsUpdated.next(this.getErrorMessages());
    }

    clearFrontEndErrors(controlName: string) {
        this.frontEndErrors.delete(controlName);
        this.errorMsgsUpdated.next(this.getErrorMessages());
    }
}