import { CISTextModel } from './models/cis-text.model';
import { CISGeoModel } from './models/cis-geo.model';
import { CisUtilServiceService } from './cis-util-service.service';
import { LocalStorageService } from './../services/local-storage.service';
import { AppService } from './../../app.service';
import { CISMetricModel } from './models/cis-metric.model';
import { CISSelectedFilter } from './models/cis-selected-filter.model';
import { CISFilter } from './models/cis-filter.model';
import { CISDate } from './cis-date-filter/cis-date.model';
import { Injectable } from '@angular/core';
import { Constants } from './consts';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { filter } from 'lodash';



@Injectable()
export class CisFilterService {

  FIELDS : [];


  constructor(private localStorageService: LocalStorageService,
    private cisUtilService: CisUtilServiceService,
    public router : Router,) {
      this.initializeSearchFilters();
      this.initializeSelectedFilters();
      this.cache = this.localStorageService.getCacheFilters();
    // this.initializeModule();
  }

  module : string;
  searchID : number;
  searchFilters : CISFilter = new CISFilter();
  selectedFilters: CISSelectedFilter  = new CISSelectedFilter();
  sortBy : "";
  cache: {};
  // selectedFilterObservable = new Observable(this, );

  public getSelectedFilters() {
    return this.selectedFilters;
  }




  search() {
    this.searchFilters.fields = this.transformSelectedFiltersToSearch();
    this.searchFilters.freeText = this.selectedFilters.freeText;
    this.selectedFilters.freeTextType = this.selectedFilters.freeTextType;
    // this.saveFilters();
    this.localStorageService.clearRefineFilters();
    // this.router.navigate(['/dashboard']);
  }


  searchUsingSearchID(module, filters) {
    this.module = module;
    this.localStorageService.setModule(module);
    this.searchFilters = _.cloneDeep(filters);
    this.initializeSelectedFilters();
  }



  saveFilters() {
    this.localStorageService.setRefineFilters(this.searchFilters);
  }

  transformSearchToFilters() {
    let filterMap = {};
    this.searchFilters.fields.forEach((field) => {
      filterMap[field['fieldName']]= field['filters'];
    });
    this.selectedFilters.fields = filterMap;
  }


  transformSelectedFiltersToSearch() {
    let filters = []
    Object.keys(this.selectedFilters.fields).forEach((key)=> {
      filters.push({fieldName: key, filters: this.selectedFilters.fields[key]});
    })
    return filters;
  }

  removeFiltersFromSearch(fieldName) {
    if(fieldName == Constants.TEXT_FILTER) {
      this.searchFilters.freeText = null;
      this.searchFilters.freeTextType = null;
      return;
    }
    delete this.selectedFilters.fields[fieldName];
    this.searchFilters.fields = this.transformSelectedFiltersToSearch();
  }


  transformFiltersToSearch(field, filters) {

    if(Constants.TEXT_FILTER == field) {
      if (_.isNil(filters) || _.isEmpty(filters)) {
        this.selectedFilters.freeText = null;
        this.selectedFilters.freeTextType = null;
      } else {
        this.selectedFilters.freeText = filters.text;
        this.selectedFilters.freeTextType = filters.textType;
      }
      return;
    }

    if(_.isNil(filters) || _.isEmpty(filters)) {
      delete this.selectedFilters.fields[field];
      return;
    }
    if(Constants.LOOKUP_FILTERS_LIST.indexOf(field) != -1) {
      let key = "value";
      if(Constants.CATEGORIES === field) key = "idValue";
      return this.transformLookupFilterForSearch(filters, key);
    }
    else if(Constants.DATE_FILTERS_LIST.indexOf(field) != -1) {
      return this.transformDateFilterForSearch(filters);
    }
    else if(Constants.METRIC_FILTERS_LIST.indexOf(field) != -1) {
      return this.transformMetricFilterForSearch(filters);
    }
    else if(Constants.TRACKING_FILTER === field) {
      return this.transformTrackingFilterForSearch(filters);
    }
    else if(Constants.GEOLOCATION === field) {
      return this.transformGeoFilterForSearch(filters);
    }
  }

  transformLookupFilterForSearch(values: any[], idField = 'value') : Array<{}> {
    let filters : Array<{}> = new Array;
    if (_.isEmpty(values)) return;
    values.forEach(value=> {
        filters.push({filterType: "Compare", [idField]: value});
      });
    return filters;
  }
  transformTrackingFilterForSearch(value: string) : Array<{}> {
    let filters : Array<{}> = new Array;
    if (_.isEmpty(value)) return;
    filters.push({filterType: "Compare", value});
    return filters;
  }
  transformDateFilterForSearch(dateFilter: CISDate ) : any[] {
    if (dateFilter.from && dateFilter.to) return [{filterType: 'Between', from: dateFilter.from, to: dateFilter.to}];
    if (dateFilter.from) return [{filterType: 'GreaterThan', value: dateFilter.from}];
    if (dateFilter.to) return [{filterType: 'LessThan', value: dateFilter.to}];
    if (dateFilter.selected && dateFilter.selected != Constants.CUSTOM_DATE) return [{filterType: 'RelativeDate', value: dateFilter.selected}];
  }

  transformMetricFilterForSearch(metricFilter: CISMetricModel ) : any[] {
    if (metricFilter.minValue && metricFilter.maxValue) return [{filterType: 'Between', from: metricFilter.minValue, to: metricFilter.maxValue}];
    if (metricFilter.minValue) return [{filterType: 'GreaterThan', value: metricFilter.minValue}];
    if (metricFilter.maxValue) return [{filterType: 'LessThan', value: metricFilter.maxValue}];
  }

  transformGeoFilterForSearch(geoFilter: CISGeoModel ) : any[] {
    if (geoFilter.latitude && geoFilter.longitude) return [{filterType: 'GeographicalRange', lat: geoFilter.latitude, lng: geoFilter.longitude, range: Number(geoFilter.range) / 1000}];
  }


  /** Method to transform values from selectedFilters to reflect changes in the different filter components.
   *
   * @param field
   * @param filters
   * @returns
   */

  transformFiltersToSetValues(field) {
    if(field == Constants.TEXT_FILTER) {
      return this.transformTextFilterToSetValues(field);
    }
    if(Constants.LOOKUP_FILTERS_LIST.indexOf(field) != -1) {
      return this.transformLookupFilterToSetValues(field);
    }
    else if(Constants.DATE_FILTERS_LIST.indexOf(field) != -1) {
      return this.transformDateFilterToSetValues(field);
    }
    else if(Constants.METRIC_FILTERS_LIST.indexOf(field) != -1) {
      return this.transformMetricFilterToSetValues(field);
    }
    else if(Constants.TRACKING_FILTER === field) {
      return this.transformTrackingFilterToSetValues(field);
    }
    else if(Constants.GEOLOCATION === field) {
      return this.transformGeoFilterToSetValues(field);
    }

  }

  transformLookupFilterToSetValues(field) {
    let filters : Array<string> = new Array();
    if (_.isEmpty(field) || !this.selectedFilters.fields.hasOwnProperty(field)) return filters;
    this.selectedFilters.fields[field].forEach(filter=> {
      if (filter.hasOwnProperty('value') && !_.isNil(filter.value) && !_.isEmpty(filter.value))
        filters.push(filter.value);
      else filters.push(filter.idValue);
    });
    return filters;
  }

  transformDateFilterToSetValues(field) {
    let filters  = new CISDate();
    if (_.isEmpty(field) || !this.selectedFilters.fields.hasOwnProperty(field)) return filters;
    this.selectedFilters.fields[field].forEach(filter=> {
      if (filter.filterType === 'Between') {
        filters.selected = Constants.CUSTOM_DATE;
        filters.from = filter.from;
        filters.to = filter.to;
      }
      else if (filter.filterType === 'LessThan') {
        filters.selected = Constants.CUSTOM_DATE;
        filters.to = filter.value;
      }
      else if (filter.filterType === 'GreaterThan') {
        filters.selected = Constants.CUSTOM_DATE;
        filters.from = filter.value;
      }
      else {
        filters.selected = filter.value;
      }
      });
    return filters;
  }

  transformTrackingFilterToSetValues(field) {
    let filters  = '';
    if (_.isEmpty(field) || !this.selectedFilters.fields.hasOwnProperty(field)) return filters;
    this.selectedFilters.fields[field].forEach(filter=> {
      if (filter.hasOwnProperty('value') && !_.isNil(filter.value) && !_.isEmpty(filter.value))
        return filter.value;
      });
    return filters;
  }

  transformGeoFilterToSetValues(field) {
    let filters  = new CISGeoModel();
    if (_.isEmpty(field) || !this.selectedFilters.fields.hasOwnProperty(field)) return filters;
    this.selectedFilters.fields[field].forEach(filter=> {
      if (filter.filterType === 'GeographicalRange') {
        filters.latitude = filter.lat;
        filters.longitude = filter.lng;
        filters.range = filter.range * 1000;
      }
      });
    return filters;
  }

  transformMetricFilterToSetValues(field) {
    let filters  = new CISMetricModel();
    if (_.isEmpty(field) || !this.selectedFilters.fields.hasOwnProperty(field)) return filters;
    this.selectedFilters.fields[field].forEach(filter=> {
      if (filter.filterType === 'Between') {
        filters.minValue = filter.from;
        filters.maxValue = filter.to;
      }
      else if (filter.filterType === 'LessThan') {
        filters.maxValue = filter.value;
      }
      else if (filter.filterType === 'GreaterThan') {
        filters.minValue = filter.value;
      }
      });
    return filters;
  }

  transformTextFilterToSetValues(field) {
    let filters  = new CISTextModel();
    if ( _.isEmpty(field) ||
    _.isNil(this.selectedFilters.freeText) || _.isEmpty(this.selectedFilters.freeText) ||
    _.isNil(this.selectedFilters.freeTextType)) return filters;

    filters.text = this.selectedFilters.freeText;
    filters.textType = _.toString(this.selectedFilters.freeTextType);
    return filters;
  }


  initializeSearchFilters = () => {
    this.searchFilters = new CISFilter();
    let filters = JSON.parse(this.localStorageService.getRefineFilters());
    if(filters?.filters) filters = filters.filters;
    // if(filters?.freeText) this.searchFilters.freeText = filters.freeText;
    // if(filters?.freeTextType) this.searchFilters.freeTextType = filters.freeTextType;

    if(!(_.isNil(filters) || _.isEmpty(filters))) {
      this.searchFilters = _.cloneDeep(filters);
    }
  }

  initializeSelectedFilters = () => {
    let filters = this.searchFilters;

    if(!(_.isNil(filters) || _.isEmpty(filters))) {
      this.selectedFilters = _.cloneDeep(this.searchFilters);
      if(!(_.isNil(filters.fields) || _.isEmpty(filters.fields))) {
        this.transformSearchToFilters();
      }
    }
  }

  resetSearchFilters = () => {
    this.searchFilters = new CISFilter();
  }

  resetSelectedFilters = () => {
    this.selectedFilters = new CISSelectedFilter();
  }


  getSearchChips(): any[] {
    let filters = [];
    if(this.searchFilters?.freeText) {
      let filterDisplay = "Search Text: " + this.searchFilters.freeText + ", Search Type: " + Constants.TEXT_SEARCH_OPTIONS[this.searchFilters.freeTextType];
      filters.push(filterDisplay);
    }
    let fields = this.searchFilters.fields
    if(fields) {
      fields.forEach((field: any) => {
        let displayFilters = [];
        field.filters.forEach(filter => {
          let value = this.cisUtilService.getDisplayValuesForFilter(filter);
          if(this.cache.hasOwnProperty(field.fieldName)) value = this.cache[field.fieldName][value];
          displayFilters.push(value);
        });
        let filterDisplay = field.fieldName + ": " + displayFilters.join(", ");
        filters.push(filterDisplay);
      });
    }
    return filters;
  }

}
