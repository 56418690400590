<!-- {PREOPEN:d575787b-456d-4057-b11d-260ac47d2c52} {"sha1":"8C4A0E2E0EFD768CAB5850B359AF669A56A45AB4"} -->
<form #form="ngForm" [class.pointer-events-none]="this.showLoader">
	<!-- {OPEN:d575787b-456d-4057-b11d-260ac47d2c52} {} -->
	<!-- {OPEN:Toolbar} {"sha1":"58C85CCF9FF02F3BAA012023D0982BED8C08259E"} -->
	<p-toolbar>
		<div class="ui-toolbar-group-left">
			{{ pageTitle }}
		</div>
	</p-toolbar>
	<!-- {CLOSE:Toolbar} {"sha1":"58C85CCF9FF02F3BAA012023D0982BED8C08259E"} -->
	<!-- {PREOPEN:8d8cf5b3-478a-4a46-99d1-bf9f31d88fe2} {"sha1":"9FC3CA20BE52A1365300762913A78DF1A87E45AD"} -->
	<p-tabView orientation="top" [(activeIndex)]="activeTabIndex" (onChange)="onTabChange($event, searchProject)">
		<!-- {OPEN:8d8cf5b3-478a-4a46-99d1-bf9f31d88fe2} {} -->
		<!-- {PREOPEN:ecdd3929-7e78-4d2f-ae89-d7bc6044013a} {"sha1":"2F4AD25E391142BA75B6407DB1672DEAE5BF17A7"} -->
		<p-tabPanel>
			<!-- {OPEN:ecdd3929-7e78-4d2f-ae89-d7bc6044013a} {} -->
			<!-- {PREOPEN:ecdd3929-7e78-4d2f-ae89-d7bc6044013a-HEADER} {"sha1":"EFFD8873F35AFEDE07E793ED170A75F21D8735DE"} -->
			<ng-template pTemplate="header">
				<!-- {OPEN:ecdd3929-7e78-4d2f-ae89-d7bc6044013a-HEADER} {"sha1":"323D8F0C41FC620F29CAEDE936A298F5F900D038"} -->
				<span class="cursor-pointer">{{isCIS()? 'CIS' : 'Barbour'}}</span>
				<!-- {CLOSE:ecdd3929-7e78-4d2f-ae89-d7bc6044013a-HEADER} {"sha1":"323D8F0C41FC620F29CAEDE936A298F5F900D038"} -->
			</ng-template>
			<!-- {POSTCLOSE:ecdd3929-7e78-4d2f-ae89-d7bc6044013a-HEADER} {"sha1":"08D2DC238814DBE0D3FB2F9729EBA749BB91A4A1"} -->
			<!-- {OPEN:c0efa127-09e1-4d1e-86ff-3657dbcf1e6c} {} -->
			<!-- {PREOPEN:a5be1380-f629-448e-b6c8-66115478085c} {"sha1":"5DAE16E2D9FB1E24F06875FEC6B97E9D7BEFDB68"} -->
            <app-searcher [setFilters]="filterParam" [setModule]="module"
						  (search)="onSearchClick($event, searchProject)"></app-searcher>
			<!-- {POSTCLOSE:a5be1380-f629-448e-b6c8-66115478085c} {"sha1":"797F18B33CA2DAC7B5A389B76ABFD8D91F547465"} -->
			<!-- {PREOPEN:0cab4beb-f7d7-48e5-af9a-212cf443c312} {"sha1":"5DAE16E2D9FB1E24F06875FEC6B97E9D7BEFDB68"} -->
			<div class="ui-g">
				<!-- {OPEN:0cab4beb-f7d7-48e5-af9a-212cf443c312} {} -->
				<!-- {PREOPEN:fd82f594-f11c-4203-8061-a5619915d48f} {"sha1":"D7D7C86781ACFD0FC50A8E98DC55A989939B33B1"} -->
				<div class="ui-g-12 ui-md-9" style="display: flex; justify-content: space-between; align-items: center">
					<!-- {OPEN:fd82f594-f11c-4203-8061-a5619915d48f} {} -->
					<!-- {OPEN:801abc4d-8220-4b5c-b0ab-87bf382f9c58} {"sha1":"05A0D2C086A1EA05C53732A4AB8E688233111FF3"} -->
					<div style="padding: 10px">
						<span>{{projectsOrCompanies?.length > 0 && totalRecords  ?'Fetched ' + projectsOrCompanies?.length + ' of ' + totalRecords : ''}}</span>
					</div>
					<div *ngIf="!isTableView" [style.text-align]="'right'"[style.paddingTop.px]="'10'" [style.paddingLeft.px]="'10'" [style.paddingBottom.px]="'10'" [style.paddingRight.px]="'10'">
						<button pButton type="button"  label="Table View" class="btn-secondary" icon="ui-icon-border-all" [style.text-align]="'right'" (click)="showTable()"></button>
					</div>
					<div *ngIf="isTableView" [style.text-align]="'right'" [style.paddingTop.px]="'10'" [style.paddingLeft.px]="'10'" [style.paddingBottom.px]="'10'" [style.paddingRight.px]="'10'">
						<button pButton type="button" label="Map View" class="btn-secondary" icon="ui-icon-map" [style.text-align]="'right'" (click)="showMap()"></button>
					</div>
					<!-- {CLOSE:801abc4d-8220-4b5c-b0ab-87bf382f9c58} {"sha1":"05A0D2C086A1EA05C53732A4AB8E688233111FF3"} -->
					<!-- {CLOSE:fd82f594-f11c-4203-8061-a5619915d48f} {} -->
				</div>
				<!-- {POSTCLOSE:fd82f594-f11c-4203-8061-a5619915d48f} {"sha1":"C4A15ED98D4B138EEB595266FB1CF4C91CF45CA5"} -->
				<!-- {PREOPEN:4a9f6765-ee20-4da1-a5e5-c2a846575aec} {"sha1":"F527A837874DC25F83449B563E33DC65962148CE"} -->
				<div class="ui-g-12 ui-md-3">
					<!-- {OPEN:4a9f6765-ee20-4da1-a5e5-c2a846575aec} {} -->
					<!-- {OPEN:1d97bb8c-a583-4b6a-90e3-e06fc36ce6ab} {"sha1":"C732BD2D9D9CA7F84F65EC01E399E8A8E412AD65"} -->
					<div [style.text-align]="'center'" [style.paddingTop.px]="'10'" [style.paddingLeft.px]="'10'" [style.paddingBottom.px]="'10'" [style.paddingRight.px]="'10'">
						<button pButton type="button" label="Save Search" class="btn-secondary" icon="ui-icon-save" (click)="onSaveSearchClick($event, searchProject)"></button>
					</div>
					<!-- {CLOSE:1d97bb8c-a583-4b6a-90e3-e06fc36ce6ab} {"sha1":"C732BD2D9D9CA7F84F65EC01E399E8A8E412AD65"} -->
					<!-- {CLOSE:4a9f6765-ee20-4da1-a5e5-c2a846575aec} {} -->
				</div>
				<!-- {POSTCLOSE:4a9f6765-ee20-4da1-a5e5-c2a846575aec} {"sha1":"C4A15ED98D4B138EEB595266FB1CF4C91CF45CA5"} -->
				<!-- {CLOSE:0cab4beb-f7d7-48e5-af9a-212cf443c312} {} -->
			</div>
			<!-- {POSTCLOSE:0cab4beb-f7d7-48e5-af9a-212cf443c312} {"sha1":"797F18B33CA2DAC7B5A389B76ABFD8D91F547465"} -->
			<!-- {CLOSE:c0efa127-09e1-4d1e-86ff-3657dbcf1e6c} {} -->
			<!-- {OPEN:1f07035e-6764-4823-ab48-63281b0497a3} {} -->
			<!-- {PREOPEN:966287a7-9ee2-4ccb-8bce-ca5cc7e1ada8} {"sha1":"5DAE16E2D9FB1E24F06875FEC6B97E9D7BEFDB68"} -->
			<div [hidden]="isTableView">
        <div class="flex-container">
				<div class="flex-child card-list" [class.card-hidden]="isCardHidden">
          <div *ngIf="!isCardHidden" [style.text-align]="'right'"  [style.paddingTop.px]="'10'" [style.paddingBottom.px]="'10'">
            <button pButton type="button" icon="ui-icon-chevron-left" class="collapse-button" (click)="onChevronClick($event, searchProject)"></button>
          </div>
          <div *ngIf="isCardHidden" [style.text-align]="'right'" [style.paddingTop.px]="'10'" [style.paddingBottom.px]="'10'">
            <button pButton type="button" icon="ui-icon-chevron-right" class="collapse-button" (click)="onChevronClick($event, searchProject)"></button>
          </div>

          <div class="card-results" [class.card-hidden]="isCardHidden">
						<div *ngIf="!isCardHidden && (projectsOrCompanies && projectsOrCompanies.length < totalRecords)" class="ui-fluid">
							<button [disabled]="isShowMoreClicked" pButton type="button" *ngIf="projectsOrCompanies?.length" label="Show More" [class]='["btn-secondary"]' (click)="onShowMoreClick($event, searchProject)"></button>
						</div>
            <app-dashboard-cards [hidden]="isTableView || isCardHidden" [setProjectsOrCompanies]="projectsOrCompanies"></app-dashboard-cards>
					</div>

        </div>
          <div class="flex-child map-view" [class.card-hidden]="isCardHidden">
            <map-search-view [projectsOrCompanies]="projectsOrCompanies" [hoveredItem]="hoveredCompanyID" (addFavouriteEmitter)="onAddFavouriteClick($event)" ></map-search-view>
        </div>
      </div>
      </div>
			<app-table-view [hidden]="!isTableView" [setConvertedDeals]="convertedDeals" [setProjectsOrCompanies]="projectsOrCompanies" (addFavouriteEmitter)="onAddFavouriteClick($event)"></app-table-view>
			<!-- {POSTCLOSE:966287a7-9ee2-4ccb-8bce-ca5cc7e1ada8} {"sha1":"797F18B33CA2DAC7B5A389B76ABFD8D91F547465"} -->
			<!-- {CLOSE:1f07035e-6764-4823-ab48-63281b0497a3} {} -->
			<!-- {CLOSE:ecdd3929-7e78-4d2f-ae89-d7bc6044013a} {} -->
		</p-tabPanel>
		<!-- {POSTCLOSE:ecdd3929-7e78-4d2f-ae89-d7bc6044013a} {"sha1":"F49AEAFEC8DDF0FE02750884DD3DAEAF27C428C6"} -->
		<!-- {PREOPEN:c7947362-fbca-4c3d-9732-b01124675a22} {"sha1":"2F4AD25E391142BA75B6407DB1672DEAE5BF17A7"} -->
		<p-tabPanel [disabled]="true">
			<!-- {OPEN:c7947362-fbca-4c3d-9732-b01124675a22} {} -->
			<!-- {PREOPEN:c7947362-fbca-4c3d-9732-b01124675a22-HEADER} {"sha1":"EFFD8873F35AFEDE07E793ED170A75F21D8735DE"} -->
			<ng-template pTemplate="header">
				<!-- {OPEN:c7947362-fbca-4c3d-9732-b01124675a22-HEADER} {"sha1":"17ACF433F8F8EB4C7AD6E588F3695EF9614B5B0D"} -->
				<span class="cursor-pointer" (click)="onAddClick()">+ Add</span>
				<!-- {CLOSE:c7947362-fbca-4c3d-9732-b01124675a22-HEADER} {"sha1":"17ACF433F8F8EB4C7AD6E588F3695EF9614B5B0D"} -->
			</ng-template>
			<!-- {POSTCLOSE:c7947362-fbca-4c3d-9732-b01124675a22-HEADER} {"sha1":"08D2DC238814DBE0D3FB2F9729EBA749BB91A4A1"} -->
			<!-- {CLOSE:c7947362-fbca-4c3d-9732-b01124675a22} {} -->
		</p-tabPanel>
		<!-- {POSTCLOSE:c7947362-fbca-4c3d-9732-b01124675a22} {"sha1":"F49AEAFEC8DDF0FE02750884DD3DAEAF27C428C6"} -->
		<!-- {CLOSE:8d8cf5b3-478a-4a46-99d1-bf9f31d88fe2} {} -->
	</p-tabView>
	<!-- {POSTCLOSE:8d8cf5b3-478a-4a46-99d1-bf9f31d88fe2} {"sha1":"5ECEB12B574903FC69B702C1DC533C590ADF76B3"} -->
	<oneit-confirmDialog></oneit-confirmDialog>
	<p-progressSpinner *ngIf="showLoader"></p-progressSpinner>
	<!-- {CLOSE:d575787b-456d-4057-b11d-260ac47d2c52} {} -->
</form>
<!-- {POSTCLOSE:d575787b-456d-4057-b11d-260ac47d2c52} {"sha1":"3FD6E659562C1876D02FC8CF54746B1502442CD2"} -->
