<div style="overflow: hidden">
  <div mat-dialog-title class="header-cis-buttons">
    <h1 class="column filters-header" style="width: 90%; text-align: center">
      CIS Filters
    </h1>
    <div class="column" style="width: 10%; text-align: right">
      <button mat-raised-button mat-dialog-close color="primary">Close</button>
    </div>
  </div>
  <div mat-dialog-content class="cis-refine-filters">
    <div class="flex" style="width: 100%">
      <div class="cis-filters-optns">
        <!-- <p-tabView [scrollTop]="true" orientation="left" [(activeIndex)]="index" (onChange)="selectFilter($event)">
          <p-tabPanel class="filter-panel" *ngFor="let filter of getAllFilters()">
            <ng-template pTemplate="header">
              <span>{{ Constants.FILTERS_UI[filter] }}</span>
            </ng-template>
            <ng-container>
              <app-tree-filter *ngIf="isLookupFilter(filter)" [_data]="getLookupFilterValues(filter)" [_key]="filter"
                name="filter" [selectedNodes]="
                  filterService.transformFiltersToSetValues(filter)
                " (dataEmitter)="getFilterChange($event)">
              </app-tree-filter>

              <div *ngIf="isMetricFilter(filter)">
                <div *ngFor="let metricFilter of metricFilters">
                  <table style="width: 100%">
                    <tr>
                      <td style="width: 20%">
                        <mat-label>{{
                          Constants.FILTERS_UI[metricFilter]
                          }}</mat-label>
                      </td>
                      <td>
                        <cis-metric-filter [_key]="metricFilter" name="metricFilter"
                          (dataEmitter)="getFilterChange($event)" [metricInput]="
                            filterService.transformFiltersToSetValues(
                              metricFilter
                            )
                          ">
                        </cis-metric-filter>
                        <p-divider></p-divider>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>

              <div *ngIf="isDateFilter(filter)">
                <div *ngFor="let dateFilter of dateFilters | keyvalue">
                  <mat-label>{{
                    Constants.FILTERS_UI[dateFilter.key]
                    }}</mat-label>
                  <cis-date-filter [_key]="dateFilter.key" [pastOptions]="dateFilter.value.pastOptions"
                    [futureOptions]="dateFilter.value.futureOptions" [betweenOptions]="dateFilter.value.betweenOptions"
                    (dataEmitter)="getFilterChange($event)" [dateData]="
                      filterService.transformFiltersToSetValues(dateFilter.key)
                    ">
                  </cis-date-filter>
                </div>
              </div>

              <cis-date-filter *ngIf="Constants.DATE_UPDATED == filter" [_key]="filter" [pastOptions]="true"
                [futureOptions]="false" [betweenOptions]="true" (dataEmitter)="getFilterChange($event)"
                [dateData]="filterService.transformFiltersToSetValues(filter)">
              </cis-date-filter>

              <cis-metric-filter *ngIf="Constants.VALUE == filter" [_key]="filter" name="filter"
                (dataEmitter)="getFilterChange($event)" [metricInput]="
                  filterService.transformFiltersToSetValues(filter)
                ">
              </cis-metric-filter>

              <cis-geo-filter *ngIf="Constants.GEOLOCATION == filter" [_key]="filter" name="filter"
                (dataEmitter)="getFilterChange($event)" [geoData]="filterService.transformFiltersToSetValues(filter)">
              </cis-geo-filter>

              <cis-text-filter *ngIf="Constants.TEXT_FILTER == filter" [_key]="filter" name="filter"
                (dataEmitter)="getFilterChange($event)" [textInput]="filterService.transformFiltersToSetValues(filter)">
              </cis-text-filter>

              <cis-tracking-filter *ngIf="Constants.TRACKING_FILTER == filter" [_key]="filter" name="filter"
                (dataEmitter)="getFilterChange($event)" [trackingStatus]="
                  filterService.transformFiltersToSetValues(filter)
                ">
              </cis-tracking-filter>
            </ng-container>
          </p-tabPanel>
        </p-tabView> -->


        <mat-tab-group [@.disabled]="true" selectedIndex="index" >

          <mat-tab class="filter-panel" *ngFor="let filter of getAllFilters()">
            <ng-template mat-tab-label>
              <span>{{ Constants.FILTERS_UI[filter] }}</span>
              </ng-template>
            <div class="cis-filter-panel-box">
              <ng-container>
                  <h2>{{ Constants.FILTERS_UI[filter] }}</h2>
                  <mat-divider></mat-divider>
                  <div class="cis-filter-panel">
                  <app-tree-filter *ngIf="isLookupFilter(filter)" [_data]="getLookupFilterValues(filter)" [_key]="filter"
                name="filter" [selectedNodes]="
                  filterService.transformFiltersToSetValues(filter)
                " (dataEmitter)="getFilterChange($event)">
              </app-tree-filter>

              <div *ngIf="isMetricFilter(filter)">
                <div *ngFor="let metricFilter of metricFilters">

                  <table style="width: 100%">
                    <tr>
                      <td style="width: 20%">
                        <mat-label>{{
                          Constants.FILTERS_UI[metricFilter]
                          }}</mat-label>
                      </td>
                      <td>
                        <cis-metric-filter [_key]="metricFilter" name="metricFilter"
                          (dataEmitter)="getFilterChange($event)" [metricInput]="
                            filterService.transformFiltersToSetValues(
                              metricFilter
                            )
                          ">
                        </cis-metric-filter>
                      </td>
                    </tr>
                  </table>
                  <mat-divider></mat-divider>
                </div>
              </div>

              <div *ngIf="isDateFilter(filter)">
                <div *ngFor="let dateFilter of dateFilters | keyvalue">
                  <table style="width: 100%">
                    <tr>
                      <td style="width: 20%">
                        <mat-label>{{
                    Constants.FILTERS_UI[dateFilter.key]
                  }}</mat-label>
              </td>
              <td>
                  <cis-date-filter [_key]="dateFilter.key" [pastOptions]="dateFilter.value.pastOptions"
                    [futureOptions]="dateFilter.value.futureOptions" [betweenOptions]="dateFilter.value.betweenOptions"
                    (dataEmitter)="getFilterChange($event)" [dateData]="
                      filterService.transformFiltersToSetValues(dateFilter.key)
                    ">
                  </cis-date-filter>
                  </td>
                  </tr>
                  </table>
                  <mat-divider></mat-divider>
                </div>
              </div>

              <cis-date-filter *ngIf="Constants.DATE_UPDATED == filter" [_key]="filter" [pastOptions]="true"
                [futureOptions]="false" [betweenOptions]="true" (dataEmitter)="getFilterChange($event)"
                [dateData]="filterService.transformFiltersToSetValues(filter)">
              </cis-date-filter>

              <cis-metric-filter *ngIf="Constants.VALUE == filter" [_key]="filter" name="filter"
                (dataEmitter)="getFilterChange($event)" [metricInput]="
                  filterService.transformFiltersToSetValues(filter)
                ">
              </cis-metric-filter>

              <cis-geo-filter *ngIf="Constants.GEOLOCATION == filter" [_key]="filter" name="filter"
                (dataEmitter)="getFilterChange($event)" [geoData]="filterService.transformFiltersToSetValues(filter)">
              </cis-geo-filter>

              <cis-text-filter *ngIf="Constants.TEXT_FILTER == filter" [_key]="filter" name="filter"
                (dataEmitter)="getFilterChange($event)" [textInput]="filterService.transformFiltersToSetValues(filter)">
              </cis-text-filter>

              <cis-tracking-filter *ngIf="Constants.TRACKING_FILTER == filter" [_key]="filter" name="filter"
                (dataEmitter)="getFilterChange($event)" [trackingStatus]="
                  filterService.transformFiltersToSetValues(filter)
                ">
              </cis-tracking-filter>
              </div>
            </ng-container>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>






      <div class="cis-summary">
        <cis-summary-search (removeEmitter)="removeFilter($event)"></cis-summary-search>
      </div>
    </div>
  </div>
  <div mat-dialog-actions align="end" class="footer-cis-buttons">
    <div class="column" style="float: right">
      <button mat-raised-button (click)="onResetClick()">Clear</button>
    </div>
    <div class="column" style="float: right">
      <button mat-raised-button mat-dialog-close="true" color="primary" (click)="onSearchClick()">
        Search
      </button>
    </div>
  </div>
</div>
