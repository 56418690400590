/* {OPEN:IMPORT} {"sha1":"904FBF6DB7D8BA6235ECEA03865FCE42C35479AC"} */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseCompanyComponent } from './base-company.component';
/* {CLOSE:IMPORT} {"sha1":"904FBF6DB7D8BA6235ECEA03865FCE42C35479AC"} */
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../oneit/services/user.service';
import { UtilsService } from '../../oneit/services/utils.service';
import { SearchService } from '../../oneit/services/search.service';
import { AppService } from '../../app.service';
import { ConfirmationService } from 'primeng/api';
import { FullLayoutComponent } from '../../oneit/components/full-layout/full-layout.component';
import { DialogService } from 'primeng';
import { CompanyService } from './company.service';
import { takeUntil } from 'rxjs/operators';
import { CreateAccountComponent } from '../create-account/create-account.component';
import { Subject } from 'rxjs';
/* {POSTCLOSE:IMPORT} {"sha1":"904FBF6DB7D8BA6235ECEA03865FCE42C35479AC"} */


/* {PREOPEN:CLASS} {"sha1":"DB1D19A72590E3EEB2C635352C421ADD56211EE3"} */
@Component({
    selector: 'app-company',
    templateUrl: './company.component.html'
})
export class CompanyComponent extends BaseCompanyComponent implements OnInit, OnDestroy {
    /* {OPEN:CLASS} {} */

    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
        super.ngOnInit();
        /* {CLOSE:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
    }
    
    ngOnDestroy() {
        /* {OPEN:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
        super.ngOnDestroy();
        /* {CLOSE:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
    }

    /* {CLOSE:CLASS} {} */

    company;
    roles;
    updates;
    accounts;
    module = 'companies';
    componentInView = new Subject();

    //@override
    constructor(
        public router: Router,
        public userService: UserService,
        public utilsService: UtilsService,
        public searchService: SearchService,
        public appService: AppService,
        public confirmationService: ConfirmationService,
        public companyService: CompanyService,
        public fullLayoutComponent: FullLayoutComponent,
        public dialogService: DialogService,
        public activatedRoute: ActivatedRoute
    ) {
        super(router, userService, utilsService, searchService, appService, confirmationService, companyService);
    }

    //@override
    executeScreenSpecificInitCode() {
        this.subscriptions.push(
            this.activatedRoute.params.subscribe(params => {
                if(params.id) {
                    this.getCompanyByID(params.id);
                    this.getCompanyTabDetails(params.id , 'roles');
                    this.getCompanyTabDetails(params.id , 'updates');
                    this.getCompanyTabDetails(params.id , 'accounts');
                }
            })
        );
    }

    getCompanyByID(id) {
        this.showLoader = true;

        this.subscriptions.push(
            this.companyService.getCompanyByID(id , this.module).subscribe(data => {
                this.showLoader = false;
                this.company = data;
            }, error => {
                this.showLoader = false;
                this.utilsService.handleError(error);
            })
        );
    }

    getCompanyTabDetails(id , tab) {
        this.showLoader = true;

        this.subscriptions.push(
            this.companyService.getCompanyTabDetails(id , this.module , tab).subscribe(data => {
                this.showLoader = false;
                if(tab === 'roles') {
                    this.roles = data;
                }

                if(tab === 'updates') {
                    this.updates = data;
                }

                if(tab === 'accounts') {
                    this.accounts = data;

                    if(localStorage.getItem('Owners')) {
                        const ownerOptions = JSON.parse(localStorage.getItem('Owners'));

                        this.accounts.forEach(account => {
                            ownerOptions.forEach(owner => {
                                if(account.ownerId === owner.id) {
                                    account.ownerName = owner.name;
                                }
                            })
                        })
                    }
                }

            }, error => {
                this.showLoader = false;
                this.utilsService.handleError(error);
            })
        );
    }

    //override
    onCreateAccountClick(event, searchCompany) {
        this.utilsService.clearErrorMessages();

        this.fullLayoutComponent.dialogOpen = true;

        this.dialogService.open(CreateAccountComponent, {
            header: 'Create Account',
            ...this.appService.dynamicDialogConfig,
            data: {company_id: this.company.id}
        }).onClose.pipe(takeUntil(this.componentInView)).subscribe(() => {
            this.utilsService.clearErrorMessages();
            this.fullLayoutComponent.dialogOpen = false;
            this.executeScreenSpecificInitCode();
        });
    }

    //@override
    onCloseClick(event, searchCompany) {
        this.utilsService.clearErrorMessages();

        this.router.navigate(['./dashboard']);
    }

    //@override
    onStarClick(event, searchProject) {
        this.utilsService.clearErrorMessages();

        this.showLoader = true;

        let params = {
            favourite: true
        };

        this.subscriptions.push(
            this.companyService.markModuleAsFavorite(params , this.module , this.company.id).subscribe(() => {
                this.showLoader = false;
                this.getCompanyByID(this.company.id);
            }, error => {
                this.showLoader = false;
                this.utilsService.handleError(error);
            })
        );
    }

    //@override
    getRolesTableCols() : any[] {
        return [
            { field: 'role.roleDescription', header: 'Role', width: '10px', noSort: false, noFilter: false },
            { field: 'project.title', header: 'Project', width: '22px', noSort: false, noFilter: false },
            { field: 'people.fullName', header: 'Contact', width: '10px', noSort: false, noFilter: false }
        ];
    }

    //@override
    getUpdatesTableCols() : any[] {
        return [
            { field: 'description', header: 'Description', width: '10px', noSort: false, noFilter: false },
            { field: 'updateDate', header: 'Date', width: '2px', noSort: false, noFilter: false }
        ];
    }
}
/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */
