import { AccordionModule } from 'primeng/accordion';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ChipsModule } from 'primeng/chips';
import { ColorPickerModule } from 'primeng/colorpicker';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KeyFilterModule } from 'primeng/keyfilter';
import { LightboxModule } from 'primeng/lightbox';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SpinnerModule } from 'primeng/spinner';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { OneITAutoCompleteModule } from '../oneit/modules/autocomplete';
import { OneITCheckboxModule } from '../oneit/modules/checkbox';
import { OneITConfirmDialogModule } from '../oneit/modules/confirmdialog';
import { OneITRadioButtonModule } from '../oneit/modules/radiobutton';

export const PrimeNGModules = [
    MegaMenuModule,
    MenubarModule,
    ScrollPanelModule,
    TieredMenuModule,
    ButtonModule,
    TableModule,
    OneITConfirmDialogModule,
    TabViewModule,
    SplitButtonModule,
    ToolbarModule,
    DropdownModule,
    DialogModule,
    CardModule,
    ChipsModule,
    ColorPickerModule,
    FieldsetModule,
    SelectButtonModule,
    OneITCheckboxModule,
    OneITAutoCompleteModule,
    InputTextModule,
    InputTextareaModule,
    InputSwitchModule,
    ProgressSpinnerModule,
    CalendarModule,
    MessagesModule,
    SpinnerModule,
    PanelModule,
    MultiSelectModule,
    EditorModule,
    FileUploadModule,
    LightboxModule,
    ListboxModule,
    AccordionModule,
    BreadcrumbModule,
    MenuModule,
    OverlayPanelModule,
    TooltipModule,
    ToastModule,
    OneITRadioButtonModule,
    KeyFilterModule,
    SidebarModule,
    ToggleButtonModule
];