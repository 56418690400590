<div>
  <div class="example-label-container" [class]="'example-label-vertical'">
    <label id="example-name-label" class="example-name-label">Range: </label>
    <label class="example-value-label">{{formatLabel(data?.range)}}</label>
  </div>
  <mat-slider
    thumbLabel
    [displayWith]="formatLabel"
    [(ngModel)]="data.range"
    tickInterval="1000"
    step="1000"
    min="1000"
    max="300000"
    aria-label="units"
  >
  </mat-slider>
  <button mat-raised-button color="primary" (click)="applyFilter()">Apply</button>
  <button mat-raised-button (click)="onClear()">Clear</button>
  <br/>
  <mat-form-field appearance="outline">
    <mat-label>Latitude</mat-label>
    <input
      disabled
      matInput
      [(ngModel)]="data.latitude"
      (change)="onChangeLatLng($event.target.value)"
      appFiveDigitDecimaNumber
    />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Longitude</mat-label>
    <input
      disabled
      matInput
      [(ngModel)]="data.longitude"
      (change)="onChangeLatLng($event.target.value)"
      appFiveDigitDecimaNumber
    />
  </mat-form-field>
  <br />

<div style="max-width: 400px;max-height: 400px;">
  <google-map [center]="center" width="50vh" height="30vh" [zoom]="zoom" [options]="options">
    <map-circle
      [center]="circleCenter"
      [radius]="data.range"
      [options]="circleOptions"
      (centerChanged)="centerChanged()"
    >
    </map-circle>
  </google-map>
  </div>
</div>
