import { CISMetricModel } from '../models/cis-metric.model';
import { Component, Input, OnInit } from '@angular/core';
import { BaseFilterComponent } from '../../base-filter/base-filter.component';

@Component({
  selector: 'cis-metric-filter',
  templateUrl: './cis-metric-filter.component.html',
  styleUrls: ['./cis-metric-filter.component.scss']
})
export class CisMetricFilterComponent extends BaseFilterComponent implements OnInit {


  @Input()
  metricInput = new CISMetricModel();

  metricData: CISMetricModel = new CISMetricModel();

  ngOnInit(): void {
    this.metricData = this.metricInput;
  }

  onChangeMin(val) {
    if(this.metricData.minValue == 0) {
      this.metricData.minValue = null;
    }
    this.emitValues();
  }
  onChangeMax(val) {
    if(this.metricData.maxValue == 0) {
      this.metricData.maxValue = null;
    }
    this.emitValues();
  }

  emitValues() {
    super.emitValues(this.metricData);
  }

  onClear() {
    this.metricData =new CISMetricModel();
  }


}
