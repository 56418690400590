<div *ngIf="(apiLoaded | async)">
  <google-map [center]="center" [zoom]="6" [options]="options" height="600px" width="100%" (mapClick)="closeInfoWindow()">

      <map-marker #marker="mapMarker" *ngFor="let item of projectsOrCompanies" [position]="getMarkerPosition(item)" [title]="getItemTitle(item)"
        [options]="getMarkerOptions(item)"
        (mapClick)="openInfoWindow(marker, item)">
      </map-marker>


    <map-info-window #infoWindow>
      <div *ngIf="selectedItem" class="map-window-cntnt">
        <div *ngIf="appService.isProjectsSelected()">
          <p class="title" *ngIf="selectedItem?.title">Project Title: {{ selectedItem?.title }}</p>
          <p *ngIf="selectedItem?.primarySector">Sector: {{ selectedItem?.primarySector }}</p>
          <p *ngIf="selectedItem?.value">Value: {{ item?.value }}</p>

          <p *ngIf="selectedItem?.planningStage">
            Planning Stage: {{ selectedItem?.planningStage }}
          </p>
          <p *ngIf="selectedItem?.lastUpdated">Updated: {{selectedItem?.lastUpdated}}</p>
        </div>

        <div *ngIf="appService.isCompaniesSelected()">
          <p class="title" *ngIf="selectedItem?.name">Company Name: {{ selectedItem?.name }}</p>
          <p *ngIf="selectedItem?.phone">Phone: {{ selectedItem?.phone }}</p>
          <p *ngIf="
          selectedItem?.Address1 ||
          selectedItem?.Address2 ||
          selectedItem?.Address3 ||
          selectedItem?.Address4
          ">
            Address: {{ appService.getModuleAddress(selectedItem) }}
          </p>
        </div>

        <p class="not-favourite" *ngIf="!selectedItem.favourite">
          Not saved as a favorite
        </p>
        <p class="favourite" *ngIf="selectedItem.favourite">Marked as a favorite</p>

        <div class="d-flex">
          <button pButton type="button" [style.width]="!selectedItem.favourite ? '50%' : '100% !important'"
            [routerLink]="'/search/' + selectedItem.module + '/' + selectedItem?.id" class="view-project-btn" [label]="
            appService.isProjectsSelected() ? 'View Project' : 'View Company'
          "></button>
          <button pButton *ngIf="!selectedItem.favourite" type="button" (click)="onAddFavouriteClick(selectedItem)"
            class="add-favorite-btn" label="Add Favorite"></button>
        </div>
      </div>
</map-info-window>
</google-map>
</div>
