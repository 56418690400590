import { Component, EventEmitter, Input, Output } from "@angular/core";


@Component({
  selector: 'cis-base-filter',
  template: ``,
  styles: []
})
export abstract class BaseFilterComponent {

  @Input()
  _key : string;

  @Output()
  public dataEmitter: EventEmitter<{}> = new EventEmitter();

  setKey(key: string): void {
    this._key = key;
  }

  getKey() : string {
    return this._key;
  }

  abstract onClear();

  emitValues(data) {
    this.dataEmitter.emit({key: this.getKey(), data:  data});
  }

}
