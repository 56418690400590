/* {OPEN:IMPORT} {"sha1":"D4EADF4E256F0E9DF3ACDD8F6857D28AEF498AD0"} */
import { Component, DoCheck, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import { Message, MenuItem, ConfirmationService } from 'primeng/api';
import { AppService } from './../../app.service';
import { OptionsObservable, OptionsWrapper } from './../../oneit/models/options.model';
import { UserService } from './../../oneit/services/user.service';
import { UtilsService } from './../../oneit/services/utils.service';
import { SearchService } from './../../oneit/services/search.service';
import { AddDataProviderService } from './add-data-provider.service';
import { SearchDataProvider } from './add-data-provider.model';
/* {CLOSE:IMPORT} {"sha1":"D4EADF4E256F0E9DF3ACDD8F6857D28AEF498AD0"} */

@Component({
    selector: 'app-base-add-data-provider',
    template: ''
})
/* {PREOPEN:CLASS} {"sha1":"4194224A6E2CFE9214714923DFB03F63097878EF"} */
export class BaseAddDataProviderComponent implements DoCheck, OnInit, OnDestroy {
    /* {OPEN:CLASS} {} */

    /* {OPEN:COMMON_VARS} {"sha1":"672A211DEEA9A3559D121CBDC5978BBA47BD6B7D"} */
    @Input() parent : any;
    pageTitle : string;
    /* {CLOSE:COMMON_VARS} {"sha1":"672A211DEEA9A3559D121CBDC5978BBA47BD6B7D"} */

    /* {OPEN:VARS} {"sha1":"DAB7620410D2C933822C19BA003624B3E09D12F4"} */
    searchDataProvider : SearchDataProvider = new SearchDataProvider();
    dataProviderOptions : any[] = [];
    isDataProviderVolatile : boolean = false;
    /* {CLOSE:VARS} {"sha1":"DAB7620410D2C933822C19BA003624B3E09D12F4"} */

    showResults : boolean = false;
    showLoader : boolean = false;
    subscriptions : Array<Subscription> = [];

    constructor(
        /* {OPEN:constructor} {"sha1":"0F98961E33FD59C07108BD2AA72678BFB7AE0BE6"} */
        public router : Router,
        public userService : UserService,
        public utilsService : UtilsService,
        public searchService : SearchService,
        public appService : AppService,
        public confirmationService: ConfirmationService,
        /* {CLOSE:constructor} {"sha1":"0F98961E33FD59C07108BD2AA72678BFB7AE0BE6"} */
    ){

    }

    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"5FBDAAB044BC4F999356808F755A7334E11EE273"} */
        this.resetInitVariables();
        this.calculateEverything();
        this.executeScreenSpecificInitCode();
        /* {CLOSE:ngOnInit} {"sha1":"5FBDAAB044BC4F999356808F755A7334E11EE273"} */
    }

    /* {PREOPEN:executeScreenSpecificInitCode} {"sha1":"DDD081544316B33777AB7B7FFD796FB9E663E681"} */
    executeScreenSpecificInitCode() {
        /* {OPEN:executeScreenSpecificInitCode} {"sha1":"54DB41AD32937D86C7318EB4BE666A1AD7B6B618"} */
        if(!this.parent) {
        }
        /* {CLOSE:executeScreenSpecificInitCode} {"sha1":"54DB41AD32937D86C7318EB4BE666A1AD7B6B618"} */
    }
    /* {POSTCLOSE:executeScreenSpecificInitCode} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getDataProviderOptions} {"sha1":"BAD22146EC695CFEA7ECB729CE4E6EF7FE95726C"} */
    getDataProviderOptions(): OptionsWrapper {
        /* {OPEN:getDataProviderOptions} {"sha1":"BE54A099083ABB33F6834F7DF9AC0387311D950A"} */
        return new OptionsObservable(false, this.appService.getDataProvidersObservable());
        /* {CLOSE:getDataProviderOptions} {"sha1":"BE54A099083ABB33F6834F7DF9AC0387311D950A"} */
    }
    /* {POSTCLOSE:getDataProviderOptions} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:resetDataProviderOptions} {"sha1":"9DD8B013654A4359EEBB57F7C354F59A03A06AA7"} */
    resetDataProviderOptions() {
        /* {OPEN:resetDataProviderOptions} {"sha1":"0F0B27714C8245CF679541F656EFA2BAE3FC81B1"} */
        if(this.parent) {
            this.parent.resetDataProviderOptions();
        } else {
            let wrapper : OptionsWrapper = this.getDataProviderOptions();

            if (wrapper) {
                this.isDataProviderVolatile = wrapper.isVolatile;

                this.subscriptions.push(wrapper.getObservable()
                    .subscribe (
                        data => {
                            this.dataProviderOptions = data;
                        },
                        error => {
                            this.utilsService.handleError(error);
                        }
                    )
                );
            }
        }
        /* {CLOSE:resetDataProviderOptions} {"sha1":"0F0B27714C8245CF679541F656EFA2BAE3FC81B1"} */
    }
    /* {POSTCLOSE:resetDataProviderOptions} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onDataProviderChange} {"sha1":"A54F9104A54C1A52A11E68DA4A6A53BC7D5AF8F7"} */
    onDataProviderChange(event, searchDataProvider) {
        /* {OPEN:onDataProviderChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
        /* {CLOSE:onDataProviderChange} {"sha1":"935A896682894FB36A7BFCE30A960C51672083B5"} */
    }
    /* {POSTCLOSE:onDataProviderChange} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:onAddClick} {"sha1":"21CE91B0532C1EB4629F93ED9622E65AEF8DA4A1"} */
    onAddClick(event, searchDataProvider) {
        /* {OPEN:onAddClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
        throw new Error('Not Implemented');
        /* {CLOSE:onAddClick} {"sha1":"450C367D813329EF536123BF9C3C87D44568A5F2"} */
    }
    /* {POSTCLOSE:onAddClick} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:resetInitVariables} {"sha1":"846C0DF64879342578D595FE17BC00B96F0BAED6"} */
    resetInitVariables() {
        /* {OPEN:resetInitVariables} {} */
        /* {OPEN:resetInitVariables_resetDataProviderOptions} {"sha1":"67215C624D110D15E0247AD494A6478841D830D4"} */
        try {
            this.resetDataProviderOptions();
        }
        catch (error) {
            console.error('Error occured when executing resetDataProviderOptions', error);
        }
        /* {CLOSE:resetInitVariables_resetDataProviderOptions} {"sha1":"67215C624D110D15E0247AD494A6478841D830D4"} */
        /* {CLOSE:resetInitVariables} {} */
    }
    /* {POSTCLOSE:resetInitVariables} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:getPageTitle} {"sha1":"174A3D30FFA103DD7BE180EE89CC0CF5EB6D11B8"} */
    getPageTitle() {
        /* {OPEN:getPageTitle} {"sha1":"AE6E166FFCD73C301EC2583D96EA645613FC3701"} */
        return 'Add Data Provider';
        /* {CLOSE:getPageTitle} {"sha1":"AE6E166FFCD73C301EC2583D96EA645613FC3701"} */
    }
    /* {POSTCLOSE:getPageTitle} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:calculateEverything} {"sha1":"CE17BF2375171825A37C544A1DCBF3A565DD5181"} */
    calculateEverything() {
        /* {OPEN:calculateEverything} {} */
        /* {OPEN:calculateEverything_pageTitle} {"sha1":"B3CA5EAE3ED54D1F0D95EA2CD05351A4A6D7480F"} */
        try {
            this.pageTitle = this.getPageTitle();
        }
        catch (error) {
            console.error('Error occured when setting pageTitle');
        }
        /* {CLOSE:calculateEverything_pageTitle} {"sha1":"B3CA5EAE3ED54D1F0D95EA2CD05351A4A6D7480F"} */
        /* {OPEN:calculateEverything_isDataProviderVolatile} {"sha1":"C2FDEF95ECADC43A18B8A05FDC4467DFAA1824B1"} */
        try {
            if(this.isDataProviderVolatile) {
                this.resetDataProviderOptions();
            }
        }
        catch (error) {
            console.error('Error occured when resetting dataProviderOptions');
        }
        /* {CLOSE:calculateEverything_isDataProviderVolatile} {"sha1":"C2FDEF95ECADC43A18B8A05FDC4467DFAA1824B1"} */
        /* {CLOSE:calculateEverything} {} */
    }
    /* {POSTCLOSE:calculateEverything} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:ngDoCheck} {"sha1":"75839B27D41E20648CE296AC51D61E24D541A70A"} */
    ngDoCheck() {
        /* {OPEN:ngDoCheck} {"sha1":"A154E0663059B6D385B4F6C11094F2084FF5E184"} */
        this.calculateEverything();
        /* {CLOSE:ngDoCheck} {"sha1":"A154E0663059B6D385B4F6C11094F2084FF5E184"} */
    }
    /* {POSTCLOSE:ngDoCheck} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {PREOPEN:ngOnDestroy} {"sha1":"C21B2137D2BD86DF7050E24A39F08355F04DA4E9"} */
    ngOnDestroy() {
        /* {OPEN:ngOnDestroy} {"sha1":"85AA65230B2C97013ABDD30D48BEB93C04E8EE54"} */
        this.utilsService.unsubscribeSubscriptions(this.subscriptions);
        /* {CLOSE:ngOnDestroy} {"sha1":"85AA65230B2C97013ABDD30D48BEB93C04E8EE54"} */
    }
    /* {POSTCLOSE:ngOnDestroy} {"sha1":"450EFDD84EDE8CD641816CE6F84E9F022BE00F82"} */

    /* {CLOSE:CLASS} {} */
}
/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */
