import { Subscription } from 'rxjs';
import { UtilsService } from './../../../oneit/services/utils.service';
import { AdminDashboardService } from './../admin-dashboard/admin-dashboard.service';
import { Component, OnInit } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
@Component({
  selector: 'app-list-orgs',
  templateUrl: './list-orgs.component.html',
  styleUrls: ['./list-orgs.component.scss'],
})
export class ListOrgsComponent implements OnInit {

  subscriptions : Array<Subscription> = [];
  addOrgTitle;

  displayedColumns: string[] = ['orgId', 'name', 'created', 'actions'];
  organizations;

  constructor(
    private dashboardService: AdminDashboardService,
    private utilsService: UtilsService) {}

  ngOnInit(): void {
    this.fetchOrganizaitons();
  }

  fetchOrganizaitons() {
    // this.showLoader = true;
    this.subscriptions.push(
        this.dashboardService.getOrganizations().subscribe(data => {
            // this.showLoader = false;
            this.organizations = data;
            console.log(this.organizations);
        }, error => {
            // this.showLoader = false;
            this.utilsService.handleError(error);
        })
    );
  }

  addOrganization = () => {
    let params = {
      name: this.addOrgTitle,
    }
    this.dashboardService.createOrganization(params).subscribe(data => {
      // this.showLoader = false;
      this.addOrgTitle=null;
      this.fetchOrganizaitons();
  }, error => {
      // this.showLoader = false;
      this.utilsService.handleError(error);
  });
  }

}

export class Organization {
  name: string;
  createdAt: Date;
  id: string;
}
