/* {OPEN:IMPORT} {"sha1":"19B6C974F3CC40CE02AFBFC963EFA391E7E06CBB"} */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseResetPasswordComponent } from './base-reset-password.component';
/* {CLOSE:IMPORT} {"sha1":"19B6C974F3CC40CE02AFBFC963EFA391E7E06CBB"} */
import { ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
/* {POSTCLOSE:IMPORT} {"sha1":"19B6C974F3CC40CE02AFBFC963EFA391E7E06CBB"} */

/* {PREOPEN:CLASS} {"sha1":"9F839AD83FCD6BB4DA5C2B52A70BFD14BEAEBD3F"} */
@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent extends BaseResetPasswordComponent implements OnInit, OnDestroy {
    /* {OPEN:CLASS} {} */

    ngOnInit() {
        /* {OPEN:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
        super.ngOnInit();
        /* {CLOSE:ngOnInit} {"sha1":"DFE5E641DE7C40A8E736555162DF58629C5DC562"} */
    }

    ngOnDestroy() {
        /* {OPEN:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
        super.ngOnDestroy();
        /* {CLOSE:ngOnDestroy} {"sha1":"6EBB3EE51FF8A84C55168D20DE692A575CF24C7E"} */
    }


    /* {CLOSE:CLASS} {} */
    token;
    errorMsgs = [];
    @ViewChild('form') form: NgForm;
    disableResetPasswordBtn = false;

    //@override
    executeScreenSpecificInitCode() {
        this.getResetToken();
    }

    getResetToken() {
        this.showLoader = true;

        this.subscriptions.push(
            this.activatedRoute.queryParams.subscribe(params => {
                this.showLoader = false;

                if ( params.token ) {
                    this.isValidToken(params);
                    return;
                }

                if ( !params.token ) {
                    this.router.navigate(['./login']);
                }
            }, error => {
                this.showLoader = false;
                this.utilsService.handleError(error);
            })
        );
    }

    isValidToken(params) {
        this.showLoader = true;

        const param = {
            token: params.token
        };

        this.subscriptions.push(
            this.resetPasswordService.validateToken(param).subscribe(() => {
                this.showLoader = false;
                this.token = params.token;
            }, error => {
                this.showLoader = false;
                this.utilsService.handleError(error);
                setTimeout(() => {
                    this.router.navigate(['./login']);
                }, 1000)
            })
        );
    }

    //@override
    onResetPasswordClick(event, resetPassword) {
        this.utilsService.clearErrorMessages();
        this.disableResetPasswordBtn = true;

        if ( this.appService.validatePassword(this.resetPassword.EnterPassword) ) {
            this.disableResetPasswordBtn = false;
            return;
        }

        if ( !this.form.valid ) {
            this.utilsService.showAllErrorMessages();
            this.disableResetPasswordBtn = false;
            return;
        }

        if ( this.resetPassword.EnterPassword !== this.resetPassword.ConfirmPassword ) {
            this.disableResetPasswordBtn = false;
            this.utilsService.handleError('Password does not match');
            return;
        }

        this.showLoader = true;

        const params = {
            token: this.token,
            password: this.resetPassword.EnterPassword,
            confirmPassword: this.resetPassword.ConfirmPassword
        };

        this.subscriptions.push(
            this.resetPasswordService.resetPassword(params).subscribe(() => {
                this.showLoader = false;
                this.disableResetPasswordBtn = false
                this.utilsService.handleSuccessMessage('Password has been reset successfully please login with the updated password');
                this.router.navigate(['./login']);
            }, error => {
                this.showLoader = false;
                this.disableResetPasswordBtn = false;
                this.utilsService.handleError(error);
            })
        );
    }

    handleKeyUp(event) {
        if(event.keyCode === 13 && !this.disableResetPasswordBtn){
            this.onResetPasswordClick(event, this.resetPassword);
        }
    }

}
/* {POSTCLOSE:CLASS} {"sha1":"C70E5DF2EC887CC71225EFF87752E01AB6C6157B"} */
